import { Grid, Icon, IconButton, Typography } from '@material-ui/core'

export const FormUploadFilesPrintEditor = () => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      style={{ paddingLeft: 5 }}
    >
      <IconButton variant='link'>
        <Icon>download</Icon>
      </IconButton>
      <Typography style={{ marginLeft: 5 }}>
        <b>File name</b>
        <div>File description</div>
      </Typography>
    </Grid>
  )
}
