import { Trans } from '@lingui/macro'
import { Button, Dialog, DialogTitle, Grid } from '@material-ui/core'
import { deleteSurveyTemplate } from 'app/services/sfAuth/sfData/sfSurvey'
import { useSnackbar } from 'notistack'
import React from 'react'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'

const DeleteSurveyTemplateDialog = ({
  closeDialog,
  template,
  dialogChildrenIds,
  updateDialogsOpen,
  fetchData,
  warningDialogOpen
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isDeleting, setDeleting] = React.useState(false)

  if (!template || !warningDialogOpen) {
    return null
  }
  return (
    <Dialog open={Boolean(warningDialogOpen)} onClose={closeDialog}>
      <DialogTitle>
        <Trans>Are you sure you want to delete this template?</Trans>
      </DialogTitle>
      <Grid container direction='row' justify='space-evenly'>
        <Button
          disabled={isDeleting}
          color='primary'
          variant='contained'
          style={{
            marginBottom: 10
          }}
          onClick={() => {
            setDeleting(true)
            const deletingSnackbar = enqueueSnackbar(null, {
              variant: 'info',
              persist: true,
              content: key =>
                ProgressSnackbar(<Trans>Deleting survey template</Trans>)
            })
            const indexClose = template.index
            deleteSurveyTemplate(template.id, dialogChildrenIds).then(
              result => {
                fetchData().then(r => {
                  closeSnackbar(deletingSnackbar)
                  enqueueSnackbar(<Trans>Survey template deleted!</Trans>, {
                    variant: 'info'
                  })
                  setDeleting(false)
                  closeDialog()
                  if (indexClose || indexClose === 0) {
                    updateDialogsOpen(indexClose)
                  }
                })
              }
            )
          }}
        >
          <Trans>Delete</Trans>
        </Button>
        <Button
          disabled={isDeleting}
          variant='contained'
          color='primary'
          style={{
            marginBottom: 10
          }}
          onClick={closeDialog}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Grid>
    </Dialog>
  )
}

export default React.memo(DeleteSurveyTemplateDialog)
