import { TextField } from '@material-ui/core'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

const DebouncedTextField = ({
  value: initialValue,
  onChange,
  debounceTime = 500,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(initialValue || '')

  const debouncedChangeHandler = useCallback(
    debounce(event => {
      if (onChange) {
        onChange(event)
      }
    }, debounceTime),
    [onChange, debounceTime]
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  const handleChange = e => {
    // Clone the event's target properties
    const clonedEvent = {
      ...e,
      target: {
        name: e.target.name,
        value: e.target.value
      }
    }

    setInputValue(e.target.value)
    debouncedChangeHandler(clonedEvent)
  }

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  return <TextField {...props} value={inputValue} onChange={handleChange} />
}

export default DebouncedTextField
