import { t } from '@lingui/macro'
import { EgretLoadable } from 'egret'
import { myI18n } from 'translation/I18nConnectedProvider'
import { authRoles } from '../../auth/authRoles'

const Forms = EgretLoadable({
  loader: () => import('../internal/Forms')
})

const Form = EgretLoadable({
  loader: () => import('./Form')
})

const FormEditor = EgretLoadable({
  loader: () => import('./editor/FormWizard')
})

const OpportunityViewWithForm = EgretLoadable({
  loader: () => import('../forms/OpportunityViewWithForm')
})

const formsRoutes = [
  {
    path: '/internal/Forms',
    component: Forms,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Forms`)
  },
  {
    path: '/internal/FormEditor/:id?',
    component: FormEditor,
    auth: authRoles.pm,
    isTranslatable: true,
    label: myI18n._(t`Form Editor`)
  },
  {
    path: '/preview-form/:formId/:id?/:previewType?',
    component: Form,
    auth: authRoles.editor
  },
  {
    path: '/opportunityFormView/:formId/:opportunityId/:objectId/:printConfig?',
    component: OpportunityViewWithForm
  }
]

export default formsRoutes
