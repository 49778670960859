import SFAuthService, { NO_USER } from '../SFAuthService'

export const createObjectiveUpdate = (obj) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
      return Promise.reject(NO_USER)
  }
  return conn.sobject('Objective_Update__c').create(obj)
}

export const updateObjectiveUpdate = (obj) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject('Objective_Update__c').update(obj);
};

export const getUpdatesForReport = reportId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Objective_Update__c')
    .find({
      Grantee_Report__c: reportId
    })
}