import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { ConfigureSelectActions } from '../../common/ConfigureSelectActions'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormBoolean } from './FormBoolean'

export const FormEditorBoolean = ({
  editMode,
  depth,
  id,
  langVersion,
  typeProps = {},
  injectable,
  showPdfProps,
  ...props
}) => {
  const { fieldType, required, selectActions = [] } = typeProps

  const dispatch = useDispatch()
  if (!editMode) {
    return (
      <FormBoolean
        {...props}
        id={id}
        langVersion={langVersion}
        typeProps={typeProps}
      />
    )
  }

  return (
    <div style={{ margin: 10 }}>
      <FormControl>
        <FormLabel component='legend'>
          <Trans>List type</Trans>
        </FormLabel>
        <RadioGroup
          defaultValue='singleselect'
          value={fieldType}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.fieldType = e.currentTarget.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <FormControlLabel
            value='checkbox'
            control={<Radio />}
            label={<Trans>Checkbox</Trans>}
          />
          <FormControlLabel
            value='switch'
            control={<Radio />}
            label={<Trans>Switch</Trans>}
          />
        </RadioGroup>
      </FormControl>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(required)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.required = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Required</Trans>}
        />
      </div>

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={['boolean']}
      />

      <div style={{ padding: 5 }}>
        <ConfigureSelectActions
          depth={depth}
          typeProps={typeProps}
          selectActions={selectActions}
          langVersion={langVersion}
          id={id}
        />
      </div>
    </div>
  )
}
