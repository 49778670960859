import { SET_PORTAL_TRANSLATION_CONFIG } from '../actions/PortalTranslationActions'

const initialState = {}

const PortalTranslationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_PORTAL_TRANSLATION_CONFIG: {
      return {
        ...state,
        ...action.data
      }
    }
    default: {
      return state
    }
  }
}

export default PortalTranslationReducer
