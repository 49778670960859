import { Trans } from '@lingui/macro'
import { Card, Grid } from '@material-ui/core'
import { MUEditedByLabel } from 'app/views/forms/multiuser/components/MUEditedByLabel'
import FindAccountButton from 'app/views/grants/FindAccountButton'
import { Field, useFormikContext } from 'formik'
import { getMainConnected, insertValueToSFObject } from '../../../Form'
import { getMuState } from '../../../common/Common'
import {
  endEditingField,
  startEditingField
} from '../../../multiuser/grpcMultiuserEdit'

export const formAccountPickerExtractSaveKey = ({
  saveMap,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const { connectedField } = connectedProps
  insertValueToSFObject({
    saveMap,
    sfObject,
    value: value ? value.id : null,
    fieldProps: connectedField,
    subObjectsMap,
    connectedObjectId
  })
}

export const formAccountPickerParseValueToCompare = (value, props) => {
  if (props.saveFailed && value) {
    return value.name
  }
  if (value && value.id) {
    return value.id
  } else {
    return value
  }
}

export const formAccountPickerDefaultValue = (obj, additionalInfo, item) => {
  const { accountsMap = {} } = additionalInfo
  if (!obj) {
    return null
  } else {
    const { connectedField } = getMainConnected(item)
    const field = connectedField?.name
    if (obj[field] && accountsMap[obj[field]]) {
      const account = accountsMap[obj[field]]
      return {
        id: account.Id,
        name: account.Name
      }
    } else {
      return obj[field]
    }
  }
}

export const formAccountPickerValueToText = (
  value,
  question,
  { accountsMap }
) => {
  if (typeof value === 'string') {
    const account = accountsMap[value] || {}
    return {
      en: account.Name || '',
      fr: account.Name || ''
    }
  } else if (!value) {
    return {
      en: '',
      fr: ''
    }
  }
  return {
    en: value.name || '',
    fr: value.name || ''
  }
}

/**
 * Form element which allows user to search for and select and organization from the database.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection}  connectedObject Connected Salesforce object.
 * @param  {SalesforceFieldConnection}  connectedField Connected Salesforce field. Accepts 'reference' (Account) type fields.
 */
export const FormAccountPicker = ({
  id,
  useMultiuser,
  muBag,
  disabled,
  connectedFieldDetails,
  editMode
}) => {
  const { values } = useFormikContext()
  const { isEdited, userColor, userName } = getMuState({ values, id })

  return (
    <Field name={id}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const onAccountFound = ({ Id, Name, ...account }) => {
          const newValue = {
            id: Id,
            name: Name
          }
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }

        return (
          <>
            <Card
              elevation={5}
              style={{
                padding: 20,
                border: '1px solid #C4C4C4'
              }}
            >
              <Grid container direction='column' justify='center'>
                <div style={{ marginBottom: 10 }}>
                  <FindAccountButton
                    disabled={
                      disabled ||
                      !connectedFieldDetails ||
                      Boolean(isEdited && useMultiuser)
                    }
                    onSelect={onAccountFound}
                    onDialogClose={e => {
                      if (useMultiuser) {
                        endEditingField({
                          ...muBag,
                          fieldId: id,
                          fieldValue: value,
                          lockId: values.muInfo.lockId
                        })
                      }
                    }}
                    onDialogOpen={e => {
                      if (useMultiuser) {
                        startEditingField({
                          ...muBag,
                          fieldId: id
                        })
                      }
                    }}
                  />
                  {Boolean(!connectedFieldDetails && !editMode) && (
                    <div style={{ padding: 10, color: 'red' }}>
                      <Trans>There is no object connected in editor!</Trans>
                    </div>
                  )}
                </div>
                {Boolean(value && typeof value === 'object') && (
                  <b>
                    <span>{value.name}</span>
                  </b>
                )}
              </Grid>
            </Card>
            {isEdited && (
              <div style={{ marginTop: 5 }}>
                <MUEditedByLabel color={userColor} userName={userName} />
              </div>
            )}
          </>
        )
      }}
    </Field>
  )
}
