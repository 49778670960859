import { portalLanguagesData } from 'app/appSettings'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm } from '../sfDataService'
import { SF_API_VERSION } from './sfOpportunity'

export const scoreCardStructureBase = {
  QuestionID: '',
  'Scoring group': undefined,
  modifier: '',
  multiple: '',
  'Option 1': '',
  'Option 2': '',
  'Option 3': '',
  'Option 4': '',
  'Option 5': '',
  'Option 6': '',
  'Option 7': '',
  'Option 8': '',
  'Option 9': '',
  'Option 10': '',
  'Option 11': '',
  'Option 12': ''
}

const mapFields = {
  direction: 'in',
  Id: 'id',
  Modifier__c: 'modifier',
  Scoring_Group__c: 'scoreGroup',
  Survey_Item__c: 'surveyItem',
  Survey_Item_Ident_Id__c: 'surveyItemIdentId',
  Scoring_Details__c: 'scoringDetails'
}

export const SCORING_CATEGORIES_CONFIG = 'SCORING_CATEGORY'

/**
 * Retrieves score card items with parsed scoring details based on the provided parameters.
 * @function
 * @category Salesforce - Score_Card_Item__c
 * @param {object} params The parameters used to filter the score card items.
 * @returns {object[]} An array of score card items with parsed scoring details.
 */
export const getScoreCardItemsParsed = (params: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card_Item__c')
    .find({ ...params })
    .then(items =>
      items.map(item => {
        if (item.Scoring_Details__c) {
          item.Scoring_Details__c = JSON.parse(item.Scoring_Details__c)
        }
        return mapSFToForm(mapFields, item)
      })
    )
}

/**
 * Retrieves the scoring categories configuration from the Salesforce 'Configuration__c' object.
 * @function
 * @category Salesforce - Configuration__c
 * @returns {object} The scoring categories configuration object.
 */
export const getScoringCategoriesConfiguration = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .find({ Type__c: 'SCORING_CATEGORY' })
    .then(r => r[0])
}

/**
 * Saves the scoring categories configuration to the Salesforce 'Configuration__c' object.
 * @function
 * @category Salesforce - Configuration__c
 * @param {string} id The id of the configuration to update.
 * @param {Array<object>} [categories=[]] The array of scoring categories to save (optional).
 * @returns {JSForceResult}
 */
export const saveScoringCategoriesConfiguration = ({ id, categories = [] }: {
  id: string,
  categories: object[]
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .update({ Id: id, Value__c: JSON.stringify(categories) })
}

/**
 * Checks if a score card exists for a given survey template id.
 * @function
 * @category Salesforce - Score_Card__c
 * @param {string} id The id of the survey template to check for.
 * @returns {boolean} True if a score card exists for the template, otherwise false.
 */
export const checkIfScoreCardExistForTemplate = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ Survey_Template__c: id })
    .select('Id')
    .then(result => {
      if (Array.isArray(result) && result.length > 0) {
        return true
      } else {
        return false
      }
    })
}

/**
 * Checks if score cards exist for multiple survey template ids.
 * @function
 * @category Salesforce - Score_Card__c
 * @param {string[]} ids The array of survey template ids to check for.
 * @returns {string[]} An array of template ods for which score cards exist.
 */
export const checkIfScoreCardExistForTemplates = (ids: string[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ Survey_Template__c: { $in: ids } })
    .then(scoreCards => {
      return scoreCards.map(obj => obj.Survey_Template__c).filter(f => f)
    })
}

interface ScoreCardParams {
  Survey_Template__c: string,
  [key: string]: any
}

/**
 * Retrieves a complete scorecard including its associated scorecard items.
 * @function
 * @category Salesforce - Score_Card__c
 * @param {ScoreCardParams} params Parameters for fetching the scorecard.
 * @param {string} params.Survey_Template__c The id of the survey template associated with the scorecard.
 * @returns {object|null} An object containing the scorecard and its associated scorecard items,
 * or null if no scorecard is found.
 */
export const getScoreCardFull = (params: ScoreCardParams) => {
  const conn = SFAuthService.getConnection()
  const templateId = params.Survey_Template__c
  if (!templateId) {
    return Promise.reject('No id provided')
  }
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ ...params })
    .then(cards => {
      if (cards.length === 0) {
        return null
      } else if (cards.length > 1) {
        return Promise.reject('Too many score cards for selected template')
      }
      const card = cards[0]
      return getScoreCardItemsParsed({
        // Survey_Template__c: templateId,
        Score_Card__c: card.Id
      }).then(items => {
        return {
          scoreCard: card,
          scoreCardItems: items
        }
      })
    })
}

/**
 * Deletes scorecards with the specified ids.
 * @function
 * @category Salesforce - Score_Card__c
 * @param {string[]} ids An array of ids of the scorecards to be deleted.
 * @returns {JSForceResult}
 */
export const deleteScoreCard = (ids: string[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
    allOrNone: true,
    compositeRequest: [
      {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          ids.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects'
      }
    ]
  })
}

interface ScoreCardCreationValues {
  titleValue: Record<string, string>;
  parentCreateTemplate: string;
}

/**
 * Creates a new scorecard with the provided values.
 * @function
 * @category Salesforce - Score_Card__c
 * @param {object} values Values for creating the scorecard.
 * @param {string} values.titleValue The title value for the scorecard.
 * @param {string} values.parentCreateTemplate The id of the parent template for the scorecard.
 * @returns {JSForceResult}
 */
export const createScoreCard = (values: ScoreCardCreationValues) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      let promiseArray: string[] = []
      Object.values(portalLanguagesData).map(obj => {
        const nameQuestion = conn.sobject('Survey_Text__c').create({
          Survey_Translation__c: translation.id,
          Text__c: values.titleValue[obj.editKey],
          Language__c: obj.surveyTextKey
        })
        promiseArray.push(nameQuestion)
      })
      return Promise.all([
        ...promiseArray,
        conn.sobject('Score_Card__c').create({
          Title_Translation__c: translation.id,
          Name: values.titleValue['en'],
          Survey_Template__c: values.parentCreateTemplate
        })
      ])
    })
}

interface SaveScoreCardObject {
  Id: string,
  [key: string]: any
}

/**
 * Saves score cards by creating new ones or updating existing ones based on the provided array.
 * @param {SaveScoreCardObject[]} array An array of score card objects to save. Each object may contain an 'Id' property indicating an existing score card to update.
 * @returns {JSForceResult}
 */
export const saveScoreCards = (array: SaveScoreCardObject[]) => {
  const toCreate: SaveScoreCardObject[] = []
  const toUpdate: SaveScoreCardObject[] = []
  array.forEach(obj => {
    if (obj.Id) {
      toUpdate.push(obj)
    } else {
      toCreate.push(obj)
    }
  })
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return Promise.all([
    conn.sobject('Score_Card_Item__c').create(toCreate),
    conn.sobject('Score_Card_Item__c').update(toUpdate)
  ])
}
