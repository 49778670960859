interface ResultObject {
  compositeResponse: CompositeResponseObject[],
  isSuccess? : boolean,
  success?: any
}

interface CompositeResponseObject {
  httpStatusCode: number,
  body: {
    success?: any,
    errorCode?: string
  }[],
}
/**
 * Determines if the result of a composite request is successful.
 * @function
 * @category Utility
 * @param {ResultObject} result The result of the composite request.
 * @param {object[]} result.compositeResponse The response object from each subrequest in the composite request.
 * @param {number} result.compositeResponse[].httpStatusCode The HTTP status code of the subrequest response.
 * @returns {boolean} Returns true if all subrequests in the composite request return a successful HTTP status code (200-299), otherwise returns false.
 */
export const successFromComposite = (result: ResultObject) => {
  let success = result.compositeResponse
    .map(item => item.httpStatusCode >= 200 && item.httpStatusCode < 300)
    .reduce((a, b) => a && b, true)
  return success
}

export const PROCESSING_HALTED = 'PROCESSING_HALTED'
export const ENTITY_IS_LOCKED = 'ENTITY_IS_LOCKED'

interface ErrorDetail {
  errorCode?: string
}

/**
 * Extracts errors from the result of a composite request.
 * @function
 * @category Utility
 * @param {Object} result The result of the composite request.
 * @param {Object[]} result.compositeResponse The response object from each subrequest in the composite request.
 * @param {Object[]} result.compositeResponse[].body The body of each subrequest response containing error details.
 * @param {string} result.compositeResponse[].body[].errorCode The error code of each error in the subrequest response.
 * @param {string} PROCESSING_HALTED The error code indicating that processing was halted for the subrequest.
 * @returns {Object} An object containing extracted errors.
 * @returns {boolean} ret.compositeError Indicates if errors were found in the composite request.
 * @returns {string[]} ret.errors An array of unique error codes extracted from the composite request.
 * @returns {Object[]} ret.errorDetails An array containing detailed error objects extracted from the composite request.
 */
export const errorsFromComposite = (result: ResultObject) => {
  const ret = {
    compositeError: true,
    errors: [] as any[],
    errorDetails: [] as ErrorDetail[]
  }
  let errors = new Set()

  for (let response of result.compositeResponse) {
    for (let error of response.body) {
      if (error.errorCode === PROCESSING_HALTED) {
        continue
      } else {
        errors.add(error.errorCode)
        ret.errorDetails.push({
          ...error
        })
      }
    }
  }
  ret.errors = [...errors.values()]
  return ret
}

/**
 * Creates a read-only field object with the specified name.
 * @function
 * @category Utility and Salesforce - Opportunity__c
 * @param {string} name The name of the field.
 * @param {Object} opp The opportunity object from which to retrieve the field value.
 * @returns {object} A read-only field object with a function that retrieves the specified field value from an opportunity object.
 * @returns {*} The value of the specified field from the opportunity object.
 */
export const readOnlyField = (name: string) => ({
  in: opp => opp[name]
})

/**
 * Creates a read-only field object with the specified name and key for a user.
 * @function
 * @category Utility and Salesforce - User
 * @param {string} name The name of the field.
 * @param {string} key The key associated with the field.
 * @param {object} user - The user object from which to retrieve the field value.
 * @returns {object} A read-only field object with a function that retrieves the specified field value from a user object.
 * @returns {*} The value of the specified field from the user object.
 */
export const readOnlyFieldMTF = (name: string, key: string) => ({
  [name]: {
    key: key,
    in: user => user[name]
  }
})

/**
 * Checks if the result of a Promise.all operation indicates success.
 * @function
 * @category Utility
 * @param {*} result - The result of the Promise.all operation.
 * @returns {boolean} Returns true if any promise in the Promise.all operation failed, otherwise returns false.
 */
export const checkPromiseAllSuccess = (result: ResultObject) => {
  if (Array.isArray(result)) {
    if (result.length === 0) {
      return false
    }
    return result.some(sub => checkPromiseAllSuccess(sub))
  } else {
    if (result.compositeResponse) {
      return result.compositeResponse.some(comp => {
        if (!comp.body) {
          return false
        } else {
          return comp.body.some(sub => !sub.success)
        }
      })
    } else if (result.hasOwnProperty('isSuccess')) {
      return !result.isSuccess
    }
    return !result.success
  }
}
