import { portalLanguagesData } from "app/appSettings";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { describe } from "./sfSurvey";
import { getUsersByFlow } from "./sfUser";

/**
 * Maps a language code to its corresponding label.
 * @function
 * @param {string} code - The language code to map.
 * @returns {string|undefined} The label corresponding to the language code, or undefined if no label is found.
 */
const mapLanguage = (code: string) => portalLanguagesData?.[code].label;

interface Case {
  Id: string;
  CreatedBy?: {
    Id: string;
    LastName: string;
    FirstName: string;
    Name: string;
  };
  CreatedById?: string;
  CaseComments?: {
    records: {
      CreatedBy: {
        Id: string;
      };
    }[];
  };
}

interface CaseComment {
  CreatedBy: {
    Id: string;
  };
}

/**
 * Retrieves comments associated with a specific Case ID from Salesforce.
 * @function
 * @category Salesforce - Case
 * @subcategory Salesforce - CaseComment
 * @param {string} id - The id of the case to retrieve associated comments for.
 * @returns {Promise<object[]>}
 */
export const getComments = (id: string) => {
  const conn = SFAuthService.getConnection();
  return conn.sobject("CaseComment").find({
    ParentId: id,
  });
};

/**
 * Adds a comment to a specific Case in Salesforce.
 * @function
 * @category Salesforce - Case
 * @subcategory Salesforce - CaseComment
 * @param {string} id - The id of the Case to add the comment to.
 * @param {string} comment - The text of the comment to add.
 * @returns {JSForceResult}
 */
export const addComment = (id: string, { comment }: { comment: string }) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("CaseComment").create({
    ParentId: id,
    IsPublished: true,
    CommentBody: comment,
  });
};

/**
 * Retrieves the available case types from Salesforce.
 * @function
 * @category Salesforce - Case
 * @returns {object[]}
 */
export const getCaseTypes = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.describe("Case").then((result) => {
    let toRet = [];
    result.fields.some((field) => {
      const bool = field.name === "Type";
      if (bool) {
        toRet = field.picklistValues
          .filter((field) => field.active)
          .map((field) => ({
            label: field.label,
            value: field.value,
          }));
      }
      return bool;
    });
    return toRet;
  });
};

interface GetCasesProps {
  searchParams?: any;
}

/**
 * Retrieves Cases associated with a specific Opportunity from Salesforce.
 * @function
 * @category Salesforce - Case
 * @param {string} id - The id of the Opportunity to retrieve associated cases for.
 * @param {object} [props={}] - Additional properties for customizing the query.
 * @param {object} [props.searchParams] - Additional search parameters to filter cases.
 * @returns {Promise<Object[]>}
 */
export const getCases = (id: string, props: GetCasesProps = {}) => {
  const conn = SFAuthService.getConnection();
  const { searchParams } = props;
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  describe("CaseComment");
  return conn
    .sobject("Case")
    .find({
      Associated_Opportunity__c: id,
      ...searchParams,
    })
    .include("CaseComments")
    .select(
      "*, CreatedBy.Id, CreatedBy.LastName, CreatedBy.FirstName, CreatedBy.Name"
    )
    .sort("CreatedDate", "ASC")
    .end()
    .sort("CreatedDate", "ASC")
    .autoFetch(true)
    .then((result) => {
      const usersIds: string[] = [];
      result.forEach((caseObj: Case) => {
        if (!usersIds.includes(caseObj.CreatedById as string)) {
          usersIds.push(caseObj.CreatedById as string);
        }
        if (caseObj.CaseComments) {
          caseObj.CaseComments.records.forEach((commentObj: CaseComment) => {
            if (!usersIds.includes(commentObj.CreatedBy.Id)) {
              usersIds.push(commentObj.CreatedBy.Id);
            }
          });
        }
      });
      return Promise.resolve(getUsersByFlow(usersIds)).then((users) => {
        const usersMap = {};
        users.forEach((user) => {
          usersMap[user.Id] = user;
        });
        const toRet = result;
        toRet.forEach((caseObj: Case, caseIndex: number) => {
          if (caseObj.CaseComments) {
            caseObj.CaseComments.records.forEach((commentObj, index) => {
              toRet[caseIndex].CaseComments.records[index].CreatedBy.Name =
                usersMap[commentObj.CreatedBy.Id]?.Name;
            });
          }
        });
        return toRet;
      });
    });
};

/**
 * Retrieves an External_Approval__c object (vote) from Salesforce based on the provided parameters.
 * @function
 * @category Salesforce - External_Approval__c
 * @param {string} oppId - The id of the opportunity for which the vote is being retrieved.
 * @param {string} userId - The id of the user who created the vote.
 * @param {string} entityType - The type of entity for which the vote is being retrieved.
 * @returns {Promise<object[]>}
 */
export const getVote = (oppId: string, userId: string, entityType: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const findQuery = userId
    ? { Opportunity__c: oppId, CreatedById: userId, Entity_Type__c: entityType }
    : { Opportunity__c: oppId, Entity_Type__c: entityType };
  return conn.sobject("External_Approval__c").find(findQuery);
};

/**
 * Saves data related to a conflict of interest for a given External_Approval__c record to the database based on the provided values.
 * If the values contain an 'Id', updates the existing record; otherwise, creates a new record.
 * @function
 * @category Salesforce - External_Approval__c
 * @subcategory Salesforce - Conflict_of_interest__c
 * @param {object} values - The values to be saved, representing the conflict of interest field.
 * @returns {JSForceResult}
 */
export const saveConflictOfInterest = (values: { Id: string }) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (values.Id) {
    return conn.sobject("External_Approval__c").update(values);
  } else {
    return conn.sobject("External_Approval__c").create(values);
  }
};

/**
 * Updates an External_Approval__c record (vote) in the database with the provided values.
 * @function
 * @category Salesforce - External_Approval__c
 * @param {string} vote - The value to be updated for the vote.
 * @param {string} updateId - The id of the External_Approval__c record to be updated.
 * @param {string} conflictOfInterest - The value indicating the conflict of interest for the vote.
 * @returns {JSForceResult}
 */
export const updateVote = ({
  vote,
  updateId,
  conflictOfInterest,
}: {
  vote: string;
  updateId: string;
  conflictOfInterest: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("External_Approval__c").update({
    Id: updateId,
    Result__c: vote,
    Conflict_of_interest__c: conflictOfInterest,
  });
};

export const entityTypes = {
  ALLOCATION_COMMITTEE: "Allocation Committee",
  AC: "Allocation Committee",
  SC: "Selection Committee",
  BOARD: "Board",
  Board: "Board",
  CMHC: "CMHC",
  EXECUTIVE_DIRECTOR: "Executive Director",
};

export const committeeCaseMapping = {
  Board: "Approval Process - Board",
  AC: "Approval Process - AC",
  SC: "Approval Process - SC",
  CMHC: "Approval Process - CMHC",
};

interface VoteCreationValues {
  voteValue: string;
  id: string;
  userId: string;
  entityType: string;
}

/**
 * Creates a new External_Approval__c (vote) record in the database with the provided values.
 * @function
 * @category Salesforce - External_Approval__c
 * @param {object} values - The values used to create the vote record.
 * @param {string} values.voteValue - The value of the vote.
 * @param {string} values.id - The id of the opportunity associated with the vote.
 * @param {string} values.userId - The id of the approving user.
 * @param {string} values.entityType - The type of entity associated with the vote.
 * @returns {Promise<object>}
 */
export const createVote = (values: VoteCreationValues) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("External_Approval__c").create({
    Result__c: values.voteValue,
    Opportunity__c: values.id,
    Approving_User__c: values.userId,
    Entity_Type__c: values.entityType,
  });
};

export const HELP_CASE_TYPES = {
  HELP: "Portal - help",
  FUNDING_UNSURE: "Funding – Unsure/Other",
  ENVIRONMENTAL_SAT: "Environmental self-assessment",
};

interface CaseCreationValues {
  Language__c: string;
  Status?: string;
  Origin?: string;
}

/**
 * Creates new cases in the database with the provided values.
 * @function
 * @category Salesforce - Case
 * @param {object[]} values - An array of objects representing the values used to create the cases.
 * @param {string} values[].Language__c - The language of the case.
 * @returns {Promise<object[]>}
 */
export const createCases = (values: CaseCreationValues[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  values.forEach((obj) => {
    obj.Status = "New";
    obj.Origin = "Portal";
    obj.Language__c = mapLanguage(obj.Language__c || "en");
  });
  return conn.sobject("Case").create(values);
};

interface CaseByFlowCreationValues {
  contact: string;
  organization?: string;
  name?: string;
  userName?: string;
  skipAssigment?: boolean;
  origin?: string;
  type: string;
  title?: string;
  owner: string;
  comments?: string;
  description: string;
  region: string;
  language?: string;
  opportunityId?: string;
  surveyId?: string;
  isESAT?: boolean;
}

/**
 * Creates a new Case object in Salesforce using a specified flow and the provided values.
 * @function
 * @category Salesforce - Case
 * @param {object} values - The values used to create the Case.
 * @param {string} values.contact - The id of the Contact associated with the Case.
 * @param {string} [values.organization] - The id of the organization associated with the Case (optional).
 * @param {string} [values.name] - The company name associated with the Case (optional).
 * @param {string} [values.userName] - The name of the User associated with the Case (optional).
 * @param {boolean} [values.skipAssigment] - Whether to skip the assignment of the Case (optional).
 * @param {string} [values.origin='Portal'] - The origin of the Case. Defaults to 'Portal' if not provided.
 * @param {string} values.type - The type of the Case.
 * @param {string} [values.title] - The title of the Case.
 * @param {string} values.owner - The id of the owner of the Case.
 * @param {string} [values.comments] - The comments associated with the Case (optional).
 * @param {string} values.description - The description associated with the Case.
 * @param {string} values.region - The region associated with the Case.
 * @param {string} [values.language='en'] - The language of the Case. Defaults to 'en' if not provided.
 * @param {string} [values.opportunityId] - The id of the Opportunity associated with the Case (optional).
 * @param {string} [values.surveyId] - The id of the Survey associated with the Case (optional).
 * @param {boolean} [values.isESAT] - Whether the Case is related to ESAT (optional).
 * @returns {FlowResult}
 */
export const createCaseByFlow = (values: CaseByFlowCreationValues) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const caseToCreate = {
    ContactId: values.contact,
    ...(values.skipAssigment !== undefined
      ? { Skip_Assignment__c: values.skipAssigment }
      : {}),
    AccountId: values.organization,
    SuppliedCompany: values.name,
    SuppliedName: values.userName,
    Status: "New",
    Origin: values.origin || "Portal",
    Type: values.type,
    Subject: values.title,
    OwnerId: values.owner,
    Comments: values.comments,
    Description: values.description,
    Region__c: values.region,
    Language__c: mapLanguage(values.language || "en"),
    Associated_Opportunity__c: values.opportunityId,
    Related_Survey__c: values.surveyId,
  };
  return conn.requestPost("/actions/custom/flow/App_Create_Case", {
    inputs: [
      {
        caseToCreate,
        esatCase: values.isESAT,
      },
    ],
  });
};

/**
 * Interface representing the values used to create a case.
 */
interface CaseCreationValues {
  contact?: string;
  skipAssigment?: boolean;
  organization?: string;
  name?: string;
  userName?: string;
  owner: string;
  origin?: string;
  type: string;
  title?: string;
  comments?: string;
  description: string;
  region: string;
  opportunityId?: string;
  surveyId?: string;
  language?: string;
  prequalificationId?: string;
}

/**
 * Creates a new case in the database with the provided values.
 * @param {CaseCreationValues} values - The values used to create the case.
 * @param {string} [values.contact] - The ID of the contact associated with the case (optional).
 * @param {boolean} [values.skipAssigment] - Whether to skip the assignment of the case (optional).
 * @param {string} values.organization - The ID of the organization associated with the case.
 * @param {string} values.name - The company name associated with the case.
 * @param {string} values.userName - The name associated with the case.
 * @param {string} values.owner - The ID of the owner associated with the case.
 * @param {string} [values.origin='Portal'] - The origin of the case. Defaults to 'Portal' if not provided (optional).
 * @param {string} values.type - The type of the case.
 * @param {string} values.title - The title of the case.
 * @param {string} values.comments - The comments associated with the case.
 * @param {string} values.description - The description associated with the case.
 * @param {string} values.region - The region associated with the case.
 * @param {string} values.opportunityId - The ID of the opportunity associated with the case.
 * @param {string} values.surveyId - The ID of the survey associated with the case.
 * @param {string} [values.language='en'] - The language of the case. Defaults to 'en' if not provided (optional).
 * @returns {Promise<object>} A Promise that resolves with the created case record, or rejects with an error message if the connection to the database is unavailable.
 */
export const createCase = (values: CaseCreationValues) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Case").create({
    ContactId: values.contact,
    ...(values.skipAssigment !== undefined
      ? { Skip_Assignment__c: values.skipAssigment }
      : {}),
    AccountId: values.organization,
    SuppliedCompany: values.name,
    SuppliedName: values.userName,
    Status: "New",
    OwnerId: values.owner,
    Origin: values.origin || "Portal",
    Type: values.type,
    Subject: values.title,
    Comments: values.comments,
    Description: values.description,
    Region__c: values.region,
    Language__c: mapLanguage(values.language || "en"),
    Prequalification__c: values.prequalificationId,
    Associated_Opportunity__c: values.opportunityId,
    Related_Survey__c: values.surveyId,
  });
};
