import { Text } from "@react-pdf/renderer";
import { pdfDefaultFontSize } from "../common/Common";

type FormPdfFillAreaProps = {
  lines?: number;
};

const FormPdfFillArea = ({ lines = 1 }: FormPdfFillAreaProps) => {
  return Array(lines)
    .fill(".")
    .map((i, index) => {
      return (
        <Text
          key={index}
          style={{
            overflow: "hidden",
            paddingTop: 16,
            fontSize: pdfDefaultFontSize,
          }}
        >
          {Array(171).fill(".").join("")}
        </Text>
      );
    });
};

export default FormPdfFillArea;
