import { Trans } from '@lingui/macro'

/**
 * Label that is used in form field to display username of user that is currently editing it.
 * @category Multiuser
 * @component
 * @returns {JSX.Element}
 * @property {color} color Object containing multiuser data needef for making the queries.
 * @property {string} userName Current multiuser session token.
 */
export const MUEditedByLabel = ({ color, userName }) => {
  if (!userName) {
    return <div style={{ minHeight: 18, marginBottom: 3 }} />
  }
  return (
    <span style={{ fontSize: 11, color, marginBottom: 3 }}>
      <Trans>Is edited by</Trans>
      {' ' + userName}
    </span>
  )
}
