import { Html } from "react-pdf-html";
import { HtmlProps } from "react-pdf-html/dist/Html";

/** ImprovedHtml component that renders list items on the left side of the PDF page
 * @param children - The content to render
 * @param collapse - Whether to collapse the content
 * @param style - The style to apply to the content
 * @param rest - The rest of the props
 */
export const ImprovedHtml = ({
  children,
  collapse = true,
  style,
  ...rest
}: HtmlProps) => {
  const html = String(children)
    .replaceAll("<ul>", "<div class='ul'>")
    .replaceAll("<li>", "<div class='li_content'>•")
    .replaceAll("</li>", "</div>")
    .replaceAll("</ul>", "</div>")
  return (
    <Html collapse={collapse} style={style} {...rest}>
      {html}
    </Html>
  );
}