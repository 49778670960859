import { SET_PORTAL_TRANSLATION_DATA } from '../actions/PortalTranslationDataActions'

const initialState = false

const PortalTranslationDataReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_PORTAL_TRANSLATION_DATA: {
      return action.data
    }
    default: {
      return state
    }
  }
}

export default PortalTranslationDataReducer
