import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  firstCol: {
    width: '35%'
  },
  secondCol: {
    width: '30%'
  },
  thirdCol: {
    width: '35%'
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    margin: '2px',
    padding: '2px'
  },
  mainTableCell: {
    fontWeight: 'bold',
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    margin: '2px',
    padding: '2px'
  }
})

export const FormProjectAffiliationsPdf = ({
  typeProps = {},
  title,
  value = [],
  langFR,
  ...props
}) => {
  const { roles } = typeProps

  const currentLanguage = myI18n.language

  if (currentLanguage === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  const roleToTrans = {}
  roles.forEach(role => {
    if (role?.apiValue && role?.label) {
      roleToTrans[role.apiValue] = role?.label?.portalTranslationId
        ? myI18n._(role.label.portalTranslationId)
        : role?.label?.[currentLanguage]
    }
  })

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact name`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.secondCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact e-mail`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact role`)}
            </Text>
          </View>
        </View>

        {value.map((contact, index) => (
          <View style={styles.tableRow} wrap={false} key={index}>
            <View style={[styles.tableCol, styles.firstCol]}>
              <Text style={styles.tableCell}>{contact.name}</Text>
            </View>
            <View style={[styles.tableCol, styles.secondCol]}>
              <Text style={styles.tableCell}>{contact.email}</Text>
            </View>
            <View style={[styles.tableCol, styles.thirdCol]}>
              <Text style={styles.tableCell}>{roleToTrans[contact.role]}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}
