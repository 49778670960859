import SFAuthService, { NO_USER } from '../SFAuthService'

const mapping = {
  Expenses: { lang: 'en_CA', category: 'expenses' },
  Revenue: { lang: 'en_CA', category: 'revenues' },
  Dépenses: { lang: 'fr_CA', category: 'expenses' },
  Revenu: { lang: 'fr_CA', category: 'revenues' }
}

/**
 * Fetches Budget Categories from Salesforce.
 * @function
 * @category Salesforce - FGM_Portal__Grantee_Budget_Category__c
 * @returns {Promise<object>}
 */
export const getBudgetCategories = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Portal__Grantee_Budget_Category__c')
    .select('Id, Name, FGM_Portal__Parent_Category__c')
    .autoFetch(true)
    .then(results => {
      let topLevel = {}
      let budgetCategoryMapping = {
        en_CA: {
          revenues: {},
          expenses: {}
        },
        fr_CA: {
          revenues: {},
          expenses: {}
        }
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].FGM_Portal__Parent_Category__c === null) {
          topLevel[results[i].Id] = results[i]
          topLevel[results[i].Id].category = mapping[results[i].Name]
          topLevel[results[i].Id].children = []
        }
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].FGM_Portal__Parent_Category__c !== null) {
          let parent = topLevel[results[i].FGM_Portal__Parent_Category__c]
          budgetCategoryMapping[parent.category.lang][parent.category.category][
            results[i].Name
          ] = results[i].Id
        }
      }
      return budgetCategoryMapping
    })
}
