import { Trans } from '@lingui/macro'
import {
  configurationTypes,
  getConfigurations
} from 'app/services/sfAuth/sfData/sfForms'
import { useEffect, useState } from 'react'
import { basePortalConfig } from '../internal/ConfigurePortalConfigurations'

const { isValidJSON } = require('utils')

/** Lookup object for labels of application type configurations in which the form is used */
export const applicationTypeConfigurationFormFieldNames = {
  form: <Trans>Form</Trans>,
  quiz: <Trans>Quiz</Trans>,
  printForm: <Trans>Print form</Trans>,
  granteeReportForm: <Trans>Grantee report form (preview)</Trans>,
  internalPrintPreview: <Trans>Applications list print preview</Trans>,
  contract: <Trans>Contract</Trans>,
  previewPM: <Trans>Contract opportunity preview</Trans>,
  SC: <Trans>Selection Committee preview</Trans>,
  AC: <Trans>Allocation Committee preview</Trans>,
  Board: <Trans>Board executive summary preview</Trans>,
  CMHC: <Trans>CMHC executive summary preview</Trans>
}

/** Array of field names for application type configurations in which the form is used */
export const applicationTypeConfigurationFormFields = Object.keys(
  applicationTypeConfigurationFormFieldNames
)

/** This function filters configurations to leave only the ones that are needed to configure application types
 * @param {Array} configurations
 * @returns {Array} configurations
 */
export const filterConfigurations = configurations => {
  const filtered = configurations.filter(item => {
    let data = {}
    const isValid = isValidJSON(item.Value__c)
    if (isValid) {
      data = JSON.parse(item.Value__c) || {}
    }
    return (
      configurationTypes[data.type] || item.Name === 'AVAILABLE_APPLICATIONS'
    )
  })
  return filtered
}

/** This function returns an array of portal configurations labels that are associated with the formId.
 * @param {String} formId - the form id
 * @param {Object} configuration - the configuration object from the portal redux state
 * @returns {Array} portalConfigurations
 * */
export const getPortalConfigurationLabels = (formId, configuration) => {
  const configurationEntries = Object.entries(configuration)
  const portalConfigurations = configurationEntries
    .filter(
      ([key, value]) =>
        value === formId && basePortalConfig?.[key]?.type === 'formId'
    )
    .map(([key, value]) => {
      return basePortalConfig?.[key]?.label
    })
  return portalConfigurations
}

/** This hook is used to get a function that returns a JSX element that displays the names of the places that a form is available in.
 * @returns {Function} getFormUsage
 * */
export const useFormUsage = () => {
  // state to store application type configurations
  const [configurations, setConfigurations] = useState([])

  // fetch application type configuration data, filter, map, and store in state
  useEffect(() => {
    getConfigurations().then(configurations => {
      const filtered = filterConfigurations(configurations).map(item => {
        let data = {}
        if (isValidJSON(item.Value__c)) {
          data = JSON.parse(item.Value__c) || {}
        }
        return {
          ...item,
          ...data
        }
      })
      setConfigurations(filtered)
    })
  }, [])

  /** returns application type configurations that include the formId
   * @param {String} formId - the form id
   * @returns {Array} applicationTypeConfigurations
   * */
  const getApplicationTypeConfigurations = formId => {
    if (!formId) return []
    return configurations.filter(item => {
      return applicationTypeConfigurationFormFields.find(
        field => item[field] === formId
      )
      // return item.form === formId
    })
  }

  /** This function returns a JSX element that displays the names of the places that a form is available in.
   * @param {String} formId - the form id
   * @param {Object} configuration - the configuration object from the portal redux state
   * @param {String} langVersion - the language version
   * @returns {String} used places
   * */
  const getFormUsage = ({ formId, configuration, langVersion }) => {
    const applicationTypeConfigurations =
      getApplicationTypeConfigurations(formId)

    const applicationTypeUsage = applicationTypeConfigurations.map(item => {
      const name = item.name[langVersion] || item.name.en
      const types = applicationTypeConfigurationFormFields.filter(
        field => item[field] === formId
      )
      const typesLabels = types.map((type, idx) => {
        return (
          <>
            {applicationTypeConfigurationFormFieldNames[type]}
            {idx !== types.length - 1 && ', '}
          </>
        )
      })
      return (
        <li>
          {name} [{typesLabels}]
        </li>
      )
    })

    const portalConfigurationLabels = getPortalConfigurationLabels(
      formId,
      configuration
    )
    const usedPlaces = (
      <ul>
        {portalConfigurationLabels.map(item => (
          <li>{item}</li>
        ))}
        {applicationTypeUsage}
      </ul>
    )
    return portalConfigurationLabels.length > 0 ||
      applicationTypeUsage.length > 0
      ? usedPlaces
      : null
  }

  return { getFormUsage }
}
