import { Divider, Grid, Paper, Typography } from "@material-ui/core"
import { Trans, t } from '@lingui/macro'

export const FormAffiliatedOrganizationsPrintEditor = ({ title }) => {

    return (
      <div style={{ flex: 1 }}>
        <div className='form-print-title-small'>{title}</div>
        <Paper elevation={6} style={{ marginTop: 10, padding: 15 }}>
          <Grid container>
            <Grid item xs style={{ padding: 4 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Name</Trans>
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Email</Trans>
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                Name Placeholder
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                Email Placeholder
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }