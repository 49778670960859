import { Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import moment from 'moment'
import React from 'react'
import { INDEX_OF_IN_PROGRESS } from './ActionPlan'
import { INDEX_OF_SELECTED, INDEX_OF_FAILED, INDEX_OF_DONE } from './ActionPlan'

const postponeDays = [14, 30, 90]

const ActionPlanBoardCard = ({
  card,
  cardActions,
  column,
  index,
  columns,
  deleting
}) => {
  const title = card.title
  const date = moment(card.dueDate).format(dateFormat)
  const [anchorEl, setAnchorEl] = React.useState(null)
  return (
    <div className='scrum-board-card'>
      <Menu
        onBackdropClick={e => {
          e.stopPropagation()
        }}
        onClose={() => {
          setAnchorEl(null)
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {postponeDays.map(numDays => {
          return (
            <MenuItem
              key={numDays}
              className='mr-4 text-small'
              fontSize='small'
              onClick={e => {
                e.stopPropagation()
                cardActions.postponeAction(numDays, column.index, index)
                setAnchorEl(null)
              }}
            >
              <Trans>Postpone {numDays} days</Trans>
            </MenuItem>
          )
        })}
      </Menu>

      <div className='px-16 py-12'>
        <h6 className='m-0 font-weight-500'>{title}</h6>
        {date}
        <div className='flex flex-middle flex-space-between mt-12 button-group text-small'>
          <Grid className='flex' container direction='row'>
            <Tooltip title={<Trans>Edit action description</Trans>}>
              <IconButton
                disabled={deleting}
                onClick={e => {
                  e.stopPropagation()
                  cardActions.openDialog({
                    ...card,
                    columnIndex: column.index,
                    cardIndex: index
                  })
                }}
              >
                <Icon className='mr-4 text-small' fontSize='small'>
                  remove_red_eye
                </Icon>
              </IconButton>
            </Tooltip>

            {column.index !== INDEX_OF_IN_PROGRESS && (
              <Tooltip title={<Trans>Mark action as In Progress</Trans>}>
                <IconButton
                  disabled={deleting}
                  onClick={e => {
                    e.stopPropagation()
                    cardActions.moveAction({
                      source: {
                        index: index
                      },
                      destination: {
                        index: columns[INDEX_OF_IN_PROGRESS].actions.length
                      },
                      targetColumnIndex: INDEX_OF_IN_PROGRESS,
                      sourceColumnIndex: column.index
                    })
                  }}
                >
                  <Icon className='mr-4 text-small' fontSize='small'>
                    hourglass_empty
                  </Icon>
                </IconButton>
              </Tooltip>
            )}

            {column.index !== INDEX_OF_DONE && (
              <Tooltip title={<Trans>Mark action as done</Trans>}>
                <IconButton
                  disabled={deleting}
                  onClick={e => {
                    e.stopPropagation()
                    cardActions.moveAction({
                      source: {
                        index: index
                      },
                      destination: {
                        index: columns[INDEX_OF_DONE].actions.length
                      },
                      targetColumnIndex: INDEX_OF_DONE,
                      sourceColumnIndex: column.index
                    })
                  }}
                >
                  <Icon className='mr-4 text-small' fontSize='small'>
                    <b>check</b>
                  </Icon>
                </IconButton>
              </Tooltip>
            )}

            {/*{column.index !== INDEX_OF_SELECTED && (*/}
            {/*  <Tooltip title={<Trans>Mark action as selected</Trans>}>*/}
            {/*    <IconButton*/}
            {/*      disabled={deleting}*/}
            {/*      onClick={e => {*/}
            {/*        e.stopPropagation()*/}
            {/*        cardActions.moveAction({*/}
            {/*          source: {*/}
            {/*            index: index*/}
            {/*          },*/}
            {/*          destination: {*/}
            {/*            index: columns[INDEX_OF_SELECTED].actions.length*/}
            {/*          },*/}
            {/*          targetColumnIndex: INDEX_OF_SELECTED,*/}
            {/*          sourceColumnIndex: column.index*/}
            {/*        })*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Icon className='mr-4 text-small' fontSize='small'>*/}
            {/*        input*/}
            {/*      </Icon>*/}
            {/*    </IconButton>*/}
            {/*  </Tooltip>*/}
            {/*)}*/}

            {column.index !== INDEX_OF_FAILED && (
              <Tooltip title={<Trans>Mark action as failed</Trans>}>
                <IconButton
                  disabled={deleting}
                  onClick={e => {
                    e.stopPropagation()
                    cardActions.moveAction({
                      source: {
                        index: index
                      },
                      destination: {
                        index: columns[INDEX_OF_FAILED].actions.length
                      },
                      targetColumnIndex: INDEX_OF_FAILED,
                      sourceColumnIndex: column.index
                    })
                  }}
                >
                  <Icon className='mr-4 text-small' fontSize='small'>
                    close
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={<Trans>Postpone action</Trans>}>
              <IconButton
                disabled={deleting}
                onClick={e => {
                  setAnchorEl(e.target)
                  e.stopPropagation()
                }}
              >
                <Icon className='mr-4 text-small' fontSize='small'>
                  <b>calendar_today</b>
                </Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title={<Trans>Delete action</Trans>}>
              <IconButton
                disabled={deleting}
                onClick={e => {
                  e.stopPropagation()
                  cardActions.deleteAction(card, index, column.index)
                }}
              >
                <Icon className='mr-4 text-small' fontSize='small'>
                  delete
                </Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default ActionPlanBoardCard
