import { Trans } from '@lingui/macro'
import { createTheme, makeStyles } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { merge } from 'lodash'
import { useSelector } from 'react-redux'
import { FormLabel } from '../common/labels/FormLabel'

const widePopupTheme = theme =>
  createTheme(
    merge(
      { ...theme },
      {
        datePickerToolbar: {
          paddingLeft: 24,
          paddingRight: 24,
          height: 100,
          color: 'primary'
        },
        overrides: {
          MuiPickersModal: {
            dialogRoot: {
              minWidth: 550
            }
          },
          MuiButton: {
            label: {
              justifyContent: 'flex-start'
            }
          },
          MuiPickersBasePicker: {
            pickerView: {
              maxWidth: '100%'
            }
          },
          MuiPickersCalendarHeader: {
            dayLabel: {
              width: 70
            }
          },
          MuiPickersDay: {
            day: {
              width: 40,
              marginLeft: 17,
              marginRight: 17
            }
          }
        }
      }
    )
  )

/**
 * Extended Material UI DateTimePicker that handles portal localization and overriding theme color. Is recommended to use over regular <DateTimePicker /> component.
 * @category Common components
 * @subcategory MUI extended
 * @component
 * @returns {JSX.Element}
 * @property {color} color Focus color of the field
 * @property {function} handleClose Callback fired on inputs onClose event
 * @property {function} handleOpen Callback fired on inputs onOpen event
 */
export const CustomDateTimePicker = ({
  color,
  handleClose,
  handleOpen,
  ...props
}) => {
  const userLang = useSelector(state => state.user.language)
  const settings = useSelector(state => state.layout.settings)
  const theme = settings.themes[settings.activeTheme]
  const customTheme = widePopupTheme({ ...theme })

  const coloredStyles = makeStyles(theme => ({
    root: {
      '& label.Mui-focused': {
        color: color || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-disabled fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-focused fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        }
      }
    }
  }))
  const classes = coloredStyles()

  return (
    <>
      <DateTimePicker
        cancelLabel={<Trans>Cancel</Trans>}
        okLabel={<Trans>Ok</Trans>}
        clearLabel={<Trans>Clear</Trans>}
        {...props}
        minDate={props.minDate || undefined}
        maxDate={props.maxDate || undefined}
        onOpen={e => {
          if (handleOpen) {
            handleOpen()
          }
        }}
        onClose={e => {
          if (handleClose) {
            handleClose()
          }
        }}
        className={color && classes.root}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
      />
      {props.error && props.metaError && (
        <FormLabel error label={<Trans id={props.metaError?.props?.id} />} />
      )}
    </>
  )
}
