import { t } from '@lingui/macro'
import { StyleSheet, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import { ImprovedHtml } from 'app/views/common-components/ImprovedHtml'
import {
  bilingualDateFormat,
  currencyFormatedString,
  numberFormat,
  percentFormattedString
} from 'app/views/common/Formats'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { getIn } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import NumberFormat from 'react-number-format'
import Html from 'react-pdf-html'
import { myI18n } from 'translation/I18nConnectedProvider'

export const pdfDefaultFontSize = 10
export const commonPdfStyles = StyleSheet.create({
  title: {
    padding: 10,
    fontSize: 16,
    fontWeight: 400
  },
  row: {
    flexWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

/**
 * Checks if memoized multiuser field should be re-rendered in next render.
 * @function
 * @category Multiuser
 * @param {object} nextProps Props passed to component in next render.
 * @param {object} currentProps Props passed to component in last render.
 * @returns {boolean} If the component should be updated.
 */
export const checkShouldUpdateForMultiuserField = (nextProps, currentProps) => {
  return (
    nextProps.lockId !== currentProps.lockId ||
    nextProps.isEdited !== currentProps.isEdited ||
    nextProps.token !== currentProps.token ||
    nextProps.disabled !== currentProps.disabled ||
    !_.isEqual(
      getIn(currentProps.formik.values, 'muUsers'),
      getIn(nextProps.formik.values, 'muUsers')
    ) ||
    getIn(nextProps.formik.errors, currentProps.name) !==
      getIn(currentProps.formik.errors, currentProps.name) ||
    getIn(nextProps.formik.touched, currentProps.name) !==
      getIn(currentProps.formik.touched, currentProps.name) ||
    getIn(nextProps.formik.values, currentProps.name) !==
      getIn(currentProps.formik.values, currentProps.name)
  )
}

/**
 * Returns current multiuser state of the field
 * @function
 * @category Multiuser
 * @param {object} values Formik values object.
 * @param {string} id Field id.
 * @returns {object} Object containing multiuser state of the field.
 */
export const getMuState = ({ values, id }) => {
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, isEdited
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  return {
    isEdited,
    userColor,
    userName
  }
}

/**
 * Guesses field type based on its value
 * @function
 * @param {any} value Field value.
 * @returns {string} Probable type of field.
 */
export const guessFieldType = value => {
  let type = 'string'
  if (!value) {
    return type
  }
  if (!isNaN(+value)) {
    type = 'int'
  } else if (moment(value).isValid()) {
    type = 'date'
  } else if (value === 'true' || value === 'false') {
    type = 'boolean'
  } else if (value && value.includes(';')) {
    type = 'multipicklist'
  }
  return type
}

/**
 * Parses value from SF field to string, based on fields type.
 * @function
 * @param {any} value Value of SF field.
 * @param {string} type API Salesforce field type.
 * @param {string} langVersion Language locale key.
 * @param {boolean} [translate] If true, returned text will be translated.
 * @returns {string} Parsed text.
 */
export const parseSfFieldValue = ({ value, type, langVersion, translate }) => {
  if (['date', 'datetime'].includes(type)) {
    if (moment.utc(value).isValid()) {
      if (translate) {
        value = bilingualDateFormat(value, langVersion)
      } else {
        value = moment.utc(value).format(dateFormat)
      }
    }
  } else if (['multipicklist', 'picklist'].includes(type)) {
    if (value) {
      value = value.replaceAll(';', ', ')
    }
  } else if (type === 'currency') {
    value = currencyFormatedString(value, langVersion)
  } else if (type === 'boolean') {
    if (typeof value === 'boolean') {
      value = value ? myI18n?._(t`Yes`) : myI18n?._(t`No`)
    }
  } else if (type === 'percent') {
    if (typeof value === 'number') {
      value = percentFormattedString(value, langVersion)
    }
  }
  if (translate) {
    value = String(myI18n?._(value))
  }
  return String(value).replace(/[^\S\r\n]+/g, ' ')
}

/**
 * Parses form text, replacing all manual references with reference field values
 * @function
 * @category Form
 * @param {string|object} text Either string to parse or translation object containing text under locale keys.
 * @param {string} langVersion Language locale key.
 * @param {object} [textStyle] Styles that will be applied to returned JSX element.
 * @param {object} describeMap describeMap variable from <Form/>.
 * @param {object} objectsFieldsMap objectsFieldsMap variable from <Form/>.
 * @param {boolean} [pdf] If true, text returned will be wrapped in PDF elements.
 * @param {boolean} [returnString] If true, text will be returned as a string.
 * @param {i18n} i18n Instance of i18n object. Used for translating texts marked as translatable.
 * @returns {string|JSX.Element} Parsed text.
 */
export const parseFormLabelText = ({
  text,
  langVersion,
  textStyle = {},
  describeMap = {},
  objectsFieldsMap = {},
  pdf,
  removeInvalidSigns = false,
  returnString = false,
  i18n
}) => {
  let toRet = text || ''
  if (text && typeof text === 'object') {
    toRet = getLabelFromTranslationData({
      langVersion,
      i18n,
      data: text
    })
  }

  const purgeInvalidSigns = string => {
    if (removeInvalidSigns) {
      return string.replaceAll('\n', '')
    } else {
      return string
    }
  }

  if (!toRet) {
    return purgeInvalidSigns(toRet)
  }

  const elementsArray = []
  const occurrences = toRet.match(/!{([^}]*)}/g)
  if (!occurrences) {
    return purgeInvalidSigns(toRet)
  }

  occurrences.forEach(occurrence => {
    let error, replace, html, translate
    let objName = occurrence.substring(
      occurrence.indexOf('{') + 1,
      occurrence.indexOf('.')
    )
    let field = occurrence.substring(
      occurrence.indexOf('.') + 1,
      occurrence.indexOf('}')
    )
    if (objName.includes('T/')) {
      translate = true
      objName = objName.replaceAll('T/', '')
    }
    if (objName === 'SPECIAL') {
      switch (field) {
        case 'CURRENT_DATE':
          replace = translate
            ? bilingualDateFormat(moment(), langVersion)
            : moment.utc().format(dateFormat)
          break
        default:
          replace = 'INCORRECT VALUE FOR SPECIAL FIELD: ' + field
          break
      }
    } else if (objectsFieldsMap[objName]) {
      let obj = objectsFieldsMap[objName][field]
      if (!obj && field.indexOf('.') !== -1) {
        const subField = field.split('.')[1]
        field = field.split('.')[0]
        if (field && subField) {
          let referenceField = objectsFieldsMap[objName][field]
          if (!referenceField || typeof referenceField !== 'object') {
            field = field.split('__r')[0] + 'Id' + '__r'
            referenceField = objectsFieldsMap[objName][field]
          }
          try {
            // if lookup field has no id we don't want to show error
            if (typeof referenceField === 'object' && referenceField.value) {
              const fieldValue = referenceField.value[subField]
              if (typeof fieldValue !== 'object') {
                let fieldType
                const parentObject = referenceField.value
                const parentType = parentObject.attributes.type
                if (describeMap[parentType]) {
                  describeMap[parentType].fields.some(field => {
                    const bool = field.name === subField
                    if (bool) {
                      fieldType = field.type
                    }
                    return bool
                  })
                }
                obj = {
                  value: fieldValue,
                  type: fieldType || guessFieldType(fieldValue)
                }
              } else {
                obj = fieldValue
              }
            } else {
              obj = {
                value: '',
                type: 'string'
              }
            }
          } catch (er) {
            error = 'NO SUBFIELD ' + subField + ' FOR FIELD: ' + field
          }
        }
      }
      if (obj) {
        const value = obj.value
        if (['date', 'datetime'].includes(obj.type)) {
          if (moment.utc(value).isValid()) {
            if (translate) {
              replace = bilingualDateFormat(value, langVersion)
            } else {
              replace = moment.utc(value).format(dateFormat)
            }
          }
        } else if (obj.type === 'currency') {
          replace = currencyFormatedString(value, langVersion)
        } else if (['multipicklist', 'picklist'].includes(obj.type)) {
          if (value) {
            replace = value.replaceAll(';', ', ')
          }
        } else if (['int', 'double'].includes(obj.type)) {
          replace = returnString || pdf ? value : numberFormat(value)
        } else if (obj.type === 'boolean') {
          if (typeof value === 'boolean') {
            replace = value ? myI18n?._(t`Yes`) : myI18n?._(t`No`)
          }
        } else if (
          [
            'string',
            'textarea',
            'email',
            'url',
            'phone',
            'address',
            'id',
            'reference'
          ].includes(obj.type)
        ) {
          if (/<\/?[a-z][\s\S]*>/i.test(value) && !returnString) {
            replace = pdf ? (
              <View>
                <ImprovedHtml style={textStyle}>{value}</ImprovedHtml>
              </View>
            ) : (
              ReactHtmlParser(value)
            )
            html = true
          } else {
            replace = value
          }
        } else {
          console.warn('No value parse configured for field type: ', obj)
          if (obj.type === 'object') {
            error =
              'REFERENCE: ' +
              occurrence +
              ' REFERENCES OBJECT, NOT SINGLE FIELD'
          } else {
            error = 'THIS TYPE OF FIELD IS NOT SUPPORTED: ' + obj.type
          }
        }
      } else if (!error) {
        error = 'NO FIELD: ' + field
      }
    }
    if (!replace) {
      replace = ''
    }
    if (translate) {
      replace = String(myI18n?._(replace))
    }
    if (returnString) {
      toRet = toRet.replace(occurrence, error || replace)
    } else {
      let element
      const sub1 = toRet.split(occurrence)[0]
      if (html) {
        element = replace
        if (sub1) {
          elementsArray.push(sub1)
        }
        elementsArray.push(element)
      } else {
        element = error || replace
        elementsArray.push(sub1, element)
      }
      toRet = toRet.split(occurrence)[1] || ''
    }
  })
  if (elementsArray.length === 0) {
    return purgeInvalidSigns(toRet)
  }
  if (toRet) {
    elementsArray.push(toRet)
  }

  if (pdf) {
    let returnArray = []
    let tempArray = []
    elementsArray.forEach(item => {
      if (item.type) {
        returnArray = returnArray.concat(tempArray)
        returnArray.push(item)
        tempArray = []
      } else {
        tempArray.push(item)
      }
    })
    if (tempArray.length > 0) {
      returnArray = returnArray.concat(tempArray)
    }
    return returnArray
  } else {
    return returnString ? purgeInvalidSigns(toRet) : elementsArray
  }
}

/**
 * Constructs form url for array of connected objects and ids of Salesforce objects that should be used in form.
 * @function
 * @category Form
 * @param {object} ids Object containing ids of Salesforce object under keys equal to thier API type.
 * @param {object[]} objectsConnected An array of object connected in form editor.
 * @returns {string} Form url.
 */
export const constructFormAddressString = ({
  previewConfig,
  user,
  userId,
  externalReviewId,
  reportId,
  organizationId,
  opportunity,
  contact,
  ids = {},
  objectsConnected = []
}) => {
  let defaultAddressString = []
  const objectsCount = {}
  if (!objectsConnected) {
    return ''
  }

  objectsConnected.forEach(obj => {
    let id = ids[obj.type] || 'NO_OBJECT_ID'

    if (previewConfig && previewConfig[obj.type]) {
      const idsArray = previewConfig[obj.type] || []
      if (!id || id === 'NO_OBJECT_ID') {
        if (!objectsCount[obj.type]) {
          objectsCount[obj.type] = 1
          id = idsArray[0]
        } else {
          id = idsArray[objectsCount[obj.type]]
          objectsCount[obj.type] += 1
        }
      }
    }

    if (id === 'NO_OBJECT_ID') {
      if (obj.type === 'Account') {
        id = organizationId
      } else if (obj.type === 'User') {
        id = userId || user?.userId
      } else if (obj.type === 'Opportunity') {
        id = opportunity
      } else if (obj.type === 'Contact') {
        id = contact
      } else if (obj.type === 'FGM_Base__Grantee_Report__c') {
        id = reportId
      } else if (obj.type === 'FGM_Base__Review__c') {
        id = externalReviewId
      } else if (obj.type === 'Review_Assignement__c') {
        id = obj.name
      }
    }
    if (Array.isArray(id)) {
      if (!objectsCount[obj.type]) {
        objectsCount[obj.type] = 1
        id = id[obj.loadOrder ? obj.loadOrder - 1 : 0]
      } else {
        id = id[obj.loadOrder ? obj.loadOrder - 1 : objectsCount[obj.type]]
        objectsCount[obj.type] += 1
      }
    }
    if (previewConfig && previewConfig[obj.type]) {
      const idsArray = previewConfig[obj.type] || []
      if (!id || id === 'NO_OBJECT_ID') {
        if (!objectsCount[obj.type]) {
          objectsCount[obj.type] = 1
          id = idsArray[0]
        } else {
          id = idsArray[objectsCount[obj.type]]
          objectsCount[obj.type] += 1
        }
      }
    }
    defaultAddressString.push(obj.identId + '=' + id)
  })
  defaultAddressString = defaultAddressString.join(';')
  return defaultAddressString
}

/**
 * Utility function used to perform actions configured to be executed on selecting form element value.
 * @function
 * @param {object} toSet Formik values that will be set as new values.
 * @param {boolean} checked If target value was just selected.
 * @param {object[]} selectActions Array of select actions configured for form element.
 * @param {object[]} [lastOptionActions=[]] For picklist elements - array of select actions from previously selected option. Used to execute 'on deselect' actions.
 * @returns {string[]} Ids of elements that were modified due to select actions.
 */
export const handleSelectActions = ({
  toSet,
  checked,
  selectActions,
  lastOptionActions = []
}) => {
  const modifiedIds = []
  Array.isArray(selectActions) &&
    selectActions.forEach(actionObj => {
      const { activation, target, action, targetProps } = actionObj
      const activate =
        ((!activation || activation === 'select') && checked) ||
        (activation === 'deselect' && !checked)
      if (target && activate) {
        if (action === 'reset') {
          if (targetProps?.picklistType === 'multiselect') {
            toSet[target] = []
            modifiedIds.push(target)
          } else {
            toSet[target] = null
            modifiedIds.push(target)
          }
        }
      }
    })

  lastOptionActions.forEach(actionObj => {
    const { activation, target, action, targetProps } = actionObj
    if (target && activation === 'deselect') {
      if (action === 'reset') {
        if (targetProps?.picklistType === 'multiselect') {
          toSet[target] = []
          modifiedIds.push(target)
        } else {
          toSet[target] = null
          modifiedIds.push(target)
        }
      }
    }
  })
  return modifiedIds
}

/**
 * Maps all form elements added to current form to array of object map.
 * @function
 * @category Form
 * @param {object} data Form data.
 * @param {string} langVersion Language locale. Used to display form elements labels in users locale.
 * @param {string} [id] Id of form element the map is used in. This element will be ommited in the map
 * @param {string[]} [elementTypes] If provided, will map only form elements of provided types.
 * @param {boolean} [asMap] If true, the function will return object instead of array.
 * @returns {object[]} List of form elements in the form with their data.
 */
export const mapEditorFormElements = ({
  data,
  langVersion,
  id,
  elementTypes = [],
  asMap = false
}) => {
  const returnArray = []
  let position = 1

  const mapItem = ({ item, returnArray, section }) => {
    if (item.elements) {
      position++
      item.elements.forEach(element =>
        mapItem({ item: element, returnArray, section })
      )
    } else {
      if (id !== item.id && elementTypes.includes(item.elementType)) {
        const title = parseFormLabelText({
          text: item.title,
          langVersion
        })
        const sectionName = parseFormLabelText({
          text: section.title,
          langVersion
        })
        returnArray.push({
          id: item.id,
          label: `[${sectionName}] ${position}. ${title}`,
          elementType: item.elementType,
          typeProps: item.typeProps
        })
      }
      position++
    }
  }
  data.sections.forEach(section =>
    section.elements.forEach(item => mapItem({ item, returnArray, section }))
  )
  if (asMap) {
    const retObject = {}
    returnArray.forEach(item => {
      retObject[item.id] = item
    })
    return retObject
  }
  return returnArray
}

/** Returns object containing element id and its position in form.
 * @function
 * @category Form
 * @param {object} treeData Form data.
 * @returns {object} Object containing element id and its position in form.
 */
export const getElementIdToFormPosition = treeData => {
  const sectionPositions = {}
  let position = 1
  treeData.sections.forEach(section => {
    section.elements.forEach(item => {
      const mapItem = item => {
        if (item.elements) {
          sectionPositions[item.id] = position
          position++
          item.elements.forEach(element => mapItem(element))
        } else {
          sectionPositions[item.id] = position
          position++
        }
      }
      mapItem(item)
    })
  })
  return sectionPositions
}

export function NumberFormatDefault (props) {
  const { inputRef, onChange, id, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      thousandSeparator=','
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
