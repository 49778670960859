import { Typography } from "@material-ui/core";
import React from "react";

export const TabHeader: React.FC<{}> = ({ children }) => {
  return (
    <Typography
      variant="h5"
      style={{
        textAlign: "center",
        marginBottom: 24,
        marginTop: 24,
      }}
    >
      {children}
    </Typography>
  );
};
