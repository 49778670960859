import { Paper } from "@material-ui/core";
import React from "react";
import { TabHeader } from "./TabHeader";

export const PortalTab: React.FC<{ tabLabel: React.ReactNode }> = ({
  tabLabel,
  children,
  ...props
}) => {
  return (
    <Paper style={{ padding: 16, height: "100%" }} {...props}>
      {tabLabel && <TabHeader>{tabLabel}</TabHeader>}
      {children}
    </Paper>
  );
};
