import { Trans } from '@lingui/macro'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { useDispatch } from 'react-redux'

export const FormEditorSubmitButton = ({
  typeProps = {},
  depth,
  editMode,
  injectable,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch()
  const { showPrintPromptAfterSubmit } = typeProps
  if (!editMode) {
    return (
      <Button color='primary' variant='contained' fullWidth>
        <Trans>Submit</Trans>
      </Button>
    )
  }

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(showPrintPromptAfterSubmit)}
            disabled={disabled}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.showPrintPromptAfterSubmit = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Show print prompt after submit</Trans>}
      />
    </div>
  )
}
