import { Trans, t } from '@lingui/macro'
import { I18n, I18nProvider } from '@lingui/react'
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { portalLanguagesData } from 'app/appSettings'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { getScoringCategoriesConfiguration } from 'app/services/sfAuth/sfData/sfScoreCard'
import {
  describe,
  getOtherTemplates,
  getSiblings,
  getSubtemplates,
  getSurveyTemplateDetails,
  saveItems,
  surveyItemOptionsFields
} from 'app/services/sfAuth/sfData/sfSurvey'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import { FormikCheckboxField } from 'formik-material-fields'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField/FormikCheckboxGroupField'
import { withSnackbar } from 'notistack'
import React, { Component, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { catalogs, myI18n } from 'translation/I18nConnectedProvider'
import {
  checkIfnoRecords,
  getEmptyLangValues,
  getInitialSurveyLangValues,
  getSurveyTransIds,
  getSurveyTranslationField
} from 'utils'
import * as Yup from 'yup'
import { TextFieldWithFormik } from '../common-components/TextFieldWithFormik'
import { TwoSidedScreen } from '../common-components/TwoSidedScreen'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'
import TranslatedFor from '../forms/form-page/TranslatedFor'
import {
  dateRangeRequiredTrans,
  questionRequiredTransWithStar
} from '../forms/formTranslations'
import { withFormikIncluded } from '../grants/applications/old-application/BaseIncludedForm'
import MultilanguageTextFieldWithFormik from '../internal/MultilanguageTextFieldWithFormik'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import { NumericFormat, countHelperText } from '../page-layouts/FormElement'
import { QuestionWidget, questionTypes } from '../surveys/QuestionWidget'
import ExternalQuestionDialog from './ExternalQuestionDialog'
import QuestionTitle from './QuestionTitle'
import SurveyCreationField from './SurveyCreationField'
import SurveyCreationFieldMenu from './SurveyCreationFieldMenu'
import {
  nonApplicableSuffix,
  questionToInitial,
  questionsToInitial
} from './SurveyTab'
import { questionValidationRules } from './ValidationRulesField'

const styles = {
  textBlock: {
    marginLeft: 30,
    marginTop: 20,
    marginRight: 30,
    textAlign: 'left'
  },
  paper: {
    width: '100%',
    paddingBottom: 10
  },
  sectionHeader: {
    fontSize: '48px',
    textAlign: 'center',
    padding: 20
  },
  question: {
    padding: 10,
    margin: 8
  }
}

const initialValues = {
  created: [],
  autosave: 0,
  // deleteIDs: [],
  toDelete: [],
  toUpdate: [],
  titleValue: getEmptyLangValues(),
  version: 'en'
}

const isSubquestion = dataObj => {
  return (
    dataObj.itemOptions.parentQuestionIndex ||
    dataObj.itemOptions.parentQuestionIndex === 0 ||
    dataObj.itemOptions.parentQuestionId
  )
}

const crypto = require('crypto')
export const surveyDataToSurveyEditor = ({
  mainTemplate,
  templates = [],
  templateId,
  otherTemplates = {},
  language
}) => {
  let toRet = {
    created: []
  }
  //newValues.caseTypes = caseTypes
  toRet.otherTemplates = []
  for (const key in otherTemplates) {
    if (Object.hasOwnProperty.call(otherTemplates, key)) {
      const obj = otherTemplates[key]
      let name = getLabelFromTranslationData({
        langVersion: language,
        data: obj.titleValue
      })
      if (!name) {
        name = obj.template.Name || ''
      }
      toRet.otherTemplates.push({
        name: name,
        id: obj.template.Id
      })
      if (obj.childTemplates.length > 0) {
        obj.childTemplates.forEach(sub => {
          let subName = getLabelFromTranslationData({
            langVersion: language,
            data: sub.titleValue
          })
          if (!subName) {
            subName = sub.template.Name || ''
          }
          toRet.otherTemplates.push({
            name: '[' + name + '] ' + subName,
            id: sub.template.Id
          })
        })
      }
    }
  }
  toRet.mainTemplate = mainTemplate.info.mainTemplate
  toRet.surveyName = mainTemplate.info.surveyName
  toRet = {
    ...toRet,
    ...mainTemplate.templateOptions,
    skipSection: Array.isArray(mainTemplate.templateOptions.skipSection)
      ? mainTemplate.templateOptions.skipSection
      : []
  }
  const data = mainTemplate.questionsDetails
  if (mainTemplate.titleDetails) {
    toRet.titleValue = mainTemplate.titleDetails.titleValue
    toRet.extendedValue = mainTemplate.titleDetails.extendedValue
    toRet.tooltipValue = mainTemplate.titleDetails.tooltipValue
  }
  toRet.otherSections = []

  templates
    .sort((a, b) => {
      return (
        new Date(a.info.templateCreatedDate) -
        new Date(b.info.templateCreatedDate)
      )
    })
    .forEach(template => {
      const sectionObj = {
        id: template.info.templateId
      }
      const groupQuestions = {}
      template.questionsDetails.forEach((dataObj, index) => {
        if (!dataObj.itemOptions) {
          return
        }
        const options = dataObj.itemOptions
        const parentId = options.parentQuestionId || options.parentQuestionIndex
        if (isSubquestion(dataObj)) {
          if (groupQuestions[parentId]) {
            groupQuestions[parentId].push({
              titleValue: dataObj.itemTranslation.titleValue,
              id: options.createdId,
              index: options.index
            })
          } else {
            groupQuestions[parentId] = [
              {
                titleValue: dataObj.itemTranslation.titleValue,
                index: dataObj.itemOptions.index,
                id: dataObj.itemOptions.createdId
              }
            ]
          }
        }
      })
      if (template.titleDetails[0]) {
        sectionObj.name = getSurveyTranslationField(
          language,
          'Text',
          template.titleDetails[0]
        )
      } else {
        sectionObj.name = template.info.surveyName
      }
      sectionObj.questions = []
      template.questionsDetails.forEach(questionData => {
        const question = {}
        const assignId = crypto.randomBytes(20).toString('hex')
        if (!questionData.itemOptions) {
          questionData.itemOptions = {
            optionsWithRequiredDetails: [],
            conditions: [],
            createdId: assignId,
            conditionsToCheck: [],
            externalConditions: []
          }
        }
        if (isSubquestion(questionData)) {
          return
        }
        if (questionData.itemOptions.createdId) {
          question.id = questionData.itemOptions.createdId
        } else {
          question.id = assignId
        }
        if (questionData.itemOptions.isGroup) {
          question.isGroup = true
          question.groupQuestions = []
          const toMatch = question.id || questionData.order
          const subQuestions = groupQuestions[toMatch]
          if (subQuestions) {
            subQuestions.sort((a, b) => {
              return a.index - b.index
            })
            subQuestions.forEach(sub => {
              question.groupQuestions.push({
                titleValue: sub.titleValue,
                id: sub.id
              })
            })
          }
        } else {
          question.isGroup = false
        }

        questionData.item.Survey_Joins_Template_and_Question__r.records.forEach(
          join => {
            if (join.Survey_Template__c === templateId) {
              question.joinId = join.Id
            }
          }
        )
        question.order = questionData.order
        question.titleValue = questionData.itemTranslation.titleValue
        sectionObj.questions.push(question)
      })
      toRet.otherSections.push(sectionObj)
    })

  const groupQuestions = {}
  data
    .sort((a, b) => {
      return a.order - b.order
    })
    .forEach((dataObj, index) => {
      if (!dataObj.itemOptions) {
        return
      }
      if (isSubquestion(dataObj)) {
        const toPush = {
          titleValue: dataObj.itemTranslation.titleValue,
          sfId: dataObj.item.Id,
          itemTranslation: dataObj.itemTranslation,
          joinId: dataObj.joinId,
          joins: dataObj.item.Survey_Joins_Template_and_Question__r.records,
          id: dataObj.itemOptions.createdId,
          index: dataObj.itemOptions.index
        }
        let parentId =
          dataObj.itemOptions.parentQuestionId ||
          dataObj.itemOptions.parentQuestionIndex
        if (groupQuestions[parentId]) {
          groupQuestions[parentId].push(toPush)
        } else {
          groupQuestions[parentId] = [toPush]
        }
      }
    })
  data
    .sort((a, b) => {
      return a.order - b.order
    })
    .forEach(dataObj => {
      const savedItem = {}
      if (!dataObj.itemOptions) {
        dataObj.itemOptions = {
          optionsWithRequiredDetails: [],
          conditions: [],
          conditionsToCheck: [],
          externalConditions: [],
          isTooltip: false
        }
      }
      if (isSubquestion(dataObj)) {
        return
      }
      savedItem.groupQuestions = []
      if (dataObj.itemOptions.isGroup) {
        savedItem.isGroup = true
        const subQuestions =
          groupQuestions[dataObj.itemOptions.createdId] ||
          groupQuestions[dataObj.order]
        if (subQuestions) {
          subQuestions.sort((a, b) => {
            return a.index - b.index
          })
          subQuestions.forEach(sub => {
            savedItem.groupQuestions.push({
              titleValue: sub.itemTranslation.titleValue,
              id: sub.id,
              sfId: sub.sfId,
              parentId: dataObj.itemOptions.createdId,
              itemTranslation: sub.itemTranslation,
              joinId: sub.joinId,
              joins: sub.joins
            })
          })
        }
      } else {
        savedItem.isGroup = false
      }
      savedItem.joins =
        dataObj.item.Survey_Joins_Template_and_Question__r.records
      savedItem.conditions = dataObj.itemOptions.conditions || []
      savedItem.externalConditions =
        dataObj.itemOptions.externalConditions || []
      savedItem.conditionsToCheck = dataObj.itemOptions.conditionsToCheck || []
      savedItem.options = dataObj.itemDetails.sort((a, b) => a.order - b.order)
      savedItem.itemTranslation = dataObj.itemTranslation
      savedItem.joinId = dataObj.joinId
      savedItem.sfId = dataObj.item.Id
      surveyItemOptionsFields.forEach(key => {
        if (dataObj.itemOptions.hasOwnProperty(key)) {
          savedItem[key] = dataObj.itemOptions[key]
        }
      })
      savedItem.validationRules = dataObj.itemOptions.validationRules || []
      const optionsWithRequiredDetails = {}
      dataObj.itemOptions.optionsWithRequiredDetails.forEach(detailObj => {
        if (detailObj) {
          const type = detailObj.type ? detailObj.type : 'text'
          const index =
            detailObj.index || detailObj.index === 0
              ? detailObj.index
              : detailObj
          optionsWithRequiredDetails[index] = type
        }
      })
      savedItem.options.forEach(option => {
        option.requireDetails = optionsWithRequiredDetails[option.order]
      })
      savedItem.titleValue = dataObj.itemTranslation.titleValue
      savedItem.tooltipValue = dataObj.itemTranslation.tooltipValue
      if (dataObj.itemOptions.createdId) {
        savedItem.id = dataObj.itemOptions.createdId
      } else {
        savedItem.id = crypto.randomBytes(20).toString('hex')
      }
      savedItem.type = dataObj.item.Type__c
      toRet.created.push(savedItem)
    })

  toRet.templateId = templateId
  toRet.titleData = mainTemplate.titleDetails
  return toRet
}

export function surveyTranslationToMapObj (titleTranslation, field = 'Text') {
  let ret = {}
  if (checkIfnoRecords(titleTranslation)) {
    return ret
  }
  const id = titleTranslation.Survey_Texts__r.records[0].Survey_Translation__c
  ret[id] = {
    value: {
      ...getInitialSurveyLangValues(field, titleTranslation)
    },
    id: {
      ...getSurveyTransIds(titleTranslation)
    }
  }
  return ret
}

export const createSurveyTranslationMap = template => {
  let translationMap = {
    ...surveyTranslationToMapObj(template.titleData)
  }
  template.created.forEach(question => {
    translationMap = {
      ...translationMap,
      ...surveyTranslationToMapObj(question.itemTranslation)
    }
    question.options.forEach(option => {
      translationMap = {
        ...translationMap,
        ...surveyTranslationToMapObj(option)
      }
    })
    if (question.tooltipAtBottom) {
      translationMap = {
        ...translationMap,
        ...surveyTranslationToMapObj(question.itemTranslation, 'Tooltip')
      }
    }
    if (question.isGroup) {
      question.groupQuestions.forEach(sub => {
        translationMap = {
          ...translationMap,
          ...surveyTranslationToMapObj(sub.itemTranslation)
        }
      })
    }
  })
  return translationMap
}

class CreateSurvey extends Component {
  constructor (props) {
    super(props)
    const templateId = props.match.params.id
    this.state.templateId = templateId
    this.state.isSubtemplate = props.match.params.templateType === 'sub'
    this.saveSurvey = this.saveSurvey.bind(this)
    this.fetchData(templateId, props)
  }

  state = {
    extended: false,
    isSubmitting: false,
    autoPreview: false,
    rebuildSchema: 0,
    conditionalObjects: {
      opportunity: {
        display: 'Opportunity',
        fields: {
          objectives: {
            display: 'Objectives',
            subfields: []
          }
        }
      }
    }
  }

  saveSurvey (values) {
    const creatingSnackbar = this.props.enqueueSnackbar(null, {
      variant: 'info',
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving survey</Trans>)
    })
    this.setState({ isSubmitting: true })
    return saveItems(values, this.state.templateId).then(result => {
      return this.fetchData(
        this.state.templateId,
        this.props,
        creatingSnackbar
      ).then(() => {
        this.setState({ isSubmitting: false })
      })
    })
  }

  fetchData (templateId, props, snackbarKey = null) {
    const detailsPromises = []
    let otherTemplatesPromise = null
    if (this.state.isSubtemplate) {
      otherTemplatesPromise = getSiblings
    } else {
      otherTemplatesPromise = getSubtemplates
    }
    return Promise.all([
      otherTemplatesPromise(templateId),
      describe('Case'),
      getScoringCategoriesConfiguration(),
      !sfOauthConfig.isIcce && describe('Objective__c')
    ])
      .then(([result, caseDescribe, configuration, objectivesDescribe]) => {
        let caseTypes = []
        if (!configuration) {
          props.enqueueSnackbar(
            <Trans>
              There is no configuration object with scoring categories
            </Trans>,
            { variant: 'error' }
          )
        } else {
          this.setState({
            scoringCategories: JSON.parse(configuration.Value__c) || []
          })
        }
        if (objectivesDescribe) {
          objectivesDescribe.fields.some(field => {
            if (field.name === 'Objective__c') {
              this.setState({
                conditionalObjects: {
                  opportunity: {
                    display: 'Opportunity',
                    fields: {
                      objectives: {
                        display: 'Objectives',
                        subfields: field.picklistValues.map(obj => ({
                          name: obj.label,
                          value: obj.value
                        }))
                      }
                    }
                  }
                }
              })
              return true
            }
            return false
          })
        }
        caseDescribe.fields.some(field => {
          if (field.name === 'Type') {
            caseTypes = field.picklistValues.filter(obj => obj.active)
            return true
          }
          return false
        })
        const otherTemplatesIDs = []
        result.templates.forEach(dataObj => {
          detailsPromises.push(getSurveyTemplateDetails(dataObj.id))
          otherTemplatesIDs.push(dataObj.id)
        })
        console.log('TEMPLATE ID', templateId)

        return Promise.all([
          getSurveyTemplateDetails(templateId),
          getOtherTemplates([
            templateId,
            result.parentId,
            ...otherTemplatesIDs
          ]),
          ...detailsPromises
        ]).then(results => {
          const splitResult = results.splice(0, 2)
          const mainTemplate = splitResult[0]
          console.log('GOT MAIN DETAILS', mainTemplate)

          let newValues = { ...props.values, templateId, caseTypes }
          const test = surveyDataToSurveyEditor({
            mainTemplate,
            templateId,
            templates: results,
            otherTemplates: splitResult[1],
            language: props.user.language
          })
          this.loaded = true
          props.setValues({ ...newValues, ...test })

          if (snackbarKey) {
            props.closeSnackbar(snackbarKey)
            props.enqueueSnackbar(<Trans>Survey saved!</Trans>, {
              variant: 'info'
            })
          }
        })
      })
      .catch(error => {
        console.error('error getting template', error)
        props.enqueueSnackbar(<Trans>Survey template loading error</Trans>, {
          variant: 'error'
        })
      })
  }

  render () {
    const { values, setFieldValue, user } = this.props
    const {
      isSubmitting,
      isSubtemplate,
      autoPreview,
      conditionalObjects,
      scoringCategories = []
    } = this.state

    console.log('values', values)
    return this.loaded ? (
      <TwoSidedScreen
        left={
          <Paper style={styles.paper}>
            <h1 style={{ textAlign: 'center', paddingTop: 20 }}>
              <Trans>Create Survey</Trans>
            </h1>

            {!isSubtemplate && (
              <TranslatedFor values={values} setFieldValue={setFieldValue} />
            )}

            <div>
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Typography style={{ fontSize: '16px' }}>
                  <Trans>Add new survey element</Trans>
                </Typography>
                <IconButton
                  onClick={() => {
                    const toSet = [...values.created]
                    let newElement = {
                      type: questionTypes.SELECT_ONE.id,
                      id: '',
                      titleValue: getEmptyLangValues(),
                      tooltipValue: getEmptyLangValues(),
                      requireDetails: false,
                      options: [],
                      joins: [],
                      conditions: [],
                      groupQuestions: [],
                      conditionsToCheck: [],
                      externalConditions: [],
                      validationRules: []
                    }
                    const crypto = require('crypto')
                    const id = crypto.randomBytes(20).toString('hex')
                    newElement.id = id
                    toSet.push(newElement)
                    this.props.setFieldValue('created', toSet)
                  }}
                >
                  <Icon>add_box</Icon>
                </IconButton>
              </Grid>
              <div style={{ padding: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={isSubmitting}
                  onClick={() => {
                    this.saveSurvey(values)
                  }}
                >
                  Save
                </Button>
              </div>
            </div>

            <div style={{ margin: 20 }}>
              <Grid container direction='row'>
                <Grid style={{ flex: 1 }}>
                  <MultilanguageTextFieldWithFormik
                    name='titleValue'
                    label={<Trans>Survey Template Title</Trans>}
                    inputDebounce
                  />
                </Grid>
                <Grid style={{ marginTop: 3 }}>
                  <IconButton
                    onClick={() => {
                      // console.log(this.state)
                      this.setState({ extended: !this.state.extended })
                    }}
                  >
                    <Icon>
                      {this.state.extended ? 'expand_less' : 'expand_more'}
                    </Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: 20 }}>
              <Collapse in={this.state.extended}>
                <MultilanguageTextFieldWithFormik
                  name='extendedValue'
                  label={<Trans>Extended description</Trans>}
                  rows={4}
                  multiline
                  inputDebounce
                />
                <div style={{ paddingTop: 20 }}>
                  <MultilanguageTextFieldWithFormik
                    name='tooltipValue'
                    label={<Trans>Survey tooltip</Trans>}
                    rows={4}
                    multiline
                    inputDebounce
                  />
                </div>
              </Collapse>
            </div>
            <div style={{ margin: 20 }}>
              <TextFieldWithFormik
                name='surveyName'
                variant='outlined'
                label={<Trans>Survey Name</Trans>}
                inputProps={{
                  maxLength: 80
                }}
                helperText={countHelperText(values.surveyName, 80)}
                fullWidth
              />
            </div>
            {!isSubtemplate && (
              <div style={{ margin: 20 }}>
                <TextFieldWithFormik
                  InputProps={{ inputComponent: NumericFormat }}
                  name='autosave'
                  variant='outlined'
                  label={<Trans>Autosave time (in minutes)</Trans>}
                  fullWidth
                />
              </div>
            )}
            {scoringCategories.length > 0 && (
              <div style={{ margin: 20 }}>
                <FormikCheckboxGroupField
                  label={<Trans>Categories that can be skipped</Trans>}
                  name='skipSection'
                  multiple
                  options={scoringCategories.map((obj, index) => ({
                    value: obj.value,
                    label: obj[user.language] || obj.en
                  }))}
                />
              </div>
            )}
            <ExternalQuestionDialog
              questionIndex={this.state.externalDialogIndex}
              dialogOpened={
                Boolean(this.state.externalDialogIndex) ||
                this.state.externalDialogIndex === 0
              }
              setDialogOpened={() => {
                this.setState({
                  externalDialogIndex: null,
                  externalDialogClone: null
                })
              }}
              newQuestion={this.state.externalDialogClone}
              values={values}
              saveSurvey={this.saveSurvey}
              userLanguage={this.props.user.language}
              setValues={this.props.setValues}
              setFieldValue={this.props.setFieldValue}
            />
            <SurveyCreationFieldMenu
              index={this.state.menuIndex}
              setFieldValue={this.props.setFieldValue}
              values={values}
              setValues={this.props.setValues}
              anchorEl={this.state.anchorEl}
              setExternalDialogOpened={(index, clone) => {
                this.setState({
                  externalDialogIndex: index,
                  externalDialogClone: clone
                })
              }}
              handleClose={() => {
                this.setState({
                  anchorEl: null,
                  menuIndex: null
                })
              }}
            />
            {values.created?.map((item, key) => {
              return (
                <SurveyCreationField
                  conditionalObjects={conditionalObjects}
                  setValues={this.props.setValues}
                  setAnchorEl={event => {
                    this.setState({
                      anchorEl: event.currentTarget,
                      menuIndex: key
                    })
                  }}
                  shouldSchemaRebuildCallback={() =>
                    this.setState({
                      rebuildSchema: this.state.rebuildSchema + 1
                    })
                  }
                  setFieldValue={this.props.setFieldValue}
                  saveSurvey={this.saveSurvey}
                  user={this.props.user}
                  key={key}
                  index={key}
                  values={values}
                />
              )
            })}
          </Paper>
        }
        right={
          <Paper style={styles.paper} square>
            <Grid
              container
              justify='center'
              alignItems='center'
              style={{ marginTop: 10 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={autoPreview}
                    onChange={event => {
                      this.setState({ autoPreview: event.target.checked })
                    }}
                    name='autoPreview'
                    color='primary'
                  />
                }
                label={
                  <h5 style={{ margin: 0 }}>
                    <Trans>Auto Preview</Trans>
                  </h5>
                }
              />
            </Grid>

            <div>
              {values.created && autoPreview ? (
                <DisplayedSurveyWithSchema
                  rebuildSchema={this.state.rebuildSchema}
                  values={values}
                  setFieldValue={this.props.setFieldValue}
                />
              ) : (
                <div style={{ padding: 15 }}>
                  <Trans>
                    Auto preview has been disabled, finish Your edits on the
                    left and then turn it on with the switch.
                  </Trans>
                </div>
              )}
            </div>
          </Paper>
        }
      />
    ) : (
      <Loading />
    )
  }
}

const DisplayedSurveyWithSchema = props => {
  const { values, rebuildSchema } = props
  const [validationSchema, setValidationSchema] = React.useState()
  const [forceUpdate, setForceUpdate] = React.useState(false)

  useEffect(() => {
    setForceUpdate(!forceUpdate)
  }, [values.version])

  useEffect(() => {
    const yupObj = {}
    values.created.forEach(obj => {
      if (!obj.isRequired) {
        return
      }
      if (obj.type === questionTypes.SELECT_MULTIPLE.id) {
        yupObj[obj.id] = Yup.array()
          .compact()
          .nullable()
          .min(1, questionRequiredTransWithStar)
          .required(questionRequiredTransWithStar)
      } else if (obj.type === questionTypes.INPUT_DATE_RANGE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.mixed()
              .required(dateRangeRequiredTrans)
              .dateRangeRequired(dateRangeRequiredTrans)
          )
        } else {
          yupObj[obj.id] = Yup.mixed()
            .required(dateRangeRequiredTrans)
            .dateRangeRequired(dateRangeRequiredTrans)
        }
      } else if (
        obj.type === questionTypes.INPUT_TEXT.id ||
        obj.type === questionTypes.INPUT_TEXTAREA.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.string().required(questionRequiredTransWithStar)
          )
        } else {
          yupObj[obj.id] = Yup.string().required(questionRequiredTransWithStar)
        }
      } else if (
        obj.type === questionTypes.INPUT_NUMBER.id ||
        obj.type === questionTypes.SLIDER.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.number()
              .transform(v => v || null)
              .nullable()
              .required(questionRequiredTransWithStar)
          )
        } else {
          yupObj[obj.id] = Yup.number()
            .transform(v => v || null)
            .nullable()
            .required(questionRequiredTransWithStar)
        }
      } else if (obj.type === questionTypes.INPUT_DATE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(Yup.mixed().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      } else {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .required(questionRequiredTransWithStar)
            .of(Yup.string().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      }
    })
    values.created.forEach(question => {
      if (question.validationRules) {
        question.validationRules.forEach(rule => {
          if (!rule.type || !rule.parameter) {
            return
          }
          if (yupObj[question.id]) {
            if (question.isGroup) {
              if (questionValidationRules[rule.type].applyToAll) {
                yupObj[question.id] = yupObj[question.id].concat(
                  questionValidationRules[rule.type].rule(rule.parameter)
                )
              } else {
                yupObj[question.id] = yupObj[question.id].concat(
                  Yup.array()
                    .transform(v => (!v ? [] : v))
                    .of(questionValidationRules[rule.type].rule(rule.parameter))
                )
              }
            } else {
              yupObj[question.id] = yupObj[question.id].concat(
                questionValidationRules[rule.type].rule(rule.parameter)
              )
            }
          } else {
            if (question.isGroup) {
              if (questionValidationRules[rule.type].applyToAll) {
                yupObj[question.id] = questionValidationRules[rule.type].rule(
                  rule.parameter
                )
              } else {
                yupObj[question.id] = Yup.array()
                  .transform(v => (!v ? [] : v))
                  .of(questionValidationRules[rule.type].rule(rule.parameter))
              }
            } else {
              yupObj[question.id] = questionValidationRules[rule.type].rule(
                rule.parameter
              )
            }
          }
        })
      }
      if (
        question.maxCharacters &&
        [questionTypes.INPUT_TEXT.id, questionTypes.INPUT_TEXTAREA.id].includes(
          question.type
        )
      ) {
        const rule = Yup.string()
          .ensure()
          .max(question.maxCharacters, ({ max }) =>
            myI18n._(t`This fields length cannot exceed ${max} characters!`)
          )
        if (question.isGroup) {
          if (yupObj[question.id]) {
            yupObj[question.id] = yupObj[question.id].concat(
              Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            )
          } else {
            yupObj[question.id] = Yup.array()
              .transform(v => (!v ? [] : v))
              .nullable()
              .of(rule)
          }
        } else {
          if (yupObj[question.id]) {
            yupObj[question.id] = yupObj[question.id].concat(rule)
          } else {
            yupObj[question.id] = rule
          }
        }
      }
      if (question.type === questionTypes.INPUT_NUMBER.id) {
        if (question.min) {
          const rule = Yup.number().min(question.min, ({ min }) =>
            myI18n._(t`Provided value cannot be lesser than ${min}!`)
          )
          if (question.isGroup) {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(
                Yup.array()
                  .transform(v => (!v ? [] : v))
                  .nullable()
                  .of(rule)
              )
            } else {
              yupObj[question.id] = Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            }
          } else {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(rule)
            } else {
              yupObj[question.id] = rule
            }
          }
        }
        if (question.max) {
          const rule = Yup.number().max(question.max, ({ max }) =>
            myI18n._(t`Provided value cannot be greater than ${max}!`)
          )
          if (question.isGroup) {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(
                Yup.array()
                  .transform(v => (!v ? [] : v))
                  .nullable()
                  .of(rule)
              )
            } else {
              yupObj[question.id] = Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            }
          } else {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(rule)
            } else {
              yupObj[question.id] = rule
            }
          }
        }
      }
    })
    setValidationSchema(Yup.object().shape(yupObj))
  }, [rebuildSchema])

  const initialTouched = {}
  values.created.forEach(q => {
    if (q.isGroup) {
      initialTouched[q.id] = q.groupQuestions.map(sub => true)
    } else {
      initialTouched[q.id] = true
    }
  })

  return (
    <Formik
      initialValues={questionsToInitial(values.created)}
      enableReinitialize
      initialTouched={{
        ...initialTouched
      }}
      touched={initialTouched}
      validationSchema={validationSchema}
    >
      {formikState => (
        <DisplayedSurvey
          formikState={formikState}
          validationSchema={validationSchema}
          {...props}
        />
      )}
    </Formik>
  )
}

const DisplayedSurvey = props => {
  const {
    formikState,
    values,
    setFieldValue,
    validationSchema,
    rebuildSchema
  } = props

  useEffect(() => {
    // const touched = {}
    // values.created.forEach(q => {
    //   if (q.isGroup) {
    //     touched[q.id] = q.groupQuestions.map(sub => true)
    //   } else {
    //     touched[q.id] = true
    //   }
    // })
    // formikState.setTouched(touched)
    formikState.validateForm().then(errors => {
      formikState.setErrors(errors)
    })
  }, [validationSchema])

  // const validateSurveyTab = ({ formikState, disableFields }) => {
  //   const errorsFiltered = {}
  //   try {
  //     validationSchema.validateSync(formikState.values, {
  //       abortEarly: false
  //     })
  //   } catch (err) {
  //     if (err instanceof ValidationError) {
  //       const validationsErrors = err.inner.map(({ path, errors }) => [
  //         path,
  //         errors
  //       ])
  //       console.log('errors', err)
  //       validationsErrors.forEach(([key, value]) => {
  //         if (!disableFields.includes(key)) {
  //           errorsFiltered[key] = value
  //         }
  //       })
  //     }
  //   }
  //   return errorsFiltered
  // }

  const checkQuestionsToDisable = ({ values, formikState }) => {
    const toDisable = []
    const toDisableOptions = {}
    if (values.created) {
      values.created.forEach((item, key) => {
        if (item.conditionsToCheck.length > 0) {
          item.conditionsToCheck.forEach(condition => {
            const addOptionToDisable = (parent, option) => {
              if (!toDisableOptions[parent]) {
                toDisableOptions[parent] = []
              }
              toDisableOptions[parent].push(option)
            }
            if (!condition || condition === {}) {
              return
            } else if (!formikState.values[condition.question]) {
              return
            }
            if (condition.isSelected) {
              if (typeof formikState.values[condition.question] === 'object') {
                if (condition.groupQuestion || condition.groupQuestion === 0) {
                  if (
                    formikState.values[condition.question][
                      condition.groupQuestion
                    ] === condition.option
                  ) {
                    toDisable.push(condition.disableQuestionId)
                  }
                } else if (
                  formikState.values[condition.question][condition.option]
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              } else {
                if (
                  formikState.values[condition.question] === condition.option
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              }
            } else {
              if (typeof formikState.values[condition.question] === 'object') {
                if (condition.groupQuestion || condition.groupQuestion === 0) {
                  if (
                    formikState.values[condition.question][
                      condition.groupQuestion
                    ] !== condition.option
                  ) {
                    toDisable.push(condition.disableQuestionId)
                  }
                } else if (
                  !formikState.values[condition.question][condition.option]
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              } else if (
                formikState.values[condition.question] !== condition.option
              ) {
                toDisable.push(condition.disableQuestionId)
              }
            }
          })
        }
      })
    }
    return { toDisable, toDisableOptions }
  }

  const disableObject = useMemo(
    () => checkQuestionsToDisable({ values, formikState }),
    [formikState.values]
  )

  // const errorsFiltered = useMemo(
  //   () =>
  //     validateSurveyTab({
  //       formikState,
  //       disableFields: disableObject.toDisable
  //     }),
  //   [formikState.values, validationSchema]
  // )

  const toDisable = disableObject.toDisable
  let displayIndex = 0
  useEffect(() => {
    displayIndex = 0
  }, [values.version])

  const _language = values.version

  return (
    <div align='center'>
      <h1 style={{ textAlign: 'center', paddingTop: 20 }}>
        <Trans>Survey Preview</Trans>
      </h1>
      <div style={{ width: '80%' }}>
        <Grid item style={{ width: 150 }}>
          <TextField
            select
            variant='outlined'
            fullWidth
            value={values.version}
            onChange={e => {
              setFieldValue('version', e.target.value)
            }}
            label={<Trans>Language</Trans>}
          >
            {Object.values(portalLanguagesData).map(obj => (
              <MenuItem key={obj.editKey} value={obj.editKey}>
                {obj.labelTrans}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </div>
      <I18nProvider catalogs={catalogs} language={_language}>
        <I18n>
          {({ i18n }) => {
            return (
              <>
                <div style={{ margin: 20 }}>
                  <h1>
                    {getLabelFromTranslationData({
                      langVersion: _language,
                      data: values.titleValue,
                      i18n
                    })}
                  </h1>
                  <Typography>
                    {getLabelFromTranslationData({
                      langVersion: _language,
                      data: values.tooltipValue,
                      i18n
                    })}
                  </Typography>
                </div>
                {values.created
                  ? values.created.map((item, index) => {
                      if (toDisable.includes(item.id)) {
                        return null
                      }
                      if (item.type !== 'HEADER') {
                        displayIndex++
                      }
                      const error = formikState.errors[values.created[index].id]
                      const errorIsArray = Boolean(
                        error && Array.isArray(error)
                      )
                      const nonApplicableValue =
                        values[item.id + nonApplicableSuffix]
                      const disabled = Boolean(
                        nonApplicableValue && !Array.isArray(nonApplicableValue)
                      )
                      const showCheckbox =
                        item.nonApplicable &&
                        !questionTypes[item.type].nonApplicableAsOption

                      const toPass = item
                      if (toPass.options) {
                        toPass.options.forEach(option => {
                          const isTrans = option.text && option.text.id
                          if (!isTrans) {
                            option.text = getLabelFromTranslationData({
                              langVersion: _language,
                              data: option.titleValue,
                              i18n
                            })
                          }
                        })
                      }
                      return (
                        <>
                          <QuestionTitle
                            question={item}
                            errors={formikState.errors}
                            displayIndex={displayIndex}
                            version={_language}
                            i18n={i18n}
                          />
                          <QuestionWidget
                            rebuildSchema={rebuildSchema}
                            disableFields={toDisable}
                            errorIsArray={errorIsArray}
                            version={_language}
                            questionValue={formikState.values[item.id]}
                            setFieldValue={formikState.setFieldValue}
                            disabled={disabled}
                            nonApplicable={nonApplicableValue}
                            question={item}
                            index={item.id}
                            key={index}
                            i18n={i18n}
                          />
                          {showCheckbox && (
                            <Grid
                              container
                              direction='row'
                              alignItems='center'
                              style={{ marginLeft: 20 }}
                              key={item.id}
                            >
                              <FormikCheckboxField
                                style={{ marginLeft: 5 }}
                                name={`${item.id}` + nonApplicableSuffix}
                                row
                                controlLabel={<Trans>Is non applicable</Trans>}
                                onChange={e => {
                                  setFieldValue(
                                    item.id,
                                    questionToInitial(item)[1]
                                  )
                                }}
                              />
                            </Grid>
                          )}
                          <div className='py-12' key={`${index}-spacer`} />
                        </>
                      )
                    })
                  : null}
              </>
            )
          }}
        </I18n>
      </I18nProvider>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withFormikIncluded({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false
  })(withSnackbar(CreateSurvey))
)
