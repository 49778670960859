import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { PostalCodeFormat } from 'app/views/common/Formats'
import { FormikCKEditor } from 'app/views/page-layouts/CKEditor/FormikCKEditor'
import { useField } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { languages } from 'translation/I18nConnectedProvider'
import MUTextField from '../../multiuser/components/MUTextField'

/**
 * Form element which renders a Material UI text input.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {number} [typeProps.maxChar] How many characters can be input in the field.
 * @param  {boolean}  [typeProps.expandable=false] If text input should expand into new lines on new line insert.
 * @param  {number} [typeProps.rows=1] How many rows the text input should occupy.
 * @param  {boolean}  [typeProps.readOnly=false] If field should be render in read only view.
 * @param  {boolean} [typeProps.isAdvancedTextEditor=false] If text input should be rendered as CKEditor, which utilizies more editing and formatting options.
 * @param  {boolean}  [typeProps.wordCount=false] If field shoud render counter for words input.
 * @param  {boolean} [typeProps.isPostalCode=false] If text input should format expecting postal code input.
 * @param  {boolean}  [typeProps.requiresRequest=false] If value of the field shoud be saved separatly from the rest of the form and ask for permission to send saving request.
 * @param  {number} [typeProps.showFieldLabel=false] If form element title should be rendered as label inside of text field.
 */
export const FormTextField = ({
  id,
  useMultiuser,
  muBag,
  connectedObject,
  disabled,
  preview,
  typeProps,
  langVersion,
  title,
  connectedMap,
  ...props
}) => {
  const {
    required,
    maxChar,
    expandable,
    rows = 1,
    readOnly,
    isAdvancedTextEditor,
    allowAddingTables,
    wordCount,
    isPostalCode,
    requiresRequest,
    showFieldLabel
  } = typeProps
  const { length } = props.connectedFieldDetails || {}
  let limit = maxChar || length
  if (limit > length) {
    if (isAdvancedTextEditor) {
      limit = Math.ceil(length / 2 / 10) * 10
    } else {
      limit = length
    }
  }

  const [dialogOpen, openDialog] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [field, meta] = useField(id)
  const value = field.value

  if (readOnly) {
    return <Typography>{value}</Typography>
  }

  const baseProps = {}
  if (isPostalCode) {
    baseProps.inputComponent = PostalCodeFormat
  }

  if (isAdvancedTextEditor) {
    return (
      <FormikCKEditor
        name={id}
        disabled={disabled}
        style={{ marginTop: 5 }}
        label={title}
        maxCharacters={limit && Number(limit)}
        muBag={muBag}
        useMultiuser={useMultiuser}
        required={required}
        wordCount={wordCount}
        allowAddingTables={allowAddingTables}
      />
    )
  }

  return (
    <>
      <Grid container wrap='nowrap'>
        <MUTextField
          useMultiuser={useMultiuser}
          id={id}
          muBag={muBag}
          disabled={disabled}
          label={showFieldLabel && title}
          multiline={expandable || rows > 1}
          minRows={rows}
          maxRows={expandable ? 30 : rows}
          required={required}
          limit={limit}
          wordCount={wordCount}
        />
        {requiresRequest && (
          <Grid
            item
            style={{
              paddingLeft: 10,
              paddingTop: 15,
              minWidth: 160
            }}
          >
            <Button
              disabled={requesting || !value || meta.error}
              color='primary'
              variant='contained'
              onClick={e => {
                setRequesting(true)
                const conn = SFAuthService.getConnection()
                if (
                  conn &&
                  connectedObject &&
                  Array.isArray(typeProps.connectedTo)
                ) {
                  const sending = enqueueSnackbar(
                    <Trans>Sending request</Trans>,
                    {
                      variant: 'info'
                    }
                  )
                  const promises = typeProps.connectedTo.map(connected => {
                    const { connectedObject, connectedField } = connected
                    let sfObject = connectedMap[connectedObject].sfObject
                    let fieldName = connectedField?.name
                    if (connectedField && fieldName.indexOf('.') !== '-1') {
                      const subObjectName = fieldName.split('.')[0]
                      const subFieldName = fieldName.split('.')[1]
                      if (subObjectName && subFieldName) {
                        fieldName = subFieldName
                        sfObject = sfObject[subObjectName]
                      }
                    }
                    const toUpdate = {
                      Id: sfObject.Id
                    }
                    toUpdate[fieldName] = value
                    return conn
                      .sobject(sfObject.attributes.type)
                      .update(toUpdate)
                  })
                  Promise.all(promises)
                    .then(r => {
                      openDialog(true)
                      setRequesting(false)
                      closeSnackbar(sending)
                    })
                    .catch(err => {
                      closeSnackbar(sending)
                      setRequesting(false)
                      console.error(err)
                    })
                } else {
                  setRequesting(false)
                  enqueueSnackbar(
                    <Trans>There is no field connected to this element!</Trans>,
                    {
                      variant: 'error'
                    }
                  )
                }
              }}
            >
              <Trans>Request change</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
      {requiresRequest && (
        <Dialog open={Boolean(dialogOpen)}>
          <DialogTitle>
            <Trans>Check your email for confirmation</Trans>
          </DialogTitle>
          <DialogContent>
            {title && (
              <Typography>
                <Trans>
                  To change {title.toLowerCase()} you have to click confirmation
                  link which was send to you via email
                </Trans>
              </Typography>
            )}
            <Grid container justify='center' style={{ padding: 15 }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  openDialog(false)
                }}
              >
                <Trans>Ok</Trans>
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export const formTextFieldValueToText = (value, question, object) => {
  const { isAdvancedTextEditor } = question.typeProps
  if (isAdvancedTextEditor || /<\/?[a-z][\s\S]*>/i.test(value)) {
    const h2p = require('html2plaintext')
    const parsed = h2p(value)
    const toRet = languages.reduce((acc, lang) => {
      acc[lang] = parsed
      return acc
    }, {})
    return toRet
  }
  const toRet = languages.reduce((acc, lang) => {
    acc[lang] = value || ''
    return acc
  }, {})
  return toRet
}
