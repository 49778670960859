import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { useDispatch } from 'react-redux'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormImage } from './FormImage'
import DebouncedTextField from '../../common/DebouncedTextField'

export const FormEditorImage = ({
  typeProps = {},
  id,
  showPrintProps,
  showPdfProps,
  injectable,
  connectedObject,
  langVersion,
  depth,
  formId,
  networkId,
  editMode,
  ...props
}) => {
  const { imgLink, width, height, percent, centerImage } = typeProps
  const dispatch = useDispatch()

  //   const handleOnDrop = ({ files }) => {
  //     console.log('handleOnDrop', files)
  //     const file = files[0]
  //     const toSet = { ...typeProps }
  //     toSet.file = {
  //       uploading: true,
  //       name: file.name
  //     }
  //     dispatch({
  //       type: 'FIELD',
  //       depth: depth.split('.'),
  //       fieldName: 'typeProps',
  //       fieldValue: { ...toSet }
  //     })
  //     const reader = new FileReader()
  //     reader.onabort = () => console.log('file reading was aborted')
  //     reader.onerror = () => console.log('file reading has failed')
  //     reader.onload = () => {
  //       const binaryStr = reader.result
  //       console.log('binary', binaryStr)
  //       enqueueSnackbar(<Trans>Uploading File</Trans>, {
  //         variant: 'info'
  //       })
  //       uploadFile(
  //         {
  //           name: file.name,
  //           tags: id,
  //           uploadId: formId
  //         },
  //         binaryStr,
  //         networkId
  //       )
  //         .then(res => {
  //           console.log(res)
  //           enqueueSnackbar(<Trans>Uploaded File</Trans>, {
  //             variant: 'success'
  //           })
  //           const toSet = { ...typeProps }
  //           toSet.file = parseDocument(res[0])
  //           dispatch({
  //             type: 'FIELD',
  //             depth: depth.split('.'),
  //             fieldName: 'typeProps',
  //             fieldValue: { ...toSet }
  //           })
  //         })
  //         .catch(error => {
  //           console.warn('cant upload file', error)
  //           enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
  //             variant: 'error'
  //           })
  //           const toSet = { ...typeProps }
  //           delete toSet.file
  //           dispatch({
  //             type: 'FIELD',
  //             depth: depth.split('.'),
  //             fieldName: 'typeProps',
  //             fieldValue: { ...toSet }
  //           })
  //         })
  //     }
  //     reader.readAsArrayBuffer(file)
  //   }

  //   const onRemoveItem = ({ id }) => {
  //     enqueueSnackbar(<Trans>Deleting File</Trans>, {
  //       variant: 'info'
  //     })
  //     deleteDocumentByFlow(id).then(result => {
  //       enqueueSnackbar(<Trans>Deleted File</Trans>, {
  //         variant: 'success'
  //       })
  //       const toSet = { ...typeProps }
  //       delete toSet.file
  //       dispatch({
  //         type: 'FIELD',
  //         depth: depth.split('.'),
  //         fieldName: 'typeProps',
  //         fieldValue: { ...toSet }
  //       })
  //     })
  //   }

  if (!editMode) {
    return <FormImage preview {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <DebouncedTextField
        label={<Trans>Link to image</Trans>}
        fullWidth
        value={imgLink || ''}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.imgLink = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <Grid container style={{ paddingTop: 10 }}>
        <Grid item xs style={{ paddingRight: 4 }}>
          <DebouncedTextField
            label={<Trans>Width</Trans>}
            fullWidth
            value={width || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>px</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              delete toSet.percent
              if (e.target.value) {
                toSet.width = e.target.value
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ paddingLeft: 4 }}>
          <DebouncedTextField
            label={<Trans>Height</Trans>}
            fullWidth
            value={height || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>px</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.height = e.target.value
              if (e.target.value) {
                delete toSet.percent
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ paddingLeft: 4 }}>
          <DebouncedTextField
            label={<Trans>Percent of original size</Trans>}
            fullWidth
            value={percent || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>%</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.percent = e.target.value
              if (e.target.value) {
                delete toSet.width
                delete toSet.height
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(centerImage)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.centerImage = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Center image</Trans>}
      />
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
      {/*
        TODO faraon - uploading file to use in pdf
        <Dropzone
          accept='image/jpeg,image/png,image/svg'
          multiple={false}
          maxFiles={1}
          disabled={file}
          onDrop={files => {
            handleOnDrop({
              files
            })
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={file ? classes.disabled : classes.dropbox}
            >
              <section>
                <div>
                  <input {...getInputProps()} />
                  <div
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justify='center'
                    >
                      <Icon style={{ marginRight: 10 }}>upload</Icon>
                      <Trans>
                        Drag 'n' drop file here, or click to select file
                      </Trans>
                    </Grid>
                  </div>
                </div>
                <div />
              </section>
            </div>
          )}
        </Dropzone>
        {file && (
          <>
            <Grid
              container
              direction='row'
              wrap='nowrap'
              justify='space-between'
              alignItems='center'
              style={{ marginBottom: 5 }}
            >
              <Grid item>{file.name}</Grid>
              <Grid item>
                <IconButton
                  disabled={file.uploading}
                  onClick={e => {
                    onRemoveItem({ id: file.id })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            {file.uploading && <LinearProgress />}
          </>
        )} */}
    </div>
  )
}
