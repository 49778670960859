import { Slider } from '@material-ui/core'
import { useState } from 'react'
import { FormErrorLabel } from '../../../common/labels/FormErrorLabel'

export function FormNumericSliderPreview ({
  id,
  useMultiuser,
  muBag,
  disabled,
  typeProps
}) {
  const [value, setValue] = useState(null)
  const [tempValue, setTempValue] = useState(null)
  const { required, max, min, step = 1 } = typeProps
  const minValue = min || 0
  const maxValue = max || 100

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Slider
        className={!value && 'slider-muted'}
        style={{
          color:
            !value && value !== 0
              ? '#A5C9D3'
              : disabled
              ? 'rgba(0, 0, 0, 0.26)'
              : '#10708A'
        }}
        onChangeCommitted={(event, newValue) => {
          setValue(newValue)
          setTempValue(null)
        }}
        onChange={(event, newValue) => {
          setTempValue(newValue)
        }}
        value={tempValue || tempValue === 0 ? tempValue : value}
        step={Number(step)}
        disabled={disabled}
        min={Number(minValue)}
        max={Number(maxValue)}
        marks={[
          { value: minValue, label: minValue },
          { value: maxValue, label: maxValue }
        ]}
        valueLabelDisplay='auto'
      />
      <FormErrorLabel id={id} required={required} />
    </div>
  )
}
