import moment from 'moment'
import SFAuthService, { NO_USER } from '../SFAuthService'

export interface BuildingItem {
  Id: string,
  Sources_and_providers__c?: string | null,
  Vulnerable_Groups__c?: string | null,
  Province__c?: string | null,
  Zip__c?: string | null,
  Street__c?: string | null,
  City__c?: string | null,
  Street_Number__c?: string | null,
  Households_with_rental__c?: number | null,
  Units_Eligible__c?: number | null,
  Units_Total__c?: number | null,
  Expiry_Date__c?: Date | null,
  FCHI_accommodations_offered__c?: string | null
  Number_of_stories__c?: number | null,
  Property_management_type__c?: string | null,
  Building_condition__c?: string | null,
  Number_of_vacant_bachelor_units__c?: number | null,
  Number_of_occupied_bachelor_units__c?: number | null,
  Number_of_vacant_1_bedroom_units__c?: number | null,
  Number_of_occupied_1_bedroom_units__c?: number | null,
  Number_of_vacant_2_bedroom_units__c?: number | null,
  Number_of_occupied_2_bedroom_units__c?: number | null,
  Number_of_vacant_3_bedroom_units__c?: number | null,
  Number_of_occupied_3_bedrooms_units__c?: number | null,
  Number_of_vacant_4_bedroom_units__c?: number | null,
  Number_of_occupied_4_bedrooms_units__c?: number | null,
  Number_of_accessible_barrier_free_units__c?: number | null
  Number_of_elevators__c?: number | null,
  Number_of_parking_spaces__c?: number | null,
  Percentage_of_barrier_free_common_areas__c?: number | null
}

export interface BuildingItemParsed {
  id: string,
  sourcesAndProviders?: { date: moment.Moment | null, name: string }[],
  vulnerableGroups?: string[],
  province?: string | null,
  zipCode?: string | null,
  street?: string | null,
  city?: string | null,
  streetNumber?: string | null,
  otherSubsidyUnits?: number | null,
  unitsEligible?: number | null,
  unitsTotal?: number | null,
  expiryDate?: Date | null,
  accommodationsOffered?: string | null,
  providers?: { date: moment.Moment | null, name: string }[]
  numberOfStories?: number | null,
  propertyManagementType?: string | null,
  buildingCondition?: string | null,
  numberOfVacantBachelorUnits?: number | null,
  numberOfOccupiedBachelorUnits?: number | null,
  numberOfVacant1BedroomUnits?: number | null,
  numberOfOccupied1BedroomUnits?: number | null,
  numberOfVacant2BedroomUnits?: number | null,
  numberOfOccupied2BedroomUnits?: number | null,
  numberOfVacant3BedroomUnits?: number | null,
  numberOfOccupied3BedroomsUnits?: number | null,
  numberOfVacant4BedroomUnits?: number | null,
  numberOfOccupied4BedroomsUnits?: number | null,
  numberOfAccessibleBarrierFreeUnits?: number | null,
  numberOfElevators?: number | null,
  numberOfParkingSpaces?: number | null,
  percentageOfBarrierFreeCommonAreas?: number | null
}

/**
 * Retrieves buildings associated with a specific Opportunity from Salesforce.
 * @function
 * @category Salesforce - Building__c
 * @param {string} id - The id of the opportunity to retrieve associated buildings for.
 * @returns {Promise<object[]>}
 */
export const getBuildings = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Building__c').find({
    Opportunity__c: id
  })
}

interface CreateBuildingsProps {
  buildings: BuildingItem[]
  opportunityId?: string
  prequalificationId?: string
}

/**
 * Creates buildings in Salesforce.
 * @function
 * @category Salesforce - Building__c
 * @param {CreateBuildingsProps} props - The properties to create the buildings with.
 * @returns {Promise<object[]>}
 */
export const createBuildings = ({buildings, opportunityId, prequalificationId}: CreateBuildingsProps): Promise<object[]> => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const records = buildings.map((building) => {
    return {
      Opportunity__c: opportunityId,
      Pre_Qualification__c: prequalificationId,
      ...building
    }
  })
  return conn.sobject('Building__c').create(records)
}


interface DeleteBuildingsProps {
  opportunityId?: string
  prequalificationId?: string
}

/**
 * Deletes buildings in Salesforce based on opportunityId or prequalificationId.
 * @function
 * @category Salesforce - Building__c
 * @param {DeleteBuildingsProps} props - The properties containing opportunityId or prequalificationId.
 * @returns {Promise<object[]>}
 */
export const deleteBuildings = async ({ opportunityId, prequalificationId }: DeleteBuildingsProps): Promise<object[]> => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  // Build a query filter based on the provided parameters
  let filterCriteria = {} as { [key: string]: string };
  if (opportunityId) {
    filterCriteria.Opportunity__c = opportunityId;
  }
  if (prequalificationId) {
    filterCriteria.Pre_Qualification__c = prequalificationId;
  }

  // Query to get the Building records matching the filter criteria
  const records = await conn.sobject('Building__c').find(filterCriteria, 'Id');

  // Check if there are records to delete
  if (records.length === 0) {
    return Promise.resolve([]); // No records found
  }

  // Extract Ids for deletion
  const recordIds = records.map(record => record.Id);

  // Delete the records
  return conn.sobject('Building__c').del(recordIds);
}

/**
 * Parses a building object retrieved from Salesforce.
 * @function
 * @category Salesforce - Building__c
 * @param {BuildingItem} item - The building object to be parsed.
 * @returns {BuildingItemParsed} The parsed building object with specific properties extracted and formatted.
 */
export const parseBuilding = (item: BuildingItem) => {
  const sourcesAndProvidersToSet: { date: moment.Moment | null; name: string }[] = []
  if (item.Sources_and_providers__c) {
    const itemsArray = item.Sources_and_providers__c.split('/n')
    itemsArray.forEach((item, index) => {
      if (index + 1 === itemsArray.length) {
        return
      }
      const name = item.split(';;;')[0]
      const date =
        item.split(';;;')[1] !== 'null'
          ? moment.utc(item.split(';;;')[1])
          : null
      sourcesAndProvidersToSet.push({ date: date, name: name })
    })
  }

  return {
    id: item.Id,
    providers: sourcesAndProvidersToSet,
    vulnerableGroups: item.Vulnerable_Groups__c
      ? item.Vulnerable_Groups__c.split(';')
      : [],
    province: item.Province__c || '',
    zipCode: item.Zip__c,
    street: item.Street__c,
    city: item.City__c,
    streetNumber: item.Street_Number__c,
    otherSubsidyUnits: item.Households_with_rental__c,
    unitsEligible: item.Units_Eligible__c,
    unitsTotal: item.Units_Total__c,
    expiryDate: item.Expiry_Date__c,
    accommodationsOffered: item.FCHI_accommodations_offered__c
  }
}
