import { Trans } from '@lingui/macro'
import { Alert, AlertTitle } from '@material-ui/lab'

export const prequalificationStageToMessages = {
  New: {
    button: <Trans>START_PREQUALIFICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>
  },
  'In Progress': {
    button: <Trans>CONTINUE_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => (
      <span>
        <Trans>STATUS</Trans>: {status}
      </span>
    )
  },
  Inactive: {
    button: <Trans>START_PREQUALIFICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>
  },
  Submitted: {
    button: <Trans>VIEW_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => <Trans>PREQUALIFICATION_IS_REVIEWED</Trans>
  },
  Hold: {
    button: <Trans>VIEW_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => <Trans>PREQUALIFICATION_IS_REVIEWED</Trans>
  },
  'First Review': {
    button: <Trans>VIEW_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => <Trans>PREQUALIFICATION_IS_REVIEWED</Trans>
  },
  'More info required': {
    button: <Trans>CONTINUE_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => (
      <span>
        <Trans>STATUS</Trans>: {status}
      </span>
    )
  },
  'Depth Review': {
    button: <Trans>VIEW_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => <Trans>PREQUALIFICATION_IS_REVIEWED</Trans>
  },
  'Director Approval': {
    button: <Trans>VIEW_PREQUALIFICATION</Trans>,
    redirect: true,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>,
    showStatus: status => <Trans>PREQUALIFICATION_IS_REVIEWED</Trans>
  },
  Approved: {
    button: <Trans>START_APPLICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_COMPLETED</Trans>
  },
  Expired: {
    redirect: true,
    button: <Trans>UPDATE_PREQUALIFICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_EXPIRED</Trans>
  },
  Declined: {
    button: <Trans>START_PREQUALIFICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>
  },
  Withdrawn: {
    button: <Trans>START_PREQUALIFICATION</Trans>,
    infoBox: <Trans>PREQUALIFICATION_REQUIRED_FOR_FUND</Trans>
  }
}

export const PrequalificationFundBanner = ({ currentStage = 'New' }) => {
  const info = prequalificationStageToMessages[currentStage]

  return (
    <Alert severity='info'>
      <AlertTitle>{info.infoBox}</AlertTitle>
      {info.showStatus && info.showStatus(currentStage)}
    </Alert>
  )
}
