import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { FormSaveButton } from './FormSaveButton'

export const FormEditorSaveButton = ({
  editMode,
  depth,
  typeProps = {},
  injectable,
  ...props
}) => {
  const { showAllErrors, showSectionErrors } = typeProps
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormSaveButton {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showAllErrors)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.showAllErrors = e.target.checked
                delete toSet.showSectionErrors
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Show all errors?</Trans>}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showSectionErrors)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.showSectionErrors = e.target.checked
                delete toSet.showAllErrors
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Show errors from current section?</Trans>}
        />
      </div>
    </div>
  )
}
