import { TextField, Typography, makeStyles, useTheme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { requiredTrans } from 'app/views/forms/formTranslations'
import { Field } from 'formik'
import { boundingExtent } from 'ol/extent'
import 'ol/ol.css'
import { fromLonLat } from 'ol/proj'
import React from 'react'
import { useSelector } from 'react-redux'
import { RFeature, RLayerStamen, RLayerVector, RMap } from 'rlayers'
import { RCircle, RFill, RStroke, RStyle, RText } from 'rlayers/style'
import {
  ListboxComponent,
  canadaCenter,
  geoData,
  renderGeohraphicalOptionsGroup
} from './FormCensusDivision'

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export const AutocompleteMuncipalities = ({
  onlyProvinces,
  id,
  avaliableProvinces,
  scopeType,
  onBlur = () => {},
  onFocus = () => {},
  ...props
}) => {
  const classes = useStyles()
  const langVersion = useSelector(state => state.user.language)

  /** flag to check if the select panel is closed */
  const [closed, setClosed] = React.useState(false)

  return (
    <Field name={id + '.selectedScope'}>
      {({ field, meta }) => {
        return (
          <Autocomplete
            id={id + '.selectedScope'}
            name={id + '.selectedScope'}
            fullWidth
            disableListWrap
            disableCloseOnSelect={false}
            options={Object.keys(geoData)
              .filter(item => {
                if (
                  item.includes('_province') ||
                  item.includes('_cenzus') ||
                  item === 'Canada'
                ) {
                  return false
                }
                if (avaliableProvinces && avaliableProvinces.length > 0) {
                  const provinceAbbrevation = geoData[item].province
                  return avaliableProvinces.includes(provinceAbbrevation)
                }
                return true
              })
              .sort((a, b) => a.localeCompare(b))}
            filterSelectedOptions
            classes={classes}
            ListboxComponent={ListboxComponent}
            renderGroup={renderGeohraphicalOptionsGroup}
            onChange={(e, value) => {
              props.onChange(e, value)
            }}
            onFocus={onFocus}
            onClose={e => {
              props?.onClose(e)
              setClosed(true)
            }}
            onBlur={e => {
              field.onBlur(e)
              if (closed) {
                props?.onClose(e)
              }
            }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  error={meta.touched && meta.error}
                  helperText={
                    meta.touched && meta.error ? meta.error : requiredTrans
                  }
                  inputProps={{
                    ...params.inputProps,
                    value:
                      getLabelFromTranslationData({
                        data: geoData[params.inputProps.value]?.muncipalLabel,
                        langVersion
                      }) || params.inputProps.value
                  }}
                  variant='outlined'
                />
              )
            }}
            renderOption={option => (
              <Typography noWrap>
                {getLabelFromTranslationData({
                  data: geoData[option]?.label,
                  langVersion
                })}
              </Typography>
            )}
            {...props}
          />
        )
      }}
    </Field>
  )
}

export const MunicipalMapComponent = React.memo(
  ({ value, addValue, getAvaliableCities }) => {
    return (
      <>
        <RMap
          width={'100%'}
          height={'700px'}
          initial={{ center: canadaCenter, zoom: 4 }}
          extent={boundingExtent([
            fromLonLat([-143, 90]),
            fromLonLat([-50, 40])
          ])}
        >
          <RLayerStamen layer='toner' properties={{ label: 'toner' }} />
          <MuncipalCitiesLayer
            value={value}
            addValue={addValue}
            getAvaliableCities={getAvaliableCities}
          />
        </RMap>
      </>
    )
  }
)

export const MuncipalCitiesLayer = React.memo(
  ({ value, getAvaliableCities }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary

    const feature = getAvaliableCities().filter(feature =>
      value.includes(feature.values_.name + '_city')
    )[0]

    return (
      <RLayerVector zIndex={5}>
        {feature && (
          <RFeature geometry={feature.values_.geometry}>
            <RStyle>
              <RCircle radius={8}>
                <RFill color={dark} />
              </RCircle>
              <RText text={feature.values_.name}>
                <RFill color='#fff' />
                <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
              </RText>
            </RStyle>
          </RFeature>
        )}
      </RLayerVector>
    )
  }
)
