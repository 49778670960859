import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { dateFormat } from 'app/appSettings'
import { useFormikContext } from 'formik'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import CustomDatePicker from '../../common-components/CustomDatePicker'
import { getLabelFromTranslationData } from '../../common/TranslationsCommon'
import ConfigureMultilanguageTextField from '../../internal/ConfigureMultilanguageTextField'
import { TooltipLabelIcon } from '../../page-layouts/TooltipLabelIcon'
import { formComponentTypes } from '../components/formComponentTypes'
import grantsRoutes from 'app/views/grants/GrantsRoutes'
import dmahRoutes from 'app/views/internal/DMAH/dmahRoutes'
import formsRoutes from '../FormsRoutes'
import surveyRoutes from 'app/views/surveys/SurveyRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import internalRoutes from 'app/views/internal/InternalRoutes'
import utilitiesRoutes from 'app/views/utilities/UtilitiesRoutes'
import vivreEnVilleRoutes from 'app/views/vivre-en-ville/VivreEnVilleRoutes'

/** arrayConditions - conditions for array of values
 * it is used to populate the arrayCondition select field in ConditionalElementEditor
 * when form element is an array of values
 * rule is a function to test the condition for every value of the array
 * That function takes 3 arguments:
 * testValue - array of values
 * testRule - function to test the condition
 * conditionParameter - parameter of the condition to match with the value
 */
export const collectionConditions = {
  any: {
    text: <Trans>Any items field</Trans>,
    rule: ({ testValue, testRule, conditionParameter }) =>
      testValue?.some(value => testRule(conditionParameter, value))
  },
  none: {
    text: <Trans>None of the items field</Trans>,
    rule: ({ testValue, testRule, conditionParameter }) =>
      !testValue?.some(value => testRule(conditionParameter, value))
  },
  all: {
    text: <Trans>All of the items fields</Trans>,
    rule: ({ testValue, testRule, conditionParameter }) =>
      testValue?.every(value => testRule(conditionParameter, value))
  }
}

export const formConditions = {
  textInputArray: {
    conditions: {
      isEmpty: {
        text: <Trans>Is empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length === 0
          } else {
            return !v
          }
        }
      },
      isNotEmpty: {
        text: <Trans>Is not empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length > 0
          } else {
            return v
          }
        }
      },
      inc: {
        text: <Trans>Includes</Trans>,

        rule: (n, v) => v && v.includes(n)
      },
      exc: {
        text: <Trans>Not includes</Trans>,
        rule: (n, v) => v && !v.includes(n)
      }
    }
  },
  picklist: {
    conditions: {
      isEmpty: {
        manualFillText: t`PDF_CONDITION_PICKLIST_NOTHING_SELECTED`,
        text: <Trans>Is empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length === 0
          } else {
            return !v
          }
        }
      },
      isNotEmpty: {
        manualFillText: t`PDF_CONDITION_PICKLIST_AT_LEAST_ONE_OPTION_SELECTED`,
        text: <Trans>Is not empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length > 0
          } else {
            return v
          }
        }
      },
      inc: {
        manualFillText: t`PDF_CONDITION_PICKLIST_OPTION_SELECTED`,
        text: item => {
          const multi =
            item.type === 'multipicklist' ||
            item.elementProps?.picklistType === 'multiselect'
          return multi ? <Trans>Includes</Trans> : <Trans>Equals</Trans>
        },
        rule: (n, v) => v && v.includes(n)
      },
      exc: {
        manualFillText: t`PDF_CONDITION_PICKLIST_OPTION_NOT_SELECTED`,
        text: item => {
          const multi =
            item.type === 'multipicklist' ||
            item.elementProps?.picklistType === 'multiselect'
          return multi ? <Trans>Not includes</Trans> : <Trans>Not equals</Trans>
        },
        rule: (n, v) => v && !v.includes(n)
      },
      indigenous: {
        text: <Trans>Indigenous(special)</Trans>,
        deprecated: true,
        rule: (n, v) =>
          v &&
          (v.includes('First Nations') ||
            v.includes('Inuit') ||
            v.includes('Métis') ||
            v.includes('All Indigenous Peoples'))
      }
    }
  },
  datePicker: {
    conditions: {
      isAfter: {
        manualFillText: t`PDF_CONDITION_PROVIDED_DATE_IS_AFTER`,
        text: <Trans>Is after</Trans>,
        rule: (n, v) => {
          return n && v && moment(moment.utc(v)).isAfter(moment.utc(n))
        }
      },
      isBefore: {
        manualFillText: t`PDF_CONDITION_PROVIDED_DATE_IS_BEFORE`,
        text: <Trans>Is before</Trans>,
        rule: (n, v) => {
          return n && v && moment(moment.utc(v)).isBefore(moment.utc(n))
        }
      }
    }
  },
  bool: {
    conditions: {
      isTrue: {
        manualFillText: t`PDF_CONDITION_BOOL_IS_SELECTED`,
        text: <Trans>Is selected</Trans>,
        rule: (n, v) => Boolean(v)
      },
      isFalse: {
        manualFillText: t`PDF_CONDITION_BOOL_IS_NOT_SELECTED`,
        text: <Trans>Is not selected</Trans>,
        rule: (n, v) => !v
      }
    }
  },
  textInput: {
    conditions: {
      eq: {
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => n === v
      },
      neq: {
        text: <Trans>Is not equal to</Trans>,
        rule: (n, v) => n !== v
      },
      contains: {
        text: <Trans>Contains</Trans>,
        rule: (n, v) => String(v).includes(n)
      },
      doesNotcontain: {
        text: <Trans>Does not contain</Trans>,
        rule: (n, v) => !String(v).includes(n)
      }
    }
  },
  textInputNumeric: {
    conditions: {
      eq: {
        manualFillText: t`PDF_CONDITION_NUMBER_EQUAL_TO`,
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => Number(n) === Number(v)
      },
      lt: {
        manualFillText: t`PDF_CONDITION_NUMBER_LESS_THAN`,
        text: <Trans>Is less than</Trans>,
        rule: (n, v) => Number(v) < Number(n)
      },
      lte: {
        manualFillText: t`PDF_CONDITION_NUMBER_LESS_THAN_OR_EQUAL`,
        text: <Trans>Is less than or equal to</Trans>,
        rule: (n, v) => Number(v) <= Number(n)
      },
      gt: {
        manualFillText: t`PDF_CONDITION_NUMBER_GREATER_THAN`,
        text: <Trans>Is greater than</Trans>,
        rule: (n, v) => Number(v) > Number(n)
      },
      gte: {
        manualFillText: t`PDF_CONDITION_NUMBER_GREATER_THAN_OR_EQUAL`,
        text: <Trans>Is greater than or equal to</Trans>,
        rule: (n, v) => Number(v) >= Number(n)
      }
    }
  },
  numericSlider: {
    conditions: {
      eq: {
        manualFillText: t`PDF_CONDITION_NUMBER_EQUAL_TO`,
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => Number(n) === Number(v)
      },
      lt: {
        manualFillText: t`PDF_CONDITION_NUMBER_LESS_THAN`,
        text: <Trans>Is less than</Trans>,
        rule: (n, v) => Number(v) < Number(n)
      },
      lte: {
        manualFillText: t`PDF_CONDITION_NUMBER_LESS_THAN_OR_EQUAL`,
        text: <Trans>Is less than or equal to</Trans>,
        rule: (n, v) => Number(v) <= Number(n)
      },
      gt: {
        manualFillText: t`PDF_CONDITION_NUMBER_GREATER_THAN`,
        text: <Trans>Is greater than</Trans>,
        rule: (n, v) => Number(v) > Number(n)
      },
      gte: {
        manualFillText: t`PDF_CONDITION_NUMBER_GREATER_THAN_OR_EQUAL`,
        text: <Trans>Is greater than or equal to</Trans>,
        rule: (n, v) => Number(v) >= Number(n)
      }
    }
  },
  otherGrants: {
    conditions: {
      isEmpty: {
        manualFillText: t`PDF_CONDITION_NOT_FILLED`,
        text: <Trans>Is empty</Trans>,
        rule: (n, v) => v.length === 0
      },
      isNotEmpty: {
        manualFillText: t`PDF_CONDITION_FILLED`,
        text: <Trans>Is not empty</Trans>,
        rule: (n, v) => v.length > 0
      }
    }
  }
}

export const specialFormConditions = {
  noErrors: 'If form has no errors',
  errorsPresent: 'If there are errors present in form',
  inRoute: myI18n._(t`CONDITIONAL_ELEMENT_EDITOR_IN_ROUTE_CONDITION`),
  notInRoute: myI18n._(t`CONDITIONAL_ELEMENT_EDITOR_NOT_IN_ROUTE_CONDITION`),
}

export const routeOptions = [
  ...grantsRoutes,
  ...dmahRoutes,
  ...formsRoutes,
  ...surveyRoutes,
  ...sessionRoutes,
  ...internalRoutes,
  ...utilitiesRoutes,
  ...vivreEnVilleRoutes
].filter(route => route.isConditionable)

export const sfFieldToConditionType = {
  id: 'textInput',
  double: 'textInputNumeric',
  currency: 'textInputNumeric',
  int: 'textInputNumeric',
  phone: 'textInput',
  string: 'textInput',
  textarea: 'textInput',
  picklist: 'picklist',
  multipicklist: 'picklist',
  percent: 'textInputNumeric',
  date: 'datePicker',
  datetime: 'datePicker',
  email: 'textInput',
  url: 'textInput',
  boolean: 'bool'
}

const standardConditionStates = [
  {
    value: 'hide',
    label: <Trans>Hide this element</Trans>,
    labelSection: <Trans>Hide this section</Trans>
  },
  {
    value: 'show',
    label: <Trans>Show this element</Trans>,
    labelSection: <Trans>Show this section</Trans>
  },
  {
    value: 'disable',
    labelSection: <Trans>CONDITIONAL_ELEMENT_EDITOR_DISABLE_SECTION_MENU_ITEM</Trans>
  },
  {
    value: 'altLabel',
    label: <Trans>Show alternative label</Trans>
  },
  {
    value: 'altHelpText',
    label: <Trans>Show alternative help text</Trans>,
    forSingleElements: true
  },
  {
    value: 'required',
    label: <Trans>Make element required</Trans>,
    forSingleElements: true
  },
  {
    value: 'notRequired',
    label: <Trans>Make element not required</Trans>,
    forSingleElements: true
  }
]

const ConditionalElementEditor = ({
  langVersion = 'en',
  subIndex,
  id,
  depth,
  conditions = [],
  elementType,
  item = {},
  ...props
}) => {
  const { values } = useFormikContext()
  /** get ids of connected objects to the form */
  const connectedObjectIds = props?.typeProps?.connectedTo?.map(
    obj => obj.connectedObject
  )
  /** get types of connected objects to the form */
  const connectedObjectTypes = values.objectsConnected?.map(obj => obj.type)
  /** get connected objects data with relatedCollections */
  const connectedObjectsWithRelatedCollections = values.objects.filter(
    obj =>
      connectedObjectTypes?.includes(obj.name) &&
      obj.relatedCollections?.length > 0
  )
  /** get relatedCollections of connected objects to the form */
  const relatedCollections = connectedObjectsWithRelatedCollections
    ?.map(obj => {
      const objConnectedName = values.objectsConnected.find(
        objConnected => objConnected.type === obj.name
      )?.name
      return obj.relatedCollections.map(collection => ({
        ...collection,
        connectedObject: obj.name,
        isCollection: true,
        label: `[${objConnectedName}] ${collection.label} (child objects)`,
        collectionKey: collection.key,
        id: `${obj.name}//${collection.key}`
      }))
    })
    .flat()

  let position = 1 // position of the element in the form editor

  const { elements } = item
  /** mapElements is a helper function returns an array of all elements of the all sections of the data function argument */
  const mapElements = data => {
    const returnArray = []
    data.sections.forEach((section, sectionIndex) =>
      section.elements.forEach(item => {
        if (
          elementType !== 'section' ||
          (elementType === 'section' && sectionIndex !== +depth)
        ) {
          mapItem({ item, returnArray, section })
          position++
        }
      })
    )

    return returnArray
  }

  /** mapItem is a helper recursive function to return an array of elements of the form editor tree
   * which type is included in the formConditions object keys or in the collectionElementTypes object keys.
   * This function maps item in the form tree
   * and pushes it to the returnArray if it does not contain elements
   * or call mapItem recursively if it contains elements. */
  const mapItem = ({ item, returnArray, section }) => {
    /** if item contains elements call mapItem recursively for each element */
    if (item.elements) {
      position++
      item.elements.forEach(element =>
        mapItem({ item: element, returnArray, section })
      )
    } else {
      if (
        // id !== item.id &&
        /** check if item type is included in the formConditions object keys */
        Object.keys(formConditions).includes(item.elementType)
      ) {
        const title = getLabelFromTranslationData({
          langVersion,
          data: item.title
        })
        const sectionName = getLabelFromTranslationData({
          langVersion,
          data: section.title
        })
        returnArray.push({
          id: item.id,
          picklistValues: item.typeProps.options,
          elementProps: {
            picklistType: item.typeProps.picklistType
          },
          type: item.elementType,
          label: `[${sectionName}] ${position}. ${title}`
        })
      }
    }
  }

  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const userLanguage = useSelector(state => state.user.language)

  const getTranslatableLabel = obj => {
    const objTitle = getLabelFromTranslationData({
      langVersion: userLanguage?.split('_')[0],
      data: obj.title
    })
    if (objTitle) {
      return objTitle
    } else {
      return myI18n?._(t`[EMPTY LABEL]`)
    }
  }
  const isSub = !isNaN(subIndex)

  const { objectsConnected, objects } = values

  /** avaliableObjectsMap is a hash map of the sf objects connected to the form with keys are the object names.
   * It is used to quickly find the connected object by its name */
  const avaliableObjectsMap = {}
  objects.forEach(obj => {
    avaliableObjectsMap[obj.name] = obj
  })

  /** additionalFields is an array of mapped sfObject fields connected to the form. */
  const additionalFields = []
  objectsConnected.forEach(obj => {
    const objData = avaliableObjectsMap[obj.type]
    if (objData) {
      const { fields, recordTypes } = objData
      if (fields) {
        objData.fields.forEach(field => {
          if (sfFieldToConditionType[field.type]) {
            additionalFields.push({
              type: field.type,
              picklistValues: field.picklistValues || [],
              id: obj.name + '//' + field.name,
              sfField: field.name,
              sfObject: obj.identId,
              label: '[' + obj.name + '] ' + field.label
            })
          }
        })
      }
      if (recordTypes) {
        additionalFields.push({
          type: 'picklist',
          id: obj.name + 'RecordType',
          sfField: 'RecordTypeId',
          sfObject: obj.identId,
          picklistValues: Object.keys(recordTypes).map(key => {
            return {
              apiValue: recordTypes[key].recordTypeId,
              label: key
            }
          }),
          label: '[' + obj.name + '] ' + 'RecordType'
        })
      }
    }
  })

  /** avaliableElements is a combined array of:
   * 1. all elements of the all sections of the form editor tree
   * which type is included in the formConditions object keys or in the collectionElementTypes object keys.
   * 2. all relatedCollections of connected objects to the form
   * 3. all mapped sfObject fields connected to the form */
  const avaliableElements = [
    ...mapElements(tree),
    ...relatedCollections,
    ...additionalFields
  ]
  const avaliableElementsMap = {}
  avaliableElements.forEach(element => {
    avaliableElementsMap[element.id] = element
  })

  const additionalConditionState =
    formComponentTypes[elementType]?.additionalConditions

  return (
    <div style={{ padding: 15 }}>
      <Grid container direction='row' alignItems='center'>
        <Typography>
          <Trans>Conditions</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            const newConditions = [...conditions]
            newConditions.push({
              state: isSub ? 'sub' : 'show'
            })
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'conditions',
              subfieldName: 'conditions',
              fieldIndex: subIndex,
              fieldValue: newConditions
            })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
        {!isSub && (
          <TooltipLabelIcon
            tooltip={
              <Trans>
                In case of conflicting conditions (e.g. Show and hide at the
                same time) the hide condition will always take precedence and
                the form element will stay hidden
              </Trans>
            }
          />
        )}
      </Grid>
      <Grid container direction='column'>
        {conditions.map((item, index) => {
          const { altLabel, state, conditionTarget, condition } = item
          let rules, rulesType
          let selectedElement = avaliableElementsMap[conditionTarget]
          if (selectedElement) {
            if (selectedElement.isCollection) {
              const field = selectedElement?.fields?.find(
                field => field.name === item.childObject
              )
              rulesType = sfFieldToConditionType[field?.type]
            } else {
              rulesType = selectedElement.sfField
                ? sfFieldToConditionType[selectedElement.type]
                : selectedElement.type
            }
            if (rulesType) {
              rules = formConditions[rulesType]?.conditions
            }
          }
          if (specialFormConditions[conditionTarget]) {
            selectedElement = {
              label: conditionTarget
            }
            if(conditionTarget === 'inRoute' || conditionTarget === 'notInRoute') {
              rulesType = 'route'
            }
          }

          let selectableOptions = []
          if (selectedElement && selectedElement.picklistValues) {
            selectableOptions = selectedElement.picklistValues.map(
              (option, index) => ({
                label:
                  option.label ||
                  option.apiValue ||
                  getTranslatableLabel(option),
                value: option.value || option.apiValue || 'option' + index
              })
            )
          }
          const showAltLabels = state === 'altLabel' || state === 'altHelpText'

          return (
            <>
              <Grid
                item
                container
                direction='row'
                key={index}
                justifyContent='space-between'
                alignItems='flex-start'
                wrap='nowrap'
              >
                <div style={{ marginTop: 25 }}>{index + 1 + '. '}</div>
                <Grid item style={{ flex: 1, padding: 10 }}>
                  <Grid container direction='column'>
                    {!isSub && (
                      <TextField
                        select
                        variant='outlined'
                        label={<Trans>If condition is met:</Trans>}
                        fullWidth
                        value={state || ''}
                        onChange={e => {
                          const newConditions = [...conditions]
                          delete newConditions[index][state]
                          newConditions[index].state = e.target.value
                          delete newConditions[index].altLabel
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'conditions',
                            subfieldName: 'conditions',
                            fieldIndex: subIndex,
                            fieldValue: newConditions
                          })
                        }}
                      >
                        {standardConditionStates
                          .filter(obj => {
                            if (
                              elementType === 'section' &&
                              !obj.labelSection
                            ) {
                              return false
                            }
                            if (
                              (elements || elementType === 'section') &&
                              obj.forSingleElements
                            ) {
                              return false
                            }
                            return true
                          })
                          .map(obj => {
                            return (
                              <MenuItem value={obj.value} key={obj.value}>
                                {elementType === 'section'
                                  ? obj.labelSection
                                  : obj.label}
                              </MenuItem>
                            )
                          })}
                        {additionalConditionState &&
                          Object.keys(additionalConditionState).map(key => {
                            const obj = additionalConditionState[key]
                            return (
                              <MenuItem value={key} key={key}>
                                {obj.label}
                              </MenuItem>
                            )
                          })}
                      </TextField>
                    )}

                    {additionalConditionState &&
                      additionalConditionState[state]?.component && (
                        <div style={{ marginTop: 8 }}>
                          {additionalConditionState[state]?.component({
                            value: item[state],
                            onChange: value => {
                              const newConditions = [...conditions]
                              newConditions[index][state] = value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }
                          })}
                        </div>
                      )}

                    {Boolean(showAltLabels) && (
                      <ConfigureMultilanguageTextField
                        value={altLabel}
                        label={<Trans>Alternative label</Trans>}
                        handleChange={value => {
                          const newConditions = [...conditions]
                          newConditions[index].altLabel = value
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'conditions',
                            subfieldName: 'conditions',
                            fieldIndex: subIndex,
                            fieldValue: newConditions
                          })
                        }}
                        useDebounce
                      />
                    )}
                    {!isSub && (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(item.conditions)}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].conditions = []
                                if (e.target.checked) {
                                  delete newConditions[index].sfField
                                  delete newConditions[index].sfObject
                                  delete newConditions[index].condition
                                  delete newConditions[index].parameter
                                  delete newConditions[index].conditionTarget
                                } else {
                                  delete newConditions[index].conditions
                                }
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  fieldName: 'conditions',
                                  fieldValue: newConditions
                                })
                              }}
                            />
                          }
                          label={<Trans>Is a group condition</Trans>}
                        />
                        {item.conditions && (
                          <TextField
                            select
                            variant='outlined'
                            fullWidth
                            value={item.logic || 'all'}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].logic = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            <MenuItem value='all'>
                              <Trans>All conditions</Trans>
                            </MenuItem>
                            <MenuItem value='any'>
                              <Trans>Any condition</Trans>
                            </MenuItem>
                          </TextField>
                        )}
                      </div>
                    )}

                    {item.conditions ? (
                      <ConditionalElementEditor
                        langVersion={langVersion}
                        id={id}
                        depth={depth}
                        subIndex={index}
                        conditions={item.conditions}
                      />
                    ) : (
                      <Autocomplete
                          freeSolo={false}
                          value={selectedElement ? selectedElement.label : ''}
                          onChange={(e, value) => {
                          let target
                            avaliableElements.some(item => {
                            const bool = item.label === value
                              if (bool) {
                              target = item
                              }
                            return bool
                          })
                          const newConditions = [...conditions]
                            if (target) {
                            newConditions[index].conditionTarget = target.id
                              if (target.isCollection) {
                              newConditions[index].connectedObject =
                                target.connectedObject
                              newConditions[index].collectionKey =
                                target.collectionKey
                              delete newConditions[index].sfField
                              delete newConditions[index].sfObject
                              }
                              if (target.sfField) {
                              newConditions[index].sfField = target.sfField
                              newConditions[index].sfObject = target.sfObject
                              delete newConditions[index].connectedObject
                              delete newConditions[index].collectionKey
                              }
                              if (!target.isCollection && !target.sfField) {
                              delete newConditions[index].sfField
                              delete newConditions[index].sfObject
                              delete newConditions[index].connectedObject
                              delete newConditions[index].collectionKey
                              }
                            delete newConditions[index].childObject
                            delete newConditions[index].type
                            delete newConditions[index].collectionCondition
                            delete newConditions[index].condition
                            delete newConditions[index].parameter
                            } else if (specialFormConditions[value]) {
                            newConditions[index].conditionTarget = value
                            } else {
                            newConditions[index] = {}
                            }
                            dispatch({
                              type: 'FIELD',
                              depth: depth.split('.'),
                              subfieldName: 'conditions',
                              fieldIndex: subIndex,
                              fieldName: 'conditions',
                              fieldValue: newConditions
                          })
                          }}
                          style={{ marginTop: 15 }}
                          fullWidth
                          options={[
                          ...Object.entries(specialFormConditions).map(
                            ([key, value]) => key
                          ),
                          ...avaliableElements.map((item, index) => item.label)
                          ]}
                        getOptionLabel={option =>
                          specialFormConditions[option] || option
                        }
                        renderInput={params => {
                          const { value } = params.inputProps
                          if (specialFormConditions[value]) {
                            params.inputProps.value =
                              specialFormConditions[value]
                          }
                          return (
                            <TextField
                              variant='outlined'
                              {...params}
                              label={<Trans>Condition target</Trans>}
                            />
                          )
                        }}
                        />
                    )}

                    <Grid container direction='row' style={{ marginTop: 10 }}>
                      {selectedElement?.fields && (
                        <Grid xs={6} item>
                          <TextField
                            select
                            variant='outlined'
                            label={<Trans>Child Object</Trans>}
                            fullWidth
                            value={item.childObject || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].childObject = e.target.value
                              const field = selectedElement?.fields?.find(
                                field => field.name === e.target.value
                              )
                              rulesType = sfFieldToConditionType[field?.type]
                              newConditions[index].type = rulesType
                              delete newConditions[index].condition
                              delete newConditions[index].parameter
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            {selectedElement?.fields.map(field => {
                              return (
                                <MenuItem value={field.name} key={field.name}>
                                  {field.label}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Grid>
                      )}

                      {selectedElement?.isCollection && (
                        <Grid xs={6} item>
                          <TextField
                            select
                            variant='outlined'
                            label={<Trans>Collection Condition</Trans>}
                            fullWidth
                            value={item.collectionCondition || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].collectionCondition =
                                e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            {Object.entries(collectionConditions).map(
                              ([key, { text }]) => {
                                return (
                                  <MenuItem value={key} key={key}>
                                    {text}
                                  </MenuItem>
                                )
                              }
                            )}
                          </TextField>
                        </Grid>
                      )}
                    </Grid>

                    {/* <MenuItem value='errorsPresent'>
                      <Trans>Form has errors present</Trans>
                    </MenuItem>
                    <MenuItem value='noErrors'>
                      <Trans>Form has no errors</Trans>
                    </MenuItem> */}
                    <Grid container direction='row' style={{ marginTop: 10 }}>
                      {rules && (
                        <Grid
                          xs={
                            ['bool', 'otherGrants'].includes(rulesType) ||
                            ['isEmpty', 'isNotEmpty'].includes(condition)
                              ? 12
                              : 6
                          }
                          item
                        >
                          <TextField
                            select
                            variant='outlined'
                            label={<Trans>Condition</Trans>}
                            fullWidth
                            value={item.condition || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].condition = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            {Object.keys(rules)
                              .filter(key => !rules[key].deprecated)
                              .map((key, index) => {
                                const ruleObj = rules[key]
                                let text = ruleObj.text

                                if (typeof text === 'function') {
                                  text = text(selectedElement)
                                }
                                return (
                                  <MenuItem value={key} key={index}>
                                    {text}
                                  </MenuItem>
                                )
                              })}
                          </TextField>
                        </Grid>
                      )}

                      {rules &&
                        [
                          'textInputNumeric',
                          'textInput',
                          'numericSlider',
                          'textInputArray',
                          'route'
                        ].includes(rulesType) && (
                          <Grid xs={6} item>
                            <TextField
                              variant='outlined'
                              fullWidth
                              InputProps={
                                ['numericSlider', 'textInputNumeric'].includes(
                                  rulesType
                                ) && {
                                  inputComponent: NumberFormatCustom
                                }
                              }
                              value={item.parameter || ''}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].parameter = e.target.value
                                dispatch({
                                  type: 'FIELD',
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  depth: depth.split('.'),
                                  fieldName: 'conditions',
                                  fieldValue: newConditions
                                })
                              }}
                            />
                          </Grid>
                        )}

                      {rules &&
                        rulesType === 'picklist' &&
                        !['isEmpty', 'isNotEmpty'].includes(condition) && (
                          <Grid xs={6} item>
                            <TextField
                              disabled={
                                selectableOptions.length === 0 ||
                                !item.condition
                              }
                              variant='outlined'
                              select
                              fullWidth
                              value={item.parameter || ''}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].parameter = e.target.value
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'conditions',
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  fieldValue: newConditions
                                })
                              }}
                            >
                              {selectableOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        )}

                      { rulesType === 'route' && (
                        <Grid item xs={12}>
                          <TextField
                            disabled={ routeOptions.length === 0 }
                            variant='outlined'
                            select
                            fullWidth
                            value={item.parameter || ''}
                            onChange={e => {
                              const newConditions = [...conditions ]
                              newConditions[index] = {
                                conditionTarget: newConditions[index].conditionTarget,
                                state: newConditions[index].state,  
                                parameter: e.target.value
                              }
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }}
                          >
                            {routeOptions.map((option, index) => (
                              <MenuItem value={option.id} key={option.id}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}

                      {rules && rulesType === 'datePicker' && (
                        <Grid xs={6} item>
                          <CustomDatePicker
                            inputVariant='outlined'
                            fullWidth
                            format={dateFormat}
                            value={item.parameter || null}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].parameter = e
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ width: 50, marginTop: 15 }}>
                  <IconButton
                    onClick={() => {
                      const newConditions = [...conditions]
                      newConditions.splice(index, 1)
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'conditions',
                        subfieldName: 'conditions',
                        fieldIndex: subIndex,
                        fieldValue: newConditions
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Grid>
              {!isSub && index !== conditions.length - 1 && (
                <Divider style={{ marginTop: 8, marginBottom: 12 }} />
              )}
            </>
          )
        })}
      </Grid>
    </div>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export default ConditionalElementEditor
