import { ICCEWorkplan } from "./FormICCEWorkplan"
import {Trans} from '@lingui/macro'

export const FormICCEWorkplanPrintEditor = props => {
    const workplanLines = [
      {
        description: <Trans>Description of Activities Placeholder</Trans>,
        budgetLine: <Trans>Budget Line Item Placeholder</Trans>,
        startDate: <Trans>Start Date Placeholder</Trans>,
        endDate: <Trans>End Date Placeholder</Trans>,
        staff: <Trans>Staff Placeholder</Trans>,
      }
    ]
    return (
      <>
        <div className='form-print-title-small'>{props.title}</div>
        <ICCEWorkplan workplanLines={workplanLines} isPrintEditor={true}/>
      </>
    )
  }