import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import {
  bilingualDateFormat,
  currencyFormatedString
} from 'app/views/common/Formats'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import Html from 'react-pdf-html'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  guessFieldType,
  parseFormLabelText,
  pdfDefaultFontSize
} from '../../common/Common'
import { formObjectsToConnect } from '../../editor/FormWizard'
import { ImprovedHtml } from 'app/views/common-components/ImprovedHtml'

/**
 * Form element which renders a collection of text items, that can contain either regular text, Salesforce field references or html, into one paragraph of text.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {Object[]} typeProps.content Text items, set in editor, that will be rendered into one paragraph.
 * @param  {boolean}  [typeProps.alignCenter=false] If text should be centered.
 * @param  {boolean}  [typeProps.justify=false] If text should be justified.
 * @param  {number} [typeProps.fontSize] Override font size for text.
 * @param  {Color} [typeProps.backgroundColor] Color of the background.
 * @param  {Color} [typeProps.textColor] Color of the text.
 */
export const FormTextCollection = ({
  langVersion,
  editor,
  title,
  typeProps,
  connectedMap = {},
  describeMap,
  pdf,
  injectableId,
  objectsFieldsMap,
  ...props
}) => {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    fontSize,
    content = []
  } = typeProps

  const ContainerType = pdf ? Text : 'span'
  const BreakContainerType = pdf ? View : 'div'
  return (
    <ContainerType
      style={{
        backgroundColor: backgroundColor && backgroundColor.id,
        color: textColor && textColor.id,
        borderRadius: 5,
        fontSize: fontSize ? Number(fontSize) : pdf && pdfDefaultFontSize,
        textAlign: alignCenter ? 'center' : justify && 'justify',
        display: editor && 'block',
        width: Boolean(alignCenter) ? '100%' : 'auto'
      }}
      className='break-in-print'
    >
      {content.map((obj, index) => {
        let {
          text,
          type,
          isHtml,
          cIndex = 0,
          testField,
          testValue,
          injectableElement,
          referenceObject,
          referenceField,
          referenceCollection,
          specialReference,
          wholeCollectionValue,
          textProps = []
        } = obj

        const style = {
          fontWeight: textProps.includes('bold') ? 'bold' : 400,
          fontStyle: textProps.includes('italics') ? 'italic' : 'normal',
          textDecoration: textProps.includes('underline') && 'underline',
          fontSize: fontSize ? Number(fontSize) : pdf && pdfDefaultFontSize
        }

        const ElementType = pdf ? Text : 'span'
        const translate = textProps.includes('translate')

        const returnChildElement = element => {
          return (
            <>
              {textProps.includes('lineBreakBefore') && (
                <BreakContainerType>{'\n'}</BreakContainerType>
              )}
              {textProps.includes('spaceBefore') && ' '}
              {element}
            </>
          )
        }

        if (type === 'text') {
          const parsedText = parseFormLabelText({
            text,
            langVersion
          })
          if (editor) {
            if (isHtml) {
              return returnChildElement(ReactHtmlParser(parsedText))
            } else {
              return returnChildElement(parsedText)
            }
          }
          const parsedString = parseFormLabelText({
            text,
            langVersion,
            returnString: true,
            describeMap,
            objectsFieldsMap,
            renderProps: {
              connectedMap,
              injectableId
            }
          })

          return returnChildElement(
            isHtml ? (
              pdf ? (
                <ImprovedHtml
                  key={index}
                  style={{
                    fontSize: fontSize ? +fontSize : pdf && pdfDefaultFontSize
                  }}
                >
                  {parsedString}
                </ImprovedHtml>
              ) : (
                <ElementType key={index}>
                  {ReactHtmlParser(parsedString)}
                </ElementType>
              )
            ) : (
              <ElementType key={index} style={style}>
                {textProps.includes('lineBreakBefore') && '\n'}
                {textProps.includes('spaceBefore') && ' '}
                {parsedString}
              </ElementType>
            )
          )
        } else if (type === 'special') {
          if (specialReference) {
            switch (specialReference) {
              case 'CURRENT_DATE':
                return returnChildElement(
                  <ElementType key={index} style={style}>
                    {textProps.includes('lineBreakBefore') && (
                      <BreakContainerType />
                    )}
                    {textProps.includes('spaceBefore') && ' '}
                    {translate
                      ? bilingualDateFormat(moment(), langVersion)
                      : moment.utc().format(dateFormat)}
                  </ElementType>
                )
              default:
                return returnChildElement(
                  <ElementType key={index} style={style}>
                    {'NO CONDITIONS SPECIFIED FOR: ' + specialReference}
                  </ElementType>
                )
            }
          }
        } else {
          if (editor && !specialReference) {
            if (
              type === 'collectionReferece' ||
              type === 'wholeCollectionReference'
            ) {
              return returnChildElement(
                <ElementType key={index} style={style}>
                  COLLECTION_REFERENCE_PLACEHOLDER
                </ElementType>
              )
            }
            const reference = referenceField || injectableElement
            return returnChildElement(
              <ElementType key={index} style={style}>
                {'REFERENCE_PLACEHOLDER: ' + reference}
              </ElementType>
            )
          }

          let sfData = connectedMap[referenceObject]
          if (!sfData) {
            return null
          }
          let fieldName = referenceField || ''
          let sfObject = sfData.sfObject
          let collection
          let fieldData = sfData.fieldsMap[referenceField]
          if (
            referenceCollection &&
            sfData.additionalInfo[referenceCollection]
          ) {
            collection = sfData.additionalInfo[referenceCollection]
            if (collection) {
              sfObject = null
              if (type === 'wholeCollectionReference') {
                if (wholeCollectionValue) {
                  let string = ''
                  switch (wholeCollectionValue) {
                    case 'length':
                      string = String(collection.length)
                      break
                    default:
                      string =
                        'NO CONDITIONS SPECIFIED FOR COLLECTION: ' +
                        referenceCollection
                      break
                  }
                  return returnChildElement(
                    <ElementType key={index} style={style}>
                      {string}
                    </ElementType>
                  )
                } else {
                  return returnChildElement(
                    <ElementType key={index} style={style}>
                      {'NO CONDITIONS SPECIFIED FOR COLLECTION: ' +
                        referenceCollection}
                    </ElementType>
                  )
                }
              }

              if (!testValue || !testField) {
                return returnChildElement(
                  <ElementType key={index} style={style}>
                    {'NO CONDITIONS SPECIFIED FOR: ' +
                      referenceField +
                      ' IN COLLECTION: ' +
                      referenceCollection}
                  </ElementType>
                )
              }
              if (testValue === 'true') {
                testValue = true
              } else if (testValue === 'false') {
                testValue = false
              }
              collection = collection.filter(obj => {
                let toCheck = obj
                if (testField.indexOf('.') !== -1) {
                  const field = testField.split('.')[0]
                  const subField = testField.split('.')[1]
                  toCheck = obj[field]
                  return toCheck[subField] === testValue
                } else {
                  return toCheck[testField] === testValue
                }
              })
              if (collection.length === 1) {
                sfObject = collection[0]
              } else if (collection.length > 1) {
                if (!isNaN(+cIndex)) {
                  sfObject = collection[cIndex]
                } else {
                  return returnChildElement(
                    <ElementType key={index} style={style}>
                      {'NO INDEX PROVIDED FOR SPECIFIED CONDITIONS: ' +
                        testValue +
                        ' IN FIELD ' +
                        testField +
                        ' IN COLLECTION: ' +
                        referenceCollection}
                    </ElementType>
                  )
                }
              }
              if (!sfObject) {
                return returnChildElement(
                  <ElementType key={index} style={style}>
                    {'NOTHING FOUND FOR SPECIFIED CONDITIONS: ' +
                      testValue +
                      ' IN FIELD ' +
                      testField +
                      ' IN COLLECTION: ' +
                      referenceCollection}
                  </ElementType>
                )
              }
              const sfObjectType = sfObject.attributes.type
              sfData = describeMap[sfObjectType]
              const fieldsMap = {}
              sfData.fields.forEach(field => {
                fieldsMap[field.name] = field
              })
              fieldData = fieldsMap[fieldName]
            }
          }
          let value = sfObject[referenceField]
          if (!fieldData && fieldName.indexOf('.') !== -1) {
            const subField = fieldName.split('.')[1]
            fieldName = fieldName.split('.')[0]
            if (fieldName && subField) {
              try {
                if (sfObject[fieldName].value) {
                  value = sfObject[fieldName].value[subField]
                } else {
                  const sfObjectType = sfObject.attributes.type
                  const formSfObjectData = formObjectsToConnect[sfObjectType]
                  if (
                    formSfObjectData.selectInfo &&
                    formSfObjectData.selectInfo[referenceField]
                  ) {
                    value = sfObject[fieldName][subField]
                    fieldData = {
                      type:
                        formSfObjectData.selectInfo[referenceField] ||
                        guessFieldType(value)
                    }
                  } else {
                    const type = sfObject[fieldName].attributes.type
                    sfData = describeMap[type]
                    const fieldsMap = {}
                    sfData.fields.forEach(field => {
                      fieldsMap[field.name] = field
                    })
                    fieldData = fieldsMap[subField]
                    value = sfObject[fieldName][subField]
                  }
                }
              } catch (er) {
                return returnChildElement(
                  <ElementType key={index} style={style}>
                    {'NO SUBFIELD ' + subField + ' FOR FIELD: ' + fieldName}
                  </ElementType>
                )
              }
            }
          }
          if (!fieldData) {
            if (
              type === 'wholeCollectionReference' ||
              type === 'collectionReference'
            ) {
              return returnChildElement(
                <ElementType key={index} style={style}>
                  {'NO DATA CONFIGURED FOR COLLECTION REFERENCE'}
                </ElementType>
              )
            }
            return returnChildElement(
              <ElementType key={index} style={style}>
                {'NO DATA CONFIGURED FOR :' + referenceField}
              </ElementType>
            )
          }
          if (['date', 'datetime'].includes(fieldData.type)) {
            if (moment.utc(value).isValid()) {
              if (translate) {
                value = bilingualDateFormat(value, langVersion)
              } else {
                value = moment.utc(value).format(dateFormat)
              }
            }
          } else if (fieldData.type === 'currency') {
            value = currencyFormatedString(value, langVersion)
          } else if (['multipicklist', 'picklist'].includes(fieldData.type)) {
            if (value) {
              value = value.replaceAll(';', ', ')
            }
          } else if (fieldData.type === 'boolean') {
            if (typeof value === 'boolean') {
              value = value ? myI18n?._(t`Yes`) : myI18n?._(t`No`)
            }
          } else if (
            [
              'string',
              'textarea',
              'email',
              'url',
              'phone',
              'address',
              'id'
            ].includes(fieldData.type)
          ) {
            if (/<\/?[a-z][\s\S]*>/i.test(value)) {
              return returnChildElement(
                pdf ? (
                  <ImprovedHtml key={index} style={style}>
                    {value}
                  </ImprovedHtml>
                ) : (
                  ReactHtmlParser(value)
                )
              )
            }
          }
          if (translate && value) {
            value = String(myI18n?._(value))
          }

          return returnChildElement(
            <ElementType key={index} style={style}>
              {value}
            </ElementType>
          )
        }

        return null
      })}
    </ContainerType>
  )
}
