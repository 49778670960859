import React from "react";

type FormEditorContextType = {
  availableObjects: any;
};

const FormContext = React.createContext<FormEditorContextType>({
  availableObjects: null,
});

export function useFormEditorContext() {
  const value = React.useContext(FormContext);
  if (!value) {
    throw new Error(
      "useFormEditorContext must be wrapped in a <SessionProvider />"
    );
  }
  return value;
}

export function FormEditorContextProvider(
  props: React.PropsWithChildren<FormEditorContextType>
) {
  return (
    <FormContext.Provider
      value={{
        ...props,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
}
