export function getPicklistApiValuesFromObjectDefinition({
  objectType,
  fieldName,
  objectsDefinitions,
}: {
  objectType: string;
  fieldName: string;
  objectsDefinitions: any[];
}) {
  const targetObject = objectsDefinitions.find(
    (obj) => obj.name === objectType
  );
  if (!targetObject) {
    return [];
  }
  return targetObject.fields
    .find((field) => field.name === fieldName)
    .picklistValues.filter((obj) => obj.active);
}

export function getConnectedObjectTypeByIdentId(
  identId: string,
  connectedObjects: any[]
) {
  if (!identId) {
    return null;
  }
  return connectedObjects.find((obj) => obj.identId === identId)?.type || null;
}
