import React, { useState, useEffect } from 'react'
import {
  Grid,
  IconButton,
  Icon,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  TableCell,
  Button,
  DialogActions
} from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'
import { muiDTNormalizedSearch } from 'app/utils/stringCompare'
import { Trans, t } from '@lingui/macro'
import { getPortalTranslations } from 'app/services/sfAuth/sfData/sfTranslations'
import { jsonCatalogs } from 'translation/I18nConnectedProvider'
import moment from 'moment'
import { dateFormat } from 'app/appSettings'
import { useSnackbar } from 'notistack'
import { Formik } from 'formik'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField'
import { I18n } from '@lingui/react'
import { portalLanguagesData } from 'app/appSettings'
import Loading from 'egret/components/EgretLoadable/Loading'

const TranslationConnectionsDialog = ({
  open,
  handleClose,
  saveTranslationConnection,
}) => {
  const [translations, setTranslations] = useState([])
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const languageKeys = Object.keys(portalLanguagesData)
  const languageValues = Object.values(portalLanguagesData)
  const languages = languageKeys.map(key => portalLanguagesData[key].editKey)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    return getPortalTranslations().then(
      result => {
        const mapped = []
        setTranslations(
          result.map(item => {
            if (!mapped.includes(item.Translation_Id__c)) {
              mapped.push(item.Translation_Id__c)
            } {/* else {
              console.warn(
                'Duplicate translation found!',
                item.Translation_Id__c
              )
            }
              */}
            const langQuery = {}
            languageValues.forEach(obj => {
              langQuery[obj.editKey] = item[obj.portalTranslationField]
            })
            return {
              id: item.Translation_Id__c,
              ...langQuery,
              found: false,
              comment: item.Translator_Comment__c,
              date: moment.utc(item.LastModifiedDate).format(dateFormat),
              actions: item.Translation_Id__c
            }
          })
        )
        setLoading(false)
      },
      reject => {
        console.log(reject)
        enqueueSnackbar(<Trans>Error occured while loading!</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  const getProperColumns = ({ i18n, values }) => {
    const hasLanguages = Boolean(
      values.displayedColumns.includes('en') &&
        values.displayedColumns.includes('fr')
    )
    const toRet = [
      {
        name: 'id',
        key: '1',
        label: i18n._(t`Id`),
        options: {
          customHeadRender: (props, sort, sortData) => {
            return (
              <TableCell
                style={{ marginLeft: 20, width: hasLanguages && '10%' }}
              >
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    sort(0)
                  }}
                >
                  <Trans>Id</Trans>
                  {sortData?.name === 'id' && (
                    <Icon
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        color: '#757575',
                        margin: 4
                      }}
                    >
                      {sortData.direction === 'desc'
                        ? 'arrow_downward'
                        : 'arrow_upward'}
                    </Icon>
                  )}
                </Button>
              </TableCell>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={{ marginLeft: 20 }}>
                <Typography style={{ fontSize: 11 }}>{value}</Typography>
              </div>
            )
          }
        }
      }
    ]
    languageValues.forEach(obj => {
      const indexOf = toRet.length
      const lang = obj.editKey
      if (!values.displayedColumns.includes(lang)) {
        return
      }
      toRet.push({
        name: lang,
        label: portalLanguagesData[lang].labelTrans,
        options: {
          customHeadRender: (props, sort, sortData) => {
            return (
              <TableCell>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    sort(indexOf)
                  }}
                >
                  {portalLanguagesData[lang].labelTrans}
                  {sortData?.name === lang && (
                    <Icon
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        color: '#757575',
                        margin: 4
                      }}
                    >
                      {sortData.direction === 'desc'
                        ? 'arrow_downward'
                        : 'arrow_upward'}
                    </Icon>
                  )}
                </Button>
              </TableCell>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={{ flex: 1, minWidth: 150 }}>
                <Typography style={{ fontSize: 12 }}>{value}</Typography>
              </div>
            )
          }
        }
      })
    })
    if (values.displayedColumns.includes('comments')) {
      const indexOf = toRet.length
      toRet.push({
        name: 'comment',
        label: i18n._(t`Translator comment`),
        options: {
          customHeadRender: (props, sort, sortData) => {
            return (
              <TableCell style={{ width: hasLanguages && '20%' }}>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    sort(indexOf)
                  }}
                >
                  <Trans>Translator comment</Trans>
                  {sortData?.name === 'comment' && (
                    <Icon
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        color: '#757575',
                        margin: 4
                      }}
                    >
                      {sortData.direction === 'desc'
                        ? 'arrow_downward'
                        : 'arrow_upward'}
                    </Icon>
                  )}
                </Button>
              </TableCell>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={{ flexShrink: 1, minWidth: 150 }}>
                <Typography style={{ fontSize: 12 }}>{value}</Typography>
              </div>
            )
          }
        }
      })
    }
    if (values.displayedColumns.includes('date')) {
      const indexOf = toRet.length
      toRet.push({
        name: 'date',
        label: i18n._(t`Modified date`),
        options: {
          searchable: false,
          customHeadRender: (props, sort, sortData) => {
            return (
              <TableCell style={{ width: '10%' }}>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    sort(indexOf)
                  }}
                >
                  <Trans>Modified date</Trans>
                  {sortData?.name === 'date' && (
                    <Icon
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        color: '#757575',
                        margin: 4
                      }}
                    >
                      {sortData.direction === 'desc'
                        ? 'arrow_downward'
                        : 'arrow_upward'}
                    </Icon>
                  )}
                </Button>
              </TableCell>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Typography style={{ fontSize: 11, textAlign: 'center' }}>
                  {value}
                </Typography>
              </div>
            )
          }
        }
      })
    }
    toRet.push({
      name: 'actions',
      label: i18n._(t`Actions`),
      searchable: false,
      options: {
        sort: false,
        customHeadRender: props => {
          return (
            <TableCell key={props.index} style={{ width: 150 }}>
              <Grid container justifyContent='center'>
                <Trans>Actions</Trans>
              </Grid>
            </TableCell>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container justifyContent='center' style={{ width: '100%' }}>
              <Tooltip title={'ADD_CONNECTION'}>
                <IconButton
                  onClick={() => { 
                    saveTranslationConnection(value)
                    handleClose()
                  }}
                >
                  <Icon>check</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          )
        }
      }
    })

    return toRet
  }

  return (
    <I18n>
      {({ i18n }) => {
        return (
          <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
            scroll='paper'
            PaperProps={{
              style: { position: 'static' }
            }}
          >
            <DialogTitle>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Trans>CONNECTIONS_TITLE</Trans>
                <Tooltip title={<Trans>Close</Trans>}>
                  <IconButton
                    onClick={() => {
                      handleClose()
                    }}
                    color='default'
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  id: '',
                  displayedColumns: languages
                }}
              >
                {({ values, setValues }) => {
                  return (
                    <>
                      <Paper style={{ padding: 20 }} elevation={6}>
                        <FormikCheckboxGroupField
                          label={<Trans>Displayed columns</Trans>}
                          style={{ marginTop: 20, width: '100%' }}
                          name='displayedColumns'
                          multiple
                          options={[
                            ...Object.values(portalLanguagesData).map(obj => ({
                              label: obj.labelTrans,
                              value: obj.editKey
                            })),
                            {
                              label: <Trans>Translator comments</Trans>,
                              value: 'comments'
                            },
                            {
                              label: <Trans>Modified date</Trans>,
                              value: 'date'
                            }
                          ]}
                        />
                      </Paper>

                      {loading ? (
                        <Loading />
                      ) : (
                        <MUIDataTable
                          title={
                            <div>
                              <Grid container style={{ marginTop: 20 }}>
                                <Typography variant='h5'>
                                  <b>
                                    <Trans>Portal Translations</Trans>
                                  </b>
                                </Typography>
                              </Grid>
                            </div>
                          }
                          data={translations}
                          options={{
                            textLabels: muiTextLabels(i18n),
                            filter: false,
                            print: false,
                            download: false,
                            viewColumns: false,
                            selectableRows: 'none',
                            customSearch: muiDTNormalizedSearch
                          }}
                          columns={getProperColumns({
                            i18n,
                            values,
                            data: translations
                          })}
                        />
                      )}
                    </>
                  )
                }}
              </Formik>
            </DialogContent>
          </Dialog>
        )
      }}
    </I18n>
  )
}

export default TranslationConnectionsDialog
