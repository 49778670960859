import { SET_TUTORIALS_DATA } from '../actions/TutorialsActions'

const initialState = {}

const tutorialsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_TUTORIALS_DATA: {
      return {
        ...action.data
      }
    }
    default: {
      return state
    }
  }
}

export default tutorialsReducer
