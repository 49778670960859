import { NO_USER } from '../SFAuthService'
import SFAuthService from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { languages } from 'translation/I18nConnectedProvider'
import { portalLanguagesData } from 'app/appSettings'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Hide_On_Live__c: 'hideOnLive',
  Location__c: 'location',
  // Text_English__c: 'textEN',
  // Text_French__c: 'textFR',
  Order__c: 'order',
  CSS_Selector__c: 'selector',
  Data_Tut__c: 'dataTut',
  Content__c: 'content'
}

const reverseMapFields = reverseMap(mapFields)

/**
 * Deletes a tutorial step with the given id.
 * @function
 * @category Salesforce - Tutorial_Step__c
 * @param {string} id The id of the tutorial step to be deleted.
 * @returns {JSForceResult}
 */
export const deleteTutorialStep = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Tutorial_Step__c').delete(id)
}

/**
 * Retrieves tutorial steps based on the provided properties, parses them, and returns the parsed data.
 * @function
 * @category Salesforce - Tutorial_Steps__c
 * @param {object} props Properties to filter tutorial steps by.
 * @returns {object[]} An array of parsed tutorial steps.
 */
export const getTutorialStepsParsed = (props: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Tutorial_Step__c')
    .find({ ...props })
    .then(steps => {
      const ret = steps
        .map(obj => mapSFToForm(mapFields, obj))
        .map((obj, index) => {
          if (!obj.text) {
            obj.text = {}
          }
          languages.forEach((lang) => {
            obj.text[lang] = steps[index]?.[portalLanguagesData[lang]?.tutorialStepField]
              || steps[index]?.[`text${lang.toUpperCase()}`] // old version field name
          })
          return obj
        })
      return ret
    })
}

interface TutorialStepObject {
  text?: string,
  id?: string
}

/**
 * Saves an array of tutorial steps to Salesforce. Updates existing steps and creates new ones as necessary.
 * @function
 * @category Salesforce - Tutorial_Steps__c
 * @param {TutorialStepObject[]} array Array of tutorial steps to be saved.
 * @returns {JSForceResult}
 */
export const saveTutorialStepsParsed = (array: TutorialStepObject[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate: TutorialStepObject[] = []
  const toCreate: TutorialStepObject[] = []
  array.forEach((obj, index) => {
    const sfObj = mapSFToForm(reverseMapFields, obj)
    sfObj.Order__c = index
    languages.forEach((lang) => {
      sfObj[portalLanguagesData[lang].tutorialStepField] = obj.text?.[lang]
    })
    delete sfObj.direction
    if (obj.id) {
      toUpdate.push(sfObj)
    } else {
      delete sfObj.Id
      toCreate.push(sfObj)
    }
  })
  return Promise.all([
    conn.sobject('Tutorial_Step__c').create(toCreate),
    conn.sobject('Tutorial_Step__c').update(toUpdate)
  ])
}
