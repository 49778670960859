import { INDEX_OF_SELECTED } from 'app/views/grants/action-plan/ActionPlan'

import {
  CHANGE_ACTION,
  ADD_NEW_ACTION,
  MOVE_TO_COLUMN,
  SET_STATE,
  DELETE_ACTION,
  DELETE_ALL_ACTIONS
} from '../actions/ActionPlanActions'

const initialState = {
  columns: [
    {
      title: 'Selected',
      actions: []
    },
    {
      title: 'In Progress',
      actions: []
    },
    {
      title: 'Done',
      actions: []
    },
    {
      title: 'Failed',
      actions: []
    }
  ]
}

const columnsInOrder = ['Selected', 'In Progress', 'Done', 'Failed']

const actionPlanReducer = (state = initialState, action) => {
  const {
    movedFromColumn,
    movedToColumn,
    movedIndex,
    moveToIndex,
    columnIndex,
    actionIndex,
    actionToAdd
  } = action
  let newState = { ...state }
  switch (action.type) {
    case SET_STATE:
      return { ...action.state }
    case DELETE_ACTION:
      const toDeleteFrom = [...newState.columns[columnIndex].actions]
      toDeleteFrom.splice(actionIndex, 1)
      newState.columns[columnIndex].actions = toDeleteFrom
      return newState
    case ADD_NEW_ACTION:
      const newActions = [...newState.columns[INDEX_OF_SELECTED].actions]
      newActions.push(actionToAdd)
      newState.columns[INDEX_OF_SELECTED].actions = newActions
      return newState
    case CHANGE_ACTION:
      newState.columns[columnIndex].actions[actionIndex] = action.actionToChange
      return newState
    case DELETE_ALL_ACTIONS:
      newState = initialState
      return newState
    case MOVE_TO_COLUMN:
      const toMove = {
        ...newState.columns[movedFromColumn].actions[movedIndex]
      }
      toMove.column = columnsInOrder[movedToColumn]
      newState.columns[movedFromColumn].actions.splice(movedIndex, 1)
      newState.columns[movedToColumn].actions.splice(moveToIndex, 0, toMove)
      return newState
    default:
      return state
  }
}

export default actionPlanReducer
