import React from 'react'
import { returnTooltip } from './surveyQustions'
import { Typography } from '@material-ui/core'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'

const QuestionTitle = ({
  question,
  errors,
  displayIndex,
  version,
  nonApplicable,
  i18n
}) => {
  let questionText = question.text
  if (!questionText) {
    questionText = getLabelFromTranslationData({
      langVersion: version,
      data: question?.titleValue,
      i18n
    })
    if (question.options) {
      question.options.forEach(option => {
        if (option.titleValue) {
          option.text = getLabelFromTranslationData({
            langVersion: version,
            data: option.titleValue,
            i18n
          })
        }
      })
    }
    question.tooltip = getLabelFromTranslationData({
      langVersion: version,
      data: question?.tooltipValue,
      i18n
    })
  }
  let showError = true
  if (nonApplicable) {
    showError = false
    if (Array.isArray(nonApplicable)) {
      if (question.groupQuestions) {
        question.groupQuestions.forEach((q, index) => {
          if (!nonApplicable[index]) {
            showError = true
          }
        })
      } else {
        showError = true
      }
    }
  }

  return (
    <div>
      {question.type === 'HEADER' ? (
        <div>
          <Typography
            variant='h5'
            style={{ paddingBottom: 5, paddingTop: 10, fontSize: 21 }}
          >
            <b>{`${questionText}`}</b>
          </Typography>
          {question.tooltip && (
            <div style={{ paddingBottom: 5 }}>
              {returnTooltip(question.tooltip)}
            </div>
          )}
        </div>
      ) : (
        <>
          <Typography style={{ fontSize: 19, margin: 15 }}>
            <b>{`${displayIndex}. ${questionText}`}</b>
            {Boolean(errors[question.id] && showError) && (
              <span style={{ color: 'red' }}>*</span>
            )}
          </Typography>
        </>
      )}
    </div>
  )
}

export default QuestionTitle
