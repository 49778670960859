import MUIDataTable from 'mui-datatables'
import { parseSfFieldValue } from '../../common/Common'
import { muiDateCompare } from 'utils'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormTablePrint = ({ title, langFR, connectedMap, typeProps }) => {

    const { connectedTo = [], columns = [] } = typeProps
  
    let collection = []
    const collectionKey = connectedTo[0]?.connectedCollection
    const connectedObject = connectedTo[0]?.connectedObject
    if (collectionKey && connectedObject && connectedMap) {
      collection =
        connectedMap[connectedObject]?.additionalInfo[collectionKey] || []
    }
      
    return (
      <MUIDataTable
        title={title}
        data={ collection.map((obj, index) => {
            const toRet = {}
            columns.forEach(column => {
              const { field, translate } = column
              if (field.name) {
                const fieldValue = obj[field.name]
                toRet[field.name] = parseSfFieldValue({
                  value: fieldValue,
                  type: field.type,
                  french: langFR,
                  translate
                })
              }
            })
            return toRet
          })
        }
        options={{
          textLabels: muiTextLabels(myI18n),
          selectableRows: 'none',
          // print: false,
          // download: false,
          viewColumns: false
        }}
        columns={columns
          .map((column, index) => {
            const { labelEN, labelFR, field } = column
            const label = langFR ? labelFR : labelEN
            return {
              name: field.name,
              label: label,
              options: {
                customHeadLabelRender: props => {
                  return (
                    <div style={{ marginLeft: index === 0 && 20 }}>{label}</div>
                  )
                },
                sortCompare: field.type === 'date' && muiDateCompare(),
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <div style={{ marginLeft: index === 0 && 20 }}>{value}</div>
                  )
                }
              }
            }
          })
          .filter(column => column.name)}
      />
    )
}