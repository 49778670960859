import { Divider } from "@material-ui/core";
import React from "react";

export const FormEditorDividerWrapper: React.FC<{ closing?: boolean }> = ({
  children,
  closing = false,
}) => {
  return (
    <>
      <Divider style={{ marginBottom: 8, marginTop: 4 }} />
      <div style={{ paddingBottom: 8 }}>{children}</div>
      {closing && <Divider style={{ marginBottom: 4 }} />}
    </>
  );
};
