import { Trans } from '@lingui/macro'
import { Card, Grid, Icon, IconButton } from '@material-ui/core'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { findLabel } from './config/baseFilters'
import './styles/ResourcesCard.css'

const ResourcesCard = ({ resource, onClickHandler }) => {
  let date
  if (resource.date) {
    date = dayjs(resource.date).format('MMM D, YYYY')
  }

  return (
    <Card className='resources-card'>
      <Grid container className='resources-card__content'>
        <Grid item xs={8} container>
          <Grid item xs={5}>
            <h5
              className='resources-card__content_item'
              onClick={event => {
                onClickHandler(resource)
                event.preventDefault()
              }}
            >
              {resource.Resource_Name}
            </h5>
            <div className='resources-card__content_item'>
              {resource.type_names && <span>{resource.type_names}</span>}
            </div>
            {date && (
              <span className='resources-card__content_item'>{date}</span>
            )}
            {resource.submittedBy && (
              <div className='resources-card__content_item'>
                {resource.submittedBy}
              </div>
            )}
          </Grid>
          <Grid item xs={7}>
            {resource.Description && (
              <p
                className='resources-card__content_description'
                dangerouslySetInnerHTML={{ __html: resource.Description }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} container className=''>
          <Grid item xs={5}>
            {resource.Location && (
              <div className='resources-card__content_item'>
                <h6>
                  <Trans>RESOURCES_LOCATION</Trans>
                </h6>
                {resource.Location.map(item => {
                  return findLabel({ filterKey: 'Location', optionKey: item })
                }).map((item, idx, arr) => {
                  return (
                    <div>
                      {item}
                      {arr.length - 1 !== idx ? ', ' : ''}
                    </div>
                  )
                })}
              </div>
            )}
          </Grid>
          <Grid item xs={5}>
            {resource.Language_Of_Resource && (
              <div className='resources-card__content_item'>
                <h6>
                  <Trans>LANGUAGE</Trans>
                </h6>
                {resource.Language_Of_Resource.map(item => {
                  return findLabel({
                    filterKey: 'Language_Of_Resource',
                    optionKey: item
                  })
                }).map((item, idx, arr) => {
                  return (
                    <div>
                      {item}
                      {arr.length - 1 !== idx ? ', ' : ''}
                    </div>
                  )
                })}
              </div>
            )}
          </Grid>
          <Grid item xs={2} className=''>
            <IconButton
              className='resources-card__content_button'
              onClick={event => {
                onClickHandler(resource)
                event.preventDefault()
              }}
            >
              <Icon>remove_red_eye</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ResourcesCard

ResourcesCard.propTypes = {
  resource: PropTypes.object,
  onClickHandler: PropTypes.func
}
