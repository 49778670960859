import { isValidJSON } from 'utils'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm } from '../sfDataService'

const mapFields = {
  Name: 'key',
  Id: 'id',
  Value__c: 'value'
}

export const ERROR_NO_CONFIGURATION_SET = 'ERROR_NO_CONFIGURATION_SET'

interface SaveConfigurationData {
  Id?: string
}

/**
 * Saves configurations by creating new ones or updating existing ones based on the provided data array.
 * @function
 * @category Salesforce - Configuration__c
 * @param {SaveConfigurationData[]} toSave Array of configurations to be saved. Configurations with 'Id' property will be updated, those without it will be created.
 * @returns {JSForceResult | FlowResult}
 */
export const saveConfigurationsWithUpdateByFlow = (toSave: SaveConfigurationData[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toCreate: SaveConfigurationData[] = []
  const toUpdate: SaveConfigurationData[] = []
  toSave.forEach(obj => {
    if (obj.Id) {
      toUpdate.push(obj)
    } else {
      toCreate.push(obj)
    }
  })
  return Promise.all([
    conn.sobject('Configuration__c').create(toCreate),
    conn.requestPost('/actions/custom/flow/App_Update_Configuration', {
      inputs: toUpdate.map(obj => ({ toUpdate: obj }))
    })
  ])
}

/**
 * Creates a new configuration using the provided properties.
 * @function
 * @category Salesforce - Configuration__c
 * @param {Object} createProps -Properties for creating the configuration.
 * @returns {JSForceResult}
 */
export const createConfiguration = (createProps: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').create(createProps)
}

/**
 * Retrieves configuration variables including their id, name, and value.
 * @function
 * @category Salesforce - Configuration__c
 * @returns {object[]} An array containing configuration variables (each containing 'Id', 'Name', and 'Value__c' properties).
 */
export const getConfigurationVariables = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .select('Id, Name, Value__c')
    .autoFetch(true)
}

/**
 * Retrieves configurations based on the provided search properties.
 * @function
 * @category Salesforce - Configuration__c
 * @param {object} searchProps Properties to search configurations by.
 * @returns {object[]} An array containing configurations matching the search properties.
 */
export const getConfiguration = (searchProps: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').find(searchProps)
}

/**
 * Retrieves configuration variables and parses them into a dictionary format.
 * @function
 * @category Salesforce - Configuration__c
 * @returns {Promise<Object|Error>} A dictionary containing configuration variables parsed from Salesforce.
 */
export const getConfigurationVariablesParsed = () => {
  return getConfigurationVariables().then(result => {
    const dictionary = {}
    result
      .map(item => mapSFToForm(mapFields, item))
      .forEach(item => {
        let value = item.value
        if (value && isValidJSON(value)) {
          value = JSON.parse(value)
          if (value && typeof value === 'object') {
            value.id = item.id
          }
        }

        if (!dictionary[item.key]) {
          dictionary[item.key] = value
        } else {
          if (Array.isArray(dictionary[item.key])) {
            dictionary[item.key].push(value)
          } else {
            dictionary[item.key] = [value, dictionary[item.key]]
          }
        }
      })
    return dictionary
  })
}

/**
 * Updates configuration variable with the provided values.
 * @function
 * @category Salesforce - Configuration__c
 * @param {object} values Object representing the configuration variable to be updated.
 * @returns {JSForceResult}
 */
export const satConfigurationVariable = (values: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').update(values)
}
