import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { snackbarClasses } from 'app/App'
import { useDispatch } from 'react-redux'

/**
 * Editor field which allows selecting a color from one of the predefined colors.
 * @category Form
 * @subcategory Editor
 * @component
 * @returns {JSX.Element}
 * @param  {string} name - Key of the field being edited
 * @param {boolean} [disabled] - If field should be disabled
 * @param  {object} typeProps - Element specific properties that are being configured.
 * @param  {number[]} depth - Position in form editor tree represented by an array of indexes.
 * @param {object} value - Value of the input.
 * @param {(string|JSX.Element)} label - Label displayed in the input field.
 * @param {string} [fieldArray] - If edited field is an array - key of the subfield that is being edited
 * @param {number} [disabled] - If edited field is an array - array index of the subfield that is being edited
 */
export const FormColorAutocomplete = ({
  name,
  disabled,
  injectable,
  fieldArray,
  fieldArrayIndex,
  value,
  typeProps,
  depth,
  label,
  ...props
}) => {
  const dispatch = useDispatch()
  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      alignContent='center'
      wrap='nowrap'
    >
      <Autocomplete
        {...props}
        freeSolo
        disabled={disabled}
        value={value || ''}
        onChange={(e, value) => {
          const toSet = { ...typeProps }
          if (fieldArray) {
            toSet[fieldArray][fieldArrayIndex][name] = value || {}
          } else {
            toSet[name] = value || {}
          }
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        onInput={(e, v) => {
          const toSet = { ...typeProps }
          if (fieldArray) {
            toSet[fieldArray][fieldArrayIndex][name] = { id: e.target.value }
          } else {
            toSet[name] = { id: e.target.value }
          }
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        fullWidth
        getOptionLabel={o => {
          if (!o) {
            return ''
          }
          if (!o.label) {
            return o.id || ''
          }
          return o.label + ' (' + o.id + ')'
        }}
        options={[
          ...Object.keys(snackbarClasses).map((key, index) => {
            const color = snackbarClasses[key].backgroundColor
            return { label: key, id: color }
          }),
          { label: 'White', id: '#ffffff' },
          { label: 'Blue', id: '#0000ff' },
          { label: 'Brown', id: '#a52a2a' },
          { label: 'Green', id: '#008000' },
          { label: 'Orange', id: '#ffa500' },
          { label: 'Pink', id: '#ffc0cb' },
          { label: 'Purple', id: '#800080' },
          { label: 'Red', id: '#ff0000' },
          { label: 'Grey', id: '#808080' }
        ]}
        renderInput={params => (
          <TextField variant='outlined' {...params} label={label} />
        )}
      />
      <div
        style={{
          margin: 10,
          borderRadius: 5,
          height: 50,
          width: 100,
          backgroundColor: value && value.id
        }}
      />
    </Grid>
  )
}
