import { Trans } from '@lingui/macro'
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { Field } from 'formik'
import { FormikCheckboxField } from 'formik-material-fields'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField'
import React, { useCallback, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { getEmptyLangValues } from 'utils'
import { TextFieldWithFormik } from '../common-components/TextFieldWithFormik'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'
import MultilanguageTextFieldWithFormik from '../internal/MultilanguageTextFieldWithFormik'
import ConditionalQuestionField from './ConditionalQuestionField'
import { questionTypes } from './QuestionWidget'
import SurveyCreationSliderFields from './SurveyCreationSliderFields'
import ValidationRulesField, {
  questionValidationRules
} from './ValidationRulesField'

export const objectsLinkedToQuestions = {
  External_Reviewer__c: [
    'Additional_comments__c',
    'Demographic_experience__c',
    'Fields_of_expertise__c',
    'Indigenous__c',
    'Professional_volunteer_experience__c',
    'Project_types__c',
    'Regional_experience__c',
    'Sector_experience__c',
    'Urban_rural__c',
    'Language_preference__c'
  ]
}

const SurveyCreationField = passedProps => {
  const {
    index,
    user,
    setFieldValue,
    setAnchorEl,
    setValues,
    values,
    conditionalObjects,
    shouldSchemaRebuildCallback
  } = passedProps

  const [expandedQuestion, setExpandedQuestion] = useState(true)
  const [warningDialogOpened, setWarningDialogOpened] = useState(false)
  // const [rebuildSchemaIndicator, rebuildSchema] = useState(0)

  const handleClick = event => {
    setAnchorEl(event)
  }

  const question = values.created[index]
  const forceConditionsRerender =
    question.forceRender || question.switchedOptions
  const otherTemplatesData = values.otherSections
  const isHeader =
    question.type === questionTypes.HEADER.id ||
    question.type === questionTypes.UPLOAD_FILES.id

  useEffect(() => {
    console.log('rebuild validation schema')
    shouldSchemaRebuildCallback()
  }, [
    question.isGroup,
    question.type,
    question.groupQuestions.length,
    question.isRequired,
    question.validationRules,
    question.min,
    question.max,
    question.maxCharacters
  ])

  return (
    <div style={{ width: 'auto', marginLeft: 30, marginRight: 30 }}>
      <WarningDialog
        question={question}
        user={user}
        open={warningDialogOpened}
        handleCloseDialog={useCallback(() => {
          setWarningDialogOpened(false)
        }, [])}
      />
      <Paper elevation={6} variant='outlined'>
        <Grid container direction='row' alignItems='center'>
          <Grid
            container
            direction='column'
            style={{ width: 'auto', flex: 1, margin: 10 }}
          >
            <Typography style={{ fontSize: '21px' }}>
              Question {index + 1}: {question.type}:{' '}
              <span style={{ fontSize: 9 }}>{question.id}</span>
            </Typography>
            {'\n'}
            <Typography>
              {getLabelFromTranslationData({
                langVersion: user.language,
                data: question.titleValue
              })}
            </Typography>
          </Grid>
          {question.joins.length > 1 && (
            <Tooltip
              title={
                <Trans>
                  This question is present in more than one survey template.
                  Editing it will change the question in all corresponding
                  templates
                </Trans>
              }
            >
              <IconButton
                onClick={() => {
                  setWarningDialogOpened(true)
                }}
              >
                <Icon>warning</Icon>
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            <Icon>menu</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              setExpandedQuestion(!expandedQuestion)
            }}
          >
            <Icon>expand_more</Icon>
          </IconButton>
        </Grid>

        <CreationFieldMemoized
          values={values}
          conditionalObjects={conditionalObjects}
          deleteQuestionFromGroup={useCallback(
            key => {
              const toSet = { ...values }
              const newQuestion = { ...question }
              if (newQuestion.groupQuestions[key].sfId) {
                const deletedQuestion = newQuestion.groupQuestions[key]
                const newDelete = [
                  {
                    type: 'Survey_Join_Template_and_Item__c',
                    id: deletedQuestion.joinId
                  },
                  {
                    type: 'SurveyTranslation__c',
                    id: deletedQuestion.itemTranslation.parentId
                  },
                  ...Object.values(deletedQuestion.itemTranslation.idValue).map(
                    id => {
                      return {
                        type: 'Survey_Text__c',
                        id: id
                      }
                    }
                  ),
                  {
                    type: 'Survey_Items__c',
                    id: deletedQuestion.sfId
                  }
                ]
                toSet.toDelete.push(...newDelete)
              }
              newQuestion.groupQuestions.splice(key, 1)
              toSet.created[index] = newQuestion
              setValues(toSet)
            },
            [question, values.toDelete]
          )}
          deleteOption={useCallback(
            key => {
              const toSet = { ...values }
              const newQuestion = { ...question }
              if (newQuestion.sfId) {
                const deletedOption = newQuestion.options[key]
                const newDelete = [...toSet.toDelete]
                if (deletedOption.parentId) {
                  newDelete.push(
                    {
                      type: 'SurveyTranslation__c',
                      id: deletedOption.parentId
                    },
                    {
                      type: 'SurveyItemDetail__c',
                      id: deletedOption.detailId
                    },
                    ...Object.values(deletedOption.idValue).map(id => {
                      return {
                        type: 'Survey_Text__c',
                        id: id
                      }
                    })
                  )
                }
                toSet.toDelete = newDelete
              }

              const newOptions = [...question.options]
              newOptions.splice(key, 1)
              newQuestion.options = newOptions
              toSet.created[index] = newQuestion
              setValues(toSet)
            },
            [question, values.toDelete]
          )}
          question={question}
          expandedQuestion={expandedQuestion}
          index={index}
          isHeader={isHeader}
          setFieldValue={setFieldValue}
          otherTemplatesData={otherTemplatesData}
        />
        {question.conditions &&
        (question.options.length > 0 ||
          (question.type !== 'SELECT_ONE' &&
            question.type !== 'SELECT_MULTIPLE'))
          ? question.conditions.map((item, key) => {
              let updateIndex = null
              if (question.switchedOptions) {
                const selectedOptionIndex = question.conditions[key].optionIndex
                updateIndex = question.switchedOptions[selectedOptionIndex]
              }
              return (
                <ConditionalQuestionField
                  getValuesFromProps={forceConditionsRerender}
                  updateOptionIndex={updateIndex}
                  otherTemplatesData={otherTemplatesData}
                  setFieldValue={setFieldValue}
                  key={key}
                  questionIndex={index}
                  index={key}
                  questionProps={question}
                  values={values}
                  user={user}
                />
              )
            })
          : null}
      </Paper>
    </div>
  )
}

const WarningDialog = React.memo(props => {
  const { question, user, open, handleCloseDialog } = props

  if (!open) {
    return null
  }
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>
          This question is present in more than one survey template!
        </Trans>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans>
            Editing this question will also affect templates displayed below:
          </Trans>
        </Typography>
        <List>
          {question.joins.map((join, index) => {
            const translationObj = JSON.parse(
              join.Item_Options__c
            ).parentTemplateName
            if (!translationObj || join.Id === question.joinId) {
              return
            }
            return (
              <ListItem key={index}>
                <ListItemIcon>
                  <Icon>article</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    translationObj[user.language] || translationObj['en']
                  }
                />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleCloseDialog}>
          <Trans>Ok</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
})

function RowsFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      maxLength={2}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isAllowed={({ floatValue }) => {
        if (!floatValue) {
          return true
        }
        return floatValue <= 20
      }}
      allowNegative={false}
      isNumericString={false}
    />
  )
}

const CreationFieldMemoized = React.memo(props => {
  const {
    question = { options: [] },
    expandedQuestion,
    index,
    deleteOption,
    deleteQuestionFromGroup,
    isHeader,
    setFieldValue,
    conditionalObjects,
    values
  } = props

  const conditionTypes = ['SELECT_YESNO', 'SELECT_ONE', 'SELECT_MULTIPLE']

  const groupTypes = [
    'SELECT_YESNO',
    'INPUT_TEXT',
    'INPUT_DATE',
    'INPUT_DATE_RANGE',
    'INPUT_TEXTAREA',
    'INPUT_NUMBER',
    'SLIDER',
    'SELECT_ONE'
  ]

  return (
    <Collapse in={expandedQuestion}>
      <Grid container>
        <MultilanguageTextFieldWithFormik
          name={`created.${index}.titleValue`}
          style={{ margin: 10 }}
          multiline
          rows={2}
          label={<Trans>Question</Trans>}
          inputDebounce
        />

        {(question.type === 'SELECT_ONE' ||
          question.type === 'SELECT_MULTIPLE') && (
          <div style={{ width: '100%' }}>
            <Button
              variant='contained'
              color='primary'
              style={{
                margin: 10
              }}
              onClick={e => {
                const toSet = [...question.options]
                toSet.push({ titleValue: getEmptyLangValues() })
                setFieldValue(`created.${index}.options`, toSet)
              }}
            >
              <Grid container direction='row' alignItems='center'>
                <Typography>
                  <Trans>Add new option</Trans>
                </Typography>
                <Icon style={{ marginLeft: 5 }}>add</Icon>
              </Grid>
            </Button>

            {question.options.map((item, key) => {
              return (
                <div
                  key={key}
                  style={{ width: 'auto', marginLeft: 10, marginRight: 10 }}
                >
                  <Grid
                    container
                    direction='row'
                    style={{
                      alignItems: 'center'
                    }}
                  >
                    <Typography style={{ textAlign: 'center', marginLeft: 15 }}>
                      <Trans>Option</Trans> {key + 1}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        deleteOption(key)
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        const toSet = [...question.options]
                        if (key > 0) {
                          const toMove = toSet[key]
                          const toReplace = toSet[key - 1]
                          toSet[key - 1] = toMove
                          toSet[key] = toReplace
                          const switched = {}
                          switched[key] = key - 1
                          switched[key - 1] = key
                          setFieldValue(
                            `created.${index}.switchedOptions`,
                            switched
                          )
                          setFieldValue(`created.${index}.options`, toSet)
                        }
                      }}
                    >
                      <Icon>arrow_upward</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        const toSet = [...question.options]
                        if (key < toSet.length - 1) {
                          const toMove = toSet[key]
                          const toReplace = toSet[key + 1]
                          toSet[key + 1] = toMove
                          toSet[key] = toReplace
                          const switched = {}
                          switched[key] = key + 1
                          switched[key + 1] = key
                          setFieldValue(
                            `created.${index}.switchedOptions`,
                            switched
                          )
                          setFieldValue(`created.${index}.options`, toSet)
                        }
                      }}
                    >
                      <Icon>arrow_downward</Icon>
                    </IconButton>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Typography>
                      <Trans>Require details on select:</Trans>{' '}
                    </Typography>
                    <FormikCheckboxGroupField
                      name={`created.${index}.options.${key}.requireDetails`}
                      style={{ margin: 5 }}
                      row='all'
                      options={[
                        { label: 'Text', value: 'text' },
                        { label: 'Number', value: 'number' }
                      ]}
                    />
                  </Grid>
                  {question.type === questionTypes.SELECT_MULTIPLE.id && (
                    <Grid container direction='row' alignItems='center'>
                      <Typography>
                        <Trans>
                          Clear and disable other options on select?
                        </Trans>
                      </Typography>
                      <FormikCheckboxField
                        style={{ marginLeft: 5 }}
                        name={`created.${index}.options.${key}.clearOnSelect`}
                      />
                    </Grid>
                  )}
                  <MultilanguageTextFieldWithFormik
                    key={key + 'titleValue'}
                    name={`created.${index}.options.${key}.titleValue`}
                    multiline
                    style={{ marginTop: 10, marginBottom: 10 }}
                    label={<Trans>Option</Trans>}
                    inputDebounce
                  />
                  {question.isLinked && (
                    <TextFieldWithFormik
                      key={key + 'API'}
                      multiline
                      style={{ marginTop: 10, marginBottom: 10 }}
                      name={`created.${index}.options.${key}.apiValue`}
                      variant='outlined'
                      label={<Trans>API value</Trans>}
                      fullWidth
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}

        {question.type === 'INPUT_TEXTAREA' && (
          <>
            <Grid container wrap='nowrap' style={{ padding: 10 }}>
              <Grid item xs>
                <TextFieldWithFormik
                  name={`created.${index}.rows`}
                  variant='outlined'
                  InputProps={{ inputComponent: RowsFormat }}
                  helperText={<Trans>Max: 20</Trans>}
                  label={<Trans>Rows number</Trans>}
                  fullWidth
                />
              </Grid>

              <Grid item style={{ paddingLeft: 10 }}>
                <FormikCheckboxField
                  label={<Trans>Allow expanding rows?</Trans>}
                  name={`created.${index}.allowRowsToExpand`}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ marginLeft: 20 }}
            >
              <Typography>
                <b>
                  <Trans>Create case from this question?</Trans>
                </b>
              </Typography>
              <FormikCheckboxField
                style={{ marginLeft: 5 }}
                name={`created.${index}.createCase`}
              />
            </Grid>
            {question.createCase && (
              <div style={{ paddingLeft: 10, paddingRight: 10, width: '100%' }}>
                <Field>
                  {({ form: { values } }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      select
                      label={<Trans>Case type</Trans>}
                      value={question.caseType || ''}
                      onChange={e =>
                        setFieldValue(
                          `created.${index}.caseType`,
                          e.target.value
                        )
                      }
                    >
                      {values.caseTypes.map((item, index) => (
                        <MenuItem value={item.value} key={index}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </div>
            )}
          </>
        )}

        {question.type === 'SLIDER' && (
          <SurveyCreationSliderFields
            question={question}
            index={index}
            setFieldValue={setFieldValue}
          />
        )}

        {question.type === questionTypes.INPUT_NUMBER.id && (
          <Grid container style={{ padding: 5 }} wrap='nowrap'>
            <Grid item xs style={{ padding: 5 }}>
              <TextFieldWithFormik
                name={`created.${index}.min`}
                variant='outlined'
                label={<Trans>Minimum value</Trans>}
                fullWidth
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextFieldWithFormik
                name={`created.${index}.max`}
                variant='outlined'
                label={<Trans>Maximum value</Trans>}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        {question.type === questionTypes.UPLOAD_FILES.id && (
          <>
            <TextFieldWithFormik
              style={{ margin: 10 }}
              name={`created.${index}.fileDescription`}
              variant='outlined'
              label={<Trans>File description</Trans>}
              fullWidth
            />
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ marginLeft: 20 }}
            >
              <Typography>
                <b>
                  <Trans>Show file description in survey?</Trans>
                </b>
              </Typography>
              <FormikCheckboxField
                style={{ marginLeft: 5 }}
                name={`created.${index}.showDescription`}
              />
            </Grid>
          </>
        )}

        {[
          questionTypes.INPUT_TEXT.id,
          questionTypes.INPUT_TEXTAREA.id
        ].includes(question.type) && (
          <TextFieldWithFormik
            style={{ margin: 10 }}
            name={`created.${index}.maxCharacters`}
            variant='outlined'
            label={<Trans>Max characters</Trans>}
            fullWidth
            InputProps={{ inputComponent: DefaultNumericFormat }}
          />
        )}

        <Grid
          container
          direction='row'
          alignItems='center'
          style={{ marginLeft: 20 }}
        >
          <Typography>
            <b>
              <Trans>Has a tooltip?</Trans>
            </b>
          </Typography>
          <FormikCheckboxField
            onChange={e => {
              if (!e) {
                setFieldValue(`created.${index}.tooltipAtBottom`, false)
              }
            }}
            style={{ marginLeft: 5 }}
            name={`created.${index}.hasTooltip`}
          />
        </Grid>

        {question.hasTooltip && (
          <div style={{ marginLeft: 20, width: '100%', marginRight: 20 }}>
            <Grid container direction='row' alignItems='center'>
              <Typography>
                <Trans>Place tooltip at bottom</Trans>
              </Typography>
              <FormikCheckboxField
                style={{ marginLeft: 5 }}
                name={`created.${index}.tooltipAtBottom`}
              />
            </Grid>
            <MultilanguageTextFieldWithFormik
              name={`created.${index}.tooltipValue`}
              rows={2}
              multiline
              style={{ marginTop: 10, marginBottom: 10 }}
              label={<Trans>Tooltip</Trans>}
              inputDebounce
            />
          </div>
        )}

        {!isHeader && (
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ marginLeft: 20 }}
          >
            <Typography>
              <b>
                <Trans>Is linked?</Trans>
              </b>
            </Typography>
            <FormikCheckboxField
              onChange={e => {
                const toSet = { ...question }
                toSet.isLinked = e
                if (!e) {
                  toSet.options.forEach(option => {
                    option.apiValue = null
                  })
                  toSet.linkedObject = null
                  toSet.linkedField = null
                }
                setFieldValue(`created.${index}`, toSet)
              }}
              style={{ marginLeft: 5 }}
              name={`created.${index}.isLinked`}
            />
          </Grid>
        )}

        {question.isLinked && !isHeader && (
          <>
            <FormControl
              style={{ marginLeft: 20, width: '100%', marginRight: 20 }}
              name={`created.${index}.linkedObject`}
            >
              <InputLabel>
                <Trans>Linked object</Trans>
              </InputLabel>
              <Select
                value={question.linkedObject || ''}
                onChange={e => {
                  const toSet = { ...question }
                  toSet.linkedObject = e.target.value
                  toSet.linkedField = null
                  setFieldValue(`created.${index}`, toSet)
                }}
              >
                {Object.keys(objectsLinkedToQuestions).map((key, index) => (
                  <MenuItem value={key} key={index}>
                    <Trans id={key} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {question.linkedObject &&
              objectsLinkedToQuestions[question.linkedObject] && (
                <FormControl
                  style={{
                    marginLeft: 20,
                    width: '100%',
                    marginTop: 10,
                    marginRight: 20
                  }}
                >
                  <InputLabel>
                    <Trans>Linked field</Trans>
                  </InputLabel>
                  <Select
                    value={question.linkedField || ''}
                    onChange={e => {
                      const toSet = { ...question }
                      toSet.linkedField = e.target.value
                      setFieldValue(`created.${index}`, toSet)
                    }}
                  >
                    {objectsLinkedToQuestions[question.linkedObject].map(
                      (item, index) => (
                        <MenuItem key={index} value={item}>
                          <Trans id={item} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
          </>
        )}

        {!isHeader && (
          <>
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ marginLeft: 20 }}
            >
              <Typography>
                <b>
                  <Trans>Is required?</Trans>
                </b>
              </Typography>
              <FormikCheckboxField
                style={{ marginLeft: 5 }}
                name={`created.${index}.isRequired`}
              />
            </Grid>
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ marginLeft: 20 }}
            >
              <Typography>
                <b>
                  <Trans>Is non applicable?</Trans>
                </b>
              </Typography>
              <FormikCheckboxField
                style={{ marginLeft: 5 }}
                name={`created.${index}.nonApplicable`}
              />
            </Grid>
            {returnConditionalBasedOnObject({
              index,
              question,
              setFieldValue,
              conditionalObjects
            })}
          </>
        )}

        <div style={{ paddingLeft: 20, paddingTop: 5 }}>
          <Typography>
            <b>
              <Trans>Skip options</Trans>
            </b>
          </Typography>
          <Grid container alignItems='center'>
            <Typography>
              <Trans>Skip in export?</Trans>
            </Typography>
            <FormikCheckboxField
              style={{ marginLeft: 5 }}
              name={`created.${index}.skipInExport`}
            />
          </Grid>
          <Grid container alignItems='center'>
            <Typography>
              <Trans>Skip in print?</Trans>
            </Typography>
            <FormikCheckboxField
              style={{ marginLeft: 5 }}
              name={`created.${index}.skipInPrint`}
            />
          </Grid>
        </div>

        {groupTypes.includes(question.type) ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ marginLeft: 20 }}
          >
            <Typography>
              <b>
                <Trans>Is a question group?</Trans>
              </b>
            </Typography>
            <FormikCheckboxField
              style={{ marginLeft: 5 }}
              name={`created.${index}.isGroup`}
              onChange={e => {
                const toSet = { ...question }
                toSet.isGroup = e
                if (!e) {
                  const newValidation = []
                  toSet.validationRules.forEach(condition => {
                    if (!condition.type) {
                      newValidation.push(condition)
                    } else {
                      if (!questionValidationRules[condition.type].applyToAll) {
                        newValidation.push(condition)
                      }
                    }
                  })
                  toSet.validationRules = newValidation
                }
                setFieldValue(`created.${index}`, toSet)
              }}
            />
          </Grid>
        ) : null}
        {question.isGroup && groupTypes.includes(question.type) ? (
          <div>
            <Button
              variant='outlined'
              style={{ margin: 10 }}
              onClick={() => {
                const toSet = { ...question }
                const newGroup = [...toSet.groupQuestions]
                const crypto = require('crypto')
                const assignId = crypto.randomBytes(20).toString('hex')
                newGroup.push({
                  titleValue: getEmptyLangValues(),
                  id: assignId
                })
                toSet.groupQuestions = newGroup
                setFieldValue(`created.${index}`, toSet)
              }}
            >
              <Grid container direction='row' alignItems='center'>
                <Trans>Add question to a group</Trans>
                <Icon style={{ marginLeft: 5 }}>add</Icon>
              </Grid>
            </Button>
          </div>
        ) : null}
        <Grid container direction='column'>
          {question.groupQuestions &&
          question.isGroup &&
          groupTypes.includes(question.type)
            ? question.groupQuestions.map((item, key) => {
                return (
                  <div key={key} style={{ padding: 10 }}>
                    <Grid container direction='row' alignItems='center'>
                      <Trans>Delete question from a group</Trans>
                      <IconButton
                        onClick={() => {
                          deleteQuestionFromGroup(key)
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                    <MultilanguageTextFieldWithFormik
                      key={key + 'titleValue'}
                      name={`created.${index}.groupQuestions.${key}.titleValue`}
                      style={{ marginTop: 10, marginBottom: 10 }}
                      label={<Trans>Question</Trans>}
                      inputDebounce
                    />
                  </div>
                )
              })
            : null}
        </Grid>
        {conditionTypes.includes(question.type) &&
        (question.options.length > 0 ||
          (question.type !== 'SELECT_ONE' &&
            question.type !== 'SELECT_MULTIPLE')) ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ margin: 10 }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                let conditions = []
                let conditionsToCheck = []
                let externalConditions = []
                if (question.conditions) {
                  conditions = [...question.conditions]
                  conditionsToCheck = [...question.conditionsToCheck]
                  externalConditions = [...question.externalConditions]
                }
                conditions.push({
                  condition: 'Choose condition!',
                  optionIndex: -1,
                  questionId: -1
                })
                conditionsToCheck.push({})
                externalConditions.push({})
                const toSet = { ...question }
                toSet.conditions = conditions
                toSet.conditionsToCheck = conditionsToCheck
                toSet.externalConditions = externalConditions
                setFieldValue(`created.${index}`, toSet)
              }}
            >
              <Grid container direction='row' alignItems='center'>
                <Trans>Add condition</Trans>
                <Icon style={{ marginLeft: 5 }}>add</Icon>
              </Grid>
            </Button>
          </Grid>
        ) : null}
        {(question.type === questionTypes.INPUT_NUMBER.id ||
          question.type === questionTypes.SLIDER.id) && (
          <ValidationRulesField
            index={index}
            question={question}
            setFieldValue={setFieldValue}
          />
        )}
      </Grid>
    </Collapse>
  )
})

function returnConditionalBasedOnObject ({
  question,
  index,
  setFieldValue,
  conditionalObjects
}) {
  const disableValue = question.conditionForObjectField
  const objValue = question.conditionalObjectName
  const fieldValue = question.conditionalObjectField
  const subfieldValue = question.conditionalObjectSubfield
  let subfields = null
  if (objValue && fieldValue) {
    try {
      subfields = conditionalObjects[objValue].fields[fieldValue].subfields
    } catch (error) {
      console.error(
        'Value saved in database was not matched with object in code!'
      )
    }
  }
  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ marginLeft: 20, marginTop: 10 }}
      >
        <FormControl
          style={{ width: 100, marginRight: 5 }}
          name={`created.${index}.conditionForObjectField`}
        >
          <Select
            value={disableValue || ''}
            onChange={e => {
              const toSet = { ...question }
              toSet.conditionForObjectField = e.target.value
              setFieldValue(`created.${index}`, toSet)
            }}
          >
            <MenuItem value='enable'>
              <Trans>Appears</Trans>
            </MenuItem>
            <MenuItem value='disable'>
              <Trans>Disappears</Trans>
            </MenuItem>
          </Select>
        </FormControl>
        <Typography>
          <b>
            <Trans> based on object field?</Trans>
          </b>
        </Typography>
        <FormikCheckboxField
          onChange={e => {
            if (!e) {
              const toSet = { ...question }
              toSet.conditionalObject = e
              toSet.conditionalObjectSubfield = null
              toSet.conditionalObjectName = null
              toSet.conditionalObjectField = null
              setFieldValue(`created.${index}`, toSet)
            }
          }}
          style={{ marginLeft: 5 }}
          name={`created.${index}.conditionalObject`}
        />
      </Grid>

      {question.conditionalObject && (
        <Grid container direction='row'>
          <FormControl
            style={{ marginLeft: 20, marginRight: 20, width: '100%' }}
            name={`created.${index}.conditionalObjectName`}
          >
            <InputLabel>
              <Trans>Conditional object</Trans>
            </InputLabel>
            <Select
              disabled={!question.conditionForObjectField}
              value={objValue}
              onChange={e => {
                const toSet = { ...question }
                toSet.conditionalObjectName = e.target.value
                toSet.conditionalObjectSubfield = null
                toSet.conditionalObjectField = null
                setFieldValue(`created.${index}`, toSet)
              }}
            >
              {Object.keys(conditionalObjects).map((key, index) => {
                const display = conditionalObjects[key].display
                return (
                  <MenuItem value={key} key={index}>
                    <Trans id={display} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl
            style={{
              marginLeft: 20,
              marginRight: 20,
              width: '100%',
              marginTop: 10,
              marginBottom: 10
            }}
          >
            <InputLabel>
              <Trans>Linked field</Trans>
            </InputLabel>
            <Select
              value={fieldValue}
              name={`created.${index}.conditionalObjectField`}
              disabled={!objValue}
              onChange={e => {
                const toSet = { ...question }
                toSet.conditionalObjectSubfield = null
                toSet.conditionalObjectField = e.target.value
                setFieldValue(`created.${index}`, toSet)
              }}
            >
              {objValue &&
                Object.keys(conditionalObjects[objValue].fields).map(
                  (item, index) => {
                    const display =
                      conditionalObjects[objValue].fields[item].display
                    return (
                      <MenuItem key={index} value={item}>
                        <Trans id={display} />
                      </MenuItem>
                    )
                  }
                )}
            </Select>
          </FormControl>
          {subfields && (
            <Grid container direction='row' wrap='nowrap'>
              <FormControl
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <InputLabel>
                  <Trans>Linked subfield</Trans>
                </InputLabel>
                <Select
                  value={subfieldValue}
                  name={`created.${index}.conditionalObjectSubfield`}
                  onChange={e => {
                    const toSet = { ...question }
                    toSet.conditionalObjectSubfield = e.target.value
                    setFieldValue(`created.${index}`, toSet)
                  }}
                >
                  {subfields.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <IconButton
                style={{
                  marginRight: 10,
                  marginTop: 15,
                  width: 50,
                  height: 50
                }}
                onClick={() => {
                  const toSet = { ...question }
                  toSet.conditionalObjectSubfield = null
                  setFieldValue(`created.${index}`, toSet)
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

export default React.memo(SurveyCreationField)
