import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid
} from '@material-ui/core'
const { portalLanguagesData } = require('app/appSettings')
const { languages } = require('translation/I18nConnectedProvider')

const TranslatedFor = ({ values, setFieldValue, style = {} }) => {
  const handleChange = (lang, value) => {
    const translatedFor = {
      ...values.translatedFor,
      [lang]: { ...values.translatedFor?.[lang], value }
    }
    setFieldValue('translatedFor', translatedFor)
  }

  return (
    <Grid style={{ paddingLeft: 10, ...style }}>
      <FormLabel
        component='legend'
        style={{ alignSelf: 'center', marginRight: '40px' }}
      >
        <Trans>TRANSLATED_FOR</Trans>
      </FormLabel>
      <FormGroup aria-label='position' row>
        {languages.map((lang, index) => {
          const checked = values?.translatedFor?.[lang]?.value
          const checkbox = (
            <Checkbox
              checked={checked}
              onClick={() => handleChange(lang, !checked)}
              key={lang}
            />
          )
          return (
            <FormControlLabel
              key={lang}
              control={checkbox}
              label={portalLanguagesData[lang]?.labelTrans}
              labelPlacement='end'
            />
          )
        })}
      </FormGroup>
    </Grid>
  )
}

export default TranslatedFor
