import { Tooltip, withStyles } from '@material-ui/core'

/**
 * Tooltip with transparent background
 * @category Common components
 * @subcategory MUI extended
 * @component
 * @returns {JSX.Element}
 */
function TransparentBackGroundToolitp (props) {
  const StylisedTooltip = withStyles({
    tooltip: {
      backgroundColor: 'transparent'
    }
  })(Tooltip)

  return <StylisedTooltip {...props} />
}

export default TransparentBackGroundToolitp
