import { Trans } from '@lingui/macro'
import { Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import { findLabel } from './config/baseFilters'
import './styles/ResourcesDetailsCard.css'

const ResourcesDetailsCard = ({ resource, onClickHandler, selectFilter }) => {
  return (
    <div className='resources-details-card'>
      <div onClick={onClickHandler} className='resources-details-card__back'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M7.825 11L20 11V13L7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11Z'
            fill='white'
          />
        </svg>
        <Trans>RESOURCES_BACK_BUTTON</Trans>
      </div>
      <Card className='resources-details-card__card'>
        <div className='resources-details-card__title-container'>
          <div className='resources-details-card__card-title'>
            {resource.Resource_Name}
          </div>

          <div className='resources-details-card__topic-cards'>
            {resource.Topic.map(item => {
              return findLabel({ filterKey: 'Topic', optionKey: item })
            }).map((id, idx) => {
              return (
                <a
                  key={id}
                  onClick={e => {
                    selectFilter(resource.Topic[idx])
                  }}
                >
                  <div key={id} className='resources-details-card__topic-card'>
                    {id}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                    >
                      <path
                        d='M21.1313 18.75H12V17.25H21.1313L16.9313 13.05L18 12L24 18L18 24L16.9313 22.95L21.1313 18.75Z'
                        fill='black'
                        fill-opacity='0.54'
                      />
                    </svg>
                  </div>
                </a>
              )
            })}
          </div>
        </div>

        <div className='resources-details-card__body'>
          <div className='resources-details-card__description'>
            {resource.Description}
          </div>
          <div className='resources-details-card__properties'>
            <div className='resources-details-card__property'>
              <div className='resources-details-card__property-title'>
                <Trans>RESOURCES_TYPE</Trans>
              </div>
              <div className='resources-details-card__property-value'>
                {findLabel({ filterKey: 'Type', optionKey: resource.Type })}
              </div>
            </div>
            <div className='resources-details-card__property'>
              <div className='resources-details-card__property-title'>
                <Trans>RESOURCES_ACCESS</Trans>
              </div>
              <div className='resources-details-card__property-value'>
                {findLabel({ filterKey: 'Access', optionKey: resource.Access })}
              </div>
            </div>
            <div className='resources-details-card__property'>
              <div className='resources-details-card__property-title'>
                <Trans>RESOURCES_LOCATION</Trans>
              </div>
              <div className='resources-details-card__property-value'>
                {resource.Location.map(item => {
                  return findLabel({ filterKey: 'Location', optionKey: item })
                }).map((item, idx, arr) => {
                  return (
                    <div>
                      {item}
                      {arr.length - 1 !== idx ? ', ' : ''}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='resources-details-card__property'>
              <div className='resources-details-card__property-title'>
                <Trans>LANGUAGE</Trans>
              </div>
              <div className='resources-details-card__property-value'>
                {resource.Language_Of_Resource.map(item => {
                  return findLabel({
                    filterKey: 'Language_Of_Resource',
                    optionKey: item
                  })
                }).map((item, idx, arr) => {
                  return (
                    <div>
                      {item}
                      {arr.length - 1 !== idx ? ', ' : ''}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='resources-details-card__property'>
              <div className='resources-details-card__property-title'>
                <Trans>RESOURCES_YEAR</Trans>
              </div>
              <div className='resources-details-card__property-value'>
                {resource.Year}
              </div>
            </div>
          </div>
          <a
            className='resources-details-card__file'
            href={resource.Resource_URL}
            target='_blank'
          >
            <div className='resources-details-card__file_icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
              >
                <path
                  d='M6.5 22C5.95 22 5.47917 21.8042 5.0875 21.4125C4.69583 21.0208 4.5 20.55 4.5 20V4C4.5 3.45 4.69583 2.97917 5.0875 2.5875C5.47917 2.19583 5.95 2 6.5 2H14.5L20.5 8V20C20.5 20.55 20.3042 21.0208 19.9125 21.4125C19.5208 21.8042 19.05 22 18.5 22H6.5ZM13.5 9H18.5L13.5 4V9Z'
                  fill='black'
                  fill-opacity='0.54'
                />
                <path
                  d='M9.5 20L8.125 18.55L10.675 16H2.5V14H10.675L8.125 11.45L9.5 10L14.5 15L9.5 20Z'
                  fill='white'
                />
              </svg>
            </div>
            <div className='resources-details-card__file_text'>
              <Trans>RESOURCES_GO_TO_RESOURCE_BUTTON</Trans>
            </div>
          </a>
        </div>

        <div className='resources-details-card__footer'>
          <div className='resources-details-card__source'>
            <Trans>RESOURCES_CARD_DETAILS_SOURCE</Trans>:{' '}
            {resource.Organization_Name}
          </div>
          <div className='resources-details-card__id'>
            <Trans>RESOURCES_CARD_DETAILS_ID</Trans>: {resource.Number}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ResourcesDetailsCard

ResourcesDetailsCard.propTypes = {
  resource: PropTypes.object,
  onClickHandler: PropTypes.func,
  selectFilter: PropTypes.func
}
