import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { currencyFormatedString } from 'app/views/common/Formats'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormBudgetUpdatePDF = ({
  id,
  langVersion,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  i18n,
  disabled,
  helpText,
  value,
  ...props
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily

  const pdfStyles = StyleSheet.create({
    headerCell: {
      textAlign: 'left',
      alignItems: 'left',
      justifyContent: 'center',
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    },
    cell: {
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse',
      alignItems: 'center'
    }
  })

  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps

  const titleRow = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 'bold'
  }
  
  if (pdfHeaderTextProps.includes('italics')) {
    titleRow.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    titleRow.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    titleRow.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    titleRow.fontSize = +pdfFontSize
  }

  const { expensesLines, revenuesLines, budgetUpdateActual, latestUpdate } =
    value

  const revenueBudgetUpdateComments = []
  const revenueBudgetUpdateActualComments = []

  Object.entries(revenuesLines).forEach(([key, obj]) => {
    if (obj.budgetUpdateChange?.changeDetails) {
      revenueBudgetUpdateComments.push(obj.budgetUpdateChange.changeDetails)
    }
    if (obj.budgetUpdateActual?.actualComments) {
      revenueBudgetUpdateActualComments.push(
        obj.budgetUpdateActual.actualComments
      )
    }
  })

  const expenseBudgetUpdateComments = []
  const expenseBudgetUpdateActualComments = []

  Object.entries(expensesLines).forEach(([key, obj]) => {
    if (obj.budgetUpdateChange?.changeDetails) {
      expenseBudgetUpdateComments.push(obj.budgetUpdateChange.changeDetails)
    }
    if (obj.budgetUpdateActual?.actualComments) {
      expenseBudgetUpdateActualComments.push(
        obj.budgetUpdateActual.actualComments
      )
    }
  })

  const budgetUpdateCommentsLength =
    revenueBudgetUpdateComments.length + expenseBudgetUpdateComments.length
  const budgetUpdateActualCommentsLength =
    revenueBudgetUpdateActualComments.length +
    expenseBudgetUpdateActualComments.length

  const hasThirdCol = budgetUpdateCommentsLength > 0
  const hasFifthCol = budgetUpdateActualCommentsLength > 0
  const totalColumns = 3 + (hasThirdCol ? 1 : 0) + (hasFifthCol ? 1 : 0)
  const columnWidth = `${100 / totalColumns}%`
  
  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      firstCol: {
        justifyContent: 'center',
        width: columnWidth
      },
      secondCol: {
        width: columnWidth
      },
      thirdCol: {
        width: columnWidth
      },
      fourthCol: {
        width: columnWidth
      },
      fifthCol: {
        width: columnWidth
      }
    })
  }

  let revenuesSum = 0
  let expensesSum = 0
  let revenuesSumActual = 0
  let expensesSumActual = 0

  Object.entries(revenuesLines).forEach(([key, obj]) => {
    const {
      budgetUpdateChange,
      budgetUpdateActual,
      applicationBudget,
      latestUpdate
    } = obj
    const value =
      budgetUpdateChange?.change ||
      latestUpdate?.change ||
      applicationBudget.value
    const actual = budgetUpdateActual?.actual
    if (value) {
      revenuesSum += +value
    }
    if (actual) {
      revenuesSumActual += +actual
    }
  })
  Object.entries(expensesLines).forEach(([key, obj]) => {
    const {
      budgetUpdateChange,
      budgetUpdateActual,
      applicationBudget,
      latestUpdate
    } = obj
    const value =
      budgetUpdateChange?.change ||
      latestUpdate?.change ||
      applicationBudget.value
    const actual = budgetUpdateActual?.actual
    if (value) {
      expensesSum += +value
    }
    if (actual) {
      expensesSumActual += +actual
    }
  })

  const adjustedColStyles = { 
    ...styles.tableCol, 
    flexGrow: 1, 
    flexShrink: 1, 
    alignSelf: 'stretch', 
    flexWrap: 'wrap' 
  }
  delete adjustedColStyles.minHeight

  return (
    <>
      <View style={styles.table}>
        <View style={{...styles.tableRow, flexGrow: 1, alignItems: 'flex-start'}}>
          <View style={[adjustedColStyles, styles.firstCol]}>
            <Text>{' '}</Text>
          </View>
          <View
            style={[
              adjustedColStyles, 
              styles.secondCol,
              titleRow
            ]}
          >
            <Text style={styles.tableCell}>
              {myI18n._(t`Amount`)}
            </Text>
          </View>
          {hasThirdCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.thirdCol, 
                titleRow
              ]}
            >
              <Text style={styles.tableCell}>
                {myI18n._(t`BUDGET_UPDATE_CHANGES_COMMENTS_LABEL`)}
              </Text>
            </View>
          )}
          <View
            style={[
              adjustedColStyles, 
              styles.fourthCol,
              titleRow
            ]}
          >
            <Text style={styles.tableCell}>
              {myI18n._(t`Actual`)}
            </Text>
          </View>
          {hasFifthCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.fifthCol,
                titleRow
              ]}
            >
              <Text style={styles.tableCell}>
                {myI18n._(t`BUDGET_UPDATE_ACTUAL_COMMENTS_LABEL`)}
              </Text>
            </View>
          )}
        </View>

        <View 
          style={{ 
            ...styles.tableRow, 
            ...adjustedColStyles, 
            width: '100%', 
            alignItems: 'center' 
            }}
        >
          <Text style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</Text>
        </View>

        {Object.entries(revenuesLines).map(([key, item]) => {
          const {
            budgetUpdateChange,
            budgetUpdateActual,
            applicationBudget,
            latestUpdate
          } = item
          const change =
            budgetUpdateChange.change ||
            latestUpdate?.change ||
            applicationBudget.value
          const budgetUpdateComments = budgetUpdateChange?.changeDetails || ''
          const budgetUpdateActualComments =
            budgetUpdateActual?.actualComments || ''

          return (
            <View 
              style={{
                ...styles.tableRow, 
                flexGrow: 1, 
                alignItems: 'flex-start'
                }} 
              key={key}
            >
              <View style={[adjustedColStyles, styles.firstCol]}>
                <Text 
                  style={[
                    styles.tableCell, 
                    { flexWrap: 'wrap' }]}
                >
                  {myI18n._(key)}
                </Text>
              </View>
              <View
                style={[
                  adjustedColStyles, 
                  styles.secondCol, 
                  styles.centeredCell
                ]}
              >
                <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                  {currencyFormatedString(change, langVersion)}
                </Text>
              </View>
              {hasThirdCol && (
                <View
                  style={[
                    adjustedColStyles,
                    styles.thirdCol,
                    styles.centeredCell
                  ]}
                >
                  <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                    {budgetUpdateComments}
                  </Text>
                </View>
              )}
              <View
                style={[adjustedColStyles, styles.fourthCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                  {currencyFormatedString(
                    budgetUpdateActual?.actual,
                    langVersion
                  )}
                </Text>
              </View>
              {hasFifthCol && (
                <View
                  style={[
                    adjustedColStyles,
                    styles.fifthCol,
                    styles.centeredCell, 
                  ]}
                >
                  <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                    {budgetUpdateActualComments}
                  </Text>
                </View>
              )}
            </View>
          )
        })}

        <View 
          style={{ 
            ...styles.tableRow, 
            ...adjustedColStyles, 
            width: '100%', 
            alignItems: 'center' 
          }}
        >
          <Text style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</Text>
        </View>

        {Object.entries(expensesLines).map(([key, item]) => {
          const {
            budgetUpdateChange,
            budgetUpdateActual,
            applicationBudget,
            latestUpdate
          } = item
          const change =
            budgetUpdateChange.change ||
            latestUpdate?.change ||
            applicationBudget.value
          const budgetUpdateComments = budgetUpdateChange?.changeDetails || ''
          const budgetUpdateActualComments =
            budgetUpdateActual?.actualComments || ''

          return (
            <View 
              style={{
                ...styles.tableRow, 
                flexGrow: 1, 
                alignItems: 'flex-start'
              }} 
              key={key}
            >
              <View 
                style={[
                  adjustedColStyles, 
                  styles.firstCol
                ]}
              >
                <Text 
                  style={[
                    styles.tableCell, 
                    { flexWrap: 'wrap' }
                  ]}
                >
                  {myI18n._(key)}
                </Text>
              </View>
              <View
                style={[
                  adjustedColStyles, 
                  styles.secondCol, 
                  styles.centeredCell
                ]}
              >
                <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                  {currencyFormatedString(change, langVersion)}
                </Text>
              </View>
              {hasThirdCol && (
                <View
                  style={[
                    adjustedColStyles,
                    styles.thirdCol,
                    styles.centeredCell
                  ]}
                >
                  <Text 
                    style={[
                      styles.tableCell, 
                      { flexWrap: 'wrap' }
                    ]}
                  >
                    {budgetUpdateComments}
                  </Text>
                </View>
              )}
              <View
                style={[adjustedColStyles, styles.fourthCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                  {currencyFormatedString(
                    budgetUpdateActual?.actual,
                    langVersion
                  )}
                </Text>
              </View>
              {hasFifthCol && (
                <View
                  style={[
                    adjustedColStyles,
                    styles.fifthCol,
                    styles.centeredCell
                  ]}
                >
                  <Text style={[styles.tableCell, { flexWrap: 'wrap' }]}>
                    {budgetUpdateActualComments}
                  </Text>
                </View>
              )}
            </View>
          )
        })}

        <View style={styles.tableRow}>
          <View 
            style={[
              adjustedColStyles, 
              styles.firstCol
            ]}
          >
            <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>
              {myI18n._(t`Total Revenews`)}
            </Text>
          </View>
          <View
            style={[
              adjustedColStyles, 
              styles.secondCol, 
              styles.centeredCell
            ]}
          >
            <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>
              {currencyFormatedString(revenuesSum, langVersion)}
            </Text>
          </View>
          {hasThirdCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.thirdCol, 
                styles.centeredCell
              ]}
            >
              <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>{''}</Text>
            </View>
          )}
          <View
            style={[
              adjustedColStyles, 
              styles.fourthCol, 
              styles.centeredCell
            ]}
          >
            <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>
              {currencyFormatedString(revenuesSumActual, langVersion)}
            </Text>
          </View>
          {hasFifthCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.fifthCol, 
                styles.centeredCell
              ]}
            >
              <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>{''}</Text>
            </View>
          )}
        </View>

        <View style={styles.tableRow}>
          <View 
            style={[
              adjustedColStyles, 
              styles.firstCol
            ]}
          >
            <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>
              {myI18n._(t`Total Expenses`)}
            </Text>
          </View>
          <View
            style={[
              adjustedColStyles, 
              styles.secondCol, 
              styles.centeredCell
            ]}
          >
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(expensesSum, langVersion)}
            </Text>
          </View>
          {hasThirdCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.thirdCol, 
                styles.centeredCell
              ]}
            >
              <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>{''}</Text>
            </View>
          )}
          <View
            style={[
              adjustedColStyles, 
              styles.fourthCol, 
              styles.centeredCell
            ]}
          >
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(expensesSumActual, langVersion)}
            </Text>
          </View>
          {hasFifthCol && (
            <View
              style={[
                adjustedColStyles, 
                styles.fifthCol, 
                styles.centeredCell
              ]}
            >
              <Text style={[styles.mainTableCell, { flexWrap: 'wrap' }]}>{''}</Text>
            </View>
          )}
        </View>
      </View>
    </>
  )
}
