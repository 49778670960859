import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { FormColorAutocomplete } from 'app/views/forms/common/FormColorAutocomplete'
import { useDispatch } from 'react-redux'
import { NumberFormatDefault } from '../../common/Common'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormText } from './FormText'
import DebouncedTextField from '../../common/DebouncedTextField'

export function FormEditorText ({
  editMode,
  disabled,
  injectable,
  depth,
  typeProps = {},
  showPrintProps,
  showPdfProps,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    italics,
    bold,
    underline,
    fontSize,
    justify,
    alignCenter
  } = typeProps
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormText {...props} editor typeProps={typeProps} />
  }
  return (
    <div>
      <FormColorAutocomplete
        style={{ marginBottom: 10 }}
        disabled={disabled}
        typeProps={typeProps}
        value={textColor}
        name='textColor'
        depth={depth}
        label={<Trans>Text color</Trans>}
        injectable={injectable}
      />

      <DebouncedTextField
        disabled={disabled}
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        multiline
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={Boolean(bold)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.bold = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Bold</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={Boolean(italics)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.italics = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Italics</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={Boolean(underline)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.underline = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Underline</Trans>}
        />
      </div>
      <FormColorAutocomplete
        disabled={disabled}
        typeProps={typeProps}
        value={backgroundColor}
        name='backgroundColor'
        depth={depth}
        label={<Trans>Background color</Trans>}
        injectable={injectable}
      />

      {/* <TextField
        value={padding || ''}
        style={{ marginTop: 5, marginBottom: 5 }}
        fullWidth
        label={<Trans>Padding</Trans>}
        InputProps={{ inputComponent: NumberFormatDefault }}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.padding = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      /> */}

      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={Boolean(alignCenter)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.alignCenter = e.target.checked
                delete toSet.justify
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Align text to center</Trans>}
        />
      )}
      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={Boolean(justify)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.justify = e.target.checked
                delete toSet.alignCenter
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Justify text</Trans>}
        />
      )}
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
    </div>
  )
}
