import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { pdfDefaultFontSize } from 'app/views/forms/common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import caCities from './canada_cities_data.json'
import divisionData from './census_divisions_by_id.json'

const styles = {
  text: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
    marginLeft: 8
  },
  question: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
    marginTop: 8,
    marginBottom: 8
  },
  description: {
    fontSize: pdfDefaultFontSize - 2,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginTop: 4,
    marginBottom: 4
  },
  required: {
    fontSize: pdfDefaultFontSize - 2,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginLeft: 4,
    marginTop: 4
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 4
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 4,
    width: '48%',
    flexWrap: 'nowrap'
  },
  odd: {
    marginLeft: '4%'
  }
}

export const FormCensusDivisionFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.description.fontFamily = inuktitutFormPdfFontFamily
    styles.question.fontFamily = inuktitutFormPdfFontFamily
    styles.text.fontFamily = inuktitutFormPdfFontFamily
    styles.required.fontFamily = inuktitutFormPdfFontFamily
  }

  const nuCities = caCities.filter(city => city.province_code === 'NU')

  const nuCityLabels = nuCities.map(city => {
    if (langVersion === 'iu-cans') {
      return city.nameIU
    } else {
      return getLabelFromTranslationData({
        data: { portalTranslationId: city.name }
      })
    }
  })

  const divisions = Object.values(divisionData).filter(division =>
    ['Kitikmeot', 'Kivalliq', 'Qikiqtaaluk'].includes(division.name)
  )

  const divisionLabels = divisions.map(division => {
    if (langVersion === 'iu-cans') {
      return division.nameIU
    } else {
      return getLabelFromTranslationData({
        data: { portalTranslationId: division.name }
      })
    }
  })

  return (
    <View>
      <Text style={styles.question}>
        {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE`)}
        {` (${myI18n._(t`Required`)})`}
      </Text>

      <View style={styles.row}>
        <View style={formPDFStyles.radioCheck} />
        <Text style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_NEIGHBOURHOOD_OPTION`)}
        </Text>
      </View>
      <View style={styles.row}>
        <View style={formPDFStyles.radioCheck} />
        <Text style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_MUNCIPAL_OPTION`)}
        </Text>
      </View>
      <View style={styles.row}>
        <View style={formPDFStyles.radioCheck} />
        <Text style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_REGIONAL_OPTION`)}
        </Text>
      </View>
      <View style={styles.row}>
        <View style={formPDFStyles.radioCheck} />
        <Text style={styles.text}>
          {myI18n._(
            t`CENZUS_DIVISION_ELEMENT_SCOPE_PROVINCE_OR_TERRITORY_OPTION`
          )}
        </Text>
      </View>

      <Text style={styles.question}>
        {myI18n._(t`FORM_CENZUS_DIVISION_PDF_CENSUS_DIVISION_MUNICIPAL`)}
        {` (${myI18n._(t`Required`)})`}
      </Text>

      <View style={styles.row}>
        {nuCityLabels.map((option, index) => {
          const isOdd = index % 2 === 1
          const _styles = isOdd
            ? [styles.listItem, styles.odd]
            : [styles.listItem]
          return (
            <View style={_styles} key={index} wrap={false}>
              <View style={formPDFStyles.radioCheck} />
              <Text style={styles.text}>
                {option} [
                {getLabelFromTranslationData({
                  data: { portalTranslationId: 'NU' }
                })}
                ]
              </Text>
            </View>
          )
        })}
      </View>

      <Text style={styles.question}>
        {myI18n._(t`FORM_CENZUS_DIVISION_PDF_CENSUS_DIVISION_REGIONAL`)}
        {` (${myI18n._(t`Required`)})`}
      </Text>

      {divisionLabels.map((division, index) => (
        <View style={styles.row} key={index}>
          <View style={formPDFStyles.checkbox} />
          <Text style={styles.text}>
            {division} [
            {getLabelFromTranslationData({
              data: { portalTranslationId: 'Region' }
            })}
            ]
          </Text>
        </View>
      ))}
    </View>
  )
}
