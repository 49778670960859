import { CensusDivisionPrint } from "./FormCensusDivision"
import { Trans, t } from '@lingui/macro'

/**
 * Component to render FormCenzusDivision element in print mode
 * @param {object} props - props of FormCenzusDivision element
 */
export const FormCensusDivisionPrintEditor = props => {

    const connectedValues = props?.typeProps?.connectedTo?.[0]

    const connectedObjectId = connectedValues?.connectedObject
    const connectedObject = props?.objectsConnected?.find(obj => obj.identId === connectedObjectId)
    const objName = connectedObject?.name
  
    const census = {
      scopeType: <>
        <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PLACEHOLDER</Trans>
        {connectedValues?.regionalScope ? <> for <i>{connectedValues?.regionalScope?.name}</i> from <i>{objName}</i></>: ""}
      </>,
      muncipality: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PLACEHOLDER</Trans>
        {connectedValues?.cities ? <> for <i>{connectedValues?.cities?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      provinces: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PLACEHOLDER</Trans>
        {connectedValues?.provinces ? <> for <i>{connectedValues?.provinces?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      selected: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_SELECTED_PLACEHOLDER</Trans>
        {connectedValues?.cenzusDivision ? <> for <i>{connectedValues?.cenzusDivision?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      pilotPhase: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION_PLACEHOLDER</Trans>
        {connectedValues?.pilotPhase ? <> for <i>{connectedValues?.pilotPhase?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      pilotPhaseScopeType: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE_PLACEHOLDER</Trans>
        {connectedValues?.pilotPhaseScopeType ? <> for <i>{connectedValues?.pilotPhaseScopeType?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      pilotPhaseMuncipality: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE_PLACEHOLDER</Trans>
        {connectedValues?.cities ? <> for <i>{connectedValues?.cities?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      pilotPhaseProvinces: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE_PLACEHOLDER</Trans>
        {connectedValues?.provinces ? <> for <i>{connectedValues?.provinces?.name}</i> from <i>{objName}</i></>: ""}
      </>),
      pilotPhaseSelected: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_SELECTED_PLACEHOLDER</Trans>
        {connectedValues?.cenzusDivision ? <> for <i>{connectedValues?.cenzusDivision?.name}</i> from <i>{objName}</i></>: ""}
      </>),      
      impactsProvincialNorth: (<>
        <Trans>CENZUS_DIVISION_ELEMENT_IMPACTS_PROVINCIAL_NORTH_PLACEHOLDER</Trans>
        {connectedValues?.impactsProvincialNorth ? <> for <i>{connectedValues?.impactsProvincialNorth?.name}</i> from <i>{objName}</i></>: ""}
      </>)
    }
  
    return (
      <>
        <div className='form-print-title-small'>{props.title}</div>
        <CensusDivisionPrint census={census}/>
      </>
    )
}