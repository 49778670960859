import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import { FormColorAutocomplete } from 'app/views/forms/common/FormColorAutocomplete'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { commonPdfStyles, NumberFormatDefault } from '../../common/Common'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export const formGranteeReportsDefaultValue = (obj, info, item) => {
  if (!obj || !info.reports) {
    return []
  }
  return info.reports.map(report => ({
    date: report.FGM_Base__Due_Date__c,
    status: report.FGM_Base__Status__c,
    type: report.Type__c,
    id: report.Id
  }))
}

export const FormGranteeReportsPdf = ({
  value,
  title,
  langVersion,
  typeProps,
  ...props
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily
  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps
  const pdfStyles = StyleSheet.create({
    headerCell: {
      textAlign: 'center',
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    },
    cell: {
      padding: 10,
      fontSize: 10,
      flex: 1,
      textAlign: 'center',
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    }
  })
  const headerStyle = { ...pdfStyles.headerCell }
  if (pdfHeaderTextProps.includes('bold')) {
    headerStyle.fontWeigth = 'bold'
  }
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }
  return (
    <View>
      <View>
        <Text style={commonPdfStyles.title}>{title}</Text>
      </View>
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Due date`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Report Type`)}</Text>
        </View>
      </View>
      {value.map((data, index) => {
        const { date, type } = data
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={pdfStyles.cell}>
              <Text>{date && moment.utc(date).format(dateFormat)}</Text>
            </View>
            <View style={pdfStyles.cell}>
              <Text>
                <Trans id={type} />
              </Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

export const FormGranteeReports = () => {
  return <div />
}

export const FormEditorGranteeReports = ({
  depth,
  showPdfProps,
  typeProps,
  editMode,
  ...props
}) => {
  const dispatch = useDispatch()
  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps
  if (!editMode) {
    return <FormGranteeReports editMode typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
            <FormGroup row>
              {[
                { key: 'bold', label: <Trans>Bold</Trans> },
                { key: 'italics', label: <Trans>Italics</Trans> },
                { key: 'underline', label: <Trans>Underline</Trans> }
              ].map(item => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      onChange={e => {
                        const v = e.target.value
                        const toSet = { ...typeProps }
                        const newTextProps = [...pdfHeaderTextProps]
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1)
                        } else {
                          newTextProps.push(v)
                        }
                        toSet.pdfHeaderTextProps = newTextProps
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              depth={depth}
              label={<Trans>Cell background color</Trans>}
              name='pdfBackgroundColor'
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  )
}
