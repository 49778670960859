export const MOVE_TO_COLUMN = 'MOVE_TO_COLUMN'
export const CHANGE_ACTION = 'CHANGE_ACTION'
export const SET_STATE = 'SET_STATE'
export const ADD_NEW_ACTION = 'ADD_NEW_ACTION'
export const DELETE_ACTION = 'DELETE_ACTION'
export const DELETE_ALL_ACTIONS = 'DELETE_ALL_ACTIONS'

export const setInitialState = initialState => {
  return dispatch => {
    dispatch({
      type: SET_STATE,
      state: initialState
    })
  }
}

export const removeAction = ({ columnIndex, actionIndex }) => {
  return dispatch => {
    dispatch({
      type: DELETE_ACTION,
      columnIndex,
      actionIndex
    })
  }
}

export const removeAllActions = () => {
  return dispatch => {
    dispatch({
      type: DELETE_ALL_ACTIONS
    })
  }
}

export const addNewAction = ({ actionToAdd }) => {
  return dispatch => {
    dispatch({
      type: ADD_NEW_ACTION,
      actionToAdd
    })
  }
}

export const changeAction = ({ actionToChange, columnIndex, actionIndex }) => {
  return dispatch => {
    dispatch({
      type: CHANGE_ACTION,
      actionToChange,
      columnIndex,
      actionIndex
    })
  }
}

export const moveToColumn = props => {
  return dispatch => {
    dispatch({
      type: MOVE_TO_COLUMN,
      ...props
    })
  }
}
