import { t } from "@lingui/macro";
import { Text, View } from "@react-pdf/renderer";
import { formPDFStyles } from "app/views/forms/pdf-components/FormPdf";
import { myI18n } from 'translation/I18nConnectedProvider';
import { parsePDFLabelToSupportWorkBreaking } from "app/views/forms/pdf-components/formPdfUtils";

export const FormProjectAffiliationsFillablePDF = ({
  value,
  elementType,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  typeProps,
  ...props
}) => {
  const { roles = [] } = typeProps;

  const columnStyles = [
    formPDFStyles.tableColumn,
    { width: "16.6%", minHeight: 36 },
  ];
  const referenceRoles = roles.some((obj) => obj.askForRelationship);
  delete formPDFStyles.headerTableCell.textAlign
  const labelStyles = {
    display: 'flex' as const,
    alignItems: 'flex-start' as const,
    justifyContent: 'center' as const,
    fontSize: 10 as const,
    fontWeight: 'bold' as const,
    margin: '2px' as const,
    paddingTop: 8 as const,
    paddingBottom: 8 as const
  }

  return (
    <View style={formPDFStyles.table}>
      <View style={formPDFStyles.tableRow}>
        {!referenceRoles && (
          <View style={columnStyles}>
            <View style={labelStyles}>
              <Text>{myI18n._(t`Contact role`)}</Text>
            </View>
          </View>
        )}
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`First Name`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`Last Name`)}</Text>
          </View>
        </View>

        {!referenceRoles ? (
          <View style={columnStyles}>
            <View style={labelStyles}>
              <Text>{myI18n._(t`Job title`)}</Text>
            </View>
          </View>
        ) : (
          <>
            <View style={columnStyles}>
                <View style={labelStyles}>
                  <Text>{parsePDFLabelToSupportWorkBreaking(myI18n._(t`Relationship to provider`))}</Text>
                </View>
            </View>
            <View style={columnStyles}>
                <View style={labelStyles}>
                  <Text>{myI18n._(t`Organization`)}</Text>
                </View>
            </View>
          </>
        )}
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`E-mail`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`Phone`)}</Text>
          </View>
        </View>
      </View>

      {roles.map((role, index) => {
        return (
          <View style={formPDFStyles.tableRow} key={index}>
            {!referenceRoles ? (
              <View style={columnStyles}>
                <View style={labelStyles}>
                  <Text>
                    {parsePDFLabelToSupportWorkBreaking(role.label[langVersion])}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={columnStyles} />
            )}
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
          </View>
        );
      })}
    </View>
  );
};
