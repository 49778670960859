import { Trans } from '@lingui/macro'
import { Grid, Icon, IconButton, Typography } from '@material-ui/core'

export const FormUploadFilesReadOnly = ({
  value = [],
  title,
  editorPreview,
  ...props
}) => {
  return (
    <div>
      {editorPreview && <Trans>FILE LIST PLACEHOLDER</Trans>}
      {value.map((file, index) => {
        return (
          <Grid
            container
            direction='row'
            alignItems='center'
            key={index}
            style={{ paddingLeft: 5 }}
          >
            <IconButton variant='link' href={file.url}>
              <Icon>download</Icon>
            </IconButton>
            <Typography style={{ marginLeft: 5 }}>
              <b>{file.name}</b>
              <div>{file.description}</div>
            </Typography>
          </Grid>
        )
      })}
    </div>
  )
}
