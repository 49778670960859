import { t } from '@lingui/macro'
import { EgretLoadable } from 'egret'
import { myI18n } from 'translation/I18nConnectedProvider'
import { authRoles } from '../../auth/authRoles'
import CreateSurvey from './CreateSurvey'

const SurveyTab = EgretLoadable({
  loader: () => import('./SurveyTab')
})

const SurveyScore = EgretLoadable({
  loader: () => import('./SurveyScore')
})

const SurveyTemplateList = EgretLoadable({
  loader: () => import('./SurveyTemplates')
})

const AvaliableSurveys = EgretLoadable({
  loader: () => import('./AvaliableSurveys')
})
const ESATSurveys = EgretLoadable({
  loader: () => import('./ESATSurveys')
})
const SurveyScoreCards = EgretLoadable({
  loader: () => import('./SurveyScoreCards')
})

const ScoreCardCreator = EgretLoadable({
  loader: () => import('./ScoreCardCreator')
})
const FormPage = EgretLoadable({
  loader: () => import('../forms/editor/FormWizard')
})

const surveyRoutes = [
  {
    path: '/surveys/sampleSurvey',
    component: SurveyTab,
    auth: authRoles.admin
  },
  {
    path: '/surveys/ScoreCards',
    component: SurveyScoreCards,
    isTranslatable: true,
    label: myI18n._(t`Score Cards`)
  },
  {
    path: '/surveys/ScoreCardCreator/:templateId/:scoreCardId',
    component: ScoreCardCreator,
    isTranslatable: true,
    label: myI18n._(t`Score Cards Editor`)
  },
  {
    path: '/surveys/SurveyTemplates',
    component: SurveyTemplateList,
    auth: authRoles.editor,
    isTranslatable: true,
    label: myI18n._(t`Survey Score`)
  },
  {
    path: '/surveys/SelfAssessmentSurveys',
    component: AvaliableSurveys,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Self Assessment Tool`)
  },
  {
    path: '/surveys/ESAT',
    component: ESATSurveys,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Environmental - Self Assessment Tool`)
  },
  {
    path: '/surveys/SurveyTab/:id/:surveyId/:mode',
    component: SurveyTab,
    auth: authRoles.grantee
  },
  {
    path: '/surveys/SurveyScore/:id/:templateId?/:scoreCardId?',
    component: SurveyScore,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Survey Score`)
  },
  {
    path: '/surveys/CreateSurvey/:id/:templateType',
    component: CreateSurvey,
    auth: authRoles.editor,
    isTranslatable: true,
    label: myI18n._(t`Survey Temaplate Editor`)
  },
  {
    path: '/surveys/CreateSurvey',
    component: CreateSurvey,
    auth: authRoles.editor,
    exact: true
  },
  {
    path: '/surveys/FormPage/:id?',
    component: FormPage
  }
]

export default surveyRoutes
