import { Image, Text, View } from '@react-pdf/renderer'
import { useEffect, useRef, useState } from 'react'
import reactImageSize from 'react-image-size'
import { parseFormLabelText, pdfDefaultFontSize } from '../../common/Common'

export const FormImagePdf = ({
  id,
  langVersion,
  title,
  typeProps,
  padding,
  describeMap,
  objectsFieldsMap,
  ...props
}) => {
  const { imgLink, width, height, percent, centerImage } = typeProps
  const [calcHeight, setCalcHeight] = useState()
  const [calcWidth, setCalcWidth] = useState()
  const ref = useRef()
  const url = imgLink

  const source = parseFormLabelText({
    text: url,
    langVersion,
    describeMap,
    objectsFieldsMap,
    returnString: true
  })

  useEffect(() => {
    if (percent) {
      reactImageSize(source).then(({ width, height }) => {
        setCalcHeight((height * percent) / 100)
        setCalcWidth((width * percent) / 100)
      })
    }
  }, [percent, source])

  const imgStyle = {}
  if (width) {
    imgStyle.maxWidth = +width
  }
  if (height) {
    imgStyle.maxHeight = +height
  }

  return (
    <View
      typeProps={typeProps}
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <View
        style={{
          alignSelf: centerImage ? 'center' : 'auto'
        }}
      >
        <Image src={source} style={{ 
          ...imgStyle,
          ...(calcHeight? {maxHeight: calcHeight} : {}),
          ...(calcWidth? {maxWidth: calcWidth} : {})
        }} />
        <Text
          style={{
            fontWeight: 'bold',
            marginTop: 5,
            fontSize: pdfDefaultFontSize
          }}
        >
          {title || ''}
        </Text>
      </View>
    </View>
  )
}
