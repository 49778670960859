import { Typography } from '@material-ui/core'

export const FormHeaderPreview = ({
  title,
  langVersion,
  typeProps,
  targetFormType,
  ...props
}) => {
  const { variant, bold, italics, fontSize, pdfFontSize, centerText } =
    typeProps
  let styleToPass = {}

  if (fontSize && !targetFormType.includes('pdf')) {
    styleToPass.fontSize = +fontSize
  }
  if (fontSize && targetFormType.includes('pdf')) {
    styleToPass.fontSize = +pdfFontSize
  }
  if (bold) {
    styleToPass.fontWeight = 'bold'
  }
  if (italics) {
    styleToPass.fontStyle = 'italic'
  }
  if (centerText) {
    styleToPass.textAlign = 'center'
  }
  const useVariant = variant !== 'none' && !targetFormType.includes('pdf')

  if (!useVariant && centerText) {
    return (
      <div
        style={{
          fontWeight: 'lighter',
          ...styleToPass
        }}
      >
        {title}
      </div>
    )
  }

  return (
    <Typography
      variant={useVariant && variant}
      style={{
        marginLeft: 15,
        fontWeight: 'lighter',
        width: '100%',
        ...styleToPass
      }}
    >
      {title}
    </Typography>
  )
}
