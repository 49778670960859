import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import { pdfSurveyHeaderFontSize } from '../PrintableReadyToFillSurvey'

export default function SurveyHeaderPdf ({ text, ...props }) {
  return (
    <Text
      style={{
        textAlign: 'center',
        fontSize: pdfSurveyHeaderFontSize,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 8,
        paddingBottom: 21
      }}
    >
      {text}
    </Text>
  )
}
