import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { commonPdfStyles } from 'app/views/forms/common/Common'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfSurveyQuestionSubFontSize } from '../PrintableReadyToFillSurvey'

export default function SurveyChoiceQuestionPdf ({ isGroup, ...props }) {
  if (isGroup) {
    return <GroupQuestionPdfView {...props} />
  } else {
    return <SingleQuestionPdfView {...props} />
  }
}

function GroupQuestionPdfView ({
  groupQuestions,
  options = [],
  type,
  nonApplicable,
  ...props
}) {
  const cellStyle = {
    flex: 1,
    padding: 4,
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: pdfSurveyQuestionSubFontSize
  }

  return (
    <View>
      <View style={commonPdfStyles.row}>
        <View style={{ ...cellStyle, flex: 3 }}>
          <Text />
        </View>
        {options.map((option, index) => {
          return (
            <View key={index} style={{ ...cellStyle }}>
              <Text>{option.text}</Text>
            </View>
          )
        })}
        {nonApplicable && (
          <View style={cellStyle}>
            <Text>{myI18n._(t`Is non applicable`)}</Text>
          </View>
        )}
      </View>
      {groupQuestions.map((groupQuestion, index) => {
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={{ ...cellStyle, flex: 3 }}>
              <Text>
                {getLabelFromTranslationData({
                  langVersion: props.version,
                  data: groupQuestion.titleValue
                })}
              </Text>
            </View>
            {options.map((option, index) => {
              return (
                <View
                  key={index}
                  style={{
                    ...cellStyle,
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {type === 'SELECT_ONE' || type === 'SELECT_YESNO' ? (
                    <RadioCheck />
                  ) : (
                    <PdfCheckbox />
                  )}
                </View>
              )
            })}
            {nonApplicable && (
              <View
                style={{
                  ...cellStyle,
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <PdfCheckbox />
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}

function SingleQuestionPdfView ({ type, options = [], ...props }) {
  return (
    <View>
      {options.map((option, index) => {
        return (
          <View key={index}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 3,
                alignItems: 'center'
              }}
            >
              {type === 'SELECT_ONE' || type === 'SELECT_YESNO' ? (
                <RadioCheck />
              ) : (
                <PdfCheckbox />
              )}
              <Text
                style={{
                  fontSize: pdfSurveyQuestionSubFontSize
                }}
              >
                {option.text?.id ? myI18n._(t`${option.text.id}`) : option.text}
              </Text>
            </View>
            {option.requireDetails && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 5,
                  alignItems: 'center'
                }}
              >
                <Text
                  style={{
                    fontSize: pdfSurveyQuestionSubFontSize
                  }}
                >
                  {myI18n._(t`Please, specify: `)}
                </Text>
                <View
                  style={{
                    borderBottom: '1px dotted black',
                    paddingLeft: 8,
                    height: 1,
                    marginTop: pdfSurveyQuestionSubFontSize + 3,
                    flexGrow: 1
                  }}
                />
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}

export const PdfCheckbox = () => (
  <View
    style={{
      width: pdfSurveyQuestionSubFontSize,
      height: pdfSurveyQuestionSubFontSize,
      border: '1px solid black',
      marginRight: 8
    }}
  />
)

const RadioCheck = () => (
  <View
    style={{
      width: pdfSurveyQuestionSubFontSize,
      height: pdfSurveyQuestionSubFontSize,
      border: '1px solid black',
      borderRadius: 8,
      marginRight: 8
    }}
  />
)
