import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core'
import React, { Component, useEffect } from 'react'
import { Prompt, useHistory } from 'react-router'

export default class RedirectWarning extends Component {
  state = {}

  constructor (props) {
    super(props)
    this.handleRedirect = this.handleRedirect.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleRedirect () {
    this.setState({ canRedirect: true, openDialog: false })
  }

  handleCancel () {
    this.setState({ openDialog: false })
  }

  render () {
    const { openDialog, canRedirect, location } = this.state
    const { open, handleSave } = this.props

    return (
      <>
        <RedirectDialog
          handleSave={handleSave}
          open={openDialog}
          canRedirect={canRedirect}
          cancel={this.handleCancel}
          redirect={this.handleRedirect}
          location={location}
        />
        <Prompt
          when={open}
          message={(location, action) => {
            if (canRedirect) {
              return true
            }
            this.setState({ openDialog: true, location })
            return false
          }}
        />
      </>
    )
  }
}

const RedirectDialog = ({
  open,
  redirect,
  location,
  canRedirect,
  cancel,
  handleSave
}) => {
  const history = useHistory()

  useEffect(() => {
    if (canRedirect) {
      history.push(location)
    }
  }, [canRedirect])

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>There are unsaved changes present in this form!</Trans>
      </DialogTitle>
      <DialogContent>
        <Trans>Would you like to save your changes before leaving?</Trans>
      </DialogContent>
      <DialogActions>
        <Grid container direction='row' justify='space-evenly'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              redirect()
            }}
          >
            <Trans>Leave without saving</Trans>
          </Button>
          {handleSave && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                handleSave()
                cancel()
              }}
            >
              <Trans>Save</Trans>
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              cancel()
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
