import axios from "axios";
import SFAuthService, { NO_USER } from "../SFAuthService";
import sfOauthConfig from "../sfAuthConfig";

interface UploadValues {
  name: string;
  description: string;
  type: string;
  tags: string;
  opportunityId?: string;
  uploadId?: string;
}

/**
 * Uploads a file to Salesforce ContentVersion object.
 * @function
 * @category Salesforce - ContentVersion and ContentDocument
 * @param {UploadValues} values The values associated with the file upload (name, description, type, tags).
 * @param {string} fileData The base64 encoded string representing the file data.
 * @param {string} networkId - The Salesforce network id.
 * @param {boolean} [returnUploadedFile=false] - Optional. If true, returns the uploaded file data; otherwise, returns documents associated with the specified entity.
 * @returns {ContentDocument | ContentDocument[]}
 */
export const uploadFile = (
  values: UploadValues,
  fileData: string,
  networkId: string,
  returnUploadedFile: boolean = false
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const uploadId: string = values.opportunityId || (values.uploadId as string);
  const base64data = new Buffer(fileData).toString("base64");
  console.log("upload file", values, networkId);
  return conn
    .sobject("ContentVersion")
    .create({
      Title: values.name,
      Description: values.description,
      PathOnClient: values.name,
      FirstPublishLocationId: uploadId,
      NetworkId: networkId,
      Type__c: values.type,
      TagCsv: values.tags,
      VersionData: base64data,
    })
    .then((result) => {
      return returnUploadedFile
        ? conn.sobject("ContentDocument").findOne({
            LatestPublishedVersionId: result.id,
          })
        : getDocumentsByEntity(uploadId);
    });
};

/**
 * Uploads a file to Salesforce ContentVersion object with progress tracking.
 * @function
 * @category Salesforce - ContentVersion and ContentDocument
 * @param {UploadValues} values The values associated with the file upload (name, description, type, tags).
 * @param {string} fileData The base64 encoded string representing the file data.
 * @param {string} networkId - The Salesforce network id.
 * @param {boolean} [returnUploadedFile=false] - Optional. If true, returns the uploaded file data; otherwise, returns documents associated with the specified entity.
 * @param {function} onProgress - Optional. A callback function to track the upload progress.
 * @returns {ContentDocument | ContentDocument[]}
 */
export const progressUploadFile = (
  values: UploadValues,
  fileData: string,
  networkId: string,
  returnUploadedFile: boolean = false,
  onProgress?: (progress: number) => void
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  const uploadId: string = values.opportunityId || (values.uploadId as string);
  const base64data = Buffer.from(fileData).toString("base64");

  if (!uploadId || !networkId || !values.name) {
    return Promise.reject(new Error("Missing required fields for upload."));
  }

  const payload = {
    Title: values.name,
    Description: values.description,
    PathOnClient: values.name,
    FirstPublishLocationId: uploadId,
    NetworkId: networkId,
    Type__c: values.type,
    TagCsv: values.tags,
    VersionData: base64data,
  };

  const endpoint = `${sfOauthConfig.config.instanceUrl}/services/data/${SF_API_VERSION}/sobjects/ContentVersion`
  const headers = {
    Authorization: `Bearer ${conn.accessToken}`,
    'Content-Type': 'application/json',
  };

  return axios.post(endpoint, payload, {
    headers,
    onUploadProgress: (progressEvent) => {
      if (progressEvent.lengthComputable && onProgress) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      }
    },
  })
  .then(response => {
    const result = response.data;
    if (returnUploadedFile) {
      return conn.sobject("ContentDocument").findOne({
        LatestPublishedVersionId: result.id,
      });
    } else {
      return getDocumentsByEntity(uploadId);
    }
  })
  .catch(error => {
    console.error("Error uploading file", error);
    return Promise.reject(error);
  });
};

/**
 * Retrieves and parses file data from Salesforce ContentDocument and ContentVersion objects.
 * @function
 * @category Salesforce - ContentDocument and ContentVersion
 * @param {string} id The id of the ContentDocument representing the file.
 * @returns {ParsedFile}
 */
export const getParsedFile = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("ContentDocument")
    .findOne({
      Id: id,
    })
    .then((contentDocument) => {
      return conn
        .sobject("ContentVersion")
        .findOne({
          ContentDocumentId: id,
        })
        .then((version) => {
          return parseDocument({
            ContentDocument: contentDocument,
            ContentVersion: contentDocument,
          });
        });
    });
};

/**
 * Uploads a file to multiple objects in Salesforce and associates it with each object.
 * @function
 * @category Salesforce
 * @param {string} name The name of the file.
 * @param {string} description The description of the file.
 * @param {string} tags The tags associated with the file.
 * @param {string} fileData The base64 encoded string representing the file data.
 * @param {string} networkId The Salesforce network id.
 * @param {string} mainId The id of the main object to associate the file with.
 * @param {string[]} ids An array of ids of the objects to associate the file with.
 * @returns {ContentDocument[]}
 */
export const uploadFileToMultipleObjects = ({
  name,
  description,
  tags,
  fileData,
  networkId,
  mainId,
  ids,
}: {
  name: string;
  description: string;
  tags: string;
  fileData: string;
  networkId: string;
  mainId: string;
  ids: string[];
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const base64data = new Buffer(fileData).toString("base64");
  console.log("upload file", name, tags, networkId, ids);
  return conn
    .sobject("ContentVersion")
    .create({
      Title: name,
      Description: description,
      PathOnClient: name,
      FirstPublishLocationId: mainId,
      NetworkId: networkId,
      TagCsv: tags,
      VersionData: base64data,
    })
    .then((contentVersion) => {
      return conn
        .sobject("ContentVersion")
        .retrieve(contentVersion.id)
        .then((document) => {
          return conn
            .sobject("ContentDocumentLink")
            .create(
              ids.map((id) => ({
                ContentDocumentId: document.ContentDocumentId,
                LinkedEntityId: id,
              }))
            )
            .then((result) => {
              return getDocumentsByEntity(mainId);
            });
        });
    });
};

/**
 * Retrieves documents associated with multiple entities using a Salesforce flow.
 * @function
 * @category Salesforce - ContentDocument and ContentVersion
 * @param {string[]} ids An array of ids of the entities to retrieve documents for.
 * @returns {FlowResult}
 */
export const getDocumentsByEntitiesByFlow = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Get_Files_For_Entity", {
      inputs: ids.map((id) => ({
        parentId: id,
      })),
    })
    .then((flowResult) => {
      const toReturn = {};
      const documentsMap = {};
      flowResult.forEach((resObj, index) => {
        const { contentDocumentLinks, contentVersions, contentDocuments } =
          resObj.outputValues;
        const matchObj = {};
        if (contentDocuments) {
          contentDocuments.forEach((document) => {
            documentsMap[document.Id] = document;
          });
        }
        contentDocumentLinks.forEach((link) => {
          matchObj[link.ContentDocumentId] = {
            ...link,
            ContentDocument: documentsMap[link.ContentDocumentId],
          };
        });
        if (contentVersions) {
          contentVersions.forEach((version) => {
            matchObj[version.ContentDocumentId].ContentVersion = version;
          });
        }
        toReturn[ids[index]] = Object.values(matchObj)
          .filter((obj: unknown): obj is DocumentData => {
            return (
              typeof obj === "object" &&
              obj !== null &&
              "ContentDocument" in obj &&
              "ContentVersion" in obj
            );
          })
          .map((obj: DocumentData) => parseDocument(obj));
      });
      return toReturn;
    });
};

/**
 * Retrieves documents associated with multiple entities from Salesforce ContentDocumentLink and ContentVersion objects.
 * @function
 * @category Salesforce - ContentDocumentLink and ContentVersion
 * @param {string[]} ids - An array of ids of the entities to retrieve documents for.
 * @returns {JSForceResult}
 */
export const getDocumentsByEntities = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const returnObj = {};
  if (ids.length === 0) {
    return Promise.resolve([]);
  }
  return conn
    .sobject("ContentDocumentLink")
    .find({
      LinkedEntityId: { $in: ids },
    })
    .select("ContentDocumentId, Id, LinkedEntityId, ContentDocument.*")
    .autoFetch(true)
    .then((result) => {
      const find: string[] = [];
      result.forEach((res) => {
        find.push(res.ContentDocumentId);
        returnObj[res.ContentDocumentId] = { ...res };
      });
      if (find.length === 0) {
        return [];
      }
      return conn
        .sobject("ContentVersion")
        .find({
          ContentDocumentId: find,
        })
        .select("Id, ContentDocumentId, TagCsv, Title, VersionNumber")
        .then((versions) => {
          const toReturn: object[] = [];
          versions.forEach((version, index) => {
            returnObj[version.ContentDocumentId].ContentVersion = version;
          });
          for (const key in returnObj) {
            toReturn.push(returnObj[key]);
          }
          return toReturn;
        });
    });
};

/**
 * Retrieves documents associated with a specific entity from Salesforce ContentDocumentLink and ContentVersion objects.
 * @function
 * @category Salesforce - ContentDocumentLink, ContentVersion, and ContentDocument
 * @param {string} id The id of the entity to retrieve documents for.
 * @returns {JSForceResult}
 */
export const getDocumentsByEntity = (id: string) => {
  if (!id) {
    return Promise.resolve([]);
  }
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  const returnObj = {};
  return conn
    .sobject("ContentDocumentLink")
    .select("ContentDocumentId, Id, LinkedEntityId, ContentDocument.*")
    .where({
      LinkedEntityId: id,
    })
    .autoFetch(true)
    .then((result) => {
      const find: string[] = [];
      result.forEach((res) => {
        find.push(res.ContentDocumentId);
        returnObj[res.ContentDocumentId] = { ...res };
      });
      if (find.length === 0) {
        return [];
      }
      return conn
        .sobject("ContentVersion")
        .find({
          ContentDocumentId: find,
        })
        .then((versions) => {
          const toReturn: object[] = [];
          versions.forEach((version, index) => {
            returnObj[version.ContentDocumentId].ContentVersion = version;
          });
          for (const key in returnObj) {
            toReturn.push(returnObj[key]);
          }
          return toReturn;
        });
    });
};

/**
 * Links a file to a target using a Salesforce flow.
 * @function
 * @category Salesforce - ContentDocument
 * @param {string} file The id of the file to link.
 * @param {string} target The id of the target entity to link the file to.
 * @returns {FlowResult}
 */
export const linkFileByFlow = ({
  file,
  target,
}: {
  file: string;
  target: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost("/actions/custom/flow/App_Link_File", {
    inputs: [
      {
        file,
        target,
      },
    ],
  });
};

/**
 * Generates the download URL for a Salesforce ContentDocument with the specified id.
 * @function
 * @category Salesforce - ContentDocument
 * @param {string} id The id of the ContentDocument.
 * @returns {string} The download URL for the ContentDocument.
 */
export const contentDocumentDownloadUrl = (id: string) =>
  `${sfOauthConfig.oauth2.loginUrl}/sfc/servlet.shepherd/document/download/${id}?operationContext=S1`;

interface DocumentData {
  ContentDocument: {
    Title: string;
    Description: string;
    Id: string;
  };
  LinkedEntityId?: string;
  ContentVersion: {
    TagCsv: string;
    Type__c: string;
    LastModifiedDate: Date;
  };
}

interface ParsedDocument {
  name: string;
  parent?: string;
  description: string;
  id: string;
  tags: string;
  type: string;
  lastModifiedDate: Date;
  url: string;
}

/**
 * Parses a document object and extracts relevant information.
 * @function
 * @category Salesforce - ContentDocument and ContentVersion
 * @param {DocumentData} doc The document object to parse.
 * @returns {ParsedDocument}
 */
export const parseDocument = (doc: DocumentData): ParsedDocument => {
  return {
    name: doc.ContentDocument.Title,
    parent: doc.LinkedEntityId,
    description: doc.ContentDocument.Description,
    id: doc.ContentDocument.Id,
    tags: doc.ContentVersion.TagCsv,
    type: doc.ContentVersion.Type__c,
    lastModifiedDate: doc.ContentVersion.LastModifiedDate,
    url: contentDocumentDownloadUrl(doc.ContentDocument.Id),
  };
};

/**
 * Deletes a document from Salesforce using a flow.
 * @function
 * @category Salesforce - ContentDocument and ContentVersion
 * @param {string} id The id of the document to delete.
 * @returns {FlowResult}
 */
export const deleteDocumentByFlow = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost("/actions/custom/flow/App_Delete_Document", {
    inputs: [
      {
        toDelete: {
          Id: id,
        },
      },
    ],
  });
};

/* This function is used to delete a document and handle the case when 'UNKNOWN_EXCEPTION' is returned */
export const deleteDocumentWithEntityIsDeletedErrorHandling = (id) => {
  return deleteDocumentByFlow(id).then(
    (result) => {
      return Promise.resolve(result);
    },
    (reject) => {
      const error = JSON.parse(reject.message);
      if (
        error?.length === 1 &&
        error[0]?.errors?.length === 1 &&
        error[0]?.errors[0]?.statusCode === "UNKNOWN_EXCEPTION"
      ) {
        return Promise.resolve();
      }
      return Promise.reject(reject);
    }
  );
};

const SF_API_VERSION = "v49.0";

/**
 * Uploads multiple files to Salesforce as documents.
 * @function
 * @category Salesforce - Document
 * @param {object[]} files An array of files to upload.
 * @returns {JSForceResult}
 */
export const uploadFiles = (files: object[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost(
    `/services/data/${SF_API_VERSION}/composite/sobjects`,
    {
      allOrNone: false,
      records: [
        files.map((item, index) => {
          return {
            attributes: {
              type: "Document",
              binaryPartName: `binaryPart_${index}`,
              binaryPartNameAlias: "Body",
            },
            FolderId: "005xx000001Svs4AAC",
            Name: "Brochure",
            Type: "pdf",
          };
        }),
      ],
    }
  );
};
