import { TextField } from '@material-ui/core'
import { FastField } from 'formik'

/**
 * Material UI TextField wrapped in Formik FastField
 * @category Common components
 * @subcategory Formik
 * @extends TextField
 * @component
 * @returns {JSX.Element}
 * @property {string} name Id of a formik field.
 */
export function FormikFastTextField ({ name, ...props }) {
  return (
    <FastField name={name}>
      {({ field }) => {
        const handleChange = (event) => {
          field.onChange(event);
        }

        return (
          <TextField
            {...field}
            value={field.value || ''}
            variant='outlined'
            fullWidth
            onChange={handleChange}
            {...props}
          />
        )
      }}
    </FastField>
  )
}
