import { Budget, sanitizeLineKey } from "./FormBudget"

export const FormBudgetPrintEditor = ({ title, typeProps }) => {
    const { expensesLines = [], revenuesLines = [] } = typeProps
  
    const revenuesRender = revenuesLines.map(key => ({
      key,
      value: '$ Value Placeholder',
      comment: 'Comment Placeholder'
    }))
  
    const expensesRender = expensesLines.map(key => ({
      key: sanitizeLineKey(key),
      value: '$ Value Placeholder',
      comment: 'Comment Placeholder'
    }))
  
    return(
      <div>
        <div className='form-print-title-small'>{title}</div>
        <Budget revenuesRender={revenuesRender} expensesRender={expensesRender} year="Year Placeholder"
          editorPreview="editor" showPdfComponent={false} showPrintComponent={true}
        />
      </div>
    )
  }