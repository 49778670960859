import { SET_FUNDING_STREAMS } from '../actions/FundingStreamsActions';

const initialState = {
  streams: [],
  validCaseTypes: []
}

const FundingStreamsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_FUNDING_STREAMS: {
      return {
        ...state,
        streams: action.data.streams || state.streams,
        validCaseTypes: action.data.validCaseTypes || state.validCaseTypes
      }
    }
    default: {
      return state
    }
  }
}

export default FundingStreamsReducer
