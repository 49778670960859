import { UPDATE_ORG_INFO } from './UserActions'

export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_AVALIABLE_ORGANIZATIONS = 'SET_AVALIABLE_ORGANIZATIONS'

export function setOrganization (org) {
  console.log('set organization', org)
  return dispatch => {
    dispatch({
      type: SET_ORGANIZATION,
      data: org
    })
    dispatch({
      type: UPDATE_ORG_INFO,
      data: org
    })
  }
}

export function setAvaliableOrganizations (list) {
  return dispatch => {
    dispatch({
      type: SET_AVALIABLE_ORGANIZATIONS,
      data: list
    })
  }
}
