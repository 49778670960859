import { Text, View } from '@react-pdf/renderer'
import { ImprovedHtml } from 'app/views/common-components/ImprovedHtml'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export const FormBooleanFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  renderIndex,
  labelAsMarkdown,
  ...props
}) => {
  const { pdfBorderDisplay } = typeProps

  if (labelAsMarkdown) {
    title = (
      <ImprovedHtml style={{ fontSize: pdfDefaultFontSize }} collapse={false}>
        {title}
      </ImprovedHtml>
    )
  }

  return (
    <View
      style={[
        pdfBorderDisplay
          ? {
              padding: 6,
              border: '1px solid rgba(0, 0, 0, 0.02)',
              borderRadius: '5px'
            }
          : {
              fontFamily:
                langVersion === 'iu-cans'
                  ? inuktitutFormPdfFontFamily
                  : defaultFormPdfFontFamily,
              fontSize: pdfDefaultFontSize
            },
        { display: 'flex', flexDirection: 'row' }
      ]}
    >
      <View style={formPDFStyles.checkbox} />
      <Text
        style={{
          fontFamily:
            langVersion === 'iu-cans'
              ? inuktitutFormPdfFontFamily
              : defaultFormPdfFontFamily,
          fontSize: pdfDefaultFontSize,
          marginLeft: 8
        }}
      >
        {renderIndex ? `${renderIndex}. ` : ''}
        {title}
      </Text>
    </View>
  )
}
