import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { NumericFormat } from 'app/views/page-layouts/FormElement'
import { useDispatch, useSelector } from 'react-redux'
import { getMainConnected } from '../../Form'
import DebouncedTextField from '../../common/DebouncedTextField'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import {
  mapFormEditorElements,
  validFieldTypesForTextFieldNumeric
} from '../../editor/FormWizard'
import { FormTextInputPrint } from '../text-input/FormTextInputReadOnly'
import { FormNumericInput } from './FormNumericInput'

export function FormEditorTextFieldNumeric ({
  typeProps = {},
  injectable,
  langVersion,
  showEditableProps,
  showPrintProps,
  showPdfProps,
  showPdfComponent,
  showPrintComponent,
  showEditableComponent,
  depth,
  editMode,
  title,
  id,
  ...props
}) {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)

  const {
    required,
    allowPhoneExtension,
    isPhone,
    forceRequired,
    currency,
    showFieldLabel,
    max,
    modifyMinSubstract,
    modifyMinAdd,
    modifyMaxSubstract,
    modifyMaxAdd,
    min
  } = typeProps

  if (!editMode) {
    if (showPrintComponent) {
      return (
        <FormTextInputPrint
          typeProps={typeProps}
          title={title}
          {...props}
          editorPreview
        />
      )
    }
    return (
      <FormNumericInput
        id={id}
        typeProps={typeProps}
        title={title}
        {...props}
      />
    )
  }

  const avaliableElementsNumeric = mapFormEditorElements({
    data: tree,
    type: 'textInputNumeric',
    id,
    langVersion
  })

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly = connectedField && connectedField.readOnly
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      {showEditableProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField)}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.min
                delete toSet.max
                delete toSet.allowPhoneExtension
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {forceReadOnly ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly &&
        showEditableProps && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || forceRequired}
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />
          </>
        )
      )}

      {!readOnly && showEditableProps && (
        <div>
          <h6 style={{ marginBottom: 5, marginTop: 10 }}>Input restrictions</h6>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currency)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.currency = e.target.checked
                  if (e.target.checked) {
                    delete toSet.isPhone
                    delete toSet.allowPhoneExtension
                  }
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Is currency?</Trans>}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isPhone)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isPhone = e.target.checked
                    delete toSet.allowPhoneExtension
                    if (e.target.checked) {
                      delete toSet.currency
                      delete toSet.min
                      delete toSet.max
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is phone number?</Trans>}
            />

            {isPhone && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(allowPhoneExtension)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.allowPhoneExtension = e.target.checked
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Allow phone number extension?</Trans>}
              />
            )}

            <Grid container>
              <Grid item xs style={{ padding: 5 }}>
                <DebouncedTextField
                  label={<Trans>Max input</Trans>}
                  disabled={Boolean(isPhone)}
                  value={max || ''}
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  fullWidth
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.max = e.target.value
                    if (!e.target.value) {
                      delete toSet.modifyMaxSubstract
                      delete toSet.modifyMaxAdd
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
                {max && (
                  <Grid
                    container
                    style={{ padding: 5, paddingTop: 10 }}
                    wrap='nowrap'
                  >
                    <TextField
                      label={<Trans>MODIFY_SUBSTRACT_FROM_MAX</Trans>}
                      fullWidth
                      variant='outlined'
                      value={modifyMaxSubstract || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.modifyMaxSubstract = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      select
                    >
                      {avaliableElementsNumeric
                        .filter(item => item.id !== id)
                        .map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        const toSet = { ...typeProps }
                        delete toSet.modifyMaxSubstract
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      size='small'
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                )}

                {max && (
                  <Grid
                    container
                    style={{ padding: 5, paddingTop: 10 }}
                    wrap='nowrap'
                  >
                    <TextField
                      label={<Trans>MODIFY_ADD_TO_MAX</Trans>}
                      fullWidth
                      variant='outlined'
                      value={modifyMaxAdd || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.modifyMaxAdd = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      select
                    >
                      {avaliableElementsNumeric
                        .filter(item => item.id !== id)
                        .map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      size='small'
                      onClick={() => {
                        const toSet = { ...typeProps }
                        delete toSet.modifyMaxAdd
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <DebouncedTextField
                  label={<Trans>Min input</Trans>}
                  disabled={Boolean(isPhone)}
                  value={min || ''}
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  fullWidth
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.min = e.target.value
                    if (!e.target.value) {
                      delete toSet.modifyMinSubstract
                      delete toSet.modifyMinAdd
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />

                {min && (
                  <Grid
                    container
                    wrap='nowrap'
                    style={{ padding: 5, paddingTop: 10 }}
                  >
                    <TextField
                      label={<Trans>MODIFY_SUBSTRACT_FROM_MIN</Trans>}
                      fullWidth
                      variant='outlined'
                      value={modifyMinSubstract || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.modifyMinSubstract = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      select
                    >
                      {avaliableElementsNumeric
                        .filter(item => item.id !== id)
                        .map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      size='small'
                      onClick={() => {
                        const toSet = { ...typeProps }
                        delete toSet.modifyMinSubstract
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                )}

                {min && (
                  <Grid
                    container
                    wrap='nowrap'
                    style={{ padding: 5, paddingTop: 10 }}
                  >
                    <TextField
                      label={<Trans>MODIFY_ADD_TO_MIN</Trans>}
                      fullWidth
                      variant='outlined'
                      value={modifyMinAdd || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.modifyMinAdd = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      select
                    >
                      {avaliableElementsNumeric
                        .filter(item => item.id !== id)
                        .map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      size='small'
                      onClick={() => {
                        const toSet = { ...typeProps }
                        delete toSet.modifyMinAdd
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        allowReadOnlyFields
        clearOnReadOnlyField={['required', 'min', 'max', 'allowPhoneExtension']}
      />
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
    </Grid>
  )
}
