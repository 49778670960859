import { Trans } from '@lingui/macro'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  grpcGetFieldComment,
  grpcLockFieldComment,
  grpcUnlockFieldComment,
  grpcUpdateFieldComment
} from '../grpcFieldComment'
import { MUEditedByLabel } from './MUEditedByLabel'
import { multiuserColoredInputClasses } from './muStyles'

/**
 * Renders an icon, that on hover displays in the tooltip field comment. Clicking on icon opens up a field comment for editing.
 * @category Multiuser
 * @component
 * @returns {JSX.Element}
 * @property {object} muBag Object containing multiuser data needef for making the queries.
 * @property {object} muBag.token Current multiuser session token.
 * @property {object} muBag.formId Id of a realm the component is used in.
 * @property {string} fieldId Id of a field.
 */
export const MUFieldComment = ({ fieldId, muBag }) => {
  const { values, setFieldValue } = useFormikContext()
  const { muUsers = {} } = values
  const commentObj = _.get(values, `muInfo.comments.${fieldId}`) || {}
  const { locked, comment, user } = commentObj
  const userId = useSelector(state => state.user.userId)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const [timer, setTimer] = useState(null)

  const fetchComment = () => {
    grpcGetFieldComment({
      fieldId,
      ...muBag,
      onSuccess: (array, lockingUser) => {
        const savedComment = array[1]
        const userId = lockingUser
        if (savedComment && (!comment || comment !== savedComment)) {
          const toSave = { ...commentObj }
          toSave.comment = savedComment
          if (userId) {
            toSave.locked = true
            toSave.user = userId
          }
          setFieldValue(`muInfo.comments.${fieldId}`, toSave, false)
        }
        setCommentValue(savedComment)
      }
    })
  }

  useEffect(() => {
    if (comment !== commentValue) {
      setCommentValue(comment)
    }
  }, [comment])

  const handleChange = e => {
    const value = e.target.value
    setCommentValue(value)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      grpcUpdateFieldComment({
        fieldId,
        ...muBag,
        comment: value
      })
    }, 700)
    setTimer(newTimer)
  }

  let userColor, userName
  if (locked) {
    userColor = muUsers[user]?.color
    userName = muUsers[user]?.name
  }
  const classes = multiuserColoredInputClasses(userColor)

  return (
    <>
      <span
        onMouseEnter={e => {
          if (!comment) {
            fetchComment()
          }
        }}
      >
        <Tooltip title={comment || ''}>
          <IconButton
            onClick={e => {
              setDialogOpen(true)
              fetchComment()
            }}
          >
            <Icon>sticky_note_2</Icon>
          </IconButton>
        </Tooltip>
      </span>
      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Trans>Field comment</Trans>
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 15 }}>
          <TextField
            className={classes.root}
            value={commentValue || ''}
            multiline
            disabled={Boolean(locked && user !== userId)}
            onChange={handleChange}
            onFocus={e => {
              grpcLockFieldComment({
                fieldId,
                ...muBag,
                comment: commentValue
              })
            }}
            onBlur={e => {
              grpcUnlockFieldComment({
                fieldId,
                ...muBag,
                comment: commentValue
              })
            }}
            variant='outlined'
            fullWidth
          />
          <MUEditedByLabel color={userColor} userName={userName} />
        </DialogContent>
      </Dialog>
    </>
  )
}
