import { View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'
import FormPdfFillArea from '../../pdf-components/FormPdfFillArea'

const FormNumericInputFillablePdf = (typeProps, langVersion, ...props) => {
  let style = {
    fontFamily:
      langVersion === 'iu-cans'
        ? inuktitutFormPdfFontFamily
        : defaultFormPdfFontFamily,
    fontSize: pdfDefaultFontSize,
    padding: 6
  }

  return (
    <View style={style}>
      <FormPdfFillArea lines={1} />
    </View>
  )
}

export default FormNumericInputFillablePdf
