import { t } from '@lingui/macro'
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import RobotoBold from 'app/assets/fonts/Roboto-Bold.ttf'
import Roboto from 'app/assets/fonts/Roboto-Regular.ttf'
import moment from 'moment'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 700 }
  ]
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 15
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5
  },
  firstRow: {
    marginBottom: 10
  },
  icon: {
    width: 16,
    height: 16
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 10
  },
  actionContainer: {
    border: '1px solid #C4C4C4',
    padding: 10,
    marginBottom: 10
  },
  actionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    marginLeft: 10
  },
  label: {
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    marginLeft: 5,
    fontSize: 12,
    opacity: 0.54
  },
  detailText: {
    fontFamily: 'Roboto',
    fontSize: 11,
    lineHeight: 1.4,
    marginBottom: 10,
    paddingLeft: 21
  },
  last: {
    marginBottom: 0
  },
  empty: {
    paddingLeft: 16
  }
})

const ActionPlanToPrintPdf = ({ columns, i18n }) => {
  return (
    <Document>
      {columns.map((column, columnIndex) => {
        if (column.actions.length === 0) return null
        return (
          <Page key={columnIndex} style={styles.page} size='A4'>
            {column.actions.length > 0 && (
              <View>
                <Text style={styles.title}>{column.title}</Text>
              </View>
            )}
            {column.actions.map((action, index) => (
              <View key={index} style={styles.actionContainer} wrap={false}>
                <View style={[styles.row, styles.firstRow]}>
                  <Image
                    src='/assets/images/pdf/folder.jpg'
                    style={styles.icon}
                  />
                  <Text style={styles.actionTitle}>{action.title}</Text>
                </View>
                {Boolean(columnIndex === 0 || columnIndex === 1) && (
                  <SelectedAction {...action} i18n={i18n} />
                )}
                {columnIndex === 2 && (
                  <CompletedAction {...action} i18n={i18n} />
                )}
                {columnIndex === 3 && <OnHoldAction {...action} i18n={i18n} />}
              </View>
            ))}
          </Page>
        )
      })}
    </Document>
  )
}

const OnHoldAction = action => {
  return (
    <View>
      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`reason for hold`
        )}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.failReason}</Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`expected outcome`
        )}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.expectedOutcome}</Text>
    </View>
  )
}

const CompletedAction = action => {
  return (
    <View>
      <View style={styles.row}>
        <Image src='/assets/images/pdf/calendar.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(t`due date`)}`}</Text>
      </View>
      <Text style={styles.detailText}>
        {moment.utc(action.dueDate).format(dateFormat)}
      </Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(t`description`)}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.description}</Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`expected outcome`
        )}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.expectedOutcome}</Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`actual outcome`
        )}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.actualOutcome}</Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`success measure`
        )}`}</Text>
      </View>
      <Text style={[styles.detailText, styles.last]}>{action.success}</Text>
    </View>
  )
}

const SelectedAction = action => {
  return (
    <View>
      <View style={styles.row}>
        <Image src='/assets/images/pdf/calendar.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(t`due date`)}`}</Text>
      </View>
      <Text style={styles.detailText}>
        {moment.utc(action.dueDate).format(dateFormat)}
      </Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(t`description`)}`}</Text>
      </View>
      <Text style={styles.detailText}>{action.description}</Text>

      <View style={styles.row}>
        <Image src='/assets/images/pdf/file.jpg' style={styles.icon} />
        <Text style={styles.label}>{`${action.i18n._(
          t`expected outcome`
        )}`}</Text>
      </View>
      <Text style={[styles.detailText, styles.last]}>
        {action.expectedOutcome}
      </Text>
    </View>
  )
}

export default ActionPlanToPrintPdf
