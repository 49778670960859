import { Trans } from '@lingui/macro'
import { Button, Card, Collapse, Icon } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import './CollapseElement.css'

/** This component makes a collapsible element. The content is shown by default if the orientation is horizontal or vertical.
 * If the orientation is none, then the content is always shown.
 * @component
 * @param {Object} props
 * @param {React.Component} props.children - the children of the component
 * @param {string} props.orientation - the orientation of the collapse element
 * @param {boolean} props.noUI - Shopuld be true if you want to use custom interaface for controlling collapse instead of default one
 * @returns {React.Component}
 */
export const CollapseElement = ({ open, noUI, children, title, ...props }) => {
  // collapse is the state of the collapse element and can be null, 'show', or 'hide'.
  // it also used to detemiine some of the classNames of the component.
  const [collapse, setCollapse] = useState(null)
  const orientation = props.orientation || 'none'

  // These references are used to define the boundaries and position of elements in horizontal orientation.
  const horizontalCollapseRef = useRef(null)
  const horizontalTitleWrapperRef = useRef(null)
  const horizontalTitleWithBtnRef = useRef(null)
  const horizontalTitleRef = useRef(null)
  const horizontalContentRef = useRef(null)
  const horizontalBtnRef = useRef(null)

  // This useEffect is used to define and set boundaries and position of elements in horizontal orientation
  useEffect(() => {
    if (
      orientation === 'horizontal' &&
      horizontalCollapseRef &&
      horizontalContentRef &&
      horizontalTitleRef &&
      horizontalTitleWrapperRef &&
      horizontalTitleWithBtnRef &&
      horizontalBtnRef
    ) {
      const defineHorizontalBoundaries = () => {
        // Title height equals to the width of the title before it is rotated
        const titleWrapperWidth =
          horizontalTitleWrapperRef.current.getBoundingClientRect().width
        let titleHeight =
          horizontalTitleRef.current.getBoundingClientRect().height
        horizontalTitleRef.current.style.transform = `rotate(-90deg) translateX(${-titleHeight}px) translateY(${
          titleWrapperWidth / 2
        }px)`

        // Bottom of the title wrapper equals either the bottom of the title or the bottom of the collapse element or the bottom of the screen
        const horizontalContentRefBottom =
          horizontalContentRef.current.getBoundingClientRect().bottom
        const horizontalTitleRefBottom =
          horizontalTitleRef.current.getBoundingClientRect().bottom
        const screenBottom = document.documentElement.clientHeight - 100 // 100 is a buffer
        let titleWrapperBottom =
          horizontalContentRefBottom > horizontalTitleRefBottom &&
          horizontalContentRefBottom < screenBottom
            ? horizontalContentRefBottom
            : horizontalTitleRefBottom < screenBottom &&
              horizontalTitleRefBottom > horizontalContentRefBottom
            ? horizontalTitleRefBottom
            : screenBottom

        // update the title height after the title is rotated
        titleHeight =
          titleWrapperBottom -
          horizontalTitleRef.current.getBoundingClientRect().top
        let collapseElementHeight =
          horizontalCollapseRef.current.getBoundingClientRect().height

        // The title is rotated -90deg and offset to the center of thier parent element
        horizontalTitleRef.current.style.transform = `rotate(-90deg) translateX(${-titleHeight}px) translateY(${
          titleWrapperWidth / 2
        }px)`
        horizontalTitleRef.current.style.width = `${titleHeight}px`
        horizontalTitleRef.current.classList.add('set')

        // if the title extends beyond the borders of the content element, then the content element is expanded to fit the title
        const contentElementBottom =
          horizontalContentRef.current.getBoundingClientRect().bottom
        const _titleBottom =
          horizontalTitleRef.current.getBoundingClientRect().bottom

        if (_titleBottom > contentElementBottom) {
          collapseElementHeight =
            collapseElementHeight + (_titleBottom - contentElementBottom)
          horizontalCollapseRef.current.style.height = `${collapseElementHeight}px`
          // horizontalTitleRef.current.style.marginTop = '-30px'
        }
        // if the collapse element extends beyond the bottom of the screen, then the title with button is made sticky
        const header = document.querySelector('.topbar')
        const headerHeight = header.getBoundingClientRect().height
        const _collapseElementBottom =
          horizontalCollapseRef.current.getBoundingClientRect().bottom
        if (document.documentElement.clientHeight < _collapseElementBottom) {
          const collapseElementTop =
            horizontalCollapseRef.current.getBoundingClientRect().top -
            headerHeight
          horizontalTitleWithBtnRef.current.style.position = 'sticky'
          horizontalTitleWithBtnRef.current.style.top =
            collapseElementTop + 'px'
        } else {
          horizontalTitleWithBtnRef.current.style.position = 'static'
        }
      }
      setTimeout(() => {
        defineHorizontalBoundaries()
        // call the function again to make sure the boundaries are set correctly
        defineHorizontalBoundaries()
      }, 0)
    }
  }, [
    horizontalCollapseRef,
    horizontalTitleWrapperRef,
    horizontalTitleWithBtnRef,
    horizontalTitleRef,
    horizontalContentRef,
    orientation
  ])

  const handleTitleClick = () => {
    if (collapse === null || collapse === 'show') {
      setCollapse('hide')
    } else {
      setCollapse('show')
    }
    if (orientation === 'horizontal') {
      const collapseElementHeight =
        horizontalCollapseRef.current.getBoundingClientRect().height
      horizontalCollapseRef.current.style.height = `${collapseElementHeight}px`
    }
  }

  if (orientation === 'none') {
    return <>{children}</>
  }

  if (noUI) {
    return (
      <div
        className={`collapse-element-content ${open || ''} hide-${orientation}`}
        ref={horizontalContentRef}
      >
        {children}
      </div>
    )
  }

  if (orientation === 'vertical') {
    return (
      <Card>
        <div className='collapse-element-vertical-title-wrapper'>
          <div className='collapse-element-vertical-title'>
            <h2>{title}</h2>
          </div>
          <ToggleCollapseButton
            onClick={handleTitleClick}
            condition={collapse}
            orientation={orientation}
          />
        </div>
        <Collapse in={collapse === 'show' || !collapse} timeout={500}>
          {children}
        </Collapse>
      </Card>
    )
  }

  return (
    <div className='collapse-element' ref={horizontalCollapseRef}>
      <div
        className='collapse-element-title-wrapper'
        ref={horizontalTitleWrapperRef}
      >
        <div
          className='collapse-element-title-with-btn'
          ref={horizontalTitleWithBtnRef}
        >
          <div
            className='collapse-element-title-btn'
            onClick={handleTitleClick}
            ref={horizontalBtnRef}
          >
            <ToggleCollapseButton
              onClick={handleTitleClick}
              condition={collapse}
              orientation={orientation}
            />
          </div>
          <div className='collapse-element-title' ref={horizontalTitleRef}>
            <h2>{title}</h2>
          </div>
        </div>
      </div>
      <div
        className={`collapse-element-content ${collapse || ''}`}
        ref={horizontalContentRef}
      >
        {children}
      </div>
    </div>
  )
}

/** This helper component is the button that toggles the collapse element.
 * @component
 * @param {Object} props
 * @param {Function} props.onClick - the function called when the button is clicked
 * @param {string} props.condition - the condition of the collapse element (null, 'show', or 'hide')
 * @param {string} props.orientation - the orientation of the collapse element
 * @returns {React.Component}
 */
const ToggleCollapseButton = ({ onClick, condition, orientation = 'none' }) => {
  return (
    <div className='collapse-element-title-icon' onClick={onClick}>
      {condition === null || condition === 'show' ? (
        <Button variant='contained' color='primary' className={orientation}>
          <Trans>COLLAPSE_ELEMENT_HIDE_BTN_LABEL</Trans>
          {orientation === 'horizontal' ? (
            <Icon>keyboard_arrow_left</Icon>
          ) : (
            <Icon>keyboard_arrow_up</Icon>
          )}
        </Button>
      ) : (
        <Button variant='contained' color='primary' className={orientation}>
          <Trans>COLLAPSE_ELEMENT_SHOW_BTN_LABEL</Trans>
          {orientation === 'horizontal' ? (
            <Icon>keyboard_arrow_right</Icon>
          ) : (
            <Icon>keyboard_arrow_down</Icon>
          )}
        </Button>
      )}
    </div>
  )
}
