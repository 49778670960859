import SFAuthService, { NO_USER } from "../SFAuthService";
import { TemplateParsed } from "./sfActionPlan";
import { SF_API_VERSION } from "./sfOpportunity";
import {
  createTranslation,
  getSurveyTranslations,
  getTranslation,
} from "./sfTranslations";

interface Template {
  Title__c: string;
  Category__c: string;
  Expected_Outcome__c: string;
  Account__c: string;
  Date__c: Date;
  From_Template__c: string;
  Order__c: number;
  Description__c: string;
  Status__c: string;
  Reason__c: string;
}

/**
 * Retrieves Action Templates with their translations from Salesforce.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {Object} searchParams - Search parameters to filter Action Templates.
 * @param {boolean} [listPermissions=false] - Flag to include list permissions fields in the query.
 * @param {boolean} [scorePermissions=false] - Flag to include score permissions fields in the query.
 * @returns {Promise<Object>} A Promise that resolves with an object containing action templates and their translations.
 */
export const getActionTemplates = (
  searchParams: object = {},
  listPermissions: boolean = false,
  scorePermissions: boolean = false
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  let query = conn.sobject("Action_Template__c").find({ ...searchParams });
  if (listPermissions) {
    query = query.select(
      "Id, Name, Title__c, Reason__c, Expected_Outcome__c, Trigger__c"
    );
  }
  if (scorePermissions) {
    query = query.select(
      "Id, Name, Trigger__c, Title__c, Reason__c, Expected_Outcome__c, Description__c"
    );
  }
  return query.then((result) => {
    const fetchIDs: string[] = [];
    const matchObj = {};
    result.forEach((template: Template) => {
      if (template.Title__c) {
        fetchIDs.push(template.Title__c);
        matchObj[template.Title__c] = { template: template };
      }
    });
    return conn
      .sobject("SurveyTranslation__c")
      .find({
        Id: { $in: fetchIDs },
      })
      .select("Id")
      .include("Survey_Texts__r")
      .select(
        "Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c, Text_Is_Portal_Translation_Id__c, Tooltip_Is_Portal_Translation_Id__c, Extended_Is_Portal_Translation_Id__c"
      )
      .end()
      .then((translations) => {
        translations.forEach((trans) => {
          matchObj[trans.Id].translation = { ...trans };
        });
        return matchObj;
      });
  });
};

/**
 * Retrieves full action templates based on provided ids, including translations.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {string[]} ids - Array of ids of the action templates to retrieve.
 * @returns {Template[]} An array of action templates with translations.
 */
export const getActionTemplatesFull = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Action_Template__c")
    .find({
      Id: { $in: ids },
    })
    .then((results: Template[]) => {
      let ids: string[] = [];
      results = results.filter((t) => Boolean(t.Title__c));
      results.forEach((template: Template) => {
        ids.push(
          template.Description__c,
          template.Title__c,
          template.Reason__c,
          template.Expected_Outcome__c
        );
      });
      ids = ids.filter((id) => id);
      return getSurveyTranslations(ids).then((translations) => {
        const translationMap = {};
        translations.forEach((trans) => {
          translationMap[trans.Id] = trans;
        });
        return results.map((template: Template) => {
          return {
            ...template,
            Description__c: translationMap[template.Description__c],
            Title__c: translationMap[template.Title__c],
            Reason__c: translationMap[template.Reason__c],
            Expected_Outcome__c: translationMap[template.Expected_Outcome__c],
          };
        });
      });
    });
};

/**
 * Retrieves one Action Template, with translations of title, descrition, expected outcome, and reason.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {string} id Id of the Action Template
 * @returns {Template}
 */
export const getActionTemplate = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Action_Template__c")
    .find({
      Id: id,
    })
    .then((result: Template[]) => {
      const template = result[0];
      return Promise.all([
        getTranslation(template.Description__c),
        getTranslation(template.Title__c),
        getTranslation(template.Reason__c),
        getTranslation(template.Expected_Outcome__c),
      ]).then(([description, title, reason, outcome]) => {
        return {
          ...template,
          Description__c: description,
          Title__c: title,
          Reason__c: reason,
          Expected_Outcome__c: outcome,
        };
      });
    });
};

/**
 * Saves an Action Template.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {object} fields Fields of the Action Template being updated.
 * @param {string} id Id of the Action Template
 * @returns {JSForceResult}
 */
export const saveActionTemplate = ({
  fields,
  id,
}: {
  fields: Omit<Template, "id">;
  id: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Action_Template__c").update({
    Id: id,
    ...fields,
  });
};

/**
 * Creates new Survey Translation records in Salesforce.
 * @function
 * @category Salesforce - SurveyTranslation__c
 * @param {object[]} records - Array of Survey Translation records to be created.
 * @returns {JSForceResult}
 */
export const createSurveyTranslations = (records: object[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost(
    `/services/data/${SF_API_VERSION}/composite/tree/SurveyTranslation__c`,
    {
      records,
    }
  );
};

/**
 * Creates new Action Template records in Salesforce.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {object[]} values - An object or an array of objects representing the values of the Action Template records to be created.
 * @returns {JSForceResult}
 */
export const createActionTemplates = (values: object[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Action_Template__c").create(values);
};

/**
 * Deletes Action Template records in Salesforce.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {string} id - Id of the Action Template to be deleted.
 * @returns {JSForceResult}
 */
export const deleteActionTemplate = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Action_Template__c").destroy(id);
};

/**
 * Creates one new Action Template in Salesforce.
 * @function
 * @category Salesforce - Action_Template__c
 * @param {TemplateParsed} props - Fields of the Action Template to be created.
 * @returns {JSForceResult}
 */
export const createActionTemplate = (props: TemplateParsed) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return Promise.all([
    // @ts-ignore
    createTranslation(props.name),
    // @ts-ignore
    createTranslation(props.description),
    createTranslation({}),
    createTranslation({}),
  ]).then(
    ([title, description, reason, outcome]) => {
      return conn.sobject("Action_Template__c").create({
        Title__c: title,
        Description__c: description,
        Reason__c: reason,
        Expected_Outcome__c: outcome,
      });
    },
    (reject) => {
      console.log(reject);
    }
  );
};
