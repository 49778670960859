import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import _ from 'lodash'
import { myI18n } from 'translation/I18nConnectedProvider'
import { sanitizeLineKey } from './FormBudget'

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  firstCol: {
    direction: 'flex',
    justifyContent: 'center',
    width: '35%'
  },
  secondCol: {
    width: '15%'
  },
  thirdCol: {
    width: '50%'
  },
  tableCol: {
    borderStyle: 'solid',
    minHeight: 35,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    margin: '2px'
  },
  secondTitle: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    margin: '2px',
    textAlign: 'center',
    marginBottom: '5px'
  },
  mainTableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    fontWeight: 'bold',
    margin: '2px'
  },
  headerRow: {
    direction: 'flex',
    justifyContent: 'center'
  },
  helptextCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 8,
    margin: '0 2px'
  }
})

export const FormBudgetPdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const expenses = typeProps.expensesLines || []
  const revenues = typeProps.revenuesLines || []
  const expensesHelpText = typeProps?.expensesHelpText?.[langVersion] || ''
  const revenuesHelpText = typeProps?.revenuesHelpText?.[langVersion] || ''

  const expensesYears = Object.keys(value.expenses)
  const revenuesYears = Object.keys(value.revenues)

  const years = [...new Set([...expensesYears, ...revenuesYears])].sort(
    (a, b) => a - b
  )

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.helptextCell.fontFamily = inuktitutFormPdfFontFamily
  }

  const combined = {
    expenses: {},
    revenues: {}
  }
  years.forEach(year => {
    const keys = ['expenses', 'revenues']
    keys.forEach(typeKey => {
      Object.entries(value[typeKey][year]).forEach(([key, obj]) => {
        let existing = _.get(combined[typeKey], key) || {
          value: 0,
          comments: []
        }
        existing.value += obj.value
        if (obj.comment) {
          existing.comments.push(obj.comment + '\n')
        }
        _.set(combined[typeKey], key, existing)
      })
    })
  })

  return (
    <View wrap={false}>
      <View style={styles.table}>
        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</Text>
            {revenuesHelpText ? (
              <Text style={styles.helptextCell}>{revenuesHelpText}</Text>
            ) : null}
          </View>
          <View style={[styles.tableCol, styles.secondCol]}>
            <Text style={styles.tableCell}>{myI18n._(t`Amount`)}</Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol]}>
            <Text style={styles.tableCell}>{myI18n._(t`Comments`)}</Text>
          </View>
        </View>

        {revenues.map((item, index) => (
          <View style={styles.tableRow} wrap={false} key={index}>
            <View style={[styles.tableCol, styles.firstCol]}>
              <Text style={styles.tableCell}>{myI18n._(item)}</Text>
            </View>
            <View style={[styles.tableCol, styles.secondCol]}>
              <Text style={styles.tableCell}>
                ${combined?.revenues?.[item]?.value}
              </Text>
            </View>
            <View style={[styles.tableCol, styles.thirdCol]}>
              <Text
                style={{
                  ...styles.tableCell,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {combined?.revenues?.[item]?.comments.map((comment, index) => {
                  return (
                    <Text debug style={{ width: '100%' }} key={index}>
                      {comment}
                    </Text>
                  )
                })}
              </Text>
            </View>
          </View>
        ))}

        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</Text>
            {expensesHelpText ? (
              <Text style={styles.helptextCell}>{expensesHelpText}</Text>
            ) : null}
          </View>
          <View style={[styles.tableCol, styles.secondCol]}></View>
          <View style={[styles.tableCol, styles.thirdCol]}></View>
        </View>

        {expenses.map((item, index) => {
          const itemKey = sanitizeLineKey(item)
          return (
            <View style={styles.tableRow} wrap={false} key={index}>
              <View style={[styles.tableCol, styles.firstCol]}>
                <Text style={styles.tableCell}>{myI18n._(item)}</Text>
              </View>
              <View style={[styles.tableCol, styles.secondCol]}>
                <Text style={styles.tableCell}>
                  ${combined?.expenses?.[itemKey]?.value}
                </Text>
              </View>
              <View style={[styles.tableCol, styles.thirdCol]}>
                <Text
                  style={{
                    ...styles.tableCell,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {combined?.expenses?.[itemKey]?.comments.map(
                    (comment, index) => {
                      return (
                        <Text debug style={{ width: '100%' }} key={index}>
                          {comment}
                        </Text>
                      )
                    }
                  )}
                </Text>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}
