import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import {
  commonPdfStyles,
  pdfDefaultFontSize
} from 'app/views/forms/common/Common'
import { defaultFormPdfFontFamily, formPDFStyles } from 'app/views/forms/pdf-components/FormPdf'
import moment from 'moment'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = StyleSheet.create({
  text: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily
  },
  title: {
    fontSize: pdfDefaultFontSize,
    fontWeight: 'bold',
    fontFamily: defaultFormPdfFontFamily
  },
  section: {
    marginBottom: 8
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4
  },
  dateCell: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 20
  },
  milestoneTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4
  }
})

/**
 * Form element which allows to display milestone updates in PDF.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormMilestoneUpdatePdf = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {
  const milestones = value || []

  const lastReportTitle = myI18n?._(
    t`FORM_MILESTONE_UPDATE_LAST_REPORT_SUBTITLE`
  )
  const previousReportTitle = myI18n?._(
    t`FORM_MILESTONE_UPDATE_PREVIOUS_REPORT_SUBTITLE`
  )

  const language = myI18n.language

  const getFormatedDate = date => {
    if (!date) {
      return ''
    }
    const _date = new Date(date)
    const month = _date.toLocaleString(language, { month: 'long' })
    const day = _date.getDate()
    const year = _date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  return (
    <View>
      {title ? <Text style={commonPdfStyles.title}>{title}</Text> : <View />}

      {milestones.map((item, index) => {
        const reportMilestoneUpdates = item.reportMilestoneUpdates || []
        const mileUpdatePrimaryActivities =
          item?.milestoneUpdate?.primaryActivities
        const mileUpdateImplementedActivities =
          item?.milestoneUpdate?.implementedActivities

        const startDate = item?.milestoneUpdate?.phaseStartDate
        const endDate = item?.milestoneUpdate?.phaseEndDate

        const areDatesChanged = item.milestoneUpdate?.areDatesChanged || 'no'
  
        return (
          <View key={index} style={styles.section}>

            {index === 0 && <View>
              <View>
                <Text style={styles.title}>
                  {myI18n?._(t`FORM_MILESTONE_UPDATE_ARE_DATES_CHANGED_SUBTITLE`)}:
                </Text>
              </View>

              <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 4}}>
                <View style={formPDFStyles.radioCheck}>
                  {areDatesChanged === "yes" ? (
                    <View style={formPDFStyles.radioCheckSelected} />
                  ) : (
                    <View />
                  )}
                </View>
                <Text style={{ fontSize: pdfDefaultFontSize, lineHeight: 1.4, marginRight: 20, marginTop: 4 }} >
                  {myI18n?._(t`Yes`)}
                </Text>              
                <View style={formPDFStyles.radioCheck}>
                  {areDatesChanged === "no" ? (
                    <View style={formPDFStyles.radioCheckSelected} />
                  ) : (
                    <View />
                  )}
                </View>              
                <Text style={{ fontSize: pdfDefaultFontSize, lineHeight: 1.4, marginTop: 4 }} >
                  {myI18n?._(t`No`)}
                </Text>
              </View>
            </View>}
            
            <View>
              <Text style={styles.milestoneTitle}>
                {myI18n?._(item.stage)}
              </Text>
            </View>

            <View style={styles.dateRow}>
              <View style={styles.dateCell}>
                <Text style={styles.title}>{myI18n?._(t`Start Date`)}:</Text>
                <Text style={styles.text}>
                  : {startDate ? moment(startDate).format(dateFormat) : ''}
                </Text>
              </View>
              <View style={styles.dateCell}>
                <Text style={styles.title}>{myI18n?._(t`End Date`)}:</Text>
                <Text style={styles.text}>
                  : {endDate ? moment(endDate).format(dateFormat) : ''}
                </Text>
              </View>
            </View>

            {/* {!mileUpdatePrimaryActivities ? (
              <View>
                <Text style={styles.title}>
                  {myI18n?._(t`FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_TITLE`)}:
                </Text>
              </View>
            ) : null}
            {!mileUpdatePrimaryActivities &&
            reportMilestoneUpdates.length === 0 ? (
              <View>
                <Text style={styles.text}>{item?.primaryActivities || ''}</Text>
              </View>
            ) : null}
            {!mileUpdatePrimaryActivities && reportMilestoneUpdates.length > 0
              ? reportMilestoneUpdates.map((answer, idx) => {
                  const title =
                    idx === 0 ? lastReportTitle : previousReportTitle
                  const formattedDate = getFormatedDate(
                    item?.reports[idx]?.FGM_Base__Submission_Date__c
                  )
                  return (
                    <View key={idx}>
                      <Text style={styles.text}>
                        {title}: {formattedDate}
                      </Text>
                      <Text style={styles.text}>
                        {answer?.Primary_activities_planned__c || ''}
                      </Text>
                    </View>
                  )
                })
              : null} */}
            {/* {mileUpdatePrimaryActivities ? ( */}
              <View>
                <View>
                  <Text style={styles.title}>
                    {myI18n?._(
                      t`FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_QUESTION`
                    )}:
                  </Text>
                </View>
                <View>
                  <Text style={styles.text}>
                    {item?.milestoneUpdate?.primaryActivities || ''}
                  </Text>
                </View>
              </View>
            {/* ) : null} */}

            {/* {!mileUpdateImplementedActivities &&
            reportMilestoneUpdates.length > 0 ? (
              <View>
                <Text style={styles.title}>
                  {myI18n?._(
                    t`FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_TITLE`
                  )}:
                </Text>
              </View>
            ) : null}
            {!mileUpdateImplementedActivities &&
            reportMilestoneUpdates.length > 0
              ? reportMilestoneUpdates.map((answer, idx) => {
                  const title =
                    idx === 0 ? lastReportTitle : previousReportTitle
                  const formattedDate = getFormatedDate(
                    item?.reports[idx]?.FGM_Base__Submission_Date__c
                  )
                  return (
                    <View key={idx}>
                      <Text style={styles.text}>
                        {title}: {formattedDate}
                      </Text>
                      <Text style={styles.text}>
                        {answer?.Primary_activities_progress__c || ''}
                      </Text>
                    </View>
                  )
                })
              : null} */}
            {/* {mileUpdateImplementedActivities ? ( */}
              <View>
                <View>
                  <Text style={styles.title}>
                    {myI18n?._(
                      t`FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_QUESTION`
                    )}:
                  </Text>
                </View>
                <View>
                  <Text style={styles.text}>
                    {item?.milestoneUpdate?.implementedActivities || ''}
                  </Text>
                </View>
              </View>
            {/* ) : null} */}
          </View>
        )
      })}
    </View>
  )
}
