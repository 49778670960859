/* global mainScript */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import './styles/Dropdown.css'
import { Card, Checkbox, FormControlLabel } from '@material-ui/core'

const DropDown = ({
	//topLevelCategory,
	filterObj,
	handleFilterSelect,
	selectedFilters
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropDownNode = useRef()

	const handleOutsideModalClick = e => {
		if (dropDownNode.current && !dropDownNode.current.contains(e.target)) {
			setIsOpen(!isOpen)
		}
	}

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideModalClick)
		}
		return () => {
			document.removeEventListener('mousedown', handleOutsideModalClick)
		}
	}, [isOpen])

	const { options = [], label, key } = filterObj
	let renderedDropdowns = options.map(option => (
		<div className='checkBoxContainer'>
			<FormControlLabel 
				control = {<Checkbox
					id={option.key}
					onChange={() => handleFilterSelect(key, option.key)}
					style={{padding: '8px 8px 8px 0px'}}
					checked={
						selectedFilters[key] &&
						selectedFilters[key].some(selected => selected === option.key)
					}
					l
				/>}
				style={{margin: '0px'}}
				label={option.label}
			/>
		</div>
	))

	return (
		<li className='dropDownContainer' ref={dropDownNode}>
			<div className={`dropDownHeader ${isOpen && 'open'}`} onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
				{label}
				<svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
					<path d="M19 21L23.3301 26.25H14.6699L19 21Z" fill="black" fill-opacity="0.54"/>
				</svg>
			</div>
			{isOpen && <Card>
				<ul className='dropdown-container'>{renderedDropdowns}</ul>
			</Card>}
		</li>
	)
}

export default DropDown

DropDown.propTypes = {
	//topLevelCategory: PropTypes.object,
	filterObj: PropTypes.object,
	handleFilterSelect: PropTypes.func,
	selectedFilters: PropTypes.object
}