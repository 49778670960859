import { Milestone } from "./FormMilestones"

export const FormMilestonesPrint = ({ value, title, typeProps = {} }) => {
    const { phases = [] } = typeProps
    return (
      <>
        <div className='form-print-title-small'>{title}</div>
        {phases.map((phase, index) => {
          const milestone = value?.[index]
          if(!milestone)
            return null
          return <Milestone index={index} phase={phase} milestone={milestone}/>
        })}
      </>
    )
  }