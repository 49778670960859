import { Trans } from '@lingui/macro'
import { Divider, Grid, Typography } from '@material-ui/core'

export const FormProjectAffiliationsPrintEditor = ({ title }) => {
  return (
    <div style={{ width: '100%' }}>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact name</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact e-mai</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact role</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container style={{ width: '100%' }}>
        <Grid item xs style={{ padding: 4 }}>
          <Typography>Contact name Placeholder</Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography>Contact e-mail Placeholder</Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography>Contact role Placeholder</Typography>
        </Grid>
      </Grid>
    </div>
  )
}
