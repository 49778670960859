import Loading from 'egret/components/EgretLoadable/Loading'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { getFormPage } from './services/sfAuth/sfData/sfForms'

const RedirectOldUrl = props => {
  const { match } = props
  const { addressString, wildcard } = match.params

  const [opportunityId, setOpportunityId] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getFormPage(wildcard).then(form => {
      const opportunityIdentId = form.objectsConnected.find(
        obj => obj.type === 'Opportunity'
      )?.identId
      const opportunityUrlChunk = addressString.split(';').find(chunk => {
        return chunk.includes(opportunityIdentId)
      })
      if (opportunityUrlChunk) {
        setOpportunityId(opportunityUrlChunk.split('=')[1])
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  if (opportunityId) {
    return <Redirect to={`/grants/grant-application/${opportunityId}`} />
  } else {
    return <Redirect to='/grants/ApplicationsList' />
  }
}

export default RedirectOldUrl
