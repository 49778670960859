export const SET_EDITING_USERS = 'SET_EDITING_USERS'
export const RESET_MU_REDUCER = 'RESET_MU_REDUCER'
export const LOGOUT_MU_USER = 'LOGOUT_MU_USER'
export const LOG_MU_USER = 'LOG_MU_USER'
export const RECONNECTED = 'RECONNECTED'

export function multiuserReduxReconnectedEvent (editingUsers) {
  return dispatch => {
    dispatch({
      type: RECONNECTED
    })
  }
}

export function setEditingUsers (editingUsers) {
  return dispatch => {
    dispatch({
      type: SET_EDITING_USERS,
      editingUsers
    })
  }
}

export function resetEditingUsers (editingUsers) {
  return dispatch => {
    dispatch({
      type: RESET_MU_REDUCER
    })
  }
}
