import moment from "moment";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { mapSFToForm, reverseMap } from "../sfDataService";

export const NSCHCF_FUNDINGS_STREAM_ID = "a41ON000000u4s9YAA";

const langKeyToAPIValue = {
  en: "English",
  fr: "French",
  "iu-cans": "Inuktitut",
};

export const mapFields = {
  direction: "in",
  Name: "sfName",
  Full_Name__r: {
    key: "fullName",
    in: (stream) => processTranslationField(stream, "Full_Name__r", "fullName"),
  },
  Full_Name__c: {
    key: "fullName",
    out: (res) => (res.fullName ? res.fullName || "" : null),
  },
  Stream_name_only__r: {
    key: "name",
    in: (stream) =>
      processTranslationField(
        stream,
        "Stream_name_only__r",
        "nameTranslations"
      ),
  },
  Stream_name_only__c: {
    key: "name",
    out: (res) => (res.name ? res.name || "" : null),
  },
  Id: "id",
  Funding_Program__r: {
    key: "subtitle",
    in: (stream) =>
      processTranslationField(stream, "Funding_Program__r", "subtitle"),
  },
  Funding_Program__c: {
    key: "subtitle",
    out: (res) => (res.subtitle ? res.subtitle : null),
  },
  Short_description__r: {
    key: "description",
    in: (stream) =>
      processTranslationField(stream, "Short_description__r", "description"),
  },
  Short_description__c: {
    key: "description",
    out: (res) => (res.description ? res.description || "" : null),
  },

  Eligibility_Quiz__c: "quiz",
  Application_form__c: "form",
  Application_print_view__c: "printForm",
  Grantee_report_preview__c: "granteeReportForm",
  Grantee_progress_report_survey_template__c:
    "granteeProgressReportSurveyTemplate",
  Grantee_final_report_survey_template__c: "granteeFinalReportSurveyTemplate",
  Applications_list_print_preview__c: "internalPrintPreview",
  Funding_agreement__c: "contract",
  Selection_Committee_preview__c: "SC",
  Allocation_Committee_preview__c: "AC",
  Board_summary_preview__c: "Board",
  CMHC_summary_preview__c: "CMHC",
  External_review_application_view__c: "externalReviewApplicationView",
  Objective_Record_Type__c: "objectiveRecordType",
  Disable_in_provinces__c: {
    key: "disableInProvinces",
    in: (stream) =>
      stream.Disable_in_provinces__c
        ? stream.Disable_in_provinces__c.split(";")
        : [],
    out: (res) => res.disableInProvinces.join(";"),
  },
  Available_in_languages__c: {
    key: "avaliableInLanguages",
    in: (stream) =>
      stream.Available_in_languages__c
        ? stream.Available_in_languages__c.split(";")
        : [],
    out: (res) =>
      res.avaliableInLanguages
        .map((lang) => langKeyToAPIValue[lang])
        .filter((v) => v)
        .join(";"),
  },
  Validity_start__c: "startTime",
  Validity_end__c: "endTime",
  Reminder_date__c: "reminderTime",
  Is_in_testing__c: "inTesting",
  Is_obsolete__c: "obsolete",
  Pre_Qualification_Application_Form__c: "prequalificationForm",
  Pre_Qualification_Eligibility_Quiz__c: "prequalificationEligibilityQuiz",
  Prequalification_Record_Type__c: "prequalificationRecordType",
  DMAH_view__c: "dmahView",
  More_info_url__r: {
    key: "moreInfoUrl",
    in: (stream) =>
      processTranslationField(stream, "More_info_url__r", "moreInfoUrl"),
  },
  More_info_url__c: {
    key: "moreInfoUrl",
    out: (res) => (res.moreInfoUrl ? res.moreInfoUrl || "" : null),
  },
  Logo_url__c: "logo",
  Notes__c: "notes",
  Order__c: "order",
  Configuration_Data_Imported__c: "configurationDataImported",
  Case_type__c: "caseType",
};

interface FundingStream {
  Name: string;
  Full_Name__r: {
    id: string;
    name: string;
    translationId: string;
    en: string;
    fr: string;
    "iu-cans": string;
    "iu-latn": string;
  } | null;
  Full_Name__c: string | null;
  Stream_name_only__r: {
    id: string;
    name: string;
    translationId: string;
    en: string;
    fr: string;
    "iu-cans": string;
    "iu-latn": string;
  } | null;
  Stream_name_only__c: string | null;
  Id: string;
  Funding_Program__r: string | null;
  Funding_Program__c: string | null;
  Short_description__r: {
    id: string;
    name: string;
    translationId: string;
    en: string;
    fr: string;
    "iu-cans": string;
    "iu-latn": string;
  } | null;
  Short_description__c: string | null;
  Eligibility_Quiz__c: string;
  Application_form__c: string;
  Application_print_view__c: string;
  Grantee_report_preview__c: string;
  Grantee_progress_report_survey_template__c: string;
  Grantee_final_report_survey_template__c: string;
  Applications_list_print_preview__c: string;
  Funding_agreement__c: string;
  Selection_Committee_preview__c: string;
  Allocation_Committee_preview__c: string;
  Board_summary_preview__c: string;
  CMHC_summary_preview__c: string;
  External_review_application_view__c: string;
  Objective_Record_Type__c: string;
  Disable_in_provinces__c: string[] | null;
  Available_in_languages__c: string[] | null;
  Validity_start__c: moment.Moment;
  Validity_end__c: moment.Moment;
  Reminder_date__c: moment.Moment;
  Is_in_testing__c: boolean;
  Is_obsolete__c: boolean;
  More_info_url__r: {
    id: string;
    name: string;
    translationId: string;
    en: string;
    fr: string;
    "iu-cans": string;
    "iu-latn": string;
  } | null;
  More_info_url__c: string | null;
  Logo_url__c: string;
  Notes__c: string;
  Order__c: number;
  Configuration_Data_Imported__c: boolean;
  Case_type__c: string | null;
}

interface FundingStreamParsed {
  direction: string;
  name: string;
  fullNameRelationship:
    | {
        id: string;
        name: string;
        translationId: string;
        en: string;
        fr: string;
        "iu-cans": string;
        "iu-latn": string;
      }
    | string
    | null;
  streamNameOnlyRelationship:
    | {
        id: string;
        name: string;
        translationId: string;
        en: string;
        fr: string;
        "iu-cans": string;
        "iu-latn": string;
      }
    | string
    | null;
  id: string;
  fundingProgram: string | null;
  shortDescriptionRelationship:
    | {
        id: string;
        name: string;
        translationId: string;
        en: string;
        fr: string;
        "iu-cans": string;
        "iu-latn": string;
      }
    | string
    | null;
  eligibilityQuiz: string;
  applicationForm: string;
  applicationPrintView: string;
  granteeReportPreview: string;
  granteeProgressReportSurveyTemplate: string;
  granteeFinalReportSurveyTemplate: string;
  applicationsListPrintPreview: string;
  fundingAgreement: string;
  selectionCommitteePreview: string;
  allocationCommitteePreview: string;
  boardSummaryPreview: string;
  CMHCSummaryPreview: string;
  objectiveRecordType: string;
  disableInProvinces: string[] | null;
  availableInLanguages: string[] | null;
  validityStart: moment.Moment;
  validityEnd: moment.Moment;
  reminderDate: moment.Moment;
  isInTesting: boolean;
  isObsolete: boolean;
  moreInfoUrlRelationship:
    | {
        id: string;
        name: string;
        translationId: string;
        en: string;
        fr: string;
        "iu-cans": string;
        "iu-latn": string;
      }
    | string
    | null;
  moreInfoUrl: string | null;
  logoUrl: string;
  notes: string;
  order: number;
  configurationDataImported: boolean;
  caseType: string | null;
}

/**
 * Parses the fields of a funding stream object.
 * @function
 * @category Salesforce - Funding Stream
 * @param {FundingStream | FundingStreamParsed} obj The funding stream object to parse.
 * @returns {FundingStream | FundingStreamParsed} The parsed and mapped form object.
 */
export const parseFundingStream = (obj: FundingStream | FundingStreamParsed) =>
  mapSFToForm(mapFields, obj);

/**
 * Retrieves funding stream objects from Salesforce.
 * @function
 * @category Salesforce - Funding Stream
 * @returns {FundingStream[]}
 */
export const getFundingStreams = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Funding_Stream__c")
    .find({})
    .select(
      "*, Full_Name__r.*, Funding_Program__r.*, Funding_Program__r.Full_name__r.*, Short_description__r.*, Stream_name_only__r.*, More_info_url__r.*"
    );
};

/**
 * Retrieves funding stream objects from Salesforce and parses their fields.
 * @function
 * @category Salesforce - Funding Stream
 * @returns {FundingStreamParsed[]}
 */
export const getFundingStreamsParsed = () => {
  const conn = SFAuthService.getConnection();
  conn.queryMore();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Funding_Stream__c")
    .find({})
    .select(
      "*, Full_Name__r.*, Funding_Program__r.*, Funding_Program__r.Full_name__r.*, Short_description__r.*, Stream_name_only__r.*, More_info_url__r.*"
    )
    .then((result) => {
      return result.map((obj) =>
        liftNestedProperties(mapSFToForm(mapFields, obj))
      );
    });
};

const processTranslationField = (stream, fieldPath, keyName) => {
  let field = stream;

  if (fieldPath === "Funding_Program__r") {
    return {
      id: field.Funding_Program__c || "",
      name: field.Funding_Program__r?.Name || "",
      [keyName]: {
        en: field.Funding_Program__r?.Full_Name__r?.Translated_EN__c || "",
        fr: field.Funding_Program__r?.Full_Name__r?.Translated_FR__c || "",
        "iu-cans":
          field.Funding_Program__r?.Full_Name__r?.Translated_IU_Cans__c || "",
        "iu-latn":
          field.Funding_Program__r?.Full_Name__r?.Translated_IU_Latn__c || "",
      },
    };
  } else {
    const fields = fieldPath.split(".");
    for (const f of fields) {
      if (field && field[f]) {
        field = field[f];
      } else {
        return null;
      }
    }
  }

  if (!field) return null;

  const en = field.Translated_EN__c || "";
  const fr = field.Translated_FR__c || "";
  const iuCans = field.Translated_IU_Cans__c || "";
  const iuLatn = field.Translated_IU_Latn__c || "";

  return {
    id: field.Id || "",
    name: field.Name || "",
    portalTranslationId: field.Translation_Id__c || "",
    [keyName]: {
      en: en,
      fr: fr,
      "iu-cans": iuCans,
      "iu-latn": iuLatn,
    },
  };
};

const liftProperty = (obj, key, nestedKey) => {
  if (obj[key]) {
    obj[key] = {
      ...obj[key],
      en: obj[key][nestedKey].en || "",
      fr: obj[key][nestedKey].fr || "",
      "iu-cans": obj[key][nestedKey]["iu-cans"] || "",
      "iu-latn": obj[key][nestedKey]["iu-latn"] || "",
    };
    obj[`${key}EN`] = obj[key][nestedKey].en || "";
    obj[`${key}FR`] = obj[key][nestedKey].fr || "";
    obj[`${key}IUC`] = obj[key][nestedKey]["iu-cans"] || "";
    obj[`${key}IUL`] = obj[key][nestedKey]["iu-latn"] || "";

    delete obj[key][nestedKey];
  }
};

export const liftNestedProperties = (stream) => {
  const liftedStream = { ...stream };

  liftProperty(liftedStream, "fullName", "fullName");
  liftProperty(liftedStream, "name", "nameTranslations");
  liftProperty(liftedStream, "description", "description");
  liftProperty(liftedStream, "moreInfoUrl", "moreInfoUrl");
  liftProperty(liftedStream, "subtitle", "subtitle");

  return liftedStream;
};

export const saveFundingStreams = (data) => {
  const conn = SFAuthService.getConnection();

  if (!conn) {
    return Promise.reject(NO_USER);
  }

  const toUpdate = data.streamList.map((dataToSave, index) => {
    const item = { ...dataToSave };
    delete item.expanded;

    item.order = index;

    if (item.startTime) {
      const date = moment.utc(item.startTime);
      const local = date.local(true);
      item.startTime = moment.utc(local);
    }

    if (item.endTime) {
      const date = moment.utc(item.endTime);
      const local = date.local(true);
      item.endTime = moment.utc(local);
    }

    if (item.reminderTime) {
      const date = moment.utc(item.reminderTime);
      const local = date.local(true);
      item.reminderTime = moment.utc(local);
    }

    let updatedItem = { ...item };

    const relationshipKeys = ["fullName", "name", "description", "moreInfoUrl"];
    const reverseMapFields = reverseMap(mapFields);

    for (const key in item) {
      if (relationshipKeys.includes(key) && item[key]) {
        updatedItem = { ...updatedItem, [key]: item[key].name };
      }

      if (key === "subtitle") {
        updatedItem = { ...updatedItem, [key]: item.subtitle.id };
      }
    }

    return mapSFToForm(reverseMapFields, updatedItem);
  });

  return conn.sobject("Funding_Stream__c").update(toUpdate);
};

/**
 * Retrieves case types available for funding streams from Salesforce.
 * @function
 * @category Salesforce - Funding Stream
 * @returns {object[]}
 */
export const getCaseTypesForFundingStreams = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.describe("Funding_Stream__c").then((result) => {
    let toRet = [];
    result.fields.some((field) => {
      const bool = field.name === "Case_type__c";
      if (bool) {
        toRet = field.picklistValues
          .filter((field) => field.active)
          .map((field) => ({
            id: field.value,
            name: field.value,
            label: field.label,
            value: field.value,
          }));
      }
      return bool;
    });
    return toRet;
  });
};
