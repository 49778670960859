import { NO_USER } from '../SFAuthService'
import SFAuthService from '../SFAuthService'

export const FIRST_SIGNER = 'FIRST_SIGNER'
export const SECOND_SIGNER = 'SECOND_SIGNER'
export const contactRoles = {
  [FIRST_SIGNER]: 'First Signer',
  [SECOND_SIGNER]: 'Second Signer'
}

/**
 * Retrieves Opportunity affiliations and associated contacts by invoking a specified flow.
 * @function
 * @category Salesforce - Opportunity_Affiliation__ and Contact__c
 * @param {string} opportunity The id of the Opportunity.
 * @param {string[]} opportunityIds An array of Opportunity ids.
 * @returns {OpportunityAffiliation[]}
 */
export const getOpportunityAffiliationsByFlow = ({
  opportunity,
  opportunityIds
}: {
  opportunity: string,
  opportunityIds: string[]
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .requestPost(
        '/actions/custom/flow/App_Opportunity_Affiliations_and_Contacts',
        {
          inputs: [{ opportunityId: opportunity, opportunityIds }]
        }
      )
      .then(flowResult => {
        const { contacts, opportunityAffiliations } = flowResult[0].outputValues
        if (!opportunityAffiliations) {
          return []
        }
        const contactsMap = {}
        contacts &&
          contacts.forEach(contact => {
            contactsMap[contact.Id] = contact
          })
        opportunityAffiliations.forEach(aff => {
          aff.Contact__c = contactsMap[aff.Contact__c]
        })
        return opportunityAffiliations || []
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

/**
 * Updates Opportunity contact affiliations by invoking a specified flow.
 * @function
 * @category Salesforce - Opportunity_Affiliation__c, Contact__c
 * @param {object} toUpdate The data to update the Opportunity contact affiliations.
 * @returns {FlowResult}
 */
export const updateOpportunityContactAffiliationByFlow = (toUpdate: object) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Update_Opportunity_Affiliations',
      {
        inputs: [{ toUpdate }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

/**
 * Adds Opportunity contact affiliations by invoking a specified flow.
 * @function
 * @category Salesforce - Opportunity_Affiliation__c, Contact__c
 * @param {object} toCreate The data to create the Opportunity contact affiliations.
 * @returns {FlowResult}
 */
export const addOpportunityContactAffiliationByFlow = (toCreate: object) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Create_Opportunity_Affiliations',
      {
        inputs: [{ toCreate }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}
