import { SET_CASE_TYPES } from '../actions/CaseDataActions'

const initialState = []

const tutorialsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_CASE_TYPES: {
      if (!action.data) {
        return state
      }
      return action.data
    }
    default: {
      return state
    }
  }
}

export default tutorialsReducer
