import { Trans, t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import { commonPdfStyles } from '../../../common/Common'

export const FormObjectivesPdf = ({
  id,
  title,
  langVersion,
  typeProps,
  value
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily
  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    displayNeedsInPDF
  } = typeProps

  const pdfStyles = StyleSheet.create({
    headerCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'left',
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    },
    cell: {
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    }
  })

  const headerStyle = { ...pdfStyles.headerCell }
  headerStyle.fontWeight = 'bold'
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }

  return (
    <View>
      {title ? (
        <View>
          <Text style={commonPdfStyles.title}>{title}</Text>
        </View>
      ) : (
        <View />
      )}
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Objective`)}</Text>
        </View>
        {displayNeedsInPDF && (
          <View style={headerStyle}>
            <Text>{myI18n?._(t`Needs`)}</Text>
          </View>
        )}
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Outcomes`)}</Text>
        </View>
      </View>
      {value.map((data, index) => {
        const { objective, needs, outcomes = [] } = data
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={pdfStyles.cell}>
              <Text>{objective}</Text>
            </View>
            {displayNeedsInPDF && (
              <View style={pdfStyles.cell}>
                <Text>{needs}</Text>
              </View>
            )}
            <View style={pdfStyles.cell}>
              {outcomes.map((item, index) => {
                return (
                  <View key={index}>
                    <Text>
                      - <Trans id={item} />
                    </Text>
                  </View>
                )
              })}
            </View>
          </View>
        )
      })}
    </View>
  )
}
