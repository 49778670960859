import { Paper } from "@material-ui/core";
import { PropsWithChildren } from "react";

type FormDisplayContainerProps = {
  viewType: "paper" | "none";
  style?: React.CSSProperties;
  styleJSON?: string;
};

export const FormDisplayContainer = ({
  viewType = "paper",
  children,
  style = {},
  styleJSON,
}: PropsWithChildren<FormDisplayContainerProps>) => {
  let baseStyle: React.CSSProperties = {
    padding: 20,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    userSelect: "text",
    ...style,
  };

  try {
    if (styleJSON) {
      const parsed = JSON.parse(styleJSON);
      if (typeof parsed === "object") {
        baseStyle = Object.assign(baseStyle, JSON.parse(styleJSON));
      }
    }
  } catch (e) {}

  if (viewType === "paper") {
    return <Paper style={baseStyle}>{children}</Paper>;
  } else {
    return <div style={baseStyle}>{children}</div>;
  }
};
