import { grpc } from '@improbable-eng/grpc-web'
import _ from 'lodash'
import { Multiuser } from './proto/generated/Multiuser_pb_service'
import {
  FieldCommentID,
  FieldCommentToSend
} from './proto/generated/Multiuser_pb'
import { host } from './grpcMultiuserEdit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

export const grpcGetFieldComment = ({ token, fieldId, onFail, onSuccess }) => {
  const request = new FieldCommentID()
  request.setFieldid(fieldId)
  grpc.unary(Multiuser.GetFieldComment, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      grpc.unary(Multiuser.WhoIsLockingFieldComment, {
        request,
        host,
        metadata: new grpc.Metadata({
          MultiuserSessionToken: token
        }),
        onEnd: lockingResponse => {
          const { array } = lockingResponse.message
          const lockingUserId = array[0]
          if (response.status !== grpc.Code.OK) {
            if (onFail) {
              onFail(response)
            }
          } else {
            if (onSuccess) {
              onSuccess(response.message.array, lockingUserId)
            }
          }
        }
      })
    }
  })
}

export const grpcUpdateFieldComment = ({
  comment,
  token,

  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentToSend()
  request.setFieldid(fieldId)
  request.setContent(comment)
  grpc.unary(Multiuser.UpdateFieldComment, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcUnlockFieldComment = ({
  comment,
  token,

  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentToSend()
  request.setFieldid(fieldId)
  request.setContent(comment)
  grpc.unary(Multiuser.CommitFieldComment, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('unlock comment response', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcLockFieldComment = ({
  comment,
  token,
  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentID()
  request.setFieldid(fieldId)
  grpc.unary(Multiuser.WhoIsLockingFieldComment, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('is field comment locked response', response, fieldId)
      const isLocked = Boolean(response.message.array[0])
      if (isLocked) {
        console.log('field comment is already locked')
      } else {
        const request = new FieldCommentToSend()
        request.setFieldid(fieldId)
        request.setContent(comment)
        grpc.unary(Multiuser.LockFieldComment, {
          request,
          host,
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          onEnd: response => {
            console.log('lock comment response', response)
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      }
    }
  })
}

export const grpcListenForFieldCommentChangedEvent = ({
  token,
  userId,
  onEventRecieved
}) => {
  return grpc.invoke(Multiuser.UserChangeFieldComment, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      UserID: userId,
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      if (onEventRecieved) {
        onEventRecieved({
          fieldId: response.array[0],
          comment: response.array[1],
          operation: response.array[2] || 0,
          userId: response.array[3]
        })
      }
    }
  })
}
