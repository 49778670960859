import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { NumberFormatDefault } from '../common/Common'
import { PdfPropsForm } from '../common/PdfPropsForm'
import DebouncedTextField from '../common/DebouncedTextField'

export const FormEditorHeader = ({
  editMode,
  depth,
  showPrintProps,
  typeProps = {},
  injectable,
  showPdfProps,
  ...props
}) => {
  const dispatch = useDispatch()
  const { variant = 'h4', bold, italics, fontSize, centerText } = typeProps

  return (
    <div>
      <TextField
        select
        style={{ marginBottom: 10 }}
        fullWidth
        variant='outlined'
        label={<Trans>Variant</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.variant = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        value={variant}
      >
        <MenuItem value='none'>
          <Trans>None</Trans>
        </MenuItem>
        {['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((v, index) => (
          <MenuItem value={v} key={index}>
            <Trans>Variant</Trans>
            {' ' + (index + 1).toString()}
          </MenuItem>
        ))}
      </TextField>
      <DebouncedTextField
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        multiline
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(bold)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.bold = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Bold</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(italics)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.italics = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Italics</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(centerText)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.centerText = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Center text</Trans>}
        />
      </div>
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          showFontSizeOption={true}
          depth={depth}
        />
      )}
    </div>
  )
}
