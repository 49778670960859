import { Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { mapEditorFormElements } from './Common'

/**
 * Interface used to configure actions that should be executed when value of component is changed, or one of its options is selected.
 * @category Form
 * @subcategory Editor
 * @component
 * @returns {JSX.Element}
 * @param  {string} id - Id of edited form element.
 * @param  {object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {object[]} selectActions - Current select actions array that is being configured.
 * @param  {number[]} depth - Position in form editor tree represented by an array of indexes.
 * @param  {number} [index] - Indicates if select actions are being configured for the base elements, or its options
 * @param  {string} langVersion - Current language used in form editor.
 */
export const ConfigureSelectActions = ({
  depth,
  id,
  langVersion,
  index,
  typeProps,
  selectActions
}) => {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const avaliableElements = mapEditorFormElements({
    data: tree,
    langVersion,
    id,
    elementTypes: [
      'textInputNumeric',
      'datePicker',
      'numericSlider',
      'textInput',
      'bool',
      'picklist'
    ],
    asMap: true
  })

  const isSub = index || index === 0
  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Typography>
          <Trans>Actions</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            const toSet = { ...typeProps }
            if (isSub) {
              toSet.options[index].selectActions = [...selectActions]
              toSet.options[index].selectActions.push({})
            } else {
              toSet.selectActions = [...selectActions]
              toSet.selectActions.push({})
            }
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
      </Grid>
      {selectActions.map((item, actionIndex) => {
        return (
          <Grid
            item
            container
            direction='row'
            key={actionIndex}
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Grid item style={{ flex: 1, padding: 10 }}>
              <Grid container direction='row'>
                <Grid xs style={{ padding: 5 }}>
                  <TextField
                    select
                    variant='outlined'
                    label={<Trans>Activate on</Trans>}
                    fullWidth
                    value={item.activation || 'select'}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      if (isSub) {
                        toSet.options[index].selectActions[
                          actionIndex
                        ].activation = e.target.value
                      } else {
                        toSet.selectActions[actionIndex].activation =
                          e.target.value
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <MenuItem value='select'>
                      <Trans>On select</Trans>
                    </MenuItem>
                    <MenuItem value='deselect'>
                      <Trans>On deselect</Trans>
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid xs style={{ padding: 5 }}>
                  <TextField
                    select
                    variant='outlined'
                    label={<Trans>Action</Trans>}
                    fullWidth
                    value={item.action || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      if (isSub) {
                        toSet.options[index].selectActions[actionIndex].action =
                          e.target.value
                      } else {
                        toSet.selectActions[actionIndex].action = e.target.value
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <MenuItem value='reset'>
                      <Trans>Reset element</Trans>
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid xs style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={item.target || ''}
                    onChange={(e, value) => {
                      const targetItem = avaliableElements[value]
                      const toSet = { ...typeProps }
                      const baseObj = {
                        target: targetItem?.id,
                        type: targetItem?.elementType,
                        targetProps: {
                          picklistType: targetItem?.typeProps?.picklistType
                        }
                      }
                      if (isSub) {
                        toSet.options[index].selectActions[actionIndex] = {
                          ...toSet.options[index].selectActions[actionIndex],
                          ...baseObj
                        }
                      } else {
                        toSet.selectActions[actionIndex] = {
                          ...toSet.selectActions[actionIndex],
                          ...baseObj
                        }
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    getOptionLabel={id => avaliableElements[id]?.label || ''}
                    options={Object.values(avaliableElements).map(
                      (item, index) => item.id
                    )}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Target</Trans>}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div style={{ width: 50 }}>
              <IconButton
                onClick={() => {
                  const toSet = { ...typeProps }
                  if (isSub) {
                    toSet.options[index].selectActions.splice(actionIndex, 1)
                  } else {
                    toSet.selectActions.splice(actionIndex, 1)
                  }
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                <Icon color='primary'>delete</Icon>
              </IconButton>
            </div>
          </Grid>
        )
      })}
    </>
  )
}
