import { DialogTitle, Grid, Icon, IconButton } from '@material-ui/core'

/**
 * DialogTitle with added close icon
 * @category Common components
 * @subcategory MUI extended
 * @component
 * @returns {JSX.Element}
 * @property {boolean} disabled If close icon should be disabled
 * @property {function} handleClose Passed to on close icon onClick event
 * @property {(string|JSX.Element)} label Dialog title label
 */
export const DialogTitleWithIconClose = ({ handleClose, label, disabled }) => {
  return (
    <DialogTitle>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        wrap='nowrap'
      >
        {label}
        <IconButton
          style={{ marginLeft: 8 }}
          onClick={handleClose}
          disabled={disabled}
        >
          <Icon>close</Icon>
        </IconButton>
      </Grid>
    </DialogTitle>
  )
}
