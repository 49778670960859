import { Trans } from '@lingui/macro'
import {
  Button,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import { FormSignature } from './FormSignature'

export const FormEditorSignature = ({
  editMode,
  depth,
  typeProps = {},
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { objects } = values
  const { signatories = [], placeholder } = typeProps
  if (!editMode) {
    return <FormSignature editMode typeProps={typeProps} {...props} />
  }
  let rolesSfData = []
  objects.some(obj => {
    const bool = obj.name === 'Opportunity_Affiliation__c'
    if (bool) {
      obj.fields.some(field => {
        const bool = field.name === 'Role__c'
        if (bool) {
          rolesSfData = field.picklistValues.filter(v => v.active)
        }
        return bool
      })
    }
    return bool
  })
  return (
    <div>
      {/* <TextField
        label={<Trans>OneSpan Placeholder</Trans>}
        fullWidth
        value={placeholder}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.placeholder = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      /> */}
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      <div style={{ padding: 10 }}>
        <Grid container>
          <Typography variant='h6'>
            <Trans>Signatories</Trans>
          </Typography>
          <Button
            variant='contained'
            color='primary'
            style={{ marginLeft: 10 }}
            onClick={e => {
              const toSet = { ...typeProps }
              const newSignatories = [...signatories]
              newSignatories.push({
                role: ''
              })
              toSet.signatories = newSignatories
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          >
            <Grid container justify='center'>
              <Icon style={{ marginRight: 5 }}>add</Icon>
              <Trans>Add signatory</Trans>
            </Grid>
          </Button>
        </Grid>
      </div>
      {signatories.map((obj, index) => {
        return (
          <div key={index} style={{ padding: 5 }}>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs>
                <b>{Number(index + 1) + '. '}</b>
              </Grid>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.signatories[index]
                  const toReplace = toSet.signatories[index + 1]
                  toSet.signatories[index + 1] = toMove
                  toSet.signatories[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === signatories.length - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.signatories[index]
                  const toReplace = toSet.signatories[index - 1]
                  toSet.signatories[index - 1] = toMove
                  toSet.signatories[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.signatories.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
            <TextField
              fullWidth
              variant='outlined'
              select
              label={<Trans>Role</Trans>}
              value={obj.role || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.signatories[index].role = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            >
              {rolesSfData.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )
      })}
    </div>
  )
}
