import { t, Trans } from "@lingui/macro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Tab,
  TableCell,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import {
  dateFormat,
  datetimeFormat,
  portalLanguagesData,
} from "app/appSettings";
import sfOauthConfig from "app/services/sfAuth/sfAuthConfig";
import {
  contentDocumentDownloadUrl,
  deleteDocumentByFlow,
  getDocumentsByEntitiesByFlow,
  linkFileByFlow,
  uploadFile,
} from "app/services/sfAuth/sfData/sfFiles";
import { getNetwork } from "app/services/sfAuth/sfData/sfUser";
import {
  createMeetingByFlow,
  deleteMeetingByFlow,
  getWorkgroupMembershipsByFlow,
  getWorkgroupsByFlow,
  updateMeetingByFlow,
} from "app/services/sfAuth/sfData/sfWorkgroups";
import Loading from "egret/components/EgretLoadable/Loading";
import { Formik } from "formik";
import { FormikTextField } from "formik-material-fields";
import _, { isError } from "lodash";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { languages, myI18n } from "translation/I18nConnectedProvider";
import { muiDateCompare } from "utils";
import * as Yup from "yup";
import { CustomDateTimePicker } from "../../common-components/CustomDateTimePicker";
import { requiredTrans } from "../../forms/formTranslations";
import { FormikCKEditor } from "../../page-layouts/CKEditor/FormikCKEditor";
import ProgressSnackbar from "../../page-layouts/CustomSnackbars";
import { countHelperText } from "../../page-layouts/FormElement";
import { muiTextLabels } from "../../utilities/muiDataTablesTranslations";

const crypto = require("crypto");

const Committees = ({ match }) => {
  const { params } = match;
  const [loading, setLoading] = useState(false);
  const [deletingIds, setDeletingIds] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [workgroups, setWorkGroups] = useState([]);
  const [network, setNetwork] = useState(null);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fetchData = () => {
    return Promise.all([
      getWorkgroupMembershipsByFlow(user.userId),
      getNetwork(),
    ]).then(([r, network]) => {
      const { memberships } = r.outputValues;
      const toFetch = [];
      memberships.forEach((obj) => {
        if (obj.Workgroup__c && !toFetch.includes(obj.Workgroup__c)) {
          toFetch.push(obj.Workgroup__c);
        }
      });
      setNetwork(network);
      return getWorkgroupsByFlow(toFetch).then((result) => {
        return getDocumentsByEntitiesByFlow(toFetch).then(
          (workgroupFilesMap) => {
            const toSet = result.map((obj, index) => {
              let { members, meetings, workgroup, catalogs } = obj.outputValues;
              if (!members) {
                members = [];
              }
              if (!meetings) {
                meetings = [];
              }
              const groupMembers = members.map((obj, index) => ({
                role: obj.Role__c,
                user: obj.User__c,
              }));

              const lang = user.language.split("_")[0];

              return {
                name:
                  workgroup?.[portalLanguagesData[lang].workgroupName] || "",
                id: workgroup.Id,
                isAdmin: groupMembers.some(
                  (member) =>
                    member.user === user.userId && member.role === "Admin"
                ),
                files: workgroupFilesMap[workgroup.Id] || [],
                members: groupMembers,
                catalogs: catalogs
                  ? catalogs.map((obj) => ({
                      id: obj.Id,
                      text: languages.reduce((acc, lang) => {
                        acc[lang] =
                          obj?.[
                            portalLanguagesData[lang]?.workgroupCatalogLabel
                          ] || "";
                        return acc;
                      }, {}),
                      allowUpload: obj.Allow_Upload__c,
                    }))
                  : [],
                meetings: meetings.map((obj, index) => {
                  const meetingData = {
                    date: moment.utc(obj.On_Date__c),
                    files: [],
                    minutes: obj.Minutes__c,
                    agenda: obj.Agenda__c,
                    id: obj.Id,
                    name: obj.Name,
                  };
                  return { ...meetingData, actions: meetingData };
                }),
              };
            });
            const meetingIds = [];
            toSet.forEach((group) => {
              group.meetings.forEach((meeting) => {
                meetingIds.push(meeting.id);
              });
            });
            return getDocumentsByEntitiesByFlow(meetingIds).then((filesMap) => {
              toSet.forEach((group) => {
                group.meetings.forEach((meeting) => {
                  meeting.files = filesMap[meeting.id] || [];
                  meeting.actions.files = filesMap[meeting.id] || [];
                });
              });
              setWorkGroups(toSet);
              if (params.initialTab) {
                setCurrentTab(params.initialTab);
              } else if (toSet.length > 0 && !currentTab) {
                setCurrentTab(toSet[0].id);
              }
              setLoading(false);
            });
          }
        );
      });
    });
  };

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [user.language]);

  useEffect(() => {
    setCurrentMeeting(null);
  }, [currentTab]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (workgroups.length === 0) {
    return (
      <div style={{ padding: 20 }}>
        <Typography
          style={{ fontSize: 24, fontWeight: 450, textAlign: "center" }}
        >
          <Trans>You are currently not added to any committee!</Trans>
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ padding: 15 }}>
      <TabContext value={currentTab}>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={currentTab}
          onChange={(e, v) => {
            setCurrentTab(v);
          }}
        >
          {workgroups.map((group, index) => {
            return (
              <Tab
                fullWidth
                disabled={currentMeeting && currentTab !== group.id}
                key={index}
                label={group.name}
                value={group.id}
              />
            );
          })}
        </Tabs>
        {workgroups.map((group, groupIndex) => {
          const onRemoveItem = (id, index) => {
            enqueueSnackbar(<Trans>Deleting File</Trans>, {
              variant: "info",
            });
            const toSet = [...workgroups];
            const fakeId = crypto.randomBytes(8).toString("hex");
            toSet[groupIndex].files[index].deleting = true;
            toSet[groupIndex].files[index].fakeId = fakeId;

            setWorkGroups(toSet);
            deleteDocumentByFlow(id).then(
              (result) => {
                enqueueSnackbar(<Trans>Deleted File</Trans>, {
                  variant: "success",
                });
                const toSet = _.cloneDeep(workgroups);
                toSet[groupIndex].files.some((file, index) => {
                  if (file.fakeId === fakeId) {
                    toSet[groupIndex].files.splice(index, 1);
                    return true;
                  }
                  return false;
                });
                setWorkGroups(toSet);
              },
              (reject) => {
                enqueueSnackbar(<Trans>Error deleting file</Trans>, {
                  variant: "error",
                });
                const toSet = _.cloneDeep(workgroups);
                toSet[groupIndex].files.some((file, index) => {
                  if (file.fakeId === fakeId) {
                    toSet[groupIndex].files[index].deleting = false;
                    return true;
                  }
                  return false;
                });
                setWorkGroups(toSet);
              }
            );
          };

          const handleOnDrop = (files, catalogId, description) => {
            console.log("handleOnDrop", files, network);
            const listCount = files.length;
            const uploadId = listCount + 1;
            const file = files[0];
            const toSet = [...workgroups];
            const fakeId = crypto.randomBytes(8).toString("hex");
            toSet[groupIndex].files.push({
              uploadId,
              tags: catalogId,
              name: file.name,
              fakeId,
              progress: true,
            });
            setWorkGroups(toSet);
            console.log("upload", [...files]);
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
              const binaryStr = reader.result;
              console.log("binary", binaryStr);
              enqueueSnackbar(<Trans>Uploading File</Trans>, {
                variant: "info",
              });
              uploadFile(
                {
                  name: file.name,
                  description,
                  tags: catalogId,
                  uploadId: user.userId,
                },
                binaryStr,
                network.Id,
                true
              )
                .then((uploadedFile) => {
                  linkFileByFlow({
                    target: group.id,
                    file: uploadedFile.Id,
                  }).then((result) => {
                    enqueueSnackbar(<Trans>Uploaded File</Trans>, {
                      variant: "success",
                    });
                    const toSet = _.cloneDeep(workgroups);
                    toSet[groupIndex].files.some((file, index) => {
                      if (file.fakeId === fakeId) {
                        toSet[groupIndex].files[index] = {
                          id: uploadedFile.Id,
                          url: contentDocumentDownloadUrl(uploadedFile.Id),
                          uploadId,
                          tags: catalogId,
                          name: file.name,
                        };
                        return true;
                      }
                      return false;
                    });
                    setWorkGroups(toSet);
                  });
                })
                .catch((error) => {
                  console.warn("cant upload file", error);
                  const errorString = isError(error) ? error.toString() : error;
                  if (errorString.includes("Maximum size")) {
                    enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
                      variant: "error",
                    });
                  }
                  const toSet = _.cloneDeep(workgroups);
                  toSet[groupIndex].files.some((file, index) => {
                    if (file.fakeId === fakeId) {
                      toSet[groupIndex].files.splice(index, 1);
                      return true;
                    }
                    return false;
                  });
                  setWorkGroups(toSet);
                });
            };
            reader.readAsArrayBuffer(file);
          };

          return (
            <TabPanel key={groupIndex} value={group.id}>
              {!currentMeeting ? (
                <div>
                  {group.isAdmin && (
                    <Grid container>
                      <AddNewMeetingButton
                        workgroupId={currentTab}
                        reloadData={fetchData}
                      />
                      <Grid item style={{ marginLeft: 10 }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => {
                            if (sfOauthConfig.isIcce) {
                              history.push(
                                `/grants/EditCommittee/${currentTab}`
                              );
                            } else {
                              history.push(
                                `/grants/EditWorkgroup/${currentTab}`
                              );
                            }
                          }}
                        >
                          <Trans>Edit committee</Trans>
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <MUIDataTable
                    title={
                      <Typography variant="h2">
                        <Trans>Meetings</Trans>
                      </Typography>
                    }
                    data={group.meetings}
                    options={{
                      textLabels: muiTextLabels(myI18n),
                      filter: false,
                      selectableRows: "none",
                      print: false,
                      download: false,
                      viewColumns: false,
                    }}
                    columns={[
                      {
                        name: "name",
                        label: myI18n._(t`Name`),
                        options: {
                          customHeadLabelRender: (props) => {
                            return (
                              <div style={{ marginLeft: 20 }}>
                                {myI18n._(t`Name`)}
                              </div>
                            );
                          },
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <div style={{ flex: 1, marginLeft: 20 }}>
                                {value}
                              </div>
                            );
                          },
                        },
                      },
                      {
                        name: "date",
                        sortCompare: muiDateCompare("date"),
                        label: myI18n._(t`On date`),
                        options: {
                          customHeadLabelRender: (props) => (
                            <Trans>On date</Trans>
                          ),
                          customBodyRender: (value) =>
                            value
                              ? moment.utc(value).format(datetimeFormat)
                              : "-",
                        },
                      },
                      {
                        name: "actions",
                        label: myI18n._(t`Actions`),
                        options: {
                          sort: false,
                          customHeadRender: (props) => (
                            <TableCell
                              style={{ width: 120, textAlign: "center" }}
                            >
                              <Trans>Actions</Trans>
                            </TableCell>
                          ),
                          customBodyRender: (value, tableMeta, updateValue) => {
                            const disabled = deletingIds.includes(value.id);
                            return (
                              <Grid container justify="center">
                                <Tooltip title={<Trans>Go to meeting</Trans>}>
                                  <IconButton
                                    disabled={disabled}
                                    onClick={() => {
                                      setCurrentMeeting(value);
                                    }}
                                  >
                                    <Icon>forward</Icon>
                                  </IconButton>
                                </Tooltip>
                                {group.isAdmin && (
                                  <Tooltip
                                    title={<Trans>Delete meeting</Trans>}
                                  >
                                    <IconButton
                                      style={{
                                        color: !disabled && "red",
                                      }}
                                      disabled={disabled}
                                      onClick={() => {
                                        const deletingSnackbar =
                                          enqueueSnackbar(
                                            <Trans>
                                              Deleting {value.name}
                                            </Trans>,
                                            { variant: "info" }
                                          );
                                        const toSet = [...deletingIds];
                                        toSet.push(value.id);
                                        setDeletingIds(toSet);
                                        deleteMeetingByFlow({
                                          Id: value.id,
                                        }).then((result) => {
                                          fetchData().then((result) => {
                                            closeSnackbar(deletingSnackbar);
                                            enqueueSnackbar(
                                              <Trans>
                                                Deleted {value.name}
                                              </Trans>,
                                              { variant: "success" }
                                            );
                                            const toSet = [...deletingIds];
                                            toSet.splice(
                                              toSet.indexOf(value.id),
                                              1
                                            );
                                            setDeletingIds(toSet);
                                          });
                                        });
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                            );
                          },
                        },
                      },
                    ]}
                  />
                  <WorkGroupFilesList
                    files={group.files}
                    catalogs={group.catalogs}
                    isAdmin={group.isAdmin}
                    id={group.id}
                    workgroupName={group.name}
                    onRemoveItem={onRemoveItem}
                    handleOnDrop={handleOnDrop}
                  />
                </div>
              ) : (
                <MeetingTab
                  handleBack={(e) => {
                    setCurrentMeeting(null);
                  }}
                  workgroupName={group.name}
                  catalogs={group.catalogs}
                  isAdmin={group.isAdmin}
                  network={network}
                  reloadData={fetchData}
                  {...currentMeeting}
                />
              )}
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

const AddNewMeetingButton = ({ reloadData, workgroupId }) => {
  const [open, openDialog] = useState(false);
  const [creating, setCreating] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const initialValues = {
    name: "",
    agenda: "",
    date: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(requiredTrans),
        agenda: Yup.string().required(requiredTrans),
        date: Yup.date().required(requiredTrans),
      })}
      enableReinitialize
    >
      {({ values, isValid, setFieldValue, resetForm, dirty }) => {
        const { name, agenda, date } = values;
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              style={{ marginBottom: 10 }}
              onClick={(e) => {
                openDialog(true);
              }}
            >
              <Grid container>
                <Icon style={{ marginRight: 5 }}>add</Icon>
                <Trans>Add new meeting</Trans>
              </Grid>
            </Button>
            <Dialog open={open} maxWidth="sm" fullWidth>
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Trans>Add new meeting</Trans>
                  <IconButton
                    disabled={creating}
                    onClick={(e) => {
                      openDialog(false);
                      resetForm(initialValues);
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <FormikTextField
                  name="name"
                  disabled={creating}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  label={<Trans>Name</Trans>}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 80,
                  }}
                  helperText={countHelperText(name, 80, true)}
                />
                <FormikTextField
                  name="agenda"
                  disabled={creating}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  label={<Trans>Agenda</Trans>}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={<Trans>Required</Trans>}
                />
                <CustomDateTimePicker
                  disabled={creating}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  label={<Trans>On date</Trans>}
                  cancelLabel={<Trans>Cancel</Trans>}
                  okLabel={<Trans>Ok</Trans>}
                  format={datetimeFormat}
                  className="text-muted"
                  inputVariant="outlined"
                  fullWidth
                  //minDate={moment.utc()}
                  value={date}
                  onChange={(e) => {
                    setFieldValue("date", e);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty || creating}
                  onClick={(e) => {
                    setCreating(true);
                    const snackbar = enqueueSnackbar(null, {
                      variant: "info",
                      persist: true,
                      content: (key) =>
                        ProgressSnackbar(<Trans>Creating meeting</Trans>),
                    });
                    createMeetingByFlow({
                      On_Date__c: date,
                      Workgroup__c: workgroupId,
                      Agenda__c: agenda,
                      Name: name,
                    }).then(
                      (result) => {
                        reloadData().then((result) => {
                          closeSnackbar(snackbar);
                          setCreating(false);
                          enqueueSnackbar(<Trans>Meeting created!</Trans>, {
                            variant: "success",
                          });
                          openDialog(false);
                        });
                      },
                      (reject) => {
                        closeSnackbar(snackbar);
                        setCreating(false);
                        enqueueSnackbar(
                          <Trans>Error ocurred while creating meeting!</Trans>,
                          { variant: "error" }
                        );
                      }
                    );
                  }}
                >
                  <Trans>Add</Trans>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 21,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  dropbox: {
    margin: "20px 0",
    border: "2px dashed #0085B8",
    padding: 35,
    backgroundColor: "rgba(0, 133, 184, 0.05)",
    "&:hover": {
      backgroundColor: "rgba(0, 133, 184, 0.15)",
      border: "3px dashed #0085B8",
      textWeight: "bold",
    },
  },
}));

const MeetingTab = ({
  id,
  workgroupName,
  files,
  network,
  name,
  date,
  isAdmin,
  agenda,
  minutes,
  handleBack,
  reloadData,
  catalogs,
  ...props
}) => {
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const formikRef = useRef();

  return (
    <Formik
      initialValues={{
        name,
        agenda,
        minutes,
        date,
        files,
      }}
      enableReinitialize
      innerRef={formikRef}
    >
      {({ values, setFieldValue, dirty, resetForm }) => {
        const orphanFiles = [];
        files.forEach((file) => {
          if (
            !catalogs.some((catalog) => file.tags.includes(catalog.id)) &&
            file.parent === id
          ) {
            orphanFiles.push(file);
          }
        });

        const handleSave = () => {
          setSaving(true);
          const snackbar = enqueueSnackbar(null, {
            variant: "info",
            persist: true,
            content: (key) =>
              ProgressSnackbar(<Trans>Saving {values.name}</Trans>),
          });
          updateMeetingByFlow({
            Id: id,
            Name: values.name,
            On_Date__c: values.date,
            Agenda__c: values.agenda,
            Minutes__c: values.minutes,
          }).then(
            (result) => {
              const toReset = { ...values };
              reloadData().then((result) => {
                closeSnackbar(snackbar);
                setSaving(false);
                enqueueSnackbar(<Trans>{values.name} updated</Trans>, {
                  variant: "success",
                });
                resetForm({ values: toReset });
              });
            },
            (reject) => {
              closeSnackbar(snackbar);
              setSaving(false);
              enqueueSnackbar(
                <Trans>Error ocurred while updating {values.name}!</Trans>,
                {
                  variant: "error",
                }
              );
            }
          );
        };

        const onRemoveItem = (id, index) => {
          enqueueSnackbar(<Trans>Deleting File</Trans>, {
            variant: "info",
          });
          const newFiles = [...formikRef.current.values.files];
          const fakeId = crypto.randomBytes(8).toString("hex");
          newFiles[index].fakeId = fakeId;
          newFiles[index].deleting = true;
          setFieldValue("files", newFiles);
          deleteDocumentByFlow(id).then(
            (result) => {
              enqueueSnackbar(<Trans>Deleted File</Trans>, {
                variant: "success",
              });
              const newFiles = [...formikRef.current.values.files];
              newFiles.some((file, index) => {
                if (file.fakeId === fakeId) {
                  newFiles.splice(index, 1);
                  return true;
                }
                return false;
              });
              setFieldValue("files", newFiles);
              // setFieldValue('files', [...files.filter(item => item.id !== id)])
              // reloadData()
            },
            (reject) => {
              enqueueSnackbar(<Trans>Error deleting file</Trans>, {
                variant: "error",
              });
              const newFiles = [...formikRef.current.values.files];
              newFiles.some((file, index) => {
                if (file.fakeId === fakeId) {
                  delete newFiles[index].fakeId;
                  delete newFiles[index].deleting;
                  return true;
                }
                return false;
              });
              // delete toSet[index].deleting
              setFieldValue("files", newFiles);
            }
          );
        };

        const handleOnDrop = (files, catalogId, description) => {
          console.log("handleOnDrop", files, network);
          const listCount = files.length;
          const uploadId = listCount + 1;
          const file = files[0];
          const newFiles = [...formikRef.current.values.files];
          const fakeId = crypto.randomBytes(8).toString("hex");
          newFiles.push({
            uploadId,
            fakeId,
            tags: catalogId,
            name: file.name,
            progress: true,
          });
          console.log("upload", [...files]);
          setFieldValue("files", newFiles);
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            const binaryStr = reader.result;
            console.log("binary", binaryStr);
            enqueueSnackbar(<Trans>Uploading File</Trans>, {
              variant: "info",
            });
            uploadFile(
              {
                name: file.name,
                description,
                tags: catalogId,
                uploadId: user.userId,
              },
              binaryStr,
              network.Id,
              true
            )
              .then((uploadedFile) => {
                linkFileByFlow({
                  target: id,
                  file: uploadedFile.Id,
                }).then((result) => {
                  enqueueSnackbar(<Trans>Uploaded File</Trans>, {
                    variant: "success",
                  });
                  const newFiles = [...formikRef.current.values.files];
                  newFiles.some((file, index) => {
                    if (file.fakeId === fakeId) {
                      newFiles[index] = {
                        id: uploadedFile.Id,
                        url: contentDocumentDownloadUrl(uploadedFile.Id),
                        uploadId,
                        description,
                        tags: catalogId,
                        name: file.name,
                      };
                      return true;
                    }
                    return false;
                  });
                  setFieldValue("files", newFiles);
                  // reloadData()
                });
              })
              .catch((error) => {
                console.warn("cant upload file", error);
                const errorString = isError(error) ? error.toString() : error;
                if (errorString.includes("Maximum size")) {
                  enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
                    variant: "error",
                  });
                } else {
                  enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
                    variant: "error",
                  });
                }
                const newFiles = [...formikRef.current.values.files];
                newFiles.some((file, index) => {
                  if (file.fakeId === fakeId) {
                    newFiles.files.splice(index, 1);
                    return true;
                  }
                  return false;
                });
                // const toSet = [...newFiles]
                // toSet.splice(toSet.length - 1, 1)
                setFieldValue("files", newFiles);
              });
          };
          reader.readAsArrayBuffer(file);
        };

        return (
          <div style={{ padding: 10 }}>
            <Grid container style={{ marginBottom: 10 }}>
              {/* <Dialog open={warningIsOpen}>
                <DialogTitle>
                  <Trans>There are unsaved changes present in this form!</Trans>
                </DialogTitle>
                <DialogContent>
                  <Trans>
                    Would you like to save your changes before leaving?
                  </Trans>
                </DialogContent>
                <DialogActions>
                  <Grid container direction='row' justify='space-evenly'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setWarningOpen(false)
                        handleBack()
                      }}
                    >
                      <Trans>Leave without saving</Trans>
                    </Button>
                    {handleSave && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          handleSave()
                          setWarningOpen(false)
                        }}
                      >
                        <Trans>Save</Trans>
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setWarningOpen(false)
                      }}
                    >
                      <Trans>Cancel</Trans>
                    </Button>
                  </Grid>
                </DialogActions>
              </Dialog> */}
              <Button
                disabled={saving}
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                <Grid container alignItems="center">
                  <Icon style={{ marginRight: 5 }}>arrow_back</Icon>
                  <Trans>Back</Trans>
                </Grid>
              </Button>
              {isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 10 }}
                  disabled={saving}
                  onClick={handleSave}
                >
                  <Grid container alignItems="center">
                    <Icon style={{ marginRight: 5 }}>save</Icon>
                    <Trans>Save</Trans>
                  </Grid>
                </Button>
              )}
            </Grid>
            {isAdmin ? (
              <FormikTextField
                style={{ marginTop: 10 }}
                name="name"
                variant="outlined"
                fullWidth
                label={<Trans>Name</Trans>}
              />
            ) : (
              <Typography style={{ fontSize: 21, fontWeight: 500 }}>
                {name}
              </Typography>
            )}
            {isAdmin ? (
              <CustomDateTimePicker
                style={{ marginTop: 10, marginBottom: 10 }}
                label={<Trans>On date</Trans>}
                cancelLabel={<Trans>Cancel</Trans>}
                okLabel={<Trans>Ok</Trans>}
                format={datetimeFormat}
                className="text-muted"
                inputVariant="outlined"
                fullWidth
                // minDate={moment.utc()}
                value={values.date}
                onChange={(e) => {
                  setFieldValue("date", e);
                }}
              />
            ) : (
              <Typography style={{ fontSize: 18, fontWeight: 400 }}>
                {moment.utc(date).format(datetimeFormat)}
              </Typography>
            )}
            {isAdmin ? (
              <div style={{ marginTop: 5 }}>
                <Typography style={{ padding: 5, fontWeight: "bold" }}>
                  <Trans>Agenda</Trans>
                </Typography>
                <FormikCKEditor
                  name="agenda"
                  config={{
                    toolbar: [
                      ["Bold", "Italic"],
                      [
                        "NumberedList",
                        "BulletedList",
                        "-",
                        "Outdent",
                        "Indent",
                      ],
                      ["Link", "Unlink"],
                      ["About"],
                    ],
                  }}
                />
              </div>
            ) : (
              <div>
                <Typography className={classes.title}>
                  <Trans>Agenda</Trans>
                </Typography>
                <Typography>{ReactHtmlParser(agenda)}</Typography>
              </div>
            )}
            {isAdmin ? (
              <div style={{ marginTop: 5 }}>
                <Typography style={{ padding: 5, fontWeight: "bold" }}>
                  <Trans>Minutes</Trans>
                </Typography>
                <FormikCKEditor
                  name="minutes"
                  config={{
                    toolbar: [
                      ["Bold", "Italic"],
                      [
                        "NumberedList",
                        "BulletedList",
                        "-",
                        "Outdent",
                        "Indent",
                      ],
                      ["Link", "Unlink"],
                      ["About"],
                    ],
                  }}
                />
              </div>
            ) : (
              <div>
                <Typography className={classes.title}>
                  <Trans>Minutes</Trans>
                </Typography>
                <Typography>{ReactHtmlParser(minutes)}</Typography>
              </div>
            )}
            <Grid container style={{ paddingTop: 10 }}>
              <Grid item xs style={{ padding: 5 }} />
              <Grid item xs style={{ padding: 5 }} />
            </Grid>
            <WorkGroupFilesList
              files={values.files}
              catalogs={catalogs}
              isAdmin={isAdmin}
              id={id}
              workgroupName={workgroupName}
              onRemoveItem={onRemoveItem}
              handleOnDrop={handleOnDrop}
            />
          </div>
        );
      }}
    </Formik>
  );
};

const WorkGroupFilesList = ({
  catalogs,
  isAdmin,
  id,
  workgroupName,
  files = [],
  onRemoveItem,
  handleOnDrop,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  const orphanFiles = [];
  files.forEach((file) => {
    if (
      !catalogs.some((catalog) => file.tags?.includes(catalog.id)) &&
      file.parent === id
    ) {
      orphanFiles.push(file);
    }
  });

  const lang = user.language.split("_")[0] || "en";

  return (
    <div>
      <Typography className={classes.title} key={id}>
        <Trans>Files</Trans>
      </Typography>
      {catalogs.map((folder, index) => {
        return (
          <div key={folder.id}>
            <Typography
              style={{ fontSize: 21, fontWeight: 350, marginTop: 10 }}
            >
              {folder.text?.[lang]}
            </Typography>
            {files.map((file, index) => {
              if (!file.tags.includes(folder.id)) {
                return null;
              }
              const disabled = file.progress || file.deleting;
              return (
                <>
                  <Grid
                    container
                    key={index}
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs style={{ paddingLeft: 35 }}>
                      {file.name}
                    </Grid>
                    <Grid item style={{ padding: 15 }}>
                      <Tooltip title={myI18n._(t`Download file`)}>
                        <IconButton
                          disabled={disabled}
                          variant="link"
                          href={file.url}
                        >
                          <Icon>download</Icon>
                        </IconButton>
                      </Tooltip>
                      {isAdmin && (
                        <Tooltip title={myI18n._(t`Remove file`)}>
                          <IconButton
                            variant="filled"
                            disabled={disabled}
                            onClick={() => {
                              onRemoveItem(file.id, index);
                            }}
                          >
                            <Icon
                              className={
                                !disabled &&
                                "material-icons MuiIcon-root MuiIcon-colorError"
                              }
                              variant="filled"
                            >
                              delete
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  {file.progress && <LinearProgress />}
                  <Divider />
                </>
              );
            })}
            {(isAdmin || folder.allowUpload) && (
              <Dropzone
                multiple={false}
                maxFiles={1}
                onDrop={(files) => {
                  const description =
                    workgroupName +
                    " - " +
                    Object.values(folder.text).join(" - ") +
                    " - " +
                    moment.utc().format(dateFormat);
                  handleOnDrop(files, folder.id, description);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className={classes.dropbox}>
                    <section>
                      <div>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="center"
                          >
                            <Icon style={{ marginRight: 10 }}>upload</Icon>
                            <Trans>
                              Drag 'n' drop file here, or click to select file
                            </Trans>
                          </Grid>
                        </div>
                      </div>
                      <div />
                    </section>
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        );
      })}
      {orphanFiles.length > 0 && (
        <div>
          <Typography style={{ fontSize: 21, fontWeight: 350, marginTop: 10 }}>
            <Trans>Other files</Trans>
          </Typography>
          {orphanFiles.map((file, index) => {
            const disabled = file.deleting;
            return (
              <>
                <Grid
                  container
                  key={index}
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs style={{ paddingLeft: 35 }}>
                    {file.name}
                  </Grid>
                  <Grid item style={{ padding: 15 }}>
                    <Tooltip title={myI18n._(t`Download file`)}>
                      <IconButton
                        disabled={disabled}
                        variant="link"
                        href={file.url}
                      >
                        <Icon>download</Icon>
                      </IconButton>
                    </Tooltip>
                    {isAdmin && (
                      <Tooltip title={myI18n._(t`Remove file`)}>
                        <IconButton
                          variant="filled"
                          disabled={disabled}
                          onClick={() => {
                            onRemoveItem(file.id, index);
                          }}
                        >
                          <Icon
                            className={
                              !disabled &&
                              "material-icons MuiIcon-root MuiIcon-colorError"
                            }
                            variant="filled"
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                {file.progress && <LinearProgress />}
                <Divider />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Committees;
