import { Trans } from '@lingui/macro'
import ConfigureMultilanguageTextFieldWithCKEditor from 'app/views/internal/ConfigureMultilanguageTextFieldWithCKEditor'
import { useDispatch, useSelector } from 'react-redux'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormHtml } from './FormHtmlInjector'

export const FormEditorHtml = ({
  editMode,
  depth,
  typeProps,
  showPdfProps,
  showPdfComponent,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const langVersion = user.language
  const { html, pdfFontSize } = typeProps
  if (!editMode) {
    return <FormHtml editor {...props} typeProps={typeProps} />
  }

  console.log('user in FormEditorHtml: ', user)
  console.log('langVersion in FormEditorHtml: ', langVersion)

  return (
    <div>
      <ConfigureMultilanguageTextFieldWithCKEditor
        value={html}
        label={<Trans>Html</Trans>}
        langVersion={langVersion}
        handleChange={value => {
          const toSet = { ...typeProps }
          toSet.html = value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
          showFontSizeOption={true}
        />
      )}
    </div>
  )
}
