import { Trans } from '@lingui/macro'

export const dateFormat = 'YYYY-MM-DD'
export const datetimeFormat = 'YYYY-MM-DD h:mm A'
export const timeFormat = 'h:mm:ss A'
export const defaultDocTitle = 'The Centre'

export const portalLanguagesData = {
  en: {
    label: 'English',
    sfLocale: 'en_US',
    abbreviation: 'EN',
    labelTrans: <Trans>English</Trans>,
    editKey: 'en',
    surveyTextKey: 'en-ca',
    portalTranslationField: 'Translated_EN__c',
    tutorialStepField: 'Text_English__c',
    workgroupName: 'Name',
    workgroupCatalogLabel: 'Label_English__c'
  },
  fr: {
    label: 'French',
    sfLocale: 'fr',
    abbreviation: 'FR',
    labelTrans: <Trans>French</Trans>,
    editKey: 'fr',
    surveyTextKey: 'fr-ca',
    portalTranslationField: 'Translated_FR__c',
    tutorialStepField: 'Text_French__c',
    workgroupName: 'Workgroup_French_Name__c',
    workgroupCatalogLabel: 'Label_French__c'
  },
  // 'iu-latn': {
  //   label: 'Inuktitut',
  //   abbreviation: 'IU',
  //   labelTrans: <Trans>Inuktitut</Trans>,
  //   editKey: 'iu-latn',
  //   surveyTextKey: 'iu-latn',
  //   portalTranslationField: 'Translated_IU_Latn__c'
  // }
  'iu-cans': {
    label: 'Inuktitut',
    abbreviation: 'IU',
    labelTrans: <Trans>Inuktitut</Trans>,
    editKey: 'iu-cans',
    surveyTextKey: 'iu-cans',
    portalTranslationField: 'Translated_IU_Cans__c',
    tutorialStepField: 'Text_Inuktitut__c',
    workgroupName: 'Workgroup_Inuktitut_Name__c',
    workgroupCatalogLabel: 'Label_Inuktitut__c'
  }
}
