export const FormTablePdfEditor = ({ title, langFR, typeProps }) => {

    const { columns = [] } = typeProps
  
    return (
      <div className='pdf-mode'>
        <div className={`pdfProps form-print-subtitle`}>{title}</div>
        <div className='table'>
          {columns.map((column) => (
            <div className='cell'>
              <div className='subtitle'>{langFR ? column?.labelFR : column?.labelEN}</div>
              {/* <div className='value'>{langFR ? `${column?.labelFR} Placeholder` : `${column?.labelEN} Placeholder`}</div> */}
              <div className='value'><i>{column?.field?.name}</i> Placeholder</div>
            </div>
          ))}
        </div>
      </div>
    )
}