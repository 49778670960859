import moment from 'moment'
import SFAuthService, { NO_USER } from '../SFAuthService'

export const INTERNAL_USER = 'INTERNAL_USER'

interface FindProps {
  CreatedById?: string,
  Account__c: string
}

/**
 * Returns Action records created by user for an organization.
 * @function
 * @category Salesforce - Action__c
 * @param {string} userId Id of user whose actions are fetched. If it's equal to 'INTERNAL_USER' then all of the actions for organization are returned.
 * @param {string} orgId Id of organization the actions were created for.
 * @param {object} permissions Additional subqueriers that should be fetched - identified by keys provided to the object.
 * @param {boolean} permissions.SCORE If true, information about orgin of action is fetched.
 * @returns {JSForceResult}
 */
export const getActions = (userId: string, orgId: string, permissions: { SCORE?: boolean } = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const findProps: FindProps = {
    CreatedById: userId,
    Account__c: orgId
  }
  if (userId === INTERNAL_USER) {
    delete findProps.CreatedById
  }
  let query = conn.sobject('Action__c').find(findProps)
  if (permissions.SCORE) {
    query = query.select(
      'Id, From_Template__c, Status__c, From_Template__r.Category__c'
    )
  }
  return query
}

interface CreateAction {
  Title__c?: string,
  Category__c?: string,
  ExpectedOutcome__c?: string,
  Account__c?: string,
  Date__c?: moment.Moment,
  From_Template__c?: string,
  Order__c?: number,
  Description__c?: string,
  Status__c?: string,
  Reason__c?: string
}

export interface TemplateParsed {
  name: string,
  category?: string,
  outcome?: string,
  timeline?: number,
  templateId: string,
  description?: string,
  reason?: string
}

/**
 * Creates Action records from all of the avaliable templates.
 * @function
 * @category Salesforce - Action__c
 * @param {TemplateParsed[]} templates Action Templates array.
 * @param {Date} completionTime Date for which actions should be done by default.
 * @param {string} orgId Id of an organization actions should be created for.
 * @returns {JSForceResult}
 */
export const createAllAvaliableActions = (templates: TemplateParsed[], completionTime: Date, orgId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Action__c')
    .find({
      Status__c: 'Selected'
    })
    .select('Order__c')
    .then((result: object[]) => {
      const toCreate: CreateAction[] = []
      let order = result.length
      templates.forEach(temp => {
        toCreate.push({
          Title__c: temp.name,
          Category__c: temp.category,
          ExpectedOutcome__c: temp.outcome,
          Account__c: orgId,
          Date__c: moment.utc(completionTime).add(temp.timeline, 'days'),
          From_Template__c: temp.templateId,
          Order__c: order,
          Description__c: temp.description,
          Status__c: 'Selected',
          Reason__c: temp.reason
        })
        order++
      })
      return conn.sobject('Action__c').create(toCreate)
    })
}

interface ActionProps {
  title?: string,
  orgId?: string,
  category?: string,
  expectedOutcome?: string,
  dueDate?: moment.Moment,
  fromTemplate?: string,
  description?: string,
  reason?: string,
  column?: string,
  order?: number
}

/**
 * Creates Action record
 * @function
 * @category Salesforce - Action__c
 * @param {ActionProps} props Props for create query.
 * @returns {JSForceResult}
 */
export const createAction = (props: ActionProps) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (!props.order) {
    return conn
      .sobject('Action__c')
      .find({
        Status__c: props.column
      })
      .select('Order__c')
      .then((result: object[]) => {
        return conn.sobject('Action__c').create({
          Title__c: props.title,
          Account__c: props.orgId,
          Category__c: props.category,
          ExpectedOutcome__c: props.expectedOutcome,
          Date__c: props.dueDate,
          From_Template__c: props.fromTemplate,
          Order__c: result.length,
          Description__c: props.description,
          Status__c: props.column,
          Reason__c: props.reason
        })
      })
  }

  return conn.sobject('Action__c').create({
    Title__c: props.title,
    Account__c: props.orgId,
    Date__c: props.dueDate,
    Category__c: props.category,
    ExpectedOutcome__c: props.expectedOutcome,
    From_Template__c: props.fromTemplate,
    Order__c: props.order,
    Description__c: props.description,
    Status__c: props.column,
    Reason__c: props.reason
  })
}

interface UpdateActionProps {
  id: string,
  title?: string,
  dueDate?: Date,
  order?: number,
  success?: boolean,
  expectedOutcome?: string,
  actualOutcome?: string,
  failReason?: string,
  description?: string,
  column?: string,
  reason?: string,
  category?: string
}

/**
 * Updates Action record
 * @function
 * @category Salesforce - Action__c
 * @param {UpdateActionProps} props Props for update query.
 * @returns {JSForceResult}
 */
export const updateAction = (props: UpdateActionProps) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate: any = {
    Id: props.id,
    Title__c: props.title,
    Date__c: props.dueDate,
    Order__c: props.order,
    Success__c: props.success,
    ExpectedOutcome__c: props.expectedOutcome,
    ActualOutcome__c: props.actualOutcome,
    ReasonOfFail__c: props.failReason,
    Description__c: props.description,
    Status__c: props.column,
    Reason__c: props.reason
  }
  if (props.category) {
    toUpdate.Category__c = props.category
  }
  return conn.sobject('Action__c').update(toUpdate)
}

interface UpdateData {
  id: string,
  title?: string,
  expectedOutcome?: string,
  failReason?: string,
  dueDate?: Date,
  description?: string,
  reason?: string
}

/**
 * Updates limited fields of an Action record
 * @function
 * @category Salesforce - Action__c
 * @param {UpdateData} updateData Props for update query.
 * @returns {JSForceResult}
 */
export const updateActionFromCalendar = (updateData: UpdateData) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').update({
    Id: updateData.id,
    Title__c: updateData.title,
    ExpectedOutcome__c: updateData.expectedOutcome,
    ReasonOfFail__c: updateData.failReason,
    Date__c: updateData.dueDate,
    Description__c: updateData.description,
    Reason__c: updateData.reason
  })
}

interface UpdateActionsOrderProps {
  toUpdate: Array<{
    Id: string,
    Order__c: number,
  }>
}

/**
 * Updates the order of Actions
 * @function
 * @category Salesforce - Action__c
 * @param {UpdateActionsOrderProps} props An array of objects (toUpdate) containing Id and Order__c properties
 * @returns {JSForceResult}
 */
export const updateActionsOrder = (props: UpdateActionsOrderProps) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').update(props)
}

/**
 * Deletes an Action record
 * @function
 * @category Salesforce - Action__c
 * @param {string} id Id of action to delete.
 * @returns {JSForceResult}
 */
export const deleteAction = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').delete(id)
}
