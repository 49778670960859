import { t, Trans } from '@lingui/macro'
import { authRoles } from 'app/auth/authRoles'
import { EgretLoadable } from 'egret'
import { myI18n } from 'translation/I18nConnectedProvider'

const dmahRoutes = [
  {
    path: '/dmah/applications',
    component: EgretLoadable({
      loader: () => import('./DMAHApplications')
    }),
    auth: authRoles.DMAH,
    isTranslatable: true,
    label: myI18n._(t`DMAH_TAB`)
  },
  {
    path: '/dmah/application/:id',
    component: EgretLoadable({
      loader: () => import('./DMAHView')
    }),
    auth: authRoles.DMAH,
    label: <Trans>DMAH_ROUTES_DMAH_APPLICATION_ROUTE_LABEL</Trans>,
    isConditionable: true,
    id: 'dmah-application'
  }
]

export default dmahRoutes
