import { parseFormLabelText } from './common/Common'
import {
  collectionConditions,
  formConditions,
  routeOptions,
  sfFieldToConditionType,
  specialFormConditions
} from './editor/ConditionalElementEditor'
import { collectionElementTypes } from './editor/FormWizard'

const isDisabled = ({ item, pdfView = false, ...props }) => {
  if (!item.conditions || item.conditions.length === 0) {
    return false
  }
  if (
    pdfView &&
    item.conditions.some(
      condition =>
        condition.conditionTarget &&
        props.elementsMap[condition.conditionTarget]
    )
  ) {
    return false
  }
  return item.conditions.some(condition => {
    const { conditionMet, state } = isConditionMet({
      condition,
      ...props
    })
    return (
      (conditionMet && state === 'hide') || (!conditionMet && state === 'show')
    )
  })
}

export const checkAltLabel = ({
  item,
  elementsMap,
  values,
  connectedMap,
  errors,
  langVersion,
  conditionType = 'altLabel'
}) => {
  let label
  item.conditions.some(condition => {
    const { conditionMet, state, altLabel } = isConditionMet({
      condition,
      elementsMap,
      values,
      langVersion,
      connectedMap,
      errors
    })
    if (conditionMet && state === conditionType) {
      label = altLabel
      return true
    } else {
      return false
    }
  })
  return label
}

export const getValidCurrentStepIndex = ({ realIndex, disabledIds = [] }) => {
  let base = realIndex
  for (let index = realIndex; index >= 0; index--) {
    if (disabledIds.includes(sectionConditionId + index)) {
      base--
    }
  }
  return base
}

export const sectionConditionId = 'section##'
export const getDisabledIds = ({ sections, errors, ...props }) => {
  if (!props.values || !errors) {
    return []
  }

  const getIdsToDisable = errors => {
    const toRet = []
    sections.forEach((section, sectionIndex) => {
      const checkConditionsInItem = item => {
        if (isDisabled({ item, ...props, errors })) {
          if (item.elements) {
            disableAllChildElements(item)
          } else {
            toRet.push(item.id)
          }
        } else if (item.elements) {
          item.elements.forEach(item => {
            checkConditionsInItem(item)
          })
        }
      }

      const disableAllChildElements = item => {
        toRet.push(item.id)
        if (item.elements) {
          item.elements.forEach(item => {
            disableAllChildElements(item)
          })
        }
      }

      if (isDisabled({ item: section, ...props, errors })) {
        toRet.push(sectionConditionId + sectionIndex)
        section.elements.forEach(item => {
          disableAllChildElements(item)
        })
      }

      section.elements.forEach(item => {
        checkConditionsInItem(item)
      })
    })
    return toRet
  }

  const preDisabledIds = getIdsToDisable(errors)
  const validErrors = { ...errors }
  preDisabledIds.forEach(id => {
    delete validErrors[id]
  })
  return getIdsToDisable(validErrors)
}

export const isConditionMet = ({
  condition,
  pathname,
  elementsMap,
  values,
  langVersion,
  describeMap = {},
  connectedMap = {},
  errors = {}
}) => {
  let bool = false
  let testRule, testValue, ruleType
  if (condition.conditions) {
    const logic = condition.logic || 'all'
    if (logic === 'any') {
      bool = condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langVersion,
          describeMap,
          connectedMap,
          pathname,
        })
        return conditionMet
      })
    } else {
      bool = !condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langVersion,
          describeMap,
          connectedMap,
          pathname,
        })
        return !conditionMet
      })
    }
  } else {
    const toRet = {
      conditionMet: false,
      state: condition.state,
      altLabel: parseFormLabelText({
        text: condition?.altLabel,
        langVersion
      })
    }
    if (
      condition.conditionTarget &&
      specialFormConditions[condition.conditionTarget]
    ) {
      if (condition.conditionTarget === 'noErrors') {
        toRet.conditionMet = Object.keys(errors).length === 0
      } else if (condition.conditionTarget === 'errorsPresent') {
        toRet.conditionMet = Object.keys(errors).length > 0
      } else if (condition.conditionTarget === 'inRoute' || condition.conditionTarget === 'notInRoute') {
        const route = routeOptions?.find(route => route.id === condition.parameter)
        // function to match the route with the path
        function matchPath(route, path) {
          // Split the route and path into parts
          const routeParts = (route || '').split('/').filter(part => part !== '');
          const pathParts = (path || '').split('/').filter(part => part !== '');
          // Loop through each part of the route
          for (let i = 0; i < routeParts.length; i++) {
            const routePart = routeParts[i];
            const pathPart = pathParts[i];
            if (routePart.startsWith(':')) {
              if (routePart.endsWith('?')) {
                continue;
              }
              if(!pathPart) {
                return false;
              }
            } else if (routePart !== pathPart) {
              return false;
            }
          }
          return true
        }        

        toRet.conditionMet = (condition.conditionTarget === 'inRoute') ? matchPath(route?.path, pathname) 
        : (condition.conditionTarget === 'notInRoute') ? !matchPath(route?.path, pathname)
        : false
      } 
      return toRet
    } else if (!condition.condition) {
      return toRet
    }
    if (
      !['isTrue', 'isFalse', 'isEmpty', 'isNotEmpty'].includes(
        condition.condition
      ) &&
      !condition.parameter
    ) {
      return toRet
    }

    if (condition.collectionKey) {
      /** It means that condition is connected to a collection */
      /** Get object we connected to */
      const connectedObject = Object.values(connectedMap).find(
        obj => obj.objectType === condition.connectedObject
      )
      /** Get the collection */
      const collection =
        connectedObject?.additionalInfo?.[condition.collectionKey]
      /** Get the values of the selected child object of the collection */
      const collectionValues = collection?.map(
        item => item[condition.childObject]
      )
      ruleType = condition.type
      if (ruleType) {
        testRule =
          formConditions?.[ruleType]?.conditions[condition.condition].rule
      }
      testValue = collectionValues
    } else if (condition.sfField) {
      /** It means that condition is connected to SF object */
      const objData = connectedMap[condition.sfObject]
      if (objData) {
        //Handle nested conditions target
        if (String(condition.sfField).includes('.')) {
          const objField = condition.sfField.split('.')[0]
          const nestedField = condition.sfField.split('.')[1]
          if (!objData.sfObject[objField]) {
            return toRet
          }
          testValue = objData.sfObject[objField][nestedField]
          const nonRelationshipFieldName = objField.replace('__r', '__c')
          const nonRelationshipNestedFieldName = nestedField.replace(
            '__r',
            '__c'
          )

          const nestedObjectInfo =
            objData.fieldsMap[nonRelationshipFieldName] ||
            objData.fieldsMap[nonRelationshipNestedFieldName]
          const nestedObjectType = nestedObjectInfo?.referenceTo[0]
          const nestedFieldInfo = describeMap[nestedObjectType]?.fields.find(
            field => field.name === nestedField
          )
          ruleType =
            sfFieldToConditionType[
              nestedFieldInfo?.type || nestedObjectInfo?.type
            ]
          if (!ruleType) {
            return toRet
          }
          testRule =
            formConditions[ruleType].conditions[condition.condition].rule
        } else {
          testValue = objData.sfObject[condition.sfField]
          ruleType =
            sfFieldToConditionType[objData.fieldsMap[condition.sfField]?.type]
          if (condition.sfField === 'RecordTypeId') {
            ruleType = 'picklist'
          }
        }
        if (!ruleType) {
          return toRet
        }
        testRule = formConditions[ruleType].conditions[condition.condition].rule
      }
    } else {
      /** It means that field is connected to form element */
      const targetElement = elementsMap[condition.conditionTarget]
      /** Check if the element exists */
      if (!targetElement) {
        console.warn(
          'Question targeted by this condition does not exist!',
          condition
        )
        return toRet
        /** Check if the element condition type is a standard one */
      } else if (
        !Object.keys(formConditions).includes(targetElement.elementType)
      ) {
        console.warn(
          'Question targeted by this condition has invalid type!',
          condition
        )
        return toRet
      }

      /** Determine the type of the element which is used to determine the test rule.
       * If the condition has a childObject, it means that the condition is related to a collection and specifically to a childObject of the collection.
       * So test rule is determined by the type of the childObject */
      const elementType = condition.childObject
        ? collectionElementTypes[targetElement.elementType].fields[
            condition.childObject
          ].type
        : targetElement.elementType

      /** Get the value of the element to which the condition is connected.
       * If the condition has a childObject and a collectionCondition, it means that the value is an array
       * and the test value is an array of the values of the childObject
       */
      testValue =
        condition.childObject && condition.collectionCondition
          ? values[condition.conditionTarget]?.map(
              item => item[condition.childObject]
            )
          : values[condition.conditionTarget]

      testRule =
        formConditions[elementType].conditions[condition.condition]?.rule
    }

    if (testRule) {
      /** If the condition has a collectionKey, it means that value is an array
       * and the test rule is applied in accordance with the collectionCondition rule */
      if (condition.collectionKey) {
        bool =
          collectionConditions[condition.collectionCondition]?.rule({
            testValue,
            testRule,
            conditionParameter: condition.parameter
          }) || false
        /** all other cases (default) */
      } else {
        bool = testRule(condition.parameter, testValue)
      }
    }
  }

  return {
    conditionMet: bool,
    state: condition.state,
    altLabel: parseFormLabelText({
      text: condition?.altLabel,
      langVersion
    })
  }
}
