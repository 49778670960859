import { Trans } from '@lingui/macro'
import { useField, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormErrorLabel } from '../../common/labels/FormErrorLabel'
import { MUEditedByLabel } from '../../forms/multiuser/components/MUEditedByLabel'
import {
  startEditingField,
  updateLockedFieldValue
} from '../../forms/multiuser/grpcMultiuserEdit'
import '../CKEditor/ckeditor5.css'
import { CKEditor } from './CKEditor'

export const FormikCKEditor = ({
  disabled,
  name,
  label,
  config,
  onBlur,
  wordLimit,
  useMultiuser,
  muBag,
  required,
  wordCount,
  maxCharacters,
  allowAddingTables,
  ...props
}) => {
  const { values } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const { value = '' } = field
  const [initialValueSet, setInitialValueSet] = useState(false)
  const [timer, setTimer] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [stats, setStats] = useState({ words: 0, characters: 0 })
  const ref = useRef()

  const user = useSelector(state => state.user)
  const langVersion = user.language

  const { setValue } = helpers
  const { muInfo = {}, muUsers = {} } = values
  const muState = muInfo[name]
  let userColor, userName
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  const editedByOther = Boolean(
    muState && muState.locked && muState.user !== user.userId
  )
  let helperArray = []
  if (wordCount) {
    helperArray.push(
      <span key='wordsCount'>
        {stats.words} <Trans>words</Trans>
        {'. '}
      </span>
    )
  }
  if (Number(maxCharacters)) {
    helperArray.push(
      <span key='limit'>
        <span>
          {stats.characters + ' '}
          <Trans>chars</Trans>
          {' ('}
          <Trans>max</Trans>: {maxCharacters} <Trans>chars</Trans>
          {')'}
        </span>
        {'. '}
      </span>
    )
  }
  if (required) {
    helperArray.push(
      <span key='required'>
        <Trans>Required</Trans>
        {'. '}
      </span>
    )
  }

  // useEffect(() => {
  //   if (editedByOther) {
  //     setIsEditedByOther(true)
  //   }
  // }, [editedByOther])

  useEffect(() => {
    setInitialValueSet(false)
  }, [name])

  useEffect(() => {
    if (value && !initialValueSet) {
      // This handles initial value
      setInputValue(value)
      setInitialValueSet(true)
    }
  }, [value, initialValueSet])

  const inputChanged = () => {
    const newValue = ref.current.editor.getData()
    const wordCount = ref.current.editor.plugins.get('WordCount').words
    const charCount = ref.current.editor.plugins.get('WordCount').characters
    let error
    if (maxCharacters && maxCharacters < charCount) {
      error = true
    }
    if (wordLimit && wordCount > wordLimit) {
      error = true
    }
    setInputValue(newValue)
    clearTimeout(timer)
    if (error) {
      if (newValue !== inputValue) {
        ref.current.editor.setData(inputValue)
      }
    } else {
      const newTimer = setTimeout(() => {
        setValue(newValue)
        if (useMultiuser) {
          updateLockedFieldValue({
            ...muBag,
            fieldId: name,
            fieldValue: newValue,
            lockId: values.muInfo.lockId
          })
        }
      }, 700)
      setTimer(newTimer)
    }
  }

  return (
    <div>
      <CKEditor
        editorRef={ref}
        allowAddingTables={allowAddingTables}
        langVersion={langVersion}
        additionalConfig={{
          wordCount: {
            onUpdate: stats => {
              setStats(stats)
            }
          }
        }}
        data={inputValue}
        disabled={disabled || editedByOther}
        onChangeWithFormik={inputChanged}
        onBlur={(e, editor) => {
          if (onBlur) {
            onBlur(e)
          }
          if (!meta.touched) {
            helpers.setTouched(true)
          }
        }}
        onFocus={(e, editor) => {
          if (useMultiuser) {
            startEditingField({
              ...muBag,
              fieldId: name
            })
          }
        }}
      />
      {useMultiuser && (
        <MUEditedByLabel color={userColor} userName={userName} />
      )}
      <FormErrorLabel
        label={helperArray}
        id={name}
        error={meta.error && meta.touched}
      />
    </div>
  )
}
