import { Trans } from '@lingui/macro'
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { getPicklistRenderProps } from './FormPicklist'

export const FormPicklistReadOnly = ({
  title,
  id,
  typeProps,
  value,
  disabled,
  langVersion,
  connectedFieldDetails,
  connectedObject,
  ...props
}) => {
  const { values } = useFormikContext()
  const { printIcon = 'none', picklistType, options = [], dynamic } = typeProps
  const { valueToTrans, valueToIndex, valueToSFDetails } =
    getPicklistRenderProps({ langVersion, connectedFieldDetails, typeProps })

  if (picklistType === 'multiselect' || Array.isArray(value)) {
    let data = value || []
    if (connectedFieldDetails) {
      data = data.sort((a, b) => {
        const aDetails = valueToSFDetails[a]
        const bDetails = valueToSFDetails[b]
        const aIndex = aDetails?.index || 0
        const bIndex = bDetails?.index || 0
        return aIndex - bIndex
      })
    }
    return (
      <div>
        <List>
          {Boolean(data.length === 0) && (
            <ListItem style={{ display: 'flex' }}>
              <Trans>This list is empty!</Trans>
            </ListItem>
          )}
          {data.map((item, index) => {
            const translatedOption = dynamic
              ? valueToSFDetails[item]?.label
              : valueToTrans[item]
            const connectedField = valueToSFDetails[item]?.connectedField
            return (
              <ListItem
                key={index}
                style={{
                  display: 'flex',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 8
                }}
              >
                {printIcon !== 'none' && (
                  <ListItemIcon style={{ minWidth: 35 }}>
                    <Icon>{printIcon}</Icon>
                  </ListItemIcon>
                )}
                <Typography>
                  {translatedOption || <Trans id={item} />}
                  {/* {values.other[id] && values.other[id][orgIndex] && (
                    <span>{' (' + values.other[id][orgIndex] + ')'}</span>
                  )} */}
                  {connectedField && (
                    <>
                      {': '}
                      <i>{connectedObject[connectedField.name]}</i>
                    </>
                  )}
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  } else {
    return (
      <div>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {dynamic
            ? valueToSFDetails[value]?.label
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <span>{' (' + values.other[id] + ')'}</span>}
        </Typography>
      </div>
    )
  }
}
