import { SET_ORGANIZATION } from '../actions/OrganizationActions'

const initialState = {}

const OrganizationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION: {
      return {
        ...state,
        ...action.data
      }
    }
    default: {
      return state
    }
  }
}

export default OrganizationReducer
