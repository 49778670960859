import { t } from '@lingui/macro'
import { Image, Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export const FormUploadFilesPdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  condtionsText,
  ...props
}) => {
  const { minFiles, required } = typeProps
  let requiredFilesNumber = minFiles || required ? 1 : 0

  const toRender = [...Array(requiredFilesNumber).keys()].fill(
    { empty: 'true' },
    0,
    requiredFilesNumber
  )

  value.forEach((obj, index) => {
    toRender[index] = obj
  })

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily:
            langVersion === 'iu-cans'
              ? inuktitutFormPdfFontFamily
              : defaultFormPdfFontFamily,
          fontSize: pdfDefaultFontSize,
          marginBottom: 4
        }}
      >
        <Text
          style={{
            fontFamily:
              langVersion === 'iu-cans'
                ? inuktitutFormPdfFontFamily
                : defaultFormPdfFontFamily,
            fontSize: pdfDefaultFontSize,
            marginLeft: 8
          }}
        >
          {tooltip ? `[${tooltip}] ` : ''}
        </Text>

        {toRender.map((obj, index) => {
          const isFilled = !obj.empty
          return (
            <View
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              {isFilled && (
                <Image
                  src='/assets/images/pdf/selected-checkbox.jpg'
                  style={{ width: 15, height: 15 }}
                />
              )}
              {!isFilled && (
                <Image
                  src='/assets/images/pdf/empty-checkbox.jpg'
                  style={{ width: 15, height: 15 }}
                />
              )}
              <Text
                style={{
                  fontFamily:
                    langVersion === 'iu-cans'
                      ? inuktitutFormPdfFontFamily
                      : defaultFormPdfFontFamily,
                  fontSize: pdfDefaultFontSize,
                  marginLeft: 5
                }}
              >
                {isFilled
                  ? obj.name
                  : obj.type
                  ? `${myI18n._(t`NO_FILE_UPLOADED`)} [${obj.type}]`
                  : myI18n._(t`NO_FILE_UPLOADED`)}
              </Text>
            </View>
          )
        })}
      </View>
      <View />
    </>
  )
}
