import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { readOnlyFieldMTF } from './jsConnectionUtils'

const mapFields = {
  direction: 'in',
  Id: 'id',
  ...readOnlyFieldMTF('User__c', 'user'),
  ...readOnlyFieldMTF('Opportunity__c', 'opportunity'),
  ...readOnlyFieldMTF('CreatedDate', 'createdData'),
  ...readOnlyFieldMTF('ModifiedDate', 'modifiedDate'),
  ...readOnlyFieldMTF('Due_date__c', 'dueDate'),
  Status__c: 'status',
  Survey__c: 'survey',
  Snapshot_About_Me__c: 'snapshotAboutMe',
  Snapshot_Review__c: 'snapshotReview',
  Reviewer_Comments__c: 'reviewerComments',
  Date_accepted_or_declined__c: 'acceptedDate',
  Conflict_Of_Interest_Declaration__c: 'conflictOfInterestDeclaration'
}

const reverseMapFields = reverseMap(mapFields)

/**
 * Retrieves review assignments for the specified user.
 * @function
 * @category Salesforce - Review_Assignement__c
 * @param {string} userId The id of the user for whom to retrieve review assignments.
 * @returns {object[]} An array of review assignments.
 */
export const getReviewAssignements = (userId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Review_Assignement__c').find({ User__c: userId })
}

/**
 * Retrieves a review assignment by its id.
 * @function
 * @category Salesforce - Review_Assignement__c
 * @param {string} id The id of the review assignment to retrieve.
 * @returns {object} The review assignment object.
 */
export const getReviewAssignement = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Review_Assignement__c').retrieve(id)
}

/**
 * Retrieves a review assignment by its and parses it.
 * @param {string} id The id of the review assignment to retrieve and parse.
 * @returns {object}
 */
export const getReviewAssignementParsed = (id: string) => {
  // console.log('getSurveyParsed', id)
  return getReviewAssignement(id).then(user => mapSFToForm(mapFields, user))
}

/**
 * Saves a review assignment using a flow.
 * @function
 * @category Salesforce - Review_Assignement__c
 * @param {object} values The values of the review assignment to save.
 * @returns {FlowResult}
 */
export const saveReviewAssignementByFlow = (values: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Update_Review_Assignment', {
    inputs: [
      {
        obj: values
      }
    ]
  })
}

/**
 * Saves a parsed review assignment using a flow.
 * @function
 * @category Salesforce - Review_Assignement__c
 * @param {object} values The parsed values of the review assignment to save.
 * @returns {FlowResult}
 */
export const saveReviewAssignementParsedByFlow = values => {
  // console.log('getSurveyParsed', id)(mapSFToForm(reverseMapFields, value))
  return saveReviewAssignementByFlow(mapSFToForm(reverseMapFields, values))
}

/**
 * Upserts an external reviewer record with the provided values.
 * @function
 * @category Salesforce - External_Reviewer__c
 * @param {object} values The values of the external reviewer record to upsert.
 * @returns {JSForceResult}
 */
export const upsertExternalReviewer = (values: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('External_Reviewer__c')
    .upsert(values, 'Contact_External_Id__c')
}

/**
 * Retrieves external reviews based on the provided search parameters.
 * @function
 * @category Salesforce - FGM_Base__Review__c
 * @param {object} searchParams - The search parameters to filter the external reviews.
 * @returns {object[]} An array of external review records matching the search parameters.
 */
export const getExternalReviews = (searchParams: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Review__c')
    .find(searchParams)
    .select(['*', 'RecordType.Name'])
}

/**
 * Retrieves an external reviewer based on the provided contact id.
 * @function
 * @category Salesforce - External_Reviewer__c
 * @param {string} contactId The id of the contact associated with the external reviewer.
 * @returns {object} The external reviewer record corresponding to the provided contact ID.
 */
export const getExternalReviewer = (contactId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('External_Reviewer__c').find({ Contact__c: contactId })
}
