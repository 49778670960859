import { Trans } from '@lingui/macro'
import { TextField } from '@material-ui/core'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { useDispatch } from 'react-redux'
import { FormAffiliatedOrganizations } from './FormAffiliatedOrganizations'
import { FormAffiliatedOrganizationsReadOnly } from './FormAffiliatedOrganizationsReadOnly'

export const FormEditorAffiliatedOrganizations = ({
  editMode,
  depth,
  showPrintProps,
  showEditableProps,
  showPrintComponent,
  typeProps = {},
  disabled,
  injectable,
  injectableId,
  ...props
}) => {
  const dispatch = useDispatch()
  const { tag } = typeProps
  if (!editMode) {
    if (showPrintComponent) {
      return (
        <FormAffiliatedOrganizationsReadOnly
          editorPreview
          typeProps={typeProps}
          {...props}
        />
      )
    }
    return (
      <FormAffiliatedOrganizations editMode typeProps={typeProps} {...props} />
    )
  }
  return (
    <div>
      {!showPrintProps && (
        <TextField
          label={<Trans>Tag</Trans>}
          fullWidth
          value={tag || ''}
          variant='outlined'
          disabled={disabled}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.tag = e.target.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        />
      )}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}
