import { t } from '@lingui/macro'
import { authRoles } from 'app/auth/authRoles'
import { EgretLoadable } from 'egret'
import { myI18n } from 'translation/I18nConnectedProvider'

const Map = EgretLoadable({
  loader: () => import('./Map')
})
const Rents = EgretLoadable({
  loader: () => import('./Rents')
})
const Rent = EgretLoadable({
  loader: () => import('./Rent')
})

const vivreEnVilleRoutes = [
  {
    path: '/vivre-en-ville/map',
    component: Map,
    auth: authRoles.vivre_en_ville,
    isTranslatable: true,
    label: myI18n._(t`VIVRE_EN_VILLE_MAP`)
  },
  {
    path: '/vivre-en-ville/rents',
    component: Rents,
    auth: authRoles.vivre_en_ville,
    isTranslatable: true,
    label: myI18n._(t`VIVRE_EN_VILLE_RENTS_TAB`)
  },
  {
    path: '/vivre-en-ville/rent/:id',
    component: Rent,
    auth: authRoles.vivre_en_ville,
    isTranslatable: true,
    label: myI18n._(t`Rent Overview`)
  }
]

export default vivreEnVilleRoutes
