import { t } from '@lingui/macro'
import { Text } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'

export function FormNumericSliderPDF ({ value, title, typeProps, ...props }) {
  const { max = 100, min = 0 } = typeProps
  let valueString =
    value +
    ' (' +
    myI18n?._(t`min`) +
    '. ' +
    String(min) +
    ', ' +
    myI18n?._(t`max`) +
    '. ' +
    String(max) +
    ')'

  return (
    <Text style={{ fontSize: pdfDefaultFontSize }}>{value && valueString}</Text>
  )
}
