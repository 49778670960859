import sfAuthConfig, { INTERNAL } from '../services/sfAuth/sfAuthConfig'

export const REVIEWER = 'REVIEWER'
export const TECHNICAL_ADVISORY = 'TECHNICAL_ADVISORY'
export const GRANTEE = 'GRANTEE'
export const CONSULTANT = 'CONSULTANT'
export const SELECTION_COMMITTEE = 'SELECTION_COMMITTEE'
export const ALLOCATION_COMMITTEE = 'ALLOCATION_COMMITTEE'
export const BOARD = 'BOARD'
export const EDITOR = 'EDITOR'
export const CMHC = 'CMHC'
export const ADMIN = 'ADMIN'
export const PM = 'PM'
export const SA = 'SA'
export const TESTER = 'TESTER'
export const WORKGROUP_MEMBER = 'WORKGROUP_MEMBER'
export const VIVREENVILLE = 'VIVREENVILLE'
export const DEBUG = 'DEBUG'
export const TRANSLATOR = 'TRANSLATOR'
export const DMAH = 'DMAH'

export const authMapping = {
  Grantee: GRANTEE,
  Reviewer: REVIEWER,
  Consultant: CONSULTANT,
  'Selection Committee': SELECTION_COMMITTEE,
  'Allocation Committee': ALLOCATION_COMMITTEE,
  Board: BOARD,
  'Template Editor': EDITOR,
  CMHC: CMHC,
  'Program Manager': PM,
  Admin: ADMIN,
  'Super Admin': SA,
  Tester: TESTER,
  'Work Group Member': WORKGROUP_MEMBER,
  Debug: DEBUG,
  'Technical Advisory': TECHNICAL_ADVISORY,
  Vivreenville: VIVREENVILLE,
  Translator: TRANSLATOR,
  DMAH: DMAH
}

export const authRoles = {
  sa: [SA],
  admin: [SA, ADMIN],
  pm: [SA, ADMIN, PM],
  editor: [SA, ADMIN, EDITOR],
  grantee: [SA, ADMIN, GRANTEE, TESTER],
  tester: [SA, ADMIN, TESTER],
  reviewer: [SA, ADMIN, REVIEWER],
  technicalAdvisory: [SA, ADMIN, TECHNICAL_ADVISORY],
  selectionCommittee: [SA, ADMIN, SELECTION_COMMITTEE],
  allocationCommittee: [SA, ADMIN, ALLOCATION_COMMITTEE],
  cmhc: [SA, ADMIN, CMHC],
  board: [SA, ADMIN, BOARD],
  workgroupMember: [WORKGROUP_MEMBER, ADMIN, SA, PM],
  vivre_en_ville: [TESTER, VIVREENVILLE],
  translator: [SA, ADMIN, PM, TRANSLATOR],
  DMAH: [DMAH, SA, ADMIN],
  user: [
    SA,
    ADMIN,
    ALLOCATION_COMMITTEE,
    EDITOR,
    GRANTEE,
    REVIEWER,
    BOARD,
    CMHC,
    TECHNICAL_ADVISORY,
    TESTER,
    SELECTION_COMMITTEE,
    CONSULTANT,
    TRANSLATOR
  ]
}

export const mapRoles = roles => {
  if (roles) {
    return roles.map(item => authMapping[item])
  } else {
    return []
  }
}

export const checkAuth = (
  auth,
  role,
  { noAuth, env, functionPermissions, path } = {},
  dataObjects = {},
  simulateInternal = false
) => {
  //console.log('checkAuth', auth, role, noAuth, functionPermissions, dataObjects, sfAuthConfig.currentEnv)
  // console.log('checkAuth', path, functionPermissions, dataObjects, sfAuthConfig.currentEnv)
  const shouldHave = hasRole(auth, role)
  const shouldNotHave = hasRole(noAuth, role)
  const hasAdmin = hasRole([ADMIN, SA], role)
  const envMatches = hasRole(
    env, 
    simulateInternal ? INTERNAL : sfAuthConfig.currentEnv
  )
  // console.log('checkAuth results',  shouldHave, shouldNotHave, hasAdmin, envMatches, !auth, !noAuth, env)
  let functionMatches = true
  if (functionPermissions && Array.isArray(functionPermissions)) {
    for (const func of functionPermissions) {
      // console.log('checkAuth function', func, functionMatches)
      try {
        functionMatches = functionMatches && func(dataObjects)
      } catch (e) {
        console.error('checkAuth Error running auth functions')
        functionMatches = false
      }
    }
  }

  return (
    shouldHave &&
    envMatches &&
    functionMatches &&
    (!shouldNotHave || hasAdmin || !auth || !noAuth)
  )
}

export const isOnlyRole = (roles, targetRole) => {
  return hasRole(roles, targetRole) && roles.length === 1
}

export const hasRole = (auth, role) => {
  if (auth && Array.isArray(auth)) {
    if (Array.isArray(role)) {
      for (let i = 0; i < role.length; i++) {
        if (auth.indexOf(role[i]) !== -1) {
          return true
        }
      }
      return false
    } else {
      return auth.indexOf(role) !== -1
    }
  } else {
    return true
  }
}
