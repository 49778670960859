export const FormObjectivesPdfEditor = ({ title }) => {
  return (
    <div className='pdf-mode'>
      <div className={`pdfProps form-print-subtitle`}>{title}</div>
      <div className='table'>
        <div className='cell'>
          <div className='subtitle'>Objective</div>
          <div className='value'>Objective Placeholder</div>
        </div>
        <div className='cell'>
          <div className='subtitle'>Outcomes</div>
          <div className='value'>Outcomes Placeholder</div>
        </div>
      </div>
    </div>
  )
}