import SFAuthService, { NO_USER } from '../SFAuthService'

/**
 * Deletes an objective from the Salesforce database.
 * @function
 * @category Salesforce - Objective__c
 * @param {string} id - The id of the objective to delete.
 * @returns {JSForceResult}
 */
export const deleteObjective = (id: string) => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Objective__c').delete(id)
}
