import { Text } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export function FormTextPdf ({
  langVersion,
  injectableId,
  connectedMap,
  objectsFieldsMap,
  describeMap,
  title,
  typeProps = {},
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    fontSize,
    bold,
    underline,
    italics
  } = typeProps

  const styles = {
    fontSize: fontSize ? Number(fontSize) : pdfDefaultFontSize,
    fontFamily:
      langVersion === 'iu-cans'
        ? inuktitutFormPdfFontFamily
        : defaultFormPdfFontFamily,
    fontWeight: bold ? 'bold' : 400,
    fontStyle: italics ? 'italic' : 'normal',
    textDecoration: underline && 'underline',
    backgroundColor: backgroundColor && backgroundColor.id,
    color: textColor && textColor.id,
    padding: 0,
    margin: 0
  }
  if (alignCenter) {
    styles.textAlign = 'center'
  } else if (justify) {
    styles.textAlign = 'justify'
  }

  return <Text style={styles}>{title}</Text>
}
