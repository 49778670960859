import AppContext from 'app/appContext'
import Loading from 'egret/components/EgretLoadable/Loading'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { checkAuth } from './authRoles'
class AuthGuard extends Component {
  constructor (props, context) {
    super(props)
    const { routes } = context

    this.state = {
      authenticated: true,
      loading: true,
      routes
    }
  }

  // componentDidMount () {
  //   if (!this.state.authenticated) {
  //     this.redirectRoute(this.props)
  //   }
  // }

  // componentDidUpdate () {
  //   if (!this.state.authenticated) {
  //     this.redirectRoute(this.props)
  //   }
  // }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState !== this.state
  }

  static getDerivedStateFromProps (props, state) {
    const { location, user, organization } = props
    const { pathname } = location
    const matched = state.routes.find(r => r.path === pathname)
    const authenticated =
      matched && matched.auth && matched.auth.length
        ? checkAuth(matched.auth, user.role, matched, { user, organization })
        : true

    return {
      authenticated,
      loading: !user.userFilled
    }
  }

  redirectRoute (props) {
    const { location, history } = props
    const { pathname } = location

    history.push({
      pathname: '/session/404',
      state: { redirectUrl: pathname }
    })
  }

  render () {
    const { children, user } = this.props
    const { authenticated, loading } = this.state

    if (loading) {
      return <Loading />
    }

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { logged: authenticated })
      }
      return child
    })

    // return <Fragment>{children}</Fragment>;
    return authenticated ? <>{childrenWithProps}</> : <Loading />
  }
}

AuthGuard.contextType = AppContext

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export default withRouter(connect(mapStateToProps)(AuthGuard))
