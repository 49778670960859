import { CommonPlaceholder } from '../CommonPlaceholder'

export function FormNumericSliderPDFPreview ({
  typeProps,
  mainConnected,
  title,
  ...props
}) {
  return (
    <CommonPlaceholder
      title={title}
      mainConnected={mainConnected}
      showPdfComponent={false}
      typeProps={typeProps}
      {...props}
    />
  )
}
