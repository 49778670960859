export const SET_CONFIGURATION_DATA = 'SET_CONFIGURATION_DATA'

export function setConfigurationData (configuration) {
  console.log('set Configuration Data', configuration)
  return dispatch => {
    dispatch({
      type: SET_CONFIGURATION_DATA,
      data: configuration
    })
  }
}
