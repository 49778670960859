import { grpc } from '@improbable-eng/grpc-web'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import moment from 'moment'
import { host } from './grpcMultiuserEdit'
import {
  ChatMessageCollectionRequest,
  ChatMessageSend
} from './proto/generated/Multiuser_pb'
import { Multiuser } from './proto/generated/Multiuser_pb_service'

export const muFetchChatMessages = ({ sessionStartTime, token }) => {
  const request = new ChatMessageCollectionRequest()
  if (sessionStartTime) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(moment.utc(sessionStartTime).unix())
    request.setFromtime(timestamp)
  }

  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.GetChatMessages, {
      request,
      host,
      metadata: new grpc.Metadata({
        MultiuserSessionToken: token
      }),
      onEnd: response => {
        if (response.status !== grpc.Code.OK) {
          reject(response.message?.toObject())
        } else {
          const { array } = response.message
          resolve(
            array[0].map(array => {
              return {
                userId: array[0],
                recieved: array[2] && moment.unix(array[2][0]),
                text: array[1],
                username: array[3]
              }
            })
          )
        }
      }
    })
  })
}

export const muSendChatMessage = ({ message, onFail, onSuccess, token }) => {
  const request = new ChatMessageSend()
  request.setMessagecontent(message)
  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.SendChatMessage, {
      request,
      host,
      metadata: new grpc.Metadata({
        MultiuserSessionToken: token
      }),
      onEnd: response => {
        if (response.status !== grpc.Code.OK) {
          reject(response.message?.toObject())
        } else {
          resolve(response.message?.toObject())
        }
      }
    })
  })
}

export const muListenForChatMessageSent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserSentChatMessage, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      if (onEventRecieved) {
        onEventRecieved({
          user: response.array[0],
          message: response.array[1],
          recieved: response.array[2] && moment.unix(response.array[2][0]),
          username: response.array[3]
        })
      }
    }
  })
}
