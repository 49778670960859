/**
 * @fileoverview gRPC-Web generated client stub for Mo_multiuser
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var Multiuser_pb = require('./Multiuser_pb.js')
const proto = {};
proto.Mo_multiuser = require('./MultiuserSF_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserSFClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserSFPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.RequestSFSaveReceive>}
 */
const methodDescriptor_MultiuserSF_UserRequestedSFSave = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/UserRequestedSFSave',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.RequestSFSaveReceive,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.userRequestedSFSave =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserRequestedSFSave',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserRequestedSFSave);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.userRequestedSFSave =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserRequestedSFSave',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserRequestedSFSave);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.SaveToSFCompleted>}
 */
const methodDescriptor_MultiuserSF_UserCompletedSaveToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/UserCompletedSaveToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.SaveToSFCompleted,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.SaveToSFCompleted.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SaveToSFCompleted>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.userCompletedSaveToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserCompletedSaveToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserCompletedSaveToSF);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SaveToSFCompleted>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.userCompletedSaveToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserCompletedSaveToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserCompletedSaveToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_MultiuserSF_ServerRequestSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/ServerRequestSubmitToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  Multiuser_pb.UserID,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  Multiuser_pb.UserID.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.serverRequestSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ServerRequestSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ServerRequestSubmitToSF);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.serverRequestSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ServerRequestSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ServerRequestSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserOperation>}
 */
const methodDescriptor_MultiuserSF_UserEndedToSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/UserEndedToSubmitToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserOperation,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserOperation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.userEndedToSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserEndedToSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserEndedToSubmitToSF);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.userEndedToSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/UserEndedToSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_UserEndedToSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.DocumentOperation,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_MultiuserSF_ReportSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/ReportSubmitToSF',
  grpc.web.MethodType.UNARY,
  Multiuser_pb.DocumentOperation,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.DocumentOperation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.DocumentOperation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.reportSubmitToSF =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ReportSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ReportSubmitToSF,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.DocumentOperation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.reportSubmitToSF =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ReportSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ReportSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.SaveToSFCompletedReport,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_MultiuserSF_ReportSaveToSFCompleted = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/ReportSaveToSFCompleted',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.SaveToSFCompletedReport,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.reportSaveToSFCompleted =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ReportSaveToSFCompleted',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ReportSaveToSFCompleted,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.reportSaveToSFCompleted =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/ReportSaveToSFCompleted',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_ReportSaveToSFCompleted);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.RequestSFSaveMessage,
 *   !proto.Mo_multiuser.RequestSFSaveResponde>}
 */
const methodDescriptor_MultiuserSF_RequestSFSave = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.MultiuserSF/RequestSFSave',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.RequestSFSaveMessage,
  proto.Mo_multiuser.RequestSFSaveResponde,
  /**
   * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.RequestSFSaveResponde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveResponde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserSFClient.prototype.requestSFSave =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/RequestSFSave',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_RequestSFSave,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.RequestSFSaveResponde>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserSFPromiseClient.prototype.requestSFSave =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.MultiuserSF/RequestSFSave',
      request,
      metadata || {},
      methodDescriptor_MultiuserSF_RequestSFSave);
};


module.exports = proto.Mo_multiuser;

