import { t, Trans } from '@lingui/macro'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { addYupRule } from '../../FormHelpersValidation'
import { questionRequiredTrans } from '../../formTranslations'

export const handleRulesFormNumericInputValidation = ({
  typeProps,
  id,
  schema,
  requiredFromConditions,
  nonRequiredFromConditions
}) => {
  let {
    required,
    max,
    min,
    isPhone,
    modifyMinSubstract,
    modifyMinAdd,
    modifyMaxSubstract,
    modifyMaxAdd
  } = typeProps

  if (isPhone) {
    let rule = Yup.string().test(
      'isValidPhone',
      () => <Trans>Phone Number must have at least 10 digits</Trans>,
      value => {
        if (value) {
          const filtered = value.replace(/[^0-9]/g, '')
          return filtered.length >= 10
        } else {
          return true
        }
      }
    )
    if (
      (required && !nonRequiredFromConditions.includes(id)) ||
      requiredFromConditions.includes(id)
    ) {
      rule = rule.required(questionRequiredTrans)
    }
    addYupRule(schema, id, rule)
  } else {
    if (min) {
      if (modifyMinSubstract || modifyMinAdd) {
        const refs = []
        if (modifyMinSubstract) {
          refs.push(modifyMinSubstract)
        }
        if (modifyMinAdd) {
          refs.push(modifyMinAdd)
        }
        addYupRule(
          schema,
          id,
          Yup.number()
            .nullable()
            .when(refs, (ref, schema) => {
              let addValue = 0
              let substractValue = 0
              if (modifyMinSubstract && modifyMinAdd) {
                substractValue = ref[0] || 0
                addValue = ref[1] || 0
              } else if (modifyMinSubstract) {
                substractValue = ref || 0
              } else if (modifyMinAdd) {
                addValue = ref || 0
              }
              return schema.min(min - substractValue + addValue, ({ min }) =>
                myI18n?._(t`Provided value cannot be lesser than ${min}!`)
              )
            })
        )
      } else {
        addYupRule(
          schema,
          id,
          Yup.number()
            .nullable()
            .min(min, ({ min }) =>
              myI18n?._(t`Provided value cannot be lesser than ${min}!`)
            )
        )
      }
    }
    if (max) {
      if (modifyMaxSubstract || modifyMaxAdd) {
        const refs = []
        if (modifyMaxSubstract) {
          refs.push(modifyMaxSubstract)
        }
        if (modifyMaxAdd) {
          refs.push(modifyMaxAdd)
        }

        addYupRule(
          schema,
          id,
          Yup.number()
            .nullable()
            .when(refs, (arr1, arr2, arr3) => {
              let addValue = 0
              let substractValue = 0
              let schema
              if (modifyMaxSubstract && modifyMaxAdd) {
                substractValue = arr1 || 0
                addValue = arr2 || 0
                schema = arr3
              } else if (modifyMaxSubstract) {
                substractValue = arr1 || 0
                schema = arr2
              } else if (modifyMaxAdd) {
                addValue = arr1 || 0
                schema = arr2
              }
              return schema.max(max - substractValue + addValue, ({ max }) =>
                myI18n?._(t`Provided value cannot be greater than ${max}!`)
              )
            })
        )
      } else {
        addYupRule(
          schema,
          id,
          Yup.number()
            .nullable()
            .max(max, ({ max }) =>
              myI18n?._(t`Provided value cannot be greater than ${max}!`)
            )
        )
      }
    }
    if (
      (required && !nonRequiredFromConditions.includes(id)) ||
      requiredFromConditions.includes(id)
    ) {
      addYupRule(
        schema,
        id,
        Yup.number().nullable().required(questionRequiredTrans)
      )
    }
  }
}
