import { Trans } from '@lingui/macro'

const indexToStage = {
  0: <Trans>Planning / initiation stage</Trans>,
  1: <Trans>Execution stage</Trans>,
  2: <Trans>Closure stage</Trans>
}
const keyToLabel = {
  comments: <Trans>Comments</Trans>,
  primaryActivities: <Trans>Primary Activities</Trans>
}

export const FormMilestonesError = error => {
  if (Array.isArray(error)) {
    return {
      toMap: error.map((item, index) => {
        if (!item) {
          console.log('errors milestones undefined error ')
          return null
        }
        return (
          <span key={index}>
            <u>
              {index + 1}
              {'. '}
              {indexToStage[index]}
            </u>
            <ul key={index}>
              {Object.entries(item).map(([key, value]) => {
                return (
                  <li
                    key={key}
                    className='m-0 text-muted'
                    style={{ maxWidth: '771px' }}
                  >
                    {keyToLabel[key]}
                    {': '}
                    {value}
                  </li>
                )
              })}
            </ul>
          </span>
        )
      })
    }
  } else {
    return error
  }
}
