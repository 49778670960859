import { Trans } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'
import { getPicklistRenderProps } from './FormPicklist'

export const FormPicklistPdf = ({
  title,
  id,
  typeProps,
  value,
  values = {},
  disabled,
  langVersion,
  connectedFieldDetails,
  connectedObject,
  ...props
}) => {
  const { picklistType, dynamic } = typeProps
  const { valueToTrans, valueToSFDetails } = getPicklistRenderProps({
    langVersion,
    connectedFieldDetails,
    typeProps
  })

  if (picklistType === 'multiselect' || Array.isArray(value)) {
    let data = value || []
    if (connectedFieldDetails) {
      data = data.sort((a, b) => {
        const aDetails = valueToSFDetails[a]
        const bDetails = valueToSFDetails[b]
        const aIndex = aDetails?.index || 0
        const bIndex = bDetails?.index || 0
        return aIndex - bIndex
      })
    }

    return (
      <>
        {Boolean(data.length === 0) && (
          <Text style={{ fontSize: pdfDefaultFontSize }}>
            {myI18n._(`This list is empty!`)}
          </Text>
        )}
        {data.map((item, index) => {
          const translatedOption = dynamic
            ? valueToSFDetails[item]?.label
            : valueToTrans[item]
          const connectedField = valueToSFDetails[item]?.connectedField

          return (
            <View
              key={index}
              style={{
                display: 'flex'
              }}
            >
              <Text style={{ fontSize: pdfDefaultFontSize }}>
                {translatedOption || <Trans id={item} />}
                {connectedField && (
                  <>
                    <Text>: </Text>
                    <Text style={{ fontStyle: 'italic' }}>
                      {connectedObject[connectedField.name]}
                    </Text>
                  </>
                )}
              </Text>
            </View>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        <Text style={{ whiteSpace: 'pre-line', fontSize: pdfDefaultFontSize }}>
          {dynamic
            ? valueToSFDetails[value]?.label
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <Text>{' (' + values.other[id] + ')'}</Text>}
        </Text>

        {/* <Text style={{ fontWeight: 'bold', fontSize: pdfDefaultFontSize }}>
          {title}
        </Text>
        <Text style={{ whiteSpace: 'pre-line', fontSize: pdfDefaultFontSize }}>
          {dynamic
            ? valueToSFDetails[value]?.label
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <Text>{' (' + values.other[id] + ')'}</Text>}
        </Text> */}
      </>
    )
  }
}
