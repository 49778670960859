import { parseFormLabelText } from "../../common/Common";
import {
  FormElementTypeProps,
  SalesforceFieldDescribe,
} from "../formComponentsDefinitions";

export const getFormPicklistOptions = ({
  typeProps,
  langVersion,
  i18n,
  connectedFieldDetails,
}: {
  typeProps: FormElementTypeProps;
  langVersion: boolean;
  i18n: any;
  connectedFieldDetails: SalesforceFieldDescribe;
}) => {
  const { options = [], dynamic } = typeProps;
  return dynamic &&
    connectedFieldDetails &&
    connectedFieldDetails.picklistValues
    ? connectedFieldDetails.picklistValues
        .filter((item) => item.active)
        .map((item, index) => {
          return {
            title: item.label,
            apiValue: item.value,
          };
        })
    : options.map((item) => {
        return {
          ...item,
          tooltip: parseFormLabelText({
            text: item.tooltip,
            i18n,
            langVersion,
          }),
          title: parseFormLabelText({
            text: item.title,
            i18n,
            langVersion,
          }),
        };
      });
};
