import { Trans } from "@lingui/macro";
import { TextField, TextFieldProps } from "@material-ui/core";
import { Field } from "formik";
import React from "react";

/**
 * Convinience wrapper for Material UI Text Field connected to Formik
 * @category Common components
 * @subcategory Formik
 * @component
 * @returns {JSX.Element}
 * @property {string} name This is an id of Formiks field that the TextField is connected to
 */
export const TextFieldWithFormik = (
  props: TextFieldProps & TextFieldWithFormikProps
) => {
  const { name, helperText, label, required, isTopLabel } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const displayError = meta.touched && meta.error;
        let helperArray: React.ReactNode[] = [];
        if (required) {
          helperArray = [<Trans>Required</Trans>, ". "];
        }
        if (helperText) {
          helperArray.push(helperText);
        }
        return (
          <div>
            {isTopLabel && (
              <div style={{ paddingBottom: "2px", fontSize: "12px" }}>
                {label}
              </div>
            )}
            <TextField
              {...field}
              {...props}
              error={meta.error && meta.touched}
              helperText={displayError ? meta.error : helperArray}
              value={field.value || ""}
              label={!isTopLabel ? label : ""}
            />
          </div>
        );
      }}
    </Field>
  );
};

interface TextFieldWithFormikProps {
  /**
   * This is an id of Formiks field that the TextField is connected to
   */
  name: string;
  isTopLabel?: boolean; // If true, label will be on top of the input, otherwise it will be inside the input
}
