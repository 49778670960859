import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import { parsePDFLabelToSupportWorkBreaking } from 'app/views/forms/pdf-components/formPdfUtils'

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    padding: 1
  },
  headerRow: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 'bold'
  },
  firstCol: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    hyphens: 'auto'
  }
})

export const FormMilestonesFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const phases = typeProps.phases || []
  const phasesLabels = phases.map(phase => phase.apiValue)
  const rowHeight = 100 / (phases.length + 1)

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, { height: rowHeight + '%' }]}>
        <View style={[styles.tableCol, styles.headerRow, { width: '18%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`FORM_MILESTONES_PHASE_NAME`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '11%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Start Date`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '11%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`End Date`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '40%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Primary Activities`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '20%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Comments`)}
          </Text>
        </View>
      </View>

      {phasesLabels.map((item, index) => (
        <View
          style={[styles.tableRow, { height: rowHeight + '%' }]}
          wrap={false}
          key={index}
        >
          <View style={[styles.tableCol, styles.firstCol, { width: '18%' }]}>
            {(item !== '' && item !== undefined ) && 
              <Text style={styles.tableCell}>
                {parsePDFLabelToSupportWorkBreaking(myI18n._(item))}
              </Text>}
          </View>
          <View style={[styles.tableCol, { width: '11%' }]}></View>
          <View style={[styles.tableCol, { width: '11%' }]}></View>
          <View style={[styles.tableCol, { width: '40%' }]}></View>
          <View style={[styles.tableCol, { width: '20%' }]}></View>
        </View>
      ))}
    </View>
  )
}
