import { Trans } from '@lingui/macro'

export const baseFilters = [
  {
    label: <Trans>LANGUAGE</Trans>,
    key: 'Language_Of_Resource',
    multiselect: true,
    check: (value = [], filterArray) => {
      return value.some(id => filterArray.includes(id))
    },
    options: [
      {
        label: <Trans>FRENCH</Trans>,
        key: 'FR'
      },
      {
        label: <Trans>ENGLISH</Trans>,
        key: 'EN'
      },
      {
        label: <Trans>INUKTITUT</Trans>,
        key: 'ᐃᓄᒃᑎᑐᑦ - Inuktitut'
      }
    ]
  },
  {
    label: <Trans>RESOURCES_LOCATION</Trans>,
    key: 'Location',
    multiselect: true,
    options: [
      {
        label: <Trans>CANADA</Trans>,
        key: 'Canada'
      },
      {
        label: <Trans>ALBERTA</Trans>,
        key: 'Alberta'
      },
      {
        label: <Trans>BRITISH_COLUMBIA</Trans>,
        key: 'British Columbia'
      },
      {
        label: <Trans>MANITOBA</Trans>,
        key: 'Manitoba'
      },
      {
        label: <Trans>NEW_BRUNSWIK</Trans>,
        key: 'New Brunswick'
      },
      {
        label: <Trans>NEWFOUNDLAND_LABRADOR</Trans>,
        key: 'Newfoundland and Labrador'
      },
      {
        label: <Trans>NORTWEST_TERRITORIES</Trans>,
        key: 'Northwest Territories'
      },
      {
        label: <Trans>NOVA_SCOTIA</Trans>,
        key: 'Nova Scotia'
      },
      {
        label: <Trans>NUNAVUT</Trans>,
        key: 'Nunavut'
      },
      {
        label: <Trans>ONTARIO</Trans>,
        key: 'Ontario'
      },
      {
        label: <Trans>PRINCE_EDWARD_ISLAND</Trans>,
        key: 'Prince Edward Island'
      },
      {
        label: <Trans>QUEBEC</Trans>,
        key: 'Québec'
      },
      {
        label: <Trans>SASKATCHEWAN</Trans>,
        key: 'Saskatchewan'
      },
      {
        label: <Trans>YUKON</Trans>,
        key: 'Yukon'
      }
    ]
  },
  {
    label: <Trans>RESOURCES_TOPIC</Trans>,
    key: 'Topic',
    multiselect: true,
    options: [
      {
        label: <Trans>RESOURCES_PROPERTY_TOPIC</Trans>,
        key: 'Property'
      },
      {
        label: <Trans>RESOURCES_COMMUNITY_TOPIC</Trans>,
        key: 'Community'
      },
      {
        label: <Trans>RESOURCES_DEVELOPMENT_TOPIC</Trans>,
        key: 'Development'
      },
      {
        label: <Trans>RESOURCES_FINANCES_TOPIC</Trans>,
        key: 'Finances'
      },
      {
        label: <Trans>RESOURCES_GOVERNANCE_TOPIC</Trans>,
        key: 'Governance'
      },
      {
        label: <Trans>RESOURCES_MEMBERS_TENANTS_TOPIC</Trans>,
        key: 'Members & tenants'
      }
    ]
  },
  {
    label: <Trans>RESOURCES_PRIORITY</Trans>,
    key: 'Centre_Priority',
    multiselect: true,
    options: [
      {
        label: <Trans>RESOURCES_ENVIROMENT_PRIORITY</Trans>,
        key: 'Environment'
      },
      {
        label: <Trans>RESOURCES_GROWTH_PRIORITY</Trans>,
        key: 'Growth'
      },
      {
        label: <Trans>RESOURCES_INDIGENOUS_PRIORITY</Trans>,
        key: 'Indigenous'
      },
      {
        label: <Trans>RESOURCES_INNOVATION_PRIORITY</Trans>,
        key: 'Innovation'
      },
      {
        label: <Trans>RESOURCES_ICLUSION_PRIORITY</Trans>,
        key: 'Inclusion'
      }
    ]
  },
  {
    label: <Trans>RESOURCES_ACCESS</Trans>,
    key: 'Access',
    multiselect: true,
    options: [
      {
        label: <Trans>RESOURCES_FREE_ACCESS</Trans>,
        key: 'Free'
      },
      {
        label: <Trans>RESOURCES_MEMBERSHIP_ACCESS</Trans>,
        key: 'Membership'
      },
      {
        label: <Trans>RESOURCES_PAY_PER_USE_ACCESS</Trans>,
        key: 'Pay-per-use'
      }
    ]
  },
  {
    label: <Trans>RESOURCES_TYPE</Trans>,
    key: 'Type',
    multiselect: true,
    options: [
      {
        label: <Trans>RESOURCES_DOCUMENTS_TOOLS_TYPE</Trans>,
        key: 'Documents & Tools'
      },
      {
        label: <Trans>RESOURCES_EVENTS_TRAINING_TYPE</Trans>,
        key: 'Events & Training'
      },
      {
        label: <Trans>RESOURCES_FUNDING_SERVICES_TYPE</Trans>,
        key: 'Funding & Services'
      }
    ]
  },
  {
    key: 'Search_Query',
    container: true,
    keys: ['Resource_Name', 'Description'],
    searchQuery: true,
    label: <Trans>RESOURCES_SEARCH_QUERY</Trans>,
    check: (value, filterValue) => {
      return String(value)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase())
    }
  }
]

/** Function to find the label of a filter option given the filter key and option key in the baseFilters array.
 * if the filter or option is not found, it returns optionKey
 * @param {string} filterKey - the key of the filter to find the label for
 * @param {string} optionKey - the key of the option to find the label for
 * @returns {string} - the label of the option
 */
export const findLabel = ({ filterKey, optionKey }) => {
  const filter = baseFilters.find(filter => filter.key === filterKey)
  if (!filter) return optionKey
  const option = filter.options.find(option => option.key === optionKey)
  if (!option) return optionKey
  return option.label
}
