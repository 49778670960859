import SFAuthService from '../../services/sfAuth/SFAuthService'

export const UPDATED_CATALOG = 'UPDATED_CATALOG'


export function updateCatalog (lang, module) {
  console.log('update Catalog', lang)
  return dispatch => {
    dispatch({
      type: UPDATED_CATALOG,
      data: module,
      lang
    })
  }
}
