
import { FormObjectivesUpdate } from './FormObjectivesUpdate'
import { formComponentTypes } from '../../formComponentTypes'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'

export const FormEditorObjectivesUpdate = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  ...props
}) => {
  const _typeProps = typeProps || {}
  if (!editMode) {
    return (
      <FormObjectivesUpdate editMode id={id} typeProps={_typeProps} {...props} />
    )
  }

  return (
    <div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={_typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.objectivesUpdate.connectsToMultipleObjects
        }
      />
    </div>
  )
}