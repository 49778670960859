import * as crypto from 'crypto'
import {
  LOGOUT_MU_USER,
  LOG_MU_USER,
  RECONNECTED,
  RESET_MU_REDUCER,
  SET_EDITING_USERS
} from '../actions/MultiuserActions'

const initialState = {
  editingUsers: {}
}

const multiuserReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_EDITING_USERS: {
      return {
        ...state,
        editingUsers: action.editingUsers
      }
    }
    case LOGOUT_MU_USER: {
      const newUsers = { ...state.editingUsers }
      delete newUsers[action.id]
      return {
        ...state,
        editingUsers: newUsers
      }
    }
    case LOG_MU_USER: {
      const newUsers = { ...state.editingUsers }
      newUsers[action.newUser.id] = action.newUser
      return {
        ...state,
        editingUsers: newUsers
      }
    }
    case RECONNECTED: {
      return {
        ...state,
        reconnectEventId: crypto.randomBytes(10).toString('hex')
      }
    }
    case RESET_MU_REDUCER: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default multiuserReducer
