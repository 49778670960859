import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { useDispatch } from 'react-redux'
import { FormContactPicker } from './FormContactPicker'

export const FormEditorContactPicker = ({
  editMode,
  showPrintProps,
  showPrintComponent,
  depth,
  typeProps,
  injectable,
  injectableId,
  disabled,
  ...props
}) => {
  const { required } = typeProps
  const dispatch = useDispatch()
  //const { values } = useFormikContext()
  // const {  connectedObject } = getMainConnected({
  //   typeProps,
  //   ...props
  // })

  // let connectedToAccount
  // if (
  //   connectedObject &&
  //   values.objectsConnected.some(
  //     obj => obj.identId === connectedObject && obj.type === 'Account'
  //   )
  // ) {
  //   connectedToAccount = true
  // }

  // useEffect(() => {
  //   if (!connectedToAccount && affiliationRole) {
  //     const toSet = { ...typeProps }
  //     delete toSet.affiliationRole
  //     dispatch({
  //       type: 'FIELD',
  //       depth: depth.split('.'),
  //       fieldName: 'typeProps',
  //       fieldValue: toSet
  //     })
  //   }
  // }, [connectedToAccount])

  if (!editMode) {
    return (
      <FormContactPicker
        editMode
        typeProps={typeProps}
        showPrintProps={showPrintProps}
        {...props}
      />
    )
  }

  return (
    <div>
      {!showPrintProps && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(required)}
                disabled={disabled}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.required = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Required</Trans>}
          />
        </div>
      )}
      {/* <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(workEmailInContactCreation)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.workEmailInContactCreation = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Use work email in contact creation</Trans>}
          />
        </div> */}
      {/* <TextField
          variant='outlined'
          label={<Trans>Default organization</Trans>}
          fullWidth
          select
          value={defaultOrganization || ''}
          style={{ marginTop: 5 }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.defaultOrganization = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <MenuItem value='current' key='current'>
            <Trans>Current Organization</Trans>
          </MenuItem>
          <MenuItem value='household' key='household'>
            <Trans>Household</Trans>
          </MenuItem>
        </TextField> */}
      {/* {connectedToAccount && (
          <Grid container>
            <Grid item xs style={{ paddingRight: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Account affiliation role</Trans>}
                fullWidth
                value={affiliationRole || ''}
                style={{ marginTop: 10 }}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.affiliationRole = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <TooltipLabelIcon
              tooltip={
                <Trans>
                  If account affiliation role is not empty, the affiliation object
                  will be created and updated when contact is picked
                </Trans>
              }
            />
          </Grid>
        )} */}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        filter={item =>
          item.type === 'reference' && item.referenceTo.includes('Contact')
        }
      />
    </div>
  )
}
