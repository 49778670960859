import { Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FormColorAutocomplete } from 'app/views/forms/common/FormColorAutocomplete'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { languages } from 'translation/I18nConnectedProvider'
import { NumberFormatDefault } from '../../common/Common'
import DebouncedTextField from '../../common/DebouncedTextField'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { formObjectsToConnect } from '../../editor/FormWizard'
import { FormTextCollection } from './FormTextCollection'

export function FormEditorTextCollection ({
  editMode,
  injectable,
  depth,
  typeProps = {},
  showPrintProps,
  showPdfProps,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    fontSize,
    content = [],
    alignCenter,
    justify
  } = typeProps
  const dispatch = useDispatch()
  const { injectableComponents } = useSelector(state => state.formEditorTree)
  const { values } = useFormikContext()
  const { objectsConnected, objects } = values

  if (!editMode) {
    return <FormTextCollection {...props} editor typeProps={typeProps} />
  }

  const emptyText = languages.reduce((acc, lang) => {
    acc[lang] = ''
    return acc
  }, {})

  return (
    <div>
      <FormColorAutocomplete
        typeProps={typeProps}
        value={textColor}
        name='textColor'
        depth={depth}
        label={<Trans>Text color</Trans>}
        injectable={injectable}
      />

      <DebouncedTextField
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <FormColorAutocomplete
        typeProps={typeProps}
        value={backgroundColor}
        name='backgroundColor'
        depth={depth}
        label={<Trans>Background color</Trans>}
        injectable={injectable}
      />

      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(alignCenter)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.alignCenter = e.target.checked
                delete toSet.justify
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Align text to center</Trans>}
        />
      )}
      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(justify)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.justify = e.target.checked
                delete toSet.alignCenter
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Justify text</Trans>}
        />
      )}
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
      <Typography style={{ fontWeight: 'bold', fontSize: 21 }}>
        <Trans>Text content</Trans>
      </Typography>
      <Grid container style={{ padding: 10 }}>
        {[
          {
            label: <Trans>Add text</Trans>,
            init: {
              type: 'text',
              text: emptyText
            }
          },
          {
            label: <Trans>Add field reference</Trans>,
            init: {
              type: 'fieldReference'
            }
          },
          {
            label: <Trans>Add collection reference (single item)</Trans>,
            init: {
              type: 'collectionReference'
            }
          },
          {
            label: <Trans>Add collection reference (whole collection)</Trans>,
            init: {
              type: 'wholeCollectionReference'
            }
          },
          {
            label: <Trans>Add element reference</Trans>,
            init: {
              type: 'element'
            }
          },
          {
            label: <Trans>Add special reference</Trans>,
            init: {
              type: 'special'
            }
          }
        ].map((item, index) => (
          <Grid
            item
            style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}
            key={index}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                const toSet = { ...typeProps }
                const newContent = [...content]
                newContent.push(item.init)
                toSet.content = newContent
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            >
              <Grid container justify='center'>
                <Icon style={{ marginRight: 5 }}>add</Icon>
                {item.label}
              </Grid>
            </Button>
          </Grid>
        ))}
      </Grid>

      {content.map((obj, index) => {
        const {
          text,
          type,
          isHtml,
          cIndex,
          testField,
          testValue,
          wholeCollectionValue,
          injectableElement,
          specialReference,
          referenceObject,
          referenceField,
          referenceCollection,
          textProps = []
        } = obj
        let element
        if (type === 'text') {
          element = (
            <div>
              <ConfigureMultilanguageTextField
                label={<Trans>Text</Trans>}
                value={text}
                handleChange={(value, e, lang) => {
                  const toSet = { ...typeProps }
                  let v = e.target.value
                  if (
                    e.nativeEvent.inputType &&
                    e.nativeEvent.inputType.startsWith('insertFromPaste')
                  ) {
                    v = e.target.value.replace(/\r?\n|\r/g, ' ')
                  }
                  toSet.content[index].text[lang] = v
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                useDebounce
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(isHtml)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.content[index].isHtml = e.target.checked
                      toSet.content[index].textProps = []
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Is html?</Trans>}
              />
            </div>
          )
        } else if (type === 'special') {
          element = (
            <TextField
              select
              variant='outlined'
              style={{ marginBottom: 10, marginTop: 10 }}
              value={specialReference || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.content[index].specialReference = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
              fullWidth
            >
              {[
                {
                  label: <Trans>Current date</Trans>,
                  value: 'CURRENT_DATE'
                }
              ].map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )
        } else if (type === 'element') {
          element = (
            <TextField
              select
              label={<Trans>Referenced element</Trans>}
              fullWidth
              variant='outlined'
              defaultValue=''
              value={injectableElement || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.content[index].injectableElement = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              {injectableComponents
                .filter(item => item.injectableId)
                .map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.injectableId}>
                      {item.injectableId}
                    </MenuItem>
                  )
                })}
            </TextField>
          )
        } else {
          const avaliableObjectsMap = {}
          objectsConnected.forEach(obj => {
            avaliableObjectsMap[obj.identId] = obj
          })
          let options = []
          let relatedCollections = []
          let objData
          referenceObject &&
            objects.some(obj => {
              if (!avaliableObjectsMap[referenceObject]) {
                return false
              }
              const bool =
                obj.name === avaliableObjectsMap[referenceObject].type
              if (bool) {
                objData = formObjectsToConnect[obj.name]
                relatedCollections = obj.relatedCollections
                if (referenceCollection) {
                  relatedCollections.some(collection => {
                    if (collection.key === referenceCollection) {
                      options = collection.fields
                    }
                    return collection.key === referenceCollection
                  })
                } else if (type !== 'collectionReference') {
                  options = obj.fields
                }
              }
              return bool
            })
          options = options.filter(item =>
            [
              'string',
              'textarea',
              'email',
              'url',
              'phone',
              'address',
              'id',
              'int',
              'double',
              'multipicklist',
              'picklist',
              'currency',
              'date',
              'datetime',
              'boolean'
            ].includes(item.type)
          )
          const optionsMap = {}
          const optionsFromSelectFields = []
          if (objData && objData.select) {
            objData.select.forEach(sel => {
              if (!sel.includes('*')) {
                optionsFromSelectFields.push({
                  name: sel,
                  label: sel
                })
              }
            })
          }
          const allOptions = [...options, ...optionsFromSelectFields]
          allOptions.forEach(opt => {
            optionsMap[opt.name] = opt
          })
          const collectionsMap = {}
          relatedCollections.forEach(opt => {
            collectionsMap[opt.key] = opt.label
          })
          const testFieldData = optionsMap[testField]

          element = (
            <Grid container>
              <Grid item xs={6} style={{ padding: 5 }}>
                <TextField
                  select
                  label={<Trans>Connected object</Trans>}
                  fullWidth
                  variant='outlined'
                  defaultValue=''
                  value={referenceObject || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.content[index].referenceObject = e.target.value
                    delete toSet.content[index].referenceField
                    delete toSet.content[index].referenceCollection
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  {objectsConnected
                    .filter(item => item.name)
                    .map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.identId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </Grid>

              {type === 'fieldReference' && (
                <Grid item xs={6} style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={referenceField || ''}
                    onChange={(e, value) => {
                      const toSet = { ...typeProps }
                      toSet.content[index].referenceField = value
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    options={allOptions.map(item => item.name)}
                    // getOptionSelected={(option, value) => value === option.id}
                    getOptionLabel={o => optionsMap[o]?.label || ''}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Connected field</Trans>}
                      />
                    )}
                  />
                </Grid>
              )}

              {(type === 'collectionReference' ||
                type === 'wholeCollectionReference') && (
                <Grid item xs={6} style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={referenceCollection || ''}
                    onChange={(e, value) => {
                      const toSet = { ...typeProps }
                      toSet.content[index].referenceCollection = value
                      delete toSet.content[index].referenceField
                      delete toSet.content[index].testField
                      delete toSet.content[index].testValue
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    options={relatedCollections.map(item => item.key)}
                    // getOptionSelected={(option, value) => value === option.id}
                    getOptionLabel={o => collectionsMap[o] || ''}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Referenced collection</Trans>}
                      />
                    )}
                  />
                </Grid>
              )}
              {type === 'collectionReference' && (
                <>
                  <Grid item xs={6} style={{ padding: 5 }}>
                    {testFieldData &&
                    testFieldData.picklistValues.length > 0 ? (
                      <TextField
                        label={<Trans>Condition value</Trans>}
                        select
                        fullWidth
                        variant='outlined'
                        value={testValue || ''}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.content[index].testValue = e.target.value
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        {testFieldData.picklistValues
                          .filter(obj => obj.active)
                          .map((obj, index) => (
                            <MenuItem value={obj.value} key={index}>
                              {obj.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={<Trans>Condition value</Trans>}
                        fullWidth
                        variant='outlined'
                        value={testValue || ''}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.content[index].testValue = e.target.value
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      />
                    )}
                  </Grid>
                  <Grid xs={6} item style={{ padding: 5 }}>
                    <Autocomplete
                      freeSolo={false}
                      value={testField || ''}
                      onChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.content[index].testField = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      fullWidth
                      options={options.map(item => item.name)}
                      getOptionLabel={o => optionsMap[o]?.label || ''}
                      renderInput={params => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label={<Trans>Condition field</Trans>}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs style={{ padding: 5 }}>
                    <DebouncedTextField
                      label={<Trans>Index</Trans>}
                      fullWidth
                      variant='outlined'
                      value={cIndex || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.content[index].cIndex = e.target.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      inputProps={{
                        maxLength: 3
                      }}
                      InputProps={{
                        inputComponent: NumberFormatDefault
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TooltipLabelIcon
                      tooltip={
                        <Trans>
                          If more than one collection element will be found for
                          specified conditions the element at this index will be
                          selected if able
                        </Trans>
                      }
                    />
                  </Grid>
                  <Grid xs={6} item style={{ padding: 5 }}>
                    <Autocomplete
                      freeSolo={false}
                      value={referenceField || ''}
                      onChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.content[index].referenceField = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      fullWidth
                      options={options.map(item => item.name)}
                      // getOptionSelected={(option, value) => value === option.id}
                      getOptionLabel={o => optionsMap[o]?.label || ''}
                      renderInput={params => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label={<Trans>Connected field</Trans>}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {type === 'wholeCollectionReference' && (
                <>
                  <Grid item xs={12} style={{ padding: 5 }}>
                    <TextField
                      label={<Trans>Whole collection reference</Trans>}
                      select
                      fullWidth
                      variant='outlined'
                      value={wholeCollectionValue || 'none'}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.content[index].wholeCollectionValue =
                          e.target.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <MenuItem value='none' key={1}>
                        <Trans>None</Trans>
                      </MenuItem>
                      <MenuItem value='length' key={2}>
                        <Trans>Collection length</Trans>
                      </MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
          )
        }

        return (
          <div key={index} style={{ padding: 5 }}>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs>
                <b>{Number(index + 1) + '. '}</b>
                {type === 'text' && <Trans>Text</Trans>}
                {type === 'fieldReference' && <Trans>Field reference</Trans>}
                {type === 'wholeCollectionReference' && (
                  <Trans>Collection reference</Trans>
                )}
                {type === 'element' && <Trans>Element reference</Trans>}
                {type === 'special' && <Trans>Special reference</Trans>}
              </Grid>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.content[index]
                  const toReplace = toSet.content[index + 1]
                  toSet.content[index + 1] = toMove
                  toSet.content[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === content.length - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.content[index]
                  const toReplace = toSet.content[index - 1]
                  toSet.content[index - 1] = toMove
                  toSet.content[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.content.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
            {element}
            {!isHtml && type !== 'element' && (
              <FormGroup row>
                {[
                  { key: 'bold', label: <Trans>Bold</Trans> },
                  { key: 'italics', label: <Trans>Italics</Trans> },
                  { key: 'underline', label: <Trans>Underline</Trans> },
                  { key: 'translate', label: <Trans>Translate</Trans> },
                  {
                    key: 'spaceBefore',
                    label: <Trans>Insert space before</Trans>
                  },
                  {
                    key: 'lineBreakBefore',
                    label: <Trans>Insert line break before</Trans>
                  }
                ]
                  .filter(
                    item => !(type === 'text' && item.key === 'translate')
                  )
                  .map(item => (
                    <FormControlLabel
                      key={item.key}
                      control={
                        <Checkbox
                          checked={textProps.includes(item.key)}
                          value={item.key}
                          onChange={e => {
                            const v = e.target.value
                            const toSet = { ...typeProps }
                            const newTextProps = [...textProps]
                            if (newTextProps.includes(v)) {
                              newTextProps.splice(newTextProps.indexOf(v), 1)
                            } else {
                              newTextProps.push(v)
                            }
                            toSet.content[index].textProps = newTextProps
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: { ...toSet }
                            })
                          }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
              </FormGroup>
            )}
          </div>
        )
      })}
    </div>
  )
}
