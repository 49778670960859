import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { ImprovedHtml } from "app/views/common-components/ImprovedHtml";
import React, { useEffect, useState } from "react";
import reactImageSize from "react-image-size";
import { parseFormLabelText, pdfDefaultFontSize } from "../common/Common";
import {
  formComponentTypes,
  formTypeToComponentTypeKey,
} from "../components/formComponentTypes";
import FormElementGroup from "../form-page/FormElementGroup";

const baseLogoSize = 100;

export const formPDFStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  headerTableCell: {
    fontSize: 10,
    textAlign: "center",
    fontWeight: "bold",
    margin: "2px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumn: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  checkbox: {
    width: 10,
    height: 10,
    marginRight: 8,
    marginTop: 2,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 0,
  },
  radioCheck: {
    width: 10,
    height: 10,
    marginRight: 8,
    marginTop: 2,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  radioCheckSelected: {
    width: 5,
    height: 5,
    backgroundColor: "black",
    borderRadius: 5,
  },
});

const getFlexPlacmentForLogo = (
  textPlacement = "left",
  logoPlacement = "right"
) => {
  if (textPlacement === "left" && logoPlacement === "left") {
    return { flexPlacement: "flex-start", order: ["text", "logo"] };
  } else if (textPlacement === "right" && logoPlacement === "right") {
    return { flexPlacement: "flex-end", order: ["text", "logo"] };
  } else if (textPlacement === "center" && logoPlacement === "center") {
    return { flexPlacement: "center", order: ["text", "logo"] };
  } else if (textPlacement === "left" && logoPlacement === "center") {
    return { flexPlacement: "space-between", order: ["text", "logo", "empty"] };
  } else if (textPlacement === "center" && logoPlacement === "left") {
    return { flexPlacement: "space-between", order: ["logo", "text", "empty"] };
  } else if (textPlacement === "right" && logoPlacement === "center") {
    return { flexPlacement: "space-between", order: ["empty", "logo", "text"] };
  } else if (textPlacement === "center" && logoPlacement === "right") {
    return { flexPlacement: "space-between", order: ["empty", "text", "logo"] };
  } else if (textPlacement === "left" && logoPlacement === "right") {
    return { flexPlacement: "space-between", order: ["text", "logo"] };
  } else if (textPlacement === "center" && logoPlacement === "right") {
    return { flexPlacement: "space-between", order: ["logo", "text"] };
  } else if (textPlacement === "right" && logoPlacement === "left") {
    return { flexPlacement: "space-between", order: ["logo", "text"] };
  } else {
    return { flexPlacement: "flex-start", order: ["text", "logo"] };
  }
};

export const defaultFormPdfFontFamily = "Roboto";
export const inuktitutFormPdfFontFamily = "Pigiarniq";

export default function FormPdfDocument({
  title,
  sections,
  data,
  renderFunc,
  describeMap,
  connectedMap,
  objectsFieldsMap,
  formViewType,
  disabledIds,
  langVersion,
  values,
  ...props
}) {
  const { pdfProps = {} } = data;
  const fontFamily =
    langVersion === "iu-cans"
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily;

  const idToRenderIndex = {};
  let currentIndex = 1;
  sections.forEach((section) => {
    const goOverChildren = (element) => {
      const { id, elementType, elements } = element;

      if (elements && !disabledIds.includes(id)) {
        elements.forEach((child) => {
          goOverChildren(child);
        });
      } else {
        const elementData = formComponentTypes[elementType];
        if (
          !disabledIds.includes(id) &&
          !elementData.noRenderIndex &&
          elementData[formTypeToComponentTypeKey[formViewType]]
        ) {
          idToRenderIndex[id] = currentIndex;
          currentIndex++;
        }
      }
    };

    section.elements.forEach((element) => {
      goOverChildren(element);
    });
  });

  let header, footer, footerHeight, headerHeight;
  let pageMargin = 2.54;

  const {
    renderElementsWithNumbering,
    showSectionTitlesAsHeaders,
    showFormTitleInPdf,
  } = pdfProps;

  const keys = ["footer", "header"];
  const { pagePadding } = pdfProps;
  if (pagePadding) {
    pageMargin = +pagePadding;
  }
  keys.forEach((key) => {
    const { component, logoHeight } = FormPdfFixedSection({
      ...data.pdfProps[key],
      langVersion,
      type: key,
      fontFamily,
      pageMargin,
      parseText: (text) => {
        return parseFormLabelText({
          text,
          langVersion,
          describeMap,
          objectsFieldsMap,
          returnString: true,
        });
      },
    });
    if (key === "footer") {
      footer = component;
      footerHeight = logoHeight;
    } else {
      header = component;
      headerHeight = logoHeight;
    }
  });

  return (
    <Document style={{ fontFamily }} title={title}>
      {sections.map((section, sIndex) => {
        const renderSectionIndex = sIndex + 1;
        const pdfTitle = showFormTitleInPdf && sIndex === 0 && (
          <Text
            style={{
              paddingBottom: 16,
              fontWeight: "bold",
              fontSize: 18,
              color: "#17474f",
            }}
          >
            {title}
          </Text>
        );

        const basePageStyle = {
          padding: pageMargin + "cm",
          fontFamily,
        };
        if (footer) {
          if (pageMargin < 1) {
            basePageStyle.paddingBottom = "1cm";
          } else {
            basePageStyle.paddingBottom = pageMargin + 0.5 + "cm";
          }
        }
        if (header) {
          if (pageMargin < 1) {
            basePageStyle.paddingTop = "1cm";
          } else {
            basePageStyle.paddingTop = pageMargin + 0.5 + "cm";
          }
        }
        if (headerHeight) {
          basePageStyle.paddingTop = headerHeight + 45;
        }
        if (footerHeight) {
          basePageStyle.paddingBottom = footerHeight + 45;
        }

        const checkForValidRender = (item) => {
          if (item.elements) {
            return item.elements.some((child) => checkForValidRender(child));
          } else {
            return (
              formComponentTypes[item.elementType][
                formTypeToComponentTypeKey[formViewType]
              ] && !disabledIds.includes(item.id)
            );
          }
        };

        const pages = [];
        let currentElements = [];
        let currentOrientation = formComponentTypes[
          section.elements[0].elementType
        ]?.landscapeOrientation
          ? "landscape"
          : "portrait";

        section.elements.forEach((item, index) => {
          if (!checkForValidRender(item) || disabledIds.includes(item.id)) {
            return;
          }
          const newOrientation = formComponentTypes[item.elementType]
            ?.landscapeOrientation
            ? "landscape"
            : "portrait";

          if (
            newOrientation !== currentOrientation &&
            currentElements.length > 0
          ) {
            pages.push(
              <Page
                style={basePageStyle}
                key={`page-${sIndex}-${index}`}
                orientation={currentOrientation}
              >
                {pdfTitle}
                {header}
                {showSectionTitlesAsHeaders &&
                  currentOrientation === "portrait" && (
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#17474f",
                        paddingBottom: 16,
                      }}
                    >
                      {`${renderSectionIndex}. `}
                      {parseFormLabelText({
                        text: section.title,
                        langVersion,
                        objectsFieldsMap,
                        describeMap,
                        connectedMap,
                        pdf: true,
                        removeInvalidSigns: true,
                      })}
                    </Text>
                  )}
                {currentElements}
                {footer}
              </Page>
            );
            currentElements = [];
            currentOrientation = newOrientation;
          }

          currentElements.push(
            <FormElementGroup
              item={{
                ...item,
                value: values[item.id],
              }}
              values={values}
              lastInSection={section.elements.length === index + 1}
              disabledIds={disabledIds}
              idToRenderIndex={idToRenderIndex}
              pdfView={true}
              formViewType={formViewType}
              baseErrors={{}}
              objectsFieldsMap={objectsFieldsMap}
              connectedMap={connectedMap}
              describeMap={describeMap}
              langVersion={langVersion}
              key={index}
              renderElementsWithNumbering={renderElementsWithNumbering}
              {...props}
            />
          );
        });

        if (currentElements.length > 0) {
          pages.push(
            <Page
              style={basePageStyle}
              key={`final-page-${sIndex}`}
              orientation={currentOrientation}
            >
              {pdfTitle}
              {header}
              {showSectionTitlesAsHeaders &&
                currentOrientation === "portrait" && (
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#17474f",
                      paddingBottom: 16,
                    }}
                  >
                    {`${renderSectionIndex}. `}
                    {parseFormLabelText({
                      text: section.title,
                      langVersion,
                      objectsFieldsMap,
                      describeMap,
                      connectedMap,
                      pdf: true,
                      removeInvalidSigns: true,
                    })}
                  </Text>
                )}
              {currentElements}
              {footer}
            </Page>
          );
        }

        return <React.Fragment key={sIndex}>{pages}</React.Fragment>;
      })}
    </Document>
  );
}

function FormPdfFixedSection({
  text,
  placement,
  fontSize,
  isPageNumber,
  textProps = [],
  logoUrl,
  logoPlacement = "right",
  fontFamily,
  parseText,
  pageMargin,
  logoSize = baseLogoSize,
  type,
}) {
  const [calcSize, setCalcSize] = useState(null);

  useEffect(() => {
    if (logoUrl) {
      reactImageSize(logoUrl).then(({ width, height }) => {
        if (width >= height) {
          const wRel = width / logoSize;
          setCalcSize({
            width: width / wRel,
            height: height / wRel,
          });
        } else if (height > width) {
          const hRel = height / logoSize;
          setCalcSize({
            width: width / hRel,
            height: height / hRel,
          });
        }
      });
    }
  }, [logoUrl]);

  const label = isPageNumber ? "5" : parseText(text);
  const style = {
    fontFamily,
    left: pageMargin + "cm",
    width: "100%",
    position: "absolute",
  };
  if (label || logoUrl) {
    if (type === "header") {
      style.top = "1.27cm";
    } else {
      style.bottom = "1.27cm";
    }

    const textStyle = {
      fontFamily,
      fontWeight: textProps.includes("bold") ? 700 : 400,
      fontStyle: textProps.includes("italics") ? "italic" : "normal",
      textDecoration: textProps.includes("underline") && "underline",
      fontSize: +fontSize || pdfDefaultFontSize,
    };

    const text = isPageNumber ? (
      <View
        render={({ pageNumber, totalPages }) => (
          <Text style={textStyle}>{pageNumber + "/" + totalPages}</Text>
        )}
      />
    ) : textProps.includes("html") ? (
      <ImprovedHtml
        style={{
          fontSize: +fontSize || pdfDefaultFontSize,
        }}
      >
        {label}
      </ImprovedHtml>
    ) : (
      <Text style={textStyle}>{label}</Text>
    );

    if (logoUrl && logoPlacement) {
      const { flexPlacement, order } = getFlexPlacmentForLogo(
        placement,
        logoPlacement
      );

      const logo = calcSize && (
        <View style={{ ...calcSize }}>
          <Image src={logoUrl} />
        </View>
      );

      return {
        logoHeight: calcSize && calcSize.height,
        component: (
          <View
            fixed
            style={{
              ...style,
              display: "flex",
              flexDirection: "row",
              justifyContent: flexPlacement,
              alignItems: type === "footer" ? "flex-end" : "flex-start",
            }}
          >
            {order.map((id, index) => {
              if (id === "text") {
                return text;
              } else if (id === "logo") {
                return logo || <View key={index} />;
              } else {
                return <View key={index} />;
              }
            })}
          </View>
        ),
      };
    } else {
      let alignItems = "center";
      if (placement === "right") {
        alignItems = "flex-end";
      } else if (placement === "center") {
        alignItems = "center";
      } else if (placement === "left") {
        alignItems = "flex-start";
      }
      return {
        component: (
          <View fixed style={{ ...style, alignItems, textAlign: placement }}>
            {text}
          </View>
        ),
      };
    }
  } else {
    return {
      component: <View fixed style={style} />,
    };
  }
}
