// source: MultiuserSF.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')
goog.object.extend(proto, google_protobuf_duration_pb)
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
goog.object.extend(proto, google_protobuf_timestamp_pb)
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
goog.object.extend(proto, google_protobuf_empty_pb)
var Multiuser_pb = require('./Multiuser_pb.js')
goog.object.extend(proto, Multiuser_pb)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveMessage', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveResponde', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestStatus', null, global)
goog.exportSymbol('proto.Mo_multiuser.SaveToSFCompleted', null, global)
goog.exportSymbol('proto.Mo_multiuser.SaveToSFCompletedReport', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserOperation', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SaveToSFCompletedReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SaveToSFCompletedReport, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.displayName =
    'proto.Mo_multiuser.SaveToSFCompletedReport'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveResponde = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveResponde, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveResponde.displayName =
    'proto.Mo_multiuser.RequestSFSaveResponde'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveMessage, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveMessage.displayName =
    'proto.Mo_multiuser.RequestSFSaveMessage'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveReceive.displayName =
    'proto.Mo_multiuser.RequestSFSaveReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SaveToSFCompleted = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SaveToSFCompleted, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SaveToSFCompleted.displayName =
    'proto.Mo_multiuser.SaveToSFCompleted'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserOperation.displayName =
    'proto.Mo_multiuser.UserOperation'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SaveToSFCompletedReport.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        result: jspb.Message.getFieldWithDefault(msg, 1, 0),
        type: jspb.Message.getFieldWithDefault(msg, 2, ''),
        error: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SaveToSFCompletedReport()
  return proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (
            reader.readEnum()
          )
          msg.setResult(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setType(value)
          break
        case 3:
          var value = /** @type {string} */ (reader.readString())
          msg.setError(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.SaveToSFCompletedReport.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SaveToSFCompletedReport.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(1, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional RequestStatus Result = 1;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setResult = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 1, value)
}

/**
 * optional string Type = 2;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setType = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Error = 3;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setError = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveResponde.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveResponde.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveResponde} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveResponde.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        status: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde}
 */
proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveResponde()
  return proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveResponde} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde}
 */
proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (
            reader.readEnum()
          )
          msg.setStatus(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.RequestSFSaveResponde.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveResponde} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveResponde.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getStatus()
  if (f !== 0.0) {
    writer.writeEnum(1, f)
  }
}

/**
 * optional RequestStatus Status = 1;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.getStatus = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde} returns this
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.setStatus = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveMessage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveMessage.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveMessage.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, ''),
        error: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage}
 */
proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveMessage()
  return proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage}
 */
proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setError(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.RequestSFSaveMessage.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveMessage.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string Type = 1;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage} returns this
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Error = 2;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage} returns this
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.setError = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0),
        type: jspb.Message.getFieldWithDefault(msg, 3, ''),
        error: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive}
 */
proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveReceive()
  return proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive}
 */
proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (
          reader.readEnum()
        )
        msg.setResult(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setError(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.RequestSFSaveReceive.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional RequestStatus Result = 2;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string Error = 4;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setError = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SaveToSFCompleted.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SaveToSFCompleted.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SaveToSFCompleted} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SaveToSFCompleted.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0),
        type: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SaveToSFCompleted}
 */
proto.Mo_multiuser.SaveToSFCompleted.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SaveToSFCompleted()
  return proto.Mo_multiuser.SaveToSFCompleted.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SaveToSFCompleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SaveToSFCompleted}
 */
proto.Mo_multiuser.SaveToSFCompleted.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (
          reader.readEnum()
        )
        msg.setResult(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.SaveToSFCompleted.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SaveToSFCompleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SaveToSFCompleted.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional RequestStatus Result = 2;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserOperation.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserOperation.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserOperation}
 */
proto.Mo_multiuser.UserOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserOperation()
  return proto.Mo_multiuser.UserOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserOperation}
 */
proto.Mo_multiuser.UserOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.OperationResult} */ (
          reader.readEnum()
        )
        msg.setResult(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserOperation.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserOperation} returns this
 */
proto.Mo_multiuser.UserOperation.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional OperationResult Result = 2;
 * @return {!proto.Mo_multiuser.OperationResult}
 */
proto.Mo_multiuser.UserOperation.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.OperationResult} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.OperationResult} value
 * @return {!proto.Mo_multiuser.UserOperation} returns this
 */
proto.Mo_multiuser.UserOperation.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.RequestStatus = {
  BLOCKED: 0,
  ALLOWED: 5
}

goog.object.extend(exports, proto.Mo_multiuser)
