import { grpc } from '@improbable-eng/grpc-web'
import { Trans } from '@lingui/macro'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import _ from 'lodash'
import moment from 'moment'
import { isValidJSON } from 'utils'
import { getMultiuserColor } from './multiuserHelpers'
import {
  RequestSFSaveMessage,
  SaveToSFCompletedReport
} from './proto/generated/MultiuserSF_grpc_web_pb'
import { MultiuserSF } from './proto/generated/MultiuserSF_pb_service'
import { CursorEvent } from './proto/generated/Multiuser_grpc_web_pb'
import {
  DocumentCacheID,
  DocumentCacheType,
  DocumentToSubmit,
  EventType,
  Field,
  FieldAndFieldValue,
  FieldAndLockID,
  FieldAndLockIDAndContent,
  FieldAndValue,
  FieldsAndValues,
  MultiuserEvent,
  Realm,
  RealmInitRequest,
  RealmMetadata,
  UserSessionRequest
} from './proto/generated/Multiuser_pb'
import { Multiuser } from './proto/generated/Multiuser_pb_service'

export const host = sfOauthConfig.config.multiuserHost

export const formRealmId = (orgId, id) => '+' + orgId + '+' + id

export const MultiuserEventType = {
  TRY_SAVE_BLOCKED: 'TRY_SAVE_BLOCKED',
  BACKUP_RESTORE: 'BACKUP_RESTORE',
  BACKUP_RESTORE_BLOCKED: 'BACKUP_RESTORE_BLOCKED'
}

const decodeUserInfoArrayResponse = response => {
  const toRet = {}
  console.log('logged users response', response)
  response[0].forEach((array, loginOrder) => {
    if (array.length > 0) {
      const id = array[0]
      const colorIndex = array[4] || 0
      toRet[id] = {
        id,
        logOrder: loginOrder,
        ...JSON.parse(array[1]),
        color: getMultiuserColor(colorIndex)
      }
    }
  })
  return toRet
}

export const formCacheTypeToLabel = {
  0: <Trans>Manual save</Trans>,
  5: <Trans>Autosaved</Trans>,
  10: <Trans>Unsaved changes</Trans>
}

export const grpcGetFormBackups = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.GetCachedDocumentVersions, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          console.log('get caches response', response.message.array[0])
          const caches = response.message.array[0]
            .filter(obj => obj[4] !== DocumentCacheType.INITIAL_DOCUMENT)
            .map(obj => {
              return {
                id: obj[1],
                type: obj[4] || 0,
                date: moment.unix(obj[3][0]),
                users: obj[5] && obj[5].map(arr => arr[1])
              }
            })
          onSuccess(caches)
        }
      }
    }
  })
}

export const grpcGetFormCache = ({ id, onFail, onSuccess, token }) => {
  const request = new DocumentCacheID()
  request.setUniqueid(id)
  grpc.unary(Multiuser.GetDocumentCacheByID, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(JSON.parse(response.message.array[2]))
        }
      }
    }
  })
}

export const mmuTryInitiatingForm = ({
  onFail,
  accessToken,
  onSuccess,
  formId,
  userId,
  initialValues,
  userInfo,
  metadata,
  mode,
  multiuserLoginToken
}) => {
  const request = new Realm()
  request.setRealmpath(formId)
  grpc.unary(Multiuser.IsRealmInited, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserLoginToken: multiuserLoginToken
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isInited = response.message.toObject().istrue
        console.log('Is form inited?', isInited)
        if (isInited) {
          grpcStartSession({
            onFail,
            userInfo,
            formId,
            mode,
            multiuserLoginToken,
            token: accessToken,
            onSuccess: ({ colorIndex, sessionToken }) => {
              Promise.all([
                muFetchAllUsersInfo({
                  multiuserLoginToken,
                  token: sessionToken,
                  userId,
                  realmId: formId
                }),
                muGetReamlMetadata({
                  sessionToken
                }),
                muGetUnsavedChanges({
                  multiuserLoginToken,
                  token: sessionToken
                }),
                muSetReamlMetadata({
                  sessionToken,
                  multiuserLoginToken,
                  metadata
                })
              ]).then(
                ([fetchAllUsersInfo, metadata, unsavedData]) => {
                  const { users, isAnotherUser } = fetchAllUsersInfo
                  const { sessionStartTime } = metadata
                  return muGetCurrentFormState({
                    formId,
                    token: sessionToken,
                    startEditingTime: sessionStartTime
                  }).then(formState => {
                    onSuccess({
                      inited: isInited,
                      formState,
                      unsavedData,
                      sessionToken,
                      logIndex: colorIndex,
                      users,
                      sessionStartTime,
                      isAnotherUser
                    })
                  })
                },
                reject => {
                  if (onFail) {
                    onFail(reject)
                  }
                }
              )
            }
          })
        } else if (!isInited) {
          const realmToInit = new RealmInitRequest()
          realmToInit.setRealmpath(formId)
          realmToInit.setContent(JSON.stringify(initialValues))
          grpc.unary(Multiuser.InitRealm, {
            request: realmToInit,
            host,
            metadata: new grpc.Metadata({
              MultiuserLoginToken: multiuserLoginToken
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                grpcStartSession({
                  onFail,
                  userInfo,
                  formId,
                  mode,
                  multiuserLoginToken,
                  token: accessToken,
                  onSuccess: ({ colorIndex, sessionToken }) => {
                    Promise.all([
                      muFetchAllUsersInfo({
                        multiuserLoginToken,
                        token: sessionToken,
                        userId,
                        realmId: formId
                      }),
                      muGetUnsavedChanges({
                        multiuserLoginToken,
                        token: sessionToken
                      }),
                      muSetReamlMetadata({
                        sessionToken,
                        multiuserLoginToken,
                        metadata
                      })
                    ]).then(
                      ([fetchAllUsersInfo, unsavedData]) => {
                        const { users, isAnotherUser } = fetchAllUsersInfo
                        const { sessionStartTime } = metadata
                        onSuccess({
                          inited: isInited,
                          unsavedData,
                          sessionToken,
                          logIndex: colorIndex,
                          users,
                          sessionStartTime,
                          isAnotherUser
                        })
                      },
                      reject => {
                        if (onFail) {
                          onFail(reject)
                        }
                      }
                    )
                  }
                })
              }
            }
          })
        }
      }
    }
  })
}

const muGetReamlMetadata = ({ sessionToken }) => {
  const requestPromise = new Promise((resolve, reject) => {
    grpc.unary(Multiuser.GetRealmMetadata, {
      request: new Empty(),
      host,
      metadata: new grpc.Metadata({
        MultiuserSessionToken: sessionToken
      }),
      onEnd: response => {
        console.log('get realm metadata result', response, sessionToken)
        if (response.status !== grpc.Code.OK) {
          reject(response.message.toObject())
        } else {
          const toRet = {}
          response.message.toObject().metadataMap.forEach(metaArray => {
            let value = metaArray[1]
            if (isValidJSON(value)) {
              value = JSON.parse(value)
            }
            toRet[metaArray[0]] = value
          })
          if (toRet.InitiationTime) {
            toRet.sessionStartTime = moment.utc(toRet.InitiationTime.$date)
            delete toRet.InitiationTime
          }
          resolve(toRet)
        }
      }
    })
  })
  return requestPromise
}

const muSetReamlMetadata = ({
  multiuserLoginToken,
  sessionToken,
  metadata
}) => {
  const request = new RealmMetadata()
  const map = request.getMetadataMap()
  Object.keys(metadata).forEach(key => {
    map.set(key, metadata[key])
  })

  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.SetRealmMetadata, {
      request,
      host,
      metadata: new grpc.Metadata({
        MultiuserSessionToken: sessionToken,
        MultiuserLoginToken: multiuserLoginToken
      }),
      onEnd: response => {
        console.log(
          'set realm metadata result',
          response,
          request,
          sessionToken
        )
        if (response.status !== grpc.Code.OK) {
          reject(response.message.toObject())
        } else {
          resolve(response.message.toObject())
        }
      }
    })
  })
}

export const updateLockedFieldValue = ({
  onFail,
  onSuccess,
  fieldId,
  lockId,
  fieldValue,
  token
}) => {
  console.log('updating locked field value', fieldId, lockId, fieldValue, token)
  const request = new FieldAndLockIDAndContent()
  request.setFieldvalue(JSON.stringify(fieldValue))
  request.setFieldid(fieldId)
  request.setLockid(lockId)
  grpc.unary(Multiuser.UpdateLockedFieldValue, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('updated locked field value', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const commitFormCache = ({
  values,
  userIds = [],
  type,
  formId,
  onFail,
  onSuccess,
  token
}) => {
  const toSend = _.cloneDeep(values)
  delete toSend.muInfo
  delete toSend.muUsers
  const request = new DocumentToSubmit()
  request.setRealmpath(formId)
  request.setContent(JSON.stringify(toSend))
  request.setCachetype(type)
  request.setParticipatingusersidsList(userIds)
  console.log('commiting form cache', toSend, formId, userIds)
  grpc.unary(Multiuser.SubmitDocumentCache, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('commit cache response', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const sendMultiuserEvent = ({
  type,
  value = '',
  eventVariant = EventType.EVENT_INFO,
  token
}) => {
  return new Promise((resolve, reject) => {
    const request = new MultiuserEvent()
    request.setEventtype(eventVariant)
    request.setEventvalue(value)
    request.setType(type)
    grpc.unary(Multiuser.SendMultiuserEvent, {
      request,
      host,
      metadata: new grpc.Metadata({
        MultiuserSessionToken: token
      }),
      onEnd: response => {
        if (response.status !== grpc.Code.OK) {
          reject(response.message.toObject())
        } else {
          resolve(response.message.toObject())
        }
      }
    })
  })
}

export const commitChangeToMultipleFields = ({
  array,
  onSuccess,
  onFail,
  token
}) => {
  const request = new FieldsAndValues()
  request.setFieldsandvaluesarrayList(
    array.map(([id, value, lock = false]) => {
      const toRet = new FieldAndValue()
      toRet.setFieldid(id)
      toRet.setValue(JSON.stringify(value))
      toRet.setLock(lock)
      return toRet
    })
  )
  grpc.unary(Multiuser.MassiveCommitFieldsImmediately, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const endEditingField = ({
  onFail,
  onSuccess,
  fieldId,
  lockId,
  fieldValue,
  token
}) => {
  const request = new Field()
  request.setFieldid(fieldId)
  grpc.unary(Multiuser.IsFieldLocked, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('ended editing field for lock', lockId)
      console.log('field id', fieldId)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isLocked = Boolean(response.message.array[0])
        console.log('was it locked?', isLocked)
        if (isLocked) {
          const request = new FieldAndLockIDAndContent()
          request.setFieldid(fieldId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          request.setLockid(lockId)
          grpc.unary(Multiuser.CommitLockedField, {
            request,
            host,
            metadata: new grpc.Metadata({
              MultiuserSessionToken: token
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        } else {
          const request = new FieldAndFieldValue()
          request.setFieldid(fieldId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          grpc.unary(Multiuser.CommitFieldImmediately, {
            request,
            host,
            metadata: new grpc.Metadata({
              MultiuserSessionToken: token
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        }
      }
    }
  })
}

export const pingServer = ({ onReject, onSuccess, token }) => {
  grpc.unary(Multiuser.Ping, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        onReject(response)
      } else {
        onSuccess()
      }
    }
  })
}

export const unlockFieldWithoutChanges = ({
  lockId,
  fieldId,
  onFail,
  onSuccess,
  token
}) => {
  const request = new FieldAndLockID()
  request.setFieldid(fieldId)
  request.setLockid(lockId)
  if (!lockId) {
    if (onSuccess) {
      onSuccess()
    }
    return
  }
  grpc.unary(Multiuser.CancelLockedField, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const startEditingField = ({
  onFail,
  onSuccess,
  fieldId,
  userId,
  token
}) => {
  const request = new Field()
  request.setFieldid(fieldId)
  grpGetLockedFieldsForForm({
    userId,
    token,
    onFail: e => {
      if (onFail) {
        onFail()
      }
    },
    onSuccess: locks => {
      let isLocked = false,
        currentLock
      locks
        .filter(obj => obj.lockedBy === userId)
        .forEach(lockObj => {
          if (lockObj.fieldId === fieldId) {
            isLocked = true
            currentLock = lockObj.lockId
          } else {
            unlockFieldWithoutChanges({
              lockId: lockObj.lockId,
              fieldId: lockObj.fieldId,
              token,
              userId
            })
          }
        })

      if (!isLocked) {
        grpc.unary(Multiuser.LockField, {
          request,
          host,
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      } else {
        console.log('field is already locked')
        const request = new FieldAndLockID()
        request.setFieldid(fieldId)
        request.setLockid(currentLock)
        grpc.unary(Multiuser.ReclaimLockedField, {
          request,
          host,
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      }
    }
  })
}

export const muFetchAllUsersInfo = ({
  //onFail,
  userId,
  //onSuccess,
  token,
  realmId,
  multiuserLoginToken
}) => {
  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.GetAllConnectedUsersWithInfo, {
      request: new Empty(),
      metadata: new grpc.Metadata({
        MultiuserLoginToken: multiuserLoginToken,
        MultiuserSessionToken: token
      }),
      host,
      onEnd: response => {
        console.log('got response for all connected users', response)
        if (response.status !== grpc.Code.OK) {
          // if (onFail) {
          //   onFail(response)
          // }
          reject(response.message.toObject())
        } else {
          const request = new Realm()
          request.setRealmpath(realmId)
          grpc.unary(Multiuser.GetCurrentSessions, {
            request,
            host,
            metadata: new grpc.Metadata({
              UserID: userId,
              MultiuserSessionToken: token
            }),
            onEnd: sessionsResponse => {
              if (sessionsResponse.status !== grpc.Code.OK) {
                // if (onFail) {
                //   onFail(sessionsResponse)
                // }
                reject(response.message.toObject())
              } else {
                const activeSessions = sessionsResponse.message.array[1]
                const usersSessions = sessionsResponse.message.array[0]
                const users = decodeUserInfoArrayResponse(
                  response.message.array
                )
                let isAnotherUser, sessionStartTime
                Object.values(users).forEach(obj => {
                  if (obj.id !== userId) {
                    isAnotherUser = true
                  }
                })
                // activeSessions.forEach(sessionArray => {
                //   const sessionTime = moment.unix(sessionArray[1][0])
                //   if (
                //     !sessionStartTime ||
                //     moment.utc(sessionTime).isBefore(sessionStartTime)
                //   ) {
                //     sessionStartTime = moment.utc(sessionTime)
                //   }
                // })
                resolve({
                  users,
                  //sessionStartTime,
                  isAnotherUser,
                  activeSessions,
                  usersSessions
                })
                // onSuccess({
                //   users,
                //   sessionStartTime,
                //   isAnotherUser,
                //   activeSessions,
                //   usersSessions
                // })
              }
            }
          })
        }
      }
    })
  })
}

export const muGetUnsavedChanges = ({ multiuserLoginToken, token }) => {
  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.GetNewestDocumentCache, {
      request: new Empty(),
      metadata: new grpc.Metadata({
        MultiuserLoginToken: multiuserLoginToken,
        MultiuserSessionToken: token
      }),
      host,
      onEnd: response => {
        console.log('get newest cache response', response)
        if (response.status !== grpc.Code.OK) {
          reject(response.message.toObject())
          // if (onSuccess) {
          //   onSuccess(null)
          // }
        } else {
          const { array } = response.message
          let unsavedData
          if (array[2]) {
            console.log('get newest form cache response', array)
            const data = JSON.parse(array[2])
            const type = array[4] || 0
            const wasNotSaved = type === DocumentCacheType.CHANGES_NOT_SAVED
            if (wasNotSaved) {
              unsavedData = data
            }
          }
          resolve(unsavedData)
          // if (onSuccess) {
          //   onSuccess(unsavedData)
          // }
        }
      }
    })
  })
}

export const muGetCurrentFormState = ({
  //onFail,
  //onSuccess,
  startEditingTime,
  token
}) => {
  let toRet = {}

  return new Promise((resolve, reject) => {
    grpc.unary(Multiuser.GetLocksNotSubmitedToCache, {
      request: new Empty(),
      metadata: new grpc.Metadata({
        MultiuserSessionToken: token
      }),
      host,
      onEnd: response => {
        if (response.status !== grpc.Code.OK) {
          // if (onFail) {
          //   onFail()
          // }
          reject(response.message.toObject())
        } else {
          const fieldLocks = response.message.array[0]
          console.log('got current field locks', fieldLocks, token)
          grpc.unary(Multiuser.GetNewestDocumentCache, {
            request: new Empty(),
            metadata: new grpc.Metadata({
              MultiuserSessionToken: token
            }),
            host,
            onEnd: response => {
              console.log('got current form state', response, token)
              if (response.status !== grpc.Code.OK) {
                // if (onFail) {
                //   onFail(response)
                // }
                reject(response.message.toObject())
              } else {
                const { array } = response.message
                if (array[2]) {
                  const data = JSON.parse(array[2])
                  const submitTime = moment.unix(array[3][0])
                  const wasNotSaved =
                    array[4] === DocumentCacheType.CHANGES_NOT_SAVED
                  let isValid = true
                  if (wasNotSaved) {
                    isValid = false
                  }
                  if (startEditingTime && submitTime) {
                    if (
                      moment
                        .utc(submitTime)
                        .isBefore(moment.utc(startEditingTime))
                    ) {
                      isValid = false
                    }
                  }
                  if (isValid) {
                    toRet = { ...toRet, ...data }
                  }
                }
                fieldLocks.forEach(lock => {
                  const fieldId = lock[3]
                  const fieldValue = lock[4] && JSON.parse(lock[4])
                  const lockCreatedTime = moment.unix(lock[8][0])
                  let isValid = true

                  if (startEditingTime && lockCreatedTime) {
                    if (
                      moment
                        .utc(lockCreatedTime)
                        .isBefore(moment.utc(startEditingTime))
                    ) {
                      isValid = false
                    }
                  }
                  if (isValid && lock[4]) {
                    toRet[fieldId] = fieldValue
                  }
                })
                // if (onSuccess) {
                //   onSuccess(toRet)
                // }
                resolve(toRet)
              }
            }
          })
        }
      }
    })
  })
}

export const grpcReportSFSaveResult = ({
  onFail,
  onSuccess,
  result,
  type,
  token
}) => {
  const request = new SaveToSFCompletedReport()
  request.setType(type)
  request.setResult(result)
  return grpc.unary(MultiuserSF.ReportSaveToSFCompleted, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const grpGetLockedFieldsForForm = ({ onFail, onSuccess, token }) => {
  return grpc.unary(Multiuser.GetLockedFields, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const { array } = response.message
        if (onSuccess) {
          onSuccess(
            array[0].map(lock => ({
              fieldId: lock[3],
              lockId: lock[1],
              lockedBy: lock[2]
            }))
          )
        }
      }
    }
  })
}

export const grpcUpdateUserInfo = ({
  formId,
  userInfo,
  onFail,
  onSuccess,
  token
}) => {
  const request = new UserSessionRequest()
  request.setRealmpath(formId)
  request.setUsername(userInfo.name)
  request.setUserdata(JSON.stringify(userInfo))
  return grpc.unary(Multiuser.UpdateUserInfo, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

export const grpcGetFieldHistory = ({ fieldId, onSuccess, onFail, token }) => {
  const request = new Field()
  request.setFieldid(fieldId)
  return grpc.unary(Multiuser.GetLocksForField, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          const savedValues = response.message.array[0]
          onSuccess(
            savedValues.map(array => ({
              date: moment.unix(array[8][0]),
              value: array[4] && JSON.parse(array[4])
            }))
          )
        }
      }
    }
  })
}

export const grpcRequestSFSave = ({ onFail, onSuccess, type, token }) => {
  const request = new RequestSFSaveMessage()
  request.setType(type)
  return grpc.unary(MultiuserSF.RequestSFSave, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const getNewestCacheOfForm = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.GetNewestDocumentCache, {
    request: new Empty(),
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      console.log('got current form state', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const moveMouseCursor = ({
  x,
  y,
  xPercent,
  yPercent,
  onFail,
  onSuccess,
  token
}) => {
  const cursorEvent = new CursorEvent()
  cursorEvent.setEventvalue(
    JSON.stringify({
      x,
      y,
      xPercent,
      yPercent
    })
  )
  grpc.unary(Multiuser.SendCursorEvent, {
    request: cursorEvent,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const grpcEndEditingForm = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.LogOut, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcStartSession = ({
  formId,
  userId,
  userInfo,
  onFail,
  onSuccess,
  mode,
  multiuserLoginToken
}) => {
  const request = new UserSessionRequest()
  request.setRealmpath(formId)
  request.setUserdata(JSON.stringify(userInfo))
  request.setUsername(userInfo.name)
  request.setUserid(userId)
  request.setToken(multiuserLoginToken)
  request.setMode(mode || 'Testing')
  console.log('start editing form', request)
  grpc.unary(Multiuser.StartSession, {
    request,
    host,
    metadata: new grpc.Metadata({}),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
          if (response.trailers.has('Exception')) {
            console.log('exception caught', response.trailers.get('Exception'))
          }
        }
      } else {
        if (onSuccess) {
          onSuccess({
            sessionToken: response.message.array[0],
            colorIndex: response.message.array[1]
              ? response.message.array[1]
              : 0
          })
        }
      }
    }
  })
}
