import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { NumberFormatDefault } from './Common'

export const PdfPropsForm = ({
  typeProps,
  showFontSizeOption = false,
  showWrapOption = true,
  wrapDisable = false,
  dispatch,
  depth,
  injectable,
  isGroup,
  defaultValue = 'card'
}) => {
  const {
    allowPDFWrap,
    pdfFontSize,
    pdfElementStyle = defaultValue
  } = typeProps

  const handleDispatch = (fieldName, fieldValue) => {
    let toSet = isGroup ? fieldValue : { ...typeProps, [fieldName]: fieldValue }
    const field = isGroup ? fieldName : 'typeProps'
    const newDepth = isGroup ? depth : depth.split('.')

    const compatibilityAdjustments = {
      border: { pdfBorderDisplay: true, pdfCardDisplay: false },
      card: { pdfBorderDisplay: false, pdfCardDisplay: true },
      plain: { pdfBorderDisplay: false, pdfCardDisplay: false }
    }

    // Apply backward compatibility adjustments if necessary
    if (compatibilityAdjustments.hasOwnProperty(fieldValue)) {
      const adjustments = compatibilityAdjustments[fieldValue]
      if (isGroup) {
        handleDispatch('pdfBorderDisplay', adjustments.pdfBorderDisplay)
        handleDispatch('pdfCardDisplay', adjustments.pdfCardDisplay)
      } else {
        toSet = { ...toSet, ...adjustments }
      }
    }

    dispatch({
      type: 'FIELD',
      injectable,
      depth: newDepth,
      fieldName: field,
      fieldValue: toSet
    })
  }

  return (
    <div style={{ marginTop: 10 }}>
      <Typography variant='h6'>
        <Trans>Pdf props</Trans>
      </Typography>
      <RadioGroup
        row
        defaultValue={defaultValue}
        value={pdfElementStyle}
        onChange={e => {
          const val = e.currentTarget.value
          handleDispatch('pdfElementStyle', val)
        }}
      >
        <FormControlLabel
          value='card'
          control={<Radio />}
          label={<Trans>Display as a card</Trans>}
        />
        <FormControlLabel
          value='border'
          control={<Radio />}
          label={<Trans>Display with border</Trans>}
        />
        <FormControlLabel
          value='plain'
          control={<Radio />}
          label={<Trans>Without styling</Trans>}
        />
      </RadioGroup>
      {showWrapOption && (
        <div style={{ padding: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(allowPDFWrap)}
                disabled={wrapDisable}
                onChange={e => {
                  handleDispatch('allowPDFWrap', e.target.checked)
                }}
              />
            }
            label={<Trans>Allow wrapping of element</Trans>}
          />
        </div>
      )}
      {showFontSizeOption && (
        <TextField
          style={{ marginTop: 8 }}
          label={<Trans>Font size</Trans>}
          value={pdfFontSize || ''}
          fullWidth
          variant='outlined'
          multiline
          InputProps={{ inputComponent: NumberFormatDefault }}
          onChange={e => {
            handleDispatch('pdfFontSize', e.target.value)
          }}
        />
      )}
    </div>
  )
}
