import {
  Chip,
  ListSubheader,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ListboxComponent } from 'app/views/common-components/ListboxComponent'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { useSelector } from 'react-redux'
import { geoData, provinceAbbrevationToProvince } from './FormCensusDivision'

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export const renderGeohraphicalOptionsGroup = params => [
  <ListSubheader key={params.key} component='div'>
    {params.group}
  </ListSubheader>,
  params.children
]

export const AutocompleteWithGeographicalOptions = ({
  onlyProvinces,
  onlyCenzusDivision,
  avaliableProvinces,
  scopeType,
  disabled,
  ...props
}) => {
  const classes = useStyles()
  const langVersion = useSelector(state => state.user.language)

  return (
    <Autocomplete
      multiple
      fullWidth
      disableListWrap
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      options={Object.keys(geoData)
        .filter(item => {
          if (scopeType === 'Regional' && item.includes('_cenzus')) {
            const provinceId =
              provinceAbbrevationToProvince[geoData[item].province] +
              '_province'
            if (!props.value.includes(provinceId)) {
              return false
            }
          }
          if (onlyCenzusDivision && !item.includes('_cenzus')) {
            return false
          }
          if (onlyProvinces) {
            return item.includes('_province')
          } else if (avaliableProvinces && avaliableProvinces.length > 0) {
            const provinceAbbrevation = geoData[item].province
            return avaliableProvinces.includes(provinceAbbrevation)
          }
          return true
        })
        .sort((a, b) => a.localeCompare(b))}
      filterSelectedOptions
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGeohraphicalOptionsGroup}
      renderInput={params => (
        <TextField {...params} variant='outlined' label={props.label} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            key={option}
            label={getLabelFromTranslationData({
              data: geoData[option]?.label,
              langVersion
            })}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => (
        <Typography noWrap>
          {getLabelFromTranslationData({
            data: geoData[option]?.label,
            langVersion
          })}
        </Typography>
      )}
      {...props}
    />
  )
}
