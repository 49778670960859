import { Trans } from '@lingui/macro'
import { dateFormat } from 'app/appSettings'
import { Field } from 'formik'
import moment from 'moment'
import { languages } from 'translation/I18nConnectedProvider'
import { getMainConnected, getSFObjectFieldValue } from '../../Form'
import MUDatePicker from '../../multiuser/components/MUDatePicker'
import * as Yup from 'yup'

/**
 * @typedef FormElementReference
 * @type {string}
 */

/**
 * Form element which renders a Material UI date picker.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.clearable=false] If date picker should render a clear button inside.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {Date} [typeProps.minDate] Minimum date that can be set in the input.
 * @param  {Date}  [typeProps.maxDate] Maximum date that can be set in the input.
 * @param  {FormElementReference} [typeProps.minDateElement] Another form element which value will be used as minimum date that can be set in the input.
 * @param  {FormElementReference}  [typeProps.maxDateElement] Another form element which value will be used as maximum date that can be set in the input.
 */
export const FormDatePicker = ({
  typeProps,
  title,
  id,
  useMultiuser,
  muBag,
  disabled,
  formikRef
}) => {
  return (
    <Field name={id}>
      {({ form: { setFieldValue, setFieldTouched, values }, field, meta }) => {
        const valueIsValid = moment.utc(field.value).isValid()
        const fieldValue = moment.utc(field.value)
        let {
          showFieldLabel,
          clearable,
          minDate,
          maxDate,
          minDateElement,
          maxDateElement,
          required,
          startDateDaysAfterCurrentDate
        } = typeProps

        if(startDateDaysAfterCurrentDate && Number(startDateDaysAfterCurrentDate) < 0){
          startDateDaysAfterCurrentDate = null
        }

        const momentMaxDate = moment.utc(values[maxDateElement])
        const momentMinDate = moment.utc(values[minDateElement])

        const disableIfDateElementNull = 
          (
            maxDateElement && 
            values.hasOwnProperty(maxDateElement) && 
            values[maxDateElement] === null
          ) ||
          (
            minDateElement && 
            values.hasOwnProperty(minDateElement) && 
            values[minDateElement] === null
          )
      
        const startDateOffsetDate = startDateDaysAfterCurrentDate
          ? moment.utc().add(startDateDaysAfterCurrentDate, 'day')
          : null

        if (startDateOffsetDate) {
          minDate = minDate 
            ? moment.max(minDate, startDateOffsetDate) 
            : startDateOffsetDate
        }

        if (minDateElement && values[minDateElement]) {
          minDate = momentMinDate
          if (startDateOffsetDate) {
            minDate = momentMinDate 
              ? moment.max(minDate, startDateOffsetDate) 
              : startDateOffsetDate
          }
          if (valueIsValid && momentMinDate.isAfter(fieldValue)) {
            if (!momentMaxDate.isValid() || !momentMaxDate.isBefore(momentMinDate)) {
              setFieldValue(id, momentMinDate)
            }
          }
        }
        if (maxDateElement && values[maxDateElement]) {
          maxDate = momentMaxDate
          if (valueIsValid && momentMaxDate.isBefore(fieldValue)) {
            if (!momentMinDate.isValid() || !momentMinDate.isAfter(momentMaxDate)) {
              setFieldValue(id, momentMaxDate)
            }
          }
        }
        return (
          <MUDatePicker
            id={id}
            muBag={muBag}
            useMultiuser={useMultiuser}
            label={showFieldLabel && title}
            cancelLabel={<Trans>Cancel</Trans>}
            okLabel={<Trans>Ok</Trans>}
            format={dateFormat}
            disabled={disabled || disableIfDateElementNull }
            className='text-muted'
            inputVariant='outlined'
            fullWidth
            clearable={Boolean(clearable)}
            minDate={minDate}
            maxDate={maxDate}
            formikRef={formikRef}
            helperText={
              <>
                {required && <Trans>Required</Trans>}
                {required && disableIfDateElementNull && '. '}
                {disableIfDateElementNull && 
                  <Trans>
                    To edit this field, please complete the required fields first.
                  </Trans>
                }
              </>
            }           
          />
        )
      }}
    </Field>
  )
}

Yup.addMethod(Yup.date, 'checkMinDateMaxDate', function (item, data, message) {
  return this.test('checkMinDateMaxDate', message, function (selectedDate) {
    const { path, createError } = this
    const {
      minDateElement,
      maxDateElement,
      minDate,
      maxDate,
      startDateDaysAfterCurrentDate,
    } = item?.typeProps || {}

    if (!selectedDate) {
      return true
    }

    const normalizedSelectedDate = moment(selectedDate)
    const dynamicMinDate = data[minDateElement] ? moment.utc(data[minDateElement]) : null
    const dynamicMaxDate = data[maxDateElement] ? moment.utc(data[maxDateElement]) : null

    let minDateToUse = dynamicMinDate ? dynamicMinDate : (minDate ? moment.utc(minDate) : null)
    let maxDateToUse = dynamicMaxDate ? dynamicMaxDate : (maxDate ? moment.utc(maxDate) : null)

    const startDateOffsetDate = startDateDaysAfterCurrentDate && startDateDaysAfterCurrentDate >= 0
      ? moment.utc().add(startDateDaysAfterCurrentDate, 'days')
      : null

    if (startDateOffsetDate) {
      minDateToUse = minDateToUse ? moment.max(minDateToUse, startDateOffsetDate) : startDateOffsetDate
    }

    const formattedSelectedDate = normalizedSelectedDate.format('YYYY-MM-DD')
    const formattedMinDate = minDateToUse ? minDateToUse.format('YYYY-MM-DD') : null
    const formattedMaxDate = maxDateToUse ? maxDateToUse.format('YYYY-MM-DD') : null

    if (formattedMinDate && formattedSelectedDate < formattedMinDate) {
      return createError({
        path,
        message: message,
      })
    }

    if (formattedMaxDate && formattedSelectedDate > formattedMaxDate) {
      return createError({
        path,
        message: message,
      })
    }

    return true
  })
})

export const FormDatePickerValidation = (item, data) => {
  const { required } = item.typeProps

  let validationSchema = Yup.date()
    .nullable()
    .checkMinDateMaxDate(
      item, 
      data, 
      <Trans>FORM_EDITOR_DATE_PICKER_INVALID_DATE_LABEL</Trans>
    )

  if (required) {
    validationSchema = validationSchema.required(<Trans>Required</Trans>)
  }

  return validationSchema
}

export const formDatePickerDefaultValue = (obj, info, item) => {
  if (!obj) {
    return null
  }
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField) {
    const sfValue = getSFObjectFieldValue(obj, connectedField)
    return sfValue ? sfValue : null
  } else {
    return null
  }
}

export const formDatePickerValueToText = value => {
  const toDate = moment(value)
  if (toDate.isValid()) {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = toDate.format(dateFormat)
      return acc
    }, {})
    return dateObj
  } else {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = value || ''
      return acc
    }, {})
    return dateObj
  }
}
