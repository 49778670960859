import { portalLanguagesData } from "app/appSettings";
import {
  getLabelFromTranslationData,
  getTitleTranslation,
} from "app/views/common/TranslationsCommon";
import moment from "moment";
import {
  getEmptyLangValues,
  getInitialSurveyLangValues,
  getSurveyTranslationField,
} from "utils";
import { CONFIGURATION_SALESFORCE_ID } from "../../../config/configurationMetadata";
import { surveyToRTF } from "../../../utils/survey/surveyToRTF";
import { satScoreCard } from "../../../views/surveys/surveyScoreCard";
import { satScoreCardCoop } from "../../../views/surveys/surveyScoreCardCoop";
import { satScoreCardOSBL } from "../../../views/surveys/surveyScoreCardOSBL";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { isSandbox } from "../sfAuthConfig";
import { mapSFToForm } from "../sfDataService";
import { SF_API_VERSION } from "./sfOpportunity";

const joinTemplateAndItemFields = [
  "Id",
  "Item_Options__c",
  "Order__c",
  "Parent__c",
  "Survey_Item__c",
  "Survey_Template__c",
  "Tag__c",
];

export const textFields = [
  "Id",
  "Extended__c",
  "Language__c",
  "Survey_Translation__c",
  "Text__c",
  "Tooltip__c",
  "Text_Is_Portal_Translation_Id__c",
  "Tooltip_Is_Portal_Translation_Id__c",
  "Extended_Is_Portal_Translation_Id__c",
];

const itemFields = ["Id", "Text__c", "Type__c"];

const translationFields = ["Id"];

const detailFields = [
  "Id",
  "Answer_Text__c",
  "API_value__c",
  "Item__c",
  "Details__c",
  "Order__c",
];

const templateFields = ["Id", "Name", "Title_Translation__c"];

const joinTemplateAndTemplateFields = [
  "Id",
  "Child_Templete__c",
  "Order__c",
  "Parent_Template__c",
];

export const NO_TEMPLATES = "NO_TEMPLATES";

export const surveyItemOptionsFields = [
  "conditionalObject",
  "allowRowsToExpand",
  "conditionalObjectSubfield",
  "conditionalObjectName",
  "conditionalObjectField",
  "conditionForObjectField",
  "isLinked",
  "linkedObject",
  "linkedField",
  "createCase",
  "caseType",
  "tooltipAtBottom",
  "hasTooltip",
  "isRequired",
  "nonApplicable",
  "skipInExport",
  "skipInPrint",
  "isGroup",
  "rows",
  "min",
  "max",
  "maxCharacters",
  "validationRules",
  "fileDescription",
  "showDescription",
];

interface FindSurveyTemplatesProps extends FindTemplatesProps {
  language?: string;
  [key: string]: any;
}

/**
 * Finds survey templates along with their children based on the provided properties.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Joins_Template_and_Question__c, and Survey_Template_Joins_Survey_Template__c
 * @param {FindSurveyTemplatesProps} [props={}] - Properties for finding survey templates (optional).
 * @returns {object} An object containing survey templates and their children.
 */
export const findSurveyTemplatesWithChildren = (
  props: FindSurveyTemplatesProps = {}
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return findTemplates(props).then((templates) => {
    const toRet = {};
    templates.forEach((result) => {
      const template = result.template;
      if (template.Survey_Template_Joins_Survey_Template1__r === null) {
        toRet[template.Id] = {
          id: template.Id,
          translations: result.titleTranslation,
          name:
            getTitleTranslation(
              props.language as string,
              result.titleTranslation
            ) || template.Name,
          children: [],
        };
      }
    });
    templates.forEach((result) => {
      const template = result.template;
      if (template.Survey_Template_Joins_Survey_Template1__r !== null) {
        const join =
          template.Survey_Template_Joins_Survey_Template1__r.records[0];
        const order = join.Order__c;
        toRet[join.Parent_Template__c].children.push({
          id: template.Id,
          order,
          join: join,
          translations: result.titleTranslation,
          name:
            getTitleTranslation(
              props.language as string,
              result.titleTranslation
            ) || template.Name,
        });
      }
    });
    return toRet;
  });
};

interface FindTemplatesProps {
  searchParams?: object;
  permissions?: {
    SCORE_CARDS?: boolean;
  };
}

/**
 * Finds survey templates based on the provided properties.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Joins_Template_and_Question__c, and Survey_Template_Joins_Survey_Template__c
 * @param {FindTemplatesProps} [props={}] Properties for finding survey templates (optional).
 * @returns {object[]} An array of survey template objects.
 */
export const findTemplates = (props: FindTemplatesProps = {}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const { searchParams = {}, permissions = {} } = props;
  let query = conn
    .sobject("Survey_Templates__c")
    .find({ ...searchParams })
    .select(templateFields.join(", "))
    .include("Survey_Joins_Template_and_Question__r")
    .select(joinTemplateAndItemFields.join(", "))
    .end()
    .include("Survey_Template_Joins_Survey_Template1__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end()
    .include("Survey_Template_Joins_Survey_Template__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end();
  if (permissions.SCORE_CARDS) {
    query = query.include("Score_Cards__r").end();
  }
  return query.run({ autoFetch: true, maxFetch: 1000 }).then((result) => {
    const toFind: string[] = [];
    const matchObj = {};
    result.forEach((template) => {
      if (!toFind.includes(template.Title_Translation__c)) {
        toFind.push(template.Title_Translation__c);
      }
      if (template.Score_Cards__r) {
        template.Score_Cards__r.records.forEach((record) => {
          if (!toFind.includes(record.Title_Translation__c)) {
            toFind.push(record.Title_Translation__c);
          }
        });
      }
      matchObj[template.Title_Translation__c] = {
        template: template,
        titleTranslation: null,
      };
    });
    if (toFind.length === 0) {
      return Promise.reject(NO_TEMPLATES);
    }
    return conn
      .sobject("SurveyTranslation__c")
      .find({ Id: toFind })
      .select(translationFields.join(", "))
      .include("Survey_Texts__r")
      .select(textFields.join(", "))
      .end()
      .run({ autoFetch: true })
      .then((translations) => {
        const toReturn: object[] = [];
        const matchScoreCardsTranslations = {};
        translations.forEach((trans) => {
          if (matchObj[trans.Id]) {
            matchObj[trans.Id].titleTranslation = trans;
          } else {
            matchScoreCardsTranslations[trans.Id] = trans;
          }
        });
        for (const key in matchObj) {
          if (Object.hasOwnProperty.call(matchObj, key)) {
            const element = matchObj[key];
            const template = element.template;
            if (template.Score_Cards__r) {
              template.Score_Cards__r.records.forEach((record) => {
                record.Title_Translation__c =
                  matchScoreCardsTranslations[record.Title_Translation__c];
              });
            }
            toReturn.push(element);
          }
        }
        return toReturn;
      });
  });
};

interface RecordObject {
  Parent_Template__c: string;
  Order__c: number;
}

interface MatchWithTranslationObject {
  template: {
    Id: string;
    Survey_Template_Joins_Survey_Template1__r: {
      records: RecordObject[];
    };
  };
  titleValue: object;
}
/**
 * Retrieves survey templates excluding specified ids.
 * @function
 * @category Salesforce - Survey_Templates__c and Survey_Template_Joins_Survey_Template1__c
 * @param {string[]} [excludeIds=[]] Array of survey template ids to exclude (optional).
 * @returns {Promise<object>} An object containing survey templates and their translations.
 */
export const getOtherTemplates = (excludeIds: string[] = []) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Survey_Templates__c")
    .find({})
    .select("Name, Id, Title_Translation__c")
    .include("Survey_Template_Joins_Survey_Template1__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end()
    .run({ autoFetch: true, maxFetch: 1000 })
    .then((result) => {
      const toFind: string[] = [];
      const matchObj = {};
      result.forEach((template) => {
        if (!excludeIds.includes(template.Id)) {
          toFind.push(template.Title_Translation__c);
          matchObj[template.Title_Translation__c] = {
            template: template,
            titleValue: getEmptyLangValues(),
          };
        }
      });
      return conn
        .sobject("SurveyTranslation__c")
        .find({ Id: toFind })
        .select(translationFields.join(", "))
        .include("Survey_Texts__r")
        .select(textFields.join(", "))
        .end()
        .then((result) => {
          const matchWithTranslation: MatchWithTranslationObject[] = [];
          result.forEach((trans) => {
            matchObj[trans.Id].titleValue = getInitialSurveyLangValues(
              "Text",
              trans
            );
          });
          for (const key in matchObj) {
            if (Object.hasOwnProperty.call(matchObj, key)) {
              const element = matchObj[key];
              matchWithTranslation.push(element);
            }
          }
          const toReturn = {};
          matchWithTranslation.forEach((template) => {
            if (!template.template.Survey_Template_Joins_Survey_Template1__r) {
              const id = template.template.Id;
              if (!toReturn[id]) {
                toReturn[id] = {
                  ...template,
                  childTemplates: [],
                };
              } else {
                toReturn[id] = {
                  ...toReturn[id],
                  ...template,
                };
              }
            }
            if (template.template.Survey_Template_Joins_Survey_Template1__r) {
              const join =
                template.template.Survey_Template_Joins_Survey_Template1__r
                  .records[0];
              const parentId = join.Parent_Template__c;
              const order = join.Order__c;
              if (toReturn[parentId]) {
                toReturn[parentId].childTemplates.push({
                  ...template,
                  order: order,
                });
                toReturn[parentId].childTemplates.sort(
                  (a, b) => a.order - b.order
                );
              } else {
                toReturn[parentId] = {
                  childTemplates: [{ ...template, order: order }],
                };
              }
            }
          });
          return toReturn;
        });
    });
};

/**
 * Finds completed surveys based on the provided template id and search properties.
 * @function
 * @category Salesforce - Survey__c
 * @param {string|string[]} templateId The id or array of ids of the survey template(s) to search for completed surveys.
 * @param {object} [searchProps={}] - Additional search properties to filter completed surveys (optional).
 * @returns {object[]} A promise that resolves with an array of completed survey objects.
 */
export const findCompletedSurveysParsed = (
  templateId: string | string[],
  searchProps: object = {}
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  console.log("completed surveys search props", searchProps, templateId);
  return conn
    .sobject("Survey__c")
    .find({
      Template__c:
        typeof templateId === "object" ? { $in: templateId } : templateId,
      SurveyState__c: "Submitted",
      Contains_Aggregated_Data__c: false,
      ...searchProps,
    })
    .then(
      (result) => {
        result = result.map((survey) => mapSFToForm(mapFields, survey));
        result.sort(
          (a, b) =>
            new Date(b.modifiedDate).getTime() -
            new Date(a.modifiedDate).getTime()
        );
        return result;
      },
      (reject) => {
        console.log(reject);
      }
    );
};

export const createSurveyTemplate = (langValue) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("SurveyTranslation__c")
    .create({})
    .then((translation) => {
      Object.values(portalLanguagesData).map((obj) => {
        conn.sobject("Survey_Text__c").create({
          Survey_Translation__c: translation.id,
          Text__c: langValue[obj.editKey],
          Language__c: obj.surveyTextKey,
        });
      });
      return conn.sobject("Survey_Templates__c").create(
        {
          Name: langValue.en,
          Title_Translation__c: translation.id,
        },
        function (err, ret) {
          if (err || !ret.success) {
            return console.error(err, ret);
          }
        }
      );
    });
};

/**
 * Creates a subsurvey under a specified parent survey template.
 * @function
 * @category Salesforce - Survey_Template_Join_Survey_Template__c, Survey_Template__c, and SurveyTranslation__c
 * @param {string} parentId The id of the parent survey template.
 * @param {object} langValue An object containing language-specific values for the subsurvey.
 * @param {number} [childCount=0] The order of the subsurvey among its siblings (optional).
 * @returns {JSForceResult}
 */
export const createSubsurvey = (
  parentId: string,
  langValue: object,
  childCount: number = 0
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("SurveyTranslation__c")
    .create({})
    .then((translation) => {
      let promiseArray: Promise<any>[] = [];
      Object.values(portalLanguagesData).map((obj) => {
        const transPromise = conn.sobject("Survey_Text__c").create({
          Survey_Translation__c: translation.id,
          Text__c: langValue[obj.editKey] || "",
          Language__c: obj.surveyTextKey,
        });
        promiseArray.push(transPromise);
      });
      return Promise.all([
        ...promiseArray,
        conn.sobject("Survey_Templates__c").create(
          {
            Name: langValue["en"],
            Title_Translation__c: translation.id,
          },
          function (err, ret) {
            if (err || !ret.success) {
              return console.error(err, ret);
            }
            // console.log('Created record id : ' + ret.id)
            return conn
              .sobject("Survey_Template_Join_Survey_Template__c")
              .create({
                Parent_Template__c: parentId,
                Order__c: childCount,
                Child_Templete__c: ret.id,
              });
          }
        ),
      ]);
    });
};

/**
 * Creates a new action text in a survey translation record.
 * @function
 * @category Salesforce - Survey_Text__c
 * @param {string} id The id of the survey translation record.
 * @param {string} languageKey The language key of the text.
 * @param {string} text The text content.
 * @param {boolean} isId Specifies whether the text is an id.
 * @returns {JSForceResult}
 */
export const createActionText = (
  id: string,
  languageKey: string,
  text: string,
  isId: boolean
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Survey_Text__c").create({
    Survey_Translation__c: id,
    Language__c: languageKey,
    Text__c: text,
    Is_Portal_Translation_Id__c: isId,
  });
};

const questionDetailsKeys = [
  "rows",
  "max",
  "min",
  "createdId",
  "tooltipAtBottom",
  "showDescription",
  "fileDescription",
  "hasTooltip",
  "mainTemplate",
  "conditionalObject",
  "conditionalObjectSubfield",
  "conditionalObjectName",
  "conditionalObjectField",
  "conditionForObjectField",
  "isLinked",
  "linkedObject",
  "linkedField",
  "isRequired",
  "isGroup",
  "conditions",
  "validationRules",
  "externalConditions",
  "conditionsToCheck",
  "index",
  "parentQuestionId",
  "parentQuestionIndex",
  "optionsWithRequiredDetails",
];

interface SurveyTemplateData {
  id: string;
  children: { id: string }[];
}

/**
 * Duplicates a survey template and its children.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Template_Join_Survey_Template__c, and Survey_Text__c
 * @param {SurveyTemplateData} data The data object containing information about the template and its children.
 * @param {string} data.id The ID of the template to be duplicated.
 * @param {object[]} data.children An array of child templates to be duplicated.
 * @returns {JSForceResult}
 */
export const duplicateSurveyTemplate = (data: SurveyTemplateData) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  return duplicateTemplate(data.id).then((duplicateId) => {
    if (data.children.length === 0) {
      return Promise.resolve();
    } else {
      return Promise.all(
        data.children.map((child, index) =>
          duplicateTemplate(child.id, duplicateId, index)
        )
      );
    }
  });
};

interface Tooltip {
  value?: string;
  isPortalId?: boolean;
}

interface TextContent {
  value?: string;
  isPortalId?: boolean;
}

interface ExtendedContent {
  value?: string;
  isPortalId?: boolean;
}

interface SurveyItemOptions {
  createdId?: string;
  parentQuestionId?: string;
  conditions?: any[];
  validationRules?: any[];
  externalConditions?: any[];
  conditionsToCheck?: any[];
}

/**
 * Duplicates a survey template with its details.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Template_Join_Survey_Template__c, and Survey_Text__c
 * @param {string} id The id of the template to duplicate.
 * @param {string|null} [parentId=null] The id of the parent template for the duplicated template (optional).
 * @param {number} [index=0] The order of the duplicated template among its siblings (optional).
 * @returns {string} The id of the duplicated template.
 */
const duplicateTemplate = (
  id: string,
  parentId: string | null = null,
  index: number = 0
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return getSurveyTemplateDetails(id).then((details) => {
    const { titleDetails, questionsDetails, info } = details;
    const crypto = require("crypto");
    const oldQuestionIdToNewId = {};
    const languages = Object.values(portalLanguagesData);
    questionsDetails.forEach((question) => {
      const { itemOptions } = question;
      oldQuestionIdToNewId[itemOptions.createdId] = crypto
        .randomBytes(20)
        .toString("hex");
    });
    return conn
      .sobject("SurveyTranslation__c")
      .create({})
      .then((translation) => {
        languages.map((obj) => {
          const langKey: string = obj.editKey;
          const text: TextContent = getTranslatedContent(
            titleDetails.titleValue,
            langKey
          );
          const tooltip: Tooltip = getTranslatedContent(
            titleDetails.tooltipValue,
            langKey
          );
          const extended: ExtendedContent = getTranslatedContent(
            titleDetails.extendedValue,
            langKey
          );
          conn.sobject("Survey_Text__c").create({
            Survey_Translation__c: translation.id,
            Tooltip__c: tooltip.value || "",
            Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
            Text_Is_Portal_Translation_Id__c: text.isPortalId,
            Text__c: (text.value || "") + " duplicate",
            Extended__c: extended.value || "",
            Extended_Is_Portal_Translation_Id__c: extended.isPortalId,
            Language__c: obj.surveyTextKey,
          });
        });
        return conn
          .sobject("Survey_Templates__c")
          .create({
            Name: info.surveyName,
            Title_Translation__c: translation.id,
            Template_Options__c: JSON.stringify(details.templateOptions || {}),
          })
          .then((templateCreatedResult) => {
            const questionPromises: Promise<any>[] = [];

            if (parentId) {
              questionPromises.push(
                conn.sobject("Survey_Template_Join_Survey_Template__c").create({
                  Parent_Template__c: parentId,
                  Order__c: index,
                  Child_Templete__c: templateCreatedResult.id,
                })
              );
            }

            questionsDetails.forEach((question, qIndex) => {
              const { item, itemDetails, itemOptions, itemTranslation, order } =
                question;
              const questionPromise = conn
                .sobject("SurveyTranslation__c")
                .create({})
                .then((questionTrans) => {
                  let questionPromiseArray: Promise<any>[] = [];
                  languages.map((obj) => {
                    const langKey = obj.editKey;
                    const text: TextContent = getTranslatedContent(
                      itemTranslation.titleValue,
                      langKey
                    );
                    const tooltip: Tooltip = getTranslatedContent(
                      itemTranslation.tooltipValue,
                      langKey
                    );
                    const extended: ExtendedContent = getTranslatedContent(
                      itemTranslation.extendedValue,
                      langKey
                    );
                    const question = conn.sobject("Survey_Text__c").create({
                      Survey_Translation__c: questionTrans.id,
                      Text__c: text.value || "",
                      Text_Is_Portal_Translation_Id__c: text.isPortalId,
                      Extended__c: extended.value || "",
                      Extended_Is_Portal_Translation_Id__c: extended.isPortalId,
                      Tooltip__c: tooltip.value || "",
                      Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
                      Language__c: obj.surveyTextKey,
                    });
                    questionPromiseArray.push(question);
                  });
                  const surveyItem = conn
                    .sobject("Survey_Items__c")
                    .create({
                      Type__c: item.Type__c,
                      Text__c: questionTrans.id,
                    })
                    .then((item) => {
                      const promises: Promise<any>[] = [];
                      itemDetails.forEach((option, index) => {
                        const details = JSON.stringify({
                          clearOnSelect: option.clearOnSelect,
                        });
                        promises.push(
                          conn
                            .sobject("SurveyTranslation__c")
                            .create({})
                            .then((optionTrans) => {
                              let optionPromiseArray: Promise<any>[] = [];
                              const promiseDetail = conn
                                .sobject("SurveyItemDetail__c")
                                .create({
                                  Answer_Text__c: optionTrans.id,
                                  API_value__c: option.apiValue,
                                  Item__c: item.id,
                                  Order__c: index,
                                  Details__c: details,
                                });
                              languages.map((obj) => {
                                const langKey = obj.editKey;
                                const text: TextContent = getTranslatedContent(
                                  option.titleValue,
                                  langKey
                                );
                                const optionQuestion = conn
                                  .sobject("Survey_Text__c")
                                  .create({
                                    Survey_Translation__c: optionTrans.id,
                                    Text__c: text.value || "",
                                    Text_Is_Portal_Translation_Id__c:
                                      text.isPortalId,
                                    Language__c: obj.surveyTextKey,
                                  });
                                optionPromiseArray.push(optionQuestion);
                              });
                              return Promise.all([
                                ...optionPromiseArray,
                                promiseDetail,
                              ]);
                            })
                        );
                      });
                      const options: SurveyItemOptions = {};
                      questionDetailsKeys.forEach((key) => {
                        options[key] = itemOptions[key];
                      });
                      const createdId =
                        oldQuestionIdToNewId[options.createdId as string];
                      if (options.parentQuestionId) {
                        options.parentQuestionId =
                          oldQuestionIdToNewId[options.parentQuestionId];
                      }
                      const promiseJoin = conn
                        .sobject("Survey_Join_Template_and_Item__c")
                        .create({
                          Survey_Item__c: item.id,
                          Survey_Template__c: templateCreatedResult.id,
                          Order__c: order,
                          Item_Options__c: JSON.stringify({
                            ...options,
                            parentTemplateName: {
                              ...languages.reduce(
                                (result, obj: { editKey: string }) => {
                                  const langkey = obj.editKey;
                                  result[langkey] = getLabelFromTranslationData(
                                    {
                                      langVersion: langkey,
                                      data: titleDetails.titleValue,
                                    }
                                  );
                                  return result;
                                },
                                {}
                              ),
                            },
                            subquestions: [
                              ...itemDetails.map((item) => item.itemId),
                            ],
                            createdId,
                            conditions: options.conditions || [],
                            validationRules: options.validationRules || [],
                            externalConditions:
                              options.externalConditions || [],
                            conditionsToCheck: options.conditionsToCheck || [],
                          }),
                        });
                      promises.push(promiseJoin);
                      return Promise.all(promises);
                    });
                  return Promise.all([surveyItem, ...questionPromiseArray]);
                });
              questionPromises.push(questionPromise);
            });

            return Promise.all(questionPromises).then(
              (r) => templateCreatedResult.id
            );
          });
      });
  });
};

interface DeleteRequest {
  method: string;
  url: string;
  referenceId: string;
}

/**
 * Deletes a survey template and its associated child templates from Salesforce.
 * @function
 * @category Salesforce - Survey_Templates__c
 * @param {string} id The id of the survey template to delete.
 * @param {string[]} [childIds=null] An array of ids of child templates to delete along with the parent template. Defaults to null if not provided.
 * @returns {JSForceResult}
 */
export const deleteSurveyTemplate = (
  id: string,
  childIds: string[] | null = null
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  let toDestroy = [id];
  if (childIds) {
    toDestroy = [id, ...childIds];
  }
  return getDeleteIds(toDestroy).then((idsArray) => {
    let requests: DeleteRequest[] = [];
    if (idsArray.length < 200) {
      requests = [
        {
          method: "DELETE",
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            "ids=" +
            idsArray.join(",") +
            "&allOrNone=true",
          referenceId: "refDeleteObjects",
        },
      ];
    } else if (idsArray.length < 1000) {
      const subArrays = new Array(Math.ceil(idsArray.length / 200))
        .fill(0)
        .map((_) => idsArray.splice(0, 200));
      subArrays.forEach((sub, index) => {
        requests.push({
          method: "DELETE",
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            "ids=" +
            sub.join(",") +
            "&allOrNone=true",
          referenceId: "refDeleteObjects" + index,
        });
      });
    } else {
      return Promise.all(
        new Array(Math.ceil(idsArray.length / 1000))
          .fill(0)
          .map((_) => idsArray.splice(0, 1000))
          .map((thousandArray) => {
            const subArrays = new Array(Math.ceil(thousandArray.length / 200))
              .fill(0)
              .map((_) => thousandArray.splice(0, 200));
            subArrays.forEach((sub, index) => {
              requests.push({
                method: "DELETE",
                url:
                  `/services/data/${SF_API_VERSION}/composite/sobjects?` +
                  "ids=" +
                  sub.join(",") +
                  "&allOrNone=true",
                referenceId: "refDeleteObjects" + index,
              });
            });
            return conn.requestPost(
              `/services/data/${SF_API_VERSION}/composite/`,
              {
                allOrNone: true,
                compositeRequest: requests,
              }
            );
          })
      );
    }
    return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
      allOrNone: true,
      compositeRequest: requests,
    });
  });
};

/**
 * Changes the lock state of a survey using a Salesforce Flow.
 * @function
 * @category Salesforce - Survey_Templates__c
 * @param {string} surveyId The id of the survey to change the lock state for.
 * @param {string} userId The id of the user initiating the lock state change.
 * @param {boolean} lockState The new lock state to set for the survey.
 * @param {boolean} lockedTooLong Indicates if the survey has been locked for too long.
 * @returns {FlowResult}
 */
export const changeSurveyLockByFlow = ({
  surveyId,
  userId,
  lockState,
  lockedTooLong,
}: {
  surveyId: string;
  userId: string;
  lockState: boolean;
  lockedTooLong: boolean;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Survey_Lock_Unlock_SAT", {
      inputs: [
        {
          surveyId,
          lockState,
          userId,
          lockedTooLong,
        },
      ],
    })
    .then((flow) => flow[0].outputValues);
};

/**
 * Retrieves grantee report surveys using a Salesforce Flow.
 * @function
 * @category Salesforce - Survey_Templates__c and Opportunity__c
 * @param {string[]} opportunities The ids of the opportunities associated with the surveys.
 * @param {string[]} templatesIDs The ids of the survey templates to retrieve.
 * @returns {Object[]} An array of survey objects.
 */
export const getGranteeReportSurveysByFlow = ({
  opportunities,
  templatesIDs,
}: {
  opportunities: string[];
  templatesIDs: string[];
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Survey_Get_Surveys", {
      inputs: opportunities.map((id) => ({ templatesIDs, opportunityId: id })),
    })
    .then((flowResult) => {
      const toRet: object[] = [];
      flowResult.forEach((res) => {
        if (res && res.outputValues.result) {
          toRet.push(...res.outputValues.result);
        }
      });
      return toRet;
    });
};

/**
 * Retrieves SATs (Service Assessment Tools) using a Salesforce Flow.
 * @function
 * @category Salesforce - Survey_Templates__c and Account
 * @param {string} accountId The id of the account for which SATs are retrieved.
 * @param {string[]} templateIDs An array of template ids for which SATs are retrieved.
 * @returns {object[]} An array of SATs (Service Assessment Tools).
 * Each SAT object contains information about the assessment tool.
 */
export const getSATsByFlow = ({
  accountId,
  templateIDs,
}: {
  accountId: string;
  templateIDs: string[];
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Survey_Get_SAT", {
      inputs: [
        {
          accountId,
          templateIDs,
        },
      ],
    })
    .then((flowResult) => flowResult[0].outputValues.result);
};

/**
 * Deletes a survey record from Salesforce.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} id The id of the survey record to delete.
 * @returns {JSForceResult}
 */
export const deleteSurvey = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Survey__c").delete(id);
};

interface SurveySearchProps {
  searchParams?: SearchParams;
  searchByFlow?: boolean;
}

interface SearchParams {
  Id?: string;
  Template__c?: string;
  Report__c?: string;
}
/**
 * Retrieves surveys from Salesforce either by querying the Survey__c object directly or by invoking a flow.
 * @function
 * @category Salesforce - Survey__c and Survey_Templates__c
 * @param {object} props Additional properties for configuring the search.
 * @param {object} props.searchParams Parameters to filter the surveys.
 * @param {boolean} props.searchByFlow - Indicates whether to use a flow to retrieve surveys.
 * @returns {object[]} An array of survey objects.
 * Each survey object contains the survey data and its associated title translation.
 */
export const getSurveysByFlow = (props: SurveySearchProps = {}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const {
    searchParams = {},
    searchByFlow = false,
  }: {
    searchParams?: SearchParams;
    searchByFlow?: boolean;
  } = props;
  console.log("sfSurvey searchParams", searchParams);

  let promise;
  if (searchByFlow) {
    promise = conn
      .requestPost("/actions/custom/flow/App_Get_Grantee_Report_Survey", {
        inputs: [
          {
            id: searchParams.Id,
            reportId: searchParams.Template__c,
            templateId: searchParams.Report__c,
          },
        ],
      })
      .then((flowResult) => flowResult[0].outputValues.result);
  } else {
    promise = conn
      .sobject("Survey__c")
      .find({ ...searchParams })
      .select("*, Template__r.Category__c, Owner.Id");
  }

  return promise.then((surveys) => {
    const templatesIds: string[] = [];
    surveys.forEach((survey) => {
      if (!templatesIds.includes(survey.Template__c)) {
        templatesIds.push(survey.Template__c);
      }
    });
    return conn
      .sobject("Survey_Templates__c")
      .find({
        Id: { $in: templatesIds },
      })
      .select(templateFields.join(", "))
      .then((templates) => {
        const translationsIds: string[] = [];
        const transObj = {};
        templates.forEach((template) => {
          if (!translationsIds.includes(template.Title_Translation__c)) {
            transObj[template.Title_Translation__c] = template.Id;
            translationsIds.push(template.Title_Translation__c);
          }
        });
        return conn
          .sobject("SurveyTranslation__c")
          .find({
            Id: { $in: translationsIds },
          })
          .select(translationFields.join(", "))
          .include("Survey_Texts__r")
          .select(textFields.join(", "))
          .end()
          .then((translations) => {
            const matchWithSurvey = {};
            translations.forEach((trans) => {
              const templateId = transObj[trans.Id];
              if (templateId) {
                matchWithSurvey[templateId] = trans;
              }
            });
            return surveys.map((survey) => {
              return {
                titleTranslation: matchWithSurvey[survey.Template__c],
                survey: survey,
              };
            });
          });
      });
  });
};

/**
 * Retrieves sibling templates of a given template.
 * @function
 * @category Salesforce - Survey_Template_Join_Survey_Template__c
 * @param {string} templateId The id of the template to find siblings for.
 * @returns {object} An object containing an array of sibling templates and the id of their parent template.
 */
export const getSiblings = (templateId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Survey_Template_Join_Survey_Template__c")
    .find({
      Child_Templete__c: templateId,
    })
    .select(joinTemplateAndTemplateFields.join(", "))
    .then((result) => {
      const parentId = result[0].Parent_Template__c;
      return conn
        .sobject("Survey_Template_Join_Survey_Template__c")
        .find({
          Parent_Template__c: parentId,
        })
        .select(joinTemplateAndTemplateFields.join(", "))
        .then((joins) => {
          const returnTemplates: object[] = [];
          joins.forEach((join) => {
            if (join.Child_Templete__c !== templateId) {
              returnTemplates.push({ id: join.Child_Templete__c });
            }
          });
          return { templates: returnTemplates, parentId: parentId };
        });
    });
};

/**
 * Retrieves subtemplates associated with a given parent template id.
 * @function
 * @category Salesforce - Survey_Template_Join_Survey_Template__c
 * @param {string} templateId The id of the parent template.
 * @returns {object | null } An object containing the subtemplates and the parent id, or null if no parent id is found.
 */
export const getSubtemplates = (templateId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  return conn
    .sobject("Survey_Template_Join_Survey_Template__c")
    .find({
      Parent_Template__c: templateId,
    })
    .select(joinTemplateAndTemplateFields.join(", "))
    .then((result) => {
      const toReturn: object[] = [];
      result.forEach((join) => {
        toReturn.push({
          id: join.Child_Templete__c,
          order: join.Order__c,
        });
      });
      return { templates: toReturn, parentId: null };
    });
};

interface ReturnObj {
  questionsDetails?: object[];
  templateOptions?: object;
  info?: object;
  titleDetails?: object | null;
}

/**
 * Retrieves details of a survey template based on the provided template id.
 * @function
 * @category Salesforce - Survey_Templates__c and Survey_Template_Joins_Survey_Template1__r
 * @param {string} templateId The id of the survey template to retrieve details for.
 * @returns {object} An object containing the survey template details.
 */
export const getSurveyTemplateDetails = (templateId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  return conn
    .sobject("Survey_Templates__c")
    .find({ Id: templateId })
    .include("Survey_Template_Joins_Survey_Template1__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end()
    .then((result) => {
      const template = result[0];
      return conn
        .sobject("Survey_Join_Template_and_Item__c")
        .find({
          Survey_Template__c: templateId,
        })
        .select(joinTemplateAndItemFields.join(", "))
        .then((joins) => {
          const detailsData: string[] = [];
          const translationData: string[] = [];
          translationData.push(template.Title_Translation__c);
          const itemsData: string[] = [];
          joins.forEach((join, index) => {
            if (!itemsData.includes(join.Survey_Item__c)) {
              itemsData.push(join.Survey_Item__c);
            }
          });
          const returnObj: ReturnObj = {};
          if (itemsData.length < 1) {
            // STOP EXECUTING PROMISES IF TEMPLATE HAS NO ITEMS
            return conn
              .sobject("SurveyTranslation__c")
              .find({
                Id: template.Title_Translation__c,
              })
              .select("Id")
              .include("Survey_Texts__r")
              .select(textFields.join(", "))
              .end()
              .run({ autoFetch: true, maxFetch: 1000 })
              .then((titleTranslation) => {
                returnObj.questionsDetails = [];
                returnObj.templateOptions = template.Template_Options__c
                  ? JSON.parse(template.Template_Options__c)
                  : {};
                returnObj.info = {
                  surveyName: template.Name,
                  templateId: template.Id,
                  order:
                    template.Survey_Template_Joins_Survey_Template1__r &&
                    template.Survey_Template_Joins_Survey_Template1__r
                      .records[0].Order__c,
                  mainTemplate:
                    template.Survey_Template_Joins_Survey_Template1__r &&
                    template.Survey_Template_Joins_Survey_Template1__r
                      .records[0].Parent_Template__c,
                  templateCreatedDate: template.CreatedDate,
                };
                returnObj.titleDetails = getDetailsParsed([
                  titleTranslation,
                ])[0];
                return returnObj;
              });
          }
          return conn
            .sobject("Survey_Items__c")
            .find({
              Id: itemsData,
            })
            .select(itemFields.join(", "))
            .include("Survey_Joins_Template_and_Question__r")
            .select(joinTemplateAndItemFields.join(", "))
            .end()
            .run({ autoFetch: true, maxFetch: 1000 })
            .then((items) => {
              items.forEach((item) => {
                detailsData.push(item.Id);
                translationData.push(item.Text__c);
              });
              return conn
                .sobject("SurveyItemDetail__c")
                .find({
                  Item__c: detailsData,
                })
                .select(detailFields.join(", "))
                .then((details) => {
                  details.forEach((detail) => {
                    translationData.push(detail.Answer_Text__c);
                  });
                  return conn
                    .sobject("SurveyTranslation__c")
                    .find({
                      Id: translationData,
                    })
                    .select(translationFields.join(", "))
                    .include("SurveyFieldTranslation__r")
                    .select(detailFields.join(", "))
                    .end()
                    .include("Survey_Texts__r")
                    .select(textFields.join(", "))
                    .end()
                    .include("Survey_Items__r")
                    .select(itemFields.join(", "))
                    .end()
                    .include("Survey_Templates2__r")
                    .select(templateFields.join(", "))
                    .end()
                    .run({ autoFetch: true, maxFetch: 1000 })
                    .then((translations) => {
                      const parsedObj = matchSurveyObjects(
                        translations,
                        items,
                        details,
                        template.Id
                      );
                      returnObj.questionsDetails = parsedObj.list;
                      returnObj.templateOptions = template.Template_Options__c
                        ? JSON.parse(template.Template_Options__c)
                        : {};
                      returnObj.info = {
                        surveyName: template.Name,
                        templateId: template.Id,
                        templateCreatedDate: template.CreatedDate,
                        order:
                          template.Survey_Template_Joins_Survey_Template1__r &&
                          template.Survey_Template_Joins_Survey_Template1__r
                            .records[0].Order__c,
                        mainTemplate:
                          template.Survey_Template_Joins_Survey_Template1__r &&
                          template.Survey_Template_Joins_Survey_Template1__r
                            .records[0].Parent_Template__c,
                      };
                      returnObj.titleDetails = parsedObj.titleTranslation;
                      return returnObj;
                    });
                });
            });
        });
    });
};

/**
 * Retrieves the ids of records to be deleted based on provided template ids.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Join_Template_and_Item__c, and Survey_Template_Joins_Survey_Template1__c
 * @param {string[]} templateIds Array of template ids.
 * @returns {string[]} - An array of record ids to be deleted.
 */
export const getDeleteIds = (templateIds: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const deleteData: string[] = [];
  const searchArray: object[] = [];
  templateIds.forEach((id) => {
    searchArray.push({ Survey_Template__c: id });
  });
  return conn
    .sobject("Survey_Templates__c")
    .find({ Id: templateIds })
    .select("Id, Name, Title_Translation__c")
    .include("Survey_Template_Joins_Survey_Template1__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end()
    .include("Survey_Template_Joins_Survey_Template__r")
    .select(joinTemplateAndTemplateFields.join(", "))
    .end()
    .then((templates) => {
      return conn
        .sobject("Survey_Join_Template_and_Item__c")
        .find({
          $or: searchArray,
        })
        .select(joinTemplateAndItemFields.join(", "))
        .then((joins) => {
          const detailsData: string[] = [];
          const translationData: string[] = [];
          const itemsData: string[] = [];
          templates.forEach((template) => {
            translationData.push(template.Title_Translation__c);
            deleteData.push(template.Id);
            if (template.Survey_Template_Joins_Survey_Template__r) {
              template.Survey_Template_Joins_Survey_Template__r.records.forEach(
                (join) => {
                  if (!deleteData.includes(join.Id)) {
                    deleteData.push(join.Id);
                  }
                }
              );
            }
            if (template.Survey_Template_Joins_Survey_Template1__r) {
              template.Survey_Template_Joins_Survey_Template1__r.records.forEach(
                (join) => {
                  if (!deleteData.includes(join.Id)) {
                    deleteData.push(join.Id);
                  }
                }
              );
            }
          });
          joins.forEach((join, index) => {
            if (!itemsData.includes(join.Survey_Item__c)) {
              itemsData.push(join.Survey_Item__c);
            }
            deleteData.push(join.Id);
          });
          if (itemsData.length < 1) {
            // STOP EXECUTING PROMISES IF TEMPLATES HAVE NO ITEMS
            return conn
              .sobject("SurveyTranslation__c")
              .find({
                Id: translationData,
              })
              .select("Id")
              .include("Survey_Texts__r")
              .select(textFields.join(", "))
              .end()
              .run({ autoFetch: true, maxFetch: 1000 })
              .then((translations) => {
                translations.forEach((translation) => {
                  translation?.Survey_Texts__r?.records?.forEach((text) => {
                    deleteData.push(text.Id);
                  });
                  deleteData.push(translation.Id);
                });
                return deleteData;
              });
          }
          return conn
            .sobject("Survey_Items__c")
            .find({
              Id: itemsData,
            })
            .select(itemFields.join(", "))
            .include("Survey_Joins_Template_and_Question__r")
            .select(joinTemplateAndItemFields.join(", "))
            .end()
            .run({ autoFetch: true, maxFetch: 1000 })
            .then((items) => {
              items.forEach((item) => {
                if (
                  item.Survey_Joins_Template_and_Question__r.records.length ===
                  1
                ) {
                  deleteData.push(item.Id);
                  detailsData.push(item.Id);
                  translationData.push(item.Text__c);
                }
              });
              // IF DELETING AND ONLY QUESTIONS USED IN OTHER TEMPLATES
              if (detailsData.length === 0) {
                return deleteData;
              }
              return conn
                .sobject("SurveyItemDetail__c")
                .find({
                  Item__c: detailsData,
                })
                .select(detailFields.join(", "))
                .then((details) => {
                  details.forEach((detail) => {
                    translationData.push(detail.Answer_Text__c);
                    deleteData.push(detail.Id);
                  });
                  return conn
                    .sobject("SurveyTranslation__c")
                    .find({
                      Id: translationData,
                    })
                    .select(translationFields.join(", "))
                    .include("SurveyFieldTranslation__r")
                    .select(detailFields.join(", "))
                    .end()
                    .include("Survey_Texts__r")
                    .select(textFields.join(", "))
                    .end()
                    .include("Survey_Items__r")
                    .select(itemFields.join(", "))
                    .end()
                    .include("Survey_Templates2__r")
                    .select(templateFields.join(", "))
                    .end()
                    .run({ autoFetch: true, maxFetch: 1000 })
                    .then((translations) => {
                      translations.forEach((translation) => {
                        translation?.Survey_Texts__r?.records?.forEach(
                          (text) => {
                            deleteData.push(text.Id);
                          }
                        );
                        deleteData.push(translation.Id);
                      });
                      return deleteData;
                    });
                });
            });
        });
    });
};

interface TranslationObject {
  isItemTranslation: boolean;
  isTitleTranslation: boolean;
  itemId: string;
}

interface SurveyItem {
  Id: string;
  Survey_Joins_Template_and_Question__r: {
    records: {
      Survey_Template__c: string;
      Item_Options__c: string;
      Order__c: number;
      Id: string;
    }[];
  };
}

interface SurveyJoin {
  Survey_Template__c: string;
  Item_Options__c: string;
  Order__c: number;
  Id: string;
}

interface SurveyDetail {
  Item__c: string;
}

/**
 * Matches survey objects based on provided translations, items, details, and template id.
 * @function
 * @category Salesforce - Survey_Joins_Template_and_Question__c
 * @param {object[]} translations The translations for items and details.
 * @param {SurveyItem[]} items The survey items to be matched.
 * @param {SurveyDetail[]} details The survey details to be matched.
 * @param {string} templateId The id of the survey template to match against.
 * @returns {object} An object containing an array of matched survey items with details
 * and options and title translation object for the survey title.
 */
const matchSurveyObjects = (
  translations: object[],
  items: SurveyItem[],
  details: SurveyDetail[],
  templateId: string
) => {
  const toReturn: object[] = [];
  const matchObj = {};
  const matchItemsWithTranslation = {};
  const matchDetailsWithTranslation = {};
  const transArray: TranslationObject[] = getDetailsParsed(
    translations
  ) as TranslationObject[];
  let titleTranslation: TranslationObject | null = null;
  transArray.forEach((obj) => {
    if (obj.isItemTranslation) {
      matchItemsWithTranslation[obj.itemId] = obj;
      return;
    } else if (obj.isTitleTranslation) {
      titleTranslation = obj;
      return;
    }
    matchDetailsWithTranslation[obj.itemId]
      ? matchDetailsWithTranslation[obj.itemId].push(obj)
      : (matchDetailsWithTranslation[obj.itemId] = [obj]);
  });

  items.forEach((item) => {
    let matchJoin: SurveyJoin | null = null;
    if (!item.Survey_Joins_Template_and_Question__r) {
      return;
    }
    item.Survey_Joins_Template_and_Question__r.records.forEach((join) => {
      if (join.Survey_Template__c === templateId) {
        matchJoin = join;
      }
    });
    if (!matchJoin) {
      return;
    }
    matchObj[item.Id] = {
      item: item,
      itemTranslation: matchItemsWithTranslation[item.Id],
      itemDetails: [],
      itemOptions: JSON.parse((matchJoin as SurveyJoin).Item_Options__c),
      order: (matchJoin as SurveyJoin).Order__c,
      joinId: (matchJoin as SurveyJoin).Id,
    };
  });
  details.forEach((detail) => {
    if (matchDetailsWithTranslation[detail.Item__c]) {
      matchObj[detail.Item__c].itemDetails = [
        ...matchDetailsWithTranslation[detail.Item__c],
      ];
    } else {
      console.error(`No details found for ${detail.Item__c}`);
    }
  });

  for (const key in matchObj) {
    if (Object.hasOwnProperty.call(matchObj, key)) {
      const element = matchObj[key];
      toReturn.push(element);
    }
  }

  return { list: toReturn, titleTranslation: titleTranslation };
};

interface Option {
  idValue?: { [key: string]: string };
  Survey_Texts__r?: any;
  titleValue?: any;
  extendedValue?: any;
  tooltipValue?: any;
  parentId?: string;
  itemId?: string;
  detailId?: string | null;
  apiValue?: string;
  order?: number;
  titleId?: string;
  isItemTranslation?: boolean;
  isTitleTranslation?: boolean;
  clearOnSelect?: boolean;
  requireDetails?: boolean;
}

/**
 * Parses details data to extract necessary information.
 * @function
 * @category Salesforce - Survey_Texts__c, Survey_Templates__c, and Survey_Items__c
 * @param {object[]} details The details data to be parsed.
 * @returns {object[]} An array of parsed details objects.
 */
const getDetailsParsed = (details: object[]) => {
  const toReturn: Option[] = [];
  details.forEach((detailArray) => {
    let option: Option = {};
    option.idValue = {};
    const languages = Object.values(portalLanguagesData);
    const optionSF = detailArray[0] ? detailArray[0] : detailArray;
    if (!optionSF.Survey_Texts__r) {
      return;
    }
    optionSF.Survey_Texts__r.records.forEach((text) => {
      languages.some((obj) => {
        if (text.Language__c === obj.surveyTextKey && option.idValue) {
          option.idValue[obj.editKey] = text.Id;
          return true;
        }
        return false;
      });
    });
    option.Survey_Texts__r = optionSF.Survey_Texts__r;
    option.titleValue = getInitialSurveyLangValues("Text", optionSF);
    option.extendedValue = getInitialSurveyLangValues("Extended", optionSF);
    option.tooltipValue = getInitialSurveyLangValues("Tooltip", optionSF);
    option.parentId = optionSF.Id;
    if (optionSF.SurveyFieldTranslation__r) {
      const detailsObj = optionSF.SurveyFieldTranslation__r.records[0];
      const optionDetails = detailsObj.Details__c
        ? JSON.parse(detailsObj.Details__c)
        : {};
      option = { ...option, ...optionDetails };
      option.itemId = detailsObj.Item__c;
      option.detailId = detailsObj.Id;
      option.apiValue = detailsObj.API_value__c;
      option.order = detailsObj.Order__c;
    } else if (optionSF.Survey_Items__r) {
      option.itemId = optionSF.Survey_Items__r.records[0].Id;
      option.detailId = null;
      option.isItemTranslation = true;
    } else if (optionSF.Survey_Templates2__r) {
      option.titleId = optionSF.Survey_Templates2__r.records[0].Id;
      option.isTitleTranslation = true;
    }
    toReturn.push(option);
  });
  toReturn.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));
  return toReturn;
};

/**
 * Saves rearranged survey template records to the Salesforce database.
 * @function
 * @category Salesforce - Survey_Template_Join_Survey_Template__c
 * @param {object[]} updateArray An array of objects containing update data.
 * @returns {JSForceResult}
 */
export const saveRearrangedTemplates = (updateArray: object[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Survey_Template_Join_Survey_Template__c")
    .update(updateArray);
};

interface SurveyItemValues {
  toDelete?: {
    id?: string;
    Id?: string;
  }[];
  toUpdate: any[];
  created: Question[];
  titleData?: {
    idValue: string;
  };
  templateId: string;
  mainTemplate: any;
  titleValue: any;
  extendedValue?: any;
  tooltipValue?: any;
  surveyName: any;
  autosave: any;
  skipSection: any;
  translatedFor: any;
}

interface Question {
  sfId?: string;
  isGroup: boolean;
  groupQuestions?: Subquestion[];
  joins: {
    Id: string;
    Item_Options__c: string;
  }[];
  titleValue: TranslationData;
  tooltipValue: TranslationData;
  id: string;
  options: Option[];
  conditions: any[];
  externalConditions: any[];
  conditionsToCheck: any[];
  mainTemplate: string;
  tooltipAtBottom: boolean;
  showDescription: boolean;
  fileDescription: string;
  hasTooltip: boolean;
  conditionalObject?: string;
  conditionalObjectSubfield?: string;
  conditionalObjectName?: string;
  conditionalObjectField?: string;
  conditionForObjectField?: string;
  isLinked: boolean;
  linkedObject?: string;
  linkedField?: string;
  isRequired: boolean;
  validationRules: any[];
  rows: number;
  max: number;
  min: number;
  joinId?: string;
  type?: string;
  itemTranslation?: {
    idValue: object;
  };
}

interface Subquestion {
  sfId?: string;
  itemTranslation: {
    idValue: {
      [key: string]: string;
    };
    parentId?: string;
  };
  joinId?: string;
  id?: string;
  titleValue: TranslationData;
  tooltipValue: string;
  type: string;
  options?: any[];
  requireDetails?: any;
  joins?: QuestionJoin[];
}

interface Text {
  value?: string;
  isPortalId?: boolean;
}

interface QuestionJoin {
  Id: string;
  Survey_Template__c?: string;
  Item_Options__c: string;
  Order__c?: number;
}

/**
 * Saves survey items to the Salesforce database, including updates, deletions, and creations.
 * @function
 * @category Salesforce - Survey_Templates__c, Survey_Items__c, and Survey_Join_Template_and_Item__c
 * @param {SurveyItemValues} values An array of objects containing values for the survey items.
 * @param {string} templateId The id of the survey template associated with the items.
 * @returns {JSForceResult}
 */
export const saveItems = (values: SurveyItemValues, templateId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const languages = Object.values(portalLanguagesData);
  const questionPromises: (Promise<any> | Promise<any>[])[] = [];
  let deletePromise = Promise.resolve();
  let requests: DeleteRequest[] = [];
  if (values.toDelete && values.toDelete.length > 0) {
    const idsArray = [
      ...values.toDelete.map((item) => {
        item.Id = item.id || item.Id;
        return item.Id;
      }),
    ];
    if (idsArray.length < 200) {
      requests = [
        {
          method: "DELETE",
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            "ids=" +
            idsArray.join(",") +
            "&allOrNone=true",
          referenceId: "refDeleteObjects",
        },
      ];
    } else {
      const subArrays = new Array(Math.ceil(idsArray.length / 200))
        .fill(0)
        .map((_) => idsArray.splice(0, 200));
      subArrays.forEach((sub, index) => {
        requests.push({
          method: "DELETE",
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            "ids=" +
            sub.join(",") +
            "&allOrNone=true",
          referenceId: "refDeleteObjects" + index,
        });
      });
    }

    deletePromise = conn.requestPost(
      `/services/data/${SF_API_VERSION}/composite/`,
      {
        allOrNone: true,
        compositeRequest: requests,
      }
    );
  }

  let queriesUpdated = [...values.toUpdate];
  return Promise.resolve(deletePromise).then((result) => {
    values.created.forEach((question, qIndex) => {
      const groupQuestionsPromises: Promise<any>[] = [];
      if (question.isGroup && question.groupQuestions) {
        question.groupQuestions.forEach((subQuestion, subIndex) => {
          if (subQuestion.sfId) {
            languages.map((obj) => {
              const langKey = obj.editKey;
              const transId = subQuestion.itemTranslation.idValue[langKey];
              const text: Text = getTranslatedContent(
                subQuestion.titleValue,
                langKey
              );
              if (!transId) {
                const surveyTranslation = getSurveyTranslationField(
                  "fr",
                  "Survey_Translation",
                  subQuestion.itemTranslation
                );
                conn.sobject("Survey_Text__c").create({
                  Survey_Translation__c: surveyTranslation,
                  Text__c: text.value,
                  Text_Is_Portal_Translation_Id__c: text.isPortalId,
                  Language__c: obj.surveyTextKey,
                });
              } else {
                queriesUpdated.push({
                  Id: transId,
                  Text__c: text.value,
                  Text_Is_Portal_Translation_Id__c: text.isPortalId,
                  Language__c: obj.surveyTextKey,
                  type: "Survey_Text__c",
                });
              }
            });
            queriesUpdated.push({
              Id: subQuestion.sfId,
              Type__c: question.type,
              type: "Survey_Items__c",
            });
            if (subQuestion.joins && subQuestion.joins.length === 0) {
              questionPromises.push(
                conn.sobject("Survey_Join_Template_and_Item__c").create({
                  Survey_Item__c: subQuestion.sfId,
                  Survey_Template__c: templateId,
                  Order__c: qIndex,
                  Item_Options__c: JSON.stringify({
                    parentQuestionIndex: qIndex,
                    parentQuestionId: question.id,
                    createdId: subQuestion.id,
                    index: subIndex,
                  }),
                })
              );
            } else {
              subQuestion.joins?.forEach((join) => {
                const options = JSON.parse(join.Item_Options__c);
                queriesUpdated.push({
                  Id: join.Id,
                  Order__c: qIndex,
                  Item_Options__c: JSON.stringify({
                    parentQuestionIndex: qIndex,
                    parentQuestionId: options.parentQuestionId || question.id,
                    createdId: options.createdId,
                    index: subIndex,
                  }),
                  type: "Survey_Join_Template_and_Item__c",
                });
              });
            }
          } else {
            groupQuestionsPromises.push(
              conn
                .sobject("SurveyTranslation__c")
                .create({})
                .then((questionTrans) => {
                  let questionPromiseArray: Promise<any>[] = [];
                  languages.map((obj) => {
                    const langKey = obj.editKey;
                    const text: Text = getTranslatedContent(
                      subQuestion.titleValue,
                      langKey
                    );
                    const questionPromise = conn
                      .sobject("Survey_Text__c")
                      .create({
                        Survey_Translation__c: questionTrans.id,
                        Text__c: text.value,
                        Text_Is_Portal_Translation_Id__c: text.isPortalId,
                        Language__c: obj.surveyTextKey,
                      });
                    questionPromiseArray.push(questionPromise);
                  });
                  const surveyItem = conn
                    .sobject("Survey_Items__c")
                    .create({
                      Type__c: question.type,
                      Text__c: questionTrans.id,
                    })
                    .then((item) => {
                      if (question.joins.length > 0) {
                        const joinPromises: Promise<any>[] = [];
                        question.joins.forEach((join: QuestionJoin) => {
                          joinPromises.push(
                            conn
                              .sobject("Survey_Join_Template_and_Item__c")
                              .create({
                                Survey_Item__c: item.id,
                                Survey_Template__c: join.Survey_Template__c,
                                Order__c: qIndex,
                                Item_Options__c: JSON.stringify({
                                  parentQuestionIndex: qIndex,
                                  parentQuestionId: question.id,
                                  createdId: subQuestion.id,
                                  index: subIndex,
                                }),
                              })
                          );
                        });
                        return Promise.all(joinPromises);
                      } else {
                        return conn
                          .sobject("Survey_Join_Template_and_Item__c")
                          .create({
                            Survey_Item__c: item.id,
                            Survey_Template__c: templateId,
                            Order__c: qIndex,
                            Item_Options__c: JSON.stringify({
                              parentQuestionIndex: qIndex,
                              parentQuestionId: question.id,
                              createdId: subQuestion.id,
                              index: subIndex,
                            }),
                          });
                      }
                    });
                  return Promise.all([...questionPromiseArray, surveyItem]);
                })
            );
          }
        });
      } else if (!question.isGroup && question.groupQuestions) {
        question.groupQuestions.forEach((subQuestion, subIndex) => {
          if (subQuestion.sfId) {
            const deleteQueries: Promise<any>[] = [];
            Object.values(subQuestion.itemTranslation.idValue).map((id) => {
              if (id) {
                deleteQueries.push(conn.sobject("Survey_Text__c").delete(id));
              }
            });
            if (subQuestion.itemTranslation.parentId) {
              deleteQueries.push(
                conn
                  .sobject("SurveyTranslation__c")
                  .delete(subQuestion.itemTranslation.parentId)
              );
            }
            if (subQuestion.joinId) {
              deleteQueries.push(
                conn
                  .sobject("Survey_Join_Template_and_Item__c")
                  .delete(subQuestion.joinId)
              );
            }
            if (subQuestion.sfId) {
              deleteQueries.push(
                conn.sobject("Survey_Items__c").delete(subQuestion.sfId)
              );
            }
            groupQuestionsPromises.push(Promise.all(deleteQueries));
          }
        });
      }

      if (question.sfId) {
        languages.map((obj) => {
          const langKey = obj.editKey;
          const transId = question.itemTranslation?.idValue[langKey];
          const text: Text = getTranslatedContent(question.titleValue, langKey);
          const tooltip: Tooltip = getTranslatedContent(
            question.tooltipValue,
            langKey
          );
          if (!transId) {
            const surveyTranslation = getSurveyTranslationField(
              "en",
              "Survey_Translation",
              question.itemTranslation
            );
            conn.sobject("Survey_Text__c").create({
              Survey_Translation__c: surveyTranslation,
              Text__c: text.value,
              Text_Is_Portal_Translation_Id__c: text.isPortalId,
              Tooltip__c: tooltip.value,
              Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
              Language__c: obj.surveyTextKey,
            });
          } else {
            queriesUpdated.push({
              Id: transId,
              Text__c: text.value,
              Text_Is_Portal_Translation_Id__c: text.isPortalId,
              Tooltip__c: tooltip.value,
              Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
              Language__c: obj.surveyTextKey,
              type: "Survey_Text__c",
            });
          }
        });
        queriesUpdated.push({
          Id: question.sfId,
          Type__c: question.type,
          type: "Survey_Items__c",
        });
        question.options.forEach((option: Option, index) => {
          const details = JSON.stringify({
            clearOnSelect: option.clearOnSelect,
          });
          if (option.parentId) {
            languages.map((obj) => {
              const langKey = obj.editKey;
              const transId = option.idValue?.[langKey];
              const text: Text = getTranslatedContent(
                option.titleValue,
                langKey
              );
              if (!transId) {
                const surveyTranslation = getSurveyTranslationField(
                  "en",
                  "Survey_Translation",
                  option
                );
                conn.sobject("Survey_Text__c").create({
                  Survey_Translation__c: surveyTranslation,
                  Text__c: text.value,
                  Text_Is_Portal_Translation_Id__c: text.isPortalId,
                  Language__c: obj.surveyTextKey,
                });
              } else {
                queriesUpdated.push({
                  Id: transId,
                  Text__c: text.value,
                  Text_Is_Portal_Translation_Id__c: text.isPortalId,
                  Language__c: obj.surveyTextKey,
                  type: "Survey_Text__c",
                });
              }
            });
            queriesUpdated.push({
              Id: option.detailId,
              API_value__c: option.apiValue,
              Order__c: index,
              Details__c: details,
              type: "SurveyItemDetail__c",
            });
          } else {
            // CREATE OPTION
            questionPromises.push(
              conn
                .sobject("SurveyTranslation__c")
                .create({})
                .then((optionTrans) => {
                  let optionPromiseArray: Promise<any>[] = [];
                  const promiseDetail = conn
                    .sobject("SurveyItemDetail__c")
                    .create({
                      Answer_Text__c: optionTrans,
                      API_value__c: option.apiValue,
                      Item__c: question.sfId,
                      Details__c: details,
                      Order__c: index,
                    });
                  optionPromiseArray.push(promiseDetail);
                  languages.map((obj) => {
                    const langKey = obj.editKey;
                    const text: Text = getTranslatedContent(
                      option.titleValue,
                      langKey
                    );
                    const optionPromise = conn
                      .sobject("Survey_Text__c")
                      .create({
                        Survey_Translation__c: optionTrans.id,
                        Text__c: text.value,
                        Text_Is_Portal_Translation_Id__c: text.isPortalId,
                        Language__c: obj.surveyTextKey,
                      });
                    optionPromiseArray.push(optionPromise);
                  });
                  return Promise.all(optionPromiseArray);
                })
            );
          }
        });
        const optionsWithRequiredDetails: object[] = [];
        question.options.forEach((option) => {
          if (option.requireDetails) {
            optionsWithRequiredDetails.push({
              index: option.order,
              type: option.requireDetails,
            });
          }
        });
        question.joins.forEach((join: QuestionJoin) => {
          const options = JSON.parse(join.Item_Options__c);
          const updateObj = {
            optionsWithRequiredDetails: optionsWithRequiredDetails,
            createdId: options.createdId, /// options.createdId, // question.id, //
            mainTemplate: options.mainTemplate,
            parentTemplateName: options.parentTemplateName || {
              ...getEmptyLangValues(),
            },
            conditions: options.conditions || [],
            externalConditions: options.externalConditions || [],
            conditionsToCheck: options.conditionsToCheck || [],
          };
          surveyItemOptionsFields.forEach((field) => {
            updateObj[field] = question[field];
          });
          if (join.Id === question.joinId) {
            updateObj.parentTemplateName = {
              ...values.titleValue,
            };
            updateObj.mainTemplate = values.mainTemplate;
            updateObj.conditions = question.conditions;
            updateObj.externalConditions = question.externalConditions;
            updateObj.conditionsToCheck = question.conditionsToCheck;
          }
          queriesUpdated.push({
            Id: join.Id,
            Order__c: join.Id === question.joinId ? qIndex : join.Order__c,
            Item_Options__c: JSON.stringify(updateObj),
            type: "Survey_Join_Template_and_Item__c",
          });
        });
        if (question.joins.length === 0) {
          questionPromises.push(
            conn.sobject("Survey_Join_Template_and_Item__c").create({
              Survey_Item__c: question.sfId,
              Survey_Template__c: templateId,
              Order__c: qIndex,
              Item_Options__c: JSON.stringify({
                optionsWithRequiredDetails: optionsWithRequiredDetails,
                tooltipAtBottom: question.tooltipAtBottom,
                fileDescription: question.fileDescription,
                showDescription: question.showDescription,
                hasTooltip: question.hasTooltip,
                mainTemplate: values.mainTemplate,
                conditionalObject: question.conditionalObject,
                conditionalObjectSubfield: question.conditionalObjectSubfield,
                conditionalObjectName: question.conditionalObjectName,
                conditionalObjectField: question.conditionalObjectField,
                conditionForObjectField: question.conditionForObjectField,
                isLinked: question.isLinked,
                linkedObject: question.linkedObject,
                linkedField: question.linkedField,
                isRequired: question.isRequired,
                validationRules: question.validationRules,
                rows: question.rows,
                max: question.max,
                min: question.min,
                parentTemplateName: {
                  ...values.titleValue,
                },
                createdId: question.id,
                conditions: question.conditions,
                isGroup: question.isGroup,
                externalConditions: question.externalConditions,
                conditionsToCheck: question.conditionsToCheck,
              }),
            })
          );
        }
      } else {
        const questionPromise = conn
          .sobject("SurveyTranslation__c")
          .create({})
          .then((questionTrans) => {
            let questionPromiseArray2: Promise<any>[] = [];
            languages.map((obj) => {
              const langKey = obj.editKey;
              const text: Text = getTranslatedContent(
                question.titleValue,
                langKey
              );
              const tooltip: Tooltip = getTranslatedContent(
                question.tooltipValue,
                langKey
              );
              const questionPromise = conn.sobject("Survey_Text__c").create({
                Survey_Translation__c: questionTrans.id,
                Text__c: text.value,
                Text_Is_Portal_Translation_Id__c: text.isPortalId,
                Tooltip__c: tooltip.value,
                Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
                Language__c: obj.surveyTextKey,
              });
              questionPromiseArray2.push(questionPromise);
            });
            const surveyItem = conn
              .sobject("Survey_Items__c")
              .create({
                Type__c: question.type,
                Text__c: questionTrans.id,
              })
              .then((item) => {
                const promises: Promise<any>[] = [];
                question.options.forEach((option, index) => {
                  const details = JSON.stringify({
                    clearOnSelect: option.clearOnSelect,
                  });
                  promises.push(
                    conn
                      .sobject("SurveyTranslation__c")
                      .create({})
                      .then((optionTrans) => {
                        let optionPromiseArray2: Promise<any>[] = [];
                        const promiseDetail = conn
                          .sobject("SurveyItemDetail__c")
                          .create({
                            Answer_Text__c: optionTrans.id,
                            API_value__c: option.apiValue,
                            Item__c: item.id,
                            Order__c: index,
                            Details__c: details,
                          });
                        optionPromiseArray2.push(promiseDetail);
                        languages.map((obj) => {
                          const langKey = obj.editKey;
                          const text: Text = getTranslatedContent(
                            option.titleValue,
                            langKey
                          );
                          const promiseOption = conn
                            .sobject("Survey_Text__c")
                            .create({
                              Survey_Translation__c: optionTrans.id,
                              Text__c: text.value,
                              Text_Is_Portal_Translation_Id__c: text.isPortalId,
                              Language__c: obj.surveyTextKey,
                            });
                          optionPromiseArray2.push(promiseOption);
                        });
                        return Promise.all(optionPromiseArray2);
                      })
                  );
                });
                const optionsWithRequiredDetails: object[] = [];
                question.options.forEach((option) => {
                  if (option.requireDetails) {
                    optionsWithRequiredDetails.push({
                      index: option.order,
                      type: option.requireDetails,
                    });
                  }
                });
                const promiseJoin = conn
                  .sobject("Survey_Join_Template_and_Item__c")
                  .create({
                    Survey_Item__c: item.id,
                    Survey_Template__c: templateId,
                    Order__c: qIndex,
                    Item_Options__c: JSON.stringify({
                      optionsWithRequiredDetails: optionsWithRequiredDetails,
                      tooltipAtBottom: question.tooltipAtBottom,
                      showDescription: question.showDescription,
                      fileDescription: question.fileDescription,
                      hasTooltip: question.hasTooltip,
                      mainTemplate: values.mainTemplate,
                      conditionalObject: question.conditionalObject,
                      conditionalObjectSubfield:
                        question.conditionalObjectSubfield,
                      conditionalObjectName: question.conditionalObjectName,
                      conditionalObjectField: question.conditionalObjectField,
                      conditionForObjectField: question.conditionForObjectField,
                      isLinked: question.isLinked,
                      linkedObject: question.linkedObject,
                      linkedField: question.linkedField,
                      isRequired: question.isRequired,
                      parentTemplateName: {
                        ...values.titleValue,
                      },
                      subquestions: [
                        ...(question.groupQuestions ?? []).map(
                          (item) => item.sfId
                        ),
                      ],
                      rows: question.rows,
                      max: question.max,
                      min: question.min,
                      createdId: question.id,
                      conditions: question.conditions,
                      isGroup: question.isGroup,
                      validationRules: question.validationRules,
                      externalConditions: question.externalConditions,
                      conditionsToCheck: question.conditionsToCheck,
                    }),
                  });
                promises.push(promiseJoin);
                return Promise.all(promises);
              });
            return Promise.all([...questionPromiseArray2, surveyItem]);
          });

        questionPromises.push(questionPromise);
      }
      questionPromises.push(groupQuestionsPromises);
    });

    if (values.titleData) {
      languages.map((obj) => {
        const langKey = obj.editKey;
        const transId: SurveyItemValues = values.titleData?.idValue[langKey];
        const text: Text = getTranslatedContent(values.titleValue, langKey);
        const tooltip: Tooltip = getTranslatedContent(
          values.tooltipValue,
          langKey
        );
        const extended: ExtendedContent = getTranslatedContent(
          values.extendedValue,
          langKey
        );
        if (!transId) {
          const surveyTranslation = getSurveyTranslationField(
            "en",
            "Survey_Translation",
            values.titleData
          );
          conn.sobject("Survey_Text__c").create({
            Survey_Translation__c: surveyTranslation,
            Text__c: text.value,
            Text_Is_Portal_Translation_Id__c: text.isPortalId,
            Tooltip__c: tooltip.value,
            Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
            Extended__c: extended.value,
            Extended_Is_Portal_Translation_Id__c: extended.isPortalId,
            Language__c: obj.surveyTextKey,
          });
        } else {
          queriesUpdated.push({
            Id: transId,
            Text__c: text.value,
            Text_Is_Portal_Translation_Id__c: text.isPortalId,
            Tooltip__c: tooltip.value,
            Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
            Extended__c: extended.value,
            Extended_Is_Portal_Translation_Id__c: extended.isPortalId,
            type: "Survey_Text__c",
          });
        }
      });
      queriesUpdated.push({
        Id: values.templateId,
        Name: values.surveyName,
        Template_Options__c: JSON.stringify({
          autosave: values.autosave,
          skipSection: values.skipSection,
          translatedFor: values.translatedFor,
        }),
        type: "Survey_Templates__c",
      });
    } else {
      questionPromises.push(
        conn
          .sobject("SurveyTranslation__c")
          .create({})
          .then((translation) => {
            let questionPromiseArray3: Promise<any>[] = [];
            languages.map((obj) => {
              const langKey = obj.editKey;
              const text: Text = getTranslatedContent(
                values.titleValue,
                langKey
              );
              const tooltip: Tooltip = getTranslatedContent(
                values.tooltipValue,
                langKey
              );
              const extended: ExtendedContent = getTranslatedContent(
                values.extendedValue,
                langKey
              );
              const promise = conn.sobject("Survey_Text__c").create({
                Survey_Translation__c: translation.id,
                Text__c: text.value,
                Text_Is_Portal_Translation_Id__c: text.isPortalId,
                Tooltip__c: tooltip.value,
                Tooltip_Is_Portal_Translation_Id__c: tooltip.isPortalId,
                Extended__c: extended.value,
                Extended_Is_Portal_Translation_Id__c: extended.isPortalId,
                Language__c: obj.surveyTextKey,
              });
              questionPromiseArray3.push(promise);
            });
            const updateSurvey = conn.sobject("Survey_Templates__c").update({
              Id: values.templateId,
              Name: values.surveyName,
              Title_Translation__c: translation.id,
              Template_Options__c: JSON.stringify({
                autosave: values.autosave,
                skipSection: values.skipSection,
                translatedFor: values.translatedFor,
              }),
            });
            questionPromiseArray3.push(updateSurvey);

            return Promise.all(questionPromiseArray3);
          })
      );
    }
    queriesUpdated = queriesUpdated.sort((a, b) => {
      var nameA = a.type.toUpperCase();
      var nameB = b.type.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    const queriesSplit = new Array(Math.ceil(queriesUpdated.length / 200))
      .fill(0)
      .map((_) => queriesUpdated.splice(0, 200));
    const updatePromise =
      queriesSplit.length > 0
        ? conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
            allOrNone: true,
            compositeRequest: [
              ...queriesSplit.map((sub, index) => {
                return {
                  method: "PATCH",
                  url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
                  referenceId: "refUpdatedObjects" + index,
                  body: {
                    allOrNone: true,
                    records: [
                      ...sub.map((item) => {
                        const type = item.type;
                        delete item.type;
                        return {
                          attributes: { type: type },
                          ...item,
                        };
                      }),
                    ],
                  },
                };
              }),
            ],
          })
        : Promise.resolve();

    return Promise.all([...questionPromises, updatePromise]);
  });
};

/**
 * Saves imported survey data by creating new records for items to create and updating existing records for items to update.
 * @function
 * @category Salesforce - Survey_Text__c
 * @param {object[]} toUpdate The array of items to update.
 * @param {object[]} toCreate The array of items to create.
 * @returns {JSForceResult}
 */
export const saveImportedSurveyData = (
  toUpdate: object[],
  toCreate: object[]
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return Promise.all([
    conn.sobject("Survey_Text__c").create(toCreate),
    conn.sobject("Survey_Text__c").update(toUpdate),
  ]);
};

/**
 * Retrieves the metadata (describe) information for a specified Salesforce object.
 * @function
 * @category Salesforce
 * @param {string} obj The name of the Salesforce object for which metadata is to be retrieved.
 * @returns {object} The metadata information object.
 */
export const describe = (obj: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject(obj).describe(function (err, meta) {
    if (err) {
      return console.error(err);
    }
    console.log(meta);
    console.log("Label : " + meta.label);
    console.log("Num of Fields : " + meta.fields.length);
    let toLog: string[] = [];
    meta.fields.forEach((item) => {
      toLog.push(item.label);
    });
    toLog = toLog.sort((a, b) => a.localeCompare(b));
    console.log(toLog);
  });
};

const mapFields = {
  direction: "in",
  Id: "id",
  Answer__c: "answer",
  Name: "name",
  SurveyState__c: "surveyState",
  CreatedDate: "createdData",
  LastModifiedDate: {
    key: "modifiedDate",
    in: (survey) =>
      survey.Last_Modified_By_User_Date__c || survey.LastModifiedDate,
  },
  Answer_RTF__c: "answerRTF",
  Template_Snapshot__c: "templateSnapshot",
  Template__c: "templateId",
  User__c: "user",
  Account__c: "account",
  OwnerId: "owner",
  Owner_Info__c: "ownerInfo",
  Locked__c: "locked",
  Opportunity__c: "opportunity",
  Account_Name__c: "accountName",
  Contains_Aggregated_Data__c: "containsAggragatedData",
  // Name: 'organisationsName',
  // Other_used_name__c: 'otherName',
};

/**
 * Retrieves a survey record from Salesforce by its id.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} id The id of the survey record to retrieve.
 * @returns {object} The survey record.
 */
export const getSurvey = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    // console.log('getSurvey', id)
    return conn
      .sobject("Survey__c")
      .find({ Id: id })
      .then((r) => r[0]);
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Retrieves a parsed survey object from Salesforce by its id.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} id The id of the survey record to retrieve.
 * @returns {Object|null} The parsed survey object if found, or null if not found or if there's an authentication issue.
 */
export const getSurveyParsed = (id: string) => {
  // console.log('getSurveyParsed', id)
  return getSurvey(id).then((survey) =>
    survey ? mapSFToForm(mapFields, survey) : null
  );
};

/**
 * Retrieves a survey record by its id using a custom flow action.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} surveyId The id of the survey record to retrieve.
 * @returns {Object|null} The parsed survey object if found, or null if not found or if there's an authentication issue.
 */
export const getSurveyParsedByFlow = (surveyId: string) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn
      .requestPost("/actions/custom/flow/App_Get_Survey", {
        inputs: [
          {
            surveyId,
          },
        ],
      })
      .then((result) => {
        if (result[0].isSuccess && result[0].outputValues.survey) {
          return mapSFToForm(mapFields, result[0].outputValues.survey);
        } else {
          console.error("survey flow error getting survey", result);
          return null;
        }
      })
      .catch((err) => {
        console.error("survey flow error in flow", err);
        return null;
      });
  } else {
    return Promise.reject(NO_USER);
  }
};

interface SurveyUpdate {
  [key: string]: any;
}

/**
 * Updates a survey record in Salesforce with the provided update object.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} Id The id of the survey record to update.
 * @param {SurveyUpdate} updateObj Fields to update in the survey record.
 * @returns {JSForceResult}
 */
export const updateSurvey = (Id: string, updateObj: SurveyUpdate) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Survey__c").update({
    Id: Id,
    ...updateObj,
  });
};

/**
 * Saves survey data to Salesforce.
 * If an id is provided, the existing survey record will be updated; otherwise, a new record will be created.
 * @function
 * @category Salesforce - Survey__c
 * @param {string} [id] The id of the survey record to update (optional). If not provided, a new record will be created.
 * @param {string} name The name of the survey.
 * @param {string} accountId The id of the account associated with the survey.
 * @param {string} opportunityId The id of the opportunity associated with the survey.
 * @param {object} values The values object containing survey data.
 * @param {string} submitButtonType The type of submit button used in the survey.
 * @param {string} template The template used for the survey.
 * @param {string} userId The id of the user associated with the survey.
 * @param {string[]} [disableIds=[]] - An optional array of ids to disable.
 * @returns {JSForceResult}
 */
export const saveSurveyData = ({
  id,
  name,
  accountId,
  opportunityId,
  values,
  submitButtonType,
  template,
  userId,
  disableIds = [],
}: {
  id: string;
  name: string;
  accountId: string;
  opportunityId: string;
  values: object;
  submitButtonType: string;
  template: string;
  userId: string;
  disableIds: string[];
}) => {
  const conn = SFAuthService.getConnection();
  const saveData = {
    Id: id,
    Name: name,
    User__c: userId,
    Answer__c: JSON.stringify(values, null, 2),
    Answer_RTF__c: surveyToRTF(values, template, disableIds),
    Account__c: accountId,
    Opportunity__c: opportunityId,
    SurveyState__c: submitButtonType,
    Last_Modified_By_User_Date__c: moment.utc(),
  };
  if (conn) {
    if (id) {
      return conn.sobject("Survey__c").update(saveData);
    } else {
      return conn.sobject("Survey__c").create(saveData);
    }
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Creates a new survey record in Salesforce.
 * @function
 * @category Salesforce - Survey__c
 * @param {object} toCreate The object containing the data to create the survey record.
 * @returns {JSForceResult}
 */
export const createSurvey = (toCreate: object) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.sobject("Survey__c").create(toCreate);
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Finds survey records in Salesforce based on the provided query criteria.
 * @function
 * @category Salesforce - Survey__c
 * @param {object} toFind The query criteria to search for survey records.
 * @returns {object[]} An array of survey records matching the query criteria.
 */
export const findSurveys = (toFind: object) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.sobject("Survey__c").find(toFind);
  } else {
    return Promise.reject(NO_USER);
  }
};

export const surveyConfigurationVariables = {
  SAT_SURVEY: {
    key: "SAT_SURVEY",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  SAT_OSBL_SURVEY: {
    key: "SAT_OSBL_SURVEY",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  SAT_COOPS_SURVEY: {
    key: "SAT_COOPS_SURVEY",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  E_SAT_SURVEY: {
    key: "E_SAT_SURVEY",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },

  ABOUT_ME_SURVEY: {
    key: "ABOUT_ME_SURVEY",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },

  EXTERNAL_REVIEW_STF_SI: {
    key: "EXTERNAL_REVIEW_STF_SI",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  EXTERNAL_REVIEW_STF_LP: {
    key: "EXTERNAL_REVIEW_STF_LP",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  EXTERNAL_REVIEW_CBTI: {
    key: "EXTERNAL_REVIEW_CBTI",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  EXTERNAL_REVIEW_FCHI: {
    key: "EXTERNAL_REVIEW_FCHI",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },

  GRANTEE_REPORT_MIDTERM: {
    key: "GRANTEE_REPORT_MIDTERM",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },

  GRANTEE_REPORT_FINAL: {
    key: "GRANTEE_REPORT_FINAL",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  OPPORTUNITY_VIEW_REPORT: {
    key: "OPPORTUNITY_VIEW_REPORT",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  OPPORTUNITY_VIEW_EXTERNAL_REVIEW: {
    key: "OPPORTUNITY_VIEW_EXTERNAL_REVIEW",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
  OPPORTUNITY_VIEW_REVIEW_APPLICATION: {
    key: "OPPORTUNITY_VIEW_REVIEW_APPLICATION",
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: "Survey_Templates__c",
  },
};

export const SATsIdsList = [
  "a2Z5X0000006IHHUA2",
  "a2Z5X0000006IHHUA2",
  "a2Z5X0000007BHhUAM",
  "a2Z5X0000007BHhUAM",
  "a2Z5X0000007CJ0UAM",
  "a2Z5X0000007CJ0UAM",
];

export const surveyMapping = {
  "S.A.T.": {
    sandbox: "a2Z5X0000006IHHUA2",
    prod: "a2Z5X0000006IHHUA2",
    hasScore: true,
    configuration: surveyConfigurationVariables.SAT_SURVEY,
    surveyScoreCard: Object.fromEntries(
      satScoreCard.map((item) => [item.QuestionID, item])
    ),
  },
  "S.A.T. - OSBL": {
    sandbox: "a2Z5X0000007BHhUAM",
    prod: "a2Z5X0000007BHhUAM",
    configuration: surveyConfigurationVariables.SAT_OSBL_SURVEY,
    hasScore: true,
    surveyScoreCard: Object.fromEntries(
      satScoreCardOSBL.map((item) => [item.QuestionID, item])
    ),
  },
  "S.A.T. - Co-ops": {
    sandbox: "a2Z5X0000007CJ0UAM",
    prod: "a2Z5X0000007CJ0UAM",
    configuration: surveyConfigurationVariables.SAT_COOPS_SURVEY,
    hasScore: true,
    surveyScoreCard: Object.fromEntries(
      satScoreCardCoop.map((item) => [item.QuestionID, item])
    ),
  },
  "Environmental S.A.T.": {
    sandbox: "a2ZAm000000038yMAA",
    prod: "a2ZAm000000038yMAA",
    configuration: surveyConfigurationVariables.E_SAT_SURVEY,
    hasScore: true,
  },
  AboutMe: {
    sandbox: "a2Z5X00000070FYUAY",
    prod: "a2Z5X00000070FYUAY",
    configuration: surveyConfigurationVariables.ABOUT_ME_SURVEY,
  },
  "Sector Transformation Fund – Sectoral Impact": {
    sandbox: "a2Z5X00000070FsUAI",
    prod: "a2Z5X00000070FsUAI",
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_STF_SI,
  },
  "Sector Transformation Fund – Local Project": {
    sandbox: "a2Z5X00000070FsUAI",
    prod: "a2Z5X00000070FsUAI",
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_STF_LP,
  },
  "Community-Based Tenant Initiative Fund (CBTIF)": {
    sandbox: "a2Z5X00000070FiUAI",
    prod: "a2Z5X00000070FiUAI",
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_CBTI,
  },
  "FCHI-2": {
    sandbox: "a2Z5X00000070FiUAI",
    prod: "a2Z5X00000070FiUAI",
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_FCHI,
  },
  "Final report": {
    configuration: surveyConfigurationVariables.GRANTEE_REPORT_FINAL,
  },
  Interim: {
    configuration: surveyConfigurationVariables.GRANTEE_REPORT_MIDTERM,
  },
};

/**
 * Retrieves the survey score card mapping for the given template ID from the provided configuration.
 * @function
 * @category Salesforce - Survey_Templates__c and Score_Card__c
 * @param {string} templateId The id of the template.
 * @param {objecobject} configuration The configuration object containing mappings.
 * @returns {Object|null} The survey score card mapping if found, otherwise null.
 */
export const templateIdToMapping = (
  templateId: string,
  configuration: object
) => {
  const ret = templateIdToMappingConf(templateId, configuration);
  if (ret) {
    return ret.surveyScoreCard;
  }
  return null;
};

/**
 * Retrieves the survey score card mapping configuration for the given template id from the provided configuration object.
 * @function
 * @category Salesforce - Score_Card__c and Survey_Templates__c
 * @param {string} templateId The id of the template to find in the configuration.
 * @param {Object} configuration The configuration object containing template mappings.
 * @returns {Object|null} The survey score card mapping configuration if found, otherwise null.
 */
export const templateIdToMappingConf = (
  templateId: string,
  configuration: object
) => {
  console.log("templateIdToMapping templateConfig ", templateId, configuration);
  for (const templateConfigName in configuration) {
    if (configuration[templateConfigName] === templateId) {
      // console.log(
      //   'templateIdToMapping templateConfigName ',
      //   templateConfigName,
      //   surveyMapping
      // )
      for (const templateName in surveyMapping) {
        // console.log('templateIdToMapping templateName ', templateName)
        if (
          surveyMapping[templateName].configuration.key === templateConfigName
        ) {
          console.log(
            "templateIdToMapping templateName score card found",
            templateName
          );
          return surveyMapping[templateName];
        }
      }
    }
  }

  return null;
};

/**
 * Checks if the provided template id is configured in the given configuration object based on the search key.
 * @function
 * @category Salesforce - Survey_Template__c, Configuration__c
 * @param {string} templateId The id of the template to check in the configuration.
 * @param {object} configuration The configuration object containing template mappings.
 * @param {string|string[]} searchKey - The key or keys to search for in the configuration.
 * @returns {boolean} True if the template  iids configured with the specified search key(s), false otherwise.
 */
export const isConfiguredTemplate = (
  templateId: string,
  configuration: object,
  searchKey: string | string[]
) => {
  const surveyMapping = templateIdToMappingConf(templateId, configuration);
  if (!surveyMapping) {
    return false;
  }
  if (Array.isArray(searchKey)) {
    for (const key of searchKey) {
      if (surveyMapping.configuration.key === key) {
        return true;
      }
    }
  } else {
    if (surveyMapping.configuration.key === searchKey) {
      return true;
    }
  }
  return false;
};

// no template ID when trying to create survey
export const NO_TEMPLATE = "NO_TEMPLATE";

/**
 * Adds a new survey based on the provided parameters and configuration, using a Salesforce flow.
 * @function
 * @category Salesforce - Survey_Templates__c and Configuration__c
 * @param {string} [contactId=null] The id of the contact associated with the survey (optional).
 * @param {string} [userId=null] The id of the user associated with the survey (optional).
 * @param {string} [opportunityId=null] The id of the opportunity associated with the survey (optional).
 * @param {string} [accountId=null] The id of the account associated with the survey (optional).
 * @param {string} name The name of the survey.
 * @param {boolean} locked Indicates whether the survey is locked or not.
 * @param {string} [reportId=null] The id of the report associated with the survey (optional).
 * @param {string} [surveyTemplate='S.A.T.'] The name of the survey template (optional).
 * @param {string} [templateId=null] The id of the template to use for the survey (optional).
 * @param {object} configuration The configuration object containing template mappings.
 * @returns {FlowResult}
 */
export const addSurveyByFlow = (
  {
    contactId = null,
    userId = null,
    opportunityId = null,
    accountId = null,
    name,
    locked,
    reportId = null,
    surveyTemplate = "S.A.T.",
    templateId = null,
  },
  configuration
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  let surveyTemplateId;
  if (!templateId && surveyMapping[surveyTemplate]) {
    if (surveyMapping[surveyTemplate].configuration && configuration) {
      surveyTemplateId =
        configuration[surveyMapping[surveyTemplate].configuration.key];
    } else {
      if (isSandbox()) {
        surveyTemplateId = surveyMapping[surveyTemplate].sandbox;
      } else {
        surveyTemplateId = surveyMapping[surveyTemplate].prod;
      }
    }
  } else {
    surveyTemplateId = templateId;
  }

  if (!surveyTemplateId) {
    return Promise.reject(NO_TEMPLATE);
  }

  return conn.requestPost("/actions/custom/flow/App_Survey_Create_New", {
    inputs: [
      {
        AccountID: accountId,
        ContactID: contactId,
        UserID: userId,
        OpportunityID: opportunityId,
        SurveyTemplateID: surveyTemplateId,
        ReportId: reportId,
        SurveyName: name,
        Locked: Boolean(locked),
      },
    ],
  });
};

/**
 * Converts the specified user to an external reviewer using a Salesforce flow.
 * @function
 * @category Salesforce - User and External_Reviewer__c
 * @param {string} userId The id of the user to be converted to an external reviewer.
 * @returns {FlowResult}
 */
export const convertToExternalReviewerByFlow = (userId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost(
    "/actions/custom/flow/App_Convert_to_External_Reviewer",
    {
      inputs: [
        {
          userId,
        },
      ],
    }
  );
};

interface TranslationData {
  [langKey: string]: string;
  portalTranslationId: string;
}

interface TranslatedContent {
  value?: string;
  isPortalId?: boolean;
}

/**
 * Retrieves translated content for the specified language key.
 * @function
 * @category Salesforce - SurveyTranslation__c, Portal_Translation__c, and FGM_Portal__Field_Value_Translation__c
 * @param {TranslationData} data The data containing translations.
 * @param {string} langKey The language key to retrieve the translation for.
 * @returns {object} An object containing the translated value and a flag indicating if it's a portal translation id.
 */
export function getTranslatedContent(data: TranslationData, langKey: string) {
  let toRet: TranslatedContent = {};
  let isContent = false;
  const keysToCheck = Object.keys(portalLanguagesData);
  keysToCheck.some((langKey) => {
    if (data[langKey] && data[langKey] !== "") {
      isContent = true;
      return true;
    }
    return false;
  });
  const portalId = data.portalTranslationId;
  toRet.isPortalId = Boolean(portalId && !isContent);
  toRet.value = toRet.isPortalId ? portalId : data[langKey] || "";
  return toRet;
}
