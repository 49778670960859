import SFAuthService from '../../services/sfAuth/SFAuthService'

export const SET_USER_DATA = 'USER_SET_DATA'
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const UPDATE_ORG_INFO = 'UPDATE_ORG_INFO'
export const USER_NOT_MU_AUTHENTICATED = 'USER_NOT_MU_AUTHENTICATED'

export function setUserData (user) {
  console.log('set user Data', user)
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    })
  }
}

export function userNotAutenticatedForMultiuser () {
  return dispatch => {
    dispatch({
      type: USER_NOT_MU_AUTHENTICATED
    })
  }
}

export function logoutUser () {
  return dispatch => {
    SFAuthService.logout().then(res => {
      dispatch({
        type: USER_LOGGED_OUT
      })
      console.log('redirect after logout')
      window.location = '/'
    })

    // todo add working logout redirect
    // history.push({
    //   pathname: '/'
    // })
  }
}
