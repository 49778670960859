import { Grid } from '@material-ui/core'
import Scrollbar from 'react-perfect-scrollbar'

/**
 * Renders two scrollable containers next to each other filling up avaliable space
 * @category Common components
 * @extends TextField
 * @component
 * @returns {JSX.Element}
 * @property {JSX.Element} left Content of left container.
 * @property {JSX.Element} right Content of right container.
 * @property {number} maxHeight Height of the containers.
 */
export const TwoSidedScreen = ({ left, right, maxHeight, ...props }) => {
  return (
    <Grid
      container
      spacing={0}
      justifyContent='space-evenly'
      style={{ flexGrow: 1 }}
      className='two-side-scroll-container'
    >
      <Grid item xs={6}>
        <Scrollbar
          className='scrollable-content'
          style={{ height: maxHeight, flex: 1 }}
          options={{ minScrollbarLength: 4, scrollYMarginOffset: 8 }}
        >
          {left}
        </Scrollbar>
      </Grid>
      <Grid item xs={6}>
        <Scrollbar
          className='scrollable-content '
          style={{ height: maxHeight, flex: 1 }}
          options={{ minScrollbarLength: 4, scrollYMarginOffset: 8 }}
        >
          {right}
        </Scrollbar>
      </Grid>
    </Grid>
  )
}
