import { t, Trans } from '@lingui/macro'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = {
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row'
  },
  firstCol: {
    width: '35%'
  },
  secondCol: {
    width: '30%'
  },
  thirdCol: {
    width: '35%'
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    margin: '2px',
    padding: '10px 20px'
  },
  mainTableCell: {
    fontWeight: 'bold',
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    margin: '2px',
    padding: '10px 20px'
  },
  title: {
    fontSize: 20,
    fontFamily: defaultFormPdfFontFamily,
    marginBottom: 8,
    marginLeft: 8
  }
}

export const FormProjectAffiliationsPdfEditor = ({
  typeProps = {},
  title,
  langFR,
  ...props
}) => {
  const currentLanguage = myI18n.language

  if (currentLanguage === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  const value = [
    {
      name: <Trans>PROJECT_AFFILATIONS_NAME_PLACEHOLDER</Trans>,
      email: <Trans>PROJECT_AFFILATIONS_EMAIL_PLACEHOLDER</Trans>,
      role: <Trans>PROJECT_AFFILATIONS_ROLE_PLACEHOLDER</Trans>
    }
  ]

  return (
    <div style={styles.table}>
      <div style={styles.tableRow} wrap={false}>
        <div style={{ ...styles.tableCol, ...styles.firstCol }}>
          <div style={styles.mainTableCell}>{myI18n._(t`Contact name`)}</div>
        </div>
        <div style={{ ...styles.tableCol, ...styles.secondCol }}>
          <div style={styles.mainTableCell}>{myI18n._(t`Contact e-mail`)}</div>
        </div>
        <div style={{ ...styles.tableCol, ...styles.thirdCol }}>
          <div style={styles.mainTableCell}>{myI18n._(t`Contact role`)}</div>
        </div>
      </div>

      {value.map((contact, index) => (
        <div style={styles.tableRow} wrap={false} key={index}>
          <div style={{ ...styles.tableCol, ...styles.firstCol }}>
            <div style={styles.tableCell}>{contact.name}</div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.secondCol }}>
            <div style={styles.tableCell}>{contact.email}</div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.thirdCol }}>
            <div style={styles.tableCell}>{contact.role}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
