import { Grid, Typography } from '@material-ui/core'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { useField } from 'formik'
import ReactHtmlParser from 'react-html-parser'
import {
  formElementsWithCustomTitleRender,
  formElementsWithoutDefaultTitle,
  formElementsWithoutFieldHistory,
  formUseStyles
} from '../editor/GroupElement'
import { MUFieldComment } from '../multiuser/components/MUFieldComment'
import { MUPreviouslySavedValuesPanel } from '../multiuser/components/MUPreviouslySavedValuesPanel'
import { FormHelptext } from './FormHelpText'

export const FormElementTitle = ({
  tooltip,
  muBag,
  labelsWidth,
  id,
  altLabelPlacement,
  helpText,
  labelAsMarkdown,
  helptextAsMarkdown,
  title,
  type,
  useMultiuser,
  disabled,
  showFieldLabel = false,
  item,
  connectedObject,
  targetFormType = 'editable'
}) => {
  const [field, meta] = useField(id)
  const classes = formUseStyles()
  const isError = Boolean(meta.error && meta.touched)

  const showTitle = Boolean(
    !formElementsWithoutDefaultTitle.includes(type) &&
      !formElementsWithCustomTitleRender.includes(type) &&
      !showFieldLabel
  )

  let width = 0
  if (altLabelPlacement) {
    width = 'initial'
  } else {
    width = '100%'
  }
  if (labelsWidth) {
    width = Number(labelsWidth)
  }

  return (
    <div
      style={{
        width
      }}
    >
      {showTitle && (
        <Grid
          item
          container
          alignItems='center'
          wrap='nowrap'
          style={{
            paddingLeft: 10,
            paddingBottom: useMultiuser ? 0 : 8,
            paddingRight: altLabelPlacement && 10,
            paddingTop: altLabelPlacement && 10,
            textAlign: altLabelPlacement && 'right'
          }}
        >
          <Typography
            style={{
              flex: altLabelPlacement && 1,
              marginRight: 5
            }}
          >
            {targetFormType.includes('pdf') && (
              <div className={classes.root}>
                <Typography className='form-pdf-title'>
                  {labelAsMarkdown ? ReactHtmlParser(title) : title}
                </Typography>
              </div>
            )}
            {targetFormType === 'printable' && (
              <Grid container wrap='nowrap'>
                <div className={classes.root}>
                  <Typography className='form-readonly-title'>
                    {labelAsMarkdown ? ReactHtmlParser(title) : title}
                  </Typography>
                </div>
                {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
              </Grid>
            )}
            {targetFormType === 'editable' && (
              <>
                {labelAsMarkdown ? (
                  <Grid container wrap='nowrap'>
                    <span className={classes.root}>
                      {ReactHtmlParser(title)}
                    </span>
                    {Boolean(isError && title) && (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                    {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                  </Grid>
                ) : (
                  <>
                    <b>{title}</b>
                    {Boolean(isError && title) && (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                    {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                  </>
                )}
              </>
            )}
          </Typography>
          {!formElementsWithoutFieldHistory.includes(type) && (
            <MUPreviouslySavedValuesPanel
              disabled={disabled}
              muBag={muBag}
              fieldId={id}
              type={type}
              connectedObject={connectedObject}
              item={item}
              useMultiuser={useMultiuser}
            />
          )}
          {useMultiuser && <MUFieldComment muBag={muBag} fieldId={id} />}
        </Grid>
      )}
      {helpText && !formElementsWithCustomTitleRender.includes(type) && (
        <FormHelptext helpText={helpText} parseLabel={helptextAsMarkdown} />
      )}
    </div>
  )
}
