/* global mainScript */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// comps
import DropDown from './Dropdown'

//style rules
import { Trans } from '@lingui/macro'
import "./styles/Filters.css";
import Search from './Search'

const Filters = ({
	filters,
	toggleSidePanel,
	handleFilterSelect,
	selectedFilters,
	setSelectedFilters,
	clearAll,
	showClearButton,
	FiltersType,
	pageTitle,
	searchQuery,
	setSearchQuery,
	searchInput,
	setSearchInput,
}) => {
	const isDesktopOrLaptop = window.innerWidth >= 1024

	useEffect(() => {
		if (searchQuery) {
			const toSet = { ...selectedFilters }
			toSet['Search_Query'] = searchQuery
			setSelectedFilters(toSet)
		} else {
			const toSet = { ...selectedFilters }
			delete toSet['Search_Query']
			setSelectedFilters(toSet)
		}
	}, [searchQuery])

	const filtersOptionsMap = {}
	filters.forEach(obj => {
		if (obj.options) {
			filtersOptionsMap[obj.key] = Object.fromEntries(
				obj.options.map(obj => [obj.key, obj])
			)
		}
	})

	let renderedFilters =
		filters &&
		filters
			.filter(obj => obj.multiselect)
			.map(obj => {
				return (
					<DropDown
						handleFilterSelect={handleFilterSelect}
						key={obj.key}
						filterObj={obj}
						//topLevelCategory={topLevelCategory}
						selectedFilters={selectedFilters}
					/>
				)
			})

	//extract the parentFilters to show the selected filters
	let renderedSelected =
		selectedFilters &&
		Object.keys(selectedFilters).map(filterKey => {
			const filterContent = selectedFilters[filterKey]
			if (Array.isArray(filterContent)) {
				return filterContent.map(f => {
					const optionData = filtersOptionsMap[filterKey][f]
					const onClose = () => {
						const toSet = { ...selectedFilters }
						toSet[filterKey] = toSet[filterKey].filter(
							selected => selected !== f
						)
						setSelectedFilters(toSet)
					}

					if(!optionData) return null

					return (
						<div className='selected'
							key={f}
							// keeping for reference
							// onClick={() =>
							// 	handleFilterSelect({
							// 		topLevelCategory: selectedFilter.topLevelCategory,
							// 		parentFilters: [
							// 			{
							// 				title: f.title,
							// 				slug: f.slug,
							// 				childFilters: f.children,
							// 			},
							// 		],
							// 	})
							// }
						>
							{optionData?.label}
							<div className='selectedClose' onClick={onClose} >
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
									<path d="M1 10L0 9L4 5L0 1L1 0L5 4L9 0L10 1L6 5L10 9L9 10L5 6L1 10Z" fill="black" fill-opacity="0.54"/>
								</svg>
							</div>
						</div>
					)
				})
			} else {
				return (
					<div className='selected'>
					<Trans>RESOURCES_INCLUDES_FILTER_PANEL_CHIP</Trans>: {filterContent}
					</div>
				)
			}
		})

	return (
		<div className='filterContainer'>
			<div className='filterContainerTitleAndSearch'>
				<h3 className='h4 filterTitle'>
					{' '}
					{FiltersType === 'projects' ? (
						<Trans>RESOURCES_FIND_PROJECTS_BY_TITLE</Trans>
					) : (
						<Trans>RESOURCES_FILTER_RESOURCES_BY_TITLE</Trans>
					)}
				</h3>

				<Search
					setSearchQuery={setSearchQuery}
					searchQuery={searchQuery}
					searchInput={searchInput}
					setSearchInput={setSearchInput}
					searchPlaceholder={pageTitle}
					FiltersType={'resources'}
				/>
			</div>


			{isDesktopOrLaptop ? (
				<>
					<ul className='filters-wrapper'>
						<div className='renderedFiltersContainer'>
							{renderedFilters}
						</div>
						{/* {window.location.href.includes('resources') ? (
							<MoreFilters className='cta-link' onClick={toggleSidePanel}>
								more FILTERS
							</MoreFilters>
						) : (
							''
						)}
						<ApplyFiltersButton
							disabled={selectedFilters.length === 0}
							onClick={ApplyFilters}
						>
							Apply filters
						</ApplyFiltersButton> */}
					</ul>
					<div className={`selectedFilters ${!showClearButton && "hide"}`}>
						{renderedSelected && renderedSelected}
						{showClearButton && (
							<div className='selected clearSelected' onClick={clearAll}>
								<Trans>RESOURCES_CLEAR_ALL_BUTTON</Trans>
							</div>
						)}
					</div>
				</>
			) : (
				<button className='applyFiltersButton filtersButton' onClick={toggleSidePanel}>
					<Trans>RESOURCES_FILTERS_BUTTON</Trans>
				</button>
			)}
		</div>
	)
}

export default Filters

Filters.propTypes = {
	filters: PropTypes.array,
	toggleSidePanel: PropTypes.func,
	handleFilterSelect: PropTypes.func,
	selectedFilters: PropTypes.object,
	clearAll: PropTypes.func,
	ApplyFilters: PropTypes.func,
	showClearButton: PropTypes.bool,
	FiltersType: PropTypes.object ,
	baseUrl: PropTypes.string,
	urlState: PropTypes.string,
	setUrlState: PropTypes.func
}