import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { dateFormat } from 'app/appSettings'
import { HEADER } from 'app/views/opportunity/opportunityViewComponents'
import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
import { myI18n } from 'translation/I18nConnectedProvider'
import { questionTypes } from '../../views/surveys/QuestionWidget'

export const optionToIndex = option => {
  if (option) {
    try {
      const index = option.split('_')[1]
      return parseInt(index, 10)
    } catch (e) {
      console.log('surveyToRTF option error', option, e)
    }
  }
  // console.warn('wrong option', option)
  return null
}

export const questionToMultilanguageText = (question, answer, otherAnswer) => {
  return {
    fr: questionToText({ question, answer, en: false, otherAnswer }),
    en: questionToText({ question, answer, otherAnswer })
  }
}

export const questionToText = ({
  question,
  answer,
  langVersion = 'en',
  otherAnswer
}) => {
  let answerText, index
  switch (question.type) {
    case questionTypes.SELECT_ONE.id:
      if (answer === 'nonApplicable') {
        answerText = myI18n._(t`Non applicable`)
        break
      }
      index = optionToIndex(answer)
      const option = question.options[index]
      if (!option) {
        return ''
      }
      answerText = index !== null && option.titleValue[langVersion]

      if (otherAnswer) {
        answerText += ' (' + otherAnswer + ')'
      }
      break
    case questionTypes.SELECT_MULTIPLE.id:
      if (Array.isArray(answer)) {
        const multiAnswer = []
        answer.forEach((item, index) => {
          const option = question.options[index]
          if (item && otherAnswer && otherAnswer[index]) {
            multiAnswer.push(
              <li key={index}>
                {option.titleValue[langVersion]}: ({otherAnswer[index]})
              </li>
            )
          } else if (item) {
            multiAnswer.push(
              <li key={index}>{option.titleValue[langVersion]}</li>
            )
          }
        })
        answerText = <ul>{multiAnswer}</ul>
      } else {
        answerText = answer
      }
      break
    case questionTypes.SELECT_YESNO.id:
      index = optionToIndex(answer)
      answerText = index !== null && (
        <Trans id={question.options[index].text.id} />
      )
      break
    case questionTypes.INPUT_DATE.id:
      answerText = <p>{answer && moment.utc(answer).format(dateFormat)}</p>
      break
    case questionTypes.INPUT_DATE_RANGE.id:
      answerText = (
        <p>
          {answer.start && (
            <p>Start: {moment.utc(answer.start).format(dateFormat)}</p>
          )}
          {answer.end && (
            <p>End: {moment.utc(answer.end).format(dateFormat)}</p>
          )}
        </p>
      )
      break
    default:
      if (!otherAnswer) {
        answerText = answer
      } else {
        answerText = (
          <p>
            {answer}
            {' (' + otherAnswer + ')'}
          </p>
        )
      }
  }
  return answerText
}

export const surveyToPrint = (survey, template, disableIDs, user) => {
  let answer, answerText, groupAnswers, otherAnswer
  let sections = 0
  const print = []

  for (const section of template) {
    const notAllDisabled = section.questions.some(question => {
      return (
        !disableIDs.includes(question.id) &&
        question.type !== HEADER &&
        !question.skipInPrint
      )
    })
    const unavaliable =
      section.questions.length === 0 ||
      disableIDs.includes(section.templateId) ||
      !notAllDisabled

    if (!unavaliable) {
      print.push(
        <div
          key={section.templateId}
          className='sectionHeader'
          style={{ pageBreakBefore: sections === 0 && 'inherit' }}
        >
          <b>
            <h3>{section.header}</h3>
          </b>
        </div>
      )
      sections++
      for (const question of section.questions) {
        const displayQuestion =
          !disableIDs.includes(question.id) && !question.skipInPrint
        otherAnswer = survey[question.id + '_other']
        if (question.type === questionTypes.HEADER.id && displayQuestion) {
          print.push(
            <div className='questionHeader' key={question.id}>
              <h3 style={{ fontSize: 21 }}>{question.text}</h3>
            </div>
          )
        } else if (question.isGroup && displayQuestion) {
          groupAnswers = []
          for (const groupQuestion of question.groupQuestions) {
            answer = survey[question.id][groupQuestion.index]
            let othersubAnswer
            if (otherAnswer) {
              othersubAnswer = otherAnswer[groupQuestion.index]
            }
            answerText = questionToText({
              question,
              answer,
              langVersion: user.language,
              otherAnswer: othersubAnswer
            })
            groupAnswers.push(
              <li key={groupQuestion.index}>
                <b>{groupQuestion.titleValue[user.language]}</b> {answerText}
              </li>
            )
          }
          print.push(
            <div className='question' key={question.id}>
              <li>
                <h6 style={{ fontStyle: 'italic' }}>{question.text}</h6>
                <ul>{groupAnswers}</ul>
                <br />
              </li>
            </div>
          )
        } else if (displayQuestion) {
          answer = survey[question.id]
          answerText = questionToText({
            question,
            answer,
            langVersion: user.language,
            otherAnswer
          })
          print.push(
            <div className='question' key={question.id}>
              <li>
                <h6 style={{ fontStyle: 'italic' }}>{question.text}</h6>
                <div style={{ marginBottom: 8 }}>{answerText}</div>
              </li>
            </div>
          )
        }
      }
    }
  }
  const ret = (
    <div>
      <ol>{print}</ol>
    </div>
  )
  return ret
}

export const surveyToRTF = (
  survey,
  template,
  disableIDs,
  useRawAnswers = false
) => {
  console.log('surveyToRTF', survey, template)
  let answer, answerText, groupAnswers, otherAnswer
  const rtf = []
  for (const section of template) {
    const notAllDisabled = section.questions.some(question => {
      return !disableIDs.includes(question.id) && question.type !== HEADER
    })
    const unavaliable =
      section.questions.length === 0 ||
      disableIDs.includes(section.templateId) ||
      !notAllDisabled

    if (!unavaliable) {
      rtf.push(
        <div key={section.templateId}>
          <b>
            <h3>{section.headerEN}</h3>
          </b>
        </div>
      )
      for (const question of section.questions) {
        const textEn = question.titleValue.en
        otherAnswer = survey[question.id + '_other']
        if (question.type === questionTypes.HEADER.id) {
          if (!disableIDs.includes(question.id)) {
            rtf.push(
              <div key={question.id}>
                <h3>{textEn}</h3>
              </div>
            )
          }
        } else if (question.isGroup) {
          if (!disableIDs.includes(question.id)) {
            groupAnswers = []
            for (const groupQuestion of question.groupQuestions) {
              if (survey[question.id]) {
                // answer = survey[`${question.id}.${groupQuestion.index}`]
                answer = survey[question.id][groupQuestion.index]
                let othersubAnswer
                if (otherAnswer) {
                  othersubAnswer = otherAnswer[groupQuestion.index]
                }
                answerText = questionToText({
                  question,
                  answer,
                  otherAnswer: othersubAnswer
                })
                groupAnswers.push(
                  <li key={groupQuestion.index}>
                    <b>{groupQuestion.titleValue.en}</b>{' '}
                    {useRawAnswers ? answer : answerText}
                  </li>
                )
              }
            }
            rtf.push(
              <li key={question.id}>
                <h6>
                  <i>{textEn}</i>
                </h6>
                <p>
                  <ul>{groupAnswers}</ul>
                </p>
                <br />
              </li>
            )
          }
        } else if (question.type === questionTypes.INPUT_DATE_RANGE.id) {
          answer = survey[question.id]
          answerText = questionToText({ question, answer, otherAnswer })
          rtf.push(
            <li key={question.id}>
              <h6>
                <i>{textEn}</i>
              </h6>
              <p>Start: {moment.utc(answerText.start).format(dateFormat)}</p>
              <p>End: {moment.utc(answerText.end).format(dateFormat)}</p>
            </li>
          )
        } else if (question.type === questionTypes.INPUT_DATE.id) {
          answer = survey[question.id]
          answerText = questionToText({ question, answer, otherAnswer })
          rtf.push(
            <li key={question.id}>
              <h6>
                <i>{textEn}</i>
              </h6>
              <p>{moment.utc(answerText).format(dateFormat)}</p>
            </li>
          )
        } else {
          if (!disableIDs.includes(question.id)) {
            answer = survey[question.id]
            answerText = questionToText({ question, answer, otherAnswer })
            rtf.push(
              <li key={question.id}>
                <h6>
                  <i>{textEn}</i>
                </h6>
                <p>{useRawAnswers ? answer : answerText}</p>
              </li>
            )
          }
        }
      }
    }
  }

  const ret = ReactDOMServer.renderToString(
    <div style={{ color: 'black' }}>
      <ol>{rtf}</ol>
    </div>
  )
  console.log('stringified RTF', ret)
  return ret
}
