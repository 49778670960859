import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { NumberFormatDefault } from '../../common/Common'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { validFieldTypesForTextFieldNumeric } from '../../editor/FormWizard'
import DebouncedTextField from '../../common/DebouncedTextField'

export function FormEditorNumericSlider ({
  typeProps = {},
  langVersion,
  injectable,
  showPrintProps,
  depth,
  editMode,
  title,
  ...props
}) {
  const dispatch = useDispatch()
  const { required, max, min, step } = typeProps

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />

      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <DebouncedTextField
            label={<Trans>Slider step</Trans>}
            value={step || '1'}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.step = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>

        <Grid item xs style={{ padding: 5 }}>
          <DebouncedTextField
            label={<Trans>Min value</Trans>}
            value={min || ''}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.min = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <DebouncedTextField
            label={<Trans>Max value</Trans>}
            value={max || ''}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.max = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        injectable={injectable}
      />
    </Grid>
  )
}
