import { Trans } from '@lingui/macro'
import { Divider, Grid, Paper, Typography } from '@material-ui/core'

export const FormAffiliatedOrganizationsPrint = ({ title, value = [] }) => {
  return (
    <div style={{ flex: 1 }}>
      <b>
        <div
          style={{
            fontSize: 21,
            width: '100%',
            color: '#757575',
            textAlign: 'center'
          }}
        >
          {title}
        </div>
      </b>
      <Paper elevation={6} style={{ marginTop: 10, padding: 15 }}>
        {Boolean(value.length === 0) ? (
          <Typography>
            <Trans>This list is empty!</Trans>
          </Typography>
        ) : (
          <>
            <Grid container>
              <Grid item xs style={{ padding: 4 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Name</Trans>
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Email</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {value.map(obj => {
              const { name, email } = obj
              return (
                <Grid container>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      {email}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
          </>
        )}
      </Paper>
    </div>
  )
}
