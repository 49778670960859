import { CommonPlaceholder } from '../CommonPlaceholder'

export function FormTextInputPrintEditor ({
  typeProps,
  mainConnected,
  ...props
}) {
  return (
    <CommonPlaceholder
      mainConnected={mainConnected}
      showPdfComponent={false}
      typeProps={typeProps}
      {...props}
    />
  )
}
