import { checkFormValidity } from "app/views/forms/FormHelpersValidation";
import { constructFormAddressString } from "app/views/forms/common/Common";
import moment from "moment";
import SFAuthService, { NO_USER } from "../SFAuthService";
import sfOauthConfig from "../sfAuthConfig";
import { mapSFToForm } from "../sfDataService";
import { saveContactByFlowParsed } from "./sfContact";
import { getConfiguration, getFormPage } from "./sfForms";
import { getJoinRequests } from "./sfJoinRequest";
import { saveUserParsed } from "./sfUser";

export const NO_FOCUS_DEMOGRAPHIC = "No focus demographic";

const mapFields = {
  direction: "in",
  Id: "id",
  Form_Page__c: "formPageId",
  LastModifiedDate: "lastModifiedDate",
  Name: "organisationsName",
  Facebook__c: "facebook",
  Website: "website",
  BillingState: "province",
  BillingStreet: "street",
  BillingPostalCode: "postalCode",
  Focus_demographic__c: {
    key: "focusDemographic",
    in: (opp) => {
      if (opp.Focus_demographic__c === "No focus demographic") {
        return [];
      }
      return opp.Focus_demographic__c
        ? opp.Focus_demographic__c.split(";")
        : [];
    },
    out: (res) => {
      if (res.hasFocusDemographic === "No") {
        return "No focus demographic";
      }
      return res.focusDemographic.join(";");
    },
  },
  Additional_Features__c: {
    key: "additionalFeatures",
    in: (opp) =>
      opp.Additional_Features__c ? opp.Additional_Features__c.split(";") : [],
    out: (res) => {
      return res.additionalFeatures.join(";");
    },
  },
  AccountTeamMembers: {
    key: "members",
    in: (acc) => (acc.AccountTeamMembers ? acc.AccountTeamMembers.records : []),
  },
  Account_Type__c: {
    key: "organisationType",
    in: (opp) => (opp.Account_Type__c ? opp.Account_Type__c.split(";") : []),
    out: (res) => {
      return res.organisationType.join(";");
    },
  },
  OwnerId: "ownerId",
  Phone: "phone",
  Email__c: "email",
  LinkedIn__c: "linkedIn",
  NumberOfEmployees: "employees",
  Total_yearly_budget__c: "yearlyBudget",
  npo02__NumberOfMembershipOpps__c: "numberOfMembers",
  Main_funding_sources__c: "mainFundingSource",
  Number_of_tenants_living_in_your_buildin__c: "numberOfTenants",
  Housing_units_managed_by_Organisation__c: "units",
  Mission_Statement__c: "missionStatement",
  Number_of_member_organizations__c: "numberOfOrganizations",
  Indigenous_Organization__c: {
    key: "indigenousAffiliation",
    in: (opp) =>
      opp.Indigenous_Organization__c
        ? opp.Indigenous_Organization__c.split(";")
        : [],
    out: (res) => res.indigenousAffiliation.join(";"),
  },
  Demography__c: {
    key: "typeOfPopulation",
    in: (opp) => (opp.Demography__c ? opp.Demography__c.split(";") : []),
    out: (res) => res.typeOfPopulation.join(";"),
  },
};

const mapFieldsICCE = {
  direction: "in",
  Id: "id",
  Name: "organisationsName",
  Instagram__c: "instagram",
  Twitter__c: "twitter",
  Facebook__c: "facebook",
  Website: "website",
  BillingStreet: "street",
  BillingPostalCode: "postalCode",
  Focus_demographic__c: {
    key: "focusDemographic",
    in: (opp) => {
      if (opp.Focus_demographic__c === "No focus demographic") {
        return [];
      }
      return opp.Focus_demographic__c
        ? opp.Focus_demographic__c.split(";")
        : [];
    },
    out: (res) => {
      if (res.hasFocusDemographic === "No") {
        return "No focus demographic";
      }
      return res.focusDemographic.join(";");
    },
  },
  AccountTeamMembers: {
    key: "members",
    in: (acc) => (acc.AccountTeamMembers ? acc.AccountTeamMembers.records : []),
  },
  Account_Type__c: {
    key: "organisationType",
    in: (opp) => (opp.Account_Type__c ? opp.Account_Type__c.split(";") : []),
    out: (res) => {
      return res.organisationType.join(";");
    },
  },
  OwnerId: "ownerId",
  Phone: "phone",
  Email__c: "email",
  LinkedIn__c: "linkedIn",
  NumberOfEmployees: "employees",
  Total_yearly_budget__c: "yearlyBudget",
  npo02__NumberOfMembershipOpps__c: "numberOfMembers",
  Main_funding_sources__c: "mainFundingSource",
  Number_of_tenants_living_in_your_buildin__c: "numberOfTenants",
  Housing_units_managed_by_Organisation__c: "units",
  Mission_Statement__c: "missionStatement",
  Number_of_member_organizations__c: "numberOfOrganizations",
  Indigenous_Organization__c: {
    key: "indigenousAffiliation",
    in: (opp) =>
      opp.Indigenous_Organization__c
        ? opp.Indigenous_Organization__c.split(";")
        : [],
    out: (res) => res.indigenousAffiliation.join(";"),
  },
  Demography__c: {
    key: "typeOfPopulation",
    in: (opp) => (opp.Demography__c ? opp.Demography__c.split(";") : []),
    out: (res) => res.typeOfPopulation.join(";"),
  },
};

const ORGANIZATION_VALIDITY_ERROR = "ORGANIZATION_VALIDITY_ERROR";

export const addAccountContactAffiliation = (toCreate) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost(
      "/actions/custom/flow/App_Create_Account_Affiliation",
      {
        inputs: [{ ...toCreate }],
      }
    );
  } else {
    return Promise.reject(NO_USER);
  }
};

export const setAcountAffiliationToFormer = (affiliationId) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost(
      "/actions/custom/flow/App_Set_Account_Affiliation_To_Former",
      {
        inputs: [{ affiliationId }],
      }
    );
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Checks if organization with probided id filled out all of the required details on the Organizations Details page.
 * @function
 * @category Salesforce - Account
 * @param {string} id Id of the Account object.
 * @param {string} pathname Pathname of the current page.
 * @returns {boolean} If organizations details are valid
 */
export const checkOrganizationDetailsValidity = (id: string, pathname: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return getConfiguration({
    Name: "FORM_ORGANIZATION_DETAILS",
  }).then((config) => {
    if (config.Value__c) {
      return getFormPage(config.Value__c).then((form) => {
        if (!form) {
          return ORGANIZATION_VALIDITY_ERROR;
        }
        return checkFormValidity({
          formId: form.id,
          id: constructFormAddressString({
            ids: {
              Account: id,
            },
            objectsConnected: form.objectsConnected,
          }),
          pathname
        });
      });
    } else {
      return ORGANIZATION_VALIDITY_ERROR;
    }
  });
};

/**
 * Returns Account object by probided name
 * @function
 * @category Salesforce - Account
 * @param {string} name Name of the Account object.
 * @returns {Account} Account Salesforce record.
 */
export const getAccountByNameByFlow = (name: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_Account_by_Name", {
      inputs: [
        {
          accountName: name,
        },
      ],
    })
    .then((result) => {
      const ret = result[0].outputValues.resultIDs;
      if (ret) {
        return ret;
      } else {
        return [];
      }
    });
};

/**
 * Returns account team members for Account with provided id.
 * @function
 * @category Salesforce - Account
 * @param {string} id Id of the Account object.
 * @returns {AccountTeamMember[]} AccountTeamMember Salesforce records.
 */
export const getAccountTeamMembers = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("AccountTeamMember")
    .find({
      AccountId: id,
    })
    .select(
      "UserId, Id, AccountAccessLevel, TeamMemberRole, User.Email, User.Name"
    );
};

interface GetAccountProps {
  permissions?: {
    TEAM_MEMBERS?: any;
  };
}

/**
 * Returns Account for provided id.
 * @function
 * @category Salesforce - Account
 * @param {string} id Id of the Account object.
 * @param {object} props Query props.
 * @param {object} [props.permissions] Additional subqueriers that should be fetched - identified by keys provided to the object.
 * @returns {Account} Account Salesforce records.
 */
export const getAccount = (id, props: GetAccountProps = {}) => {
  const conn = SFAuthService.getConnection();
  const { permissions = {} } = props;
  let retPromise = conn.sobject("Account").find({
    Id: id,
  });
  if (permissions.TEAM_MEMBERS) {
    retPromise = retPromise
      .include("AccountTeamMembers")
      .select(
        "UserId, Id, AccountAccessLevel, TeamMemberRole, User.Email, User.Name"
      )
      .end();
  }
  if (conn) {
    return retPromise;
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Returns Accounts mapped by theid Id for provided ids.
 * @function
 * @category Salesforce - Account
 * @param {string[]} ids Ids of the Account objects.
 * @returns {object} Object containing Accounts mapped by Id.
 */
export const getAccountsMap = (ids: string[] = []) => {
  const conn = SFAuthService.getConnection();
  if (ids.length === 0) {
    return Promise.resolve([]);
  }
  return conn
    .sobject("Account")
    .find({
      Id: { $in: ids },
    })
    .then((accounts) => {
      const toRet = {};
      accounts.forEach((acc) => {
        toRet[acc.Id] = acc;
      });
      return toRet;
    });
};

interface GetOrganizationProps {
  searchString?: string;
  index?: number;
  recordsPerPage?: number;
  sorting?: {
    name: string;
    direction: "ASC" | "DESC";
  };
  fields?: string[];
}

/**
 * @typedef OrganizationParsedWithPaginationResult
 * @property {number} totalCount - Total cound of records in database.
 * @property {Account[]} records - Account records returned from the query.
 * @type {object}
 */
/**
 * Returns Account salesforce records for provided parameters with pagination in UserOrganizations component.
 * @function
 * @category Salesforce - Account
 * @param {GetOrganizationProps} props Query props.
 * @param {object} [props.searchString] String by which search query will be performed.
 * @param {object} [props.index] Index of the page to be fetched.
 * @param {object} [props.recordsPerPage] Number of records to be fetched.
 * @param {object} [props.sorting] Sorting object.
 * @param {object} [props.fields] Array of fiels that should be returned from the query.
 * @returns {OrganizationParsedWithPaginationResult} Account Salesforce records and total count of records.
 */
export const getOrganizationParsedWithPagination = ({
  searchString,
  index = 0,
  recordsPerPage = 30,
  sorting,
  fields,
}: GetOrganizationProps) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const sort = {
    field: "Id",
    direction: "ASC",
  };
  const fieldsMap = {
    name: "Name",
    stale: "LastModifiedDate",
  };
  if (sorting && Object.keys(sorting).length > 0) {
    sort.direction = String(sorting.direction).toUpperCase();
    sort.field = fieldsMap[sorting.name];
  }

  const selectString = fields?.join(", ");
  const whereString = `WHERE Name LIKE '%${
    searchString || ""
  }%' AND RecordType.Name != 'Household Account'`;

  const query = `SELECT ${selectString} FROM Account ${whereString} ORDER BY ${
    sort.field
  } ${sort.direction} NULLS LAST LIMIT ${recordsPerPage} OFFSET ${
    index * recordsPerPage
  }`;

  return Promise.all([
    conn.query(query),
    conn.query(`SELECT COUNT(Id) FROM Account ${whereString}`),
  ]).then(
    ([result, countResult]) => {
      return {
        records: result.records,
        totalCount: countResult.records[0].expr0,
      };
    },
    (reject) => {
      console.log(reject);
      Promise.reject(reject);
    }
  );
};

interface GetOrganizationsProps {
  permissions?: {
    [key: string]: any;
  };
  fields?: string[];
  searchFields?: {
    [key: string]: any;
  };
}

/**
 * Returns Account salesforce records for provided parameters.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {object} [props.permissions] Additional subqueriers that should be fetched - identified by keys provided to the object (optional).
 * @param {object} [props.fields] Array of fiels that should be returned from the query (optional).
 * @param {object} props.searchFields Object by which search query will be performed.
 * @returns {Account[]} Account Salesforce records.
 */
export const getOrganizations = (props: GetOrganizationsProps = {}) => {
  const conn = SFAuthService.getConnection();
  const {
    permissions = {},
    fields = ["Id", "Name"],
    searchFields = {},
  } = props;
  if (conn) {
    let retPromise = conn
      .sobject("Account")
      .find(searchFields)
      .select(fields.join(", "));
    if (permissions.TEAM_MEMBERS_FULL) {
      retPromise = retPromise
        .include("AccountTeamMembers")
        .select(
          "UserId, Id, Title__c, AccountAccessLevel, TeamMemberRole, User.Email, User.Name, User.FGM_Portal__UserEmail__c, User.ContactId, User.Contact.*"
        )
        .end();
    }
    retPromise = retPromise.autoFetch(true);
    return retPromise;
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Returns parsed Account salesforce records for provided parameters.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {object} [props.permissions] Additional subqueriers that should be fetched - identified by keys provided to the object.
 * @param {object} [props.fields] Array of fiels that should be returned from the query.
 * @param {object} props.searchFields Object by which search query will be performed.
 * @returns {Account[]} Account Salesforce records.
 */
export const getOrganizationsParsed = (props: {
  permissions: any;
  fields: string[];
  searchFields: any;
}) => {
  const conn = SFAuthService.getConnection();
  const {
    permissions = {},
    fields = ["Id", "Name"],
    searchFields = {},
  } = props || {};
  if (conn) {
    let retPromise = conn
      .sobject("Account")
      .find(searchFields)
      .select(fields.join(", "));
    if (permissions.TEAM_MEMBERS_FULL) {
      retPromise = retPromise
        .include("AccountTeamMembers")
        .select(
          "UserId, Id, Title__c, AccountAccessLevel, TeamMemberRole, User.Email, User.Name, User.FGM_Portal__UserEmail__c, User.ContactId, User.Contact.*"
        )
        .end();
    }
    retPromise = retPromise.autoFetch(true);
    return retPromise.then((result) =>
      result.map((obj) => mapSFToForm(mapFields, obj))
    );
  } else {
    return Promise.reject(NO_USER);
  }
};

interface SFUser {
  id: string;
  contactId: string;
}

interface CreateOrganizationByFlowProps {
  displayName: string;
  organizationName: string;
  email: string;
  jobTitle: string;
}

/**
 * Creates new Account salesforce record for user as well as manager AccountTeamMember record for them in a newly created organization.
 * @function
 * @category Salesforce - Account
 * @param {SFUser} sfUser User object from redux.
 * @param {CreateOrganizationByFlowProps} props Query props.
 * @param {string} props.displayName Users name.
 * @param {string} props.organizationName Name of the created organization.
 * @param {string} props.email Users email.
 * @param {string} props.jobTitle Users job title.
 * @returns {Id} Id of newly created Account record.
 */
export const createOrganizationByFlow = (
  sfUser: SFUser,
  {
    displayName,
    organizationName,
    email,
    jobTitle,
  }: CreateOrganizationByFlowProps
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const promise = saveAccountByFlow({
    Name: organizationName || "new Organization for " + displayName,
    Email__c: email,
  }).then((account) => {
    return Promise.all([
      saveUserParsed(sfUser),
      conn.requestPost("/actions/custom/flow/App_Team_Create_member", {
        inputs: [
          {
            title: jobTitle,
            orgId: account.id,
            userId: sfUser.id,
            contactId: sfUser.contactId,
            toCreate: "Manager",
          },
        ],
      }),
    ]).then(([saveUser, flowResult]) => account.id);
  });
  if (sfUser.contactId) {
    return promise.then((associatedOrganizations) => {
      return saveContactByFlowParsed({
        id: sfUser.contactId,
        associatedOrganizations,
      }).then(() => associatedOrganizations);
    });
  } else {
    return promise;
  }
};

/**
 * Returns  Join Requests salesforce records for organizations the user is manager in.
 * @function
 * @category Salesforce - Account
 * @param {string} userId Id of the user.
 * @returns {Join_Request__c[]} Account Salesforce records.
 */
export const getUsersOrganizationsJoinRequests = (userId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return getOrganizations({
    permissions: {
      TEAM_MEMBERS_FULL: true,
    },
    fields: ["Id"],
  }).then((orgs) => {
    const list = orgs
      .filter(function (acc) {
        let currentUserTeamMemberRole;
        if (acc.AccountTeamMembers) {
          const thisUserAtm = acc.AccountTeamMembers.records.filter(
            (atm) => atm.UserId === userId
          );
          if (thisUserAtm && thisUserAtm.length === 1 && thisUserAtm[0]) {
            currentUserTeamMemberRole = thisUserAtm[0].TeamMemberRole;
          }
          return currentUserTeamMemberRole === "Manager";
        } else {
          return false;
        }
      })
      .map((org) => org.Id);
    if (list.length === 0) {
      return [];
    }
    return getJoinRequests(list, true);
  });
};

interface AccountSearchObject {
  name?: string | null;
  email?: string | null;
  website?: string | null;
  phone?: string | null;
}

export const TOO_MANY_RECORDS_FOUND_ERROR = "TOO_MANY_RECORDS_FOUND_ERROR";
export const NO_ORGANIZATION_FOUND_ERROR = "NO_ORGANIZATION_FOUND_ERROR";

/**
 * Searches for Account record by provided props.
 * @function
 * @category Salesforce - Account
 * @param {AccountSearchObject} searchObj Values the search query will use.
 * @param {string} searchObj.name Name of the Account.
 * @param {string} searchObj.email Email of the Account.
 * @param {string} searchObj.website Website of the Account.
 * @param {string} searchObj.phone Phone of the Account.
 * @returns {Account|string} Account record found or string with error prompting to change search input.
 */
export const searchForAccountByFlow = (searchObj: AccountSearchObject) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (
    searchObj.name === "" &&
    searchObj.email === "" &&
    searchObj.website === "" &&
    searchObj.phone === ""
  ) {
    return Promise.resolve().then((result) => {
      return "Cannot search by empty record!";
    });
  }

  const matchMapping = [
    {
      sf: "Name",
      key: "name",
    },
    { sf: "Email__c", key: "email" },
    { sf: "Phone", key: "phone" },
    { sf: "Website", key: "website" },
  ];

  return conn
    .requestPost("/actions/custom/flow/App_Find_Account", {
      inputs: [
        {
          name: searchObj.name !== "" ? searchObj.name : null,
          email: searchObj.email !== "" ? searchObj.email : null,
          website: searchObj.website !== "" ? searchObj.website : null,
          phone: searchObj.phone !== "" ? searchObj.phone : null,
        },
      ],
    })
    .then((result) => {
      let records = [];
      const { error, resultsCount, orgList } = result[0].outputValues;
      if (resultsCount > 0) {
        records = orgList.filter(
          (obj) => !String(obj.Name).includes("(duplicate)")
        );
      }
      if (error === "TOO_MANY_FLOW_FAILS") {
        return TOO_MANY_RECORDS_FOUND_ERROR;
      }
      if (records.length === 0) {
        return NO_ORGANIZATION_FOUND_ERROR;
      } else if (records.length > 0 && error === "TOO_MANY") {
        // Try to reduce records list by sctrict compare
        let strictRecords = records.filter((obj) => {
          let passed = false;
          matchMapping.forEach((mapObj) => {
            if (
              searchObj[mapObj.key] &&
              searchObj[mapObj.key] === obj[mapObj.sf]
            ) {
              passed = true;
            }
          });
          return passed;
        });
        // If there are no strict matches, but a lot of close matches then we don't have a good data set to return
        if (strictRecords.length === 0) {
          return TOO_MANY_RECORDS_FOUND_ERROR;
        }
        // If there are strict matches we sort them by number of inputs matched, prioritizing name
        return strictRecords.sort((a, b) => {
          let aHists = 0;
          let bHits = 0;
          matchMapping.forEach((mapObj) => {
            if (searchObj[mapObj.key]) {
              if (searchObj[mapObj.key] === a[mapObj.sf]) {
                mapObj.key === "name" ? (aHists += 3) : aHists++;
              }
              if (searchObj[mapObj.key] === b[mapObj.sf]) {
                mapObj.key === "name" ? (bHits += 3) : bHits++;
              }
            }
          });
          return aHists - bHits;
        });
      } else {
        return records;
      }
    });
};

/**
 * Searches for Account record by provided props.
 * This function is different from searchForAccountByFlow function because this one does not validate input and handle output.
 * @function
 * @category Salesforce - Account
 * @param {object} searchObj Values the search query will use.
 * @param {string} searchObj.name Name of the Account.
 * @param {string} searchObj.email Email of the Account.
 * @param {string} searchObj.website Website of the Account.
 * @param {number} searchObj.phone Phone of the Account.
 * @returns {Account|string} Account record found or string with error prompting to change search input.
 */
export const searchForAccountRawByFlow = (searchObj: AccountSearchObject) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_Account", {
      inputs: [
        {
          name: searchObj.name ? searchObj.name : null,
          email: searchObj.email ? searchObj.email : null,
          website: searchObj.website ? searchObj.website : null,
          phone: searchObj.phone ? searchObj.phone : null,
        },
      ],
    })
    .then((result) => {
      let records = [];
      if (result[0].outputValues.resultsCount > 0) {
        records = result[0].outputValues.orgList;
      }
      return records;
    });
};

/**
 * Checks all organizations the user belongs to if their data wasn't updated in more than a year.
 * @function
 * @category Salesforce - Account
 * @param {string} userId Id of the user.
 * @param {string} [orgNowSaved] If this function was called after updating the organization - Id of the Account that was just saved.
 * @returns {Id[]} Ids of organizations that were not updated recently.
 */
export const checkIfOrganizationsAreStale = (
  userId: string,
  orgNowSaved: string | null = null
) => {
  return getOrganizations({
    permissions: {
      TEAM_MEMBERS_FULL: true,
    },
    fields: ["Id", "LastModifiedDate"],
  }).then((result) => {
    const list = result.filter(function (acc) {
      if (acc.AccountTeamMembers) {
        let isMember = false;
        acc.AccountTeamMembers.records.forEach((member) => {
          if (member.UserId === userId) {
            isMember = true;
          }
        });
        return isMember;
      } else {
        return false;
      }
    });
    const staleIds = list
      .map((org) => {
        const diff = moment
          .utc()
          .diff(moment.utc(org.LastModifiedDate), "months");
        if (orgNowSaved && orgNowSaved === org.Id) {
          return false;
        }
        return diff > 11 ? org.Id : false;
      })
      .filter((p) => p);
    return staleIds;
  });
};

/**
 * Changes the Account Team Member role through flow.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {string} props.roleName Role API name.
 * @param {string} props.userId User id of the team member.
 * @param {string} props.accountId Id of the account the team member belong to.
 * @returns {FlowResult}
 */
export const changeAccountTeamMemberRoleByFlow = ({
  roleName,
  userId,
  accountId,
}: {
  roleName: string;
  userId: string;
  accountId: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Team_Edit_member", {
      inputs: [
        {
          roleName,
          accountId,
          userId,
        },
      ],
    });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Deletes the Account Team Member record through flow, removing user from organization.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {string} props.userId User id of the team member.
 * @param {string} props.accountId Id of the account the team member belong to.
 * @returns {FlowResult}
 */
export const removeMemberFromOrganizationByFlow = ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Team_Remove_member", {
      inputs: [
        {
          accountId: accountId,
          userId: userId,
        },
      ],
    });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Deletes the Account Team Member record through flow, removing user from organization.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {string} props.userId User id of user being added to team.
 * @param {string} props.accountId Id of the account the user is added to.
 * @param {string} props.role Role of member being created.
 * @param {string} props.userMail Email of member being created.
 * @param {string} props.jobTitle Job title of member being created.
 * @returns {FlowResult}
 */
export const addNewMemberToOrganizationByFlow = ({
  userId,
  accountId,
  role,
  userMail,
  jobTitle,
}: {
  userId: string;
  accountId: string;
  role: string;
  userMail: string;
  jobTitle: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost("/actions/custom/flow/App_Team_Add_member", {
    inputs: [
      {
        jobTitle: jobTitle,
        accountId: accountId,
        userId: userId,
        roleName: role || "Partner",
        userEmail: userMail,
      },
    ],
  });
};

/**
 * Creates a join request for an organization for user through Flow.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {string} props.userId User id of user requesting joining.
 * @param {string} props.accountId Id of the account the user requests joining.
 * @param {string} props.jobTitle Job title of the user.
 * @returns {FlowResult}
 */
export const askToJoinOrganizationByFlow = ({
  userId,
  jobTitle,
  orgId,
}: {
  userId: string;
  jobTitle: string;
  orgId: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Team_Ask_to_join", {
      inputs: [
        {
          accountId: orgId,
          userId,
          jobTitle,
        },
      ],
    });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Returns Affiliations records for the organization by its Id through flow.
 * @function
 * @category Salesforce - Account
 * @param {object} props Query props.
 * @param {string} props.accountId Id of the organization.
 * @returns {Affiliation[]}
 */
export const getAccountAffiliationsByFlow = ({
  accountId,
}: {
  accountId: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn
      .requestPost(
        "/actions/custom/flow/App_Account_Affiliations_and_Contacts",
        {
          inputs: [{ accountId }],
        }
      )
      .then((flowResult) => {
        const { contacts, accountAffiliations } = flowResult[0].outputValues;
        if (!accountAffiliations) {
          return [];
        }
        const contactsMap = {};
        contacts &&
          contacts.forEach((contact) => {
            contactsMap[contact.Id] = contact;
          });
        accountAffiliations.forEach((aff) => {
          aff.npe5__Contact__c = contactsMap[aff.npe5__Contact__c];
        });
        return accountAffiliations || [];
      });
  } else {
    return Promise.reject(NO_USER);
  }
};

export const ERROR_DUPLICATE_NAME = "DUPLICATE_NAME_DETECTED";

interface SaveAccountProps {
  Name: string;
  Id?: string;
  Email__c: string;
}
/**
 * Saves Account for provided query props or creates a new one if no Id was provided in query props. Will return an error if another Account with the same name already exists
 * @function
 * @category Salesforce - Account
 * @param {SaveAccountProps} props Query props.
 * @param {string} props.accountId Id of the organization.
 * @returns {JSForceResult}
 */
export const saveAccountByFlow = (props: SaveAccountProps) => {
  const conn = SFAuthService.getConnection();
  return conn
    .requestPost("/actions/custom/flow/App_Find_Account_by_Name", {
      inputs: [
        {
          accountName: props.Name,
          accountId: props.Id,
        },
      ],
    })
    .then((result) => {
      const dupliates = result[0].outputValues.result;
      if (dupliates > 0) {
        return Promise.reject(new Error(ERROR_DUPLICATE_NAME));
      } else {
        if (props.Id) {
          return conn.sobject("Account").update(props);
        } else {
          return conn.sobject("Account").create(props);
        }
      }
    });
};

interface Member {
  UserId: string;
  TeamMemberRole: string;
}
/**
 * Returs user Account Member role from list of organization members.
 * @function
 * @param {string} id Id of the Account object.
 * @param {object} [props] Query props.
 * @returns {string}
 */
export const whatRole = (userId: string, members: Member[]) => {
  for (const member of members) {
    if (member.UserId === userId) {
      return member.TeamMemberRole;
    }
  }
  return null;
};

/**
 * Maps user organization to Redux state.
 * @function
 * @category Redux
 * @param {object} obj Account object.
 * @param {boolean} [onlyActiveKeys] If only keys existing on mapped object should be mapped.
 * @returns {Account}
 */
export const mapAccountToRedux = (obj: object, onlyActiveKeys: boolean) =>
  mapSFToForm(
    sfOauthConfig.isIcce ? mapFieldsICCE : mapFields,
    obj,
    onlyActiveKeys
  );

interface QueryProp {
  permissions?: object;
}

/**
 * Returns parsed Account record for provided id.
 * @function
 * @category Salesforce - Account
 * @param {string} id Id of the Account object.
 * @param {object} [props] Query props.
 * @param {object} [props.permissions] Additional subqueriers that should be fetched - identified by keys provided to the object.
 * @returns {Account}
 */
export const getAccountParsedById = (id: string, props: QueryProp = {}) => {
  return getAccount(id, props).then((acc) => {
    return acc[0] ? mapSFToForm(mapFields, acc[0]) : null;
  });
};
