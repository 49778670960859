import { Trans } from '@lingui/macro'
import {
  Card,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fundLogos } from '../grants/committees/ReviewApplications'

export const useViewComponentStyles = makeStyles({
  textBlock: {
    marginLeft: 30,
    marginTop: 20,
    marginRight: 30,
    // fontSize: 18,
    textAlign: 'left'
  },
  subtitle: {
    fontSize: 12,
    display: 'flex',
    color: '#919191'
  },
  paperGrid: {
    padding: 20
  },
  largeText: {
    fontSize: 21
  },
  normalSize: {},
  paper: {
    width: '100%',
    paddingBottom: 10
  },
  sectionHeader: {
    fontSize: '48px',
    textAlign: 'center',
    padding: 20
  },
  fieldLabel: {}
})
export const HEADER = 'HEADER'
const Header = ({ text, data, ...props }) => {
  const classes = useViewComponentStyles()
  return (
    <span className={props.spanClassName}>
      {props.pageBreakBefore && !props.disabled && <PageBreak />}
      <Typography className={classes.sectionHeader} variant='h1' {...props}>
        {text}
      </Typography>
    </span>
  )
}

export const SUBHEADER = 'SUBHEADER'
const Subheader = ({ text, data, style, ...props }) => {
  const classes = useViewComponentStyles()
  return (
    <b>
      <div
        style={{
          fontSize: 21,
          width: '100%',
          color: '#757575',
          textAlign: 'center',
          ...style
        }}
      >
        {data || text}
      </div>
    </b>
  )
}

export const FUND_IMAGE = 'FUND_IMAGE'
const FundImage = ({ fundTypeName, imageClass }) => {
  return (
    <img
      title={fundTypeName}
      className={imageClass}
      src={fundLogos[fundTypeName]}
      style={{
        width: '100px',
        height: '100px',
        margin: 10
      }}
    />
  )
}

export const GRID = 'GRID'
export const GridContainer = ({
  direction = 'row',
  flexWrap = 'wrap',
  style,
  object,
  gridClass,
  justify,
  alignItems,
  filterOptions,
  ...props
}) => {
  const objects = convertConfiguration(props, object, filterOptions)

  return (
    <Grid
      wrap={flexWrap}
      style={style}
      container
      className={gridClass}
      direction={direction}
      {...props}
      justify={justify}
      alignItems={alignItems}
      // style={{ backgroundColor: 'red' }}
    >
      {objects}
    </Grid>
  )
}

export const PAPER = 'PAPER'
const PaperContainer = ({
  object,
  style,
  filterOptions,
  title,
  paperClass,
  ...props
}) => {
  const objects = convertConfiguration(props, object, filterOptions)
  const classes = useViewComponentStyles()
  const defaultStyle = style || { width: 'auto', margin: 20, padding: 15 }

  return (
    <Paper
      style={{ ...defaultStyle }}
      className={paperClass}
      elevation={6}
      {...props}
    >
      {title && (
        <Typography className={classes.subtitle}>
          <Trans id={title} />
        </Typography>
      )}
      {objects}
    </Paper>
  )
}

export const DATA_ON_TOP = 'DATA_ON_TOP'
const DataOnTop = ({ data, subtitle, disabled, gridClass, ...props }) => {
  const classes = useViewComponentStyles()
  if (disabled) {
    return null
  }
  return (
    <Grid item {...props} className={gridClass}>
      <div style={{ margin: 10 }}>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          <b>{data}</b>
        </Typography>
        <Typography className={classes.subtitle}>
          <b>{subtitle}</b>
        </Typography>
      </div>
    </Grid>
  )
}
export const TEXT_DATA = 'TEXT_DATA'
const DataText = ({ data, subtitle }) => {
  const classes = useViewComponentStyles()
  return (
    <div style={{ margin: 10 }}>
      <Typography>
        <b>{subtitle}</b>
      </Typography>
      <Typography style={{ whiteSpace: 'pre-line' }}>{data}</Typography>
    </div>
  )
}

export const DATA_ON_BOTTOM_NO_CARD = 'DATA_ON_BOTTOM_NO_CARD'
const DataOnBottomWithoutCard = ({
  data,
  style,
  subtitle,
  gridClass,
  paperClass,
  paperStyle,
  dataClass = 'normalText',
  dataProps,
  filterKeys,
  dataComponent = 'p',
  ...props
}) => {
  const classes = useViewComponentStyles()
  return (
    <Grid
      style={{ ...style, padding: 15 }}
      item
      {...props}
      className={gridClass}
      justify='center'
    >
      <Typography className={classes.subtitle}>{subtitle}</Typography>
      <Typography
        style={{ whiteSpace: 'pre-line' }}
        {...dataProps}
        className={classes[dataClass]}
        component={dataComponent}
      >
        {data}
      </Typography>
    </Grid>
  )
}

export const DATA_ON_BOTTOM = 'DATA_ON_BOTTOM'
const DataOnBottom = ({
  data,
  style,
  subtitle,
  gridClass,
  paperClass,
  paperStyle,
  dataClass = 'normalText',
  dataProps,
  filterKeys,
  dataComponent = 'p',
  ...props
}) => {
  const classes = useViewComponentStyles()

  return (
    <Grid
      style={{ ...style }}
      item
      {...props}
      className={gridClass}
      justify='center'
    >
      <Paper
        elevation={6}
        style={{ padding: 15, ...paperStyle }}
        className={paperClass}
      >
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        <Typography
          style={{ whiteSpace: 'pre-line' }}
          {...dataProps}
          className={classes[dataClass]}
          component={dataComponent}
        >
          {data}
        </Typography>
      </Paper>
    </Grid>
  )
}

export const DATA_LIST = 'DATA_LIST'
const DataList = ({
  data,
  dataComponent,
  style,
  subtitle,
  prevData,
  bulletIcon,
  paperClass,
  dataClass = 'normalText',
  dataProps,
  emptyPlaceholder,
  ...props
}) => {
  const classes = useViewComponentStyles()
  return (
    <Paper
      elevation={6}
      style={style || { padding: 15, marginLeft: 20, marginRight: 20 }}
      className={paperClass}
    >
      {prevData && (
        <>
          <Typography className={classes.subtitle}>{prevData.main}</Typography>
          <Typography
            style={{
              marginBottom: 15,
              marginLeft: 15,
              marginTop: 15,
              whiteSpace: 'pre-line'
            }}
          >
            {prevData.sub}
          </Typography>
        </>
      )}
      <Typography className={classes.subtitle}>{subtitle}</Typography>
      <List {...dataProps} className={classes[dataClass]}>
        {Boolean(!data || data.length === 0) && (
          <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
            {emptyPlaceholder || <Trans>This list is empty!</Trans>}
          </ListItem>
        )}
        {Boolean(data && dataComponent) &&
          data.map((item, index) => {
            return (
              <ListItem key={index} style={{ display: 'flex' }}>
                {bulletIcon && (
                  <ListItemIcon>
                    <Icon>{bulletIcon}</Icon>
                  </ListItemIcon>
                )}
                {dataComponent(item)}
              </ListItem>
            )
          })}
        {Boolean(data && !dataComponent) &&
          data.map((item, index) => {
            return (
              <ListItem key={index} style={{ display: 'flex' }}>
                {bulletIcon && (
                  <ListItemIcon>
                    <Icon>{bulletIcon}</Icon>
                  </ListItemIcon>
                )}
                <Typography>
                  <Trans id={item} />
                </Typography>
              </ListItem>
            )
          })}
      </List>
    </Paper>
  )
}

export const TEXT_LIST = 'TEXT_LIST'
const TextList = ({ object, filterOptions, ...props }) => {
  const classes = useViewComponentStyles()
  const objects = convertConfiguration(props, object, filterOptions)
  return (
    <List>
      {objects.map((item, index) => {
        return <ListItem key={index}>{item}</ListItem>
      })}
    </List>
  )
}

export const FILES_LIST = 'FILES_LIST'
export const FilesList = ({ files, data, gridClass }) => {
  const toMap = data || files
  return (
    <Card className={gridClass}>
      {toMap.map((file, index) => {
        return (
          <Grid
            container
            direction='row'
            alignItems='center'
            key={index}
            style={{ paddingLeft: 5 }}
          >
            <IconButton variant='link' href={file.url}>
              <Icon>download</Icon>
            </IconButton>
            <Typography style={{ marginLeft: 5 }}>
              <b>{file.name}</b>
              <div>{file.description}</div>
            </Typography>
          </Grid>
        )
      })}
    </Card>
  )
}

export const PAGE_BREAK = 'PAGE_BREAK'
export const PageBreak = () => {
  return <div className='page-break'>TEST</div>
}

export const LIST_TO_OBJECTS = 'LIST_TO_OBJECTS'
export const ListToObjects = ({ list = [], conf, filterOptions, ...props }) => {
  return list.map((item, index) => {
    return convertConfiguration(conf, item, filterOptions)
  })
}

export const OBJECT_TO_LIST = 'OBJECT_TO_LIST'
export const ObjectToList = ({ obj, conf, filterOptions, ...props }) => {
  return Object.keys(obj).map((key, index) => {
    return convertConfiguration(conf, { data: obj[key], key }, filterOptions)
  })
}

export const componentsMapping = {
  [PAGE_BREAK]: { component: PageBreak },
  [FILES_LIST]: { component: FilesList },
  [HEADER]: { component: Header },
  [SUBHEADER]: { component: Subheader },
  [FUND_IMAGE]: { component: FundImage },
  [GRID]: { component: GridContainer },
  [PAPER]: { component: PaperContainer },
  [DATA_ON_TOP]: { component: DataOnTop },
  [DATA_ON_BOTTOM]: { component: DataOnBottom },
  [DATA_ON_BOTTOM_NO_CARD]: { component: DataOnBottomWithoutCard },
  [DATA_LIST]: { component: DataList },
  [TEXT_LIST]: { component: TextList },
  [TEXT_DATA]: { component: DataText },
  [LIST_TO_OBJECTS]: { component: ListToObjects },
  [OBJECT_TO_LIST]: { component: ObjectToList }
}

const convertField =
  (object, filterOptions) =>
  ({ type, ...rest }, index) => {
    const Component = componentsMapping[type].component
    const calculatedProps = {}
    for (const prop in rest) {
      if (typeof rest[prop] === 'function') {
        try {
          calculatedProps[prop] = rest[prop](object)
        } catch (error) {
          if (error instanceof TypeError) {
            console.error(
              'convertField: problem in selector',
              prop,
              rest,
              error
            )
          } else {
            console.error(
              'convertField: unknown problem in selector',
              prop,
              rest,
              error
            )
          }
          calculatedProps[prop] = 'ERROR GETTING DATA'
        }
      } else {
        calculatedProps[prop] = rest[prop]
      }
    }

    if (rest.fields) {
      calculatedProps.object = object
      calculatedProps.filterOptions = filterOptions
    }
    return <Component {...calculatedProps} key={index} />
  }

export const checkCondition = (filterKey, filterOption) => {
  if (
    (filterKey.key === filterOption && !filterKey.negative) ||
    (filterKey.key !== filterOption && filterKey.negative)
  ) {
    return true
  } else {
    return false
  }
}

export const convertConfiguration = (conf, object, filterOptions) => {
  const filteredFields = conf.fields.filter(item => {
    for (const option in item.filterKeys) {
      if (!checkCondition(item.filterKeys[option], filterOptions[option])) {
        return false
      }
    }
    if (item.disabled) {
      // TODO check if function
      return !item.disabled(object)
    }
    return true
  })

  return filteredFields.map(convertField(object, filterOptions))
}

export const milestonesSegment = index => {
  return {
    type: PAPER,
    fields: [
      {
        type: DATA_ON_BOTTOM,
        data: opp =>
          opp.objectives.objectives[index]
            ? opp.objectives.objectives[index].objective
            : '',
        subtitle: <Trans>Objective</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        data: opp =>
          opp.objectives.objectives[index]
            ? opp.objectives.objectives[index].needs
            : '',
        subtitle: <Trans>Needs</Trans>
      },
      {
        type: DATA_LIST,
        bulletIcon: 'fiber_manual_record',
        data: opp =>
          opp.objectives.objectives[index]
            ? opp.objectives.objectives[index].outcomes
            : [],
        subtitle: <Trans>Outcomes</Trans>
      }
    ]
  }
}

export const formatWithBreaks = value => {
  if (value) {
    if (typeof value === 'string') {
      const lines = value.split(/\r?\n/)
      return lines.map(item => <p>{item}</p>)
    }
  }
  return value
}
