/**
 * Form element which renders an empty space
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param {number}  [typeProps.height=1] Height of empty space in pixels
 * @param {number} [typeProps.width=1] Width of empty space in pixels
 */
export const FormBlankSpace = ({ typeProps }) => {
  const { width = 1, height = 1 } = typeProps
  return (
    <div
      style={{
        width: Number(width),
        height: Number(height)
      }}
    />
  )
}
