import { portalLanguagesData } from "app/appSettings";
import { myI18n } from "translation/I18nConnectedProvider";
import { checkIfnoRecords } from "utils";

/**
 * Given locale key, returns correct label for translation data object, which is used in multilingual portal features.
 * @function
 * @category Translations
 * @param {string} langVersion Locale key.
 * @param {object} data Data object cotaining labels under locale keys.
 * @param {any} i18n I18n object used for translating connected Portal Translations. Defaults to i18n instance used in a project.
 * @param {string} [fallbackKey='en'] Locale key that will be used if label for given language key will be empty.
 * @return {string} Returns correct label from translation data object.
 */
export const getLabelFromTranslationData = (props: {
  /**
   * Data object cotaining labels under locale keys.
   */
  data;
  /**
   * Locale key
   */
  langVersion: string;
  /**
   * I18n object used for translating connected Portal Translations. Defaults to i18n instance used in a project.
   */
  i18n?;
  /**
   * Locale key that will be used if label for given language key will be empty.
   */
  fallbackKey?;
}) => {
  const { data, langVersion, i18n = myI18n, fallbackKey = "en" } = props;
  if (!data) {
    return "";
  }
  let toRet = data.portalTranslationId
    ? i18n._(data.portalTranslationId)
    : data[langVersion];
  if (!toRet && data[fallbackKey]) {
    toRet = data[fallbackKey];
  }
  return toRet || "";
};

/**
 * Given locale key, returns correct label for Survey_Text__c Salesforce object.
 * @param {string} userLang Locale key.
 * @param {object} titleTranslation Survey_Text__c object instance.
 * @param {string} [fieldName='Text'] Field form the Survey_Text__c object that contains the translation.
 * @param {string} [fallbackKey='en-ca'] If no label for target language is provided, label for fallback locale will be returned.
 * @return {string} Returns correct label from Survey_Text__c object.
 */
export function getTitleTranslation(
  userLang: string,
  titleTranslation: any,
  fieldName: string = "Text",
  fallbackKey: string = "en-ca"
) {
  let ret = "";
  if (checkIfnoRecords(titleTranslation)) {
    return ret;
  }
  const langData = portalLanguagesData[userLang];
  if (!langData) {
    return ret;
  }

  titleTranslation.Survey_Texts__r.records.some((trans) => {
    if (trans.Language__c === langData.surveyTextKey) {
      ret = trans.Text__c;
      if (trans[fieldName + "_Is_Portal_Translation_Id__c"]) {
        ret = myI18n._(ret);
      }
      return true;
    }
    return false;
  });

  if (!ret && fallbackKey) {
    titleTranslation.Survey_Texts__r.records.some((trans) => {
      if (trans.Language__c === fallbackKey) {
        ret = trans.Text__c;
        if (trans[fieldName + "_Is_Portal_Translation_Id__c"]) {
          ret = myI18n._(ret);
        }
        return true;
      }
      return false;
    });
  }
  return ret;
}

/**
 * Parses salesforce api value to string matching portal translation id.
 * @function
 * @category Translations
 * @param {string} apiValue Salesforce api value string.
 * @param {string} [prefix=''] String that will be added to the begining of returned string.
 * @returns {string} String matching portal translation id.
 */
export function parseSFApiValueToTranslationId(apiValue, prefix = "") {
  const toRet = String(apiValue)
    .toUpperCase()
    .replaceAll(" - ", " ")
    .replace(/\s+/g, "_");
  return prefix ? `${prefix}_${toRet}` : toRet;
}
