import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { FormOtherGrants } from './FormOtherGrants'

export const FormEditorOtherGrants = ({
  editMode,
  showPrintProps,
  typeProps,
  depth,
  injectable,
  injectableId,
  ...props
}) => {
  if (!editMode) {
    return <FormOtherGrants typeProps={typeProps} {...props} />
  }

  return (
    <>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        validTypes={['textarea', 'string']}
      />
    </>
  )
}
