import { t, Trans } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { commonPdfStyles } from 'app/views/forms/common/Common'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = {
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 16
  },
  firstCol: {
    width: '33%',
  },
  secondCol: {
    width: '33%'
  },
  thirdCol: {
    width: '34%'
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  headerCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    padding: '10px 20px',
    fontWeight: 'bold',
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    padding: '10px 20px',
    wordBreak: 'break-all'
  },
}

export const FormOtherGrantsPdfEditor = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (<div >
    {title? <div style={commonPdfStyles.title}>{title}</div>: null}
    <div style={styles.table}>
      <div style={styles.tableRow}>
        <div style={{...styles.tableCol, ...styles.firstCol}}>
          <div style={styles.headerCell}><Trans>Name</Trans></div>
        </div>
        <div style={{...styles.tableCol, ...styles.secondCol}}>
          <div style={styles.headerCell}><Trans>Value</Trans></div>
        </div>
        <div style={{...styles.tableCol, ...styles.thirdCol}}>
          <div style={styles.headerCell}>
            <Trans>Status</Trans>
          </div>
        </div>
      </div>

      <div style={styles.tableRow} >
        <div style={{...styles.tableCol, ...styles.firstCol}}>
          <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_NAME_PLACEHOLDER</Trans></div>
        </div>
        <div style={{...styles.tableCol, ...styles.secondCol}}>
        <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_VALUE_PLACEHOLDER</Trans></div>
        </div>
        <div style={{...styles.tableCol, ...styles.thirdCol}}>
        <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_STATE_PLACEHOLDER</Trans></div>
        </div>
      </div>
    </div>
  </div>)
}
