import { SET_CONFIGURATION_DATA } from '../actions/ConfigurationActions'

const initialState = {}

const configurationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURATION_DATA: {
      return {
        ...state,
        ...action.data
      }
    }
    default: {
      return state
    }
  }
}

export default configurationReducer
