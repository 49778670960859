import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Typography
} from '@material-ui/core'
import { saveRearrangedTemplates } from 'app/services/sfAuth/sfData/sfSurvey'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'

const ChangeSubtemplatesOrderDialogue = ({
  open,
  closeDialog,
  template,
  saveRearrangment
}) => {
  const [subtemplates, setSubtemplates] = useState([])
  const [isSaving, setSaving] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (!template || !open) {
      return
    }
    setSubtemplates([...template.children])
  }, [template])

  const handleSave = () => {
    const updateQueries = []
    setSaving(true)
    const toSave = [...subtemplates]
    subtemplates.forEach((sub, index) => {
      updateQueries.push({
        Id: sub.join.Id,
        Order__c: index
      })
      toSave[index].order = index
    })
    const saving = enqueueSnackbar(null, {
      variant: 'info',
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving</Trans>)
    })
    saveRearrangedTemplates(updateQueries).then(result => {
      saveRearrangment(template.index, toSave)
      closeSnackbar(saving)
      setSaving(false)
      closeDialog()
    })
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    margin: 4,
    padding: 10,
    minHeight: 60,
    background: isDragging ? '#5DBD94' : '#DDDDDD',
    ...draggableStyle
  })

  if (!template) {
    return null
  }

  return (
    <Dialog open={Boolean(open)} maxWidth={false}>
      <div style={{ width: '650px' }}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Trans>Rearrange subtemplates</Trans>
            <IconButton
              disabled={isSaving}
              onClick={() => {
                closeDialog()
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography style={{ marginBottom: 20 }}>
              <Trans>
                Change order of subtemplates by drag and dropping cards below in
                desired order.
              </Trans>
            </Typography>
          </div>
          <DragDropContext
            onDragEnd={result => {
              const { source, destination } = result
              if (!destination) {
                return
              }
              const toSet = [...subtemplates]
              const moved = toSet[source.index]
              toSet.splice(source.index, 1)
              toSet.splice(destination.index, 0, moved)
              setSubtemplates(toSet)
            }}
          >
            <Droppable droppableId='dropArea' direction='vertical' type='card'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {subtemplates.map((sub, index) => {
                    return (
                      <Draggable
                        key={sub.id}
                        draggableId={sub.id}
                        index={index}
                        type='card'
                      >
                        {(provided, snapshot) => (
                          <Card
                            elevation={4}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {sub.name}
                          </Card>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSaving}
            variant='contained'
            color='primary'
            onClick={handleSave}
          >
            <Trans>Save</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default React.memo(ChangeSubtemplatesOrderDialogue)
