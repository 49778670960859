import {
  parseExtensionPhoneFromSF,
  parseExtensionPhoneToSF,
} from "app/views/common/Formats";
import { chunkArray } from "../../../../utils";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { mapSFToForm, reverseMap } from "../sfDataService";

const mapFields = {
  direction: "in",
  Id: "id",
  OwnerId: "owner",
  Title: "jobTitle",
  Email: "email",
  Name: "name",
  FirstName: "firstName",
  LastName: "lastName",
  Associated_Organizations__c: "associatedOrganizations",
  Role__c: "role",
  Relationship__c: "relationship",
  Provided_Organization_Name__c: "providedOrganizationName",
  Phone: {
    key: "phoneNumber",
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.Phone
      );
      toRet.phoneNumberCountryCode = countryCode;
      return phoneNumber;
    },
    out: (res) => {
      return parseExtensionPhoneToSF(
        res.phoneNumber,
        res.phoneNumberCountryCode
      );
    },
  },
  MobilePhone: {
    key: "mobilePhone",
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.MobilePhone
      );
      toRet.mobilePhoneCountryCode = countryCode;
      return phoneNumber;
    },
    out: (res) => {
      return parseExtensionPhoneToSF(
        res.mobilePhone,
        res.mobilePhoneCountryCode
      );
    },
  },
  npe01__WorkPhone__c: {
    key: "workPhone",
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.npe01__WorkPhone__c
      );
      toRet.workPhoneCountryCode = countryCode;
      return phoneNumber;
    },
    out: (res) => {
      return parseExtensionPhoneToSF(res.workPhone, res.workPhoneCountryCode);
    },
  },
};
const reverseMapFields = reverseMap(mapFields);

interface ContactParsed {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  associatedOrganizations?: any[];
}

interface Contact {
  Id: string;
  Name: string;
  Email__c?: string;
  Phone__c?: string;
}

/**
 * Saves Contact data using a specified flow.
 * @function
 * @category Salesforce - Contact
 * @param {Contact } contact The Contact data to be saved.
 * @param {string} contact.id The id of the Contact.
 * @param {string} contact.name The name of the Contact.
 * @param {string} [contact.email] The email of the Contact (optional).
 * @param {string} [contact.phone] The phone number of the Contact (optional).
 * @returns {FlowResult}
 */
export const saveContactByFlow = (contact: Contact) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Update_Contact", {
      inputs: [
        {
          contact,
        },
      ],
    })
    .then((flowResult) => flowResult[0]);
};

export const saveContactFromLookupField = ({
  objectName,
  objectId,
  fieldName,
  fieldValue,
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost(
    "/actions/custom/flow/App_Save_Contact_Lookup_Field",
    {
      inputs: [
        {
          objectName,
          objectId,
          fieldName,
          fieldValue,
        },
      ],
    }
  );
};

export const saveContactParsed = (value) => {
  return saveContactByFlow(mapSFToForm(reverseMapFields, value) as Contact);
};

/**
 * Checks the usage of a Contact for an Account using a custom flow.
 * @function
 * @category Salesforce - Contact, Account, and Opportunity.
 * @param {string} contactId The id of the Contact.
 * @param {string} orgId The id of the organization (Account).
 * @returns {Opportunity[]}
 */
export const checkContactUsageForAccountByFlow = ({
  contactId,
  orgId,
}: {
  contactId: string;
  orgId: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Check_Contact_Usage_For_Account", {
      inputs: [
        {
          contactId,
          orgId,
        },
      ],
    })
    .then((flowResult) => flowResult[0].outputValues.opportunitiesUsed || []);
};

/**
 * Maps a Salesforce Contact object to a form, optionally filtering out inactive fields.
 * @function
 * @category Salesforce - Contact
 * @param {object} contact The Salesforce Contact object to map.
 * @param {boolean} [onlyActive=false] Optional. If true, only active fields will be mapped.
 * @returns {Contact}
 */
export const mapContact = (
  contact: Contact,
  { onlyActive = false }: { onlyActive?: boolean } = {}
) => {
  return mapSFToForm(mapFields, contact, onlyActive);
};

/**
 * Saves a Contact by processing the input value through reverse mapping and then invoking a flow.
 * @param {Contact} value The input value to be processed and saved.
 * @returns {FlowResult}
 */
export const saveContactByFlowParsed = (value: ContactParsed) => {
  return saveContactByFlow(mapSFToForm(reverseMapFields, value) as Contact);
};

/**
 * @typedef GetContactsResult
 * @property {string} actionName - The name of the action.
 * @property {string[] | null} errors - Array of error messages or null if no errors occurred.
 * @property {boolean} isSuccess - Indicates whether the action was successful.
 * @property {Object} outputValues - Object containing output values.
 * @property {Contact[]} outputValues.contactsFound - Array of Contact records found.
 * @property {string} outputValues.Flow__InterviewStatus - The status of the flow interview.
 */
/**Retrieves Contacts using a custom flow based on provided ids.
 * @function
 * @category Salesforce - Contact
 * @param {string[]} ids - An array of Contact ids to retrieve.
 * @returns {GetContactsResult}
 */
export const getContactsByFlow = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const chunks = chunkArray(ids, 50);

  return conn
    .requestPost("/actions/custom/flow/App_Find_contacts", {
      inputs: chunks.map((item) => ({
        ids: item,
      })),
    })
    .then((results) => [
      results.reduce(
        (prev, current) => {
          if (current.isSuccess) {
            prev.isSuccess = prev.isSuccess && current.isSuccess;
            prev.outputValues.contactsFound =
              prev.outputValues.contactsFound.concat(
                current.outputValues.contactsFound
              );
          } else {
            prev.isSuccess = false;
            if (!prev.errors) {
              prev.errors = [];
            }
            prev.errors = prev.errors.concat(current.errors);
          }
          return prev;
        },
        {
          actionName: "App_Find_contacts",
          errors: null,
          isSuccess: true,
          outputValues: {
            contactsFound: [],
            Flow__InterviewStatus: "Finished",
          },
        }
      ),
    ]);
};

interface ContactsMap {
  [contactId: string]: ContactDetails;
}

interface ContactDetails {
  Id: string;
}

/**
 * Retrieves Contacts from Salesforce based on provided ids and maps them.
 * @param {string[]} ids - An array of ids of Contacts to be retrieved.
 * @returns {ContactsMap}
 */
export const getContactsMapByFlow = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (ids.length === 0) {
    return Promise.resolve([]);
  }
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_contacts", {
      inputs: [
        {
          ids,
        },
      ],
    })
    .then((contactsFlow) => {
      const contacts = contactsFlow[0].outputValues.contactsFound.filter(
        (v) => v
      );
      const toRet = {};
      contacts.forEach((contact) => {
        toRet[contact.Id] = { ...contact };
      });
      return toRet;
    });
};

interface ContactToCreate {
  title?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  providedOrganizationName?: string;
  workEmail?: string;
  email?: string;
}

/**
 * Creates a new Contact using a custom flow based on the provided values.
 * @param {ContactToCreate} values - An object containing the values for creating the new Contact.
 * @returns {FlowResult}
 */
export const createContactByFlow = (values: ContactToCreate) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Create_New_Contact", {
      inputs: [
        {
          ...values,
        },
      ],
    })
    .then((flowResult) => flowResult[0].outputValues);
};

interface ContactToBeChecked {
  Id?: string;
  Name?: string;
  Email__c?: string;
  Phone__c?: string;
}

/**
 * Checks Contact by invoking a custom Salesforce flow.
 * @function
 * @category Salesforce - Contact
 * @param {ContactToBeChecked} values The values to be passed as inputs to the flow.
 * @returns {FlowResult}
 */
export const checkContactByFlow = (values: ContactToBeChecked) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost("/actions/custom/flow/App_Find_Contact", {
    inputs: [
      {
        ...values,
      },
    ],
  });
};

/**
 * Sets account ownership by invoking a custom Salesforce flow.
 * @function
 * @category - Salesforce - Contact and User
 * @param {string} userId - The id of the User to set as the owner.
 * @param {string} contactId - The id of the Contact for which to set ownership.
 * @returns {FlowResult}
 */
export const setAccountOwnershipByFlow = (
  userId: string,
  contactId: string
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost("/actions/custom/flow/App_Set_Contact_Ownership", {
    inputs: [
      {
        contactId: contactId,
        userId: userId,
      },
    ],
  });
};

/**
 * Sets the reviewer status for a User by invoking a custom Salesforce flow.
 * @function
 * @category Salesforce - User
 * @param {string} userId - The id of the User to set as a reviewer.
 * @returns {FlowResult}
 */
export const setReviewerStatusByFlow = (userId: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.requestPost(
    "/actions/custom/flow/App_Add_to_External_Reviewers_Group",
    {
      inputs: [
        {
          userId: userId,
        },
      ],
    }
  );
};
