import { BuildingItem, BuildingItemParsed } from 'app/services/sfAuth/sfData/sfBuildings';
import ExcelJS from 'exceljs';

interface ExcelFileImportPdfField {
  columnIdx: number;
  field: string;
  label: string;
  sfField?: keyof BuildingItem
}

const preQualificationHousingPortfolioFields: ExcelFileImportPdfField[] = [
  {
    columnIdx: 1,
    label: 'Building identifier',
    field: 'buildingIdentifier',
  },
  {
    columnIdx: 2,
    label: 'Building address: Street + number',
    field: 'buildingAddress',
  },
  {
    columnIdx: 3,
    label: 'City/Town',
    field: 'city',
  },
  {
    columnIdx: 4,
    label: 'Postal code',
    field: 'postalCode',
  },
  {
    columnIdx: 5,
    label: 'Province/Territory',
    field: 'province',
  },
  {
    columnIdx: 6,
    label: 'No. of stories',
    field: 'noOfStories',
    sfField: 'Number_of_stories__c',
  },
  {
    columnIdx: 7,
    label: 'No. of accessible/barrier free units',
    field: 'noOfAccessibleUnits',
    sfField: "Number_of_accessible_barrier_free_units__c",
  },
  {
    columnIdx: 8,
    label: 'Property management',
    field: 'propertyManagement',
    sfField: 'Property_management_type__c',
  },
  {
    columnIdx: 9,
    label: 'Current building condition',
    field: 'currentBuildingCondition',
    sfField: 'Building_condition__c',
  },
  {
    columnIdx: 10,
    label: 'No. of vacant Bachelor units',
    field: 'noOfVacantBachelorUnits',
    sfField: 'Number_of_vacant_bachelor_units__c',
  },
  {
    columnIdx: 11,
    label: 'No. of occupied Bachelor units',
    field: 'noOfOccupiedBachelorUnits',
    sfField: 'Number_of_occupied_bachelor_units__c',
  },
  {
    columnIdx: 12,
    label: 'No. of vacant 1 Bedroom units',
    field: 'noOfVacant1BedroomUnits',
    sfField: 'Number_of_vacant_1_bedroom_units__c',
  },
  {
    columnIdx: 13,
    label: 'No. of occupied 1 bedroom units',
    field: 'noOfOccupied1BedroomUnits',
    sfField: 'Number_of_occupied_1_bedroom_units__c',
  },
  {
    columnIdx: 14,
    label: 'No. of vacant 2 bedroom units',
    field: 'noOfVacant2BedroomUnits',
    sfField: 'Number_of_vacant_2_bedroom_units__c',
  },
  {
    columnIdx: 15,
    label: 'No. of occupied 2 bedroom units',
    field: 'noOfOccupied2BedroomUnits',
    sfField: 'Number_of_occupied_2_bedroom_units__c',
  },
  {
    columnIdx: 16,
    label: 'No. of vacant 3 bedroom units',
    field: 'noOfVacant3BedroomUnits',
    sfField: 'Number_of_vacant_3_bedroom_units__c',
  },
  {
    columnIdx: 17,
    label: 'No. of occupied 3 bedrooms units',
    field: 'noOfOccupied3BedroomUnits',
    sfField: 'Number_of_occupied_3_bedrooms_units__c',
  },
  {
    columnIdx: 18,
    label: 'No. of vacant 4+ bedroom units',
    field: 'noOfVacant4BedroomUnits',
    sfField: 'Number_of_vacant_4_bedroom_units__c',
  },
  {
    columnIdx: 19,
    label: 'No. of occupied 4+ bedrooms units',
    field: 'noOfOccupied4BedroomUnits',
    sfField: 'Number_of_occupied_4_bedrooms_units__c',
  },
  {
    columnIdx: 20,
    label: 'Total No. of units',
    field: 'totalNoOfUnits',
    sfField: 'Units_Total__c',
  },
];


const propertyInformationFields: ExcelFileImportPdfField[] = [
  {
    columnIdx: 1,
    label: 'Building identifier',
    field: 'buildingIdentifier',
  },
  {
    columnIdx: 2,
    label: 'Building address: Street + number',
    field: 'buildingAddress',
  },
  {
    columnIdx: 3,
    label: 'City/Town',
    field: 'city',
  },
  {
    columnIdx: 4,
    label: 'Postal code',
    field: 'postalCode',
  },
  {
    columnIdx: 5,
    label: 'Province/Territory',
    field: 'province',
  },
  {
    columnIdx: 6,
    label: 'No. of stories',
    field: 'noOfStories',
    sfField: 'Number_of_stories__c',
  },
  {
    columnIdx: 7,
    label: 'No. of elevators',
    field: 'noOfElevators',
    sfField: 'Number_of_elevators__c',
  },
  {
    columnIdx: 8,
    label: 'No. of parking spaces',
    field: 'noOfParkingSpaces',
    sfField: 'Number_of_parking_spaces__c',
  },
  {
    columnIdx: 9,
    label: 'Property management',
    field: 'propertyManagement',
    sfField: 'Property_management_type__c',
  },
  {
    columnIdx: 10,
    label: 'Current building condition',
    field: 'currentBuildingCondition',
    sfField: 'Building_condition__c',
  },
  {
    columnIdx: 11,
    label: '% of barrier free common areas',
    field: 'percentBarrierFreeCommonAreas',
    sfField: 'Percentage_of_barrier_free_common_areas__c',
  },
  {
    columnIdx: 12,
    label: 'Total No. of units',
    field: 'totalNoOfUnits',
    sfField: 'Units_Total__c',
  },
  {
    columnIdx: 13,
    label: 'No. of accessible/barrier free units',
    field: 'noOfAccessibleUnits',
    sfField: "Number_of_accessible_barrier_free_units__c",
  },
  {
    columnIdx: 14,
    label: 'No. of vacant Bachelor units',
    field: 'noOfVacantBachelorUnits',
    sfField: 'Number_of_vacant_bachelor_units__c',
  },
  {
    columnIdx: 15,
    label: 'No. of occupied Bachelor units',
    field: 'noOfOccupiedBachelorUnits',
    sfField: 'Number_of_occupied_bachelor_units__c',
  },
  {
    columnIdx: 16,
    label: 'No. of vacant 1 Bedroom units',
    field: 'noOfVacant1BedroomUnits',
    sfField: 'Number_of_vacant_1_bedroom_units__c',
  },
  {
    columnIdx: 17,
    label: 'No. of occupied 1 bedroom units',
    field: 'noOfOccupied1BedroomUnits',
    sfField: 'Number_of_occupied_1_bedroom_units__c',
  },
  {
    columnIdx: 18,
    label: 'No. of vacant 2 bedroom units',
    field: 'noOfVacant2BedroomUnits',
    sfField: 'Number_of_vacant_2_bedroom_units__c',
  },
  {
    columnIdx: 19,
    label: 'No. of occupied 2 bedroom units',
    field: 'noOfOccupied2BedroomUnits',
    sfField: 'Number_of_occupied_2_bedroom_units__c',
  },
  {
    columnIdx: 20,
    label: 'No. of vacant 3 bedroom units',
    field: 'noOfVacant3BedroomUnits',
    sfField: 'Number_of_vacant_3_bedroom_units__c',
  },
  {
    columnIdx: 21,
    label: 'No. of occupied 3 bedrooms units',
    field: 'noOfOccupied3BedroomUnits',
    sfField: 'Number_of_occupied_3_bedrooms_units__c',
  },
  {
    columnIdx: 22,
    label: 'No. of vacant 4+ bedroom units',
    field: 'noOfVacant4BedroomUnits',
    sfField: 'Number_of_vacant_4_bedroom_units__c',
  },
  {
    columnIdx: 23,
    label: 'No. of occupied 4+ bedrooms units',
    field: 'noOfOccupied4BedroomUnits',
    sfField: 'Number_of_occupied_4_bedrooms_units__c',
  },
];

const parseExcelFileImportPdf = async function({file, noOfHeaderRows, fields, organizationNameCell, organizationPhoneCell}:{
  file: Buffer, noOfHeaderRows: number, fields: ExcelFileImportPdfField[], organizationNameCell: string, organizationPhoneCell: string
}) {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);  

  const worksheet = workbook.getWorksheet(1);
  const parsedRows: typeof fields = [];

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber <= noOfHeaderRows) {
      return;
    }
    const parsedRow: ExcelFileImportPdfField = {} as ExcelFileImportPdfField;
    for (const field of fields) {
      const cellValue = row.getCell(field.columnIdx).value;
      parsedRow[field.field] = typeof cellValue === 'string' || typeof cellValue === 'number' ? cellValue : null;
    }
    parsedRows.push(parsedRow);
  })

  const organisationsNameCell = worksheet.getCell(organizationNameCell);
  const organizationsPhoneCell = worksheet.getCell(organizationPhoneCell);
  return {
    rows: parsedRows,
    organization: {
      name: organisationsNameCell?.value,
      phone: organizationsPhoneCell?.value,
    }
  };
}

export const parsedRowToSfBuilding = function(parsedRow: ExcelFileImportPdfField, fields: ExcelFileImportPdfField[]): BuildingItem {
  const building: BuildingItem = {} as BuildingItem;
  for (const field of fields) {
    if (field.sfField){
      // @ts-ignore
      building[field.sfField] = parsedRow[field.field];
    }
  }
  return building;
} 

export const sfBuildingToParsedRow = function(sfBuilding: BuildingItem, fields: ExcelFileImportPdfField[]): BuildingItemParsed {
  const parsedRow: BuildingItemParsed = {} as BuildingItemParsed;
  for (const field of fields) {
    // @ts-ignore
    parsedRow[field.field] = sfBuilding[field.sfField];
  }
  return parsedRow;
}

interface UploadExcelFileImportPdfProps {
  filePath: string;
  connectedObject: string;
  organization: {
    organisationsName: string;
    phone: string;
    email: string;
    street: string;
    province: string;
    postalCode: string;
  }
}

function uploadExcelFileImportPdf({filePath, organization, connectedObject}: UploadExcelFileImportPdfProps): Promise<string> {
  return fetch(filePath)
  .then(response => response.arrayBuffer())
  .then(async (buffer) => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);

    const worksheet = workbook.getWorksheet(1); 
    const cellM1 = worksheet.getCell('M1');
    const cellM2 = worksheet.getCell('M2');
    const cellN1 = worksheet.getCell('N1');
    const cellN2 = worksheet.getCell('N2');

    if(connectedObject === 'Pre-Qualification') {
      cellM1.value = organization?.organisationsName || cellM1.value;
      cellM2.value = organization?.phone || cellM2.value;
    }
    if(connectedObject === 'Opportunity') {
      cellN1.value = organization?.organisationsName || cellN1.value;
      cellN2.value = organization?.phone || cellN2.value;
    }
    
    const modifiedBuffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    return url;
  })
}

export { parseExcelFileImportPdf, uploadExcelFileImportPdf, preQualificationHousingPortfolioFields, propertyInformationFields };

