import SFAuthService, { NO_USER } from '../../SFAuthService'
import { mapSFToForm, reverseMap } from '../../sfDataService'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Name: 'name',
  Budget_Category__c: 'budgetCategory',
  Actual_amount__c: 'actual',
  Actual_Amount_Comments__c: 'actualComments',
  Amount_changes__c: 'change',
  Change_details__c: 'changeDetails',
  Grantee_Report__c: 'granteeReport'
}
const reverseMapFields = reverseMap(mapFields)

export const parseBudgetUpdateSFObject = obj => mapSFToForm(mapFields, obj)

export const fetchBudgetUpdatesForReport = reportId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Budget_Update__c')
    .find({
      Grantee_Report__c: reportId
    })
    .then(result => result.map(obj => mapSFToForm(mapFields, obj)))
}

export const createBudgetUpdateParsed = toCreate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Budget_Update__c')
    .create(
      Array.isArray(toCreate)
        ? toCreate.map(obj => mapSFToForm(reverseMapFields, obj))
        : mapSFToForm(reverseMapFields, toCreate)
    )
}

export const updateBudgetUpdateParsed = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Budget_Update__c')
    .update(
      Array.isArray(toUpdate)
        ? toUpdate.map(obj => mapSFToForm(reverseMapFields, obj))
        : mapSFToForm(reverseMapFields, toUpdate)
    )
}
