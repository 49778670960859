import { dateFormat, portalLanguagesData } from "app/appSettings";
import moment from "moment";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { mapSFToForm } from "../sfDataService";

const mapFields = {
  direction: "in",
  Translation_Id__c: "id",
  Id: "sfId",
  Translator_Comment__c: "comment",
  LastModifiedDate: {
    key: "lastModifiedDate",
    in: (obj) => {
      return obj.LastModifiedDate
        ? moment.utc(obj.LastModifiedDate).format(dateFormat)
        : "";
    },
  },
  Translated_EN__c: "en",
  Translated_FR__c: "fr",
  Translated_IU_Latn__c: "iu-latn",
  Translated_IU_Cans__c: "iu-cans",
};

/**
 * Updates multiple survey text records in Salesforce.
 * @function
 * @category Salesforce - Survey_Text__c
 * @param {object[]} updateArray An array of objects containing the fields to update for each survey text record.
 * @returns {JSForceResult}
 */
export const updateTexts = (updateArray: object[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Survey_Text__c").update(updateArray);
};

/**
 * Creates a new translation record in Salesforce for survey text.
 * @function
 * @category Salesforce - SurveyTranslation__c and Survey_Text__c
 * @param {object} props An object containing the translation properties.
 * @returns {string} The id of the created translation record.
 */
export const createTranslation = (props: object) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("SurveyTranslation__c")
    .create({})
    .then((translation) => {
      const langPromises = Object.values(portalLanguagesData).map((obj) => {
        return conn.sobject("Survey_Text__c").create({
          Survey_Translation__c: translation.id,
          Language__c: obj.surveyTextKey,
          Text__c: props[obj.editKey] || "",
        });
      });
      return Promise.all(langPromises).then((result) => {
        return translation.id;
      });
    });
};

/**
 * Retrieves portal translations from Salesforce.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @returns {object[]} An array of portal translation records.
 */
export const getPortalTranslations = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const langQuery = Object.values(portalLanguagesData)
    .map((obj) => {
      return obj.portalTranslationField;
    })
    .join(", ");
  return conn
    .sobject("Portal_Translation__c")
    .find({})
    .select(
      `Id, Translation_Id__c, ${langQuery}, Translator_Comment__c, LastModifiedDate`
    )
    .run({ autoFetch: true, maxFetch: 10000 });
};

/**
 * Retrieves and parses portal translations from Salesforce.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @returns {object[]} An array of parsed portal translation objects.
 */
export const getPortalTranslationsParsed = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const langQuery = Object.values(portalLanguagesData)
    .map((obj) => {
      return obj.portalTranslationField;
    })
    .join(", ");
  return conn
    .sobject("Portal_Translation__c")
    .find({})
    .select(
      `Id, Translation_Id__c, ${langQuery}, Translator_Comment__c, LastModifiedDate`
    )
    .run({ autoFetch: true, maxFetch: 10000 })
    .then((result) => {
      const ret = result.map((transObj) => {
        const langObj = {};
        Object.values(portalLanguagesData).forEach((obj) => {
          langObj[obj.editKey] = transObj[obj.portalTranslationField];
        });
        return { ...mapSFToForm(mapFields, transObj), ...langObj };
      });
      return ret;
    });
};

interface PortalTranslationValues {
  id: string;
  comment: string;
  langValues: object;
}

/**
 * Creates a new portal translation record in Salesforce based on the provided values.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {object} values The values for creating the portal translation record.
 * @param {string} values.id The translation id.
 * @param {string} values.comment The translator's comment.
 * @param {object} values.langValues Object containing language-specific values.
 * @returns {JSForceResult}
 */
export const createPortalTranslation = (values: PortalTranslationValues) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const langQuery = {};
  Object.values(portalLanguagesData).forEach((obj) => {
    const lang = obj.editKey;
    langQuery[obj.portalTranslationField] = values[lang];
  });
  return conn.sobject("Portal_Translation__c").create({
    Translation_Id__c: values.id,
    ...langQuery,
    Translator_Comment__c: values.comment,
  });
};

/**
 * Creates new portal translation records in Salesforce based on the provided list of translation data.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {PortalTranslationValues[]} list The list of translation data objects.
 * @param {string} list[].id The translation id.
 * @param {string} list[].comment The translator's comment.
 * @param {object} list[].langValues Object containing language-specific values.
 * @returns {JSForceResult}
 */
export const createPortalTranslations = (list: PortalTranslationValues[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const toCreate = list.map((obj) => {
    const langQuery = {};
    Object.values(portalLanguagesData).forEach((langobj) => {
      const lang = langobj.editKey;
      langQuery[langobj.portalTranslationField] = obj[lang];
    });
    return {
      Translation_Id__c: obj.id,
      ...langQuery,
      Translator_Comment__c: obj.comment,
    };
  });
  if (toCreate.length < 200) {
    return conn.sobject("Portal_Translation__c").create(toCreate);
  } else {
    const subArrays = new Array(Math.ceil(toCreate.length / 200))
      .fill(0)
      .map((_) => toCreate.splice(0, 200));
    return Promise.all(
      subArrays.map((sub) => conn.sobject("Portal_Translation__c").create(sub))
    );
  }
};

interface PortalTranslationUpdateData extends PortalTranslationValues {
  SFId: string;
}

/**
 * Updates a portal translation record in Salesforce with the provided values.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {PortalTranslationUpdateData} values The values to update the portal translation record.
 * @param {string} values.SFId The Salesforce ID of the portal translation record.
 * @param {string} values.id The translation ID.
 * @param {string} values.comment The translator's comment.
 * @param {object} values.langValues Object containing language-specific values.
 * @returns {Promise<void>} A promise that resolves when the portal translation is updated.
 */
export const updatePortalTranslation = (
  values: PortalTranslationUpdateData
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const langQuery = {};
  Object.values(portalLanguagesData).forEach((obj) => {
    const lang = obj.editKey;
    langQuery[obj.portalTranslationField] = values[lang];
  });
  return conn.sobject("Portal_Translation__c").update({
    Id: values.SFId,
    Translation_Id__c: values.id,
    ...langQuery,
    Translator_Comment__c: values.comment,
  });
};

/**
 * Updates portal translation records in Salesforce with the provided array of values.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {PortalTranslationUpdateData[]} array An array of data to update the portal translation records.
 * @returns {JSForceResult}
 */
export const updatePortalTranslations = (
  array: PortalTranslationUpdateData[]
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (array.length < 200) {
    return conn.sobject("Portal_Translation__c").update(array);
  } else {
    const subArrays = new Array(Math.ceil(array.length / 200))
      .fill(0)
      .map((_) => array.splice(0, 200));
    return Promise.all(
      subArrays.map((sub) => conn.sobject("Portal_Translation__c").update(sub))
    );
  }
};

/**
 * Deletes a portal translation record in Salesforce with the provided id.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {string} id The id of the portal translation record to delete.
 * @returns {JSForceResult}
 */
export const deletePortalTranslation = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Portal_Translation__c").destroy(id);
};

/**
 * Deletes multiple portal translation records in Salesforce with the provided IDs.
 * @function
 * @category Salesforce - Portal_Translation__c
 * @param {string | string[]} ids The id or array of ids of the portal translation records to delete.
 * @returns {Promise<any>} A promise that resolves with the result of the deletion operation.
 */
export const deletePortalTranslations = async (ids: string | string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return new Promise((resolve, reject) => {
    conn.sobject("Portal_Translation__c").destroy(ids, (err, rets) => {
      if (err) {
        reject(err);
      } else {
        resolve(rets);
      }
    });
  });
};

/**
 * Retrieves survey translation records from Salesforce based on the provided ids.
 * @function
 * @category Salesforce - SurveyTranslation__c and Survey_Texts__c
 * @param {string[]} ids An array of ids of the survey translation records to retrieve.
 * @returns {object[]} An array of survey translation records.
 */
export const getSurveyTranslations = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  const maxQueryLength = 500;
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  if (ids.length < maxQueryLength) {
    return conn
      .sobject("SurveyTranslation__c")
      .find({
        Id: { $in: ids },
      })
      .select("Id")
      .include("Survey_Texts__r")
      .select(
        "Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c, Text_Is_Portal_Translation_Id__c, Tooltip_Is_Portal_Translation_Id__c, Extended_Is_Portal_Translation_Id__c"
      )
      .end()
      .run({ autoFetch: true, maxFetch: 500 });
  } else {
    const subArrays = new Array(Math.ceil(ids.length / maxQueryLength))
      .fill(0)
      .map((_) => ids.splice(0, maxQueryLength));
    return Promise.all(
      subArrays.map((sub) =>
        conn
          .sobject("SurveyTranslation__c")
          .find({
            Id: { $in: sub },
          })
          .select("Id")
          .include("Survey_Texts__r")
          .select(
            "Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c, Text_Is_Portal_Translation_Id__c, Tooltip_Is_Portal_Translation_Id__c, Extended_Is_Portal_Translation_Id__c"
          )
          .end()
      )
    ).then((result) => {
      return result.flat();
    });
  }
};

/**
 * Retrieves a translation record from Salesforce based on the provided id.
 * @function
 * @category Salesforce - SurveyTranslation__c and Survey_Texts__c
 * @param {string} id The id of the translation record to retrieve.
 * @returns {object} The translation record.
 */
export const getTranslation = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("SurveyTranslation__c")
    .find({
      Id: id,
    })
    .select("Id")
    .include("Survey_Texts__r")
    .select(
      "Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c, Text_Is_Portal_Translation_Id__c, Tooltip_Is_Portal_Translation_Id__c, Extended_Is_Portal_Translation_Id__c"
    )
    .end()
    .then((res) => res[0]);
};
