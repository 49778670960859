import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export const FormUploadFilesFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  condtionsText,
  ...props
}) => {
  const { minFiles, required } = typeProps
  let requiredFilesNumber = minFiles || required ? 1 : 0

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontFamily:
            langVersion === 'iu-cans'
              ? inuktitutFormPdfFontFamily
              : defaultFormPdfFontFamily,
          fontSize: pdfDefaultFontSize,
          marginBottom: 4
        }}
      >
        <View style={formPDFStyles.checkbox} />
        <Text
          style={{
            fontFamily:
              langVersion === 'iu-cans'
                ? inuktitutFormPdfFontFamily
                : defaultFormPdfFontFamily,
            fontSize: pdfDefaultFontSize,
            marginLeft: 8
          }}
        >
          {requiredFilesNumber ? `(${requiredFilesNumber}) ` : ''}
          {tooltip ? `[${tooltip}] ` : ''}
          {title + ' '}
          {required && `(${myI18n._(t`Required`)})`}
          {condtionsText ? <>({condtionsText}) </> : ''}
        </Text>
      </View>
      {helpText ? (
        <View style={{ marginTop: 4 }}>
          <Text
            style={{ fontSize: pdfDefaultFontSize - 2, fontStyle: 'italic' }}
          >
            {helpText}
          </Text>
        </View>
      ) : (
        <View />
      )}
    </>
  )
}
