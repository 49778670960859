import ReactHtmlParser from 'react-html-parser'
import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'
import { formUseStyles } from '../editor/GroupElement'

export const FormHelptext = ({ helpText, parseToHtml, parseLabel }) => {
  const classes = formUseStyles()
  if (!helpText) {
    return <div />
  } else if (parseToHtml) {
    helpText = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(helpText)
      .toString()
  }

  return (
    <div
      style={{ padding: 10, paddingTop: 0, fontWeight: 400, fontSize: 13 }}
      className={classes.root}
    >
      {parseLabel || parseToHtml ? ReactHtmlParser(helpText) : helpText}
    </div>
  )
}
