import { Trans } from '@lingui/macro'
import { Button, Grid, Icon, IconButton, Typography } from '@material-ui/core'

import { portalLanguagesData } from 'app/appSettings'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import {
  deleteCatalogsByFlow,
  getWorkgroupsByFlow,
  saveWorkgroupCatalogsByFlow,
  updateWorkgroupByFlow
} from 'app/services/sfAuth/sfData/sfWorkgroups'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import { FormikCheckboxField } from 'formik-material-fields'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { languages } from 'translation/I18nConnectedProvider'
import ConfigureMultilanguageTextField from '../../internal/ConfigureMultilanguageTextField'

const EditCommittee = ({ match }) => {
  const { params } = match
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [sfWorkgroup, setSfWorkgroup] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const user = useSelector(state => state.user)

  const fetchData = () => {
    return getWorkgroupsByFlow([params.id]).then(result => {
      const output = result[0].outputValues
      setSfWorkgroup({
        id: params.id,
        name: languages.reduce((acc, lang) => {
          acc[lang] =
            output.workgroup[portalLanguagesData[lang].workgroupName] || ''
          return acc
        }, {}),
        catalogs: output.catalogs
          ? output.catalogs.map(obj => ({
              id: obj.Id,
              text: languages.reduce((acc, lang) => {
                acc[lang] =
                  obj[portalLanguagesData[lang].workgroupCatalogLabel] || ''
                return acc
              }, {}),
              allowUpload: obj.Allow_Upload__c
            }))
          : []
      })
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Formik enableReinitialize initialValues={sfWorkgroup}>
      {({ values, setFieldValue, initialValues }) => {
        const { catalogs, nameEN, nameFR } = values
        const name = user.language === 'en_US' ? nameEN : nameFR
        let catalogNum = 0

        const handleSave = () => {
          setSaving(true)
          const workgroupSave = {
            Id: sfWorkgroup.id,
            ...languages.reduce((acc, lang) => {
              acc[portalLanguagesData[lang].workgroupName] =
                sfWorkgroup.name[lang]
              return acc
            }, {})
          }
          const snackbar = enqueueSnackbar(<Trans>Saving {name}</Trans>, {
            variant: 'info',
            persist: true
          })
          const promises = []
          const toSave = catalogs
            .filter(obj => !obj.deleted)
            .map(catalog => ({
              Id: catalog.id,
              ...languages.reduce((acc, lang) => {
                acc[portalLanguagesData[lang].workgroupCatalogLabel] =
                  catalog.text[lang]
                return acc
              }, {}),
              Allow_Upload__c: catalog.allowUpload,
              Workgroup__c: sfWorkgroup.id
            }))
          if (toSave.length > 0) {
            promises.push(saveWorkgroupCatalogsByFlow(toSave))
          }
          const toDelete = catalogs
            .filter(obj => obj.deleted && obj.id)
            .map(catalog => ({
              Id: catalog.id
            }))
          if (toDelete.length > 0) {
            promises.push(deleteCatalogsByFlow(toDelete))
          }
          Promise.all([updateWorkgroupByFlow(workgroupSave), ...promises]).then(
            result => {
              fetchData().then(result => {
                setSaving(false)
                closeSnackbar(snackbar)
                enqueueSnackbar(<Trans>Saved {name}</Trans>, {
                  variant: 'success'
                })
              })
            },
            reject => {
              setSaving(false)
              closeSnackbar(snackbar)
              enqueueSnackbar(
                <Trans>Error ocurred while saving {name}!</Trans>,
                {
                  variant: 'error'
                }
              )
            }
          )
        }

        return (
          <div style={{ padding: 20 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                if (sfOauthConfig.isIcce) {
                  history.push(`/grants/Committees/${sfWorkgroup.id}`)
                } else {
                  history.push(`/grants/Workgroups/${sfWorkgroup.id}`)
                }
              }}
            >
              <Icon style={{ marginRight: 5 }}>arrow_back</Icon>
              <Trans>Go back to committee list</Trans>
            </Button>
            <Typography variant='h4' style={{ textAlign: 'center' }}>
              <Trans>Edit</Trans>{' '}
              {sfWorkgroup.name[user?.language.split('_')[0] || 'en']}
            </Typography>
            <div style={{ padding: 10 }}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                style={{ marginTop: 15, marginBottom: 15 }}
                disabled={saving}
                onClick={e => {
                  handleSave()
                }}
              >
                <Icon style={{ marginRight: 5 }}>save</Icon>
                <Trans>Save</Trans>
              </Button>
              <Grid container>
                <Grid item xs>
                  <ConfigureMultilanguageTextField
                    label={<Trans>Name</Trans>}
                    value={sfWorkgroup.name}
                    handleChange={value => {
                      setSfWorkgroup({ ...sfWorkgroup, name: value })
                    }}
                    style={{ marginBottom: 10 }}
                    allowConnectingToPortalTranslation={false}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems='center'>
                <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                  <Trans>Catalogs</Trans>
                </Typography>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ marginLeft: 10 }}
                  onClick={e => {
                    const toSet = [...catalogs]
                    toSet.push({
                      text: languages.reduce((acc, lang) => {
                        acc[lang] = ''
                        return acc
                      }, {}),
                      allowUpload: true
                    })
                    setFieldValue('catalogs', toSet)
                  }}
                >
                  <Icon>add</Icon>
                </Button>
              </Grid>
              {catalogs.map((catalog, index) => {
                if (catalog.deleted) {
                  return null
                } else {
                  catalogNum++
                }
                return (
                  <div key={index}>
                    <Grid container justify='space-between' alignItems='center'>
                      <Typography>{catalogNum}</Typography>
                      <IconButton
                        onClick={e => {
                          const toSet = [...catalogs]
                          toSet[index].deleted = true
                          setFieldValue('catalogs', toSet)
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                    <Grid container>
                      <Grid item xs>
                        <ConfigureMultilanguageTextField
                          label={<Trans>Label</Trans>}
                          value={catalog.text}
                          handleChange={value => {
                            const toSet = [...catalogs]
                            toSet[index].text = value
                            setFieldValue('catalogs', toSet)
                          }}
                          allowConnectingToPortalTranslation={false}
                        />
                      </Grid>
                    </Grid>
                    <FormikCheckboxField
                      name={`catalogs.${index}.allowUpload`}
                      style={{ marginLeft: 10 }}
                      FormControlLabelProps={{ labelPlacement: 'end' }}
                      controlLabel={<Trans>Allow upload</Trans>}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditCommittee
