export const SET_PORTAL_TRANSLATION_DATA = 'SET_PORTAL_TRANSLATION_DATA'

export function setPortalTranslationData (value) {
  return dispatch => {
    dispatch({
      type: SET_PORTAL_TRANSLATION_DATA,
      data: value
    })
  }
}
