import { Grid, Paper } from "@material-ui/core";
import { Text, View } from "@react-pdf/renderer";
import { formItemPadding } from "../Form";
import { checkAltLabel } from "../FormHelpersConditions";
import { AdjustPdfProps } from "../common/AdjustPdfProps";
import { parseFormLabelText, pdfDefaultFontSize } from "../common/Common";
import { formElementsWithoutInput } from "../editor/GroupElement";
import { FormElement } from "./FormElement";

export const formPrintViewSpacing = 15;

const FormElementGroup = ({
  item,
  langVersion,
  index,
  columns = 1,
  renderPrint,
  skipCard,
  formViewType = "editable",
  pdfDocument,
  ...props
}) => {
  const {
    title = {},
    elements,
    conditions,
    padding = {},
    headerFontSize,
    style,
    headerStyle,
    bold,
    italics,
  } = item;
  const { connectedMap, describeMap } = props;
  const paddingStyles = {};
  const paddingKeys = [
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "paddingBottom",
  ];
  paddingKeys.forEach((key) => {
    let toSet = 0;
    if (padding[key]) {
      toSet = Number(padding[key]);
    }
    paddingStyles[key] = toSet;
  });

  if (elements) {
    if (props.disabledIds.includes(item.id)) {
      return null;
    }
    let titleLabel = title[langVersion];
    if (conditions && conditions.length > 0) {
      const altLabel = checkAltLabel({
        item,
        describeMap,
        connectedMap,
        values: props.values,
        errors: props.baseErrors,
        langVersion,
        elementsMap: props.elementsMap,
      });
      if (typeof altLabel === "string") {
        titleLabel = altLabel;
      }
    }

    let styleToPass = {
      width: "100%",
      ...paddingStyles,
    };
    let headerStyleToPass = {};
    try {
      if (style) {
        const parsed = JSON.parse(style);
        if (typeof parsed === "object") {
          styleToPass = Object.assign(styleToPass, JSON.parse(style));
        }
      }
    } catch (e) {}
    try {
      const parsed = JSON.parse(headerStyle);
      if (typeof parsed === "object") {
        headerStyleToPass = Object.assign(headerStyleToPass, parsed);
      }
    } catch (e) {}
    if (headerFontSize) {
      headerStyleToPass.fontSize = +headerFontSize;
    }
    if (bold) {
      headerStyleToPass.fontWeight = "bold";
    }
    if (italics) {
      headerStyleToPass.fontStyle = "italic";
    }

    if (formViewType.includes("pdf")) {
      styleToPass.width = props.printWidth || "100%";
      delete props.printWidth;
      delete headerStyleToPass.fontSize;
      const columns =
        item.columns && formViewType !== "fillable-pdf" ? +item.columns : 1;
      return (
        <AdjustPdfProps key={index} typeProps={item} style={styleToPass}>
          {titleLabel ? (
            <Text
              style={{
                fontWeight: "bold",
                paddingTop: 12,
                paddingBottom: 6,
                fontSize: pdfDefaultFontSize,
                ...headerStyleToPass,
              }}
            >
              {parseFormLabelText({
                text: titleLabel,
                langVersion,
                pdf: true,
                describeMap: props.describeMap,
                objectsFieldsMap: props.objectsFieldsMap,
                renderProps: {
                  connectedMap,
                },
              })}
            </Text>
          ) : (
            <Text />
          )}

          <View
            style={{
              fontSize: pdfDefaultFontSize,
              width: "100%",
              flexDirection: "row",
              flexWrap: item.elements.length <= columns ? "nowrap" : "wrap",
            }}
          >
            {item.elements.map((element, eIndex) => {
              return (
                <FormElementGroup
                  item={{
                    ...element,
                    value: !formElementsWithoutInput.includes(
                      element.elementType
                    )
                      ? props.values[element.id]
                      : null,
                  }}
                  langVersion={langVersion}
                  key={eIndex}
                  formViewType={formViewType}
                  printWidth={String(100 / columns) + "%"}
                  {...props}
                />
              );
            })}
          </View>
        </AdjustPdfProps>
      );
    }

    const toRender = (
      <Grid key={index} style={styleToPass} item xs={12 / columns}>
        {titleLabel && (
          <div
            style={{
              marginBottom: formItemPadding,
              marginLeft: formItemPadding,
              fontSize: 16,
              ...headerStyleToPass,
            }}
          >
            {parseFormLabelText({
              text: titleLabel,
              langVersion,
              objectsFieldsMap: props.objectsFieldsMap,
              describeMap: props.describeMap,
              renderProps: {
                connectedMap,
              },
            })}
          </div>
        )}
        <Grid container direction="row" className="break-in-print">
          {item.elements.map((element, eIndex) => {
            const labelsWidth = element.labelsWidth || item.labelsWidth;
            const itemsSpacing = element.itemsSpacing || item.itemsSpacing;

            return (
              <FormElementGroup
                item={{
                  ...element,
                  value: !formElementsWithoutInput.includes(element.elementType)
                    ? props.values[element.id]
                    : null,
                  itemsSpacing: itemsSpacing,
                  labelsWidth,
                }}
                langVersion={langVersion}
                key={eIndex}
                columns={item.columns}
                skipCard={true}
                renderPrint={renderPrint}
                formViewType={formViewType}
                {...props}
              />
            );
          })}
        </Grid>
      </Grid>
    );

    if (renderPrint && !skipCard) {
      const containerStyle = {
        padding: formPrintViewSpacing,
      };
      if (props.lastInSection && props.printView) {
        containerStyle.paddingBottom = 0;
      }
      return (
        <div style={containerStyle}>
          <Paper
            elevation={3}
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            {toRender}
          </Paper>
        </div>
      );
    } else {
      return toRender;
    }
  } else {
    return (
      <FormElement
        item={item}
        langVersion={langVersion}
        index={index}
        formViewType={formViewType}
        skipCard={skipCard}
        renderPrint={renderPrint}
        xs={12 / columns}
        pdfDocument={item?.elementType === "submitButton" ? pdfDocument : null}
        {...props}
      />
    );
  }
};

export default FormElementGroup;
