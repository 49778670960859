import React from "react";

/**
 * Label used in form fields. Can be render in either normal or error state.
 * @category Common components
 * @subcategory Form
 * @component
 * @returns {JSX.Element}
 * @property {React.ReactNode} label Label content
 * @property {boolean} [error=false] If true, label will be render in the error state
 */
export const FormLabel: React.FC<{
  /**
  If true, label will be render in the error state
   */
  error: boolean;
  /**
  Label content
   */
  label: React.ReactNode;
}> = ({ error, label }) => {
  return (
    <p
      style={{
        color: error ? "#f5543b" : "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75rem",
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 14,
        textAlign: "left",
      }}
    >
      {label}
    </p>
  );
};
