import { Grid, Paper } from '@material-ui/core'
import { FormElementTitle } from '../common/FormElementTitle'
import { formComponentTypes } from '../components/formComponentTypes'
import { formElementsWithoutCardInPrintView } from '../editor/GroupElement'
import { formPrintViewSpacing } from './FormElementGroup'

export const FormElementForReadOnly = ({
  xs = 12,
  item,
  langVersion,
  connectedMap,
  describeMap,
  muBag,
  index,
  skipCard,
  lastInSection,
  paddingStyles,
  label,
  helptextAsMarkdown,
  tooltipLabel,
  helptextLabel,
  ...props
}) => {
  const type = item.elementType
  const elementData = formComponentTypes[type]
  const { labelAsMarkdown, typeProps, elementType } = item
  const { printAvoidBreak, printLabelPlacement } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'

  const alt = item.altLabelPlacement
  if (elementData.formComponentReadOnly) {
    let printRender = (
      <Grid container direction={alt ? 'row' : 'column'} wrap='nowrap'>
        <FormElementTitle
          title={label}
          helpText={!elementData?.customHelptext && helptextLabel}
          tooltip={tooltipLabel}
          id={item.id}
          altLabelPlacement={alt}
          labelAsMarkdown={labelAsMarkdown}
          helptextAsMarkdown={helptextAsMarkdown}
          targetFormType={'printable'}
          type={elementType}
        />
        {elementData.formComponentReadOnly({
          ...item,
          describeMap,
          title: label,
          connectedMap,
          editMode: false,
          muBag,
          langVersion,
          tooltip: tooltipLabel,
          ...props
        })}
      </Grid>
    )

    if (formElementsWithoutCardInPrintView.includes(elementType)) {
      if (!skipCard) {
        printRender = (
          <div style={{ padding: formPrintViewSpacing }}>{printRender}</div>
        )
      }
    } else {
      if (!skipCard) {
        const containerStyle = {
          padding: formPrintViewSpacing
        }
        if (lastInSection) {
          containerStyle.paddingBottom = 0
        }
        printRender = (
          <div style={containerStyle}>
            <Paper
              elevation={3}
              style={{
                ...paddingStyles,
                width: '100%',
                display: 'flex'
              }}
            >
              {printRender}
            </Paper>
          </div>
        )
      } else {
        printRender = (
          <div style={{ ...paddingStyles, width: '100%', display: 'flex' }}>
            {printRender}
          </div>
        )
      }
    }
    const printBreakBehaviourClass = printAvoidBreak
      ? 'avoid-print-break'
      : 'allow-print-break'
    return (
      <Grid
        key={index}
        id={item.id}
        item
        container
        xs={xs}
        style={{
          width: '100%',
          display: 'flex'
        }}
        direction={alt ? 'row' : 'column'}
        wrap='nowrap'
        className={
          typeProps.printPageBreakBefore
            ? 'page-break-before'
            : printBreakBehaviourClass
        }
      >
        {printRender}
      </Grid>
    )
  } else {
    return null
  }
}
