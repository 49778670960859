import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBudgetCategories } from '../services/sfAuth/sfData/sfBudgetCategory'
import { getRecordTypes } from '../services/sfAuth/sfData/sfOpportunity'

export const globalValues = {}

/**
 * Global wrapper that sets some global Salesforce values
 * @category App wrappers
 * @extends TextField
 * @component
 * @returns {JSX.Element}
 * @property {JSX.Element} children Children elements.
 */
export const GlobalValues = ({ children }) => {
  const user = useSelector(state => state.user)

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.id) {
      getBudgetCategories().then(result => {
        globalValues.budgetCategories = result
      })

      // TODO move this to reducer
      getRecordTypes('FGM_Base__Grantee_Report__c', true).then(recordTypes => {
        console.log('report record types', recordTypes)
        globalValues.reportRecordTypes = recordTypes
      })

      /** fetch translations and save them in redux */
      // getPortalTranslationsParsed()
      //   .then(res => {
      //     dispatch(setPortalTranslationData(res))
      //   })
      //   .catch(error => {
      //     console.error('Error fetching translations:', error)
      //   })
    }
  }, [user.id])

  return <Fragment>{children}</Fragment>
}
