import { View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'
import FormPdfFillArea from '../../pdf-components/FormPdfFillArea'

export const FormTextInputFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  ...props
}) => {
  const {
    pdfBorderDisplay,
    maxChar,
    isAdvancedTextEditor,
    rows = 1
  } = typeProps
  let baseRows = isAdvancedTextEditor ? 10 : rows
  if (maxChar) {
    baseRows = Math.ceil(maxChar / 255)
  }

  let style = {
    fontFamily:
      langVersion === 'iu-cans'
        ? inuktitutFormPdfFontFamily
        : defaultFormPdfFontFamily,
    fontSize: pdfDefaultFontSize,
    padding: 6
  }
  if (pdfBorderDisplay) {
    style = {
      ...style,
      padding: 6,
      border: '1px solid rgba(0, 0, 0, 0.02)',
      borderRadius: '5px'
    }
  }

  return (
    <View style={style}>
      <FormPdfFillArea lines={baseRows} />
    </View>
  )
}
