import { grpc } from '@improbable-eng/grpc-web'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { host } from './grpcMultiuserEdit'
import { getMultiuserColor } from './multiuserHelpers'
import { RequestStatus } from './proto/generated/MultiuserSF_pb'
import { MultiuserSF } from './proto/generated/MultiuserSF_pb_service'
import { Multiuser } from './proto/generated/Multiuser_pb_service'

export const muListenForFieldLockEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserChangedLockFieldStatus, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      if (onEventRecieved) {
        const { array } = response
        onEventRecieved({
          userId: array[0],
          operation: array[2] || 0,
          changes: array[1].map(arr => ({
            fieldId: arr[0],
            lockId: arr[1],
            fieldValue: arr[2]
          }))
        })
      }
    }
  })
}

export const muListenForMouseCursorEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserSentCursorEvent, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userId = response.array[1]
        const coordinates = JSON.parse(response.array[0])
        onEventRecieved({ userId, coordinates })
      }
    }
  })
}

export const muListenForStarEditingFormEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserHasLoggedInToRealm, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const userResponse = response.array[1]
      const colorIndex = response.array[4] || 0
      if (onEventRecieved && userResponse) {
        onEventRecieved({
          ...JSON.parse(userResponse),
          color: getMultiuserColor(colorIndex)
        })
      }
    }
  })
}

export const muListenForEndEditingFormEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserHasLoggedOutFromRealm, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved(response.array[0])
      }
    }
  })
}

export const muListenForMultiuserEvents = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserSentMultiuserEvent, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved({
          type: array[1],
          variant: array[2],
          userId: array[3]
        })
      }
    }
  })
}

export const muListenForUserInfoUpdated = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserUpdatedInfoAboutSelf, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved({
          userId: array[0],
          info: JSON.parse(array[1])
        })
      }
    }
  })
}

export const muListenForSFSaveRequest = ({ onEventRecieved, token }) => {
  return grpc.invoke(MultiuserSF.UserRequestedSFSave, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      console.log('save request message', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userRequesting = response.array[0]
        const requestType = response.array[2]
        const status = response.array[1]
        let canSave = true
        if (status === RequestStatus.BLOCKED) {
          canSave = false
        }
        onEventRecieved({
          canSave,
          requestType,
          userRequesting
        })
      }
    }
  })
}

export const muListenForSFSaveResult = ({ onEventRecieved, token }) => {
  return grpc.invoke(MultiuserSF.UserCompletedSaveToSF, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      console.log('save result form server', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userSaving = response.array[0]
        const requestType = response.array[2]
        const status = response.array[1]
        let success = true
        if (!status || status === RequestStatus.BLOCKED) {
          success = false
        }
        onEventRecieved({
          success,
          requestType,
          userSaving
        })
      }
    }
  })
}
