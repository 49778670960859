import { Checkbox, FormControlLabel } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import ReactHtmlParser from 'react-html-parser'
import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'

const useStyles = makeStyles(theme => ({
    root: {
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
      }
    }
}))

export const FormBooleanPrintEditor = ({
    id,
    value,
    disabled,
    typeProps,
    langFR,
    titleFR,
    titleEN,
    title,
    labelAsMarkdown,
    ...props
  }) => {
    title = title || (langFR ? titleFR : titleEN)
    const classes = useStyles()
    if (labelAsMarkdown) {
      title = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(title)
        .toString()
      title = <div className={classes.root}>{ReactHtmlParser(title)}</div>
    }
  
    return (
      <FormControlLabel
        control={<Checkbox disabled checked={Boolean(value)} />}
        label={title}
      />
    )
}