export const getObjNameById = ({ id, objects }) => {
  if(objects?.length) {
    const obj = objects.find(o => o.identId === id)
    return obj.name
  }
  const obj = objects?.find((o) => o[0] === id);
  return obj?.name;
};

export const getObjectsFromConnectedMap = (connectedMap) => {
  const objects = [];
  for (const [key, value] of Object.entries(connectedMap)) {
    objects.push({
      identId: key,
      name: value.objectType,
    });
  }
  return objects;
};