import { Trans } from '@lingui/macro'
import { Card, Grid, Icon, Paper, Typography } from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import moment from 'moment'
import React from 'react'

const ActionPlanToPrint = ({ columns }) => {
  return (
    <>
      {columns.map((column, columnIndex) => {
        return (
          <>
            {column.actions.length > 0 && (
              <>
                <div className='page-break' />
                <div
                  style={{
                    textAlign: 'center',
                    margin: 10,
                    fontWeight: 'bold',
                    fontSize: 24,
                    width: '100%'
                  }}
                >
                  <Trans id={column.title}/>
                </div>
              </>
            )}
            <>
              {column.actions.map((action, index) => {
                return (
                  <div key={index} style={{ padding: 10, width: '100%' }}>
                    <div
                      className='printActionPlan'
                      style={{
                        padding: 15,
                        flexGrow: 1,
                        width: '100%',
                        border: '1px solid #C4C4C4'
                      }}
                    >
                      <Grid
                        style={{ marginBottom: 10 }}
                        direction='row'
                        container
                        alignItems='center'
                      >
                        <Icon className='text-muted'>assignment</Icon>
                        <span style={{ margin: 5, fontWeight: 'bold' }}>
                          {action.title}
                        </span>
                      </Grid>
                      {Boolean(columnIndex === 0 || columnIndex === 1) && (
                        <SelectedAction {...action} />
                      )}
                      {columnIndex === 2 && <CompletedAction {...action} />}
                      {columnIndex === 3 && <OnHoldAction {...action} />}
                    </div>
                  </div>
                )
              })}
            </>
          </>
        )
      })}
    </>
  )
}

const OnHoldAction = action => {
  return (
    <>
      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans>reason for hold</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {action.failReason}
      </div>

      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans>expected outcome</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20 }}>{action.expectedOutcome}</div>
    </>
  )
}

const CompletedAction = action => {
  return (
    <>
      <Grid direction='row' container alignItems='center'>
        <Icon className='text-muted'>calendar_today</Icon>
        <span style={{ margin: 5 }} className='uppercase text-muted'>
          <Trans>due date</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {moment.utc(action.dueDate).format(dateFormat)}
      </div>
      <Grid direction='row' container alignItems='center'>
        <Icon className='text-muted'>description</Icon>
        <span style={{ margin: 5 }} className='uppercase text-muted'>
          <Trans>description</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {action.description}
      </div>
      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans>expected outcome</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {action.expectedOutcome}
      </div>

      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans>actual outcome</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {action.actualOutcome}
      </div>

      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans> success measure</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20 }}>{action.success}</div>
    </>
  )
}

const SelectedAction = action => {
  return (
    <>
      <Grid direction='row' container alignItems='center'>
        <Icon className='text-muted'>calendar_today</Icon>
        <span style={{ margin: 5 }} className='uppercase text-muted'>
          <Trans>due date</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {moment.utc(action.dueDate).format(dateFormat)}
      </div>
      <Grid direction='row' container alignItems='center'>
        <Icon className='text-muted'>description</Icon>
        <span style={{ margin: 5 }} className='uppercase text-muted'>
          <Trans>description</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
        {action.description}
      </div>
      <Grid direction='row' container alignItems='center'>
        <span style={{ marginLeft: 30 }} className='uppercase text-muted'>
          <Trans>expected outcome</Trans>
        </span>
      </Grid>
      <div style={{ marginLeft: 20 }}>{action.expectedOutcome}</div>
    </>
  )
}

export default ActionPlanToPrint
