import { Typography } from '@material-ui/core'

export const FormAccountPickerPrintEditor = ({ value, title, typeProps }) => {
  return (
    <div>
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {value && value.name}
      </Typography>
    </div>
  )
}
