import ReactHtmlParser from 'react-html-parser'
import { parseFormLabelText } from '../../common/Common'

/**
 * Form element which renders a HTML.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {string}  typeProps.html Stringified html that will be parsed by the element.
 */
export const FormHtml = ({
  typeProps,
  langVersion,
  editor,
  showPdfProps,
  showPdfComponent,
  describeMap,
  objectsFieldsMap,
  i18n
}) => {
  const { html } = typeProps
  const toParse = parseFormLabelText({
    text: html,
    i18n,
    langVersion,
    objectsFieldsMap,
    returnString: true,
    describeMap
  })

  if (editor && showPdfComponent) {
    return <div>{ReactHtmlParser(toParse)}</div>
  }

  return ReactHtmlParser(toParse)
}
