// source: Multiuser.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
goog.object.extend(proto, google_protobuf_empty_pb)
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
goog.object.extend(proto, google_protobuf_timestamp_pb)
goog.exportSymbol('proto.Mo_multiuser.AsyncLock', null, global)
goog.exportSymbol('proto.Mo_multiuser.ChatMessage', null, global)
goog.exportSymbol('proto.Mo_multiuser.ChatMessageCollection', null, global)
goog.exportSymbol(
  'proto.Mo_multiuser.ChatMessageCollectionRequest',
  null,
  global
)
goog.exportSymbol('proto.Mo_multiuser.ChatMessageSend', null, global)
goog.exportSymbol('proto.Mo_multiuser.CursorEvent', null, global)
goog.exportSymbol('proto.Mo_multiuser.CursorEventReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCache', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCacheID', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCacheList', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCacheListLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCacheLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentCacheType', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.DocumentToSubmit', null, global)
goog.exportSymbol('proto.Mo_multiuser.EventType', null, global)
goog.exportSymbol('proto.Mo_multiuser.Field', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldAndFieldValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldAndLockID', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldAndLockIDAndContent', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldAndValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldComment', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentID', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentToSend', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldIDAndLockID', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldIDLockIDFieldValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldLock', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldLockOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldsAndLocks', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldsAndValues', null, global)
goog.exportSymbol('proto.Mo_multiuser.GlobalUserSessionRequest', null, global)
goog.exportSymbol('proto.Mo_multiuser.IsTrue', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockID', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockPointer', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockState', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockStateType', null, global)
goog.exportSymbol('proto.Mo_multiuser.LocksList', null, global)
goog.exportSymbol('proto.Mo_multiuser.LocksListLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.MessageCount', null, global)
goog.exportSymbol('proto.Mo_multiuser.MultiuserError', null, global)
goog.exportSymbol('proto.Mo_multiuser.MultiuserEvent', null, global)
goog.exportSymbol('proto.Mo_multiuser.MultiuserEventReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.OperationResult', null, global)
goog.exportSymbol('proto.Mo_multiuser.Realm', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmInitRequest', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmMetadata', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmMetadataWithTarget', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmSessionInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmSessionInfoEvent', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmSessionsInfos', null, global)
goog.exportSymbol('proto.Mo_multiuser.RealmWithName', null, global)
goog.exportSymbol('proto.Mo_multiuser.SessionEventType', null, global)
goog.exportSymbol('proto.Mo_multiuser.SessionInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.SessionStartResponde', null, global)
goog.exportSymbol('proto.Mo_multiuser.Time', null, global)
goog.exportSymbol('proto.Mo_multiuser.User', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserID', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserInfoList', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserName', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserNameRequest', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserNamesMap', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserSessionRequest', null, global)
goog.exportSymbol('proto.Mo_multiuser.UsersCount', null, global)
goog.exportSymbol('proto.Mo_multiuser.UsersList', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.IsTrue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.IsTrue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.IsTrue.displayName = 'proto.Mo_multiuser.IsTrue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.Time = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.Time, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.Time.displayName = 'proto.Mo_multiuser.Time'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmInfo.displayName = 'proto.Mo_multiuser.RealmInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmMetadata = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmMetadata, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmMetadata.displayName =
    'proto.Mo_multiuser.RealmMetadata'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmMetadataWithTarget = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmMetadataWithTarget, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmMetadataWithTarget.displayName =
    'proto.Mo_multiuser.RealmMetadataWithTarget'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.Realm = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.Realm, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.Realm.displayName = 'proto.Mo_multiuser.Realm'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmInitRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmInitRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmInitRequest.displayName =
    'proto.Mo_multiuser.RealmInitRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserSessionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserSessionRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserSessionRequest.displayName =
    'proto.Mo_multiuser.UserSessionRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.GlobalUserSessionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.GlobalUserSessionRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.GlobalUserSessionRequest.displayName =
    'proto.Mo_multiuser.GlobalUserSessionRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.User = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.User, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.User.displayName = 'proto.Mo_multiuser.User'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserInfo.displayName = 'proto.Mo_multiuser.UserInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserID.displayName = 'proto.Mo_multiuser.UserID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UsersList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.UsersList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.UsersList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UsersList.displayName = 'proto.Mo_multiuser.UsersList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserNamesMap = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserNamesMap, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserNamesMap.displayName =
    'proto.Mo_multiuser.UserNamesMap'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserInfoList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.UserInfoList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.UserInfoList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserInfoList.displayName =
    'proto.Mo_multiuser.UserInfoList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UsersCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UsersCount, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UsersCount.displayName = 'proto.Mo_multiuser.UsersCount'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserName = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserName, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserName.displayName = 'proto.Mo_multiuser.UserName'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserNameRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserNameRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserNameRequest.displayName =
    'proto.Mo_multiuser.UserNameRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmSessionsInfos = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.RealmSessionsInfos.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.RealmSessionsInfos, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmSessionsInfos.displayName =
    'proto.Mo_multiuser.RealmSessionsInfos'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SessionInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SessionInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SessionInfo.displayName = 'proto.Mo_multiuser.SessionInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmSessionInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmSessionInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmSessionInfo.displayName =
    'proto.Mo_multiuser.RealmSessionInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmSessionInfoEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmSessionInfoEvent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmSessionInfoEvent.displayName =
    'proto.Mo_multiuser.RealmSessionInfoEvent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockID.displayName = 'proto.Mo_multiuser.LockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.AsyncLock = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.AsyncLock, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.AsyncLock.displayName = 'proto.Mo_multiuser.AsyncLock'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockPointer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockPointer, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockPointer.displayName = 'proto.Mo_multiuser.LockPointer'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldAndFieldValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldAndFieldValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldAndFieldValue.displayName =
    'proto.Mo_multiuser.FieldAndFieldValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldsAndValues = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FieldsAndValues.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FieldsAndValues, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldsAndValues.displayName =
    'proto.Mo_multiuser.FieldsAndValues'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldsAndLocks = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FieldsAndLocks.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FieldsAndLocks, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldsAndLocks.displayName =
    'proto.Mo_multiuser.FieldsAndLocks'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldIDAndLockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldIDAndLockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldIDAndLockID.displayName =
    'proto.Mo_multiuser.FieldIDAndLockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldLockOperation = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FieldLockOperation.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FieldLockOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldLockOperation.displayName =
    'proto.Mo_multiuser.FieldLockOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldAndValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldAndValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldAndValue.displayName =
    'proto.Mo_multiuser.FieldAndValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldLock = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldLock, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldLock.displayName = 'proto.Mo_multiuser.FieldLock'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldIDLockIDFieldValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.displayName =
    'proto.Mo_multiuser.FieldIDLockIDFieldValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldAndLockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldAndLockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldAndLockID.displayName =
    'proto.Mo_multiuser.FieldAndLockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldAndLockIDAndContent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldAndLockIDAndContent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldAndLockIDAndContent.displayName =
    'proto.Mo_multiuser.FieldAndLockIDAndContent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.Field = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.Field, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.Field.displayName = 'proto.Mo_multiuser.Field'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LocksListLite = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.LocksListLite.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.LocksListLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LocksListLite.displayName =
    'proto.Mo_multiuser.LocksListLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LocksList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.LocksList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.LocksList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LocksList.displayName = 'proto.Mo_multiuser.LocksList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockStateType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockStateType, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockStateType.displayName =
    'proto.Mo_multiuser.LockStateType'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SessionStartResponde = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SessionStartResponde, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SessionStartResponde.displayName =
    'proto.Mo_multiuser.SessionStartResponde'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentCacheID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.DocumentCacheID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentCacheID.displayName =
    'proto.Mo_multiuser.DocumentCacheID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RealmWithName = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RealmWithName, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RealmWithName.displayName =
    'proto.Mo_multiuser.RealmWithName'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentCache = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.DocumentCache.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.DocumentCache, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentCache.displayName =
    'proto.Mo_multiuser.DocumentCache'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentCacheListLite = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.DocumentCacheListLite.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.DocumentCacheListLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentCacheListLite.displayName =
    'proto.Mo_multiuser.DocumentCacheListLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentCacheList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.DocumentCacheList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.DocumentCacheList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentCacheList.displayName =
    'proto.Mo_multiuser.DocumentCacheList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentCacheLite = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.DocumentCacheLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentCacheLite.displayName =
    'proto.Mo_multiuser.DocumentCacheLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentToSubmit = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.DocumentToSubmit.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.DocumentToSubmit, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentToSubmit.displayName =
    'proto.Mo_multiuser.DocumentToSubmit'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.DocumentOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.DocumentOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.DocumentOperation.displayName =
    'proto.Mo_multiuser.DocumentOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentID.displayName =
    'proto.Mo_multiuser.FieldCommentID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentToSend = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentToSend, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentToSend.displayName =
    'proto.Mo_multiuser.FieldCommentToSend'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldComment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldComment, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldComment.displayName =
    'proto.Mo_multiuser.FieldComment'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentOperation.displayName =
    'proto.Mo_multiuser.FieldCommentOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.CursorEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.CursorEvent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.CursorEvent.displayName = 'proto.Mo_multiuser.CursorEvent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.CursorEventReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.CursorEventReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.CursorEventReceive.displayName =
    'proto.Mo_multiuser.CursorEventReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.MultiuserEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.MultiuserEvent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.MultiuserEvent.displayName =
    'proto.Mo_multiuser.MultiuserEvent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.MultiuserEventReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.MultiuserEventReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.MultiuserEventReceive.displayName =
    'proto.Mo_multiuser.MultiuserEventReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageSend = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessageSend, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageSend.displayName =
    'proto.Mo_multiuser.ChatMessageSend'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageCollection = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.ChatMessageCollection.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.ChatMessageCollection, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageCollection.displayName =
    'proto.Mo_multiuser.ChatMessageCollection'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessageCollectionRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.displayName =
    'proto.Mo_multiuser.ChatMessageCollectionRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.MessageCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.MessageCount, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.MessageCount.displayName =
    'proto.Mo_multiuser.MessageCount'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessage, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessage.displayName = 'proto.Mo_multiuser.ChatMessage'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.IsTrue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.IsTrue.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.IsTrue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.IsTrue.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        istrue: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.IsTrue}
 */
proto.Mo_multiuser.IsTrue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.IsTrue()
  return proto.Mo_multiuser.IsTrue.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.IsTrue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.IsTrue}
 */
proto.Mo_multiuser.IsTrue.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIstrue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.IsTrue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.IsTrue.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.IsTrue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.IsTrue.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getIstrue()
  if (f) {
    writer.writeBool(1, f)
  }
}

/**
 * optional bool Istrue = 1;
 * @return {boolean}
 */
proto.Mo_multiuser.IsTrue.prototype.getIstrue = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.Mo_multiuser.IsTrue} returns this
 */
proto.Mo_multiuser.IsTrue.prototype.setIstrue = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.Time.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.Time.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.Time} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.Time.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        remainingtime: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          1,
          0.0
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.Time}
 */
proto.Mo_multiuser.Time.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.Time()
  return proto.Mo_multiuser.Time.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.Time} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.Time}
 */
proto.Mo_multiuser.Time.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setRemainingtime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.Time.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.Time.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.Time} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.Time.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getRemainingtime()
  if (f !== 0.0) {
    writer.writeDouble(1, f)
  }
}

/**
 * optional double RemainingTime = 1;
 * @return {number}
 */
proto.Mo_multiuser.Time.prototype.getRemainingtime = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.Time} returns this
 */
proto.Mo_multiuser.Time.prototype.setRemainingtime = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmInfo.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : []
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmInfo}
 */
proto.Mo_multiuser.RealmInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmInfo()
  return proto.Mo_multiuser.RealmInfo.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmInfo}
 */
proto.Mo_multiuser.RealmInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 4:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      4,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.RealmInfo.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmInfo} returns this
 */
proto.Mo_multiuser.RealmInfo.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * map<string, string> Metadata = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.RealmInfo.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 4, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.RealmInfo} returns this
 */
proto.Mo_multiuser.RealmInfo.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmMetadata.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmMetadata.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmMetadata.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : []
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmMetadata}
 */
proto.Mo_multiuser.RealmMetadata.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmMetadata()
  return proto.Mo_multiuser.RealmMetadata.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmMetadata}
 */
proto.Mo_multiuser.RealmMetadata.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmMetadata.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmMetadata.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmMetadata.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      1,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
}

/**
 * map<string, string> Metadata = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.RealmMetadata.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 1, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.RealmMetadata} returns this
 */
proto.Mo_multiuser.RealmMetadata.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmMetadataWithTarget.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmMetadataWithTarget.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmMetadataWithTarget} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmMetadataWithTarget.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        realmpath: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmMetadataWithTarget}
 */
proto.Mo_multiuser.RealmMetadataWithTarget.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmMetadataWithTarget()
  return proto.Mo_multiuser.RealmMetadataWithTarget.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmMetadataWithTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmMetadataWithTarget}
 */
proto.Mo_multiuser.RealmMetadataWithTarget.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = msg.getMetadataMap()
          reader.readMessage(value, function (message, reader) {
            jspb.Map.deserializeBinary(
              message,
              reader,
              jspb.BinaryReader.prototype.readString,
              jspb.BinaryReader.prototype.readString,
              null,
              '',
              ''
            )
          })
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setRealmpath(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmMetadataWithTarget.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.RealmMetadataWithTarget.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmMetadataWithTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmMetadataWithTarget.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      1,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * map<string, string> Metadata = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.RealmMetadataWithTarget.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 1, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.RealmMetadataWithTarget} returns this
 */
proto.Mo_multiuser.RealmMetadataWithTarget.prototype.clearMetadataMap =
  function () {
    this.getMetadataMap().clear()
    return this
  }

/**
 * optional string RealmPath = 2;
 * @return {string}
 */
proto.Mo_multiuser.RealmMetadataWithTarget.prototype.getRealmpath =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmMetadataWithTarget} returns this
 */
proto.Mo_multiuser.RealmMetadataWithTarget.prototype.setRealmpath = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.Realm.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.Realm.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.Realm} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.Realm.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.Realm}
 */
proto.Mo_multiuser.Realm.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.Realm()
  return proto.Mo_multiuser.Realm.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.Realm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.Realm}
 */
proto.Mo_multiuser.Realm.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.Realm.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.Realm.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.Realm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.Realm.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.Realm.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.Realm} returns this
 */
proto.Mo_multiuser.Realm.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmInitRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmInitRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmInitRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmInitRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : []
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmInitRequest}
 */
proto.Mo_multiuser.RealmInitRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmInitRequest()
  return proto.Mo_multiuser.RealmInitRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmInitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmInitRequest}
 */
proto.Mo_multiuser.RealmInitRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmInitRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmInitRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmInitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmInitRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      4,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.RealmInitRequest.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmInitRequest} returns this
 */
proto.Mo_multiuser.RealmInitRequest.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.RealmInitRequest.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmInitRequest} returns this
 */
proto.Mo_multiuser.RealmInitRequest.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * map<string, string> Metadata = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.RealmInitRequest.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 4, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.RealmInitRequest} returns this
 */
proto.Mo_multiuser.RealmInitRequest.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserSessionRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserSessionRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserSessionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserSessionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 3, ''),
        username: jspb.Message.getFieldWithDefault(msg, 4, ''),
        token: jspb.Message.getFieldWithDefault(msg, 5, ''),
        mode: jspb.Message.getFieldWithDefault(msg, 6, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserSessionRequest}
 */
proto.Mo_multiuser.UserSessionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserSessionRequest()
  return proto.Mo_multiuser.UserSessionRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserSessionRequest}
 */
proto.Mo_multiuser.UserSessionRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setToken(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setMode(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserSessionRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserSessionRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getToken()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getMode()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setRealmpath = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserData = 3;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setUserdata = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string Token = 5;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setToken = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string Mode = 6;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionRequest.prototype.getMode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionRequest} returns this
 */
proto.Mo_multiuser.UserSessionRequest.prototype.setMode = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.GlobalUserSessionRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.GlobalUserSessionRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.GlobalUserSessionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.GlobalUserSessionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 3, ''),
        username: jspb.Message.getFieldWithDefault(msg, 4, ''),
        token: jspb.Message.getFieldWithDefault(msg, 5, ''),
        mode: jspb.Message.getFieldWithDefault(msg, 6, ''),
        browsercardid: jspb.Message.getFieldWithDefault(msg, 8, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.GlobalUserSessionRequest()
  return proto.Mo_multiuser.GlobalUserSessionRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.GlobalUserSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setRealmpath(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setUserid(value)
          break
        case 3:
          var value = /** @type {string} */ (reader.readString())
          msg.setUserdata(value)
          break
        case 4:
          var value = /** @type {string} */ (reader.readString())
          msg.setUsername(value)
          break
        case 5:
          var value = /** @type {string} */ (reader.readString())
          msg.setToken(value)
          break
        case 6:
          var value = /** @type {string} */ (reader.readString())
          msg.setMode(value)
          break
        case 8:
          var value = /** @type {string} */ (reader.readString())
          msg.setBrowsercardid(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.GlobalUserSessionRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.GlobalUserSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.GlobalUserSessionRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getToken()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getMode()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getBrowsercardid()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getRealmpath =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setRealmpath = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserData = 3;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getUserdata =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setUserdata = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getUsername =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setUsername = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string Token = 5;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string Mode = 6;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getMode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setMode = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string BrowserCardID = 8;
 * @return {string}
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.getBrowsercardid =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.GlobalUserSessionRequest} returns this
 */
proto.Mo_multiuser.GlobalUserSessionRequest.prototype.setBrowsercardid =
  function (value) {
    return jspb.Message.setProto3StringField(this, 8, value)
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.User.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.User.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.User} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.User.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.User}
 */
proto.Mo_multiuser.User.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.User()
  return proto.Mo_multiuser.User.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.User}
 */
proto.Mo_multiuser.User.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.User.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.User.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.User.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.User.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.User} returns this
 */
proto.Mo_multiuser.User.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserInfo.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 2, ''),
        logtime:
          (f = msg.getLogtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        username: jspb.Message.getFieldWithDefault(msg, 4, ''),
        sequencialnumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
        mode: jspb.Message.getFieldWithDefault(msg, 6, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserInfo}
 */
proto.Mo_multiuser.UserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserInfo()
  return proto.Mo_multiuser.UserInfo.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserInfo}
 */
proto.Mo_multiuser.UserInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLogtime(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setSequencialnumber(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setMode(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLogtime()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getSequencialnumber()
  if (f !== 0) {
    writer.writeInt32(5, f)
  }
  f = message.getMode()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserData = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUserdata = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional google.protobuf.Timestamp LogTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.UserInfo.prototype.getLogtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setLogtime = function (value) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.clearLogtime = function () {
  return this.setLogtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.UserInfo.prototype.hasLogtime = function () {
  return jspb.Message.getField(this, 3) != null
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional int32 SequencialNumber = 5;
 * @return {number}
 */
proto.Mo_multiuser.UserInfo.prototype.getSequencialnumber = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setSequencialnumber = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value)
}

/**
 * optional string Mode = 6;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getMode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setMode = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        user: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserID}
 */
proto.Mo_multiuser.UserID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserID()
  return proto.Mo_multiuser.UserID.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserID}
 */
proto.Mo_multiuser.UserID.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUser(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserID.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getUser()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string User = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserID.prototype.getUser = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserID} returns this
 */
proto.Mo_multiuser.UserID.prototype.setUser = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.UsersList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UsersList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UsersList.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UsersList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UsersList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        useridList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UsersList}
 */
proto.Mo_multiuser.UsersList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UsersList()
  return proto.Mo_multiuser.UsersList.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UsersList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UsersList}
 */
proto.Mo_multiuser.UsersList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.addUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UsersList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UsersList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UsersList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UsersList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUseridList()
  if (f.length > 0) {
    writer.writeRepeatedString(1, f)
  }
}

/**
 * repeated string UserID = 1;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.UsersList.prototype.getUseridList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.UsersList} returns this
 */
proto.Mo_multiuser.UsersList.prototype.setUseridList = function (value) {
  return jspb.Message.setField(this, 1, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.UsersList} returns this
 */
proto.Mo_multiuser.UsersList.prototype.addUserid = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.UsersList} returns this
 */
proto.Mo_multiuser.UsersList.prototype.clearUseridList = function () {
  return this.setUseridList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserNamesMap.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserNamesMap.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserNamesMap} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserNamesMap.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        usersMap: (f = msg.getUsersMap())
          ? f.toObject(includeInstance, undefined)
          : []
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserNamesMap}
 */
proto.Mo_multiuser.UserNamesMap.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserNamesMap()
  return proto.Mo_multiuser.UserNamesMap.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserNamesMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserNamesMap}
 */
proto.Mo_multiuser.UserNamesMap.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = msg.getUsersMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserNamesMap.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserNamesMap.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserNamesMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserNamesMap.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUsersMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      1,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
}

/**
 * map<string, string> Users = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.UserNamesMap.prototype.getUsersMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 1, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.UserNamesMap} returns this
 */
proto.Mo_multiuser.UserNamesMap.prototype.clearUsersMap = function () {
  this.getUsersMap().clear()
  return this
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.UserInfoList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserInfoList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserInfoList.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserInfoList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserInfoList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userinfosList: jspb.Message.toObjectList(
          msg.getUserinfosList(),
          proto.Mo_multiuser.UserInfo.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserInfoList}
 */
proto.Mo_multiuser.UserInfoList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserInfoList()
  return proto.Mo_multiuser.UserInfoList.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserInfoList}
 */
proto.Mo_multiuser.UserInfoList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.UserInfo()
        reader.readMessage(
          value,
          proto.Mo_multiuser.UserInfo.deserializeBinaryFromReader
        )
        msg.addUserinfos(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserInfoList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserInfoList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserInfoList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserinfosList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.UserInfo.serializeBinaryToWriter
    )
  }
}

/**
 * repeated UserInfo UserInfos = 1;
 * @return {!Array<!proto.Mo_multiuser.UserInfo>}
 */
proto.Mo_multiuser.UserInfoList.prototype.getUserinfosList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Mo_multiuser.UserInfo, 1)
  )
}

/**
 * @param {!Array<!proto.Mo_multiuser.UserInfo>} value
 * @return {!proto.Mo_multiuser.UserInfoList} returns this
 */
proto.Mo_multiuser.UserInfoList.prototype.setUserinfosList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.UserInfo}
 */
proto.Mo_multiuser.UserInfoList.prototype.addUserinfos = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.UserInfo,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.UserInfoList} returns this
 */
proto.Mo_multiuser.UserInfoList.prototype.clearUserinfosList = function () {
  return this.setUserinfosList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UsersCount.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UsersCount.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UsersCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UsersCount.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        count: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UsersCount}
 */
proto.Mo_multiuser.UsersCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UsersCount()
  return proto.Mo_multiuser.UsersCount.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UsersCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UsersCount}
 */
proto.Mo_multiuser.UsersCount.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setCount(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UsersCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UsersCount.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UsersCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UsersCount.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getCount()
  if (f !== 0) {
    writer.writeInt32(1, f)
  }
}

/**
 * optional int32 Count = 1;
 * @return {number}
 */
proto.Mo_multiuser.UsersCount.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.UsersCount} returns this
 */
proto.Mo_multiuser.UsersCount.prototype.setCount = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserName.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserName.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserName} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserName.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        user: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserName}
 */
proto.Mo_multiuser.UserName.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserName()
  return proto.Mo_multiuser.UserName.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserName}
 */
proto.Mo_multiuser.UserName.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUser(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserName.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserName.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserName.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUser()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string User = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserName.prototype.getUser = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserName} returns this
 */
proto.Mo_multiuser.UserName.prototype.setUser = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserNameRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserNameRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserNameRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserNameRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        placeintime:
          (f = msg.getPlaceintime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserNameRequest}
 */
proto.Mo_multiuser.UserNameRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserNameRequest()
  return proto.Mo_multiuser.UserNameRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserNameRequest}
 */
proto.Mo_multiuser.UserNameRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setPlaceintime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserNameRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserNameRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserNameRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getPlaceintime()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserNameRequest.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserNameRequest} returns this
 */
proto.Mo_multiuser.UserNameRequest.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional google.protobuf.Timestamp PlaceInTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.UserNameRequest.prototype.getPlaceintime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.UserNameRequest} returns this
 */
proto.Mo_multiuser.UserNameRequest.prototype.setPlaceintime = function (value) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.UserNameRequest} returns this
 */
proto.Mo_multiuser.UserNameRequest.prototype.clearPlaceintime = function () {
  return this.setPlaceintime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.UserNameRequest.prototype.hasPlaceintime = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.RealmSessionsInfos.repeatedFields_ = [1, 2]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmSessionsInfos.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmSessionsInfos.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmSessionsInfos} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmSessionsInfos.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        infosList: jspb.Message.toObjectList(
          msg.getInfosList(),
          proto.Mo_multiuser.SessionInfo.toObject,
          includeInstance
        ),
        realmsList: jspb.Message.toObjectList(
          msg.getRealmsList(),
          proto.Mo_multiuser.RealmInfo.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmSessionsInfos}
 */
proto.Mo_multiuser.RealmSessionsInfos.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmSessionsInfos()
  return proto.Mo_multiuser.RealmSessionsInfos.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmSessionsInfos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmSessionsInfos}
 */
proto.Mo_multiuser.RealmSessionsInfos.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.SessionInfo()
        reader.readMessage(
          value,
          proto.Mo_multiuser.SessionInfo.deserializeBinaryFromReader
        )
        msg.addInfos(value)
        break
      case 2:
        var value = new proto.Mo_multiuser.RealmInfo()
        reader.readMessage(
          value,
          proto.Mo_multiuser.RealmInfo.deserializeBinaryFromReader
        )
        msg.addRealms(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmSessionsInfos.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmSessionsInfos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmSessionsInfos.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getInfosList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.SessionInfo.serializeBinaryToWriter
    )
  }
  f = message.getRealmsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Mo_multiuser.RealmInfo.serializeBinaryToWriter
    )
  }
}

/**
 * repeated SessionInfo Infos = 1;
 * @return {!Array<!proto.Mo_multiuser.SessionInfo>}
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.getInfosList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.SessionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.Mo_multiuser.SessionInfo,
      1
    )
  )
}

/**
 * @param {!Array<!proto.Mo_multiuser.SessionInfo>} value
 * @return {!proto.Mo_multiuser.RealmSessionsInfos} returns this
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.setInfosList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.SessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.SessionInfo}
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.addInfos = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.SessionInfo,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.RealmSessionsInfos} returns this
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.clearInfosList = function () {
  return this.setInfosList([])
}

/**
 * repeated RealmInfo Realms = 2;
 * @return {!Array<!proto.Mo_multiuser.RealmInfo>}
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.getRealmsList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.RealmInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Mo_multiuser.RealmInfo, 2)
  )
}

/**
 * @param {!Array<!proto.Mo_multiuser.RealmInfo>} value
 * @return {!proto.Mo_multiuser.RealmSessionsInfos} returns this
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.setRealmsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.Mo_multiuser.RealmInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.RealmInfo}
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.addRealms = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.Mo_multiuser.RealmInfo,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.RealmSessionsInfos} returns this
 */
proto.Mo_multiuser.RealmSessionsInfos.prototype.clearRealmsList = function () {
  return this.setRealmsList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SessionInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SessionInfo.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SessionInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SessionInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        username: jspb.Message.getFieldWithDefault(msg, 4, ''),
        sessionstarttime:
          (f = msg.getSessionstarttime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        mode: jspb.Message.getFieldWithDefault(msg, 6, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SessionInfo}
 */
proto.Mo_multiuser.SessionInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SessionInfo()
  return proto.Mo_multiuser.SessionInfo.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SessionInfo}
 */
proto.Mo_multiuser.SessionInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSessionstarttime(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setMode(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SessionInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.SessionInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SessionInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getSessionstarttime()
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getMode()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.SessionInfo.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.SessionInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.SessionInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional google.protobuf.Timestamp SessionStartTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.SessionInfo.prototype.getSessionstarttime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      5
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.setSessionstarttime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.clearSessionstarttime = function () {
  return this.setSessionstarttime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.SessionInfo.prototype.hasSessionstarttime = function () {
  return jspb.Message.getField(this, 5) != null
}

/**
 * optional string Mode = 6;
 * @return {string}
 */
proto.Mo_multiuser.SessionInfo.prototype.getMode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SessionInfo} returns this
 */
proto.Mo_multiuser.SessionInfo.prototype.setMode = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmSessionInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmSessionInfo.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmSessionInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmSessionInfo.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        username: jspb.Message.getFieldWithDefault(msg, 4, ''),
        sessionstarttime:
          (f = msg.getSessionstarttime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : []
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmSessionInfo}
 */
proto.Mo_multiuser.RealmSessionInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmSessionInfo()
  return proto.Mo_multiuser.RealmSessionInfo.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmSessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmSessionInfo}
 */
proto.Mo_multiuser.RealmSessionInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSessionstarttime(value)
        break
      case 6:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmSessionInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmSessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmSessionInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getSessionstarttime()
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      6,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional google.protobuf.Timestamp SessionStartTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.getSessionstarttime =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        5
      )
    )
  }

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.setSessionstarttime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.clearSessionstarttime =
  function () {
    return this.setSessionstarttime(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.hasSessionstarttime =
  function () {
    return jspb.Message.getField(this, 5) != null
  }

/**
 * map<string, string> Metadata = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 6, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.RealmSessionInfo} returns this
 */
proto.Mo_multiuser.RealmSessionInfo.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmSessionInfoEvent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmSessionInfoEvent.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmSessionInfoEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmSessionInfoEvent.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        info:
          (f = msg.getInfo()) &&
          proto.Mo_multiuser.RealmSessionInfo.toObject(includeInstance, f),
        eventtype: jspb.Message.getFieldWithDefault(msg, 2, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmSessionInfoEvent}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmSessionInfoEvent()
  return proto.Mo_multiuser.RealmSessionInfoEvent.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmSessionInfoEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmSessionInfoEvent}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.Mo_multiuser.RealmSessionInfo()
          reader.readMessage(
            value,
            proto.Mo_multiuser.RealmSessionInfo.deserializeBinaryFromReader
          )
          msg.setInfo(value)
          break
        case 2:
          var value = /** @type {!proto.Mo_multiuser.SessionEventType} */ (
            reader.readEnum()
          )
          msg.setEventtype(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.RealmSessionInfoEvent.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmSessionInfoEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmSessionInfoEvent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getInfo()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Mo_multiuser.RealmSessionInfo.serializeBinaryToWriter
    )
  }
  f = message.getEventtype()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
}

/**
 * optional RealmSessionInfo Info = 1;
 * @return {?proto.Mo_multiuser.RealmSessionInfo}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.getInfo = function () {
  return /** @type{?proto.Mo_multiuser.RealmSessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.Mo_multiuser.RealmSessionInfo, 1)
  )
}

/**
 * @param {?proto.Mo_multiuser.RealmSessionInfo|undefined} value
 * @return {!proto.Mo_multiuser.RealmSessionInfoEvent} returns this
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.setInfo = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.RealmSessionInfoEvent} returns this
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.clearInfo = function () {
  return this.setInfo(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.hasInfo = function () {
  return jspb.Message.getField(this, 1) != null
}

/**
 * optional SessionEventType EventType = 2;
 * @return {!proto.Mo_multiuser.SessionEventType}
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.getEventtype = function () {
  return /** @type {!proto.Mo_multiuser.SessionEventType} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.SessionEventType} value
 * @return {!proto.Mo_multiuser.RealmSessionInfoEvent} returns this
 */
proto.Mo_multiuser.RealmSessionInfoEvent.prototype.setEventtype = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockID}
 */
proto.Mo_multiuser.LockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockID()
  return proto.Mo_multiuser.LockID.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockID}
 */
proto.Mo_multiuser.LockID.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockID.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getId()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Mo_multiuser.LockID.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.LockID} returns this
 */
proto.Mo_multiuser.LockID.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.AsyncLock.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.AsyncLock.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.AsyncLock} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.AsyncLock.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        parentid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.AsyncLock}
 */
proto.Mo_multiuser.AsyncLock.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.AsyncLock()
  return proto.Mo_multiuser.AsyncLock.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.AsyncLock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.AsyncLock}
 */
proto.Mo_multiuser.AsyncLock.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setParentid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.AsyncLock.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.AsyncLock.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.AsyncLock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.AsyncLock.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getParentid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string ParentID = 2;
 * @return {string}
 */
proto.Mo_multiuser.AsyncLock.prototype.getParentid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.AsyncLock} returns this
 */
proto.Mo_multiuser.AsyncLock.prototype.setParentid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.AsyncLock.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.AsyncLock} returns this
 */
proto.Mo_multiuser.AsyncLock.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockPointer.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockPointer.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockPointer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockPointer.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        lockid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockPointer}
 */
proto.Mo_multiuser.LockPointer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockPointer()
  return proto.Mo_multiuser.LockPointer.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockPointer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockPointer}
 */
proto.Mo_multiuser.LockPointer.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockPointer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockPointer.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockPointer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockPointer.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string LockID = 1;
 * @return {string}
 */
proto.Mo_multiuser.LockPointer.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.LockPointer} returns this
 */
proto.Mo_multiuser.LockPointer.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldAndFieldValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldAndFieldValue.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldAndFieldValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldAndFieldValue.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldAndFieldValue}
 */
proto.Mo_multiuser.FieldAndFieldValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldAndFieldValue()
  return proto.Mo_multiuser.FieldAndFieldValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldAndFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldAndFieldValue}
 */
proto.Mo_multiuser.FieldAndFieldValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldAndFieldValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldAndFieldValue.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldAndFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldAndFieldValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndFieldValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndFieldValue} returns this
 */
proto.Mo_multiuser.FieldAndFieldValue.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldValue = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndFieldValue.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndFieldValue} returns this
 */
proto.Mo_multiuser.FieldAndFieldValue.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FieldsAndValues.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldsAndValues.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldsAndValues.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldsAndValues} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldsAndValues.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldsandvaluesarrayList: jspb.Message.toObjectList(
          msg.getFieldsandvaluesarrayList(),
          proto.Mo_multiuser.FieldAndValue.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldsAndValues}
 */
proto.Mo_multiuser.FieldsAndValues.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldsAndValues()
  return proto.Mo_multiuser.FieldsAndValues.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldsAndValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldsAndValues}
 */
proto.Mo_multiuser.FieldsAndValues.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FieldAndValue()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader
        )
        msg.addFieldsandvaluesarray(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldsAndValues.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldsAndValues.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldsAndValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldsAndValues.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldsandvaluesarrayList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FieldAndValue FieldsAndValuesArray = 1;
 * @return {!Array<!proto.Mo_multiuser.FieldAndValue>}
 */
proto.Mo_multiuser.FieldsAndValues.prototype.getFieldsandvaluesarrayList =
  function () {
    return /** @type{!Array<!proto.Mo_multiuser.FieldAndValue>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.Mo_multiuser.FieldAndValue,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.Mo_multiuser.FieldAndValue>} value
 * @return {!proto.Mo_multiuser.FieldsAndValues} returns this
 */
proto.Mo_multiuser.FieldsAndValues.prototype.setFieldsandvaluesarrayList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value)
  }

/**
 * @param {!proto.Mo_multiuser.FieldAndValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FieldsAndValues.prototype.addFieldsandvaluesarray =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.Mo_multiuser.FieldAndValue,
      opt_index
    )
  }

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FieldsAndValues} returns this
 */
proto.Mo_multiuser.FieldsAndValues.prototype.clearFieldsandvaluesarrayList =
  function () {
    return this.setFieldsandvaluesarrayList([])
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FieldsAndLocks.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldsAndLocks.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldsAndLocks.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldsAndLocks} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldsAndLocks.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldsandvaluesList: jspb.Message.toObjectList(
          msg.getFieldsandvaluesList(),
          proto.Mo_multiuser.FieldIDAndLockID.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldsAndLocks}
 */
proto.Mo_multiuser.FieldsAndLocks.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldsAndLocks()
  return proto.Mo_multiuser.FieldsAndLocks.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldsAndLocks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldsAndLocks}
 */
proto.Mo_multiuser.FieldsAndLocks.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FieldIDAndLockID()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader
        )
        msg.addFieldsandvalues(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldsAndLocks.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldsAndLocks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldsAndLocks.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldsandvaluesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FieldIDAndLockID FieldsAndValues = 1;
 * @return {!Array<!proto.Mo_multiuser.FieldIDAndLockID>}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.getFieldsandvaluesList =
  function () {
    return /** @type{!Array<!proto.Mo_multiuser.FieldIDAndLockID>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.Mo_multiuser.FieldIDAndLockID,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.Mo_multiuser.FieldIDAndLockID>} value
 * @return {!proto.Mo_multiuser.FieldsAndLocks} returns this
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.setFieldsandvaluesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldIDAndLockID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.addFieldsandvalues = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.FieldIDAndLockID,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FieldsAndLocks} returns this
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.clearFieldsandvaluesList =
  function () {
    return this.setFieldsandvaluesList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldIDAndLockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldIDAndLockID.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldIDAndLockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldIDAndLockID.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldIDAndLockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldIDAndLockID()
  return proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldIDAndLockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldIDAndLockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDAndLockID} returns this
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDAndLockID} returns this
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FieldLockOperation.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldLockOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldLockOperation.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldLockOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldLockOperation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldsList: jspb.Message.toObjectList(
          msg.getFieldsList(),
          proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject,
          includeInstance
        ),
        operation: jspb.Message.getFieldWithDefault(msg, 3, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldLockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldLockOperation()
  return proto.Mo_multiuser.FieldLockOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldLockOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldLockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = new proto.Mo_multiuser.FieldIDLockIDFieldValue()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader
        )
        msg.addFields(value)
        break
      case 3:
        var value = /** @type {!proto.Mo_multiuser.LockOperation} */ (
          reader.readEnum()
        )
        msg.setOperation(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldLockOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldLockOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldLockOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter
    )
  }
  f = message.getOperation()
  if (f !== 0.0) {
    writer.writeEnum(3, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * repeated FieldIDLockIDFieldValue Fields = 2;
 * @return {!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getFieldsList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.Mo_multiuser.FieldIDLockIDFieldValue,
      2
    )
  )
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setFieldsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.addFields = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.Mo_multiuser.FieldIDLockIDFieldValue,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.clearFieldsList = function () {
  return this.setFieldsList([])
}

/**
 * optional LockOperation Operation = 3;
 * @return {!proto.Mo_multiuser.LockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getOperation = function () {
  return /** @type {!proto.Mo_multiuser.LockOperation} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.LockOperation} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setOperation = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldAndValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldAndValue.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldAndValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldAndValue.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        value: jspb.Message.getFieldWithDefault(msg, 2, ''),
        lock: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FieldAndValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldAndValue()
  return proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldAndValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setValue(value)
        break
      case 3:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setLock(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldAndValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldAndValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getValue()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLock()
  if (f) {
    writer.writeBool(3, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndValue} returns this
 */
proto.Mo_multiuser.FieldAndValue.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Value = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndValue.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndValue} returns this
 */
proto.Mo_multiuser.FieldAndValue.prototype.setValue = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional bool Lock = 3;
 * @return {boolean}
 */
proto.Mo_multiuser.FieldAndValue.prototype.getLock = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.Mo_multiuser.FieldAndValue} returns this
 */
proto.Mo_multiuser.FieldAndValue.prototype.setLock = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldLock.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldLock.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldLock} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldLock.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 4, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 5, ''),
        fieldmetadata: jspb.Message.getFieldWithDefault(msg, 6, ''),
        state: jspb.Message.getFieldWithDefault(msg, 7, 0),
        parentlockid: jspb.Message.getFieldWithDefault(msg, 8, ''),
        lockstarttime:
          (f = msg.getLockstarttime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        lockcommittime:
          (f = msg.getLockcommittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        locksubmitedtime:
          (f = msg.getLocksubmitedtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.FieldLock.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldLock()
  return proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldLock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldmetadata(value)
        break
      case 7:
        var value = /** @type {!proto.Mo_multiuser.LockState} */ (
          reader.readEnum()
        )
        msg.setState(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setParentlockid(value)
        break
      case 9:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLockstarttime(value)
        break
      case 10:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLockcommittime(value)
        break
      case 11:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLocksubmitedtime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldLock.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldLock.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldLock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldLock.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getFieldmetadata()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getState()
  if (f !== 0.0) {
    writer.writeEnum(7, f)
  }
  f = message.getParentlockid()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
  f = message.getLockstarttime()
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getLockcommittime()
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getLocksubmitedtime()
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string FieldID = 4;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string FieldValue = 5;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string FieldMetadata = 6;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldmetadata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldmetadata = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional LockState State = 7;
 * @return {!proto.Mo_multiuser.LockState}
 */
proto.Mo_multiuser.FieldLock.prototype.getState = function () {
  return /** @type {!proto.Mo_multiuser.LockState} */ (
    jspb.Message.getFieldWithDefault(this, 7, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.LockState} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value)
}

/**
 * optional string ParentLockID = 8;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getParentlockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setParentlockid = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional google.protobuf.Timestamp LockStartTime = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLockstarttime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      9
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLockstarttime = function (value) {
  return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLockstarttime = function () {
  return this.setLockstarttime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLockstarttime = function () {
  return jspb.Message.getField(this, 9) != null
}

/**
 * optional google.protobuf.Timestamp LockCommitTime = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLockcommittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      10
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLockcommittime = function (value) {
  return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLockcommittime = function () {
  return this.setLockcommittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLockcommittime = function () {
  return jspb.Message.getField(this, 10) != null
}

/**
 * optional google.protobuf.Timestamp LockSubmitedTime = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLocksubmitedtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      11
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLocksubmitedtime = function (value) {
  return jspb.Message.setWrapperField(this, 11, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLocksubmitedtime = function () {
  return this.setLocksubmitedtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLocksubmitedtime = function () {
  return jspb.Message.getField(this, 11) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldIDLockIDFieldValue()
  return proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setFieldid(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setLockid(value)
          break
        case 3:
          var value = /** @type {string} */ (reader.readString())
          msg.setFieldvalue(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setLockid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getFieldvalue =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldAndLockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldAndLockID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldAndLockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldAndLockID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldAndLockID}
 */
proto.Mo_multiuser.FieldAndLockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldAndLockID()
  return proto.Mo_multiuser.FieldAndLockID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldAndLockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldAndLockID}
 */
proto.Mo_multiuser.FieldAndLockID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldAndLockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldAndLockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldAndLockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldAndLockID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndLockID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndLockID} returns this
 */
proto.Mo_multiuser.FieldAndLockID.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndLockID.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndLockID} returns this
 */
proto.Mo_multiuser.FieldAndLockID.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldAndLockIDAndContent.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldAndLockIDAndContent.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldAndLockIDAndContent}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldAndLockIDAndContent()
  return proto.Mo_multiuser.FieldAndLockIDAndContent.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldAndLockIDAndContent}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setFieldid(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setLockid(value)
          break
        case 3:
          var value = /** @type {string} */ (reader.readString())
          msg.setFieldvalue(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.FieldAndLockIDAndContent.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.setLockid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.getFieldvalue =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FieldAndLockIDAndContent.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.Field.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.Field.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.Field} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.Field.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.Field}
 */
proto.Mo_multiuser.Field.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.Field()
  return proto.Mo_multiuser.Field.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.Field}
 */
proto.Mo_multiuser.Field.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.Field.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.Field.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.Field.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.Field.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.Field} returns this
 */
proto.Mo_multiuser.Field.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.LocksListLite.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LocksListLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LocksListLite.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LocksListLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LocksListLite.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LocksListLite}
 */
proto.Mo_multiuser.LocksListLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LocksListLite()
  return proto.Mo_multiuser.LocksListLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LocksListLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LocksListLite}
 */
proto.Mo_multiuser.LocksListLite.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LocksListLite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LocksListLite.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LocksListLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LocksListLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(1, f)
  }
}

/**
 * repeated string LocksIDs = 1;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.LocksListLite.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.setLocksidsList = function (value) {
  return jspb.Message.setField(this, 1, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.LocksList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LocksList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LocksList.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LocksList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LocksList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locksList: jspb.Message.toObjectList(
          msg.getLocksList(),
          proto.Mo_multiuser.FieldLock.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LocksList}
 */
proto.Mo_multiuser.LocksList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LocksList()
  return proto.Mo_multiuser.LocksList.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LocksList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LocksList}
 */
proto.Mo_multiuser.LocksList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FieldLock()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader
        )
        msg.addLocks(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LocksList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LocksList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LocksList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LocksList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLocksList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FieldLock.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FieldLock Locks = 1;
 * @return {!Array<!proto.Mo_multiuser.FieldLock>}
 */
proto.Mo_multiuser.LocksList.prototype.getLocksList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldLock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Mo_multiuser.FieldLock, 1)
  )
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldLock>} value
 * @return {!proto.Mo_multiuser.LocksList} returns this
 */
proto.Mo_multiuser.LocksList.prototype.setLocksList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldLock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.LocksList.prototype.addLocks = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.FieldLock,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.LocksList} returns this
 */
proto.Mo_multiuser.LocksList.prototype.clearLocksList = function () {
  return this.setLocksList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockStateType.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockStateType.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockStateType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockStateType.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        lockstate: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockStateType}
 */
proto.Mo_multiuser.LockStateType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockStateType()
  return proto.Mo_multiuser.LockStateType.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockStateType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockStateType}
 */
proto.Mo_multiuser.LockStateType.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {!proto.Mo_multiuser.LockState} */ (
          reader.readEnum()
        )
        msg.setLockstate(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockStateType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockStateType.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockStateType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockStateType.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLockstate()
  if (f !== 0.0) {
    writer.writeEnum(1, f)
  }
}

/**
 * optional LockState LockState = 1;
 * @return {!proto.Mo_multiuser.LockState}
 */
proto.Mo_multiuser.LockStateType.prototype.getLockstate = function () {
  return /** @type {!proto.Mo_multiuser.LockState} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.LockState} value
 * @return {!proto.Mo_multiuser.LockStateType} returns this
 */
proto.Mo_multiuser.LockStateType.prototype.setLockstate = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SessionStartResponde.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SessionStartResponde.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SessionStartResponde} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SessionStartResponde.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        multiusertokenresponde: jspb.Message.getFieldWithDefault(msg, 1, ''),
        sequencialnumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
        isreadonly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SessionStartResponde}
 */
proto.Mo_multiuser.SessionStartResponde.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SessionStartResponde()
  return proto.Mo_multiuser.SessionStartResponde.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SessionStartResponde} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SessionStartResponde}
 */
proto.Mo_multiuser.SessionStartResponde.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setMultiusertokenresponde(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setSequencialnumber(value)
        break
      case 3:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsreadonly(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SessionStartResponde.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.SessionStartResponde.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SessionStartResponde} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SessionStartResponde.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMultiusertokenresponde()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getSequencialnumber()
  if (f !== 0) {
    writer.writeInt32(2, f)
  }
  f = message.getIsreadonly()
  if (f) {
    writer.writeBool(3, f)
  }
}

/**
 * optional string MultiuserTokenResponde = 1;
 * @return {string}
 */
proto.Mo_multiuser.SessionStartResponde.prototype.getMultiusertokenresponde =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SessionStartResponde} returns this
 */
proto.Mo_multiuser.SessionStartResponde.prototype.setMultiusertokenresponde =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value)
  }

/**
 * optional int32 SequencialNumber = 2;
 * @return {number}
 */
proto.Mo_multiuser.SessionStartResponde.prototype.getSequencialnumber =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
  }

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.SessionStartResponde} returns this
 */
proto.Mo_multiuser.SessionStartResponde.prototype.setSequencialnumber =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value)
  }

/**
 * optional bool IsReadonly = 3;
 * @return {boolean}
 */
proto.Mo_multiuser.SessionStartResponde.prototype.getIsreadonly = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.Mo_multiuser.SessionStartResponde} returns this
 */
proto.Mo_multiuser.SessionStartResponde.prototype.setIsreadonly = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentCacheID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentCacheID.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentCacheID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentCacheID.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        uniqueid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentCacheID}
 */
proto.Mo_multiuser.DocumentCacheID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentCacheID()
  return proto.Mo_multiuser.DocumentCacheID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentCacheID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentCacheID}
 */
proto.Mo_multiuser.DocumentCacheID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentCacheID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentCacheID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentCacheID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentCacheID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string UniqueID = 1;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCacheID.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCacheID} returns this
 */
proto.Mo_multiuser.DocumentCacheID.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RealmWithName.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RealmWithName.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RealmWithName} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RealmWithName.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        realmname: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RealmWithName}
 */
proto.Mo_multiuser.RealmWithName.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RealmWithName()
  return proto.Mo_multiuser.RealmWithName.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RealmWithName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RealmWithName}
 */
proto.Mo_multiuser.RealmWithName.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmname(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RealmWithName.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RealmWithName.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RealmWithName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RealmWithName.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getRealmname()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.RealmWithName.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmWithName} returns this
 */
proto.Mo_multiuser.RealmWithName.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string RealmName = 2;
 * @return {string}
 */
proto.Mo_multiuser.RealmWithName.prototype.getRealmname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RealmWithName} returns this
 */
proto.Mo_multiuser.RealmWithName.prototype.setRealmname = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.DocumentCache.repeatedFields_ = [7]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentCache.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentCache.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentCache} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentCache.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        submittime:
          (f = msg.getSubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        cachetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
        participatingusersMap: (f = msg.getParticipatingusersMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        documentname: jspb.Message.getFieldWithDefault(msg, 8, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentCache}
 */
proto.Mo_multiuser.DocumentCache.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentCache()
  return proto.Mo_multiuser.DocumentCache.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentCache} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentCache}
 */
proto.Mo_multiuser.DocumentCache.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSubmittime(value)
        break
      case 5:
        var value = /** @type {!proto.Mo_multiuser.DocumentCacheType} */ (
          reader.readEnum()
        )
        msg.setCachetype(value)
        break
      case 6:
        var value = msg.getParticipatingusersMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setDocumentname(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentCache.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentCache.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentCache} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentCache.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getSubmittime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getCachetype()
  if (f !== 0.0) {
    writer.writeEnum(5, f)
  }
  f = message.getParticipatingusersMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      6,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(7, f)
  }
  f = message.getDocumentname()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCache.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCache.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCache.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp SubmitTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.DocumentCache.prototype.getSubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setSubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.clearSubmittime = function () {
  return this.setSubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.DocumentCache.prototype.hasSubmittime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional DocumentCacheType CacheType = 5;
 * @return {!proto.Mo_multiuser.DocumentCacheType}
 */
proto.Mo_multiuser.DocumentCache.prototype.getCachetype = function () {
  return /** @type {!proto.Mo_multiuser.DocumentCacheType} */ (
    jspb.Message.getFieldWithDefault(this, 5, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.DocumentCacheType} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setCachetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value)
}

/**
 * map<string, string> ParticipatingUsers = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Mo_multiuser.DocumentCache.prototype.getParticipatingusersMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 6, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.clearParticipatingusersMap =
  function () {
    this.getParticipatingusersMap().clear()
    return this
  }

/**
 * repeated string LocksIDs = 7;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.DocumentCache.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setLocksidsList = function (value) {
  return jspb.Message.setField(this, 7, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * optional string DocumentName = 8;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCache.prototype.getDocumentname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCache} returns this
 */
proto.Mo_multiuser.DocumentCache.prototype.setDocumentname = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.DocumentCacheListLite.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentCacheListLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentCacheListLite.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentCacheListLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentCacheListLite.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        documentcacheliteList: jspb.Message.toObjectList(
          msg.getDocumentcacheliteList(),
          proto.Mo_multiuser.DocumentCacheLite.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentCacheListLite}
 */
proto.Mo_multiuser.DocumentCacheListLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentCacheListLite()
  return proto.Mo_multiuser.DocumentCacheListLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentCacheListLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentCacheListLite}
 */
proto.Mo_multiuser.DocumentCacheListLite.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.Mo_multiuser.DocumentCacheLite()
          reader.readMessage(
            value,
            proto.Mo_multiuser.DocumentCacheLite.deserializeBinaryFromReader
          )
          msg.addDocumentcachelite(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentCacheListLite.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.DocumentCacheListLite.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentCacheListLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentCacheListLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getDocumentcacheliteList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.DocumentCacheLite.serializeBinaryToWriter
    )
  }
}

/**
 * repeated DocumentCacheLite DocumentCacheLite = 1;
 * @return {!Array<!proto.Mo_multiuser.DocumentCacheLite>}
 */
proto.Mo_multiuser.DocumentCacheListLite.prototype.getDocumentcacheliteList =
  function () {
    return /** @type{!Array<!proto.Mo_multiuser.DocumentCacheLite>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.Mo_multiuser.DocumentCacheLite,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.Mo_multiuser.DocumentCacheLite>} value
 * @return {!proto.Mo_multiuser.DocumentCacheListLite} returns this
 */
proto.Mo_multiuser.DocumentCacheListLite.prototype.setDocumentcacheliteList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value)
  }

/**
 * @param {!proto.Mo_multiuser.DocumentCacheLite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.DocumentCacheLite}
 */
proto.Mo_multiuser.DocumentCacheListLite.prototype.addDocumentcachelite =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.Mo_multiuser.DocumentCacheLite,
      opt_index
    )
  }

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.DocumentCacheListLite} returns this
 */
proto.Mo_multiuser.DocumentCacheListLite.prototype.clearDocumentcacheliteList =
  function () {
    return this.setDocumentcacheliteList([])
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.DocumentCacheList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentCacheList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentCacheList.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentCacheList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentCacheList.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        documentcacheList: jspb.Message.toObjectList(
          msg.getDocumentcacheList(),
          proto.Mo_multiuser.DocumentCache.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentCacheList}
 */
proto.Mo_multiuser.DocumentCacheList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentCacheList()
  return proto.Mo_multiuser.DocumentCacheList.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentCacheList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentCacheList}
 */
proto.Mo_multiuser.DocumentCacheList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.DocumentCache()
        reader.readMessage(
          value,
          proto.Mo_multiuser.DocumentCache.deserializeBinaryFromReader
        )
        msg.addDocumentcache(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentCacheList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentCacheList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentCacheList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentCacheList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getDocumentcacheList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.DocumentCache.serializeBinaryToWriter
    )
  }
}

/**
 * repeated DocumentCache DocumentCache = 1;
 * @return {!Array<!proto.Mo_multiuser.DocumentCache>}
 */
proto.Mo_multiuser.DocumentCacheList.prototype.getDocumentcacheList =
  function () {
    return /** @type{!Array<!proto.Mo_multiuser.DocumentCache>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.Mo_multiuser.DocumentCache,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.Mo_multiuser.DocumentCache>} value
 * @return {!proto.Mo_multiuser.DocumentCacheList} returns this
 */
proto.Mo_multiuser.DocumentCacheList.prototype.setDocumentcacheList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.DocumentCache=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.DocumentCache}
 */
proto.Mo_multiuser.DocumentCacheList.prototype.addDocumentcache = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.DocumentCache,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.DocumentCacheList} returns this
 */
proto.Mo_multiuser.DocumentCacheList.prototype.clearDocumentcacheList =
  function () {
    return this.setDocumentcacheList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentCacheLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentCacheLite.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentCacheLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentCacheLite.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        uniqueid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        submittime:
          (f = msg.getSubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentCacheLite}
 */
proto.Mo_multiuser.DocumentCacheLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentCacheLite()
  return proto.Mo_multiuser.DocumentCacheLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentCacheLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentCacheLite}
 */
proto.Mo_multiuser.DocumentCacheLite.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSubmittime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentCacheLite.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentCacheLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentCacheLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getSubmittime()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string UniqueID = 1;
 * @return {string}
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentCacheLite} returns this
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional google.protobuf.Timestamp SubmitTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.getSubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      2
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.DocumentCacheLite} returns this
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.setSubmittime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.DocumentCacheLite} returns this
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.clearSubmittime = function () {
  return this.setSubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.DocumentCacheLite.prototype.hasSubmittime = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.DocumentToSubmit.repeatedFields_ = [3, 6]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentToSubmit.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentToSubmit.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentToSubmit} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentToSubmit.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, ''),
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
        cachetype: jspb.Message.getFieldWithDefault(msg, 4, 0),
        participatingusersidsList:
          (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentToSubmit}
 */
proto.Mo_multiuser.DocumentToSubmit.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentToSubmit()
  return proto.Mo_multiuser.DocumentToSubmit.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentToSubmit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentToSubmit}
 */
proto.Mo_multiuser.DocumentToSubmit.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      case 4:
        var value = /** @type {!proto.Mo_multiuser.DocumentCacheType} */ (
          reader.readEnum()
        )
        msg.setCachetype(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.addParticipatingusersids(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentToSubmit.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentToSubmit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentToSubmit.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(3, f)
  }
  f = message.getCachetype()
  if (f !== 0.0) {
    writer.writeEnum(4, f)
  }
  f = message.getParticipatingusersidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(6, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * repeated string LocksIDs = 3;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.setLocksidsList = function (
  value
) {
  return jspb.Message.setField(this, 3, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * optional DocumentCacheType CacheType = 4;
 * @return {!proto.Mo_multiuser.DocumentCacheType}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.getCachetype = function () {
  return /** @type {!proto.Mo_multiuser.DocumentCacheType} */ (
    jspb.Message.getFieldWithDefault(this, 4, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.DocumentCacheType} value
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.setCachetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value)
}

/**
 * repeated string ParticipatingUsersIDs = 6;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.getParticipatingusersidsList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 6)
    )
  }

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.setParticipatingusersidsList =
  function (value) {
    return jspb.Message.setField(this, 6, value || [])
  }

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.addParticipatingusersids =
  function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 6, value, opt_index)
  }

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.DocumentToSubmit} returns this
 */
proto.Mo_multiuser.DocumentToSubmit.prototype.clearParticipatingusersidsList =
  function () {
    return this.setParticipatingusersidsList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.DocumentOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.DocumentOperation.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.DocumentOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.DocumentOperation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realmpath: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.DocumentOperation}
 */
proto.Mo_multiuser.DocumentOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.DocumentOperation()
  return proto.Mo_multiuser.DocumentOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.DocumentOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.DocumentOperation}
 */
proto.Mo_multiuser.DocumentOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setRealmpath(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.OperationResult} */ (
          reader.readEnum()
        )
        msg.setResult(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.DocumentOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.DocumentOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.DocumentOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.DocumentOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealmpath()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
}

/**
 * optional string RealmPath = 1;
 * @return {string}
 */
proto.Mo_multiuser.DocumentOperation.prototype.getRealmpath = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.DocumentOperation} returns this
 */
proto.Mo_multiuser.DocumentOperation.prototype.setRealmpath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional OperationResult Result = 2;
 * @return {!proto.Mo_multiuser.OperationResult}
 */
proto.Mo_multiuser.DocumentOperation.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.OperationResult} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.OperationResult} value
 * @return {!proto.Mo_multiuser.DocumentOperation} returns this
 */
proto.Mo_multiuser.DocumentOperation.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentID}
 */
proto.Mo_multiuser.FieldCommentID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentID()
  return proto.Mo_multiuser.FieldCommentID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentID}
 */
proto.Mo_multiuser.FieldCommentID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldCommentID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentID} returns this
 */
proto.Mo_multiuser.FieldCommentID.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentToSend.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentToSend.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentToSend} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentToSend.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentToSend}
 */
proto.Mo_multiuser.FieldCommentToSend.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentToSend()
  return proto.Mo_multiuser.FieldCommentToSend.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentToSend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentToSend}
 */
proto.Mo_multiuser.FieldCommentToSend.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldCommentToSend.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentToSend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentToSend.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentToSend} returns this
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentToSend} returns this
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldComment.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldComment.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldComment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldComment.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, ''),
        lastsubmiteruserid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        lastsubmittime:
          (f = msg.getLastsubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        currentuserid: jspb.Message.getFieldWithDefault(msg, 5, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldComment}
 */
proto.Mo_multiuser.FieldComment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldComment()
  return proto.Mo_multiuser.FieldComment.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldComment}
 */
proto.Mo_multiuser.FieldComment.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setLastsubmiteruserid(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLastsubmittime(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setCurrentuserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldComment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldComment.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldComment.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLastsubmiteruserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getLastsubmittime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getCurrentuserid()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string LastSubmiterUserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getLastsubmiteruserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setLastsubmiteruserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp LastSubmitTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldComment.prototype.getLastsubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      4
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setLastsubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.clearLastsubmittime = function () {
  return this.setLastsubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldComment.prototype.hasLastsubmittime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional string CurrentUserID = 5;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getCurrentuserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setCurrentuserid = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentOperation.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentOperation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, ''),
        operation: jspb.Message.getFieldWithDefault(msg, 3, 0),
        userid: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentOperation()
  return proto.Mo_multiuser.FieldCommentOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setFieldid(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setContent(value)
          break
        case 3:
          var value = /** @type {!proto.Mo_multiuser.LockOperation} */ (
            reader.readEnum()
          )
          msg.setOperation(value)
          break
        case 4:
          var value = /** @type {string} */ (reader.readString())
          msg.setUserid(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.FieldCommentOperation.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getOperation()
  if (f !== 0.0) {
    writer.writeEnum(3, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setContent = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional LockOperation Operation = 3;
 * @return {!proto.Mo_multiuser.LockOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getOperation = function () {
  return /** @type {!proto.Mo_multiuser.LockOperation} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.LockOperation} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setOperation = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value)
}

/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.CursorEvent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.CursorEvent.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.CursorEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.CursorEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventvalue: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.CursorEvent}
 */
proto.Mo_multiuser.CursorEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.CursorEvent()
  return proto.Mo_multiuser.CursorEvent.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.CursorEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.CursorEvent}
 */
proto.Mo_multiuser.CursorEvent.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setEventvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.CursorEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.CursorEvent.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.CursorEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.CursorEvent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string EventValue = 1;
 * @return {string}
 */
proto.Mo_multiuser.CursorEvent.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEvent} returns this
 */
proto.Mo_multiuser.CursorEvent.prototype.setEventvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.CursorEventReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.CursorEventReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.CursorEventReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.CursorEventReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        eventvalue: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.CursorEventReceive}
 */
proto.Mo_multiuser.CursorEventReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.CursorEventReceive()
  return proto.Mo_multiuser.CursorEventReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.CursorEventReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.CursorEventReceive}
 */
proto.Mo_multiuser.CursorEventReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setEventvalue(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.CursorEventReceive.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.CursorEventReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.CursorEventReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string EventValue = 1;
 * @return {string}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEventReceive} returns this
 */
proto.Mo_multiuser.CursorEventReceive.prototype.setEventvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEventReceive} returns this
 */
proto.Mo_multiuser.CursorEventReceive.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.MultiuserEvent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.MultiuserEvent.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.MultiuserEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.MultiuserEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventvalue: jspb.Message.getFieldWithDefault(msg, 1, ''),
        type: jspb.Message.getFieldWithDefault(msg, 2, ''),
        eventtype: jspb.Message.getFieldWithDefault(msg, 3, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.MultiuserEvent}
 */
proto.Mo_multiuser.MultiuserEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.MultiuserEvent()
  return proto.Mo_multiuser.MultiuserEvent.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.MultiuserEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.MultiuserEvent}
 */
proto.Mo_multiuser.MultiuserEvent.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setEventvalue(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 3:
        var value = /** @type {!proto.Mo_multiuser.EventType} */ (
          reader.readEnum()
        )
        msg.setEventtype(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.MultiuserEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.MultiuserEvent.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.MultiuserEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.MultiuserEvent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getEventtype()
  if (f !== 0.0) {
    writer.writeEnum(3, f)
  }
}

/**
 * optional string EventValue = 1;
 * @return {string}
 */
proto.Mo_multiuser.MultiuserEvent.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.MultiuserEvent} returns this
 */
proto.Mo_multiuser.MultiuserEvent.prototype.setEventvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Type = 2;
 * @return {string}
 */
proto.Mo_multiuser.MultiuserEvent.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.MultiuserEvent} returns this
 */
proto.Mo_multiuser.MultiuserEvent.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional EventType EventType = 3;
 * @return {!proto.Mo_multiuser.EventType}
 */
proto.Mo_multiuser.MultiuserEvent.prototype.getEventtype = function () {
  return /** @type {!proto.Mo_multiuser.EventType} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.EventType} value
 * @return {!proto.Mo_multiuser.MultiuserEvent} returns this
 */
proto.Mo_multiuser.MultiuserEvent.prototype.setEventtype = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.MultiuserEventReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.MultiuserEventReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.MultiuserEventReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.MultiuserEventReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        eventvalue: jspb.Message.getFieldWithDefault(msg, 1, ''),
        type: jspb.Message.getFieldWithDefault(msg, 2, ''),
        eventtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
        userid: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.MultiuserEventReceive}
 */
proto.Mo_multiuser.MultiuserEventReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.MultiuserEventReceive()
  return proto.Mo_multiuser.MultiuserEventReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.MultiuserEventReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.MultiuserEventReceive}
 */
proto.Mo_multiuser.MultiuserEventReceive.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setEventvalue(value)
          break
        case 2:
          var value = /** @type {string} */ (reader.readString())
          msg.setType(value)
          break
        case 3:
          var value = /** @type {!proto.Mo_multiuser.EventType} */ (
            reader.readEnum()
          )
          msg.setEventtype(value)
          break
        case 4:
          var value = /** @type {string} */ (reader.readString())
          msg.setUserid(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.MultiuserEventReceive.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.MultiuserEventReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.MultiuserEventReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getEventtype()
  if (f !== 0.0) {
    writer.writeEnum(3, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string EventValue = 1;
 * @return {string}
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.MultiuserEventReceive} returns this
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.setEventvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Type = 2;
 * @return {string}
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.MultiuserEventReceive} returns this
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional EventType EventType = 3;
 * @return {!proto.Mo_multiuser.EventType}
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.getEventtype = function () {
  return /** @type {!proto.Mo_multiuser.EventType} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  )
}

/**
 * @param {!proto.Mo_multiuser.EventType} value
 * @return {!proto.Mo_multiuser.MultiuserEventReceive} returns this
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.setEventtype = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value)
}

/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.MultiuserEventReceive} returns this
 */
proto.Mo_multiuser.MultiuserEventReceive.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageSend.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessageSend.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageSend} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageSend.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        messagecontent: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageSend}
 */
proto.Mo_multiuser.ChatMessageSend.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageSend()
  return proto.Mo_multiuser.ChatMessageSend.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageSend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageSend}
 */
proto.Mo_multiuser.ChatMessageSend.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setMessagecontent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageSend.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessageSend.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageSend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageSend.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMessagecontent()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string MessageContent = 1;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageSend.prototype.getMessagecontent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageSend} returns this
 */
proto.Mo_multiuser.ChatMessageSend.prototype.setMessagecontent = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.ChatMessageCollection.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageCollection.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessageCollection.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageCollection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageCollection.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        messagesList: jspb.Message.toObjectList(
          msg.getMessagesList(),
          proto.Mo_multiuser.ChatMessage.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageCollection}
 */
proto.Mo_multiuser.ChatMessageCollection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageCollection()
  return proto.Mo_multiuser.ChatMessageCollection.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageCollection}
 */
proto.Mo_multiuser.ChatMessageCollection.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.Mo_multiuser.ChatMessage()
          reader.readMessage(
            value,
            proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader
          )
          msg.addMessages(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.ChatMessageCollection.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageCollection.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMessagesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter
    )
  }
}

/**
 * repeated ChatMessage Messages = 1;
 * @return {!Array<!proto.Mo_multiuser.ChatMessage>}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.getMessagesList =
  function () {
    return /** @type{!Array<!proto.Mo_multiuser.ChatMessage>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.Mo_multiuser.ChatMessage,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.Mo_multiuser.ChatMessage>} value
 * @return {!proto.Mo_multiuser.ChatMessageCollection} returns this
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.setMessagesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.ChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.addMessages = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.ChatMessage,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.ChatMessageCollection} returns this
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.clearMessagesList =
  function () {
    return this.setMessagesList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.Mo_multiuser.ChatMessageCollectionRequest.toObject(
        opt_includeInstance,
        this
      )
    }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fromtime:
          (f = msg.getFromtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        totime:
          (f = msg.getTotime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        count: jspb.Message.getFieldWithDefault(msg, 3, 0),
        userid: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageCollectionRequest()
  return proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new google_protobuf_timestamp_pb.Timestamp()
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          )
          msg.setFromtime(value)
          break
        case 2:
          var value = new google_protobuf_timestamp_pb.Timestamp()
          reader.readMessage(
            value,
            google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
          )
          msg.setTotime(value)
          break
        case 3:
          var value = /** @type {number} */ (reader.readInt32())
          msg.setCount(value)
          break
        case 4:
          var value = /** @type {string} */ (reader.readString())
          msg.setUserid(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.Mo_multiuser.ChatMessageCollectionRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined
    f = message.getFromtime()
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      )
    }
    f = message.getTotime()
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
      )
    }
    f = message.getCount()
    if (f !== 0) {
      writer.writeInt32(3, f)
    }
    f = message.getUserid()
    if (f.length > 0) {
      writer.writeString(4, f)
    }
  }

/**
 * optional google.protobuf.Timestamp FromTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getFromtime =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        1
      )
    )
  }

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setFromtime =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value)
  }

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.clearFromtime =
  function () {
    return this.setFromtime(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.hasFromtime =
  function () {
    return jspb.Message.getField(this, 1) != null
  }

/**
 * optional google.protobuf.Timestamp ToTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getTotime =
  function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_timestamp_pb.Timestamp,
        2
      )
    )
  }

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setTotime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.clearTotime =
  function () {
    return this.setTotime(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.hasTotime =
  function () {
    return jspb.Message.getField(this, 2) != null
  }

/**
 * optional int32 Count = 3;
 * @return {number}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getCount =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
  }

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setCount = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getUserid =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
  }

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.MessageCount.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.MessageCount.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.MessageCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.MessageCount.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        messages: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.MessageCount}
 */
proto.Mo_multiuser.MessageCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.MessageCount()
  return proto.Mo_multiuser.MessageCount.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.MessageCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.MessageCount}
 */
proto.Mo_multiuser.MessageCount.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setMessages(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.MessageCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.MessageCount.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.MessageCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.MessageCount.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMessages()
  if (f !== 0) {
    writer.writeInt32(1, f)
  }
}

/**
 * optional int32 Messages = 1;
 * @return {number}
 */
proto.Mo_multiuser.MessageCount.prototype.getMessages = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.MessageCount} returns this
 */
proto.Mo_multiuser.MessageCount.prototype.setMessages = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessage.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        messagecontent: jspb.Message.getFieldWithDefault(msg, 2, ''),
        sendtime:
          (f = msg.getSendtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        username: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessage()
  return proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setMessagecontent(value)
        break
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSendtime(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getMessagecontent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getSendtime()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string MessageContent = 2;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getMessagecontent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setMessagecontent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional google.protobuf.Timestamp SendTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessage.prototype.getSendtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_timestamp_pb.Timestamp,
      3
    )
  )
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setSendtime = function (value) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.clearSendtime = function () {
  return this.setSendtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessage.prototype.hasSendtime = function () {
  return jspb.Message.getField(this, 3) != null
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.SessionEventType = {
  JOIN: 0,
  LEAVE: 1,
  STATUSCHANGED: 2
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.LockState = {
  UNCOMMITED: 0,
  COMMITTED_NORMALLY: 5,
  COMMITTED_NORMALLY_AND_SUBMITED: 6,
  COMMITTED_ASYNCHRONOUS: 10,
  COMMITTED_ASYNCHRONOUS_AND_SUBMITED: 11
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.LockOperation = {
  LOCK: 0,
  UPDATE: 5,
  COMMIT: 10,
  CANCEL: 15,
  RECLAIM: 20
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.OperationResult = {
  FAILED: 0,
  FAILED_TIMEOUT: 5,
  SUCCESS: 10
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.DocumentCacheType = {
  MANUAL_SAVE: 0,
  AUTO_SAVE: 5,
  CHANGES_NOT_SAVED: 10,
  INITIAL_DOCUMENT: 15
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.MultiuserError = {
  EVERYTHING_IS_FINE: 0,
  UNKNOWN_SERVER_ERROR: 1000,
  OPERATION_CANCELED: 1001,
  INCORRECT_AUTHENTICATION: 1002,
  EXTERNAL_AUTHENTICATION_PROVIDER_ERROR: 1003,
  SESSION_TOKEN_EXPIRED: 1004,
  RESOURCE_BELONGS_TO_ANOTHER_USER: 2001,
  FIELD_IS_ALREADY_LOCKED: 3001,
  INVALID_ARGUMENT: 5000,
  INVALID_ARGUMENT_LOCKID: 5001,
  INVALID_ARGUMENT_DOCUMENTID: 5002,
  INVALID_ARGUMENT_USERID: 5003
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.EventType = {
  EVENT_SUCCESS: 0,
  EVENT_ERROR: 1,
  EVENT_INFO: 2,
  EVENT_ALERT: 3
}

goog.object.extend(exports, proto.Mo_multiuser)
