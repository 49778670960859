export const SET_PORTAL_TRANSLATION_CONFIG = 'SET_PORTAL_TRANSLATION_CONFIG'

export function setPortalTranslationConfig (value) {
  return dispatch => {
    dispatch({
      type: SET_PORTAL_TRANSLATION_CONFIG,
      data: value
    })
  }
}
