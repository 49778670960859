export const FormMilestonesPdfEditor = ({ title }) => {
    return (
      <div className='pdf-mode'>
        <div className={`pdfProps form-print-subtitle`}>{title}</div>
        <div className='table'>
          <div className='cell'>
            <div className='subtitle'>Stage</div>
            <div className='value'>Stage Value Placeholder</div>
          </div>
          <div className='cell'>
            <div className='subtitle'>Start date</div>
            <div className='value'>Start Date Placeholder</div>
          </div>
          <div className='cell'>
            <div className='subtitle'>End date</div>
            <div className='value'>End Date Placeholder</div>
          </div>
          <div className='cell'>
            <div className='subtitle'>Primary Activities</div>
            <div className='value'>Primary Activities Placeholder</div>
          </div>
          <div className='cell'>
            <div className='subtitle'>Comments</div>
            <div className='value'>Comments Placeholder</div>
          </div>
        </div>
      </div>
    )
  }