import {
  Grid,
  Icon,
  IconButton,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { MouseEventHandler } from "react";

type SelectFieldWithClearProps = {
  onClear?: MouseEventHandler<HTMLButtonElement>;
} & TextFieldProps;

export const TextFieldWithClear = ({
  children,
  onClear = () => {},
  ...props
}: SelectFieldWithClearProps) => {
  return (
    <Grid container>
      <Grid item xs>
        <TextField {...props} value={props.value || ""}>
          {children}
        </TextField>
      </Grid>
      <Grid item>
        <IconButton onClick={onClear}>
          <Icon>close</Icon>
        </IconButton>
      </Grid>
    </Grid>
  );
};
