import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid
} from '@material-ui/core'
import { useFormikContext } from 'formik'

const { portalLanguagesData } = require('app/appSettings')
const { languages } = require('translation/I18nConnectedProvider')

/** Component to override the user language in the form.
 * This component allows selection of one of the portal languages.
 * If the language is selected, then while rendering the related form,
 * the form uses the language specified to display instead of user language
 * @component
 * @param {Object} props
 * @param {Object} props.style - style object to be applied to the component
 */
const OverrideFormLanguage = ({ style }) => {
  const { values, setFieldValue } = useFormikContext()
  const passedStyle = style || {}
  const handleChange = value => {
    const toSet = value === values?.overrideFormLanguage ? null : value
    setFieldValue('overrideFormLanguage', toSet)
  }

  return (
    <Grid style={{ paddingLeft: 10, ...passedStyle }}>
      <FormLabel
        component='legend'
        style={{ alignSelf: 'center', marginRight: '40px' }}
      >
        <Trans>OVERRIDE_FORM_LANGUAGE_LABEL</Trans>
      </FormLabel>
      <FormGroup aria-label='position' row>
        {languages.map(lang => {
          const checked = values?.overrideFormLanguage === lang
          const checkbox = (
            <Checkbox
              checked={checked}
              onClick={() => handleChange(lang)}
              key={lang}
            />
          )
          return (
            <FormControlLabel
              control={checkbox}
              label={
                portalLanguagesData[lang]?.labelTrans || (
                  <Trans>
                    OVERRIDE_FORM_LANGUAGE_NO_OVERRIDE_SELECT_CHOICE
                  </Trans>
                )
              }
            />
          )
        })}
      </FormGroup>
    </Grid>
  )
}

export default OverrideFormLanguage
