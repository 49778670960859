export const SET_TUTORIALS_DATA = 'SET_TUTORIALS_DATA'

export function setTutorialsFromSteps (steps = []) {
  console.log('set tutorials from steps', steps)
  const toSet = {}
  steps.forEach(step => {
    const { location } = step
    if (!toSet[location]) {
      toSet[location] = [step]
    } else {
      toSet[location].push(step)
    }
  })
  return dispatch => {
    dispatch({
      type: SET_TUTORIALS_DATA,
      data: toSet
    })
  }
}
