import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

export const FormTitle = ({ title, children }) => (
  <div
    style={{
      margin: '5px 0px',
      borderRadius: 4,
      padding: 15
    }}
  >
    <Grid
      container
      wrap='nowrap'
      style={{
        paddingLeft: 35,
        marginTop: 0,
        alignItems: 'center'
      }}
    >
      <DoubleArrowIcon style={{ paddingRight: 10 }} />
      <Grid container item>
        <Grid item xs>
          <Typography variant='h2'>{title}</Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  </div>
)
