import SFAuthService from '../SFAuthService'
import { NO_USER } from '../SFAuthService'

/**
 * Retrieves technical advisories assigned to a user from Salesforce.
 * @function
 * @category Salesforce - TechnicalAdvisoryAssignment__c
 * @param {string} userId The id of the user to retrieve technical advisories for.
 * @returns {TechnicalAdvisoryAssignment[]} An array of technical advisories assigned to the user.
 */
export const getTechnicalAdvisoriesForUser = (userId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('TechnicalAdvisoryAssignment__c')
    .find({
      User__c: userId
    })
    .then(assigments => {
      const opportunitiesId: string[] = []
      assigments.forEach(item => {
        if (!opportunitiesId.includes(item.Opportunity__c)) {
          opportunitiesId.push(item.Opportunity__c)
        }
      })
      return conn
        .sobject('Opportunity')
        .find({
          Id: { $in: opportunitiesId }
        })
        .select('Id, Name')
        .then(opportunities => {
          const opportunitiesMap = {}
          opportunities.forEach(opp => {
            opportunitiesMap[opp.Id] = {
              ...opp
            }
          })
          return assigments.map(assigment => ({
            ...assigment,
            Opportunity__c: opportunitiesMap[assigment.Opportunity__c]
          }))
        })
    })
}

/**
 * Retrieves opportunities with technical advisory assignments from Salesforce.
 * @function
 * @category Salesforce - Technical_Advisory_Assignments__c and Opportunity__c
 * @returns {OpportunityWithAssignments[]} An array of opportunities
 * with associated technical advisory assignments.
 */
export const getOpportunitiesWithTechnicalAdvisoryAssignments = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Opportunity')
    .find({
      Community_Delete__c: false
    })
    .select('Id, Name, Account.Name, StageName, RecordTypeId')
    .include('Technical_Advisory_Assignments__r')
    .end()
}

/**
 * Submits a technical advisory assignment in Salesforce.
 * @function
 * @category Salesforce - TechnicalAdvisoryAssignment__c
 * @param {string} id The id of the technical advisory assignment to submit.
 * @returns {JSForceResult}
 */
export const submitTechnicalAdvisory = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('TechnicalAdvisoryAssignment__c').update({
    Id: id,
    Status__c: 'Submitted'
  })
}
