import { Trans } from '@lingui/macro'
import { Button, Grid, Icon, MenuItem, TextField } from '@material-ui/core'
import { getFormPages } from 'app/services/sfAuth/sfData/sfForms'
import { PortalTab } from 'app/views/common-components/PortalTab'
import { TextFieldWithFormik } from 'app/views/common-components/TextFieldWithFormik'
import { constructFormAddressString } from 'app/views/forms/common/Common'
import { requiredTrans } from 'app/views/forms/formTranslations'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import Form from '../../forms/Form'
import { sfValidation } from '../ConfigurePortalConfigurations'

const GenerateFillablePDFForm = () => {
  const [loading, setLoading] = useState(true)
  const [avaliableForms, setAvaliableForms] = useState([])
  const [validationSchema, setValidationSchema] = useState({})
  const [generatePDF, setGeeratePdf] = useState(false)
  const [generatedPreviews, setGeneratedPreviews] = useState([])
  const [configurationId, setConfigurationId] = useState(null)
  const user = useSelector(state => state.user)
  const configuration = useSelector(state => state.configuration)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const fetchData = () => {
    Promise.all([getFormPages()]).then(([formPages, configObjects]) => {
      setAvaliableForms(formPages)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
    setGeneratedPreviews(
      configuration.CONFIGURED_PRINT_PREVIEWS
        ? configuration.CONFIGURED_PRINT_PREVIEWS
        : []
    )
  }, [])

  const buildValidationSchema = objects => {
    const toSet = {
      name: Yup.string()
    }
    objects.forEach(obj => {
      toSet[obj.identId] = sfValidation.required(requiredTrans)
    })
    setValidationSchema(toSet)
  }

  if (loading) {
    return <Loading />
  }

  const formIdToConfig = {}
  avaliableForms.forEach(form => {
    formIdToConfig[form.id] = form.config
  })

  return (
    <PortalTab tabLabel={<Trans>TAB_LABEL_GENERATE_PDF_FROM_FORM</Trans>}>
      <Formik
        validationSchema={Yup.object().shape(validationSchema)}
        initialValues={{
          form: ''
        }}
        validateOnBlur
      >
        {({
          values,
          setFieldValue,
          setValues,
          isValid,
          validateForm,
          resetForm,
          dirty
        }) => {
          const { form, name, domain } = values

          return (
            <div>
              <h5 style={{ marginBottom: 15 }}>
                <Trans>Configure</Trans>
              </h5>
              <TextField
                select
                fullWidth
                variant='outlined'
                value={values.form || ''}
                label={<Trans>Form</Trans>}
                onChange={e => {
                  const objects =
                    formIdToConfig[e.target.value]?.objectsConnected
                  if (objects) {
                    buildValidationSchema(objects)
                  }
                  resetForm({
                    values: {
                      form: e.target.value,
                      name: ''
                    }
                  })
                }}
              >
                {avaliableForms
                  .sort((a, b) => {
                    const titleA = a.name[user.language] || ''
                    const titleB = b.name[user.language] || ''
                    return titleA.localeCompare(titleB)
                  })
                  .map((form, index) => (
                    <MenuItem key={index} value={form.id}>
                      {form.name[user.language]}
                      {form.comments && ' [' + form.comments + ']'}
                      {form.config.version &&
                        ' [ v.' + form.config.version + ' ]'}
                    </MenuItem>
                  ))}
              </TextField>

              {formIdToConfig[form] && formIdToConfig[form].objectsConnected && (
                <div style={{ paddingTop: 15 }}>
                  <Trans>Objects connected</Trans>
                  {formIdToConfig[form].objectsConnected.map(
                    (object, index) => {
                      return (
                        <Grid container key={index} direction='column'>
                          <TextFieldWithFormik
                            style={{ marginTop: 10 }}
                            label={object.name + ' [' + object.type + ']'}
                            name={object.identId}
                            fullWidth
                            variant='outlined'
                            onChange={e => {
                              setFieldValue(object.identId, e.target.value)
                              setGeeratePdf(false)
                            }}
                          />
                        </Grid>
                      )
                    }
                  )}

                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValid || !dirty}
                    fullWidth
                    style={{ marginTop: 24, marginBottom: 16 }}
                    onClick={e => {
                      setGeeratePdf(true)
                    }}
                  >
                    <Trans>GENERATE_PDF_FROM_FORM</Trans>
                    <Icon style={{ marginLeft: 5 }}>picture_as_pdf</Icon>
                  </Button>
                </div>
              )}

              {generatePDF && (
                <div style={{ height: 850 }}>
                  <Form
                    fixedDisplay
                    defaultFormType='fillable-pdf'
                    fetchString={constructFormAddressString({
                      objectsConnected: formIdToConfig[form].objectsConnected,
                      ids: Object.fromEntries(
                        formIdToConfig[form].objectsConnected.map(obj => {
                          return [obj.type, values[obj.identId]]
                        })
                      )
                    })}
                    formId={values.form}
                  />
                </div>
              )}
            </div>
          )
        }}
      </Formik>
    </PortalTab>
  )
}

export default GenerateFillablePDFForm
