import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ReactHtmlParser from 'react-html-parser'
import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}))
export const FormBooleanPrint = ({
  id,
  title,
  value,
  disabled,
  typeProps,
  langVersion,
  labelAsMarkdown,
  ...props
}) => {
  const classes = useStyles()

  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString()
  }

  title = <div className={classes.root}>{ReactHtmlParser(title)}</div>

  return (
    <FormControlLabel
      control={<Checkbox disabled checked={Boolean(value)} />}
      label={title}
    />
  )
}
