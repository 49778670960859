import { Trans } from '@lingui/macro'

const keyToLabel = {
  needs: <Trans>Needs</Trans>,
  objective: <Trans>Objective</Trans>,
  outcomes: <Trans>Outcomes</Trans>
}

export const FormObjectivesError = error => {
  if (Array.isArray(error)) {
    return {
      toMap: error
        .filter(err => err)
        .map((errorItem, index) => {
          return (
            <span key={index}>
              <u>
                <Trans>Objective</Trans> {index + 1}
              </u>
              <ul key={index}>
                {Object.entries(errorItem).map(([key, value]) => {
                  return (
                    <li
                      key={key}
                      className='m-0 text-muted'
                      style={{ maxWidth: '771px' }}
                    >
                      <b>
                        {keyToLabel[key]}
                        {': '}
                      </b>
                      {value}
                    </li>
                  )
                })}
              </ul>
            </span>
          )
        })
    }
  } else {
    return error
  }
}
