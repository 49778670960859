import { t } from '@lingui/macro'

export const muiTextLabels = i18n => ({
  body: {
    noMatch: i18n._(t`Sorry, no matching records found`),
    toolTip: i18n._(t`Sort`),
    columnHeaderTooltip: column => i18n._(t`Sort for ${column.label}`)
  },
  pagination: {
    next: i18n._(t`Next Page`),
    previous: i18n._(t`Previous Page`),
    rowsPerPage: i18n._(t`Rows per page:`),
    displayRows: i18n._(t`of`)
  },
  toolbar: {
    search: i18n._(t`Search`),
    downloadCsv: i18n._(t`Download`),
    print: i18n._(t`Print`),
    viewColumns: i18n._(t`View Columns`),
    filterTable: i18n._(t`Filter Table`)
  },
  filter: {
    all: i18n._(t`All`),
    title: i18n._(t`FILTERS`),
    reset: i18n._(t`RESET`)
  },
  viewColumns: {
    title: i18n._(t`Show Columns`),
    titleAria: i18n._(t`Show/Hide Table Columns`)
  },
  selectedRows: {
    text: i18n._(t`row(s) selected`),
    delete: i18n._(t`Delete`),
    deleteAria: i18n._(t`Delete Selected Rows`)
  }
})
