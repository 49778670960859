import React from 'react'
import { Field } from 'formik'
import DebouncedTextField from './DebouncedTextField'

const DebouncedFormikTextField = ({ name, debounceTime,  ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <DebouncedTextField
        {...field}
        {...props}
        debounceTime={debounceTime}
        onChange={(event) => {
          form.setFieldValue(name, event.target.value)
        }}
      />
    )}
  </Field>
)

export default DebouncedFormikTextField
