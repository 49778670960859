import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import { CurrencyFormated } from 'app/views/common/Formats'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'

export function FormTextInputPrint ({
  value,
  elementType,
  typeProps,
  langVersion,
  editorPreview,
  ...props
}) {
  const { currency, isPhone, isAdvancedTextEditor } = typeProps
  let valueToShow = value
  if (elementType === 'textInputNumeric' && valueToShow) {
    if (isPhone) {
      valueToShow = '+1 ' + valueToShow
    } else if (currency) {
      valueToShow = <CurrencyFormated value={valueToShow} />
    }
  } else if (elementType === 'datePicker' && valueToShow) {
    valueToShow = moment(valueToShow).format(dateFormat)
  } else if (isAdvancedTextEditor || /<\/?[a-z][\s\S]*>/i.test(valueToShow)) {
    valueToShow = ReactHtmlParser(value)
  }

  return (
    <div>
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {editorPreview ? <Trans>FIELD VALUE PLACEHOLDER</Trans> : valueToShow}
      </Typography>
    </div>
  )
}
