import { useHistory } from 'react-router'
import HomepageButton from '../../../../grants/HomepageButton'

export const FormRedirectButton = ({
  title,
  typeProps: { url, color },
  ...props
}) => {
  const history = useHistory()
  return (
    <div>
      <HomepageButton
        text={title}
        link={true}
        url={url}
        color={color}
        openInSameWindow={true}
        onClick={e => {
          e.preventDefault()
          history.push(url)
        }}
      />
    </div>
  )
}
