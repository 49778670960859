import { t } from '@lingui/macro'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { fetchBudgetUpdatesForReport } from 'app/services/sfAuth/sfData/reports/sfBudgetUpdate'
import {
  getReportsForOpportunity,
  getReportsForOpportunityWithObjectiveUpdates,
  getReportsWithMilestoneUpdatesForOpportunity
} from 'app/services/sfAuth/sfData/sfReports'
import moment from 'moment'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getConnectedObjectOfType } from '../Form'
import { FormEditorHeader } from '../header/FormEditorHeader'
import { FormHeader } from '../header/FormHeader'
import { FormHeaderPDF } from '../header/FormHeaderPDF'
import { FormHeaderPreview } from '../header/FormHeaderPreview'
import { FormBlankSpace } from './blank-space/FormBlankSpace'
import { FormBlankSpacePrint } from './blank-space/FormBlankSpacePrint'
import { FormBlankSpacePrintEditor } from './blank-space/FormBlankSpacePrintEditor'
import { FormEditorBlankSpace } from './blank-space/FormEditorBlankSpace'
import {
  FormBoolean,
  formBooleanParseValueToCompare,
  formBooleanValueToText
} from './boolean/FormBoolean'
import { FormBooleanFillablePdf } from './boolean/FormBooleanFillablePdf'
import { FormBooleanPdf } from './boolean/FormBooleanPdf'
import { FormBooleanPdfEditor } from './boolean/FormBooleanPdfEditor'
import { FormBooleanPrint } from './boolean/FormBooleanPrint'
import { FormBooleanPrintEditor } from './boolean/FormBooleanPrintEditor'
import { FormEditorBoolean } from './boolean/FormEditorBoolean'
import { CommonPlaceholder } from './CommonPlaceholder'
import {
  FormAccountPicker,
  formAccountPickerDefaultValue,
  formAccountPickerExtractSaveKey,
  formAccountPickerParseValueToCompare,
  formAccountPickerValueToText
} from './custom/account-picker/FormAccountPicker'
import { FormAccountPickerPrint } from './custom/account-picker/FormAccountPickerPrint'
import { FormEditorAccountPicker } from './custom/account-picker/FormEditorAccountPicker'
import {
  FormAffiliatedOrganizations,
  formAffiliatedOrganizationsDefaultValue
} from './custom/affiliated-organizations/FormAffiliatedOrganizations'
import { FormAffiliatedOrganizationsPrint } from './custom/affiliated-organizations/FormAffiliatedOrganizationsPrint'
import { FormAffiliatedOrganizationsPrintEditor } from './custom/affiliated-organizations/FormAffiliatedOrganizationsPrintEditor'
import { FormEditorAffiliatedOrganizations } from './custom/affiliated-organizations/FormEditorAffiliatedAccounts'
import {
  FormBudgetUpdate,
  formBudgetUpdateDefaultValue,
  formBudgetUpdateSavePromise,
  formBudgetUpdateValidation
} from './custom/budget-update/FormBudgetUpdate'
import FormBudgetUpdateError from './custom/budget-update/FormBudgetUpdateError'
import { FormBudgetUpdatePDF } from './custom/budget-update/FormBudgetUpdatePDF'
import FormEditorBudgetUpdate from './custom/budget-update/FormEditorBudgetUpdate'
import {
  FormBudget,
  FormBudgetExtractKey,
  formBudgetDefaultValue,
  formBudgetParseValueToCompare,
  formBudgetValidation,
  formBudgetValueToText
} from './custom/budget/FormBudget'
import { FormBudgetError } from './custom/budget/FormBudgetError'
import { FormBudgetFillablePdf } from './custom/budget/FormBudgetFillablePdf'
import { FormBudgetPdf } from './custom/budget/FormBudgetPdf'
import { FormBudgetPdfEditor } from './custom/budget/FormBudgetPdfEditor'
import { FormBudgetPrintEditor } from './custom/budget/FormBudgetPrintEditor'
import { FormBudgetReadOnly } from './custom/budget/FormBudgetReadOnly'
import { FormEditorBudget } from './custom/budget/FormEditorBudget'
import {
  FormCensusDivision,
  cenzusDivisionParseValueToCompare,
  formCensusDivisionValidation,
  formCenzusDivisionDefaultValue,
  formCenzusDivisionError,
  formCenzusDivisionExtractKey
} from './custom/cenzus-division/FormCensusDivision'
import { FormCensusDivisionFillablePdf } from './custom/cenzus-division/FormCensusDivisionFillablePdf'
import { FormCensusDivisionFillablePdfEditor } from './custom/cenzus-division/FormCensusDivisionFillablePdfEditor'
import { FormCensusDivisionPdf } from './custom/cenzus-division/FormCensusDivisionPdf'
import { FormCensusDivisionPdfEditor } from './custom/cenzus-division/FormCensusDivisionPdfEditor'
import { FormCensusDivisionPrint } from './custom/cenzus-division/FormCensusDivisionPrint'
import { FormCensusDivisionPrintEditor } from './custom/cenzus-division/FormCensusDivisionPrintEditor'
import { FormEditorCensusDivision } from './custom/cenzus-division/FormEditorCenzusDivision'
import { FormEditorContactPicker } from './custom/contact-picker/FormEditorContactPicker'
import { FormEditorExcelFileImport } from './custom/excel-file-import/FormEditorExcelFileImport'
import { FormExcelFileImport } from './custom/excel-file-import/FormExcelFileImport'
import { formExcelFileImportSavePromise } from './custom/excel-file-import/FormExcelFileImportUtils'
import {
  FormEditorICCEBudget,
  FormICCEBudget,
  formICCEBudgetDefaultValue,
  formICCEBudgetError,
  formICCEBudgetExtractKey,
  formICCEBudgetValidation,
  formICCEBudgetValueToText
} from './custom/form-icce-budget/FormICCEBudget'
import { FormICCEBudgetPrint } from './custom/form-icce-budget/FormICCEBudgetPrint'
import { FormICCEBudgetPrintEditor } from './custom/form-icce-budget/FormICCEBudgetPrintEditor'
import {
  FormEditorICCEWorkplan,
  FormICCEWorkplan,
  formICCEWorkplanDefaultValue,
  formICCEWorkplanError,
  formICCEWorkplanExtractKey,
  formICCEWorkplanValidation,
  formICCEWorkplanValueToText
} from './custom/form-icce-workplan/FormICCEWorkplan'
import { FormICCEWorkplanPrint } from './custom/form-icce-workplan/FormICCEWorkplanPrint'
import { FormICCEWorkplanPrintEditor } from './custom/form-icce-workplan/FormICCEWorkplanPrintEditor'
import {
  FormAssociatedContactsList,
  FormAssociatedContactsListPrint,
  FormEditorAssociatedContactsList,
  formAssociatedContactsListDefaultValue,
  formAssociatedContactsListValidation,
  formAssociatedContactsListValueToText,
  formAssociatedContactsSavePromise
} from './custom/FormAssociatedContactsList'
import {
  FormContactPicker,
  FormContactPickerPrint,
  formContactParseValueToCompare,
  formContactPickerDefaultValue,
  formContactPickerSavePromise,
  formContactPickerValueToText
} from './custom/FormContactPicker'
import { FormEditorMilestoneUpdate } from './custom/milestone-update/FormEditorMilestoneUpdate'
import { FormMilestoneUpdate } from './custom/milestone-update/FormMilestoneUpdate'
import { FormMilestoneUpdatePdf } from './custom/milestone-update/FormMilestoneUpdatePdf'
import { FormMilestoneUpdatePdfEditor } from './custom/milestone-update/FormMilestoneUpdatePdfEditor'
import {
  FormMilestoneUpdateDefaultValue,
  FormMilestoneUpdateExtractKey,
  formMilestoneUpdateValueToText
} from './custom/milestone-update/FormMilestoneUpdateUtils'
import { FormMilestoneUpdateValidation } from './custom/milestone-update/FormMilestoneUpdateValidation'
import { FormEditorMilestones } from './custom/milestones/FormEditorMilestones'
import {
  FormMilestones,
  FormMilestonesDefaultValue,
  FormMilestonesExtractKey,
  FormMilestonesValidation,
  formMilestonesValueToText
} from './custom/milestones/FormMilestones'
import { FormMilestonesError } from './custom/milestones/FormMilestonesError'
import { FormMilestonesFillablePdf } from './custom/milestones/FormMilestonesFillablePdf'
import { FormMilestonesPdf } from './custom/milestones/FormMilestonesPdf'
import { FormMilestonesPdfEditor } from './custom/milestones/FormMilestonesPdfEditor'
import { FormMilestonesPrint } from './custom/milestones/FormMilestonesPrint'
import { FormMilestonesPrintEditor } from './custom/milestones/FormMilestonesPrintEditor'
import { FormEditorObjectivesUpdate } from './custom/objectives-update/FormEditorObjectivesUpdate'
import { FormObjectivesUpdate } from './custom/objectives-update/FormObjectivesUpdate'
import { FormObjectivesUpdatePdf } from './custom/objectives-update/FormObjectivesUpdatePdf'
import { FormObjectivesUpdatePdfEditor } from './custom/objectives-update/FormObjectivesUpdatePdfEditor'
import { FormObjectivesUpdateReadOnly } from './custom/objectives-update/FormObjectivesUpdateReadOnly'
import {
  FormObjectivesUpdateDefaultValue,
  FormObjectivesUpdateExtractKey,
  formObjectivesUpdateValidaiton,
  formObjectivesUpdateValueToText
} from './custom/objectives-update/FormObjectivesUpdateUtils'
import { FormEditorObjectives } from './custom/objectives/FormEditorObjectives'
import {
  FormObjectives,
  FormObjectivesDefaultValue,
  FormObjectivesExtractKey,
  FormObjectivesValidation,
  formObjectivesValueToText
} from './custom/objectives/FormObjectives'
import { FormObjectivesError } from './custom/objectives/FormObjectivesError'
import { FormObjectivesFillablePdf } from './custom/objectives/FormObjectivesFillablePdf'
import { FormObjectivesPdf } from './custom/objectives/FormObjectivesPdf'
import { FormObjectivesPdfEditor } from './custom/objectives/FormObjectivesPdfEditor'
import { FormObjectivesPrint } from './custom/objectives/FormObjectivesPrint'
import { FormObjectivesPrintEditor } from './custom/objectives/FormObjectivesPrintEditor'
import { FormEditorOtherGrants } from './custom/other-grants/FormEditorOtherGrants'
import {
  FormOtherGrants,
  formOtherGrantsDefaultValue,
  formOtherGrantsExtractSaveKey,
  formOtherGrantsValueToText
} from './custom/other-grants/FormOtherGrants'
import { FormOtherGrantsFillablePdf } from './custom/other-grants/FormOtherGrantsFillablePdf'
import { FormOtherGrantsPdf } from './custom/other-grants/FormOtherGrantsPdf'
import { FormOtherGrantsPdfEditor } from './custom/other-grants/FormOtherGrantsPdfEditor'
import { FormOtherGrantsPrint } from './custom/other-grants/FormOtherGrantsPrint'
import { FormOtherGrantsPrintEditor } from './custom/other-grants/FormOtherGrantsPrintEditor'
import { FormEditorProjectAffiliations } from './custom/project-affiliations/FormEditorProjectAffiliations'
import { FormProjectAffiliationsFillablePDF } from './custom/project-affiliations/FormProjectAffiliationsFillablePDF'
import { FormProjectAffiliationsPdf } from './custom/project-affiliations/FormProjectAffiliationsPdf'
import { FormProjectAffiliationsPdfEditor } from './custom/project-affiliations/FormProjectAffiliationsPdfEditor'
import { FormProjectAffiliationsPrintEditor } from './custom/project-affiliations/FormProjectAffiliationsPrintEditor'
import { FormEditorRedirectButton } from './custom/redirect-button/FormEditorRedirectButton'
import { FormRedirectButton } from './custom/redirect-button/FormRedirectButton'
import { FormEditorSignature } from './custom/signature/FormEditorSignature'
import { FormSignaturePdf } from './custom/signature/FormSignaturePdf'
import { FormSignaturePrint } from './custom/signature/FormSignaturePrint'
import { FormSignaturePrintEditor } from './custom/signature/FormSignaturePrintEditor'
import { FormEditorSubmitButton } from './custom/submit-button/FormEditorSubmitButton'
import { FormSubmitButton } from './custom/submit-button/FormSubmitButton'
import {
  FormDatePicker,
  FormDatePickerValidation,
  formDatePickerDefaultValue,
  formDatePickerValueToText
} from './date-picker/FormDatePicker'
import { FormDatePickerFillablePdf } from './date-picker/FormDatePickerFillablePdf'
import { FormDatePickerPrint } from './date-picker/FormDatePickerPrint'
import { FormDatePickerPrintEditor } from './date-picker/FormDatePickerPrintEditor'
import { FormEditorDatePicker } from './date-picker/FormEditorDatePicker'
import {
  FormEditorGranteeReports,
  FormGranteeReports,
  FormGranteeReportsPdf,
  formGranteeReportsDefaultValue
} from './deprecated/FormGranteeReports'
import {
  FormEditorPayments,
  FormPayments,
  FormPaymentsPdf,
  formPaymentsDefaultValue
} from './deprecated/FormPayments'
import {
  FormEditorSubmitOpportunity,
  FormSubmitOpportunity
} from './deprecated/FormSubmitOpportunity'
import { FormEditorUploadFiles } from './files-upload/FormEditorUploadFiles'
import {
  FormUploadFiles,
  formUploadFilesConditionsStates
} from './files-upload/FormUploadFiles'
import { FormUploadFilesFillablePdf } from './files-upload/FormUploadFilesFillablePDF'
import { FormUploadFilesPdf } from './files-upload/FormUploadFilesPdf'
import { FormUploadFilesPdfEditor } from './files-upload/FormUploadFilesPdfEditor'
import { FormUploadFilesPrint } from './files-upload/FormUploadFilesPrint'
import { FormUploadFilesPrintEditor } from './files-upload/FormUploadFilesPrintEditor'
import { FormEditorHtml } from './html/FormEditorHtml'
import { FormHtml } from './html/FormHtmlInjector'
import { FormHtmlPdf } from './html/FormHtmlPdf'
import { FormHtmlPrint } from './html/FormHtmlPrint'
import { FormHtmlPrintEditor } from './html/FormHtmlPrintEditor'
import { FormEditorImage } from './image/FormEditorImage'
import { FormImage, formImageConditionsStates } from './image/FormImage'
import { FormImageFillablePdf } from './image/FormImageFillablePdf'
import { FormImagePdf } from './image/FormImagePdf'
import { FormImagePrint } from './image/FormImagePrint'
import { FormEditorTextFieldNumeric } from './numeric-input/FormEditorNumericInput'
import {
  FormNumericInput,
  formTextFieldNumericConditionsStates,
  formTextFieldNumericDefaultValue,
  formTextFieldNumericValueToText
} from './numeric-input/FormNumericInput'
import FormNumericInputFillablePdf from './numeric-input/FormNumericInputFillablePdf'
import { FormNumericInputPdf } from './numeric-input/FormNumericInputPdf'
import { FormNumericInputPdfEditor } from './numeric-input/FormNumericInputPdfEditor'
import { FormNumericInputPrint } from './numeric-input/FormNumericInputPrint'
import { FormNumericInputPrintEditor } from './numeric-input/FormNumericInputPrintEditor'
import { FormEditorNumericSlider } from './numeric-slider/FormEditorNumericSlider'
import { FormNumericSlider } from './numeric-slider/FormNumericSlider'
import { FormNumericSliderPDF } from './numeric-slider/FormNumericSliderPDF'
import { FormNumericSliderPDFPreview } from './numeric-slider/FormNumericSliderPDFPreview'
import { FormNumericSliderPreview } from './numeric-slider/FormNumericSliderPreview'
import { FormNumericSliderReadOnly } from './numeric-slider/FormNumericSliderReadOnly'
import { FormEditorPicklist } from './picklist/FormEditorPicklist'
import { FormPicklist, formPicklistValueToText } from './picklist/FormPicklist'
import { FormPicklistFillablePdf } from './picklist/FormPicklistFillablePdf'
import { FormPicklistPdf } from './picklist/FormPicklistPdf'
import { FormPicklistPrint } from './picklist/FormPicklistPrint'
import { FormPicklistPrintEditor } from './picklist/FormPicklistPrintEditor'
import { FormEditorSaveButton } from './save-button/FormEditorSaveButton'
import { FormSaveButton } from './save-button/FormSaveButton'
import { FormEditorTable } from './table/FormEditorTable'
import { FormTable } from './table/FormTable'
import { FormTablePdf } from './table/FormTablePdf'
import { FormTablePdfEditor } from './table/FormTablePdfEditor'
import { FormTablePrint } from './table/FormTablePrint'
import { FormTablePrintEditor } from './table/FormTablePrintEditor'
import { FormEditorTextCollection } from './text-collection/FormEditorTextCollection'
import { FormTextCollection } from './text-collection/FormTextCollection'
import { FormTextCollectionFillablePdf } from './text-collection/FormTextCollectionFillablePdf'
import { FormTextCollectionPdf } from './text-collection/FormTextCollectionPdf'
import { FormTextCollectionPdfEditor } from './text-collection/FormTextCollectionPdfEditor'
import { FormTextCollectionPrint } from './text-collection/FormTextCollectionPrint'
import { FormTextCollectionPrintEditor } from './text-collection/FormTextCollectionPrintEditor'
import { FormEditorTextField } from './text-input/FormEditorTextField'
import {
  FormTextField,
  formTextFieldValueToText
} from './text-input/FormTextField'
import { FormTextInputFillablePdf } from './text-input/FormTextInputFillablePdf'
import { FormTextInputPdf } from './text-input/FormTextInputPdf'
import { FormTextInputPrint } from './text-input/FormTextInputPrint'
import { FormTextInputPrintEditor } from './text-input/FormTextInputPrintEditor'
import { FormTextInputPdfEditor } from './text-input/FormTextInputPrintPdfEditor'
import { FormEditorText } from './text/FormEditorText'
import { FormText } from './text/FormText'
import { FormTextPdf } from './text/FormTextPdf'
import { FormTextPdfEditor } from './text/FormTextPdfEditor'
import { FormTextPrint } from './text/FormTextPrint'
import { FormTextPrintEditor } from './text/FormTextPrintEditor'

/**
 * @typedef SalesforceObjectConnection
 * @type {object}
 */
/**
 * @typedef SalesforceFieldConnection
 * @type {object}
 */

export const formTypeToComponentTypeKey = {
  pdf: 'formComponentPdf',
  editable: 'formComponent',
  printable: 'formComponentReadOnly',
  'fillable-pdf': 'formComponentFillablePdf'
}

export const formTypeToEditorPreviewKey = {
  pdf: 'formEditorPreviewPDF',
  editable: 'formEditorPreview',
  printable: 'formEditorPreviewReadOnly',
  'fillable-pdf': 'formEditorPreviewFillablePDF'
}

export const formComponentTypes = {
  // TODO: Cleanup previews
  text: {
    text: myI18n?._(t`Text`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    editorComponent: props => <FormEditorText {...props} />,
    formComponent: props => <FormText {...props} />,
    formEditorPreview: props => <FormEditorText {...props} />,
    formComponentReadOnly: props => <FormTextPrint {...props} />,
    formEditorPreviewReadOnly: props => <FormTextPrintEditor {...props} />,
    formComponentPdf: props => <FormTextPdf {...props} />,
    formEditorPreviewPDF: props => <FormTextPdfEditor {...props} />,
    formComponentFillablePdf: props => <FormTextPdf {...props} />,
    formEditorPreviewFillablePDF: props => <FormTextPdf {...props} />
  },
  // TODO: Cleanup previews
  textWithReferences: {
    text: myI18n?._(t`Text with references`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    editorComponent: props => <FormEditorTextCollection {...props} />,
    formComponent: props => <FormTextCollection {...props} />,
    formEditorPreview: props => <FormTextCollection {...props} />,
    formComponentReadOnly: props => <FormTextCollectionPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormTextCollectionPrintEditor {...props} />
    ),
    formComponentPdf: props => <FormTextCollectionPdf {...props} />,
    formEditorPreviewPDF: props => <FormTextCollectionPdfEditor {...props} />,
    formComponentFillablePdf: props => (
      <FormTextCollectionFillablePdf {...props} />
    ),
    formEditorPreviewFillablePDF: props => (
      <FormTextCollectionPdfEditor {...props} />
    )
  },
  header: {
    editorComponent: props => <FormEditorHeader {...props} />,
    text: myI18n?._(t`Header`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    formComponent: props => <FormHeader {...props} />,
    formEditorPreview: props => <FormHeaderPreview {...props} />,
    formComponentReadOnly: props => <FormHeader {...props} />,
    formEditorPreviewReadOnly: props => <FormHeaderPreview {...props} />,
    formComponentPdf: props => <FormHeaderPDF {...props} />,
    formEditorPreviewPDF: props => <FormHeaderPreview {...props} />,
    formComponentFillablePdf: props => <FormHeaderPDF {...props} />,
    formEditorPreviewFillablePDF: props => <FormHeaderPreview {...props} />
  },
  // TODO: Cleanup previews
  image: {
    text: myI18n?._(t`Image`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    additionalConditions: formImageConditionsStates,
    formComponentReadOnly: props => <FormImagePrint {...props} />,
    formEditorPreviewReadOnly: props => <FormImage {...props} />,
    formComponentPdf: props => <FormImagePdf {...props} />,
    formEditorPreviewPDF: props => <FormImage {...props} />,
    formComponentFillablePdf: props => <FormImageFillablePdf {...props} />,
    formEditorPreviewFillablePDF: props => <FormImage {...props} />,
    formComponent: props => <FormImage {...props} />,
    formEditorPreview: props => <FormEditorImage {...props} />,
    editorComponent: props => <FormEditorImage {...props} />
  },

  textInput: {
    text: myI18n?._(t`Text Input`),
    basic: true,
    injectable: true,
    editorComponent: props => <FormEditorTextField {...props} />,
    formEditorPreview: props => <FormTextField {...props} />,
    formEditorPreviewReadOnly: props => <FormTextInputPrintEditor {...props} />,
    formEditorPreviewPDF: props => <FormTextInputPdfEditor {...props} />,
    formComponent: props => <FormTextField {...props} />,
    formComponentPdf: props => <FormTextInputPdf {...props} />,
    formComponentFillablePdf: props => <FormTextInputFillablePdf {...props} />,
    formComponentReadOnly: props => <FormTextInputPrint {...props} />,
    valueToText: (value, question, object) =>
      formTextFieldValueToText(value, question, object),
    defaultValue: ''
  },
  // TODO: Cleanup previews
  textInputNumeric: {
    text: myI18n?._(t`Numeric Input`),
    basic: true,
    injectable: true,
    formComponent: props => <FormNumericInput {...props} />,
    formEditorPreview: props => <FormEditorTextFieldNumeric {...props} />,
    editorComponent: props => <FormEditorTextFieldNumeric {...props} />,
    formComponentPdf: props => <FormNumericInputPdf {...props} />,
    formEditorPreviewPDF: props => <FormNumericInputPdfEditor {...props} />,
    formComponentFillablePdf: props => (
      <FormNumericInputFillablePdf {...props} />
    ),
    formComponentReadOnly: props => <FormNumericInputPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormNumericInputPrintEditor {...props} />
    ),
    valueToText: value => formTextFieldNumericValueToText(value),
    additionalConditions: formTextFieldNumericConditionsStates,
    defaultValue: (obj, addInfo, item) =>
      formTextFieldNumericDefaultValue(obj, addInfo, item)
  },
  numericSlider: {
    editorComponent: props => <FormEditorNumericSlider {...props} />,
    text: myI18n?._(t`Numeric slider`),
    basic: true,
    injectable: true,
    formComponent: props => <FormNumericSlider {...props} />,
    formEditorPreview: props => <FormNumericSliderPreview {...props} />,
    formComponentReadOnly: props => <FormNumericSliderReadOnly {...props} />,
    formComponentPdf: props => <FormNumericSliderPDF {...props} />,
    formEditorPreviewPDF: props => <FormNumericSliderPDFPreview {...props} />
  },
  // TODO: Cleanup previews
  datePicker: {
    text: myI18n?._(t`Date picker`),
    basic: true,
    injectable: true,
    formComponentReadOnly: props => <FormDatePickerPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormDatePickerPrintEditor {...props} />
    ),
    formComponent: props => <FormDatePicker {...props} />,
    validation: (item, data) => FormDatePickerValidation(item, data),
    editorComponent: props => <FormEditorDatePicker {...props} />,
    formEditorPreview: props => <FormEditorDatePicker {...props} />,
    valueToText: value => formDatePickerValueToText(value),
    defaultValue: (obj, info, item) =>
      formDatePickerDefaultValue(obj, info, item),
    formComponentFillablePdf: props => <FormDatePickerFillablePdf {...props} />
  },
  // TODO: Cleanup previews
  bool: {
    text: myI18n?._(t`Boolean Input`),
    basic: true,
    injectable: true,
    formComponentReadOnly: props => <FormBooleanPrint {...props} />,
    formEditorPreviewReadOnly: props => <FormBooleanPrintEditor {...props} />,
    formComponent: props => <FormBoolean {...props} />,
    editorComponent: props => <FormEditorBoolean {...props} />,
    formComponentPdf: props => <FormBooleanPdf {...props} />,
    formEditorPreviewPDF: props => <FormBooleanPdfEditor {...props} />,
    formEditorPreview: props => <FormEditorBoolean {...props} />,
    defaultValue: false,
    valueToText: value => formBooleanValueToText(value),
    parseValueToCompare: value => formBooleanParseValueToCompare(value),
    formComponentFillablePdf: props => <FormBooleanFillablePdf {...props} />
  },
  // TODO: Cleanup previews
  picklist: {
    text: myI18n?._(t`Picklist`),
    basic: true,
    injectable: true,
    formComponent: props => <FormPicklist {...props} />,
    editorComponent: props => <FormEditorPicklist {...props} />,
    formEditorPreview: props => <FormEditorPicklist {...props} />,
    formComponentReadOnly: props => <FormPicklistPrint {...props} />,
    formEditorPreviewReadOnly: props => <FormPicklistPrintEditor {...props} />,
    formComponentPdf: props => <FormPicklistPdf {...props} />,
    formEditorPreviewPDF: props => <FormPicklistPrintEditor {...props} />,
    valueToText: (value, question, object) =>
      formPicklistValueToText(value, question, object),
    formComponentFillablePdf: props => <FormPicklistFillablePdf {...props} />
  },
  // TODO: Cleanup previews
  uploadFiles: {
    text: myI18n?._(t`Upload Files`),
    noFieldConnect: true,
    basic: true,
    injectable: true,
    additionalConditions: formUploadFilesConditionsStates,
    editorComponent: props => <FormEditorUploadFiles {...props} />,
    formEditorPreviewPDF: props => <FormUploadFilesPdfEditor {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormUploadFilesPrintEditor {...props} />
    ),
    formEditorPreview: props => <FormEditorUploadFiles {...props} />,
    formComponent: props => <FormUploadFiles {...props} />,
    formComponentReadOnly: props => <FormUploadFilesPrint {...props} />,
    formComponentPdf: props => <FormUploadFilesPdf {...props} />,
    formComponentFillablePdf: props => (
      <FormUploadFilesFillablePdf {...props} />
    ),
    defaultValue: []
  },
  // TODO: Cleanup previews
  blank: {
    text: myI18n?._(t`Blank space`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    formComponentReadOnly: props => <FormBlankSpacePrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormBlankSpacePrintEditor {...props} />
    ),
    formComponent: props => <FormBlankSpace {...props} />,
    editorComponent: props => <FormEditorBlankSpace {...props} />,
    formEditorPreview: props => <FormEditorBlankSpace {...props} />
  },
  // TODO: Cleanup previews
  html: {
    text: myI18n?._(t`Html`),
    basic: true,
    injectable: true,
    noRenderIndex: true,
    formComponentReadOnly: props => <FormHtmlPrint {...props} />,
    formEditorPreviewReadOnly: props => <FormHtmlPrintEditor {...props} />,
    formComponent: props => <FormHtml {...props} />,
    formEditorPreview: props => <FormEditorHtml {...props} />,
    formComponentPdf: props => <FormHtmlPdf {...props} />,
    formEditorPreviewPDF: props => <FormHtml {...props} />,
    formComponentFillablePdf: props => <FormHtmlPdf {...props} />,
    formEditorPreviewFillablePDF: props => <FormHtml {...props} />,
    editorComponent: props => <FormEditorHtml {...props} />
  },
  // TODO: Cleanup previews
  saveButton: {
    text: myI18n?._(t`Save button`),
    basic: true,
    injectable: true,
    formComponent: props => <FormSaveButton {...props} />,
    editorComponent: props => <FormEditorSaveButton {...props} />,
    formEditorPreview: props => <FormEditorSaveButton {...props} />
  },
  // TODO: Cleanup previews
  submitButton: {
    text: myI18n?._(t`Submit button`),
    basic: true,
    injectable: true,
    formComponent: props => <FormSubmitButton {...props} />,
    editorComponent: props => <FormEditorSubmitButton {...props} />,
    formEditorPreview: props => <FormEditorSubmitButton {...props} />
  },
  // TODO: Cleanup previews
  submitOpportunity: {
    injectable: true,
    deprecated: true,
    text: myI18n?._(t`Submit application field`),
    formComponent: props => <FormSubmitOpportunity {...props} />,
    editorComponent: props => <FormEditorSubmitOpportunity {...props} />,
    formEditorPreview: props => <FormEditorSubmitOpportunity {...props} />,
    defaultValue: Array(5).fill(false)
  },
  // TODO: Cleanup previews
  table: {
    text: myI18n?._(t`Table`),
    noFieldConnect: true,
    injectable: true,
    formComponentReadOnly: props => <FormTablePrint {...props} />,
    formEditorPreviewReadOnly: props => <FormTablePrintEditor {...props} />,
    formComponentPdf: props => <FormTablePdf {...props} />,
    formEditorPreviewPDF: props => <FormTablePdfEditor {...props} />,
    formComponent: props => <FormTable {...props} />,
    formEditorPreview: props => <FormEditorTable {...props} />,
    editorComponent: props => <FormEditorTable {...props} />
  },
  excelFileImport: {
    text: myI18n?._(t`FORM_COMPONENT_TYPES_EXCEL_FILE_IMPORT`),
    noFieldConnect: true,
    injectable: true,
    savePromise: props => formExcelFileImportSavePromise(props),
    // defaultValue: (obj, info, item) => formExcelFileImportDefaultValue(obj, info, item),
    defaultValue: () => {},
    formComponent: props => <FormExcelFileImport {...props} />,
    editorComponent: props => <FormEditorExcelFileImport {...props} />,
    formEditorPreview: props => <FormEditorExcelFileImport {...props} />,
    connectsToMultipleObjects: [
      {
        type: 'Opportunity',
        label: 'Opportunity',
        include: 'Buildings__r',
        includeSelect:
          'Building__c.Id, Building__c.Sources_and_providers__c, Building__c.Number_of_vacant_bachelor_units__c'
      },
      {
        type: 'Pre_Qualification__c',
        label: 'Pre Qualification',
        include: 'Buildings__r',
        includeSelect: 'Building__c.Id, Building__c.Sources_and_providers__c'
      }
    ]
  },
  // TODO: Cleanup previews
  milestones: {
    text: myI18n?._(t`Milestones`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    relatedCollections: ['milestones'],
    injectable: true,
    landscapeOrientation: true,
    // include: 'FGM_Base__Benchmarks__r',
    // initialTouchedIds: props => formMilestonesinitialTouchedIds(props),
    formComponentPdf: props => <FormMilestonesPdf {...props} />,
    formEditorPreviewPDF: props => <FormMilestonesPdfEditor {...props} />,
    formComponentReadOnly: props => <FormMilestonesPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormMilestonesPrintEditor {...props} />
    ),
    formComponent: props => <FormMilestones {...props} />,
    editorComponent: props => <FormEditorMilestones {...props} />,
    formEditorPreview: props => <FormEditorMilestones {...props} />,
    validation: item => FormMilestonesValidation(item),
    extractError: error => FormMilestonesError(error),
    defaultValue: (obj, addInfo, item) =>
      FormMilestonesDefaultValue(obj, addInfo, item),
    extractSaveKey: props => FormMilestonesExtractKey(props),
    valueToText: (value, question) =>
      formMilestonesValueToText(value, question),
    formComponentFillablePdf: props => <FormMilestonesFillablePdf {...props} />
  },
  // TODO: Cleanup previews
  objectives: {
    text: myI18n?._(t`Objectives`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    customHelptext: true,
    injectable: true,
    relatedCollections: ['objectives'],
    formComponentPdf: props => <FormObjectivesPdf {...props} />,
    formEditorPreviewPDF: props => <FormObjectivesPdfEditor {...props} />,
    formComponentReadOnly: props => <FormObjectivesPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormObjectivesPrintEditor {...props} />
    ),
    formComponent: props => <FormObjectives {...props} />,
    formEditorPreview: props => <FormEditorObjectives {...props} />,
    editorComponent: props => <FormEditorObjectives {...props} />,
    defaultValue: obj => FormObjectivesDefaultValue(obj),
    extractError: error => FormObjectivesError(error),
    validation: item => FormObjectivesValidation(item),
    extractSaveKey: props => FormObjectivesExtractKey(props),
    valueToText: value => formObjectivesValueToText(value),
    formComponentFillablePdf: props => <FormObjectivesFillablePdf {...props} />
  },
  // TODO: Cleanup previews
  objectivesUpdate: {
    text: myI18n?._(t`FORM_ELEMENT_OBJECTIVES_UPDATE`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    customHelptext: true,
    injectable: true,
    relatedCollections: ['objectives'],
    formComponentReadOnly: props => <FormObjectivesUpdateReadOnly {...props} />,
    formComponent: props => <FormObjectivesUpdate {...props} />,
    formComponentPdf: props => <FormObjectivesUpdatePdf {...props} />,
    formEditorPreviewPDF: props => <FormObjectivesUpdatePdfEditor {...props} />,
    formEditorPreview: props => <FormEditorObjectivesUpdate {...props} />,
    editorComponent: props => <FormEditorObjectivesUpdate {...props} />,
    defaultValue: (...args) => FormObjectivesUpdateDefaultValue(...args),
    extractSaveKey: props => FormObjectivesUpdateExtractKey(props),
    valueToText: value => formObjectivesUpdateValueToText(value),
    validation: (item, data, describeMap) =>
      formObjectivesUpdateValidaiton(item, data, describeMap),
    connectsToMultipleObjects: [
      {
        type: 'Opportunity',
        label: 'Opportunity',
        additional: async (result, { item, idsMap }) => {
          const connectedReport = getConnectedObjectOfType(
            item,
            'FGM_Base__Grantee_Report__c'
          )
          let currentReportId
          if (connectedReport) {
            currentReportId = idsMap[connectedReport.connectedObject]
          }
          const opportunityConnectedObject = item.typeProps.connectedTo.find(
            connected => connected.forceType === 'Opportunity'
          ).connectedObject

          return getReportsForOpportunityWithObjectiveUpdates(
            idsMap[opportunityConnectedObject]
          ).then(reportsResult => {
            const currentReportDate = reportsResult.find(
              obj => obj.Id === currentReportId
            )?.FGM_Base__Due_Date__c

            const reports = reportsResult
              .filter(
                obj =>
                  obj.FGM_Base__Submission_Date__c &&
                  obj?.FGM_Base__Status__c !== 'Obsolete' &&
                  moment(obj.FGM_Base__Due_Date__c).isBefore(currentReportDate)
              )
              .sort((a, b) => {
                return moment(a.FGM_Base__Submission_Date__c).diff(
                  b.FGM_Base__Submission_Date__c
                )
              })
            const updates = reports
              .reduce((acc, report) => {
                if (report.Objective_Updates__r?.records) {
                  const objectiveUpdatesWithReportData =
                    report.Objective_Updates__r.records.map(update => ({
                      ...update,
                      reportId: report.Id,
                      reportSubmissionDate: report.FGM_Base__Submission_Date__c
                    }))
                  acc.push(...objectiveUpdatesWithReportData)
                }
                return acc
              }, [])
              .sort((a, b) => {
                return moment(a.reportSubmissionDate).diff(
                  b.reportSubmissionDate
                )
              })
              .map(update => {
                return {
                  id: update.Id,
                  objectiveId: update.Objective__c,
                  identifiedNeedChanges: update.Identified_need_changes__c,
                  actualOutcomes: update.Actual_outcomes__c,
                  desiredOutcomeChanges: update.Desired_outcome_changes__c,
                  createdDate: update.CreatedDate,
                  reportId: update.reportId,
                  reportSubmissionDate: update.reportSubmissionDate
                }
              })
            return result.map(resObj => ({
              ...resObj,
              latestObjectiveUpdates: updates
            }))
          })
        }
      },
      {
        type: 'FGM_Base__Grantee_Report__c',
        label: 'Current report'
      }
    ]
  },
  // TODO: Cleanup previews
  milestoneUpdate: {
    text: myI18n?._(t`FORM_ELEMENT_MILESTONE_UPDATE`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    customHelptext: true,
    injectable: true,
    formComponent: props => <FormMilestoneUpdate {...props} />,
    formEditorPreview: props => <FormEditorMilestoneUpdate {...props} />,
    formComponentPdf: props => <FormMilestoneUpdatePdf {...props} />,
    formEditorPreviewPDF: props => <FormMilestoneUpdatePdfEditor {...props} />,
    editorComponent: props => <FormEditorMilestoneUpdate {...props} />,
    defaultValue: (...args) => FormMilestoneUpdateDefaultValue(...args),
    extractSaveKey: props => FormMilestoneUpdateExtractKey(props),
    valueToText: value => formMilestoneUpdateValueToText(value),
    validation: item => FormMilestoneUpdateValidation(item),
    connectsToMultipleObjects: [
      {
        type: 'Opportunity',
        label: 'Opportunity',
        include: 'FGM_Base__Benchmarks__r',
        includeSelect:
          'FGM_Base__Benchmark__c.Id, FGM_Base__Benchmark__c.FGM_Base__Due_Date__c, FGM_Base__Benchmark__c.FGM_Base__Completion_Date__c, FGM_Base__Benchmark__c.Primary_activities__c, FGM_Base__Benchmark__c.FGM_Base__Description__c, FGM_Base__Benchmark__c.FGM_Base__Status__c',
        additional: async (result, { item, idsMap }) => {
          const connectedReport = getConnectedObjectOfType(
            item,
            'FGM_Base__Grantee_Report__c'
          )
          let currentReportId
          if (connectedReport) {
            currentReportId = idsMap[connectedReport.connectedObject]
          }
          const opportunityConnectedObject = item.typeProps.connectedTo.find(
            connected => connected.forceType === 'Opportunity'
          ).connectedObject

          return getReportsWithMilestoneUpdatesForOpportunity(
            idsMap[opportunityConnectedObject]
          ).then(reportsResult => {
            // reports for the connected opportunity without the current report
            const currentReportDate = reportsResult.find(
              obj => obj.Id === currentReportId
            )?.FGM_Base__Due_Date__c
            const reports = reportsResult
              .filter(
                obj =>
                  obj.Id !== currentReportId &&
                  obj?.FGM_Base__Status__c !== 'Obsolete' &&
                  moment(obj.FGM_Base__Due_Date__c).isBefore(currentReportDate)
              )
              .sort((a, b) => {
                return moment(b.FGM_Base__Submission_Date__c).diff(
                  a.FGM_Base__Submission_Date__c
                )
              })
            return result.map(resObj => ({
              ...resObj,
              reports
            }))
          })
        }
      },
      {
        type: 'FGM_Base__Grantee_Report__c',
        label: 'Current report'
      }
    ]
  },
  // TODO: Cleanup previews
  budget: {
    text: myI18n?._(t`Budget`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    formComponentReadOnly: props => <FormBudgetReadOnly {...props} />,
    include: 'FGM_Portal__Grantee_Budget_Line_Items__r',
    includeSelect:
      'Id, LastModifiedDate, FGM_Portal__Amount__c, FGM_Portal__Category__r.Name, FGM_Portal__Grantee_Budget__r.Name, FGM_Portal__Category__r.Id, ' +
      'FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name, ' +
      'FGM_Portal__Grantee_Budget_Line_Item__c.FGM_Portal__Note__c ',
    formComponent: props => <FormBudget {...props} />,
    formEditorPreview: props => <FormEditorBudget {...props} />,
    formEditorPreviewReadOnly: props => <FormBudgetPrintEditor {...props} />,
    validation: props => formBudgetValidation(props),
    extractError: error => FormBudgetError(error),
    editorComponent: props => <FormEditorBudget {...props} />,
    defaultValue: obj => formBudgetDefaultValue(obj),
    extractSaveKey: props => FormBudgetExtractKey(props),
    valueToText: value => formBudgetValueToText(value),
    parseValueToCompare: value => formBudgetParseValueToCompare(value),
    formComponentPdf: props => <FormBudgetPdf {...props} />,
    formComponentFillablePdf: props => <FormBudgetFillablePdf {...props} />,
    formEditorPreviewPDF: props => <FormBudgetPdfEditor {...props} />,
    formEditorPreviewFillablePDF: props => <FormBudgetPdfEditor {...props} />
  },
  // TODO: Cleanup previews
  budgetUpdate: {
    text: myI18n?._(t`FORM_ELEMENT_BUDGET_UPDATE`),
    landscapeOrientation: true,
    connectsToMultipleObjects: [
      {
        type: 'Opportunity',
        label: 'Opportunity',
        select: 'Actual_Payments_Made__c',
        include: [
          {
            base: 'FGM_Portal__Grantee_Budget_Line_Items__r',
            select:
              'Id, LastModifiedDate, FGM_Portal__Amount__c, FGM_Portal__Category__r.Name, FGM_Portal__Category__r.Id, FGM_Portal__Grantee_Budget__r.Name, ' +
              'FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name, ' +
              'FGM_Portal__Grantee_Budget_Line_Item__c.FGM_Portal__Note__c '
          }
        ],
        additional: (result, { item, idsMap }) => {
          // TODO Add support to multiple Opportunities if necessary
          const oppId = result[0].Id
          const connectedReport = getConnectedObjectOfType(
            item,
            'FGM_Base__Grantee_Report__c'
          )
          let currentReportId
          if (connectedReport) {
            currentReportId = idsMap[connectedReport.connectedObject]
          }
          return getReportsForOpportunity(oppId).then(reportsResult => {
            const currentReportDate = reportsResult.find(
              obj => obj.Id === currentReportId
            )?.FGM_Base__Due_Date__c

            const reportsSorted = reportsResult
              .filter(
                obj =>
                  obj.FGM_Base__Submission_Date__c &&
                  obj?.FGM_Base__Status__c !== 'Obsolete' &&
                  moment(obj.FGM_Base__Due_Date__c).isBefore(currentReportDate)
              )
              .sort((a, b) => {
                return moment(a.FGM_Base__Submission_Date__c).diff(
                  b.FGM_Base__Submission_Date__c
                )
              })
            const latestReport = reportsSorted[reportsSorted.length - 1]
            if (!latestReport) {
              return result
            }
            return fetchBudgetUpdatesForReport(latestReport.Id).then(
              updatesResult => {
                return result.map(resObj => ({
                  ...resObj,
                  FGM_Base__Grantee_Reports__r: reportsResult,
                  latestReport,
                  latestReportBudgetUpdates: updatesResult
                }))
              }
            )
          })
        }
      },
      {
        type: 'FGM_Base__Grantee_Report__c',
        label: 'Current report',
        select: ['Budget_changes__c', 'FGM_Base__Status__c'],
        include: 'Budget_Updates__r'
      }
    ],
    formComponent: props => <FormBudgetUpdate {...props} />,
    formEditorPreview: props => <FormEditorBudgetUpdate {...props} />,
    formComponentPdf: props => <FormBudgetUpdatePDF {...props} />,
    editorComponent: props => <FormEditorBudgetUpdate {...props} />,
    defaultValue: (obj, info) => formBudgetUpdateDefaultValue(obj, info),
    savePromise: props => formBudgetUpdateSavePromise(props),
    validation: props => formBudgetUpdateValidation(props),
    extractError: error => FormBudgetUpdateError(error)
  },
  // TODO: Cleanup previews
  icce_budget: {
    text: myI18n?._(t`ICCE Budget`),
    disabled: !sfOauthConfig.isIcce,
    noFieldConnect: true,
    include: 'Budget_Lines__r',
    validation: item => formICCEBudgetValidation(item),
    extractError: error => formICCEBudgetError(error),
    defaultValue: (obj, addInfo, item) =>
      formICCEBudgetDefaultValue(obj, addInfo, item),
    formComponentReadOnly: props => <FormICCEBudgetPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormICCEBudgetPrintEditor {...props} />
    ),
    formComponent: props => <FormICCEBudget {...props} />,
    editorComponent: props => <FormEditorICCEBudget {...props} />,
    formEditorPreview: props => <FormEditorICCEBudget {...props} />,
    extractSaveKey: props => formICCEBudgetExtractKey(props),
    valueToText: (v, q, obj) => formICCEBudgetValueToText(v, q, obj)
  },
  // TODO: Cleanup previews
  icce_workplan: {
    text: myI18n?._(t`ICCE Workplan`),
    disabled: !sfOauthConfig.isIcce,
    noFieldConnect: true,
    include: 'Work_Plan_Lines__r',
    extractError: error => formICCEWorkplanError(error),
    validation: item => formICCEWorkplanValidation(item),
    defaultValue: obj => formICCEWorkplanDefaultValue(obj),
    formComponent: props => <FormICCEWorkplan {...props} />,
    formEditorPreview: props => <FormEditorICCEWorkplan {...props} />,
    editorComponent: props => <FormEditorICCEWorkplan {...props} />,
    formComponentReadOnly: props => <FormICCEWorkplanPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormICCEWorkplanPrintEditor {...props} />
    ),
    extractSaveKey: props => formICCEWorkplanExtractKey(props),
    valueToText: (v, q, obj) => formICCEWorkplanValueToText(v, q, obj)
  },
  // googleMapsPicker: {
  //   text: myI18n?._(t`Google Maps Picker`),
  //   noFieldConnect: true,
  //   deprecated: true,
  //   formComponent: props => <FormGoogleMapsPicker {...props} />,
  //   editorComponent: props => <FormEditorGoogleMapsPicker {...props} />,
  //   defaultValue: (obj, info, item) =>
  //     formGoogleMapsPickerDefaultValue(obj, info, item),
  //   validation: () => formGoogleMapsPickerValidation(),
  //   extractError: error => formGoogleMapsPickerError(error),
  //   extractSaveKey: props => formGoogleMapsPickerExtractSaveKey(props),
  //   valueToText: (value, question) =>
  //     formGoogleMapsPickerValueToText(value, question)
  // },
  // TODO: Cleanup previews
  granteeReports: {
    text: myI18n?._(t`Grantee Reports`),
    deprecated: true,
    noFieldConnect: true,
    relatedCollections: ['reports'],
    defaultValue: (obj, info, item) =>
      formGranteeReportsDefaultValue(obj, info, item),
    formComponentPdf: props => <FormGranteeReportsPdf {...props} />,
    formComponent: props => <FormGranteeReports {...props} />,
    editorComponent: props => <FormEditorGranteeReports {...props} />,
    formEditorPreview: props => <FormEditorGranteeReports {...props} />
  },
  // TODO: Cleanup previews
  payments: {
    text: myI18n?._(t`Payments`),
    deprecated: true,
    noFieldConnect: true,
    relatedCollections: ['payments'],
    defaultValue: (obj, info, item) =>
      formPaymentsDefaultValue(obj, info, item),
    formComponentPdf: props => <FormPaymentsPdf {...props} />,
    formComponent: props => <FormPayments {...props} />,
    formEditorPreview: props => <FormEditorPayments {...props} />,
    editorComponent: props => <FormEditorPayments {...props} />
  },
  // TODO: Cleanup previews
  otherGrants: {
    text: myI18n?._(t`Other grants`),
    injectable: true,
    formComponentReadOnly: props => <FormOtherGrantsPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormOtherGrantsPrintEditor {...props} />
    ),
    formComponent: props => <FormOtherGrants {...props} />,
    formEditorPreview: props => <FormEditorOtherGrants {...props} />,
    editorComponent: props => <FormEditorOtherGrants {...props} />,
    extractSaveKey: props => formOtherGrantsExtractSaveKey(props),
    defaultValue: (obj, info, item) =>
      formOtherGrantsDefaultValue(obj, info, item),
    valueToText: value => formOtherGrantsValueToText(value),
    formComponentPdf: props => <FormOtherGrantsPdf {...props} />,
    formEditorPreviewPDF: props => <FormOtherGrantsPdfEditor {...props} />,
    formComponentFillablePdf: props => <FormOtherGrantsFillablePdf {...props} />
  },
  signature: {
    text: myI18n._(t`Signature`),
    //deprecated: true,
    relatedCollections: ['opportunityAffiliatedContacts'],
    noFieldConnect: true,
    formComponentPdf: props => <FormSignaturePdf {...props} />,
    formEditorPreviewPDF: props => <FormSignaturePrintEditor {...props} />,
    formComponentReadOnly: props => <FormSignaturePrint {...props} />,
    formEditorPreviewReadOnly: props => <FormSignaturePrintEditor {...props} />,
    editorComponent: props => <FormEditorSignature {...props} />
  },
  // TODO: Cleanup previews
  associatedContactsList: {
    text: myI18n?._(t`Project affiliations`),
    noFieldConnect: true,
    injectable: true,
    extraFields: ['Assigned_Program_Manager__c'],
    relatedCollections: ['opportunityAffiliatedContacts'],
    formComponentReadOnly: props => (
      <FormAssociatedContactsListPrint {...props} />
    ),
    formComponentPdf: props => <FormProjectAffiliationsPdf {...props} />,
    formEditorPreviewPDF: props => (
      <FormProjectAffiliationsPdfEditor {...props} />
    ),
    formEditorPreviewReadOnly: props => (
      <FormProjectAffiliationsPrintEditor {...props} />
    ),
    editorComponent: props => <FormEditorAssociatedContactsList {...props} />,
    formComponent: props => <FormAssociatedContactsList {...props} />,
    formEditorPreview: props => <FormEditorProjectAffiliations {...props} />,
    savePromise: props => formAssociatedContactsSavePromise(props),
    validation: item => formAssociatedContactsListValidation(item),
    valueToText: (value, question) =>
      formAssociatedContactsListValueToText(value, question),
    defaultValue: (obj, info, item) =>
      formAssociatedContactsListDefaultValue(obj, info, item),
    formComponentFillablePdf: props => (
      <FormProjectAffiliationsFillablePDF {...props} />
    )
  },
  // TODO: Cleanup previews
  accountJoinList: {
    text: myI18n?._(t`Affiliated organizations`),
    deprecated: true,
    noFieldConnect: true,
    injectable: true,
    formComponentReadOnly: props => (
      <FormAffiliatedOrganizationsPrint {...props} />
    ),
    formEditorPreviewReadOnly: props => (
      <FormAffiliatedOrganizationsPrintEditor {...props} />
    ),
    formComponent: props => <FormAffiliatedOrganizations {...props} />,
    formEditorPreview: props => (
      <FormEditorAffiliatedOrganizations {...props} />
    ),
    editorComponent: props => <FormEditorAffiliatedOrganizations {...props} />,
    defaultValue: (obj, info, item) =>
      formAffiliatedOrganizationsDefaultValue(obj, info, item)
  },
  connectContact: {
    text: myI18n?._(t`Contact picker`),
    injectable: true,
    relatedCollections: ['accountAffiliations'],
    editorComponent: props => <FormEditorContactPicker {...props} />,
    formEditorPreview: props => <FormEditorContactPicker {...props} />,
    formEditorPreviewReadOnly: props => <CommonPlaceholder {...props} />,
    formComponent: props => <FormContactPicker {...props} />,
    formComponentReadOnly: props => <FormContactPickerPrint {...props} />,
    defaultValue: (obj, info, item) =>
      formContactPickerDefaultValue(obj, info, item),
    //extractSaveKey: props => formContactPickerExtractSaveKey(props),
    savePromise: props => formContactPickerSavePromise(props),
    valueToText: (v, q, obj) => formContactPickerValueToText(v, q, obj),
    parseValueToCompare: (value, props = {}) =>
      formContactParseValueToCompare(value, props)
  },
  connectAccount: {
    text: myI18n?._(t`Organization picker`),
    injectable: true,
    editorComponent: props => <FormEditorAccountPicker {...props} />,
    formEditorPreview: props => <FormEditorAccountPicker {...props} />,
    formEditorPreviewReadOnly: props => <CommonPlaceholder {...props} />,
    formComponent: props => <FormAccountPicker {...props} />,
    formComponentReadOnly: props => <FormAccountPickerPrint {...props} />,
    extractSaveKey: props => formAccountPickerExtractSaveKey(props),
    defaultValue: (obj, info, item) =>
      formAccountPickerDefaultValue(obj, info, item),
    valueToText: (value, question, object) =>
      formAccountPickerValueToText(value, question, object),
    parseValueToCompare: (value, props = {}) =>
      formAccountPickerParseValueToCompare(value, props)
  },
  // TODO: Cleanup previews
  homePageButton: {
    text: myI18n?._(t`Redirect Button`),
    editorComponent: props => <FormEditorRedirectButton {...props} />,
    formComponent: props => <FormRedirectButton {...props} />,
    formEditorPreview: props => <FormEditorRedirectButton {...props} />,
    formComponentReadOnly: props => <FormRedirectButton {...props} />
  },
  // TODO: Cleanup previews
  cenzusDivision: {
    text: myI18n?._(t`Cenzus Division`),
    noFieldConnect: true,
    injectable: true,
    extractError: error => formCenzusDivisionError(error),
    defaultValue: (obj, info, item) =>
      formCenzusDivisionDefaultValue(obj, info, item),
    extractSaveKey: props => formCenzusDivisionExtractKey(props),
    validation: item => formCensusDivisionValidation(item),
    formComponent: props => <FormCensusDivision {...props} />,
    formEditorPreview: props => <FormEditorCensusDivision {...props} />,
    editorComponent: props => <FormEditorCensusDivision {...props} />,
    formComponentPdf: props => <FormCensusDivisionPdf {...props} />,
    formEditorPreviewPDF: props => <FormCensusDivisionPdfEditor {...props} />,
    formComponentReadOnly: props => <FormCensusDivisionPrint {...props} />,
    formEditorPreviewReadOnly: props => (
      <FormCensusDivisionPrintEditor {...props} />
    ),
    valueToText: (value, question, object) => (
      <FormCensusDivisionPrint value={value} />
    ),
    parseValueToCompare: (value, props = {}) =>
      cenzusDivisionParseValueToCompare(value, props),
    formComponentFillablePdf: props => (
      <FormCensusDivisionFillablePdf {...props} />
    ),
    formEditorPreviewFillablePDF: props => (
      <FormCensusDivisionFillablePdfEditor {...props} />
    )
  }
}
