import SFAuthService from '../SFAuthService'
import { NO_USER } from '../SFAuthService'
import jsforce from 'jsforce'

/**
 * Retrieves a list of Account-Opportunity affiliations by Opportunity id or ids, or by a tag.
 * @function
 * @category Salesforce - Account and Opportunity
 * @param {string} [opportunity] Id of the Opportunity object.
 * @param {string} [tag] Tag by which Accounts are connectedy with the Opportunity (optional).
 * @param {string[]} [opportunityIds] Array of ids of Opportunities for which connected Accounts should be returned.
 * @returns {Affiliation[]}
 */
export const getAccountJoinOpportunityListByFlow = ({
  opportunity,
  opportunityIds,
  tag
}: {
  opportunity?: string,
  opportunityIds?: string[],
  tag?: string
}) => {
  const conn: jsforce.Connection = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_List',
      {
        inputs: [{ opportunityId: opportunity, tag, opportunityIds }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

/**
 * Creates an affiliation between an Account and an Opportunity.
 * @function
 * @category Salesforce - Account and Opportunity
 * @param {string} accountId Id of the Account object.
 * @param {string} [tag] Tag by which the Account will be connected to the Opportunity (optional).
 * @param {string} opportunityId Id of the Opportunity to be connected to the Account.
 * @returns {FlowResult}
 */
export const createAccountJoinOpportunityByFlow = ({
  accountId,
  tag,
  opportunityId
}:{
  accountId: string,
  tag?: string,
  opportunityId: string
}) => {
  const conn: jsforce.Connection = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_Add',
      {
        inputs: [{ accountId, opportunityId, tag }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

/**
 * Deletes an Account-Opportunity affiliation.
 * @function
 * @category Salesforce - Account and Opportunity
 * @param {string} [accountId] Id of the Account object (optional).
 * @param {string} id Id of the Account-Opportunity affiliation to be deleted.
 * @param {string} [tag] Tag by which the Account was connected to the Opportunity (optional).
 * @param {string} [opportunityId] Id of the Opportunity connected to the Account (optional).
 * @returns  {FlowResult}
 */
export const deleteAccountJoinOpportunityByFlow = ({
  accountId,
  id,
  tag,
  opportunityId
}: {
  accountId?: string,
  id: string,
  tag?: string,
  opportunityId?: string,
}) => {
  const conn: jsforce.Connection = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_Remove',
      {
        inputs: [{ accountId, opportunityId, tag, id }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}
