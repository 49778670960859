import SFAuthService, { NO_USER } from "../SFAuthService";

/**
 * Creates a join request using a custom flow in Salesforce.
 * @function
 * @category Salesforce - Join_Request__c
 * @param {string} accountId The id of the Account associated with the join request.
 * @param {string} userId - The id of the User associated with the join request.
 * @param {string} email - The email address of the User associated with the join request.
 * @param {string} role - The role of the User in the team associated with the join request.
 * @param {boolean} isModifyRequest - Indicates whether it's a modification request or not.
 * @returns {FlowResult}
 */
export const createJoinRequestByFlow = ({
  accountId,
  userId,
  email,
  role,
  isModifyRequest,
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn
      .requestPost("/actions/custom/flow/App_Create_Join_Request", {
        inputs: [
          {
            toCreate: {
              AccountId__c: accountId,
              UserId__c: userId,
              Email__c: email,
              Team_role__c: role,
              Is_Modify_Request__c: isModifyRequest,
            },
          },
        ],
      })
      .then((result) => {
        return result[0].outputValues;
      });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Denies a join request by invoking a custom flow in Salesforce.
 * @function
 * @category Salesforce - Join_Request__c
 * @param {string} id The id of the join request to deny.
 * @returns {FlowResult}.
 */
export const denyRequestByFlow = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Delete_Join_Request", {
      inputs: [
        {
          toDelete: {
            Id: id,
          },
        },
      ],
    });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Denies join requests by invoking a custom flow in Salesforce.
 * @function
 * @category Salesforce - Join_Request__c
 * @param {string[]} ids An array of ids of the join requests to deny.
 * @returns {FlowResult}.
 */
export const denyRequestsByFlow = (ids: string[]) => {
  const conn = SFAuthService.getConnection();

  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Delete_Join_Request", {
      inputs: ids.map((id) => ({
        toDelete: {
          Id: id,
        },
      })),
    });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Retrieves join requests from the Salesforce database.
 * @function
 * @category Salesforce - Join_Request__c
 * @param {string[]} idsList - The list of account IDs to filter join requests.
 * @param {boolean} [onyJoin=false] - Optional. If true, filters only join requests with associated users.
 * @returns {JoinRequest[]}
 */
export const getJoinRequests = (
  idsList: string[],
  onyJoin: boolean = false
) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn
      .sobject("Join_Request__c")
      .find({
        AccountId__c: { $in: idsList },
      })
      .then((result) => {
        if (onyJoin) {
          result = result.filter((v) => v.UserId__c);
        }
        return result.filter((v) => Boolean(v));
      });
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Retrieves the count of join requests using a custom flow in Salesforce.
 * @function
 * @category Salesforce - Join_Request__c
 * @returns {FlowResult}
 */
export const getJoinRequestCountByFlow = () => {
  const conn = SFAuthService.getConnection();
  return conn
    .requestPost("/actions/custom/flow/Portal_Get_Join_Request_Count", {
      inputs: [{}],
    })
    .then((result) => {
      return result[0].outputValues;
    });
};
