import { Trans, t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import moment from 'moment'
import { myI18n } from 'translation/I18nConnectedProvider'
import { commonPdfStyles } from '../../../common/Common'

export const FormMilestonesPdf = ({
  value = [],
  title,
  langVersion,
  typeProps = {}
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily
  const pdfStyles = StyleSheet.create({
    headerCell: {
      textAlign: 'center',
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    },
    cell: {
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    }
  })

  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    phases = []
  } = typeProps
  const headerStyle = { ...pdfStyles.headerCell }
  headerStyle.alignItems = 'flex-start'
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  headerStyle.fontWeight = 'bold'
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }
  return (
    <View>
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Stage`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Start Date`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`End Date`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2.25 }}>
          <Text>{myI18n?._(t`Primary Activities`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2.25 }}>
          <Text>{myI18n?._(t`Comments`)}</Text>
        </View>
      </View>
      {phases
        .filter((phase, index) => value[index])
        .map((phase, index) => {
          const milestone = value[index]
          return (
            <View style={commonPdfStyles.row} key={index}>
              <View style={[pdfStyles.cell, {fontWeight: 'bold'}]}>
                <Text>
                  <Trans id={myI18n?._(t`${phase.apiValue}`)} />
                </Text>
              </View>
              <View style={pdfStyles.cell}>
                <Text>
                  {milestone.startDate &&
                    moment.utc(milestone.startDate).format(dateFormat)}
                </Text>
              </View>
              <View style={pdfStyles.cell}>
                <Text>
                  {milestone.endDate &&
                    moment.utc(milestone.endDate).format(dateFormat)}
                </Text>
              </View>
              <View style={{ ...pdfStyles.cell, flex: 2.25 }}>
                <Text>{milestone.primaryActivities}</Text>
              </View>
              <View style={{ ...pdfStyles.cell, flex: 2.25 }}>
                <Text>{milestone.comments}</Text>
              </View>
            </View>
          )
        })}
    </View>
  )
}
