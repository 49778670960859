/**
 * Form element which renders a text block with few styling options.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.bold=false] If text should be rendered bold.
 * @param  {boolean} [typeProps.italics=false] If text should be rendered in italics.
 * @param  {boolean} [typeProps.underline=false] If text should be rendered in underline.
 * @param  {boolean}  [typeProps.alignCenter=false] If text should be centered.
 * @param  {boolean}  [typeProps.justify=false] If text should be justified.
 * @param  {number} [typeProps.fontSize] Override font size for text.
 * @param  {Color} [typeProps.backgroundColor] Color of the background.
 * @param  {Color} [typeProps.textColor] Color of the text.
 */
export function FormText ({
  langVersion,
  editor,
  connectedMap,
  describeMap,
  objectsFieldsMap,
  title,
  typeProps,
  injectableId,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    italics,
    bold,
    underline,
    fontSize
  } = typeProps

  const ContainerType = alignCenter ? 'div' : 'span'
  return (
    <ContainerType
      style={{
        backgroundColor: backgroundColor && backgroundColor.id,
        color: textColor && textColor.id,
        borderRadius: 5,
        fontSize: fontSize && Number(fontSize),
        textAlign: alignCenter ? 'center' : justify && 'justify',
        fontWeight: bold ? 'bold' : 400,
        fontStyle: italics ? 'italic' : 'normal',
        textDecoration: underline && 'underline',
        whiteSpace: 'pre-wrap',
        display: editor && 'block',
        width: '100%'
      }}
      className='break-in-print'
    >
      {title}
    </ContainerType>
  )
}
