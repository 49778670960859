
import {Trans} from '@lingui/macro'
import { Alert } from '@material-ui/lab'
import { ICCEBudget } from './FormICCEBudget'

export const FormICCEBudgetPrint = ({ value, title, ...props }) => {
    const fiscalYears = value?.fiscalYears || []
  
    return (
      <div style={{ flex: 1 }}>
        <div className='form-print-title'>{title}</div>
        {fiscalYears.length === 0 && <Alert severity='info'>
          <Trans>There are no lines added to ICCE budget of this application</Trans>
        </Alert>}
        {fiscalYears.map(obj => {
          const { year, budgetLines } = obj
          return <ICCEBudget year={year} budgetLines={budgetLines} key={year}/>
        })}
      </div>
    )
  }