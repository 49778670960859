/* global mainScript */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

//style rules
import "./styles/SidePanelDropDown.css";

const SidePanelDropDown = ({
	topLevelCategory,
	handleParentFilterSelect,
	handleChildFilterSelect,
	selectedFilters,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropDownNode = useRef();

	return (
		<div className="dropDownContainer" ref={dropDownNode}>
			<div
				className={`dropDownHeader ${isOpen && "open"}`}
			 	onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}
			>
				{topLevelCategory.title}
			</div>
			{isOpen && (
				<ul
					className="dropDownListContainer"
					initial="closed"
					animate="open"
					exit="closed"
				>
					{topLevelCategory.filters &&
						topLevelCategory.filters.length > 0 &&
						topLevelCategory.filters.map((parentFilter) => {
							const isAllChildrenSelected =
								selectedFilters &&
								selectedFilters.some((sf) => {
									return sf.parentFilters.some((pf) => {
										return pf.childFilters.length ==
											parentFilter.children.length;
									});
								});
							return (
								<li className="listItem" key={parentFilter.slug}>
									<div className={`checkBoxContainer parentFilter ${isAllChildrenSelected && "allChildrenSelected"}`}
										key={parentFilter.slug}
										onClick={() => {
											handleParentFilterSelect({
												topLevelCategory: topLevelCategory.slug,
												parentFilters: [
													{
														slug: parentFilter.slug,
														title: parentFilter.title,
														childFilters: parentFilter.children,
													},
												],
											});
										}}
									>
										<input
											id={parentFilter.slug}
											onChange={() => {}}
											checked={
												selectedFilters &&
												selectedFilters.some((sf) => {
													return sf.parentFilters.some(
														(pf) => pf.slug === parentFilter.slug
													);
												})
											}
											type={"checkbox"}
										/>
										<label className="parentLabel"> {parentFilter.title}</label>
									</div>
									<div className="childListContainer">
										{parentFilter.children &&
											parentFilter.children.length > 0 &&
											parentFilter.children.map((childFilter) => (
												<div className={`checkBoxContainer`}
													key={childFilter.slug}
													onClick={() => {
														handleChildFilterSelect({
															topLevelCategory: topLevelCategory.slug,
															parentFilters: [
																{
																	slug: parentFilter.slug,
																	title: parentFilter.title,
																	childFilters: [
																		{
																			slug: childFilter.slug,
																			title: childFilter.title,
																		},
																	],
																},
															],
														});
													}}
												>
													<input
														id={childFilter.slug}
														type={"checkbox"}
														onChange={() => {}}
														checked={
															selectedFilters &&
															selectedFilters.some((sf) => {
																return sf.parentFilters.some((pf) => {
																	return pf.childFilters.some(
																		(cf) => cf.slug == childFilter.slug
																	);
																});
															})
														}
													></input>
													<label className="parentLabel childLabel">{childFilter.title}</label>
												</div>
											))}
									</div>
								</li>
							)
						})}
				</ul>
			)}
		</div>
	);
};

export default SidePanelDropDown;

SidePanelDropDown.propTypes = {
	topLevelCategory: PropTypes.object,
	handleParentFilterSelect: PropTypes.func,
	handleChildFilterSelect: PropTypes.func,
	selectedFilters: PropTypes.array,
};