export const formTranslationsStructure = {
  text: {},
  textWithReferences: {
    content: {
      isArray: true,
      label: 'Text part',
      keys: ['text']
    }
  },
  html: {
    html: {
      label: 'Html',
      keyOverride: {
        en: '',
        fr: 'French'
      }
    }
  },
  header: {},
  textInput: {
    regexValidationMessage: {
      label: 'Regex validation message'
    }
  },
  textInputNumeric: {},
  numericSlider: {},
  datePicker: {},
  bool: {},
  picklist: {
    options: {
      isArray: true,
      label: 'Option',
      keys: ['title', 'tooltip']
    }
  },
  uploadFiles: {
    uploadPrefix: {
      label: 'Uploaded file prefix'
    }
  },
  saveButton: {},
  submitButton: {},
  table: {
    columns: {
      isArray: true,
      label: 'Column',
      keys: ['label']
    }
  },
  milestones: {
    text: {
      label: 'Help text'
    },
    phases: {
      isArray: true,
      label: 'Phase',
      keys: ['startDateTooltip', 'endDateTooltip']
    }
  },
  objectives: {
    outcomesHelpText: {
      label: 'Outcomes help text'
    },
    needsHelpText: {
      label: 'Needs help text'
    }
  },
  budget: {
    revenuesHelpText: {
      nonUppercase: true,
      label: 'Revenues help text'
    },
    expensesHelpText: {
      nonUppercase: true,
      label: 'Expenses help text'
    }
  },
  icce_budget: {
    unit: {
      label: 'Unit'
    },
    baseCategories: {
      label: 'Budget category',
      isArray: true,
      keys: ['title']
    }
  },
  icce_workplan: {},
  otherGrants: {},
  associatedContactsList: {
    roles: {
      isArray: true,
      label: 'Role',
      keys: ['label']
    }
  },
  accountJoinList: {},
  connectContact: {},
  connectAccount: {},
  cenzusDivision: {
    predefinedGroups: {
      isArray: true,
      label: 'Predefined group',
      keys: ['label']
    }
  }
}
