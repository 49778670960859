import { SET_AVALIABLE_ORGANIZATIONS } from '../actions/OrganizationActions'

const initialState = []

const AvaliableOrganizationsReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_AVALIABLE_ORGANIZATIONS: {
      return action.data ? [...action.data] : []
    }
    default: {
      return state
    }
  }
}

export default AvaliableOrganizationsReducer
