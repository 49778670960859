import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import {
  SurveyPdfNonApplicableCheckbox,
  pdfSurveyQuestionSubFontSize
} from '../PrintableReadyToFillSurvey'
import { Trans } from '@lingui/macro'
import { PdfInputLine } from './SurveyTextInputQuestionPdf'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'

export default function SurveyRangeQuestionPdf ({ isGroup, ...props }) {
  if (isGroup) {
    return <GroupQuestionPdfView {...props} />
  } else {
    return <SingleQuestionPdfView {...props} />
  }
}

function GroupQuestionPdfView ({ groupQuestions, nonApplicable, ...props }) {
  return groupQuestions.map((question, index) => {
    return (
      <View key={index}>
        <Text
          style={{
            fontWeight: 'thin',
            paddingLeft: 10,
            fontSize: pdfSurveyQuestionSubFontSize,
            paddingTop: 10
          }}
        >
          {getLabelFromTranslationData({
            langVersion: props.version,
            data: question.titleValue
          })}
        </Text>
        <SingleQuestionPdfView {...question} />
        {nonApplicable && <SurveyPdfNonApplicableCheckbox />}
      </View>
    )
  })
}

function SingleQuestionPdfView ({ type, ...props }) {
  return <DottedLine />
}

const DottedLine = () => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingTop: 4
      }}
    >
      <Text
        style={{
          fontSize: pdfSurveyQuestionSubFontSize,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <Trans>from</Trans>
      </Text>
      <PdfInputLine />
      <Text
        style={{
          fontSize: pdfSurveyQuestionSubFontSize,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <Trans>to</Trans>
      </Text>
      <PdfInputLine />
    </View>
  )
}
