const { makeStyles } = require('@material-ui/core')

const baseColor = 'rgba(0, 0, 0, 0.38)'

export const multiuserColoredInputClasses = userColor => {
  const coloredStyles = makeStyles(theme => {
    return {
      root: {
        '& label.Mui-focused': {
          color: [userColor || baseColor, '!important']
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: [userColor || baseColor, '!important']
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: [userColor || baseColor, '!important']
          },
          '&.Mui-disabled fieldset': {
            borderColor: [userColor || baseColor, '!important']
          },
          '&.Mui-focused fieldset': {
            borderColor: [userColor || baseColor, '!important']
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: [userColor || baseColor, '!important']
        }
      }
    }
  })
  return coloredStyles()
}
