import SFAuthService, { NO_USER } from "../SFAuthService";

type RecordToDelete = {
  Id: string;
};

/**
 * Calls flow that operates on 'toDelete' variable by provided name
 * @function
 * @category Salesforce
 * @param {string} flowName Name of the flow to be called.
 * @param {RecordToDelete[]} records Records to be deleted.
 * @returns {JSForceResult}
 */
export const callDeleteFlow = (flowName: string, records: RecordToDelete[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }

  return conn.requestPost(`/actions/custom/flow/${flowName}`, {
    inputs: [
      {
        toDelete: records,
      },
    ],
  });
};

export const enforceQuerySuccess = (
  query: () => Promise<void>,
  repeatsBeforeFail: number = Infinity,
  timeoutMs: number = 5000
) => {
  let failCount = 0;

  const executeQuery = () => {
    return query().then(
      (result) => {
        return result;
      },
      (error) => {
        failCount++;
        return new Promise((resolve, reject) => {
          if (failCount <= repeatsBeforeFail) {
            setTimeout(() => {
              resolve(executeQuery());
            }, timeoutMs);
          } else {
            reject(error);
          }
        });
      }
    );
  };

  return executeQuery();
};
