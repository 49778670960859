import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { formComponentTypes } from '../../formComponentTypes'
import { FormBudgetUpdate } from './FormBudgetUpdate'

const FormEditorBudgetUpdate = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  ...props
}) => {

  const _typeProps = typeProps || {}

  if (!editMode) {
    return (
      <FormBudgetUpdate editMode id={id} typeProps={_typeProps} {...props} />
    )
  }

  return (
    <div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={_typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.budgetUpdate.connectsToMultipleObjects
        }
      />
    </div>
  )
}

export default FormEditorBudgetUpdate
