import { Trans, t } from '@lingui/macro'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormBudgetError = error => {
  if (!error.expenses) {
    return error
  }
  return (
    <ul>
      {Object.keys(error.expenses)
        .filter(key => error.expenses[key])
        .map(key => {
          const lines = error.expenses[key]
          return (
            <li key={key}>
              <u>
                {key}
                {', '}
                <Trans>Expenses</Trans>
              </u>
              <ul>
                {lines &&
                  Object.keys(lines).map(line => {
                    const error = lines[line].comment
                    if (!error) {
                      return null
                    }
                    return (
                      <li key={line}>
                        <b>
                          {myI18n?._(t`${line}`) + ' ['}
                          <Trans>Comments</Trans>
                          {']: '}
                        </b>

                        <Trans id={error.props.id} />
                      </li>
                    )
                  })}
              </ul>
            </li>
          )
        })}
    </ul>
  )
}
