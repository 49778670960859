import { Grid, Typography } from '@material-ui/core'
import { FieldValuePlaceholder } from '../../CommonPlaceholder'

export const FormSignature = () => {
  return <div />
}

export const Signuture = ({
  typeProps,
  mainConnected,
  signature,
  isPrintEditor
}) => {
  return (
    <Grid item xs style={{ padding: 4 }}>
      <div
        style={{
          height: 1,
          width: 250,
          marginBottom: 8,
          backgroundColor: 'black'
        }}
      />

      <Typography style={{ whiteSpace: 'pre-line' }}>
        {typeProps?.isConnected && isPrintEditor ? (
          <FieldValuePlaceholder
            mainConnected={mainConnected}
            typeProps={typeProps}
          />
        ) : (
          signature
        )}
      </Typography>
    </Grid>
  )
}
