import * as Yup from 'yup'
import { Trans} from '@lingui/macro'

Yup.addMethod(Yup.array, 'checkMilestoneUpdateChronology', function (message) {
  return this.test('checkMilestoneUpdateChronology', message, function (milestones) {

    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    const startDates = milestones.map(item => item.milestoneUpdate?.phaseStartDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js
    const endDates = milestones.map(item => item.milestoneUpdate?.phaseEndDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js
    let bool = false
    const errors = []
    for (let index = 1; index < startDates.length; index++) {
      if (startDates[index] && endDates[index -1] && startDates[index] <= endDates[index - 1]) {
        bool = true
        errors.push(
          new Yup.ValidationError(
            message,
            startDates[index],
            path + `[${index}].milestoneUpdate.phaseStartDate`
          )
        )
        errors.push(
          new Yup.ValidationError(
            message,
            endDates[index],
            path + `[${index - 1}].milestoneUpdate.phaseEndDate`
          )
        )
      }
    }
    for (let index = 0; index < endDates.length; index++) {
      if (startDates[index] && endDates[index] && endDates[index] <= startDates[index]) {
        bool = true
        errors.push(
          new Yup.ValidationError(
            <Trans></Trans>,
            endDates[index],
            path + `[${index}].milestoneUpdate.phaseEndDate`
          )
        )
        errors.push(
          new Yup.ValidationError(
            <Trans></Trans>,
            startDates[index],
            path + `[${index}].milestoneUpdate.phaseStartDate`
          )
        )
        errors.push(new Yup.ValidationError(message, "yes", path + `[${index}].milestoneUpdate.phaseDate`))
      }
    }
    if (bool) {
      return createError({ message: () => errors })
      //return createError({ path, message })
    }
    return !bool
  })
})

Yup.addMethod(Yup.array, 'areDatesChanged', function (message) {
  return this.test('areDatesChanged', message, function (milestones) {

    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    const startDates = milestones.map(item => item.milestoneUpdate?.phaseStartDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js
    const endDates = milestones.map(item => item.milestoneUpdate?.phaseEndDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js
    const _startDates = milestones.map(item => item.startDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js
    const _endDates = milestones.map(item => item.endDate)
    .map(item => item?.format ? item.format('YYYY-MM-DD') : item) // format is a function of moment.js

    let bool = false
    const errors = []

    milestones.forEach((item, idx) => {
      const areDatesChangedError = item.milestoneUpdate?.areDatesChanged === "yes" && startDates[idx] === _startDates[idx] && endDates[idx] === _endDates[idx]
      if(areDatesChangedError){
        bool = true
        errors.push(new Yup.ValidationError(<Trans></Trans>, "yes", path + `[${idx}].milestoneUpdate.phaseEndDate`))
        errors.push(new Yup.ValidationError(<Trans></Trans>, "yes", path + `[${idx}].milestoneUpdate.phaseStartDate`))
        errors.push(new Yup.ValidationError(message, "yes", path + `[${idx}].milestoneUpdate.phaseDate`))
      }
    })

    if (bool) {
      return createError({ message: () => errors })
    }
    return !bool
  })
})

Yup.addMethod(Yup.array, 'checkRequiredTextFields', function (message) {
  return this.test('checkRequiredTextFields', message, function (milestones) {
    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    let bool = false
    const errors = []
    milestones.forEach((item, idx) => {
      const isEmptyText = item.milestoneUpdate?.implementedActivities ? item.milestoneUpdate?.implementedActivities.trim() === "" : true
      if(isEmptyText){
        bool = true
        errors.push(new Yup.ValidationError(message, "yes", path + `[${idx}].milestoneUpdate.implementedActivities`))
      }
    })

    if (bool) {
      return createError({ message: () => errors })
    }
    return !bool
  })
})

const requiredTrans = <Trans>This field is required</Trans>
const notAllowedTrans = <Trans>Past or current date is not allowed!</Trans>
const chronologicalTrans = (
  <Trans>Dates have to be in chronological order!</Trans>
)
const startDateMinDateTrans = <Trans>FORM_MILESTONES_START_DATE_MIN_DATE_ERROR</Trans>
const areDatesChangedTrans = (<Trans>FORM_MILESTONES_UPDATE_ARE_DATES_CHANGED_ERRORS</Trans>)
const requiredText = <Trans>Required</Trans>

export const FormMilestoneUpdateValidation = item => {
  const { commentsRequired, activitiesRequired, datesRequired } = item.typeProps
  const baseObject = {}
  return Yup.array()
    .ensure()
    .of(Yup.object(baseObject))
    .areDatesChanged(areDatesChangedTrans)
    .checkMilestoneUpdateChronology(chronologicalTrans)
    .checkRequiredTextFields(requiredText)
    // .checkStartDateMinDate({message: startDateMinDateTrans, phases: item.typeProps.phases})
    .label(<Trans>FORM_MILESTONE_UPDATE_VALIDATION</Trans>)
}
