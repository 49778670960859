import { useCallback, useEffect, useRef, useState } from "react"
import staticResourceFilters from './data/resourcefilters.json'
import { baseFilters } from "./config/baseFilters"
import PageWrapper from "./PageWrapper"
import { Trans } from "@lingui/macro"
import Filters from "./Filters"
import Loading from 'egret/components/EgretLoadable/Loading'
import ResourcesDetailsCard from "./ResourcesDetailsCard"
import OtherResourcesGrid from "./OtherResourcesGrid"
import ResourcesCard from "./ResourcesCard"
import FiltersSidePanel from "./FiltersSidePanel"
import PropTypes from 'prop-types'
import { Card, TablePagination } from "@material-ui/core"
import "./styles/ResourceInventory.css"
import { useSelector } from "react-redux"

const App = ({ pageTitle, locale = 'en' }) => {
	let baseUrl = locale == 'fr' ? window.origin + '/fr' : window.origin

	const [cardsPerPage, setCardsPerPage] = useState(10)
	const [open, setOpen] = useState(false)
	const [filters, setFilters] = useState([])
	const [resources, setResources] = useState([])
	const [mainListScroll, setMainListScroll] = useState(0)
	const [selectedResource, setSelectedResource] = useState(null)
	const topRef = useRef(null)
	const parseFilter = () => {
		try {
			return JSON.parse(
				Buffer.from(document.location.search.replace('?', ''), 'base64').toString(
					'ascii'
				)
			)
		} catch (SyntaxError) {
			return []
		}
	}
	const [selectedFilters, setSelectedFilters] = useState(
		document.location.search ? parseFilter() : []
	)
	const currentLanguage = useSelector(state => state.language) || 'en'
	const [currentPageNumber, setCurrentPageNumber] = useState(1)
	const [totalPages, setTotalPages] = useState([])
	const [clearFilters, setClearFilters] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const [searchInput, setSearchInput] = useState('')

	// 2 useEffects responsible for calling the api reqs
	// the reason they're seperated is bc they need to be called based on different values

	useEffect(() => {
		if (currentLanguage){
			getResources(currentLanguage === 'fr')
		}
	}, [currentLanguage])

	useEffect(() => {
		getFilters(baseUrl)
	}, [baseUrl])

	// handles the scroll behaviour on open and closing the sidepanel
	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'scroll'
		}
	}, [open])

	const getFilters = baseUrl => {
		const response = { data: staticResourceFilters }
		let filtersArray = []
		Object.entries(response.data).forEach(filter => {
			const [key, value] = filter
			if (key !== 'locale') {
				filtersArray.push(value)
			}
		})
		setFilters(baseFilters)
	}

	const getResources = (french = false) => {
		setIsLoading(true)
		const download = (retries=0) => {
			return fetch(`/wp-content/themes/DIVI-Child-Theme/js/resources_${french ? 'fr' : 'en'}.json`).then(result => {
				return result.json().then(resourceArray => {
					console.log('resources', french, resourceArray)
					setResources(resourceArray.map(item => item.fields))
					setTotalPages(
						pageArray(Math.ceil(resourceArray.length / cardsPerPage))
					)
				})
			}).catch(error => {
				console.error(error)
				if (retries < 3){
					setTimeout(() => download(retries +1 ), 2000)
				}else{
					alert('an Error occurred, please reaload')
				}

			}).finally(() => {
				setIsLoading(false)
			})
		}
		download().then(r => console.log('download success'))
	}

	const ApplyFilters = useCallback(() => {
		setCurrentPageNumber(1)
		console.log('apply filters', currentLanguage)
		getResources(currentLanguage === 'fr')
	}, [currentLanguage])

	// responsible for opening and closing the sidepanel
	// it lives in the App because it gets passed down to multiple components
	const toggleSidePanel = () => {
		setOpen(!open)
	}

	const handleFilterSelect = (key, option) => {
		const toSet = { ...selectedFilters }
		if (!toSet[key]) {
			toSet[key] = [option]
		} else {
			if (toSet[key].includes(option)) {
				const index = toSet[key].indexOf(option)
				toSet[key].splice(index, 1)
			} else {
				toSet[key].push(option)
			}
		}
		setSelectedFilters(toSet)
	}

	const handleChildFilterSelect = filter => {
		//find of the top level category is in the selected filters
		let existingCategoryIndex = selectedFilters.findIndex(
			f => f.topLevelCategory == filter.topLevelCategory
		)

		if (existingCategoryIndex >= 0) {
			let selectedFiltersCopy = [...selectedFilters]
			// check if parent filter exists
			let existingParentIndex = selectedFiltersCopy[
				existingCategoryIndex
			].parentFilters.findIndex(f => f.slug === filter.parentFilters[0].slug)

			if (existingParentIndex >= 0) {
				// the parent already exists
				// we need to check if the child exists in that parentFilters array
				if (
					selectedFiltersCopy[existingCategoryIndex].parentFilters[
						existingParentIndex
					].childFilters.some(
						f => f.slug == filter.parentFilters[0].childFilters[0].slug
					)
				) {
					// child filter exists so we need to remove it
					selectedFiltersCopy[existingCategoryIndex].parentFilters[
						existingParentIndex
					].childFilters = selectedFiltersCopy[
						existingCategoryIndex
					].parentFilters[existingParentIndex].childFilters.filter(
						f => f.slug !== filter.parentFilters[0].childFilters[0].slug
					)
					// remove the parent filter if its childern array is empty
					if (
						!selectedFiltersCopy[existingCategoryIndex].parentFilters[
							existingParentIndex
						].childFilters.length
					) {
						selectedFiltersCopy[
							existingCategoryIndex
						].parentFilters = selectedFiltersCopy[
							existingCategoryIndex
						].parentFilters.filter(
							pf =>
								pf.slug !==
								selectedFiltersCopy[existingCategoryIndex].parentFilters[
									existingParentIndex
								].slug
						)
					}
					// remove the top level category if its parent childern array is empty
					if (
						!selectedFiltersCopy[existingCategoryIndex].parentFilters.length
					) {
						selectedFiltersCopy = selectedFiltersCopy.filter(
							f =>
								f.topLevelCategory !==
								selectedFiltersCopy[existingCategoryIndex].topLevelCategory
						)
					}
				} else {
					//child filter doesnt exist so we need to add it
					selectedFiltersCopy[existingCategoryIndex].parentFilters[
						existingParentIndex
					].childFilters = [
						...selectedFiltersCopy[existingCategoryIndex].parentFilters[
							existingParentIndex
						].childFilters,
						filter.parentFilters[0].childFilters[0]
					]
				}
				setSelectedFilters(selectedFiltersCopy)
			} else {
				// parent doesnt exist so we should add it to the parents filter array in its top level category
				selectedFiltersCopy[existingCategoryIndex].parentFilters = [
					...selectedFiltersCopy[existingCategoryIndex].parentFilters,
					filter.parentFilters[0]
				]
				setSelectedFilters(selectedFiltersCopy)
			}
		} else {
			setSelectedFilters([...selectedFilters, filter])
		}
	}

	const clearAll = () => {
		setSelectedFilters([])
		setSearchQuery('')
		setSearchInput('')
		setCurrentPageNumber(1)
		setClearFilters(!clearFilters) // trigger value
		window.history.pushState({}, '', window.location.pathname)
	}

	const pageArray = number => {
		return [...Array(number + 1).keys()].slice(1)
		// this is a helper function for the Pagination Station
		// it creates an array of the total number of pages, so that we can map over it to display the pagination buttons.
	}

	const onClickHandler = resource => {
		setSelectedResource(resource)
		// setMainListScroll(window.scrollY)
		topRef.current.scrollIntoView()	
	}

	const filtersDataMap = Object.fromEntries(
		baseFilters.map(obj => [obj.key, obj])
	)
	const renderedResources = resources.filter(resource => {
		let isValid = true
		Object.entries(selectedFilters).forEach(([key, value]) => {
			const filterData = filtersDataMap[key]
			if (value.length > 0) {
				//filter is applied
				let bool = true
				if (filterData.container){
					let subBool = false
					for (let subKey of filterData.keys){
						subBool = subBool || filterData.check(resource[subKey], value)
					}
					bool = subBool
				}else if (filterData.check) {
					bool = filterData.check(resource[key], value)
				} else if (Array.isArray(resource[key])) {
					bool = value.some(fKey => resource[key].includes(fKey))
				} else {
					bool = value.includes(resource[key])
				}
				if (!bool) {
					isValid = false
				}
			}
		})
		return isValid
	})

	if (
		renderedResources.length + cardsPerPage <
		currentPageNumber * cardsPerPage
	) {
		setCurrentPageNumber(Math.ceil(renderedResources.length / cardsPerPage))
	}

	const isFilterSelected = Object.values(selectedFilters).some(
		array => array.length > 0
	)

	const pages =
		renderedResources &&
		pageArray(Math.ceil(renderedResources.length / cardsPerPage))
	const startIndex = (currentPageNumber - 1) * cardsPerPage

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<PageWrapper aria-label='Resources Library'>
				{selectedResource ? (
					<div ref={topRef}>
						<ResourcesDetailsCard
							resource={selectedResource}
							onClickHandler={() => {
								setSelectedResource(null)
								if (mainListScroll) {
									setTimeout(() => {
										window.scrollTo(0, mainListScroll)
									}, 200)
								}
							}}
							selectFilter={filter => {
								const toSet = {Topic: [filter] }
								setSelectedResource(null)
								setSelectedFilters(toSet)
							}}
						/>
						<OtherResourcesGrid
							currentResourceId={selectedResource.Resource_Entry_ID}
							onClickHandler={resource => {
								setSelectedResource(resource)
								topRef.current.scrollIntoView()
							}}
							resources={renderedResources.filter(resource => {
								return (
									resource.Resource_Entry_ID !==
									selectedResource.Resource_Entry_ID
								)
							})}
						/>
					</div>
				) : (
					<div className="resource_inventory" ref={topRef}>
						<h1>
							<Trans>RESOURCES_RESOURCE_INVENTORY_TITLE</Trans>
						</h1>

						{filters.length > 0 && (
							<Filters
								filters={filters}
								toggleSidePanel={toggleSidePanel}
								handleFilterSelect={handleFilterSelect}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
								clearAll={clearAll}
								ApplyFilters={ApplyFilters}
								showClearButton={isFilterSelected}
								FiltersType={`resources`}
								pageTitle={pageTitle}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								searchInput={searchInput}
								setSearchInput={setSearchInput}
							/>
						)}
						<Card>
							<h4 className="title"><Trans>RESOURCES_RESULTS_TITLE</Trans></h4>
							{renderedResources
								.slice(startIndex, startIndex + cardsPerPage)
								.map(resource => {
									return (
										<ResourcesCard
											resource={resource}
											key={resource.id}
											onClickHandler={onClickHandler}
										/>
									)
								})
							}
							<TablePagination
								component='div'
								className="pagination"
								count={renderedResources.length}
								rowsPerPage={cardsPerPage}
								page={currentPageNumber - 1}
								onPageChange={(e, page) => {
									setCurrentPageNumber(page + 1)
								}}
								onRowsPerPageChange={e => {
									setCardsPerPage(e.target.value)
								}}
								rowsPerPageOptions={[10, 15, 100]}
							/>
						</Card>
					</div>
				)}
			</PageWrapper>
			{open && (
				<FiltersSidePanel
					open={open}
					filters={filters}
					selectedFilters={selectedFilters}
					toggleSidePanel={toggleSidePanel}
					handleParentFilterSelect={handleFilterSelect}
					handleChildFilterSelect={handleChildFilterSelect}
					clearAll={clearAll}
					ApplyFilters={ApplyFilters}
				/>
			)}
		</>
	)
}

export default App

App.propTypes = {
	pageTitle: PropTypes.string,
	locale: PropTypes.string
}