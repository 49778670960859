export const SET_CASE_TYPES = 'SET_CASE_TYPES'

export function setCaseTypes (caseTypes) {
  return dispatch => {
    dispatch({
      type: SET_CASE_TYPES,
      data: caseTypes
    })
  }
}
