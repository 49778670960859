import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core'
import React, { useEffect } from 'react'

const PopUpDialogue = ({
  dialogTitle,
  dialogMessege,
  type = 'ok',
  displayAlt,
  altMessege,
  altHandle,
  altText,
  open = true,
  closeFunction = null,
  cancelFunction = null,
  confirmFunction = null,
  okText = <Trans>Ok</Trans>,
  cancelText = <Trans>Cancel</Trans>
}) => {
  const [isOpened, setOpen] = React.useState(open)

  useEffect(() => {
    if (open) {
      setOpen(true)
    }
  }, [open])

  return (
    <Dialog
      open={isOpened}
      disableBackdropClick
      onClose={() => {
        setOpen(false)
        if (closeFunction) {
          closeFunction()
        }
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        {displayAlt ? altMessege : dialogMessege}
        <Grid
          container
          direction='row'
          alignItems='center'
          justify='space-evenly'
          style={{ marginTop: 20 }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setOpen(false)
              if (confirmFunction) {
                confirmFunction()
              } else if (closeFunction) {
                closeFunction()
              }
            }}
          >
            {okText}
          </Button>
          {type === 'okCancel' && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setOpen(false)
                if (cancelFunction) {
                  cancelFunction()
                } else if (closeFunction) {
                  closeFunction()
                }
              }}
            >
              {cancelText}
            </Button>
          )}
          {displayAlt && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setOpen(false)
                if (altHandle) {
                  altHandle()
                }
              }}
            >
              {altText}
            </Button>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default PopUpDialogue
