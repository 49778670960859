import { Card, Grid, Typography } from '@material-ui/core'
import { mapContact } from 'app/services/sfAuth/sfData/sfContact'
import { MUEditedByLabel } from 'app/views/forms/multiuser/components/MUEditedByLabel'
import { Field, useFormikContext } from 'formik'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { getMainConnected } from '../../../Form'
import { getMuState } from '../../../common/Common'
import {
  endEditingField
} from '../../../multiuser/grpcMultiuserEdit'

export const formContactPickerExtractSaveKey = ({
  saveMap,
  item,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const { connectedField, connectedObject } = connectedProps
  const fieldName = connectedField?.name
  if (fieldName && connectedObject) {
    saveMap[connectedObject][fieldName] = value?.id
  }
}

export const formContactParseValueToCompare = (value, props) => {
  if (props.saveFailed && value) {
    let toRet = value.firstName + ' ' + value.lastName
    if (value.email) {
      toRet += ', ' + value.email
    }
    return toRet
  }
  if (value && value.id) {
    return value.id
  } else {
    return value
  }
}

export const formContactPickerValueToText = (
  value,
  question,
  { contactsMap }
) => {
  if (typeof value === 'string') {
    const contact = contactsMap[value] || {}
    let toRet = contact.FirstName + ' ' + contact.LastName
    if (contact.Email) {
      toRet += ', ' + contact.Email
    }
    return {
      en: toRet,
      fr: toRet
    }
  } else if (!value) {
    return {
      en: '',
      fr: ''
    }
  }
  let toRet = value.firstName + ' ' + value.lastName
  if (value.email) {
    toRet += ', ' + value.email
  }
  if (value.jobTitle) {
    toRet += ', ' + value.jobTitle
  }
  return {
    en: toRet,
    fr: toRet
  }
}

export const formContactPickerDefaultValue = (obj, additionalInfo, item) => {
  const { contactsMap = {} } = additionalInfo
  if (!obj) {
    return null
  } else {
    const { connectedField } = getMainConnected(item)
    //const { affiliationRole } = item.typeProps
    const field = connectedField?.name
    if (obj[field] && contactsMap[obj[field]]) {
      const contact = contactsMap[obj[field]]
      //let relatedAffiliation
      // if (affiliationRole && accountAffiliations) {
      //   accountAffiliations.some(affiliation => {
      //     if (
      //       affiliation.npe5__Role__c === affiliationRole &&
      //       affiliation.npe5__Status__c === 'Current'
      //     ) {
      //       relatedAffiliation = affiliation
      //       return true
      //     } else {
      //       return false
      //     }
      //   })
      // }
      return { ...mapContact(contact) } //, affiliation: relatedAffiliation }
    } else {
      return obj[field]
    }
  }
}

/**
 * Form element which allows user to select a contact from organizations address book.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection}  connectedObject Connected Salesforce object.
 * @param  {SalesforceFieldConnection}  connectedField Connected Salesforce field. Accepts 'reference' (Contact) type fields.
 */
export const FormContactPicker = ({
  id,
  title,
  editMode,
  disabled,
  saveDisabled,
  useMultiuser,
  muBag,
  showPrintProps,
  showPrintComponent,
  typeProps
}) => {
  const { values, setFieldValue } = useFormikContext()
  const { isEdited, userColor, userName } = getMuState({ values, id })
  const { connectedTo = [] } = typeProps

  if (showPrintComponent) {
    return (
      <div>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          CONTACT INFO PLACEHOLDER
        </Typography>
      </div>
    )
  }

  return (
    <Field name={id}>
      {({ field: { value }, meta }) => {
        const onContactFound = ({ ...contact }) => {
          const newValue = { ...value, ...contact }
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }

        const onContactUpdate = contact => {
          setFieldValue(id, { ...value, ...contact })
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: { ...value, ...contact },
              lockId: values.muInfo.lockId
            })
          }
        }

        return (
          <>
            <Card
              elevation={5}
              style={{
                padding: 20,
                border: '1px solid #C4C4C4'
              }}
            >
              <Grid container direction='column' justify='center'>
                <div style={{ marginBottom: 10 }}>
                  {/* <AddressBookContactPicker
                    isContactFound={Boolean(value && typeof value === 'object')}
                    preview={saveDisabled}
                    onDialogClose={e => {
                      if (useMultiuser) {
                        endEditingField({
                          ...muBag,
                          fieldId: id,
                          fieldValue: value,
                          lockId: values.muInfo.lockId
                        })
                      }
                    }}
                    onDialogOpen={e => {
                      if (useMultiuser) {
                        startEditingField({
                          ...muBag,
                          fieldId: id
                        })
                      }
                    }}
                    // useWorkEmailInNewContact={Boolean(
                    //   workEmailInContactCreation
                    // )}
                    allowCreatingNewContact
                    disabled={
                      disabled ||
                      editMode ||
                      connectedTo.length === 0 ||
                      Boolean(isEdited && useMultiuser)
                    }
                    onSelect={onContactFound}
                    // currentOrganizationAsDefault={
                    //   defaultOrganization === 'current'
                    // }
                  /> */}
                </div>

                {Boolean(value && typeof value === 'object') && (
                  <Grid container>
                    <Grid item>
                      <b>
                        <span>{value.firstName + ' ' + value.lastName}</span>{' '}
                      </b>
                      <div>
                        <span>{value.email}</span>
                      </div>
                    </Grid>
                    {/* <EditContactPanel
                      disabled={disabled || saveDisabled}
                      onContactUpdate={onContactUpdate}
                      contactInfo={value}
                      reloadLastModifiedDates={reloadLastModifiedDates}
                      renderIconOnLeft
                      // programManager={connectedObject.Assigned_Program_Manager__c}
                    /> */}
                  </Grid>
                )}
              </Grid>
            </Card>
            <FormErrorLabel id={id} error={meta.error} />
            {isEdited && (
              <div style={{ marginTop: 5 }}>
                <MUEditedByLabel color={userColor} userName={userName} />
              </div>
            )}
          </>
        )
      }}
    </Field>
  )
}
