import { Text } from '@react-pdf/renderer'

export function FormHeaderPDF ({ title, langVersion, typeProps, ...props }) {
  const { bold, italics, pdfFontSize = 14, centerText } = typeProps
  let styleToPass = {
    fontWeight: 300
  }

  if (pdfFontSize) {
    styleToPass.fontSize = +pdfFontSize
  }
  if (bold) {
    styleToPass.fontWeight = 'bold'
  }
  if (italics) {
    styleToPass.fontStyle = 'italic'
  }
  if (centerText) {
    styleToPass.textAlign = 'center'
  }

  return <Text style={styleToPass}>{title}</Text>
}
