import { Redirect } from 'react-router-dom'
import RedirectOldUrl from './RedirectOldUrl'
import formsRoutes from './views/forms/FormsRoutes'
import grantsRoutes from './views/grants/GrantsRoutes'
import dmahRoutes from './views/internal/DMAH/dmahRoutes'
import internalRoutes from './views/internal/InternalRoutes'
import sessionRoutes from './views/sessions/SessionRoutes'
import surveyRoutes from './views/surveys/SurveyRoutes'
import utilitiesRoutes from './views/utilities/UtilitiesRoutes'
import vivreEnVilleRoutes from './views/vivre-en-ville/VivreEnVilleRoutes'

const redirectOldUrl = [
  {
    path: '/elasticform/:wildcard/:addressString',
    exact: true,
    render: props => <RedirectOldUrl {...props} />
  }
]

const redirectRoute = [
  {
    path: '/',
    exact: true,
    // component: () => <Redirect to="/dashboard/learning-management" />
    // TODO: handle redirect from login and default to home
    // component: () => <Redirect to='/grants/ApplicationList' />
    component: () => <Redirect to='/grants/home' />
  }
]

const errorRoute = [
  {
    component: () => <Redirect to='/session/404' />
  }
]

const routes = [
  ...redirectOldUrl,
  ...surveyRoutes,
  ...internalRoutes,
  ...grantsRoutes,
  ...sessionRoutes,
  ...utilitiesRoutes,
  ...formsRoutes,
  ...vivreEnVilleRoutes,
  ...dmahRoutes,
  ...redirectRoute,
  ...errorRoute
]

export default routes
