import { Trans } from '@lingui/macro'
import { Divider, Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { formComponentTypes } from '../components/formComponentTypes'

export const getFormVersionDifference = ({ current, cache, elementsMap }) => {
  const toRet = Object.entries(current).filter(([key, value]) => {
    const elementData = elementsMap[key]
    if (
      !elementData ||
      !elementData.elementType ||
      ['muInfo', 'muUsers'].includes(key)
    ) {
      return false
    }
    let currentValue = value || null
    let savedValue = cache[key] || null
    const typeData = formComponentTypes[elementData.elementType]
    if (typeData.parseValueToCompare) {
      savedValue = typeData.parseValueToCompare(savedValue) || null
      currentValue = typeData.parseValueToCompare(currentValue) || null
    }
    if (typeof currentValue === 'string') {
      currentValue = currentValue.trim()
    }
    if (typeof savedValue === 'string') {
      savedValue = savedValue.trim()
    }
    return !_.isEqual(currentValue, savedValue)
  })
  return toRet
}

export const VersionsDifferences = ({ cache, current, elementsMap }) => {
  const user = useSelector(state => state.user)

  const renderArray = getFormVersionDifference({ cache, current, elementsMap })
    .map(([key, value]) => {
      const elementData = elementsMap[key]
      const typeData = formComponentTypes[elementData.elementType]
      if (!typeData.formComponentReadOnly) {
        return null
      }
      return (
        <div key={key}>
          <Grid container>
            <Grid item xs style={{ padding: 10 }}>
              {typeData.formComponentReadOnly({
                ...elementData,
                value,
                lang: user.language?.split('_')[0] || 'en'
              })}
            </Grid>
            <Grid item xs style={{ padding: 10 }}>
              {typeData.formComponentReadOnly({
                ...elementData,
                value: cache[key],
                lang: user.language?.split('_')[0] || 'en'
              })}
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    })
    .filter(o => o)

  if (renderArray.length === 0) {
    return (
      <Alert severity='info'>
        <AlertTitle>
          <Trans>
            There are no changes between current version and this saved backup
          </Trans>
        </AlertTitle>
      </Alert>
    )
  }

  return (
    <Grid container direction='column'>
      {renderArray}
    </Grid>
  )
}
