import { multiuserColors } from './FormMultiuser'

export const getMultiuserColor = colorIndex => {
  let color = multiuserColors[colorIndex]
  while (!color) {
    colorIndex -= multiuserColors.length
    color = multiuserColors[colorIndex]
  }
  return color
}
