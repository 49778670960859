import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { useDispatch } from 'react-redux'
import { FormAccountPicker } from './FormAccountPicker'

export const FormEditorAccountPicker = ({
  editMode,
  showPrintProps,
  depth,
  typeProps,
  disabled,
  injectable,
  injectableId,
  ...props
}) => {
  const dispatch = useDispatch()
  const { required } = typeProps
  if (!editMode) {
    return <FormAccountPicker editMode typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(required)}
              disabled={disabled}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.required = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Required</Trans>}
        />
      </div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        filter={item =>
          item.type === 'reference' && item.referenceTo.includes('Account')
        }
      />
    </div>
  )
}
