import { t, Trans } from "@lingui/macro";
import {
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { dateFormat } from "app/appSettings";
import { setOrganization } from "app/redux/actions/OrganizationActions";
import {
  getAccountParsedById,
  getOrganizationParsedWithPagination,
  getOrganizations,
} from "app/services/sfAuth/sfData/sfAccount";
import {
  createJoinRequestByFlow,
  denyRequestByFlow,
  getJoinRequestCountByFlow,
  getJoinRequests,
} from "app/services/sfAuth/sfData/sfJoinRequest";
import { saveUser } from "app/services/sfAuth/sfData/sfUser";
import Loading from "egret/components/EgretLoadable/Loading";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { myI18n } from "translation/I18nConnectedProvider";
import { muiDateCompare } from "utils";
import sfOauthConfig from "../../../services/sfAuth/sfAuthConfig";
import ProgressSnackbar from "../../page-layouts/CustomSnackbars";
import { muiTextLabels } from "../../utilities/muiDataTablesTranslations";

export const accountRoles = {
  MANAGER: {
    apiName: "Manager",
    label: t`Manager`,
    description: t`Manager (edit access to all portal features and user management)`,
  },
  // old api name
  COLLABORATOR: {
    apiName: "Collaborator",
    cantCreate: true,
    label: t`Contributor`,
    description: t`Contributor (edit access for grant applications and other portal features)`,
  },
  // new api name
  CONTRIBUTOR: {
    apiName: "Contributor",
    label: t`Contributor`,
    description: t`Contributor (edit access for grant applications and other portal features)`,
  },
  PARTNER: {
    apiName: "Operator",
    label: t`Partner`,
    description: t`Partner (read-only access to all grant applications)`,
  },
  ASSOCIATE: {
    apiName: "Associate",
    cantCreate: true,
    label: t`Associate`,
    description: t`Associate (read-only access for select grant applications)`,
  },
};

const apiNameToAccountRole = {};
for (const role of Object.values(accountRoles)) {
  apiNameToAccountRole[role.apiName] = role;
}
export { apiNameToAccountRole };

const UserOrganizations = () => {
  const [organizations, setOrganizations] = React.useState(null);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [joinRequestCount, setJoinRequestCount] = React.useState(false);
  const [myRequests, setMyRequests] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const currentOrganization = useSelector((state) => state.organization);
  const [totalCount, setTotalCount] = useState(0);
  /** timer for search debounce */
  const [timer, setTimer] = useState(null);
  const simulateInternal = useSelector(
    (state) => state?.testingOptions?.simulateInternal
  );
  const isInternal = simulateInternal || sfOauthConfig.isInternal;

  /** this state is used when sfOauthConfig.isInternal is true */
  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: 30,
    searchText: "",
    sortOrder: {
      direction: "asc",
      name: "name",
    },
  });

  const loadData = (tableState) => {
    setIsFetching(true);
    // query props used for both getOrganizationParsedWithPagination and getOrganizations queries
    const organizationQueryCommonProps = {
      permissions: {
        TEAM_MEMBERS_FULL: true,
      },
      fields: [
        "Id",
        "Name",
        "OwnerId",
        "LastModifiedDate",
        "RecordType.Name",
        "RecordType.Id",
      ],
    };

    return Promise.all([
      isInternal
        ? getOrganizationParsedWithPagination({
            ...organizationQueryCommonProps,
            index: tableState?.page || 0,
            sorting: tableState?.sortOrder || {
              direction: "asc",
              name: "name",
            },
            searchString: tableState?.searchText || "",
            recordsPerPage: tableState?.rowsPerPage || 30,
          })
        : getOrganizations(organizationQueryCommonProps),
      getJoinRequestCountByFlow(),
    ]).then(([result, flowResult]) => {
      const usersList = [];
      const accList = [];
      const requestOrgs = [];
      console.log("join request flow results", flowResult);
      if (flowResult) {
        setJoinRequestCount(flowResult.count);
        if (flowResult.requests) {
          setMyRequests(
            flowResult.requests.map((request, index) => ({
              organizationName: flowResult.organizations[index],
              createdDate: moment(request.CreatedDate).utc(),
              jobTitle: request.Job_Title__c,
            }))
          );
        }
      }
      if (isInternal) setTotalCount(result.totalCount);
      const records = isInternal ? result.records : result;
      const list = records.filter(function (acc) {
        if (acc.RecordType.Name === "Household Account") {
          return false;
        }
        if (acc.Name && acc.Name.toLowerCase().includes("duplicate")) {
          return false;
        }
        // if (acc.OwnerId === user.userId) {
        //   return true
        // }
        // load all for internal users
        if (isInternal) {
          return true;
        }
        if (acc.AccountTeamMembers) {
          let isMember = false;
          acc.AccountTeamMembers.records.forEach((member) => {
            if (member.UserId === user.userId) {
              isMember = true;

              acc.AccountTeamMembers.records.forEach((member) => {
                if (!usersList.includes(member.UserId)) {
                  usersList.push(member.UserId);
                }
              });
            }
          });
          return isMember;
        } else {
          return false;
        }
      });
      console.log("organizations filtered", list);
      return getJoinRequests(accList, true).then((requests) => {
        console.log("got requests", requests);
        setOrganizations([
          ...requestOrgs,
          ...list.map((item) => {
            const orgRequests = requests
              .filter((request) => {
                return Boolean(request) && request.AccountId__c === item.Id;
              })
              .map((request) => {
                return {
                  email: request.Email__c,
                  id: request.Id,
                  isInvite: !request.UserId__c,
                  request: request,
                  user: request.UserId__c,
                  role: request.Team_role__c,
                  isModifyRequest: request.Is_Modify_Request__c,
                  account: request.AccountId__c,
                };
              });

            let currentUserTeamMemberRole;
            if (item.AccountTeamMembers) {
              const thisUserAtm = item.AccountTeamMembers.records.filter(
                (atm) => atm.UserId === user.userId
              );
              if (thisUserAtm && thisUserAtm.length === 1 && thisUserAtm[0]) {
                currentUserTeamMemberRole = thisUserAtm[0].TeamMemberRole;
              }
            }
            let requested, requestedId;
            orgRequests.some((request) => {
              const bool =
                request.user === user.userId && request.isModifyRequest;
              if (bool) {
                requested = request.role;
                requestedId = request.id;
              }
              return bool;
            });

            return {
              name: item.Name,
              userRole: {
                role: apiNameToAccountRole[currentUserTeamMemberRole]?.label
                  ?.id,
                apiRole: currentUserTeamMemberRole,
                accountId: item.Id,
                requestedId,
                requested,
              },
              requests: orgRequests,
              select: { id: item.Id, name: item.Name },
              stale: {
                stale:
                  moment
                    .utc()
                    .diff(moment.utc(item.LastModifiedDate), "months") > 6,
                date: moment.utc(item.LastModifiedDate).format("YYYY-MM-DD"),
              },
              actions: {
                id: item.Id,
                canManageMembers: currentUserTeamMemberRole === "Manager",
                requestCount: orgRequests.length,
              },
            };
          }),
        ]);
      });
    });
  };

  useEffect(() => {
    loadData().finally(() => {
      setIsFetching(false);
    });
  }, []);

  const reloadData = () => {
    return loadData().finally(() => {
      setIsFetching(false);
    });
  };

  if (!organizations) {
    return <Loading />;
  }

  const tableOptions = isInternal
    ? {
        serverSide: true,
        page: tableState?.page,
        rowsPerPage: tableState?.rowsPerPage,
        count: totalCount,
        sortOrder: tableState?.sortOrder,
        onSearchChange: (searchText) => {
          clearTimeout(timer);
          setIsFetching(true);
          const newTimer = setTimeout(() => {
            setTableState({ ...tableState, page: 0, searchText });
            loadData({ ...tableState, page: 0, searchText }).finally(() => {
              setIsFetching(false);
            });
          }, 700);
          setTimer(newTimer);
        },
        onTableChange: (action, tableState) => {
          if (["changePage", "sort", "changeRowsPerPage"].includes(action)) {
            setIsFetching(true);
            setTableState(tableState);
            loadData(tableState).finally(() => {
              setIsFetching(false);
            });
          }
        },
      }
    : {
        rowsPerPage: 100,
        sortOrder: {
          direction: "asc",
          name: "name",
        },
      };

  return (
    <div style={{ padding: 20 }}>
      {isFetching && <Loading />}
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/grants/JoinOrganization");
          }}
        >
          <Trans>Add/Join Organization</Trans>
        </Button>
        {Boolean(joinRequestCount) && (
          <div style={{ textAlign: "center", margin: 15 }}>
            <Typography variant="h6">
              <Trans>
                You have <b style={{ fontSize: "26px" }}>{joinRequestCount}</b>{" "}
                pending join requests waiting to be accepted by a manager.
              </Trans>
            </Typography>
          </div>
        )}
      </Grid>

      <MUIDataTable
        title={
          <Typography variant="h2">
            <Trans>Organizations</Trans>
          </Typography>
        }
        data={organizations}
        columns={[
          {
            name: "name",
            label: myI18n._(t`Organization name`),
            options: {
              customHeadLabelRender: (props) => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <Trans>Organization name</Trans>
                  </div>
                );
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>;
              },
            },
          },
          {
            name: "userRole",
            label: myI18n._(t`Role`),
            options: {
              sort: false,
              customHeadLabelRender: (props) => {
                return (
                  <div style={{ marginLeft: 20, minWidth: 200 }}>
                    <Trans>Role</Trans>
                  </div>
                );
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                const { role, accountId, requested, requestedId, apiRole } =
                  value;
                return (
                  <div style={{ flex: 1, marginLeft: 20 }}>
                    <Trans id={role} />
                    {role !== "Manager" && (
                      <RequestHigherAccessButton
                        requested={requested}
                        requestedId={requestedId}
                        currentRole={apiRole}
                        accountId={accountId}
                        reloadData={reloadData}
                      />
                    )}
                  </div>
                );
              },
            },
          },
          {
            name: "select",
            label: myI18n._(t`Select organization`),
            options: {
              sort: false,
              customHeadLabelRender: (props) => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <Trans>Select currently associated organization</Trans>
                  </div>
                );
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                const isCurrent = value.id === currentOrganization.id;
                return (
                  <div
                    style={{
                      flex: 1,
                      marginLeft: 20,
                    }}
                  >
                    {value.id && (
                      <>
                        <Checkbox
                          disabled={isUpdating}
                          checked={isCurrent}
                          color=""
                          style={{ zIndex: 20 }}
                          onChange={() => {
                            if (isCurrent) {
                              return;
                            }
                            setIsUpdating(true);
                            const saving = enqueueSnackbar(null, {
                              variant: "info",
                              persist: true,
                              content: (key) =>
                                ProgressSnackbar(
                                  <Trans>Selecting organization</Trans>
                                ),
                            });
                            Promise.all([
                              getAccountParsedById(value.id, {
                                permissions: { TEAM_MEMBERS: true },
                              }),
                              saveUser({
                                Id: user.userId,
                                Associated_Organizations__c: value.id,
                              }),
                            ]).then(([org, saveUser]) => {
                              const newCurrent = { ...org };
                              closeSnackbar(saving);
                              enqueueSnackbar(
                                <Trans>Organization selected!</Trans>,
                                {
                                  variant: "success",
                                }
                              );
                              dispatch(setOrganization(newCurrent));
                              setIsUpdating(false);
                            });
                          }}
                        />
                      </>
                    )}
                  </div>
                );
              },
            },
          },
          {
            name: "stale",
            label: myI18n._(t`Last modified date`),
            options: {
              sortCompare: muiDateCompare("date"),
              customHeadLabelRender: (props) => {
                return (
                  <div style={{ marginRight: 20 }}>
                    <Trans>Last modified date</Trans>
                  </div>
                );
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ flex: 1, marginRight: 20 }}>
                    <span style={{ color: value.stale && "red" }}>
                      {value.date}
                      {value.stale && (
                        <>
                          {" "}
                          [<Trans>Stale</Trans>]
                        </>
                      )}
                    </span>
                  </div>
                );
              },
            },
          },
          {
            name: "actions",
            label: myI18n._(t`Actions`),
            options: {
              sort: false,
              customHeadLabelRender: (props) => {
                return (
                  <div style={{ marginRight: 20 }}>
                    <Trans>Actions</Trans>
                  </div>
                );
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ flex: 1, marginRight: 20 }}>
                    {value.id && (
                      <Tooltip title={<Trans>Organization details</Trans>}>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/grants/organization-details/${value.id}`
                            );
                          }}
                        >
                          <Icon>domain</Icon>
                        </IconButton>
                      </Tooltip>
                    )}

                    {value.canManageMembers && (
                      <Tooltip title={<Trans>Organization members</Trans>}>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/grants/ManageOrganizationMembers/${value.id}`
                            );
                          }}
                        >
                          <Badge
                            overlap="rectangular"
                            color="error"
                            badgeContent={value.requestCount}
                          >
                            <Icon>people-alt</Icon>
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                );
              },
            },
          },
        ]}
        options={{
          textLabels: muiTextLabels(myI18n),
          filter: false,
          selectableRows: "none",
          print: false,
          download: false,
          viewColumns: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            const isCurrent =
              organizations[dataIndex].select.id === currentOrganization.id;
            if (!organizations[dataIndex].select.id) {
              return { style: { backgroundColor: "#F5F5F5" } };
            }
            return {
              style: { backgroundColor: isCurrent && "#85e296" },
            };
          },
          ...tableOptions,
        }}
      />

      {myRequests.length > 0 && (
        <div style={{ marginTop: 20 }}>
          <MUIDataTable
            title={
              <Typography variant="h2">
                <Trans>My pending requests</Trans>
              </Typography>
            }
            data={myRequests}
            columns={[
              {
                name: "organizationName",
                label: myI18n._(t`Organization name`),
                options: {
                  customHeadLabelRender: (props) => {
                    return (
                      <div style={{ marginLeft: 20 }}>
                        <Trans>Organization name</Trans>
                      </div>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
                    );
                  },
                },
              },
              {
                name: "jobTitle",
                label: myI18n._(t`Job title`),
                options: {
                  customHeadLabelRender: (props) => <Trans>Job title</Trans>,
                },
              },
              {
                name: "createdDate",
                label: myI18n._(t`Request date`),
                options: {
                  sortCompare: muiDateCompare(),
                  customHeadLabelRender: (props) => <Trans>Request date</Trans>,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{moment(value).format(dateFormat)}</div>;
                  },
                },
              },
            ]}
            options={{
              textLabels: muiTextLabels(myI18n),
              filter: false,
              selectableRows: "none",
              print: false,
              download: false,
              viewColumns: false,
            }}
          />
        </div>
      )}
    </div>
  );
};

const RequestHigherAccessButton = ({
  requested,
  requestedId,
  currentRole,
  accountId,
  reloadData,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [role, selectRole] = useState(currentRole);
  const [creatingRequest, setCreatingRequeest] = useState(false);
  const user = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    selectRole(currentRole);
    setCreatingRequeest(false);
  }, [dialogOpen]);

  if (requested) {
    return (
      <>
        <Dialog open={dialogOpen}>
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Trans>
                Waiting for decision to accept role: {myI18n._(requested)}
              </Trans>
              <IconButton
                style={{ marginLeft: 10 }}
                disabled={creatingRequest}
                onClick={(e) => {
                  setDialogOpen(false);
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              style={{ marginTop: 10 }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={creatingRequest || !requestedId}
                  onClick={(e) => {
                    setCreatingRequeest(true);
                    denyRequestByFlow(requestedId).then(
                      (e) => {
                        reloadData().then((result) => {
                          setDialogOpen(false);
                          enqueueSnackbar(<Trans>Request canceled</Trans>, {
                            variant: "success",
                          });
                        });
                      },
                      (reject) => {
                        console.log("error canceling request", reject);
                        enqueueSnackbar(
                          <Trans>Error ocurred while canceling request</Trans>,
                          {
                            variant: "error",
                          }
                        );
                        setCreatingRequeest(false);
                      }
                    );
                  }}
                >
                  <Trans>Cancel request</Trans>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Tooltip
          title={
            <Trans>
              Waiting for decision to accept role:
              {myI18n._(requested)}
            </Trans>
          }
        >
          <IconButton
            onClick={(e) => {
              setDialogOpen(true);
            }}
          >
            <Icon>hourglass_empty</Icon>
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Trans>Request higher access</Trans>
            <IconButton
              disabled={creatingRequest}
              onClick={(e) => {
                setDialogOpen(false);
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            onChange={(e) => {
              selectRole(e.target.value);
            }}
            name="role"
            margin="normal"
          >
            {Object.values(accountRoles)
              .filter((role) => !role.cantCreate)
              .map((roleObj) => {
                return (
                  <FormControlLabel
                    disabled={
                      roleObj.apiName === currentRole || creatingRequest
                    }
                    key={roleObj.apiName}
                    value={roleObj.apiName}
                    checked={roleObj.apiName === role}
                    control={<Radio />}
                    label={<Trans id={roleObj.description} />}
                  />
                );
              })}
          </RadioGroup>

          <Grid container style={{ marginTop: 10 }} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              disabled={creatingRequest || role === currentRole}
              onClick={(e) => {
                setCreatingRequeest(true);
                createJoinRequestByFlow({
                  accountId,
                  userId: user.userId,
                  email: user.email,
                  isModifyRequest: true,
                  role,
                }).then(
                  (result) => {
                    reloadData().then((result) => {
                      setDialogOpen(false);
                      enqueueSnackbar(<Trans>Request sent</Trans>, {
                        variant: "success",
                      });
                    });
                  },
                  (reject) => {
                    console.log("error sending request", reject);
                    enqueueSnackbar(
                      <Trans>Error ocurred while sending request</Trans>,
                      {
                        variant: "error",
                      }
                    );
                    setCreatingRequeest(false);
                  }
                );
              }}
            >
              <Trans>Send request</Trans>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Request higher access</Trans>}>
        <IconButton
          onClick={(e) => {
            setDialogOpen(true);
          }}
        >
          <Icon>outgoing_mail</Icon>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default UserOrganizations;
