import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

/**
 * Form element which renders a header.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {Object}  [typeProps.style] CSS style object that will be applied to the element.
 * @param  {string} [typeProps.variant='none'] Material UI variant of Typography component that will be applied to header.
 * @param  {boolean}  [typeProps.bold=false] If text should be rendered bold.
 * @param  {boolean} [typeProps.italics=false] If text should be rendered in italics.
 * @param  {boolean}  [typeProps.centerText=false] If text should be centered.
 * @param  {number} [typeProps.fontSize] Override font size for header.
 */
export const FormHeader = ({ title, langVersion, typeProps, ...props }) => {
  const { style, variant, bold, italics, fontSize, centerText } = typeProps
  let styleToPass = {}
  let isError = false

  if (style) {
    try {
      const parsed = JSON.parse(style)
      if (typeof parsed === 'object') {
        styleToPass = Object.assign(styleToPass, parsed)
      } else {
        isError = true
      }
    } catch (e) {
      console.warn('could not parse style in form ', e)
      isError = Boolean(style)
    }
  }

  if (fontSize) {
    styleToPass.fontSize = +fontSize
  }
  if (bold) {
    styleToPass.fontWeight = 'bold'
  }
  if (italics) {
    styleToPass.fontStyle = 'italic'
  }
  if (centerText) {
    styleToPass.textAlign = 'center'
  }
  return (
    <>
      <Typography
        variant={variant !== 'none' && variant}
        style={{
          marginLeft: 15,
          fontWeight: 'lighter',
          ...styleToPass
        }}
      >
        {title}
      </Typography>
      {isError && (
        <span
          style={{
            color: '#f5543b',
            fontSize: '0.75rem',
            marginTop: 3,
            marginLeft: 14,
            textAlign: 'left'
          }}
        >
          <Trans>Style prop for this component was inncorrectly provided</Trans>
        </span>
      )}
    </>
  )
}
