import SFAuthService, { NO_USER } from '../SFAuthService'

/**
 * Retrieves workgroup memberships for a user using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Membership__c
 * @param {string} userId The id of the user for whom workgroup memberships are to be retrieved.
 * @returns {object} The workgroup memberships data for the specified user obtained from the specified flow.
 */
export const getWorkgroupMembershipsByFlow = (userId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Workgroup_Get_User_Membership', {
      inputs: [
        {
          userId
        }
      ]
    })
    .then(r => r[0])
}

/**
 * Retrieves workgroups using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup__c
 * @param {string[]} [ids=[]] - An optional array of workgroup ids. If provided, retrieves workgroups with these ids; otherwise, retrieves all workgroups.
 * @returns {object} The workgroups data obtained from the specified flow.
 */
export const getWorkgroupsByFlow = (ids: string[] = []) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Get_Workgroup', {
    inputs: ids.map(id => ({
      workgroupId: id
    }))
  })
}

/**
 * Creates a meeting using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Meeting__c
 * @param {object} toCreate Object containing properties for creating the meeting.
 * @returns {FlowResult}
 */
export const createMeetingByFlow = (toCreate) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Create_Meeting', {
    inputs: [{ toCreate }]
  })
}

/**
 * Saves workgroup catalogs using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Catalog__c
 * @param {object[]} toSave An array of objects representing the workgroup catalog(s) to be saved.
 * @returns {FlowResult}
 */
export const saveWorkgroupCatalogsByFlow = (toSave: object[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Save_Folder', {
    inputs: toSave.map(obj => ({
      toSave: obj
    }))
  })
}

/**
 * Updates a workgroup using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup__c
 * @param {object} toUpdate Object representing the workgroup to be updated.
 * @returns {FlowResult}
 */
export const updateWorkgroupByFlow = (toUpdate) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Workgroup_Update_Workgroup',
    {
      inputs: [{ toUpdate }]
    }
  )
}

/**
 * Deletes catalogs using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Catalog__c
 * @param {object[]} toDelete An array of objects representing the catalogs to be deleted.
 * @returns {FlowResult}
 */
export const deleteCatalogsByFlow = (toDelete: object[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Workgroup_Delete_Catalogs',
    {
      inputs: [{ toDelete }]
    }
  )
}

/**
 * Updates a meeting using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Meeting__c
 * @param {object} toUpdate Object containing properties for updating the meeting.
 * @returns {FlowResult}
 */
export const updateMeetingByFlow = (toUpdate: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Update_Meeting', {
    inputs: [{ toUpdate }]
  })
}

/**
 * Deletes a meeting using a specific flow.
 * @function
 * @category Salesforce - OP_WG_Workgroup_Meeting__c
 * @param {object} toDelete Object containing properties for deleting the meeting.
 * @returns {FlowResult}
 */
export const deleteMeetingByFlow = (toDelete: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Delete_Meeting', {
    inputs: [{ toDelete }]
  })
}
