import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SaveWillOverrideWarningDialog = ({
  handleSave,
  handleClose,
  open,
  data
}) => {
  const [changedValues, setChangedValues] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()
  const userLang = useSelector(state => state.user.language)

  useEffect(() => {
    if (data) {
      const { current, saved, formData } = data
      const toSet = []
      Object.keys(current).forEach(key => {
        if (saved[key] && formData[key]) {
          const lang = userLang.split('_')[0] || 'en'
          const currentLabel = current[key][lang]
          const savedLabel = saved[key][lang]
          const currentValue = current[key].value
          const savedValue = saved[key].value
          let isSame = isEqual(currentValue, savedValue)
          if (isSame && (saved[key].other || current[key].other)) {
            isSame = isEqual(saved[key].other, current[key].other)
          }
          // if (Array.isArray(currentValue)) {
          //   currentValue.some((v, index) => {
          //     if (v && typeof v === 'object') {
          //       Object.keys(v).some(key => {
          //         isSame = v[key] === savedValue[index][key]
          //         return !isSame
          //       })
          //     } else {
          //       isSame = v === savedValue[index]
          //     }
          //     return !isSame
          //   })
          // } else if (currentValue && typeof currentValue === 'object') {
          //   isSame = isEqual(currentValue, savedValue)
          //   // Object.keys(currentValue).some(key => {
          //   //   isSame = currentValue[key] === savedValue[key]
          //   //   return !isSame
          //   // })
          // } else {
          //   isSame = currentValue === savedValue
          // }
          if (!isSame) {
            let labelKey = lang
            if (formData.type === 'form') {
              labelKey = 'title'
            }
            toSet.push({
              currentValue: currentLabel,
              savedValue: savedLabel,
              label: formData[key][labelKey]
            })
          }
        }
      })
      if (toSet.length > 0) {
        enqueueSnackbar(<Trans>There is new data present!</Trans>, {
          variant: 'error'
        })
        setChangedValues(toSet)
      } else {
        handleClose()
        handleSave()
      }
    }
  }, [open])

  return (
    <Dialog disableBackdropClick maxWidth='lg' fullWidth open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          alignItems='center'
          justify='space-between'
        >
          <Trans>
            Another user already saved this survey while you were filling it!
          </Trans>
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 10 }}>
          <Trans>
            Compare your changes with the currently saved data and decide if you
            want to override it.
          </Trans>
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell width='40%'>
                  <b>
                    <Trans>Question</Trans>
                  </b>
                </TableCell>
                <TableCell align='right' width='30%'>
                  <b>
                    <Trans>Current value</Trans>
                  </b>
                </TableCell>
                <TableCell align='right' width='30%'>
                  <b>
                    <Trans>Saved value</Trans>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changedValues.map((obj, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row' style={{ padding: 8 }}>
                    {obj.label}
                  </TableCell>
                  <TableCell align='right' style={{ padding: 8 }}>
                    {obj.currentValue}
                  </TableCell>
                  <TableCell align='right' style={{ padding: 8 }}>
                    {obj.savedValue}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ marginTop: 10 }}
          color='primary'
          variant='contained'
          onClick={() => {
            handleClose()
          }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color='primary'
          variant='contained'
          onClick={() => {
            handleClose()
            handleSave()
          }}
        >
          <Grid container direction='row' alignItems='center' justify='center'>
            <Trans>Override changes</Trans>
            <Icon style={{ marginLeft: 5 }}>save</Icon>
          </Grid>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveWillOverrideWarningDialog
